/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-calendar-picker-container {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(34, 34, 34, 0.8);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: "tnum";
  position: absolute;
  z-index: 9999;
  font-family: Roboto, Arial, San-serif;
}
.ant-calendar-picker-container.slide-up-enter.slide-up-enter-active.ant-calendar-picker-container-placement-topLeft,
.ant-calendar-picker-container.slide-up-enter.slide-up-enter-active.ant-calendar-picker-container-placement-topRight,
.ant-calendar-picker-container.slide-up-appear.slide-up-appear-active.ant-calendar-picker-container-placement-topLeft,
.ant-calendar-picker-container.slide-up-appear.slide-up-appear-active.ant-calendar-picker-container-placement-topRight {
  animation-name: antSlideDownIn;
}
.ant-calendar-picker-container.slide-up-enter.slide-up-enter-active.ant-calendar-picker-container-placement-bottomLeft,
.ant-calendar-picker-container.slide-up-enter.slide-up-enter-active.ant-calendar-picker-container-placement-bottomRight,
.ant-calendar-picker-container.slide-up-appear.slide-up-appear-active.ant-calendar-picker-container-placement-bottomLeft,
.ant-calendar-picker-container.slide-up-appear.slide-up-appear-active.ant-calendar-picker-container-placement-bottomRight {
  animation-name: antSlideUpIn;
}
.ant-calendar-picker-container.slide-up-leave.slide-up-leave-active.ant-calendar-picker-container-placement-topLeft,
.ant-calendar-picker-container.slide-up-leave.slide-up-leave-active.ant-calendar-picker-container-placement-topRight {
  animation-name: antSlideDownOut;
}
.ant-calendar-picker-container.slide-up-leave.slide-up-leave-active.ant-calendar-picker-container-placement-bottomLeft,
.ant-calendar-picker-container.slide-up-leave.slide-up-leave-active.ant-calendar-picker-container-placement-bottomRight {
  animation-name: antSlideUpOut;
}
.ant-calendar-picker {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(34, 34, 34, 0.8);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: "tnum";
  position: relative;
  display: inline-block;
  outline: none;
  cursor: text;
  transition: opacity 0.3s;
}
.ant-calendar-picker-input {
  outline: none;
}
.ant-calendar-picker-input.ant-input {
  line-height: 1.5715;
}
.ant-calendar-picker-input.ant-input-sm {
  padding-top: 0;
  padding-bottom: 0;
}
.ant-calendar-picker:hover .ant-calendar-picker-input:not(.ant-input-disabled) {
  border-color: #333;
}
.ant-calendar-picker:focus .ant-calendar-picker-input:not(.ant-input-disabled) {
  border-color: #666060;
  border-right-width: 1px !important;
  outline: 0;
  box-shadow: 0 0 0 1px rgba(89, 89, 89, 0.2);
}
.ant-calendar-picker-clear,
.ant-calendar-picker-icon {
  position: absolute;
  top: 50%;
  right: 12px;
  z-index: 1;
  width: 14px;
  height: 14px;
  margin-top: -7px;
  font-size: 12px;
  line-height: 14px;
  transition: all 0.3s;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.ant-calendar-picker-clear {
  z-index: 2;
  color: #fff;
  font-size: 14px;
  background: #fff;
  cursor: pointer;
  opacity: 0;
  pointer-events: none;
}
.ant-calendar-picker-clear:hover {
  color: rgba(34, 34, 34, 0.45);
}
.ant-calendar-picker:hover .ant-calendar-picker-clear {
  opacity: 1;
  pointer-events: auto;
}
.ant-calendar-picker-icon {
  display: inline-block;
  color: #fff;
  font-size: 14px;
  line-height: 1;
}
.ant-input-disabled + .ant-calendar-picker-icon {
  cursor: not-allowed;
}
.ant-calendar-picker-small .ant-calendar-picker-clear,
.ant-calendar-picker-small .ant-calendar-picker-icon {
  right: 8px;
}
.ant-calendar {
  position: relative;
  width: 280px;
  font-size: 14px;
  line-height: 1.5715;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #fff;
  border-radius: 10px;
  outline: none;
  box-shadow: 0 0 1px 1px rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.08), 0 2px 8px 2px rgba(0, 0, 0, 0.05);
}
.ant-calendar-input-wrap {
  height: 34px;
  padding: 6px 10px;
  border-bottom: 1px solid #f0f0f0;
}
.ant-calendar-input {
  width: 100%;
  height: 22px;
  color: rgba(34, 34, 34, 0.8);
  background: #fff;
  border: 0;
  outline: 0;
  cursor: auto;
}
.ant-calendar-input::-moz-placeholder {
  color: #bfbfbf;
  opacity: 1;
}
.ant-calendar-input:-ms-input-placeholder {
  color: #bfbfbf;
}
.ant-calendar-input::-webkit-input-placeholder {
  color: #bfbfbf;
}
.ant-calendar-input:-moz-placeholder-shown {
  text-overflow: ellipsis;
}
.ant-calendar-input:-ms-input-placeholder {
  text-overflow: ellipsis;
}
.ant-calendar-input:placeholder-shown {
  text-overflow: ellipsis;
}
.ant-calendar-week-number {
  width: 286px;
}
.ant-calendar-week-number-cell {
  text-align: center;
}
.ant-calendar-header {
  height: 40px;
  line-height: 40px;
  text-align: center;
  border-bottom: 1px solid #f0f0f0;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.ant-calendar-header a:hover {
  color: #45a8ff;
}
.ant-calendar-header .ant-calendar-century-select,
.ant-calendar-header .ant-calendar-decade-select,
.ant-calendar-header .ant-calendar-year-select,
.ant-calendar-header .ant-calendar-month-select {
  display: inline-block;
  padding: 0 2px;
  color: rgba(255, 255, 255, 0.85);
  font-weight: 500;
  line-height: 40px;
}
.ant-calendar-header .ant-calendar-century-select-arrow,
.ant-calendar-header .ant-calendar-decade-select-arrow,
.ant-calendar-header .ant-calendar-year-select-arrow,
.ant-calendar-header .ant-calendar-month-select-arrow {
  display: none;
}
.ant-calendar-header .ant-calendar-prev-century-btn,
.ant-calendar-header .ant-calendar-next-century-btn,
.ant-calendar-header .ant-calendar-prev-decade-btn,
.ant-calendar-header .ant-calendar-next-decade-btn,
.ant-calendar-header .ant-calendar-prev-month-btn,
.ant-calendar-header .ant-calendar-next-month-btn,
.ant-calendar-header .ant-calendar-prev-year-btn,
.ant-calendar-header .ant-calendar-next-year-btn {
  position: absolute;
  top: 0;
  display: inline-block;
  padding: 0 5px;
  color: rgba(34, 34, 34, 0.45);
  font-size: 16px;
  font-family: Arial, 'Hiragino Sans GB', 'Microsoft Yahei', 'Microsoft Sans Serif', sans-serif;
  line-height: 40px;
}
.ant-calendar-header .ant-calendar-prev-century-btn,
.ant-calendar-header .ant-calendar-prev-decade-btn,
.ant-calendar-header .ant-calendar-prev-year-btn {
  left: 7px;
  height: 100%;
}
.ant-calendar-header .ant-calendar-prev-century-btn::before,
.ant-calendar-header .ant-calendar-prev-decade-btn::before,
.ant-calendar-header .ant-calendar-prev-year-btn::before,
.ant-calendar-header .ant-calendar-prev-century-btn::after,
.ant-calendar-header .ant-calendar-prev-decade-btn::after,
.ant-calendar-header .ant-calendar-prev-year-btn::after {
  position: relative;
  top: -1px;
  display: inline-block;
  width: 8px;
  height: 8px;
  vertical-align: middle;
  border: 0 solid #aaa;
  border-width: 1.5px 0 0 1.5px;
  border-radius: 1px;
  transform: rotate(-45deg) scale(0.8);
  transition: all 0.3s;
  content: '';
}
.ant-calendar-header .ant-calendar-prev-century-btn:hover::before,
.ant-calendar-header .ant-calendar-prev-decade-btn:hover::before,
.ant-calendar-header .ant-calendar-prev-year-btn:hover::before,
.ant-calendar-header .ant-calendar-prev-century-btn:hover::after,
.ant-calendar-header .ant-calendar-prev-decade-btn:hover::after,
.ant-calendar-header .ant-calendar-prev-year-btn:hover::after {
  border-color: rgba(34, 34, 34, 0.8);
}
.ant-calendar-header .ant-calendar-prev-century-btn::after,
.ant-calendar-header .ant-calendar-prev-decade-btn::after,
.ant-calendar-header .ant-calendar-prev-year-btn::after {
  display: none;
}
.ant-calendar-header .ant-calendar-prev-century-btn::after,
.ant-calendar-header .ant-calendar-prev-decade-btn::after,
.ant-calendar-header .ant-calendar-prev-year-btn::after {
  position: relative;
  left: -3px;
  display: inline-block;
}
.ant-calendar-header .ant-calendar-next-century-btn,
.ant-calendar-header .ant-calendar-next-decade-btn,
.ant-calendar-header .ant-calendar-next-year-btn {
  right: 7px;
  height: 100%;
}
.ant-calendar-header .ant-calendar-next-century-btn::before,
.ant-calendar-header .ant-calendar-next-decade-btn::before,
.ant-calendar-header .ant-calendar-next-year-btn::before,
.ant-calendar-header .ant-calendar-next-century-btn::after,
.ant-calendar-header .ant-calendar-next-decade-btn::after,
.ant-calendar-header .ant-calendar-next-year-btn::after {
  position: relative;
  top: -1px;
  display: inline-block;
  width: 8px;
  height: 8px;
  vertical-align: middle;
  border: 0 solid #aaa;
  border-width: 1.5px 0 0 1.5px;
  border-radius: 1px;
  transform: rotate(-45deg) scale(0.8);
  transition: all 0.3s;
  content: '';
}
.ant-calendar-header .ant-calendar-next-century-btn:hover::before,
.ant-calendar-header .ant-calendar-next-decade-btn:hover::before,
.ant-calendar-header .ant-calendar-next-year-btn:hover::before,
.ant-calendar-header .ant-calendar-next-century-btn:hover::after,
.ant-calendar-header .ant-calendar-next-decade-btn:hover::after,
.ant-calendar-header .ant-calendar-next-year-btn:hover::after {
  border-color: rgba(34, 34, 34, 0.8);
}
.ant-calendar-header .ant-calendar-next-century-btn::after,
.ant-calendar-header .ant-calendar-next-decade-btn::after,
.ant-calendar-header .ant-calendar-next-year-btn::after {
  display: none;
}
.ant-calendar-header .ant-calendar-next-century-btn::before,
.ant-calendar-header .ant-calendar-next-decade-btn::before,
.ant-calendar-header .ant-calendar-next-year-btn::before,
.ant-calendar-header .ant-calendar-next-century-btn::after,
.ant-calendar-header .ant-calendar-next-decade-btn::after,
.ant-calendar-header .ant-calendar-next-year-btn::after {
  transform: rotate(135deg) scale(0.8);
}
.ant-calendar-header .ant-calendar-next-century-btn::before,
.ant-calendar-header .ant-calendar-next-decade-btn::before,
.ant-calendar-header .ant-calendar-next-year-btn::before {
  position: relative;
  left: 3px;
}
.ant-calendar-header .ant-calendar-next-century-btn::after,
.ant-calendar-header .ant-calendar-next-decade-btn::after,
.ant-calendar-header .ant-calendar-next-year-btn::after {
  display: inline-block;
}
.ant-calendar-header .ant-calendar-prev-month-btn {
  left: 29px;
  height: 100%;
}
.ant-calendar-header .ant-calendar-prev-month-btn::before,
.ant-calendar-header .ant-calendar-prev-month-btn::after {
  position: relative;
  top: -1px;
  display: inline-block;
  width: 8px;
  height: 8px;
  vertical-align: middle;
  border: 0 solid #aaa;
  border-width: 1.5px 0 0 1.5px;
  border-radius: 1px;
  transform: rotate(-45deg) scale(0.8);
  transition: all 0.3s;
  content: '';
}
.ant-calendar-header .ant-calendar-prev-month-btn:hover::before,
.ant-calendar-header .ant-calendar-prev-month-btn:hover::after {
  border-color: rgba(34, 34, 34, 0.8);
}
.ant-calendar-header .ant-calendar-prev-month-btn::after {
  display: none;
}
.ant-calendar-header .ant-calendar-next-month-btn {
  right: 29px;
  height: 100%;
}
.ant-calendar-header .ant-calendar-next-month-btn::before,
.ant-calendar-header .ant-calendar-next-month-btn::after {
  position: relative;
  top: -1px;
  display: inline-block;
  width: 8px;
  height: 8px;
  vertical-align: middle;
  border: 0 solid #aaa;
  border-width: 1.5px 0 0 1.5px;
  border-radius: 1px;
  transform: rotate(-45deg) scale(0.8);
  transition: all 0.3s;
  content: '';
}
.ant-calendar-header .ant-calendar-next-month-btn:hover::before,
.ant-calendar-header .ant-calendar-next-month-btn:hover::after {
  border-color: rgba(34, 34, 34, 0.8);
}
.ant-calendar-header .ant-calendar-next-month-btn::after {
  display: none;
}
.ant-calendar-header .ant-calendar-next-month-btn::before,
.ant-calendar-header .ant-calendar-next-month-btn::after {
  transform: rotate(135deg) scale(0.8);
}
.ant-calendar-body {
  padding: 8px 12px;
}
.ant-calendar table {
  width: 100%;
  max-width: 100%;
  background-color: transparent;
  border-collapse: collapse;
}
.ant-calendar table,
.ant-calendar th,
.ant-calendar td {
  text-align: center;
  border: 0;
}
.ant-calendar-calendar-table {
  margin-bottom: 0;
  border-spacing: 0;
}
.ant-calendar-column-header {
  width: 33px;
  padding: 6px 0;
  line-height: 18px;
  text-align: center;
}
.ant-calendar-column-header .ant-calendar-column-header-inner {
  display: block;
  font-weight: normal;
}
.ant-calendar-week-number-header .ant-calendar-column-header-inner {
  display: none;
}
.ant-calendar-cell {
  height: 30px;
  padding: 3px 0;
}
.ant-calendar-date {
  display: block;
  width: 24px;
  height: 24px;
  margin: 0 auto;
  padding: 0;
  color: rgba(34, 34, 34, 0.8);
  line-height: 22px;
  text-align: center;
  background: transparent;
  border: 1px solid transparent;
  border-radius: 10px;
  transition: background 0.3s ease;
}
.ant-calendar-date-panel {
  position: relative;
  outline: none;
}
.ant-calendar-date:hover {
  background: #f5f5f5;
  cursor: pointer;
}
.ant-calendar-date:active {
  color: #fff;
  background: #29bbff;
}
.ant-calendar-today .ant-calendar-date {
  color: #00a6ff;
  font-weight: bold;
  border-color: #00a6ff;
}
.ant-calendar-selected-day .ant-calendar-date {
  background: #a3ebff;
}
.ant-calendar-last-month-cell .ant-calendar-date,
.ant-calendar-next-month-btn-day .ant-calendar-date,
.ant-calendar-last-month-cell .ant-calendar-date:hover,
.ant-calendar-next-month-btn-day .ant-calendar-date:hover {
  color: #fff;
  background: transparent;
  border-color: transparent;
}
.ant-calendar-disabled-cell .ant-calendar-date {
  position: relative;
  width: auto;
  color: #fff;
  background: #cccccc;
  border: 1px solid transparent;
  border-radius: 0;
  cursor: not-allowed;
}
.ant-calendar-disabled-cell .ant-calendar-date:hover {
  background: #cccccc;
}
.ant-calendar-disabled-cell.ant-calendar-selected-day .ant-calendar-date::before {
  position: absolute;
  top: -1px;
  left: 5px;
  width: 24px;
  height: 24px;
  background: rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  content: '';
}
.ant-calendar-disabled-cell.ant-calendar-today .ant-calendar-date {
  position: relative;
  padding-right: 5px;
  padding-left: 5px;
}
.ant-calendar-disabled-cell.ant-calendar-today .ant-calendar-date::before {
  position: absolute;
  top: -1px;
  left: 5px;
  width: 24px;
  height: 24px;
  border: 1px solid #fff;
  border-radius: 10px;
  content: ' ';
}
.ant-calendar-disabled-cell-first-of-row .ant-calendar-date {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}
.ant-calendar-disabled-cell-last-of-row .ant-calendar-date {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}
.ant-calendar-footer {
  padding: 0 12px;
  line-height: 38px;
  border-top: 1px solid #f0f0f0;
}
.ant-calendar-footer:empty {
  border-top: 0;
}
.ant-calendar-footer-btn {
  display: block;
  text-align: center;
}
.ant-calendar-footer-extra {
  text-align: left;
}
.ant-calendar .ant-calendar-today-btn,
.ant-calendar .ant-calendar-clear-btn {
  display: inline-block;
  margin: 0 0 0 8px;
  text-align: center;
}
.ant-calendar .ant-calendar-today-btn-disabled,
.ant-calendar .ant-calendar-clear-btn-disabled {
  color: #fff;
  cursor: not-allowed;
}
.ant-calendar .ant-calendar-today-btn:only-child,
.ant-calendar .ant-calendar-clear-btn:only-child {
  margin: 0;
}
.ant-calendar .ant-calendar-clear-btn {
  position: absolute;
  top: 7px;
  right: 5px;
  display: none;
  width: 20px;
  height: 20px;
  margin: 0;
  overflow: hidden;
  line-height: 20px;
  text-align: center;
  text-indent: -76px;
}
.ant-calendar .ant-calendar-clear-btn::after {
  display: inline-block;
  width: 20px;
  color: #fff;
  font-size: 14px;
  line-height: 1;
  text-indent: 43px;
  transition: color 0.3s ease;
}
.ant-calendar .ant-calendar-clear-btn:hover::after {
  color: rgba(34, 34, 34, 0.45);
}
.ant-calendar .ant-calendar-ok-btn {
  position: relative;
  display: inline-block;
  font-weight: 400;
  white-space: nowrap;
  text-align: center;
  background-image: none;
  border: 1px solid transparent;
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.015);
  cursor: pointer;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  touch-action: manipulation;
  height: 32px;
  padding: 0 15px;
  color: #fff;
  background-color: #00a6ff;
  border-color: #00a6ff;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
  height: 24px;
  padding: 0 7px;
  font-size: 14px;
  border-radius: 10px;
  line-height: 22px;
}
.ant-calendar .ant-calendar-ok-btn > .anticon {
  line-height: 1;
}
.ant-calendar .ant-calendar-ok-btn,
.ant-calendar .ant-calendar-ok-btn:active,
.ant-calendar .ant-calendar-ok-btn:focus {
  outline: 0;
}
.ant-calendar .ant-calendar-ok-btn:not([disabled]):hover {
  text-decoration: none;
}
.ant-calendar .ant-calendar-ok-btn:not([disabled]):active {
  outline: 0;
  box-shadow: none;
}
.ant-calendar .ant-calendar-ok-btn.disabled,
.ant-calendar .ant-calendar-ok-btn[disabled] {
  cursor: not-allowed;
}
.ant-calendar .ant-calendar-ok-btn.disabled > *,
.ant-calendar .ant-calendar-ok-btn[disabled] > * {
  pointer-events: none;
}
.ant-calendar .ant-calendar-ok-btn-lg {
  height: 40px;
  padding: 0 15px;
  font-size: 14px;
  border-radius: 10px;
}
.ant-calendar .ant-calendar-ok-btn-sm {
  height: 24px;
  padding: 0 7px;
  font-size: 14px;
  border-radius: 10px;
}
.ant-calendar .ant-calendar-ok-btn > a:only-child {
  color: currentColor;
}
.ant-calendar .ant-calendar-ok-btn > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-calendar .ant-calendar-ok-btn:hover,
.ant-calendar .ant-calendar-ok-btn:focus {
  color: #fff;
  background-color: #29bbff;
  border-color: #29bbff;
}
.ant-calendar .ant-calendar-ok-btn:hover > a:only-child,
.ant-calendar .ant-calendar-ok-btn:focus > a:only-child {
  color: currentColor;
}
.ant-calendar .ant-calendar-ok-btn:hover > a:only-child::after,
.ant-calendar .ant-calendar-ok-btn:focus > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-calendar .ant-calendar-ok-btn:active,
.ant-calendar .ant-calendar-ok-btn.active {
  color: #fff;
  background-color: #0086d9;
  border-color: #0086d9;
}
.ant-calendar .ant-calendar-ok-btn:active > a:only-child,
.ant-calendar .ant-calendar-ok-btn.active > a:only-child {
  color: currentColor;
}
.ant-calendar .ant-calendar-ok-btn:active > a:only-child::after,
.ant-calendar .ant-calendar-ok-btn.active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-calendar .ant-calendar-ok-btn-disabled,
.ant-calendar .ant-calendar-ok-btn.disabled,
.ant-calendar .ant-calendar-ok-btn[disabled],
.ant-calendar .ant-calendar-ok-btn-disabled:hover,
.ant-calendar .ant-calendar-ok-btn.disabled:hover,
.ant-calendar .ant-calendar-ok-btn[disabled]:hover,
.ant-calendar .ant-calendar-ok-btn-disabled:focus,
.ant-calendar .ant-calendar-ok-btn.disabled:focus,
.ant-calendar .ant-calendar-ok-btn[disabled]:focus,
.ant-calendar .ant-calendar-ok-btn-disabled:active,
.ant-calendar .ant-calendar-ok-btn.disabled:active,
.ant-calendar .ant-calendar-ok-btn[disabled]:active,
.ant-calendar .ant-calendar-ok-btn-disabled.active,
.ant-calendar .ant-calendar-ok-btn.disabled.active,
.ant-calendar .ant-calendar-ok-btn[disabled].active {
  color: #fff;
  background-color: #cccccc;
  border-color: #bfbfbf;
  text-shadow: none;
  box-shadow: none;
}
.ant-calendar .ant-calendar-ok-btn-disabled > a:only-child,
.ant-calendar .ant-calendar-ok-btn.disabled > a:only-child,
.ant-calendar .ant-calendar-ok-btn[disabled] > a:only-child,
.ant-calendar .ant-calendar-ok-btn-disabled:hover > a:only-child,
.ant-calendar .ant-calendar-ok-btn.disabled:hover > a:only-child,
.ant-calendar .ant-calendar-ok-btn[disabled]:hover > a:only-child,
.ant-calendar .ant-calendar-ok-btn-disabled:focus > a:only-child,
.ant-calendar .ant-calendar-ok-btn.disabled:focus > a:only-child,
.ant-calendar .ant-calendar-ok-btn[disabled]:focus > a:only-child,
.ant-calendar .ant-calendar-ok-btn-disabled:active > a:only-child,
.ant-calendar .ant-calendar-ok-btn.disabled:active > a:only-child,
.ant-calendar .ant-calendar-ok-btn[disabled]:active > a:only-child,
.ant-calendar .ant-calendar-ok-btn-disabled.active > a:only-child,
.ant-calendar .ant-calendar-ok-btn.disabled.active > a:only-child,
.ant-calendar .ant-calendar-ok-btn[disabled].active > a:only-child {
  color: currentColor;
}
.ant-calendar .ant-calendar-ok-btn-disabled > a:only-child::after,
.ant-calendar .ant-calendar-ok-btn.disabled > a:only-child::after,
.ant-calendar .ant-calendar-ok-btn[disabled] > a:only-child::after,
.ant-calendar .ant-calendar-ok-btn-disabled:hover > a:only-child::after,
.ant-calendar .ant-calendar-ok-btn.disabled:hover > a:only-child::after,
.ant-calendar .ant-calendar-ok-btn[disabled]:hover > a:only-child::after,
.ant-calendar .ant-calendar-ok-btn-disabled:focus > a:only-child::after,
.ant-calendar .ant-calendar-ok-btn.disabled:focus > a:only-child::after,
.ant-calendar .ant-calendar-ok-btn[disabled]:focus > a:only-child::after,
.ant-calendar .ant-calendar-ok-btn-disabled:active > a:only-child::after,
.ant-calendar .ant-calendar-ok-btn.disabled:active > a:only-child::after,
.ant-calendar .ant-calendar-ok-btn[disabled]:active > a:only-child::after,
.ant-calendar .ant-calendar-ok-btn-disabled.active > a:only-child::after,
.ant-calendar .ant-calendar-ok-btn.disabled.active > a:only-child::after,
.ant-calendar .ant-calendar-ok-btn[disabled].active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-calendar .ant-calendar-ok-btn-disabled,
.ant-calendar .ant-calendar-ok-btn.disabled,
.ant-calendar .ant-calendar-ok-btn[disabled],
.ant-calendar .ant-calendar-ok-btn-disabled:hover,
.ant-calendar .ant-calendar-ok-btn.disabled:hover,
.ant-calendar .ant-calendar-ok-btn[disabled]:hover,
.ant-calendar .ant-calendar-ok-btn-disabled:focus,
.ant-calendar .ant-calendar-ok-btn.disabled:focus,
.ant-calendar .ant-calendar-ok-btn[disabled]:focus,
.ant-calendar .ant-calendar-ok-btn-disabled:active,
.ant-calendar .ant-calendar-ok-btn.disabled:active,
.ant-calendar .ant-calendar-ok-btn[disabled]:active,
.ant-calendar .ant-calendar-ok-btn-disabled.active,
.ant-calendar .ant-calendar-ok-btn.disabled.active,
.ant-calendar .ant-calendar-ok-btn[disabled].active {
  color: #fff;
  background-color: #cccccc;
  border-color: #bfbfbf;
  text-shadow: none;
  box-shadow: none;
}
.ant-calendar .ant-calendar-ok-btn-disabled > a:only-child,
.ant-calendar .ant-calendar-ok-btn.disabled > a:only-child,
.ant-calendar .ant-calendar-ok-btn[disabled] > a:only-child,
.ant-calendar .ant-calendar-ok-btn-disabled:hover > a:only-child,
.ant-calendar .ant-calendar-ok-btn.disabled:hover > a:only-child,
.ant-calendar .ant-calendar-ok-btn[disabled]:hover > a:only-child,
.ant-calendar .ant-calendar-ok-btn-disabled:focus > a:only-child,
.ant-calendar .ant-calendar-ok-btn.disabled:focus > a:only-child,
.ant-calendar .ant-calendar-ok-btn[disabled]:focus > a:only-child,
.ant-calendar .ant-calendar-ok-btn-disabled:active > a:only-child,
.ant-calendar .ant-calendar-ok-btn.disabled:active > a:only-child,
.ant-calendar .ant-calendar-ok-btn[disabled]:active > a:only-child,
.ant-calendar .ant-calendar-ok-btn-disabled.active > a:only-child,
.ant-calendar .ant-calendar-ok-btn.disabled.active > a:only-child,
.ant-calendar .ant-calendar-ok-btn[disabled].active > a:only-child {
  color: currentColor;
}
.ant-calendar .ant-calendar-ok-btn-disabled > a:only-child::after,
.ant-calendar .ant-calendar-ok-btn.disabled > a:only-child::after,
.ant-calendar .ant-calendar-ok-btn[disabled] > a:only-child::after,
.ant-calendar .ant-calendar-ok-btn-disabled:hover > a:only-child::after,
.ant-calendar .ant-calendar-ok-btn.disabled:hover > a:only-child::after,
.ant-calendar .ant-calendar-ok-btn[disabled]:hover > a:only-child::after,
.ant-calendar .ant-calendar-ok-btn-disabled:focus > a:only-child::after,
.ant-calendar .ant-calendar-ok-btn.disabled:focus > a:only-child::after,
.ant-calendar .ant-calendar-ok-btn[disabled]:focus > a:only-child::after,
.ant-calendar .ant-calendar-ok-btn-disabled:active > a:only-child::after,
.ant-calendar .ant-calendar-ok-btn.disabled:active > a:only-child::after,
.ant-calendar .ant-calendar-ok-btn[disabled]:active > a:only-child::after,
.ant-calendar .ant-calendar-ok-btn-disabled.active > a:only-child::after,
.ant-calendar .ant-calendar-ok-btn.disabled.active > a:only-child::after,
.ant-calendar .ant-calendar-ok-btn[disabled].active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-calendar-range-picker-input {
  width: 44%;
  height: 99%;
  text-align: center;
  background-color: transparent;
  border: 0;
  outline: 0;
}
.ant-calendar-range-picker-input::-moz-placeholder {
  color: #bfbfbf;
  opacity: 1;
}
.ant-calendar-range-picker-input:-ms-input-placeholder {
  color: #bfbfbf;
}
.ant-calendar-range-picker-input::-webkit-input-placeholder {
  color: #bfbfbf;
}
.ant-calendar-range-picker-input:-moz-placeholder-shown {
  text-overflow: ellipsis;
}
.ant-calendar-range-picker-input:-ms-input-placeholder {
  text-overflow: ellipsis;
}
.ant-calendar-range-picker-input:placeholder-shown {
  text-overflow: ellipsis;
}
.ant-calendar-range-picker-input[disabled] {
  cursor: not-allowed;
}
.ant-calendar-range-picker-separator {
  display: inline-block;
  min-width: 10px;
  height: 100%;
  color: rgba(34, 34, 34, 0.45);
  white-space: nowrap;
  text-align: center;
  vertical-align: top;
  pointer-events: none;
}
.ant-calendar-range {
  width: 552px;
  overflow: hidden;
}
.ant-calendar-range .ant-calendar-date-panel::after {
  display: block;
  clear: both;
  height: 0;
  visibility: hidden;
  content: '.';
}
.ant-calendar-range-part {
  position: relative;
  width: 50%;
}
.ant-calendar-range-left {
  float: left;
}
.ant-calendar-range-left .ant-calendar-time-picker-inner {
  border-right: 1px solid #f0f0f0;
}
.ant-calendar-range-right {
  float: right;
}
.ant-calendar-range-right .ant-calendar-time-picker-inner {
  border-left: 1px solid #f0f0f0;
}
.ant-calendar-range-middle {
  position: absolute;
  left: 50%;
  z-index: 1;
  height: 34px;
  margin: 1px 0 0 0;
  padding: 0 200px 0 0;
  color: rgba(34, 34, 34, 0.45);
  line-height: 34px;
  text-align: center;
  transform: translateX(-50%);
  pointer-events: none;
}
.ant-calendar-range-right .ant-calendar-date-input-wrap {
  margin-left: -90px;
}
.ant-calendar-range.ant-calendar-time .ant-calendar-range-middle {
  padding: 0 10px 0 0;
  transform: translateX(-50%);
}
.ant-calendar-range .ant-calendar-today :not(.ant-calendar-disabled-cell) :not(.ant-calendar-last-month-cell) :not(.ant-calendar-next-month-btn-day) .ant-calendar-date {
  color: #00a6ff;
  background: #a3ebff;
  border-color: #00a6ff;
}
.ant-calendar-range .ant-calendar-selected-start-date .ant-calendar-date,
.ant-calendar-range .ant-calendar-selected-end-date .ant-calendar-date {
  color: #fff;
  background: #00a6ff;
  border: 1px solid transparent;
}
.ant-calendar-range .ant-calendar-selected-start-date .ant-calendar-date:hover,
.ant-calendar-range .ant-calendar-selected-end-date .ant-calendar-date:hover {
  background: #00a6ff;
}
.ant-calendar-range.ant-calendar-time .ant-calendar-range-right .ant-calendar-date-input-wrap {
  margin-left: 0;
}
.ant-calendar-range .ant-calendar-input-wrap {
  position: relative;
  height: 34px;
}
.ant-calendar-range .ant-calendar-input,
.ant-calendar-range .ant-calendar-time-picker-input {
  position: relative;
  display: inline-block;
  width: 100%;
  height: 32px;
  padding: 4px 11px;
  color: rgba(34, 34, 34, 0.8);
  font-size: 14px;
  line-height: 1.5715;
  background-color: #fff;
  background-image: none;
  border: 1px solid #bfbfbf;
  border-radius: 10px;
  transition: all 0.3s;
  height: 24px;
  padding-right: 0;
  padding-left: 0;
  line-height: 24px;
  border: 0;
  box-shadow: none;
}
.ant-calendar-range .ant-calendar-input::-moz-placeholder,
.ant-calendar-range .ant-calendar-time-picker-input::-moz-placeholder {
  color: #bfbfbf;
  opacity: 1;
}
.ant-calendar-range .ant-calendar-input:-ms-input-placeholder,
.ant-calendar-range .ant-calendar-time-picker-input:-ms-input-placeholder {
  color: #bfbfbf;
}
.ant-calendar-range .ant-calendar-input::-webkit-input-placeholder,
.ant-calendar-range .ant-calendar-time-picker-input::-webkit-input-placeholder {
  color: #bfbfbf;
}
.ant-calendar-range .ant-calendar-input:-moz-placeholder-shown, .ant-calendar-range .ant-calendar-time-picker-input:-moz-placeholder-shown {
  text-overflow: ellipsis;
}
.ant-calendar-range .ant-calendar-input:-ms-input-placeholder, .ant-calendar-range .ant-calendar-time-picker-input:-ms-input-placeholder {
  text-overflow: ellipsis;
}
.ant-calendar-range .ant-calendar-input:placeholder-shown,
.ant-calendar-range .ant-calendar-time-picker-input:placeholder-shown {
  text-overflow: ellipsis;
}
.ant-calendar-range .ant-calendar-input:hover,
.ant-calendar-range .ant-calendar-time-picker-input:hover {
  border-color: #333;
  border-right-width: 1px !important;
}
.ant-calendar-range .ant-calendar-input:focus,
.ant-calendar-range .ant-calendar-time-picker-input:focus {
  border-color: #666060;
  border-right-width: 1px !important;
  outline: 0;
  box-shadow: 0 0 0 1px rgba(89, 89, 89, 0.2);
}
.ant-calendar-range .ant-calendar-input-disabled,
.ant-calendar-range .ant-calendar-time-picker-input-disabled {
  color: #fff;
  background-color: #cccccc;
  cursor: not-allowed;
  opacity: 1;
}
.ant-calendar-range .ant-calendar-input-disabled:hover,
.ant-calendar-range .ant-calendar-time-picker-input-disabled:hover {
  border-color: #bfbfbf;
  border-right-width: 1px !important;
}
.ant-calendar-range .ant-calendar-input[disabled],
.ant-calendar-range .ant-calendar-time-picker-input[disabled] {
  color: #fff;
  background-color: #cccccc;
  cursor: not-allowed;
  opacity: 1;
}
.ant-calendar-range .ant-calendar-input[disabled]:hover,
.ant-calendar-range .ant-calendar-time-picker-input[disabled]:hover {
  border-color: #bfbfbf;
  border-right-width: 1px !important;
}
textarea.ant-calendar-range .ant-calendar-input,
textarea.ant-calendar-range .ant-calendar-time-picker-input {
  max-width: 100%;
  height: auto;
  min-height: 32px;
  line-height: 1.5715;
  vertical-align: bottom;
  transition: all 0.3s, height 0s;
}
.ant-calendar-range .ant-calendar-input-lg,
.ant-calendar-range .ant-calendar-time-picker-input-lg {
  height: 45px;
  padding: 10.5px 11px;
  font-size: 14px;
}
.ant-calendar-range .ant-calendar-input-sm,
.ant-calendar-range .ant-calendar-time-picker-input-sm {
  height: 24px;
  padding: 0px 7px;
}
.ant-calendar-range .ant-calendar-input:focus,
.ant-calendar-range .ant-calendar-time-picker-input:focus {
  box-shadow: none;
}
.ant-calendar-range .ant-calendar-time-picker-icon {
  display: none;
}
.ant-calendar-range.ant-calendar-week-number {
  width: 574px;
}
.ant-calendar-range.ant-calendar-week-number .ant-calendar-range-part {
  width: 286px;
}
.ant-calendar-range .ant-calendar-year-panel,
.ant-calendar-range .ant-calendar-month-panel,
.ant-calendar-range .ant-calendar-decade-panel {
  top: 34px;
}
.ant-calendar-range .ant-calendar-month-panel .ant-calendar-year-panel {
  top: 0;
}
.ant-calendar-range .ant-calendar-decade-panel-table,
.ant-calendar-range .ant-calendar-year-panel-table,
.ant-calendar-range .ant-calendar-month-panel-table {
  height: 208px;
}
.ant-calendar-range .ant-calendar-in-range-cell {
  position: relative;
  border-radius: 0;
}
.ant-calendar-range .ant-calendar-in-range-cell > div {
  position: relative;
  z-index: 1;
}
.ant-calendar-range .ant-calendar-in-range-cell::before {
  position: absolute;
  top: 4px;
  right: 0;
  bottom: 4px;
  left: 0;
  display: block;
  background: #e6faff;
  border: 0;
  border-radius: 0;
  content: '';
}
.ant-calendar-range .ant-calendar-footer-extra {
  float: left;
}
div.ant-calendar-range-quick-selector {
  text-align: left;
}
div.ant-calendar-range-quick-selector > a {
  margin-right: 8px;
}
.ant-calendar-range .ant-calendar-header,
.ant-calendar-range .ant-calendar-month-panel-header,
.ant-calendar-range .ant-calendar-year-panel-header,
.ant-calendar-range .ant-calendar-decade-panel-header {
  border-bottom: 0;
}
.ant-calendar-range .ant-calendar-body,
.ant-calendar-range .ant-calendar-month-panel-body,
.ant-calendar-range .ant-calendar-year-panel-body,
.ant-calendar-range .ant-calendar-decade-panel-body {
  border-top: 1px solid #f0f0f0;
}
.ant-calendar-range.ant-calendar-time .ant-calendar-time-picker {
  top: 68px;
  z-index: 2;
  width: 100%;
  height: 207px;
}
.ant-calendar-range.ant-calendar-time .ant-calendar-time-picker-panel {
  height: 267px;
  margin-top: -34px;
}
.ant-calendar-range.ant-calendar-time .ant-calendar-time-picker-inner {
  height: 100%;
  padding-top: 40px;
  background: none;
}
.ant-calendar-range.ant-calendar-time .ant-calendar-time-picker-combobox {
  display: inline-block;
  height: 100%;
  background-color: #fff;
  border-top: 1px solid #f0f0f0;
}
.ant-calendar-range.ant-calendar-time .ant-calendar-time-picker-select {
  height: 100%;
}
.ant-calendar-range.ant-calendar-time .ant-calendar-time-picker-select ul {
  max-height: 100%;
}
.ant-calendar-range.ant-calendar-time .ant-calendar-footer .ant-calendar-time-picker-btn {
  margin-right: 8px;
}
.ant-calendar-range.ant-calendar-time .ant-calendar-today-btn {
  height: 22px;
  margin: 8px 12px;
  line-height: 22px;
}
.ant-calendar-range-with-ranges.ant-calendar-time .ant-calendar-time-picker {
  height: 233px;
}
.ant-calendar-range.ant-calendar-show-time-picker .ant-calendar-body {
  border-top-color: transparent;
}
.ant-calendar-time-picker {
  position: absolute;
  top: 40px;
  width: 100%;
  background-color: #fff;
}
.ant-calendar-time-picker-panel {
  position: absolute;
  z-index: 9999;
  width: 100%;
}
.ant-calendar-time-picker-inner {
  position: relative;
  display: inline-block;
  width: 100%;
  overflow: hidden;
  font-size: 14px;
  line-height: 1.5;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  outline: none;
}
.ant-calendar-time-picker-combobox {
  width: 100%;
}
.ant-calendar-time-picker-column-1,
.ant-calendar-time-picker-column-1 .ant-calendar-time-picker-select {
  width: 100%;
}
.ant-calendar-time-picker-column-2 .ant-calendar-time-picker-select {
  width: 50%;
}
.ant-calendar-time-picker-column-3 .ant-calendar-time-picker-select {
  width: 33.33%;
}
.ant-calendar-time-picker-column-4 .ant-calendar-time-picker-select {
  width: 25%;
}
.ant-calendar-time-picker-input-wrap {
  display: none;
}
.ant-calendar-time-picker-select {
  position: relative;
  float: left;
  height: 226px;
  overflow: hidden;
  font-size: 14px;
  border-right: 1px solid #f0f0f0;
}
.ant-calendar-time-picker-select:hover {
  overflow-y: auto;
}
.ant-calendar-time-picker-select:first-child {
  margin-left: 0;
  border-left: 0;
}
.ant-calendar-time-picker-select:last-child {
  border-right: 0;
}
.ant-calendar-time-picker-select ul {
  width: 100%;
  max-height: 206px;
  margin: 0;
  padding: 0;
  list-style: none;
}
.ant-calendar-time-picker-select li {
  width: 100%;
  height: 24px;
  margin: 0;
  line-height: 24px;
  text-align: center;
  list-style: none;
  cursor: pointer;
  transition: all 0.3s;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.ant-calendar-time-picker-select li:last-child::after {
  display: block;
  height: 202px;
  content: '';
}
.ant-calendar-time-picker-select li:hover {
  background: #f5f5f5;
}
.ant-calendar-time-picker-select li:focus {
  color: #00a6ff;
  font-weight: 600;
  outline: none;
}
li.ant-calendar-time-picker-select-option-selected {
  font-weight: 600;
  background: #cccccc;
}
li.ant-calendar-time-picker-select-option-disabled {
  color: #fff;
}
li.ant-calendar-time-picker-select-option-disabled:hover {
  background: transparent;
  cursor: not-allowed;
}
.ant-calendar-time .ant-calendar-day-select {
  display: inline-block;
  padding: 0 2px;
  color: rgba(255, 255, 255, 0.85);
  font-weight: 500;
  line-height: 34px;
}
.ant-calendar-time .ant-calendar-footer {
  position: relative;
  height: auto;
}
.ant-calendar-time .ant-calendar-footer-btn {
  text-align: right;
}
.ant-calendar-time .ant-calendar-footer .ant-calendar-today-btn {
  float: left;
  margin: 0;
}
.ant-calendar-time .ant-calendar-footer .ant-calendar-time-picker-btn {
  display: inline-block;
  margin-right: 8px;
}
.ant-calendar-time .ant-calendar-footer .ant-calendar-time-picker-btn-disabled {
  color: #fff;
}
.ant-calendar-month-panel {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 10;
  background: #fff;
  border-radius: 10px;
  outline: none;
}
.ant-calendar-month-panel > div {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.ant-calendar-month-panel-hidden {
  display: none;
}
.ant-calendar-month-panel-header {
  height: 40px;
  line-height: 40px;
  text-align: center;
  border-bottom: 1px solid #f0f0f0;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  position: relative;
}
.ant-calendar-month-panel-header a:hover {
  color: #45a8ff;
}
.ant-calendar-month-panel-header .ant-calendar-month-panel-century-select,
.ant-calendar-month-panel-header .ant-calendar-month-panel-decade-select,
.ant-calendar-month-panel-header .ant-calendar-month-panel-year-select,
.ant-calendar-month-panel-header .ant-calendar-month-panel-month-select {
  display: inline-block;
  padding: 0 2px;
  color: rgba(255, 255, 255, 0.85);
  font-weight: 500;
  line-height: 40px;
}
.ant-calendar-month-panel-header .ant-calendar-month-panel-century-select-arrow,
.ant-calendar-month-panel-header .ant-calendar-month-panel-decade-select-arrow,
.ant-calendar-month-panel-header .ant-calendar-month-panel-year-select-arrow,
.ant-calendar-month-panel-header .ant-calendar-month-panel-month-select-arrow {
  display: none;
}
.ant-calendar-month-panel-header .ant-calendar-month-panel-prev-century-btn,
.ant-calendar-month-panel-header .ant-calendar-month-panel-next-century-btn,
.ant-calendar-month-panel-header .ant-calendar-month-panel-prev-decade-btn,
.ant-calendar-month-panel-header .ant-calendar-month-panel-next-decade-btn,
.ant-calendar-month-panel-header .ant-calendar-month-panel-prev-month-btn,
.ant-calendar-month-panel-header .ant-calendar-month-panel-next-month-btn,
.ant-calendar-month-panel-header .ant-calendar-month-panel-prev-year-btn,
.ant-calendar-month-panel-header .ant-calendar-month-panel-next-year-btn {
  position: absolute;
  top: 0;
  display: inline-block;
  padding: 0 5px;
  color: rgba(34, 34, 34, 0.45);
  font-size: 16px;
  font-family: Arial, 'Hiragino Sans GB', 'Microsoft Yahei', 'Microsoft Sans Serif', sans-serif;
  line-height: 40px;
}
.ant-calendar-month-panel-header .ant-calendar-month-panel-prev-century-btn,
.ant-calendar-month-panel-header .ant-calendar-month-panel-prev-decade-btn,
.ant-calendar-month-panel-header .ant-calendar-month-panel-prev-year-btn {
  left: 7px;
  height: 100%;
}
.ant-calendar-month-panel-header .ant-calendar-month-panel-prev-century-btn::before,
.ant-calendar-month-panel-header .ant-calendar-month-panel-prev-decade-btn::before,
.ant-calendar-month-panel-header .ant-calendar-month-panel-prev-year-btn::before,
.ant-calendar-month-panel-header .ant-calendar-month-panel-prev-century-btn::after,
.ant-calendar-month-panel-header .ant-calendar-month-panel-prev-decade-btn::after,
.ant-calendar-month-panel-header .ant-calendar-month-panel-prev-year-btn::after {
  position: relative;
  top: -1px;
  display: inline-block;
  width: 8px;
  height: 8px;
  vertical-align: middle;
  border: 0 solid #aaa;
  border-width: 1.5px 0 0 1.5px;
  border-radius: 1px;
  transform: rotate(-45deg) scale(0.8);
  transition: all 0.3s;
  content: '';
}
.ant-calendar-month-panel-header .ant-calendar-month-panel-prev-century-btn:hover::before,
.ant-calendar-month-panel-header .ant-calendar-month-panel-prev-decade-btn:hover::before,
.ant-calendar-month-panel-header .ant-calendar-month-panel-prev-year-btn:hover::before,
.ant-calendar-month-panel-header .ant-calendar-month-panel-prev-century-btn:hover::after,
.ant-calendar-month-panel-header .ant-calendar-month-panel-prev-decade-btn:hover::after,
.ant-calendar-month-panel-header .ant-calendar-month-panel-prev-year-btn:hover::after {
  border-color: rgba(34, 34, 34, 0.8);
}
.ant-calendar-month-panel-header .ant-calendar-month-panel-prev-century-btn::after,
.ant-calendar-month-panel-header .ant-calendar-month-panel-prev-decade-btn::after,
.ant-calendar-month-panel-header .ant-calendar-month-panel-prev-year-btn::after {
  display: none;
}
.ant-calendar-month-panel-header .ant-calendar-month-panel-prev-century-btn::after,
.ant-calendar-month-panel-header .ant-calendar-month-panel-prev-decade-btn::after,
.ant-calendar-month-panel-header .ant-calendar-month-panel-prev-year-btn::after {
  position: relative;
  left: -3px;
  display: inline-block;
}
.ant-calendar-month-panel-header .ant-calendar-month-panel-next-century-btn,
.ant-calendar-month-panel-header .ant-calendar-month-panel-next-decade-btn,
.ant-calendar-month-panel-header .ant-calendar-month-panel-next-year-btn {
  right: 7px;
  height: 100%;
}
.ant-calendar-month-panel-header .ant-calendar-month-panel-next-century-btn::before,
.ant-calendar-month-panel-header .ant-calendar-month-panel-next-decade-btn::before,
.ant-calendar-month-panel-header .ant-calendar-month-panel-next-year-btn::before,
.ant-calendar-month-panel-header .ant-calendar-month-panel-next-century-btn::after,
.ant-calendar-month-panel-header .ant-calendar-month-panel-next-decade-btn::after,
.ant-calendar-month-panel-header .ant-calendar-month-panel-next-year-btn::after {
  position: relative;
  top: -1px;
  display: inline-block;
  width: 8px;
  height: 8px;
  vertical-align: middle;
  border: 0 solid #aaa;
  border-width: 1.5px 0 0 1.5px;
  border-radius: 1px;
  transform: rotate(-45deg) scale(0.8);
  transition: all 0.3s;
  content: '';
}
.ant-calendar-month-panel-header .ant-calendar-month-panel-next-century-btn:hover::before,
.ant-calendar-month-panel-header .ant-calendar-month-panel-next-decade-btn:hover::before,
.ant-calendar-month-panel-header .ant-calendar-month-panel-next-year-btn:hover::before,
.ant-calendar-month-panel-header .ant-calendar-month-panel-next-century-btn:hover::after,
.ant-calendar-month-panel-header .ant-calendar-month-panel-next-decade-btn:hover::after,
.ant-calendar-month-panel-header .ant-calendar-month-panel-next-year-btn:hover::after {
  border-color: rgba(34, 34, 34, 0.8);
}
.ant-calendar-month-panel-header .ant-calendar-month-panel-next-century-btn::after,
.ant-calendar-month-panel-header .ant-calendar-month-panel-next-decade-btn::after,
.ant-calendar-month-panel-header .ant-calendar-month-panel-next-year-btn::after {
  display: none;
}
.ant-calendar-month-panel-header .ant-calendar-month-panel-next-century-btn::before,
.ant-calendar-month-panel-header .ant-calendar-month-panel-next-decade-btn::before,
.ant-calendar-month-panel-header .ant-calendar-month-panel-next-year-btn::before,
.ant-calendar-month-panel-header .ant-calendar-month-panel-next-century-btn::after,
.ant-calendar-month-panel-header .ant-calendar-month-panel-next-decade-btn::after,
.ant-calendar-month-panel-header .ant-calendar-month-panel-next-year-btn::after {
  transform: rotate(135deg) scale(0.8);
}
.ant-calendar-month-panel-header .ant-calendar-month-panel-next-century-btn::before,
.ant-calendar-month-panel-header .ant-calendar-month-panel-next-decade-btn::before,
.ant-calendar-month-panel-header .ant-calendar-month-panel-next-year-btn::before {
  position: relative;
  left: 3px;
}
.ant-calendar-month-panel-header .ant-calendar-month-panel-next-century-btn::after,
.ant-calendar-month-panel-header .ant-calendar-month-panel-next-decade-btn::after,
.ant-calendar-month-panel-header .ant-calendar-month-panel-next-year-btn::after {
  display: inline-block;
}
.ant-calendar-month-panel-header .ant-calendar-month-panel-prev-month-btn {
  left: 29px;
  height: 100%;
}
.ant-calendar-month-panel-header .ant-calendar-month-panel-prev-month-btn::before,
.ant-calendar-month-panel-header .ant-calendar-month-panel-prev-month-btn::after {
  position: relative;
  top: -1px;
  display: inline-block;
  width: 8px;
  height: 8px;
  vertical-align: middle;
  border: 0 solid #aaa;
  border-width: 1.5px 0 0 1.5px;
  border-radius: 1px;
  transform: rotate(-45deg) scale(0.8);
  transition: all 0.3s;
  content: '';
}
.ant-calendar-month-panel-header .ant-calendar-month-panel-prev-month-btn:hover::before,
.ant-calendar-month-panel-header .ant-calendar-month-panel-prev-month-btn:hover::after {
  border-color: rgba(34, 34, 34, 0.8);
}
.ant-calendar-month-panel-header .ant-calendar-month-panel-prev-month-btn::after {
  display: none;
}
.ant-calendar-month-panel-header .ant-calendar-month-panel-next-month-btn {
  right: 29px;
  height: 100%;
}
.ant-calendar-month-panel-header .ant-calendar-month-panel-next-month-btn::before,
.ant-calendar-month-panel-header .ant-calendar-month-panel-next-month-btn::after {
  position: relative;
  top: -1px;
  display: inline-block;
  width: 8px;
  height: 8px;
  vertical-align: middle;
  border: 0 solid #aaa;
  border-width: 1.5px 0 0 1.5px;
  border-radius: 1px;
  transform: rotate(-45deg) scale(0.8);
  transition: all 0.3s;
  content: '';
}
.ant-calendar-month-panel-header .ant-calendar-month-panel-next-month-btn:hover::before,
.ant-calendar-month-panel-header .ant-calendar-month-panel-next-month-btn:hover::after {
  border-color: rgba(34, 34, 34, 0.8);
}
.ant-calendar-month-panel-header .ant-calendar-month-panel-next-month-btn::after {
  display: none;
}
.ant-calendar-month-panel-header .ant-calendar-month-panel-next-month-btn::before,
.ant-calendar-month-panel-header .ant-calendar-month-panel-next-month-btn::after {
  transform: rotate(135deg) scale(0.8);
}
.ant-calendar-month-panel-body {
  flex: 1 1;
}
.ant-calendar-month-panel-footer {
  border-top: 1px solid #f0f0f0;
}
.ant-calendar-month-panel-footer .ant-calendar-footer-extra {
  padding: 0 12px;
}
.ant-calendar-month-panel-table {
  width: 100%;
  height: 100%;
  table-layout: fixed;
  border-collapse: separate;
}
.ant-calendar-month-panel-selected-cell .ant-calendar-month-panel-month {
  color: #fff;
  background: #00a6ff;
}
.ant-calendar-month-panel-selected-cell .ant-calendar-month-panel-month:hover {
  color: #fff;
  background: #00a6ff;
}
.ant-calendar-month-panel-cell {
  text-align: center;
}
.ant-calendar-month-panel-cell-disabled .ant-calendar-month-panel-month,
.ant-calendar-month-panel-cell-disabled .ant-calendar-month-panel-month:hover {
  color: #fff;
  background: #cccccc;
  cursor: not-allowed;
}
.ant-calendar-month-panel-month {
  display: inline-block;
  height: 24px;
  margin: 0 auto;
  padding: 0 8px;
  color: rgba(34, 34, 34, 0.8);
  line-height: 24px;
  text-align: center;
  background: transparent;
  border-radius: 10px;
  transition: background 0.3s ease;
}
.ant-calendar-month-panel-month:hover {
  background: #f5f5f5;
  cursor: pointer;
}
.ant-calendar-year-panel {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 10;
  background: #fff;
  border-radius: 10px;
  outline: none;
}
.ant-calendar-year-panel > div {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.ant-calendar-year-panel-hidden {
  display: none;
}
.ant-calendar-year-panel-header {
  height: 40px;
  line-height: 40px;
  text-align: center;
  border-bottom: 1px solid #f0f0f0;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  position: relative;
}
.ant-calendar-year-panel-header a:hover {
  color: #45a8ff;
}
.ant-calendar-year-panel-header .ant-calendar-year-panel-century-select,
.ant-calendar-year-panel-header .ant-calendar-year-panel-decade-select,
.ant-calendar-year-panel-header .ant-calendar-year-panel-year-select,
.ant-calendar-year-panel-header .ant-calendar-year-panel-month-select {
  display: inline-block;
  padding: 0 2px;
  color: rgba(255, 255, 255, 0.85);
  font-weight: 500;
  line-height: 40px;
}
.ant-calendar-year-panel-header .ant-calendar-year-panel-century-select-arrow,
.ant-calendar-year-panel-header .ant-calendar-year-panel-decade-select-arrow,
.ant-calendar-year-panel-header .ant-calendar-year-panel-year-select-arrow,
.ant-calendar-year-panel-header .ant-calendar-year-panel-month-select-arrow {
  display: none;
}
.ant-calendar-year-panel-header .ant-calendar-year-panel-prev-century-btn,
.ant-calendar-year-panel-header .ant-calendar-year-panel-next-century-btn,
.ant-calendar-year-panel-header .ant-calendar-year-panel-prev-decade-btn,
.ant-calendar-year-panel-header .ant-calendar-year-panel-next-decade-btn,
.ant-calendar-year-panel-header .ant-calendar-year-panel-prev-month-btn,
.ant-calendar-year-panel-header .ant-calendar-year-panel-next-month-btn,
.ant-calendar-year-panel-header .ant-calendar-year-panel-prev-year-btn,
.ant-calendar-year-panel-header .ant-calendar-year-panel-next-year-btn {
  position: absolute;
  top: 0;
  display: inline-block;
  padding: 0 5px;
  color: rgba(34, 34, 34, 0.45);
  font-size: 16px;
  font-family: Arial, 'Hiragino Sans GB', 'Microsoft Yahei', 'Microsoft Sans Serif', sans-serif;
  line-height: 40px;
}
.ant-calendar-year-panel-header .ant-calendar-year-panel-prev-century-btn,
.ant-calendar-year-panel-header .ant-calendar-year-panel-prev-decade-btn,
.ant-calendar-year-panel-header .ant-calendar-year-panel-prev-year-btn {
  left: 7px;
  height: 100%;
}
.ant-calendar-year-panel-header .ant-calendar-year-panel-prev-century-btn::before,
.ant-calendar-year-panel-header .ant-calendar-year-panel-prev-decade-btn::before,
.ant-calendar-year-panel-header .ant-calendar-year-panel-prev-year-btn::before,
.ant-calendar-year-panel-header .ant-calendar-year-panel-prev-century-btn::after,
.ant-calendar-year-panel-header .ant-calendar-year-panel-prev-decade-btn::after,
.ant-calendar-year-panel-header .ant-calendar-year-panel-prev-year-btn::after {
  position: relative;
  top: -1px;
  display: inline-block;
  width: 8px;
  height: 8px;
  vertical-align: middle;
  border: 0 solid #aaa;
  border-width: 1.5px 0 0 1.5px;
  border-radius: 1px;
  transform: rotate(-45deg) scale(0.8);
  transition: all 0.3s;
  content: '';
}
.ant-calendar-year-panel-header .ant-calendar-year-panel-prev-century-btn:hover::before,
.ant-calendar-year-panel-header .ant-calendar-year-panel-prev-decade-btn:hover::before,
.ant-calendar-year-panel-header .ant-calendar-year-panel-prev-year-btn:hover::before,
.ant-calendar-year-panel-header .ant-calendar-year-panel-prev-century-btn:hover::after,
.ant-calendar-year-panel-header .ant-calendar-year-panel-prev-decade-btn:hover::after,
.ant-calendar-year-panel-header .ant-calendar-year-panel-prev-year-btn:hover::after {
  border-color: rgba(34, 34, 34, 0.8);
}
.ant-calendar-year-panel-header .ant-calendar-year-panel-prev-century-btn::after,
.ant-calendar-year-panel-header .ant-calendar-year-panel-prev-decade-btn::after,
.ant-calendar-year-panel-header .ant-calendar-year-panel-prev-year-btn::after {
  display: none;
}
.ant-calendar-year-panel-header .ant-calendar-year-panel-prev-century-btn::after,
.ant-calendar-year-panel-header .ant-calendar-year-panel-prev-decade-btn::after,
.ant-calendar-year-panel-header .ant-calendar-year-panel-prev-year-btn::after {
  position: relative;
  left: -3px;
  display: inline-block;
}
.ant-calendar-year-panel-header .ant-calendar-year-panel-next-century-btn,
.ant-calendar-year-panel-header .ant-calendar-year-panel-next-decade-btn,
.ant-calendar-year-panel-header .ant-calendar-year-panel-next-year-btn {
  right: 7px;
  height: 100%;
}
.ant-calendar-year-panel-header .ant-calendar-year-panel-next-century-btn::before,
.ant-calendar-year-panel-header .ant-calendar-year-panel-next-decade-btn::before,
.ant-calendar-year-panel-header .ant-calendar-year-panel-next-year-btn::before,
.ant-calendar-year-panel-header .ant-calendar-year-panel-next-century-btn::after,
.ant-calendar-year-panel-header .ant-calendar-year-panel-next-decade-btn::after,
.ant-calendar-year-panel-header .ant-calendar-year-panel-next-year-btn::after {
  position: relative;
  top: -1px;
  display: inline-block;
  width: 8px;
  height: 8px;
  vertical-align: middle;
  border: 0 solid #aaa;
  border-width: 1.5px 0 0 1.5px;
  border-radius: 1px;
  transform: rotate(-45deg) scale(0.8);
  transition: all 0.3s;
  content: '';
}
.ant-calendar-year-panel-header .ant-calendar-year-panel-next-century-btn:hover::before,
.ant-calendar-year-panel-header .ant-calendar-year-panel-next-decade-btn:hover::before,
.ant-calendar-year-panel-header .ant-calendar-year-panel-next-year-btn:hover::before,
.ant-calendar-year-panel-header .ant-calendar-year-panel-next-century-btn:hover::after,
.ant-calendar-year-panel-header .ant-calendar-year-panel-next-decade-btn:hover::after,
.ant-calendar-year-panel-header .ant-calendar-year-panel-next-year-btn:hover::after {
  border-color: rgba(34, 34, 34, 0.8);
}
.ant-calendar-year-panel-header .ant-calendar-year-panel-next-century-btn::after,
.ant-calendar-year-panel-header .ant-calendar-year-panel-next-decade-btn::after,
.ant-calendar-year-panel-header .ant-calendar-year-panel-next-year-btn::after {
  display: none;
}
.ant-calendar-year-panel-header .ant-calendar-year-panel-next-century-btn::before,
.ant-calendar-year-panel-header .ant-calendar-year-panel-next-decade-btn::before,
.ant-calendar-year-panel-header .ant-calendar-year-panel-next-year-btn::before,
.ant-calendar-year-panel-header .ant-calendar-year-panel-next-century-btn::after,
.ant-calendar-year-panel-header .ant-calendar-year-panel-next-decade-btn::after,
.ant-calendar-year-panel-header .ant-calendar-year-panel-next-year-btn::after {
  transform: rotate(135deg) scale(0.8);
}
.ant-calendar-year-panel-header .ant-calendar-year-panel-next-century-btn::before,
.ant-calendar-year-panel-header .ant-calendar-year-panel-next-decade-btn::before,
.ant-calendar-year-panel-header .ant-calendar-year-panel-next-year-btn::before {
  position: relative;
  left: 3px;
}
.ant-calendar-year-panel-header .ant-calendar-year-panel-next-century-btn::after,
.ant-calendar-year-panel-header .ant-calendar-year-panel-next-decade-btn::after,
.ant-calendar-year-panel-header .ant-calendar-year-panel-next-year-btn::after {
  display: inline-block;
}
.ant-calendar-year-panel-header .ant-calendar-year-panel-prev-month-btn {
  left: 29px;
  height: 100%;
}
.ant-calendar-year-panel-header .ant-calendar-year-panel-prev-month-btn::before,
.ant-calendar-year-panel-header .ant-calendar-year-panel-prev-month-btn::after {
  position: relative;
  top: -1px;
  display: inline-block;
  width: 8px;
  height: 8px;
  vertical-align: middle;
  border: 0 solid #aaa;
  border-width: 1.5px 0 0 1.5px;
  border-radius: 1px;
  transform: rotate(-45deg) scale(0.8);
  transition: all 0.3s;
  content: '';
}
.ant-calendar-year-panel-header .ant-calendar-year-panel-prev-month-btn:hover::before,
.ant-calendar-year-panel-header .ant-calendar-year-panel-prev-month-btn:hover::after {
  border-color: rgba(34, 34, 34, 0.8);
}
.ant-calendar-year-panel-header .ant-calendar-year-panel-prev-month-btn::after {
  display: none;
}
.ant-calendar-year-panel-header .ant-calendar-year-panel-next-month-btn {
  right: 29px;
  height: 100%;
}
.ant-calendar-year-panel-header .ant-calendar-year-panel-next-month-btn::before,
.ant-calendar-year-panel-header .ant-calendar-year-panel-next-month-btn::after {
  position: relative;
  top: -1px;
  display: inline-block;
  width: 8px;
  height: 8px;
  vertical-align: middle;
  border: 0 solid #aaa;
  border-width: 1.5px 0 0 1.5px;
  border-radius: 1px;
  transform: rotate(-45deg) scale(0.8);
  transition: all 0.3s;
  content: '';
}
.ant-calendar-year-panel-header .ant-calendar-year-panel-next-month-btn:hover::before,
.ant-calendar-year-panel-header .ant-calendar-year-panel-next-month-btn:hover::after {
  border-color: rgba(34, 34, 34, 0.8);
}
.ant-calendar-year-panel-header .ant-calendar-year-panel-next-month-btn::after {
  display: none;
}
.ant-calendar-year-panel-header .ant-calendar-year-panel-next-month-btn::before,
.ant-calendar-year-panel-header .ant-calendar-year-panel-next-month-btn::after {
  transform: rotate(135deg) scale(0.8);
}
.ant-calendar-year-panel-body {
  flex: 1 1;
}
.ant-calendar-year-panel-footer {
  border-top: 1px solid #f0f0f0;
}
.ant-calendar-year-panel-footer .ant-calendar-footer-extra {
  padding: 0 12px;
}
.ant-calendar-year-panel-table {
  width: 100%;
  height: 100%;
  table-layout: fixed;
  border-collapse: separate;
}
.ant-calendar-year-panel-cell {
  text-align: center;
}
.ant-calendar-year-panel-year {
  display: inline-block;
  height: 24px;
  margin: 0 auto;
  padding: 0 8px;
  color: rgba(34, 34, 34, 0.8);
  line-height: 24px;
  text-align: center;
  background: transparent;
  border-radius: 10px;
  transition: background 0.3s ease;
}
.ant-calendar-year-panel-year:hover {
  background: #f5f5f5;
  cursor: pointer;
}
.ant-calendar-year-panel-selected-cell .ant-calendar-year-panel-year {
  color: #fff;
  background: #00a6ff;
}
.ant-calendar-year-panel-selected-cell .ant-calendar-year-panel-year:hover {
  color: #fff;
  background: #00a6ff;
}
.ant-calendar-year-panel-last-decade-cell .ant-calendar-year-panel-year,
.ant-calendar-year-panel-next-decade-cell .ant-calendar-year-panel-year {
  color: #fff;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.ant-calendar-decade-panel {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 10;
  display: flex;
  flex-direction: column;
  background: #fff;
  border-radius: 10px;
  outline: none;
}
.ant-calendar-decade-panel-hidden {
  display: none;
}
.ant-calendar-decade-panel-header {
  height: 40px;
  line-height: 40px;
  text-align: center;
  border-bottom: 1px solid #f0f0f0;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  position: relative;
}
.ant-calendar-decade-panel-header a:hover {
  color: #45a8ff;
}
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-century-select,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-decade-select,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-year-select,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-month-select {
  display: inline-block;
  padding: 0 2px;
  color: rgba(255, 255, 255, 0.85);
  font-weight: 500;
  line-height: 40px;
}
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-century-select-arrow,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-decade-select-arrow,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-year-select-arrow,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-month-select-arrow {
  display: none;
}
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-prev-century-btn,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-next-century-btn,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-prev-decade-btn,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-next-decade-btn,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-prev-month-btn,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-next-month-btn,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-prev-year-btn,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-next-year-btn {
  position: absolute;
  top: 0;
  display: inline-block;
  padding: 0 5px;
  color: rgba(34, 34, 34, 0.45);
  font-size: 16px;
  font-family: Arial, 'Hiragino Sans GB', 'Microsoft Yahei', 'Microsoft Sans Serif', sans-serif;
  line-height: 40px;
}
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-prev-century-btn,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-prev-decade-btn,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-prev-year-btn {
  left: 7px;
  height: 100%;
}
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-prev-century-btn::before,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-prev-decade-btn::before,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-prev-year-btn::before,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-prev-century-btn::after,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-prev-decade-btn::after,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-prev-year-btn::after {
  position: relative;
  top: -1px;
  display: inline-block;
  width: 8px;
  height: 8px;
  vertical-align: middle;
  border: 0 solid #aaa;
  border-width: 1.5px 0 0 1.5px;
  border-radius: 1px;
  transform: rotate(-45deg) scale(0.8);
  transition: all 0.3s;
  content: '';
}
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-prev-century-btn:hover::before,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-prev-decade-btn:hover::before,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-prev-year-btn:hover::before,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-prev-century-btn:hover::after,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-prev-decade-btn:hover::after,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-prev-year-btn:hover::after {
  border-color: rgba(34, 34, 34, 0.8);
}
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-prev-century-btn::after,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-prev-decade-btn::after,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-prev-year-btn::after {
  display: none;
}
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-prev-century-btn::after,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-prev-decade-btn::after,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-prev-year-btn::after {
  position: relative;
  left: -3px;
  display: inline-block;
}
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-next-century-btn,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-next-decade-btn,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-next-year-btn {
  right: 7px;
  height: 100%;
}
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-next-century-btn::before,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-next-decade-btn::before,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-next-year-btn::before,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-next-century-btn::after,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-next-decade-btn::after,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-next-year-btn::after {
  position: relative;
  top: -1px;
  display: inline-block;
  width: 8px;
  height: 8px;
  vertical-align: middle;
  border: 0 solid #aaa;
  border-width: 1.5px 0 0 1.5px;
  border-radius: 1px;
  transform: rotate(-45deg) scale(0.8);
  transition: all 0.3s;
  content: '';
}
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-next-century-btn:hover::before,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-next-decade-btn:hover::before,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-next-year-btn:hover::before,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-next-century-btn:hover::after,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-next-decade-btn:hover::after,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-next-year-btn:hover::after {
  border-color: rgba(34, 34, 34, 0.8);
}
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-next-century-btn::after,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-next-decade-btn::after,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-next-year-btn::after {
  display: none;
}
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-next-century-btn::before,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-next-decade-btn::before,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-next-year-btn::before,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-next-century-btn::after,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-next-decade-btn::after,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-next-year-btn::after {
  transform: rotate(135deg) scale(0.8);
}
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-next-century-btn::before,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-next-decade-btn::before,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-next-year-btn::before {
  position: relative;
  left: 3px;
}
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-next-century-btn::after,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-next-decade-btn::after,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-next-year-btn::after {
  display: inline-block;
}
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-prev-month-btn {
  left: 29px;
  height: 100%;
}
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-prev-month-btn::before,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-prev-month-btn::after {
  position: relative;
  top: -1px;
  display: inline-block;
  width: 8px;
  height: 8px;
  vertical-align: middle;
  border: 0 solid #aaa;
  border-width: 1.5px 0 0 1.5px;
  border-radius: 1px;
  transform: rotate(-45deg) scale(0.8);
  transition: all 0.3s;
  content: '';
}
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-prev-month-btn:hover::before,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-prev-month-btn:hover::after {
  border-color: rgba(34, 34, 34, 0.8);
}
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-prev-month-btn::after {
  display: none;
}
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-next-month-btn {
  right: 29px;
  height: 100%;
}
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-next-month-btn::before,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-next-month-btn::after {
  position: relative;
  top: -1px;
  display: inline-block;
  width: 8px;
  height: 8px;
  vertical-align: middle;
  border: 0 solid #aaa;
  border-width: 1.5px 0 0 1.5px;
  border-radius: 1px;
  transform: rotate(-45deg) scale(0.8);
  transition: all 0.3s;
  content: '';
}
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-next-month-btn:hover::before,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-next-month-btn:hover::after {
  border-color: rgba(34, 34, 34, 0.8);
}
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-next-month-btn::after {
  display: none;
}
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-next-month-btn::before,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-next-month-btn::after {
  transform: rotate(135deg) scale(0.8);
}
.ant-calendar-decade-panel-body {
  flex: 1 1;
}
.ant-calendar-decade-panel-footer {
  border-top: 1px solid #f0f0f0;
}
.ant-calendar-decade-panel-footer .ant-calendar-footer-extra {
  padding: 0 12px;
}
.ant-calendar-decade-panel-table {
  width: 100%;
  height: 100%;
  table-layout: fixed;
  border-collapse: separate;
}
.ant-calendar-decade-panel-cell {
  white-space: nowrap;
  text-align: center;
}
.ant-calendar-decade-panel-decade {
  display: inline-block;
  height: 24px;
  margin: 0 auto;
  padding: 0 6px;
  color: rgba(34, 34, 34, 0.8);
  line-height: 24px;
  text-align: center;
  background: transparent;
  border-radius: 10px;
  transition: background 0.3s ease;
}
.ant-calendar-decade-panel-decade:hover {
  background: #f5f5f5;
  cursor: pointer;
}
.ant-calendar-decade-panel-selected-cell .ant-calendar-decade-panel-decade {
  color: #fff;
  background: #00a6ff;
}
.ant-calendar-decade-panel-selected-cell .ant-calendar-decade-panel-decade:hover {
  color: #fff;
  background: #00a6ff;
}
.ant-calendar-decade-panel-last-century-cell .ant-calendar-decade-panel-decade,
.ant-calendar-decade-panel-next-century-cell .ant-calendar-decade-panel-decade {
  color: #fff;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.ant-calendar-month .ant-calendar-month-header-wrap {
  position: relative;
  height: 288px;
}
.ant-calendar-month .ant-calendar-month-panel,
.ant-calendar-month .ant-calendar-year-panel {
  top: 0;
  height: 100%;
}
.ant-calendar-week-number-cell {
  opacity: 0.5;
}
.ant-calendar-week-number .ant-calendar-body tr {
  cursor: pointer;
  transition: all 0.3s;
}
.ant-calendar-week-number .ant-calendar-body tr:hover {
  background: #e6faff;
}
.ant-calendar-week-number .ant-calendar-body tr.ant-calendar-active-week {
  font-weight: bold;
  background: #a3ebff;
}
.ant-calendar-week-number .ant-calendar-body tr .ant-calendar-selected-day .ant-calendar-date,
.ant-calendar-week-number .ant-calendar-body tr .ant-calendar-selected-day:hover .ant-calendar-date {
  color: rgba(34, 34, 34, 0.8);
  background: transparent;
}
@use autoprefixer;
ul,
li {
  margin: 0;
  padding: 0;
  list-style: none;
}
p {
  margin-bottom: 0.5em;
}
.list-style {
  padding-left: 20px;
}
.list-style li {
  list-style: decimal;
  margin-bottom: 6px;
}
.common-distance-wrap {
  margin: 0 auto;
  min-width: 1200px;
}
.common-distance-wrap .Toastify__toast-container--top-center {
  top: 34%;
}
.common-distance {
  width: 1200px;
  padding: 0 40px;
  margin: 0 auto;
  position: relative;
}
.line-distance {
  height: 20px;
}
.Deliberate-reservation {
  position: relative;
  height: 400px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
/* 公用样式 */
.theme-color {
  color: #00a6ff;
}
.pointer {
  cursor: pointer;
}
.center {
  text-align: center;
}
.offset-y {
  transform: translateY(0);
  transition: transform 0.3s;
}
.offset-y:hover {
  transform: translateY(-4px);
}
.offset-small-y {
  transform: translateY(0), translateZ(0);
  transition: transform 0.3s;
}
.offset-small-y:hover {
  transform: translateY(-2px), translateZ(0);
}
.clear-padding {
  padding: 0 !important;
}
.clear-margin-bottom {
  margin-bottom: 0 !important;
}
.clear-border {
  border: 0 !important;
}
.inline-block {
  display: inline-block;
}
.theme-color {
  color: #00a6ff;
}
.white-bg-wrap {
  color: #222;
  background-color: #fff;
  min-height: 800px;
  padding-bottom: 20px;
}
.topbanner > img {
  width: 100%;
  padding: 0 !important;
}
.home-section-title {
  margin-top: 35px;
  padding-left: 15px;
  line-height: 1.4em;
  height: 1.4em;
  font-weight: bold;
  position: relative;
  color: #222222;
}
.home-section-title::before {
  content: "";
  background-color: #00a6ff;
  width: 4px;
  height: 1.3em;
  position: absolute;
  left: 0;
  top: 0.05em;
}
.item-wrap {
  display: flex;
  line-height: 1.4;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}
.item-wrap .ant-btn {
  padding-right: 0;
}
.item-wrap .ant-btn > span {
  max-width: none;
}
p.home-section-title {
  margin-bottom: 1em;
  line-height: 1.5em;
}
.placeholder-right.ant-select .ant-select-selection__placeholder {
  text-align: right;
  display: block !important;
}
.main-title {
  margin: 20px 0;
}
.main-title .anticon {
  margin-right: 6px;
  font-size: 23px;
}
.ant-pagination {
  text-align: center;
}
.gray-color {
  color: #666;
}
.green-color {
  color: #1c8eff;
}
.warning-color {
  color: #ff8f00;
}
.error-color {
  color: #f53d3d;
}
.success-color {
  color: #42d200;
}
.fail-color {
  color: #999999;
}
.black-color {
  color: #222;
}
.red-color {
  color: #f92d2d;
}
.tlc-sprite.user-deposit-currency,
.tlc-sprite.user-bonus-currency {
  background-color: #cccccc;
}
.tlc-sprite.user-email,
.tlc-sprite.user-phone {
  background-color: #ffffff;
}
.tlc-sprite.user-email.curr,
.tlc-sprite.user-phone.curr,
.tlc-sprite.user-deposit-currency.curr,
.tlc-sprite.user-bonus-currency.curr {
  background-color: #42d200;
}
.tlc-sprite.user-email {
  background-image: url("/vn/img/home/icon/Email.png");
}
.tlc-sprite.user-phone {
  background-image: url("/vn/img/home/icon/Phone.png");
}
.tlc-sprite.user-email.curr {
  background-position: -185px -135px;
  background-image: url("/vn/img/home/sprite20230330.png");
}
.tlc-sprite.user-phone.curr {
  background-position: -204px -135px;
  background-image: url("/vn/img/home/sprite20230330.png");
}
.tlc-sprite.user-deposit-currency {
  background-position: 2px -296px;
}
.tlc-sprite.user-bonus-currency {
  background-position: -14px -295px;
}
.verification-form-wrap .ant-input-group-addon {
  background-color: #00a5fd;
  border-color: #00a5fd;
  color: #fff;
  cursor: pointer;
}
.verification-form-wrap .ant-input-group-addon:hover {
  background-color: #49a5ff;
}
.verification-form-wrap .ant-input-group-addon:active {
  background-color: #1b87f2;
  border-color: #1b87f2;
}
.verification-form-wrap .has-error .userNames-input .ant-input {
  border-right: 0;
}
.verification-form-wrap .has-error .userNames-input .ant-input-group-addon {
  border: 1px solid #ff4d4f;
  border-left: 0;
}
.verification-form-wrap .userNames-input .ant-input-group-addon {
  background-color: #cccccc;
  border: #cccccc;
  color: #fff;
}
.verification-form-wrap .userNames-input.active .ant-input-group-addon {
  background-color: #1b87f2;
  border: #1b87f2;
}
.verification-form-wrap .userNames-input.havename .ant-input-disabled {
  border-right: transparent;
}
.verification-form-wrap .userNames-input.havename .ant-input-group-addon {
  background-color: #f5f5f5;
  border-top: 1px solid #ccc;
  border-right: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  color: #42d200;
  font-size: 12px;
  cursor: default;
}
.verification-form-wrap .userNames-input.havename .ant-input-group-addon div {
  width: 77px;
}
.verification-form-wrap .phverifySuccess {
  line-height: 40px;
  text-align: center;
}
.ant-input-group-wrapper.disabled-time .ant-input-group-addon {
  background: #cccccc;
  border-color: #cccccc;
  cursor: default;
}
.ant-input-group-wrapper.abled-time .ant-input-group-addon {
  background-color: #00a5fd;
  border-color: #00a5fd;
  color: #fff;
}
.verification-form-wrap .inner-addon-icon {
  cursor: pointer;
  position: absolute;
  top: 0px;
  right: 20px;
}
.user-form-wrap .edit-disabeld.enable .ant-input-group-addon:last-child,
.promotion-search .ant-input-group-addon:last-child {
  background-color: #00a6ff;
  border-color: #00a6ff;
  color: #fff;
  cursor: pointer;
}
.user-form-wrap .edit-disabeld .ant-input-group-wrapper.tlc-input-disabled.verified .ant-input-group-addon:last-child {
  color: #34c759;
  background-color: #f4f2f2;
  cursor: default;
  border-left: unset;
}
.user-form-wrap .edit-disabeld.enable .ant-input-group-addon:last-child:hover,
.promotion-search .ant-input-group-addon:last-child:hover {
  background-color: #fa5757;
}
.user-form-wrap .edit-disabeld.enable .ant-input-group-addon:last-child:active,
.promotion-search .ant-input-group-addon:last-child:active {
  background-color: #d41c22;
  border-color: #d41c22;
}
.user-form-wrap .ant-input-group-addon:last-child > div .ant-input-group-addon:last-child > div,
.verification-form-wrap .ant-input-group-addon:last-child > div {
  width: 66px;
  height: 43px;
  line-height: 43px;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.user-form-wrap .ant-input-group-addon:last-child > div .disabled-time .ant-input-group-addon:last-child > div,
.verification-form-wrap .disabled-time .ant-input-group-addon:last-child > div {
  width: 76px;
  height: 43px;
  line-height: 21.5px;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  white-space: normal;
}
.user-form-wrap .ant-input-group-addon:last-child > div .ant-form-item-label label,
.verification-form-wrap .ant-form-item-label label {
  font-weight: bold;
}
.inline-link-btn .ant-btn.ant-btn-link {
  padding: 0;
  width: auto !important;
  height: auto;
}
.has-error .hard-clear-error.ant-input {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  border-color: #bfbfbf;
}
.otp-btn-wrap button.ant-btn {
  width: 50%;
}
.otp-btn-wrap button.ant-btn.cry-otp-wrap {
  width: 100%;
}
.otp-btn-wrap.otp-email-btn .ant-btn {
  width: 100%;
}
.otp-btn-wrap button.changeVerify {
  color: #1c8eff;
  border: 1px solid #1c8eff;
  width: 47%;
  margin-right: 10px;
  padding: 0;
}
.otp-cs-tip {
  color: #999;
  margin: -3px 0 10px 0;
}
.otp-cs-tip .otp-cs {
  color: #00a6ff;
  cursor: pointer;
}
.otp-cs-tip-exceed {
  color: #222;
  margin: -3px 0 10px 0;
  text-align: center;
}
.remain {
  text-align: center;
  color: #f92d2d;
}
.edit-disabeld.true .ant-input-group-addon {
  cursor: not-allowed;
  padding-right: 0;
  background-color: #f5f5f5;
}
.edit-disabeld.true .ant-input-disabled {
  padding-left: 0;
  border-left: 0;
}
.ant-form-item.small-form-item .ant-form-item-control {
  line-height: 20px;
}
.ant-btn.ant-btn-primary.tlc-btn-1 {
  background-color: #434ee0;
  border-color: #434ee0;
}
.ant-btn.ant-btn-primary.tlc-btn-1:hover,
.ant-btn.ant-btn-primary.tlc-btn-1:focus {
  background-color: #6971e6;
  border-color: #6971e6;
}
.ant-btn.ant-btn-primary.tlc-btn-1:active,
.ant-btn.ant-btn-primary.tlc-btn-1.active {
  background-color: #313ddd;
  border-color: #313ddd;
}
.ant-btn.ant-btn-primary.tlc-btn-2 {
  background-color: #42d200;
  border-color: #42d200;
}
.ant-btn.ant-btn-primary.tlc-btn-2:hover,
.ant-btn.ant-btn-primary.tlc-btn-2:focus {
  background-color: #68db33;
  border-color: #68db33;
}
.ant-btn.ant-btn-primary.tlc-btn-2:active,
.ant-btn.ant-btn-primary.tlc-btn-2.active {
  background-color: #3fc700;
  border-color: #3fc700;
}
.ant-btn.ant-btn-primary.tlc-btn-3 {
  color: #1c8eff;
  background-color: #f0f0f2;
  border-color: #f0f0f2;
}
.ant-btn.ant-btn-primary.tlc-btn-3:hover,
.ant-btn.ant-btn-primary.tlc-btn-3:focus {
  background-color: #ebebed;
  border-color: #ebebed;
}
.ant-btn.ant-btn-primary.tlc-btn-3:active,
.ant-btn.ant-btn-primary.tlc-btn-3.active {
  background-color: #e4e4e6;
  border-color: #e4e4e6;
}
.ant-btn.tlc-large-btn {
  padding: 0 100px;
  line-height: 1;
}
.disabled-date.ant-calendar-picker-container .ant-calendar-header .ant-calendar-prev-year-btn,
.disabled-date.ant-calendar-picker-container .ant-calendar-header .ant-calendar-next-year-btn {
  display: none;
}
.disabled-date.ant-calendar-picker-container .ant-calendar-header .ant-calendar-prev-month-btn {
  left: 10px;
}
.disabled-date.ant-calendar-picker-container .ant-calendar-header .ant-calendar-next-month-btn {
  right: 10px;
}
.disabled-date.ant-calendar-picker-container .ant-calendar-my-select {
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  pointer-events: none;
}
.disabled-date.ant-calendar-picker-container .ant-calendar-my-select .ant-calendar-year-select {
  color: #222222;
}
.disabled-date.ant-calendar-picker-container .ant-calendar-my-select .ant-calendar-month-select {
  color: #222222;
}
.ant-calendar-picker .anticon-calendar.ant-calendar-picker-icon {
  width: 50px;
  top: 8px;
  right: 1px;
  line-height: 46px;
  height: 43px;
  border-bottom-right-radius: 8px;
  border-top-right-radius: 8px;
  background-image: linear-gradient(100deg, #f9f9f9, #d6d6d6);
  color: #888;
}
.gray-pagination.ant-pagination .ant-pagination-item-active {
  border-color: #888;
  background-color: #f0f0f2;
}
.gray-pagination.ant-pagination .ant-pagination-item-active a {
  color: #222;
}
.gray-pagination.ant-pagination .ant-pagination-item:focus,
.gray-pagination.ant-pagination .ant-pagination-item:hover,
.gray-pagination.ant-pagination .ant-pagination-prev:focus .ant-pagination-item-link,
.gray-pagination.ant-pagination .ant-pagination-next:focus .ant-pagination-item-link,
.gray-pagination.ant-pagination .ant-pagination-prev:hover .ant-pagination-item-link,
.gray-pagination.ant-pagination .ant-pagination-next:hover .ant-pagination-item-link {
  color: #222;
  border-color: #888;
}
.gray-pagination.ant-pagination .ant-pagination-item:focus a,
.gray-pagination.ant-pagination .ant-pagination-item:hover a,
.gray-pagination.ant-pagination .ant-pagination-prev:focus .ant-pagination-item-link a,
.gray-pagination.ant-pagination .ant-pagination-next:focus .ant-pagination-item-link a,
.gray-pagination.ant-pagination .ant-pagination-prev:hover .ant-pagination-item-link a,
.gray-pagination.ant-pagination .ant-pagination-next:hover .ant-pagination-item-link a {
  color: #222;
}
.ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):focus-within,
.ant-radio-button-wrapper:focus-within {
  outline: none !important;
}
.gray-radio .ant-radio-button-wrapper:hover {
  color: #000;
}
.gray-radio.ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  background: #666;
  border-color: #666;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.gray-radio.ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  box-shadow: -1px 0 0 0 #666;
}
.gray-radio.ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover {
  background: #858585;
  border-color: #858585;
}
.gray-radio.ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):active {
  background: #525252;
  border-color: #525252;
}
.black-radio .ant-radio-button-wrapper:hover {
  color: #000;
}
.black-radio.ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  background: #000;
  border-color: #666;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.black-radio.ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  box-shadow: -1px 0 0 0 #666;
}
.black-radio.ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover {
  background: #000;
  border-color: #e0e0e0;
}
.black-radio.ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):active {
  background: #000;
  border-color: #e0e0e0;
}
.ant-select-selection .ant-select-arrow {
  color: #666060;
}
.ant-input.tlc-input-disabled[disabled],
.ant-select-disabled.tlc-select-disabled .ant-select-selection,
.tlc-date-disabled .ant-input.ant-input-disabled[disabled],
.ant-checkbox-disabled + span,
.tlc-input-disabled .ant-input-disabled {
  color: rgba(0, 0, 0, 0.7);
}
.ant-input.tlc-input-disabled[disabled] {
  background-color: #f4f2f2;
}
.ant-btn.tlc-button-disabled[disabled] {
  color: #fff;
  background-color: #ccc;
}
.ant-input.tlc-input-disabled.contact-server {
  color: #1c8eff;
  text-align: left;
  cursor: pointer;
}
.ant-input.small-radius {
  border-radius: 2px;
}
.tlc-month-picker.ant-calendar-picker-container .ant-calendar-month .ant-calendar-month-header-wrap {
  height: 228px;
}
.tlc-month-picker.ant-calendar-picker-container .ant-calendar-month-panel-body {
  padding: 10px 0;
}
.tlc-month-picker.ant-calendar-picker-container .ant-calendar-month-panel-month {
  height: 26px;
  line-height: 26px;
  padding: 0 12px;
}
.ant-tabs.border-tabs > .ant-tabs-top-bar .ant-tabs-nav-container::after {
  display: block;
  height: 2px;
  background: #e0e0e0;
  z-index: -1;
  position: absolute;
  bottom: 0%;
  left: 0%;
  width: 100%;
}
.ant-tabs.border-tabs .ant-tabs-tab {
  padding-left: 40px;
  padding-right: 40px;
  font: normal normal bold 16px/21px "Roboto", Arial, sans-serif;
  color: #999999;
}
.ant-tabs.border-tabs .ant-tabs-tab > div {
  position: relative;
}
.ant-tabs.border-tabs .ant-tabs-tab > div.hasRead::before {
  content: "";
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background-color: #f92d2d;
  position: absolute;
  top: 0px;
  right: -8px;
}
.ant-tabs.border-tabs > .ant-tabs-top-bar .ant-tabs-tab.ant-tabs-tab-active {
  color: #00a5fe !important;
  text-align: center;
  font: normal normal bold 18px/24px Roboto !important;
  letter-spacing: 0px;
  opacity: 1;
  height: 36px;
}
.ant-tabs.border-tabs .ant-tabs-ink-bar {
  width: 120px;
  height: 6px;
  /* UI Properties */
  background: var(--unnamed-color-00a6ff) 0% 0% no-repeat padding-box;
  background: #00a6ff 0% 0% no-repeat padding-box;
  opacity: 1;
  bottom: 0px;
}
.ant-popover {
  opacity: 0.99;
  line-height: 1.4;
}
.ant-popover .ant-popover-inner-content {
  padding: 10px 15px 8px 15px;
  color: #000;
}
.ant-popover.popover-dark {
  z-index: 1;
}
.ant-popover.popover-dark > .ant-popover-content > .ant-popover-arrow {
  border-top-color: #2c2c2c;
  border-left-color: #2c2c2c;
}
.ant-popover.popover-dark .ant-popover-inner {
  background-color: #2c2c2c;
}
.ant-popover.popover-dark .ant-popover-inner-content {
  color: #fff;
  opacity: 1;
}
.question-popover-tip {
  font-size: 16px;
  vertical-align: 0.1em;
  vertical-align: middle;
  padding-left: 12px;
  display: inline-block;
}
.question-popover-tip.my-promotion-tip {
  height: 16px;
  vertical-align: text-bottom;
  padding-left: 0;
  margin-left: 6px;
}
.question-popover-tip.my-promotion-tip > i {
  vertical-align: top;
}
.tlc-padding-top .ant-modal-content .ant-modal-close .ant-modal-close-x {
  color: white;
}
.tlc-padding-top .ant-modal-content .ant-pagination-item-active {
  border: 1px solid #e0e0e0;
}
.tlc-padding-top .ant-modal-content .ant-pagination-item-active a {
  color: #222222;
}
.tlc-modal-confirm .ant-modal-header {
  padding-top: 38px;
  padding-bottom: 20px;
}
.tlc-modal-confirm .ant-btn {
  margin-top: 20px;
}
.tlc-modal-confirm .btns-wrap .ant-btn.ant-btn-primary {
  width: 48%;
  display: inline-block;
}
.tlc-modal-confirm .btns-wrap .ant-btn.ant-btn-primary:first-child {
  margin-right: 4%;
}
.align-left .ant-modal-confirm-body .ant-modal-confirm-content,
.align-left {
  text-align: left;
}
.ant-modal-confirm-title .anticon.anticon-exclamation-circle {
  font-size: 80px;
  margin-bottom: 20px;
}
.ant-modal-confirm-btns .ant-btn.circle {
  border-radius: 20px;
}
.ant-modal.tlc-closable-clear .ant-modal-content .ant-modal-close {
  display: none;
}
.ant-modal.tlc-small-center-dialog .ant-modal-content .content {
  padding-top: 22px;
  text-align: center;
  width: 264px;
  margin: auto;
  color: #000;
}
.ant-modal.tlc-primary-inline .ant-modal-content .ant-btn.ant-btn-primary {
  width: 47% !important;
}
.ant-modal-confirm.only-title-confirm .ant-modal-confirm-content {
  display: none;
}
.ant-select-dropdown.small-option .ant-select-dropdown-menu-item {
  text-align: center;
  line-height: 16px;
}
.ant-modal-body .ant-spin {
  display: block;
  padding-top: 36%;
  opacity: 0.6;
  position: absolute;
  border-radius: 10px;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: 11;
}
.ant-modal-body .ant-spin.hide,
.user-center-loading.ant-spin.hide {
  display: none !important;
}
.ant-skeleton.tlc-about-skeleton {
  width: 100%;
}
.ant-skeleton.tlc-about-skeleton.ant-skeleton-active .ant-skeleton-content .ant-skeleton-paragraph {
  margin-bottom: 50px;
}
.ant-skeleton.ant-skeleton-active .ant-skeleton-content .ant-skeleton-paragraph > li,
.ant-skeleton-with-avatar .ant-skeleton-content .ant-skeleton-title {
  height: 45px;
  border-radius: 8px;
  background: linear-gradient(90deg, #efefef 25%, #fff 37%, #efefef 63%);
  background-size: 400% 100%;
  animation: ant-skeleton-loading 1.4s ease infinite;
}
.ant-modal.tlc-wechat-modal._white .ant-modal-content {
  background-color: #fff;
  color: #000;
}
.ant-modal.tlc-wechat-modal._white .ant-modal-content .ant-modal-close {
  color: #000;
}
.ant-modal.tlc-wechat-modal .ant-modal-content {
  background-color: #000;
  color: #fff;
}
.ant-modal.tlc-wechat-modal .ant-modal-content .ant-modal-body {
  padding: 50px;
}
.ant-modal.tlc-wechat-modal .ant-modal-content .ant-modal-body > img {
  width: 100%;
  margin-top: 10px;
}
.ant-modal.tlc-wechat-modal .ant-modal-content .ant-modal-body > p {
  margin-bottom: 0.2em;
}
.ant-modal.tlc-wechat-modal .ant-modal-content .ant-modal-close {
  color: #fff;
}
.ant-popover.footer-popover .ant-popover-inner {
  border-radius: 4px;
}
.ant-popover.footer-popover .ant-popover-inner-content {
  padding: 6px 10px;
}
.tlc-container-wrapper._1 {
  padding-top: 35px;
}
.tlc-container-wrapper._not-login .header-warp,
.tlc-container-wrapper._has-login .header-warp {
  position: absolute;
}
.tlc-container-wrapper._not-login .header-warp,
.tlc-container-wrapper._has-login .header-warp {
  transition: transform 0.3s ease;
}
.tlc-container-wrapper._not-login .header-warp.zoom-out,
.tlc-container-wrapper._has-login .header-warp.zoom-out {
  transform: translateY(-10px);
}
.tlc-container-wrapper._not-login .tlc-sign .tlc-sprite,
.tlc-container-wrapper._has-login .tlc-sign .tlc-sprite {
  z-index: 1001;
  position: relative;
  pointer-events: none;
}
.has-center-learn.header-warp {
  position: absolute;
}
.tlc-learn-shadow-wrap .common-distance > img {
  position: absolute;
  top: -133px;
  right: 36px;
  z-index: 1002;
}
.has-header-center .header-popover.show {
  right: 122px !important;
  top: 166px !important;
}
.tlc-container-wrapper._has-login .user-center-btn,
.tlc-container-wrapper._has-member .user-center-btn,
.tlc-container-wrapper._has-login .wallet-center-btn,
.tlc-container-wrapper._has-member .wallet-center-btn,
.tlc-container-wrapper._has-login .qrcode-picture canvas,
.tlc-container-wrapper._has-member .qrcode-picture canvas {
  z-index: 1001;
  position: relative;
  pointer-events: none;
}
.user-center-popover .user-center-btn,
.user-center-popover .wallet-center-btn {
  z-index: 1001;
  position: relative;
  pointer-events: none;
}
.tlc-container-wrapper._has-login .tlc-learn-shadow-wrap > img {
  display: inline;
  display: initial;
  margin-top: 230px;
  position: absolute;
  top: 288px;
  left: 0;
  right: 0;
  margin: auto;
  z-index: 1002;
}
.tlc-container-wrapper._not-login .tlc-learn-shadow.index {
  background-image: url("/vn/img/learn/not_login_prompt_new.png");
  background-repeat: no-repeat;
  background-position: center -74px;
}
.tlc-container-wrapper._has-login .tlc-learn-shadow.index {
  background-image: url("/vn/img/learn/has_login_prompt.png");
  background-repeat: no-repeat;
  background-position: center -54px;
}
.tlc-learn-shadow-wrap > img {
  display: none;
}
.tlc-learn-shadow {
  width: 100%;
  display: block;
  position: absolute;
  overflow: hidden;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 1000;
  text-align: center;
}
.learn-knew {
  width: 160px;
  height: 44px;
  background-color: transparent;
  background-image: url("/vn/img/learn/member_know.png");
  background-repeat: no-repeat;
  border: 0;
  margin-top: 696px;
  outline: none;
  cursor: pointer;
}
.learn-knew:hover {
  opacity: 0.8;
}
@keyframes ant-skeleton-loading {
  0% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0 50%;
  }
}
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}
::-webkit-scrollbar-track {
  background-color: rgba(93, 93, 93, 0.24);
}
::-webkit-scrollbar-thumb {
  background-color: #b1b2b3;
}
.leaveAddWalletModal .ant-modal-body {
  padding: 30px;
}
.leaveAddWalletModal .ant-modal-body .content {
  text-align: left;
  padding: 0px 0px 40px 0px;
}
.leaveAddWalletModal .ant-modal-body .buttonWrap {
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
  text-align: center;
}
.leaveAddWalletModal .ant-modal-body .buttonWrap button.ant-btn {
  width: 160px;
  padding: 10px 0;
  height: 40px;
}
.leaveAddWalletModal .ant-modal-body .buttonWrap button:last-of-type {
  background-color: #00a6ff;
  color: #fff;
}
.checkLBWithdrawalModal .msg1 {
  text-align: left;
  padding: 20px 0px;
}
.checkLBWithdrawalModal .msg1 span {
  color: #1c8eff;
  cursor: pointer;
}
.checkLBWithdrawalModal .msg2 {
  padding: 0px 0px 20px;
}
.checkLBWithdrawalModal .msg2 span {
  color: #1c8eff;
  cursor: pointer;
}
.checkLBWithdrawalModal .btn {
  display: block;
  margin: 0 auto;
  text-align: center;
}
.checkLBWithdrawalModal .btn button.ant-btn {
  width: 45%;
  margin: 0 8px;
  padding: 10px 0;
  height: 40px;
  color: #f92d2d;
  border: 1px solid #f92d2d;
  box-sizing: border-box;
}
.checkLBWithdrawalModal .btn button:last-of-type {
  background-color: #f92d2d;
  color: #fff;
}
.big-empty-box {
  height: 215px;
  color: #999999;
}
.big-empty-box .ant-empty-image {
  padding-top: 20px;
  width: 230px;
  height: 158px;
  margin: 0 auto 20px auto;
}
.t-alert-mask {
  width: 100%;
  height: 100%;
  position: fixed;
  text-align: center;
  left: 0;
  top: 0;
  z-index: 1111;
}
.t-alert-mask .tHistorySuccess {
  max-width: 300px;
  min-width: 100px;
  padding: 18px;
  background: #daffe3 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 100px;
  transform: translateY(-50%);
  font-size: 16px;
  color: #0ccc3c;
  display: inline-block;
  position: relative;
  top: 50%;
}
.t-alert-mask .tHistorySuccess img {
  width: 18px;
  height: auto;
}
.t-alert-mask > p {
  max-width: 302px;
  padding: 30px;
  border-radius: 10px;
  transform: translateY(-50%);
  font-size: 16px;
  background-color: rgba(0, 0, 0, 0.8);
  color: #fff;
  display: inline-block;
  position: relative;
  top: 50%;
}
.upload-thumb-input-wrap {
  padding: 15px 15px 0 15px;
}
.upload-thumb-input-wrap > div {
  color: #666;
  font-size: 12px;
}
.upload-thumb-input-wrap input {
  height: 40px;
  line-height: 40px;
  border: 0;
  background-color: #f6f6f6;
  text-overflow: ellipsis;
}
.upload-thumb-input-wrap .anticon {
  color: #999;
  cursor: pointer;
}
#about-title {
  position: fixed;
  z-index: 1000;
  left: 48px;
  right: 48px;
  top: 0;
  height: 52px;
  line-height: 52px;
  color: #220000;
}
.tlc-about {
  padding: 10px 0px;
  margin: -1px;
}
.tlc-about table {
  width: 100%;
}
.tlc-about table tbody tr {
  background: none;
  border: none;
}
.tlc-about table td {
  padding: 0;
}
.tlc-about table td img {
  width: 100%;
}
.tlc-about .space-carousel0 {
  position: relative;
  margin-bottom: 32px;
}
.tlc-about .space-carousel0:after {
  position: absolute;
  left: 16px;
  right: 16px;
  bottom: -16px;
  content: "";
  display: block;
  height: 1px;
  background: rgba(255, 255, 255, 0.25);
}
.tlc-about .tlcabout_us table td,
.tlc-about .currency-introduction table td {
  position: relative;
}
.tlc-about .tlcabout_us table td h2,
.tlc-about .currency-introduction table td h2 {
  position: absolute;
  top: -17.6px;
  left: calc(50% - 84px);
  width: 168px;
  height: 45px;
  line-height: 45px;
  text-align: center;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 100%;
  font-size: 18px;
  font-weight: normal;
}
.tlc-about .tlcabout_us table td p,
.tlc-about .currency-introduction table td p {
  background: #ffffff;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  margin-left: 16px;
  margin-right: 16px;
  padding: 32px 16px 16px;
  color: #aba79d;
  font-size: 14px;
}
.tlc-about .tlcabout_us table td {
  position: relative;
}
.tlc-about .tlcabout_us table td h2 {
  background-image: url("/static/images/aboutus/aboutus-block.png");
}
.tlc-about .currency-introduction table td {
  position: relative;
}
.tlc-about .currency-introduction table td h2 {
  background-image: url("/static/images/currency/title.png");
}
#tlc-aboutUs {
  background: #f77a78;
}
#tlc-aboutUs .tlcslogan,
#tlc-aboutUs .tlcguarantee,
#tlc-aboutUs .tlclicenses {
  padding-left: 16px;
  padding-right: 16px;
}
#tlc-aboutUs .tlcslogan table tr td,
#tlc-aboutUs .tlcguarantee table tr td,
#tlc-aboutUs .tlclicenses table tr td {
  background: #ffffff;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  padding: 16px;
  font-size: 14px;
}
#tlc-aboutUs .tlcpartners table,
#tlc-aboutUs .tlcpartners tr,
#tlc-aboutUs .tlcpartners td {
  vertical-align: middle;
  padding: 0;
}
#tlc-aboutUs .tlclicenses table {
  margin: 0;
}
#tlc-aboutUs table caption {
  font-size: 16px;
  color: #fff;
  margin-bottom: 10px;
}
#tlc-aboutUs table tr td {
  color: #333;
}
#tlc-currency {
  background: #00a6ff;
  color: #fff;
  font-size: 12px;
}
#tlc-currency .content {
  padding-left: 16px;
  padding-right: 16px;
}
#tlc-currency table caption {
  text-align: left;
  color: #ffee00;
  font-size: 14px;
}
#tlc-currency .tlccoin_rule table tr > td,
#tlc-currency .tlccoin_expiration table tr > td,
#tlc-currency .tlc8shop_change_rule table tr > td {
  padding: 10px 0;
}
#tlc-currency .tlc8shop_change_rule tr:nth-child(2) td,
#tlc-currency .tlccoin_expiration tr:nth-child(2) td {
  padding: 0;
}
#tlc-currency .tlc8shop_change_rule tr:nth-child(2) td table,
#tlc-currency .tlccoin_expiration tr:nth-child(2) td table {
  background: #ffffff;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
}
#tlc-currency .tlc8shop_change_rule tr:nth-child(2) td table tr,
#tlc-currency .tlccoin_expiration tr:nth-child(2) td table tr {
  border-bottom: 1px solid #f0f0f2;
}
#tlc-currency .tlc8shop_change_rule tr:nth-child(2) td table tr:last-child,
#tlc-currency .tlccoin_expiration tr:nth-child(2) td table tr:last-child {
  border-bottom: none;
}
#tlc-currency .tlc8shop_change_rule tr:nth-child(2) td table tr td,
#tlc-currency .tlccoin_expiration tr:nth-child(2) td table tr td {
  height: 36px;
  line-height: 36px;
  color: #030303;
  text-align: center;
}
#tlc-currency .tlc8shop_change_rule tr:nth-child(2) td table tr:first-child td {
  color: #00a6ff;
}
#tlc-currency .tlccoin_expiration tr:nth-child(2) td table tr:first-child td {
  color: #d6d1c2;
}
#tlc-sponsor {
  padding-bottom: 0;
}
#tlc-sponsor table {
  margin-bottom: 0;
}
#tlc-sponsor table caption {
  color: #00a6ff;
  font-size: 18px;
}
#tlc-sponsor table td {
  padding: 0;
}
#tlc-sponsor div {
  margin-bottom: 30px;
}
#tlc-sponsor div.tlcWest_Bromwich {
  margin-bottom: 0;
}
#tlc-sponsor div table tr:first-child td {
  padding-top: 24px;
  padding-bottom: 24px;
}
#tlc-sponsor div table tr td {
  padding-left: 30px;
  padding-right: 30px;
  font-size: 14px;
  color: #666;
}
#tlc-sponsor div.tlcMiddlesbrough table tr:last-child td,
#tlc-sponsor div.tlcSouthHampton table tr:last-child td,
#tlc-sponsor div.tlcWest_Bromwich table tr:last-child td {
  padding-left: 0;
  padding-right: 0;
}
#tlc-sponsor div.tlcSouthHampton table tr:nth-child(2) td {
  padding-left: 0;
  padding-right: 0;
}
#tlc-sponsor div.tlcWest_Bromwich table tr:last-child td img {
  display: block;
  margin-top: 32px;
}
#tlc-sponsor div.tlcWest_Bromwich p {
  display: none;
}
#tlc-sponsor div.tlcSouthHampton table tr:last-child td {
  display: flex;
  padding-top: 16px;
  justify-content: space-between;
}
#tlc-sponsor div.tlcSouthHampton table tr:last-child td img {
  max-width: 32%;
  height: 88px;
}
#tlc-sponsor div.tlcSunderland table tr:last-child td {
  display: flex;
  padding-top: 16px;
  justify-content: space-between;
}
#tlc-sponsor div.tlcSunderland table tr:last-child td img {
  max-width: 48%;
  height: 114px;
}
#tlc-sponsor div:first-child table caption,
#tlc-sponsor div:last-child table caption {
  color: #c5af5f;
  font-size: 18px;
  padding-top: 10px;
}
#tlc-sponsor div:first-child table tr td,
#tlc-sponsor div:last-child table tr td {
  color: #fff;
}
#tlc-sponsor div:first-child table caption,
#tlc-sponsor div:first-child table tbody tr:first-child {
  background: #d91616;
}
#tlc-sponsor div:first-child table tr:last-child td {
  padding-left: 0;
  padding-right: 0;
}
#tlc-sponsor div:last-child {
  background: #d91616;
  padding-bottom: 32px;
  margin-bottom: 0;
}
.tlcrefer {
  background: #fff !important;
  padding: 0 16px;
}
.tlcrefer strong {
  color: #222222 !important;
}
#tlc-sponsor .tlcrefer table caption,
#tlc-sponsor div:first-child table tbody tr:first-child {
  background: #fff !important;
}
#tlc-sponsor .tlcrefer table tr td,
#tlc-sponsor div:last-child table tr td {
  color: #222222 !important;
}
#Sponsorship img {
  width: 100%;
}
#Sponsorship .TODO {
  position: absolute;
  bottom: -15px;
  left: 0;
  right: 0;
  padding: 100px;
}
.TopBanner {
  position: relative;
  display: flex;
  justify-content: center;
  height: 200px;
}
.TopBanner img {
  position: absolute;
  top: -15%;
}
.Home .swiper_container::after {
  position: absolute;
  left: -30%;
  top: 0;
  z-index: -1;
  width: 160%;
  height: 135px;
  content: "";
  border-radius: 0 0 80% 80%;
  background-image: linear-gradient(126deg, #00a6ff 0%, #00a6ff 100%);
}
.Showlist .item {
  background-color: #ffffff;
  padding: 10px;
  margin-bottom: 20px;
  border-radius: 12px;
}
.Showlist .header {
  display: flex;
  justify-content: space-between;
  padding: 8px;
}
.Question-Modal .modal-info-content {
  background-color: #efeff4;
}
.CTCTutorial__modal .swiper-pagination {
  position: absolute;
  text-align: center;
  transition: 0.3s opacity;
  transform: translate3d(0, 0, 0);
  z-index: 10;
  bottom: 10px;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.CTCTutorial__modal .swiper-pagination .swiper-pagination-bullet {
  width: 10px !important;
  height: 10px !important;
  text-align: center;
  line-height: 20px;
  font-size: 12px;
  color: #000;
  opacity: 1;
  background: rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  margin: 5px 2px;
}
.CTCTutorial__modal .swiper-pagination .swiper-pagination-bullet-active {
  color: #fff;
  background: #007aff;
}
.PageChange {
  width: 45px;
  height: 125px;
  position: fixed;
  top: 45%;
  right: 0;
}
.PageChange li {
  background: #f0ffff9c;
  width: 10px;
  height: 10px;
  border-radius: 10px;
  margin-left: 15px;
  margin-bottom: 8px;
}
.PageChange .active {
  background-color: white;
}
.screens {
  display: block;
  height: 1px;
  opacity: 0;
}
.GreenBtn {
  background-color: #0ccc3c !important;
  border: 1px solid #0ccc3c !important;
}
.btnYellow {
  background: transparent linear-gradient(123deg, #ffb858 0%, #ff8b62 100%) 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border: 1px solid #00000000;
}
.btnBlue {
  background: transparent linear-gradient(126deg, #2ce3fc 0%, #32c7f7 100%) 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #0202021a;
  border: 1px solid #2ddefb;
}
.btnPurple {
  background: transparent linear-gradient(119deg, #fc96ee 0%, #d274ff 100%) 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #0202021a;
  border: 1px solid #fc96ee;
}
.ant-popover .ant-popover-content .ant-popover-arrow {
  border-color: #2c2c2c !important;
}
.ant-popover .ant-popover-content .ant-popover-inner {
  background-color: #2c2c2c;
}
.ant-popover .ant-popover-content .ant-popover-inner-content {
  color: #fff;
  font-weight: normal;
  font-style: normal;
  font-size: 12px;
  line-height: 18px;
  display: flex;
  gap: 5px;
}
.ant-popover .ant-popover-content .ant-popover-inner-content .btn-close-popover {
  width: -moz-fit-content;
  width: fit-content;
  height: -moz-fit-content;
  height: fit-content;
  background-color: transparent;
  border: none;
  cursor: pointer;
}
.popover-dark .ant-popover-inner-content {
  padding: 15px;
}
.info-popover .ant-popover-inner-content {
  padding: 15px;
}
.info-popover .ant-popover-inner-content p {
  display: flex;
  align-items: flex-start;
  gap: 10px;
  line-height: 22px;
  margin: 0;
}
.info-popover .ant-popover-inner-content p button {
  margin: 0;
  padding: 0;
  font-size: 12px;
  height: -moz-fit-content;
  height: fit-content;
  border: none;
  background-color: transparent;
  cursor: pointer;
}
.info-popover .ant-popover-inner-content .p-text {
  width: 300px;
  height: 66px;
}
.general-modal .ant-modal-header .ant-modal-title {
  color: #fff;
  text-align: center;
  font-size: 20px;
}
.general-modal .ant-modal-body {
  padding: 22px 30px 30px 30px;
}
.general-modal .ant-modal-body .ant-form-explain {
  color: #ff4d4f;
  background-color: #fee5e5;
  padding: 10px 0 10px 16px;
  border-radius: 8px;
  margin-top: 8px;
  margin-bottom: 20px;
  font-size: 12px;
}
.general-modal .ant-modal-body button:disabled {
  background-color: #cccccc;
  color: #fff;
  border-color: #cccccc;
  cursor: not-allowed;
}
.unclosable-modal .ant-modal-body {
  text-align: center;
  padding: 40px 30px;
  color: #222;
}
.unclosable-modal .ant-modal-footer {
  padding: 0 30px 30px 30px;
}
.unclosable-modal .ant-modal-footer > div {
  display: flex;
  gap: 20px;
}
.unclosable-modal .ant-modal-footer button {
  height: 40px;
  margin: 0;
}
.general-table {
  background-color: #2e3033;
  border-radius: 12px;
  overflow: hidden;
}
.general-table .ant-table-content > .ant-table-body {
  cursor: default;
}
.general-table .ant-table-content > .ant-table-body .ant-table-thead > tr > th {
  background-color: transparent;
  text-align: center;
  color: #d6d1c2;
  font-weight: normal;
  font-style: normal;
  font-size: 16px;
  line-height: 21px;
  border-bottom: 1px solid #393b40;
}
.general-table .ant-table-content > .ant-table-body .ant-table-tbody > tr > td {
  text-align: center;
  border-bottom: 1px solid #393b40;
}
.general-table .ant-table-content > .ant-table-body .ant-table-tbody > tr > td p {
  margin: 0;
  color: #aba79d;
  font-weight: normal;
  font-style: normal;
  font-size: 14px;
  line-height: 19px;
}
.general-table .ant-table-content > .ant-table-body .ant-table-tbody > tr > td img {
  width: 25px;
  height: 25px;
}
.general-table .ant-table-content > .ant-table-body .ant-table-tbody tr:hover td {
  background-color: unset;
}
.general-ordered-list {
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding-left: 15px;
}
.general-ordered-list li {
  list-style-type: decimal;
  color: #ABA79D;
  font-size: 12px;
  font-weight: 300;
  padding-left: 8px;
}
.general-pagination li {
  background-color: transparent;
  border-radius: 5px;
  border: 1px solid #e0e0e0;
}
.general-pagination li a {
  background-color: transparent !important;
  color: #222;
  border: none !important;
}
.general-pagination li:not(:first-child),
.general-pagination li:not(:last-child) {
  color: #222;
}
.general-pagination .ant-pagination-item-active {
  border-color: transparent;
  background-color: #f0f0f2;
}
.general-pagination .ant-pagination-item-active a {
  color: #222;
}
.general-pagination .ant-pagination-item:focus,
.general-pagination .ant-pagination-item:hover,
.general-pagination .ant-pagination-next:hover,
.general-pagination .ant-pagination-prev:hover {
  border-color: #e0e0e0;
}
.general-pagination .ant-pagination-item:focus a,
.general-pagination .ant-pagination-item:hover a,
.general-pagination .ant-pagination-next:hover a,
.general-pagination .ant-pagination-prev:hover a {
  color: black;
}
.general-pagination .ant-pagination-disabled a {
  color: #bfbfbf;
}
.general-pagination .ant-pagination-disabled:hover a {
  color: #bfbfbf;
}
.ant-calendar-picker-container .ant-calendar-body .ant-calendar-selected-day .ant-calendar-date,
.ant-calendar-picker-container .ant-calendar-body .ant-calendar-selected-date .ant-calendar-date {
  background-color: #00a6ff;
  color: #fff;
  border-radius: 50%;
}
.ant-calendar-picker-container .ant-calendar-body .ant-calendar-disabled-cell .ant-calendar-date {
  background-color: #d8d8d8;
  color: #999999;
  border-radius: unset;
}
.ant-calendar-picker-container .ant-calendar-body .ant-calendar-last-day-of-month + .ant-calendar-cell .ant-calendar-date,
.ant-calendar-picker-container .ant-calendar-body .ant-calendar-disabled-cell-first-of-row .ant-calendar-date {
  border-radius: 20px 0 0 20px;
}
.ant-calendar-picker-container .ant-calendar-body .ant-calendar-disabled-cell-last-of-row .ant-calendar-date,
.ant-calendar-picker-container .ant-calendar-body .ant-calendar-last-day-of-month .ant-calendar-date {
  border-radius: 0 20px 20px 0;
}
.ant-calendar-picker-container .ant-calendar-body .ant-calendar-last-day-of-month + .ant-calendar-cell.ant-calendar-disabled-cell-last-of-row .ant-calendar-date,
.ant-calendar-picker-container .ant-calendar-body .ant-calendar-last-day-of-month.ant-calendar-disabled-cell-first-of-row .ant-calendar-date {
  border-radius: 20px 20px 20px 20px;
}
.ant-calendar-picker-container .ant-calendar-body .ant-calendar-last-month-cell .ant-calendar-date,
.ant-calendar-picker-container .ant-calendar-body .ant-calendar-next-month-btn-day .ant-calendar-date {
  color: transparent;
  background-color: #fff;
}
.csbox .l {
  text-align: center;
  color: #189eaa;
  font-weight: 600;
  cursor: pointer;
}
.csbox .r {
  text-align: center;
  color: #01bf63;
  font-weight: 600;
  cursor: pointer;
}
.csbox {
  display: flex;
  justify-content: center;
  align-items: center;
}
.csbox img {
  width: 163px;
  height: 155px;
  margin: 15px 10px;
}
.csbox img:hover {
  transition: all 0.5s;
  transform: scale(1.1);
}
.remind-circle:before {
  content: "";
  width: 5px;
  height: 5px;
  display: block;
  position: absolute;
  background-color: #f92d2d;
  border-radius: 50%;
  right: 0;
  top: 0;
}
.header-popover-inner-tip {
  color: #222;
  vertical-align: baseline;
  vertical-align: initial;
  display: inline-block;
  padding: 0 8px;
}
.header-popover-inner-tip .anticon.anticon-question-circle {
  font-size: 15px;
}
.header-popover-wrap {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 0;
  z-index: 1000;
}
.header-popover-wrap.show-hard,
.header-popover-wrap.has-header-center {
  position: absolute;
  pointer-events: none;
  z-index: 1001;
}
.header-popover-wrap.show-hard .header-popover.show {
  right: 40px !important;
}
.header-popover.wallet {
  width: 385px;
  top: 166px;
}
.header-popover.wallet .header-popover-inner-content > li {
  margin: 1px 0 !important;
}
.header-popover.wallet .anticon.anticon-reload {
  cursor: pointer;
  vertical-align: -0.2em;
  font-size: 16px;
}
.header-popover.wallet .header-popover-inner-title {
  font-weight: bold;
  padding-top: 15px;
}
.header-popover.wallet .header-popover-inner-title .balance-main {
  width: 140px;
  margin: 0 18px;
  color: #222;
  line-height: 22px;
  padding: 0 10px;
  background-color: #fff;
  border-radius: 4px;
}
.header-popover.wallet .header-popover-inner-content {
  padding-left: 10px;
  padding-right: 10px;
}
.header-popover.wallet .header-popover-inner-content li {
  width: 100%;
  display: flex;
  padding: 4px 16px;
  justify-content: space-between;
  cursor: default;
  color: #222;
}
.UnderMaintenance .balance,
.UnderMaintenance .localizedName {
  filter: grayscale(100%);
  opacity: 0.4;
}
.header-popover.wallet .header-popover-inner-content li > span:first-child {
  max-width: 80%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.header-popover.wallet .header-popover-inner-content li .null {
  color: #999;
}
.header-popover {
  opacity: 0;
  width: 500px;
  height: 0;
  min-width: 316px;
  right: 110px;
  top: 166px;
  transform-origin: 26px top;
  padding-top: 15px;
  position: absolute;
  transform: scale(0);
}
.header-popover.show {
  animation: popverIn 0.3s cubic-bezier(0.42, 0, 0.24, 0.98);
  animation-fill-mode: forwards;
}
.header-popover.hide {
  animation: popverOut 0.3s cubic-bezier(0.42, 0, 0.24, 0.98);
  animation-fill-mode: forwards;
}
.header-popover-arrow {
  position: absolute;
  display: block;
  width: 8.48528137px;
  height: 8.48528137px;
  background: transparent;
  border-style: solid;
  border-width: 4.24264069px;
  top: 11px;
  left: 6%;
  border-top-color: #0073cb;
  border-right-color: transparent;
  border-bottom-color: transparent;
  border-left-color: #0073cb;
  transform: translateX(50%) rotate(45deg);
}
.header-popover-arrow.loading {
  border-top-color: #bebdc2;
  border-left-color: #bebdc2;
  box-shadow: -2px -2px 5px #fff;
}
.header-popover-inner {
  background-color: #fff;
  background-clip: padding-box;
  border-radius: 6px;
  overflow: hidden;
  box-shadow: #dedede 0px 1px 6px;
}
.header-popover-inner-title {
  display: flex;
  line-height: 2.2;
  color: #fff;
  font-size: 12px;
  padding: 20px 20px 15px 20px;
  background-color: #0073cb;
}
.user-picture-wrap {
  width: 46px;
  height: 46px;
  background-color: #ccc;
  margin-right: 20px;
  border-radius: 50%;
  overflow: hidden;
}
.user-picture-wrap > img {
  width: 100%;
}
.user-title-wrap h4 {
  color: #fff;
  font-weight: bold;
  line-height: 14px;
  height: 14px;
}
.user-info-thumb {
  font-size: 12px;
  line-height: 14px;
  margin: 1px 0;
}
.user-info-thumb > span:nth-child(2) {
  width: 1px;
  height: 100%;
  background-color: #fff;
  margin: 0 10px;
}
.user-title-wrap .user-exit {
  position: absolute;
  opacity: 0.8;
  right: 20px;
  top: 30px;
  height: 24px;
  font-size: 12px;
  border-radius: 12px;
  color: #ffffff;
  border-color: #ffffff;
}
.user-title-wrap .user-exit:hover {
  opacity: 1;
}
.user-title-wrap .tlc-sprite {
  width: 14px;
  height: 14px;
  display: inline-block;
  vertical-align: -0.24em;
  margin-right: 6px;
  border-radius: 50%;
}
.user-title-wrap .tlc-sprite.member-grade._1 {
  background-color: #f9a194;
  background-position: -147px -135px;
}
.user-title-wrap .tlc-sprite.member-grade._2 {
  background-color: #f9a194;
  background-position: -166px -135px;
}
.user-title-wrap .money-light {
  color: #ffee00;
}
.header-popover-inner-content {
  padding: 15px 0;
  overflow: hidden;
}
.header-popover-inner-content > li {
  float: left;
  border-radius: 5px;
  box-sizing: border-box;
  padding: 4px 6px;
  margin: 1px 0;
  cursor: pointer;
  position: relative;
  border: 1px dashed transparent;
  border-radius: 15px;
  font-size: 12px;
  width: 30%;
}
.header-popover-inner-content > li:nth-child(1),
.header-popover-inner-content > li:nth-child(4),
.header-popover-inner-content > li:nth-child(7) {
  width: 40%;
}
.header-popover-inner-content > li.remind-circle::before {
  right: 50px;
  top: 6px;
}
.header-popover-inner-content > li:hover {
  background-color: #eaeaea;
}
.header-popover-inner-content .tlc-sprite {
  width: 16px;
  height: 16px;
  margin-right: 8px;
  display: inline-block;
  vertical-align: -0.2em;
}
.has-header-center .header-popover-inner-content > li.betrecords,
.has-header-center .header-popover-inner-content > li.mybonus,
.has-header-center .header-popover-inner-content > li.changerecords {
  border-color: #eb1717;
}
.header-popover-inner-content .user-info {
  background-image: url("/vn/img/home/icon/icon-profile.svg");
}
.header-popover-inner-content .tlc-sprite.my-promotion {
  background-image: url("/vn/img/home/icon/icon-rewards.svg");
}
.header-popover-inner-content .tlc-sprite.record {
  background-image: url("/vn/img/home/icon/icon-transaction.svg");
}
.header-popover-inner-content .tlc-sprite.bank-user {
  background-image: url("/vn/img/home/icon/icon-bank.svg");
}
.header-popover-inner-content .tlc-sprite.my-bonus {
  background-image: url("/vn/img/home/icon/icon-rebate.svg");
}
.header-popover-inner-content .tlc-sprite.bet-record {
  background-image: url("/vn/img/home/icon/icon-bet.svg");
}
.header-popover-inner-content .tlc-sprite.message-center {
  background-image: url("/vn/img/home/icon/icon-message.svg");
}
.header-popover-inner-content .tlc-sprite.smartico {
  background-image: url("/vn/img/home/icon/icon-smartico.svg");
  background-position: center;
}
.header-popover-inner-content .smartico {
  display: flex;
  justify-content: flex-start;
}
.header-popover-inner-content .tlc-sprite.pt-account {
  background-position: -297px -92px;
}
.header-popover-inner-content .tlc-sprite.change-record {
  background-position: -276px -91px;
}
.tlc-notice-wrapper {
  padding-top: 8px;
}
.login-wrap,
.user-center-btn,
.wallet-center-btn {
  display: inline-block;
  vertical-align: top;
  padding-right: 10px;
}
.login-wrap .forget-password {
  display: flex;
  justify-content: end;
}
.login-wrap .forget-password .ant-btn-link {
  font-size: 12px;
  padding: 0 !important;
}
.input-wrap .ant-input {
  width: 120px;
  margin-left: 10px;
  display: inline-block;
}
.tlc-sign {
  text-align: right;
}
.tlc-sign-header.hidden {
  pointer-events: none;
  opacity: 0;
}
.tlc-sign-header .tlc-sign > .tlc-sprite {
  width: 60px;
  height: 60px;
  margin-left: 5px;
  display: inline-block;
  vertical-align: top;
  cursor: pointer;
}
.tlc-sign-header .tlc-sign > .notice-section-btn.login-btn,
.header-warp .ant-menu-item.header-btn.login-btn {
  background-image: linear-gradient(260deg, #52c2ff 0%, #00a6ff 100%);
  background-image: -webkit-linear-gradient(260deg, #52c2ff 0%, #00a6ff 100%);
  border: 1px solid #ffffff80 !important;
}
.header-warp .ant-menu-item.header-btn.login-btn {
  margin: 0;
  padding: 0;
}
.tlc-sign-header .tlc-sign > .notice-section-btn.register-btn,
.header-warp .ant-menu-item.header-btn.register-btn {
  background-image: linear-gradient(260deg, #0ccb3b 0%, #0ccb3b 100%);
  background-image: -webkit-linear-gradient(260deg, #0ccb3b 0%, #0ccb3b 100%);
  border: 1px solid #ffffff80 !important;
}
.tlc-sign-header .tlc-sign > .tlc-sprite.login-btn {
  background-position: -193px 0px;
}
.tlc-sign-header .tlc-sign > .tlc-sprite.register-btn {
  background-position: -258px 0px;
}
.tlc-sign-header .tlc-sign > .tlc-sprite.deposit-btn {
  background-position: -2px 0px;
}
.tlc-sign-header .tlc-sign > .tlc-sprite.withdraw-btn {
  background-position: -67px 0px;
}
.tlc-sign-header .tlc-sign > .tlc-sprite.transfer-btn {
  background-position: -130px 0px;
}
.tlc-container-wrapper {
  padding-top: 114px;
  position: relative;
}
.tlc-container-wrapper-Diamond {
  padding-top: 114px;
  position: relative;
  background-image: url("/vn/img/diamondClub/DiamondBackground.jpg");
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.tlc-sign-header.common-distance {
  margin-top: 15px;
  margin-bottom: 20px;
  transition: opacity 0.2s;
  transform: translateZ(0px);
}
.wallet-center-btn {
  width: 130px;
  text-align: center;
}
.wallet-center-btn > span:nth-child(1) {
  width: 78px;
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
  vertical-align: -0.6em;
  font-size: 12px;
  padding-right: 5px;
  text-align: left;
}
.user-center-btn,
.wallet-center-btn {
  padding: 0 8px;
  line-height: 26px;
  height: 26px;
  background-color: #fff;
  color: #fff;
  box-shadow: 0 0 3px #ddd;
  margin-top: 5px;
  margin-right: 10px;
  border-radius: 5px;
  cursor: pointer;
  color: #222222;
  transition: background-color 0.2s;
}
.user-center-btn:hover,
.user-center-btn.active,
.wallet-center-btn.active,
.wallet-center-btn:hover {
  background-color: #0073cb;
  color: #fff;
}
.good-greeting {
  color: #000;
  text-align: left;
  padding-left: 10px;
  padding-bottom: 3px;
  font-weight: bold;
  display: flex;
}
.good-greeting .nameLoading {
  width: 200px;
  height: 22px;
  background: linear-gradient(90deg, #f2f2f2 25%, #e6e6e6 37%, #f2f2f2 63%) 0% 0% no-repeat padding-box padding-box transparent;
  background-size: 400% 100%;
  animation: ant-skeleton-loading 1.4s ease infinite;
}
.ant-menu-submenu.ant-menu-submenu-popup .ant-menu-item {
  color: #222;
}
.ant-menu-item .user-center-btn,
.ant-menu-item .wallet-center-btn {
  margin-top: 9px;
}
.ant-menu-item .user-center-btn .tlc-sprite.user-message,
.input-wrap .user-center-btn .tlc-sprite.user-message {
  width: 16px;
  height: 16px;
  display: inline-block;
  vertical-align: middle;
  margin: 0 10px;
  background-position: -104px -134px;
  position: relative;
}
.user-center-btn:hover .tlc-sprite.user-message,
.user-center-btn.active .tlc-sprite.user-message {
  background-position: -125px -134px;
}
.wallet-center-btn .anticon {
  margin-left: 10px;
}
.user-center-btn:hover .anticon,
.user-center-btn.active .anticon,
.wallet-center-btn.active .anticon,
.wallet-center-btn:hover .anticon {
  transform: rotate(180deg);
}
.menu-wrapper {
  position: relative;
}
.tlc-sprite {
  background-image: url("/vn/img/home/sprite20230330.png");
  background-repeat: no-repeat;
}
.header-warp {
  color: #fff;
  padding-top: 10px;
  background-color: #00a6ff;
  border-radius: 0 0 50px 50px;
  border: 5px solid #1299f7;
  border-top-width: 0;
  box-sizing: border-box;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 999;
}
.header-warp .common-distance {
  width: 1260px;
  padding: 0;
}
.header-warp .ant-menu.Header-Menu-list-Right {
  text-align: right;
  display: flex !important;
  justify-content: space-between !important;
  gap: 15px;
  padding-left: 85px;
}
.header-warp .ant-menu.Header-Menu-list,
.header-warp .ant-menu.Header-Menu-list-Right {
  position: static;
  height: 37px;
}
.header-warp .ant-menu.Header-Menu-list .ant-menu-submenu,
.header-warp .ant-menu.Header-Menu-list-Right .ant-menu-submenu,
.header-warp .ant-menu.Header-Menu-list .ant-menu-item,
.header-warp .ant-menu.Header-Menu-list-Right .ant-menu-item {
  position: static;
  padding-left: 0;
  padding-right: 0;
}
.header-warp .ant-menu.Header-Menu-list .ant-menu-item,
.header-warp .ant-menu.Header-Menu-list-Right .ant-menu-item {
  text-shadow: 1px 2px #000;
  padding-bottom: 15px;
  line-height: 24px;
}
.header-warp .header-Row .ant-menu,
.header-warp .header-Row .ant-menu.right-head {
  color: #fff;
  text-align: left;
}
.header-warp .header-Row .menu-wrapper .ant-menu-horizontal a {
  color: #fff;
}
.header-warp .menu-wrapper .ant-menu.Header-Menu-list .ant-menu-item,
.header-warp .menu-wrapper .ant-menu.Header-Menu-list-Right .ant-menu-item {
  text-shadow: none;
}
.header-warp .menu-wrapper .ant-menu.Header-Menu-list .ant-menu-item .gameCatNew,
.header-warp .menu-wrapper .ant-menu.Header-Menu-list-Right .ant-menu-item .gameCatNew {
  position: relative;
  top: -6px;
  left: 1px;
}
.header-warp .menu-wrapper .ant-menu.Header-Menu-list .ant-menu-item .gameCatComingSoon,
.header-warp .menu-wrapper .ant-menu.Header-Menu-list-Right .ant-menu-item .gameCatComingSoon {
  font-size: 0.25rem;
  background: #ff4747;
  color: #fff;
  padding: 0 3px;
  height: 0.4rem;
  line-height: 0.36rem;
  position: absolute;
  top: 0px;
  right: -20px;
  border-radius: 0.1rem;
  min-width: 10%;
}
.headerHoverBox > li {
  color: #000;
}
.header-warp .ant-menu.Header-Menu-list .ant-menu-item > .Menu_item,
.header-warp .ant-menu.Header-Menu-list-Right .ant-menu-item > .Menu_item {
  height: 35px;
  text-align: center;
  padding-left: 6px;
  padding-right: 6px;
  position: relative;
}
.header-warp .ant-menu.Header-Menu-list .ant-menu-item .tlc-sprite,
.header-warp .ant-menu.Header-Menu-list-Right .ant-menu-item .tlc-sprite {
  background-position: -54px -136px;
  width: 18px;
  height: 8px;
  display: inline-block;
  transition: all 0.5s;
}
.header-warp .ant-menu.Header-Menu-list .ant-menu-item:hover .tlc-sprite,
.header-warp .ant-menu.Header-Menu-list-Right .ant-menu-item:hover .tlc-sprite,
.header-warp .ant-menu.Header-Menu-list .ant-menu-item.ant-menu-item-active .tlc-sprite,
.header-warp .ant-menu.Header-Menu-list-Right .ant-menu-item.ant-menu-item-active .tlc-sprite {
  background-position: -76px -136px;
}
.header-warp .ant-menu.Header-Menu-list .ant-menu-item .tlc-sprite.home,
.header-warp .ant-menu.Header-Menu-list-Right .ant-menu-item .tlc-sprite.home {
  background-position: 2px -128px;
  width: 24px;
  height: 24px;
  vertical-align: top;
  display: inline-block;
}
.rewardsCentre-btn .rewardsCentre {
  width: 100px;
  height: 38px;
  background-image: url("/vn/img/home/header/header_smartico.webp");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  cursor: pointer;
}
.rewardsCentre-btn .rewardsCentre:hover {
  background-image: url("/vn/img/home/header/header_smartico_hover.webp");
}
.header-warp .menu-placeholder-bar .menu-placeholder-inner-bar {
  border-top: 4px solid #00a6ff;
  padding-left: 20px;
  padding-right: 20px;
  height: inherit;
  height: 100%;
  top: -180px;
  position: relative;
  cursor: default;
  transition: top 0.3s ease-in-out 0.08s;
}
.header-warp .menu-placeholder-bar .menu-placeholder-inner-bar .menu-sign-bar {
  font-weight: bold;
}
.header-warp .menu-placeholder-bar .menu-bar {
  float: left;
  padding: 15px 4px;
  height: 180px;
  height: inherit;
  width: 70%;
}
.header-warp .menu-placeholder-bar .menu-bar-pages {
  text-align: center;
}
.header-warp .menu-placeholder-bar .ant-carousel {
  line-height: 1.7;
}
.header-warp .menu-placeholder-bar .ant-carousel .slick-dots-bottom {
  bottom: 0px;
}
.header-warp .menu-placeholder-bar .ant-carousel .slick-dots li {
  margin: 0 5px;
}
.header-warp .menu-placeholder-bar .ant-carousel .slick-dots li button {
  width: 8px;
  height: 8px;
  background: #ababab;
  border-radius: 50%;
}
.header-warp .menu-placeholder-bar .ant-carousel .slick-dots li.slick-active button {
  background: #666;
}
.helpMenu {
  display: flex;
  align-items: center;
}
.helpMenu .helpCenter a {
  height: 33px;
  display: flex !important;
  align-items: center;
  font-size: 14px;
  padding-left: 6px !important;
}
.header-warp .menu-placeholder-bar .ant-carousel .slick-arrow {
  width: 32px;
  height: 32px;
  text-align: center;
  z-index: 1;
  top: 36%;
  transition-duration: 0.1s;
}
.header-warp .menu-placeholder-bar .ant-carousel .slick-arrow.slick-prev {
  left: 60px;
  background-image: url("/vn/img/home/icon/icon-arrow-left-inactive.png");
  transition-duration: 0.1s;
}
.header-warp .menu-placeholder-bar .ant-carousel .slick-arrow.slick-next {
  right: 50px;
  background-image: url("/vn/img/home/icon/icon-arrow-right-inactive.png");
  transition-duration: 0.1s;
}
.header-warp .menu-placeholder-bar .ant-carousel .slick-arrow.slick-prev:hover {
  background-image: url("/vn/img/home/icon/icon-arrow-left.png");
  transform: scale(1.2);
  transition-duration: 0.1s;
}
.header-warp .menu-placeholder-bar .ant-carousel .slick-arrow.slick-next:hover {
  background-image: url("/vn/img/home/icon/icon-arrow-right.png");
  transform: scale(1.2);
  transition-duration: 0.1s;
}
.header-warp .menu-placeholder-bar .menu-section,
.header-warp .menu-placeholder-bar .menu-sign-bar {
  float: left;
  text-shadow: none;
  color: #000;
  width: 15%;
  height: 100%;
  text-align: left;
  box-sizing: border-box;
  line-height: 1;
  padding: 20px 15px 5px 15px;
}
.header-warp .menu-placeholder-bar .menu-section p,
.header-warp .menu-placeholder-bar .menu-sign-bar p {
  margin-bottom: 20px;
}
.header-warp .menu-placeholder-bar .menu-sign-bar > h2 {
  font-weight: bold;
}
.header-warp .menu-placeholder-bar .menu-sign-bar > .sign-brief {
  margin-top: 20px;
  font-size: 14px;
}
.header-warp .menu-placeholder-bar .menu-sign-bar > .sign-brief > p:nth-child(2) {
  font-size: 38px;
}
.header-warp .menu-placeholder-bar .menu-sign-bar > .sign-brief > p:nth-child(2) > span {
  font-size: 16px;
}
.header-warp .menu-placeholder-bar .menu-section {
  white-space: normal;
  line-height: 1.5;
  font-weight: 500;
  position: relative;
  width: 140px;
  height: 170px;
}
.header-warp .menu-placeholder-bar .menu-section.app-down {
  padding-top: 8px;
}
.header-warp .menu-placeholder-bar .menu-section > h3 {
  text-align: center;
  font-weight: bold;
  margin: 0;
}
.header-warp .menu-placeholder-bar .menu-section > div {
  width: 80px;
  height: 80px;
  background: #ddd;
  border-radius: 5px;
  margin: 10px auto 6px auto;
}
.header-warp .menu-placeholder-bar .menu-section > .app-down-address {
  white-space: normal;
  word-break: break-all;
  width: 100%;
  overflow: auto;
  margin-top: 6px;
  font-size: 12px;
  color: #fff;
  text-align: center;
}
.header-warp .menu-placeholder-bar .menu-section > p {
  font-size: 12px;
  font-weight: normal;
  white-space: pre;
  word-break: break-all;
}
.tlc-sign-header .tlc-sign .notice-section-btn,
.header-warp .ant-menu-item.header-btn,
.tlc-sign-header .tlc-sign > .tlc-sprite {
  box-sizing: border-box;
  font-size: 12px;
  text-align: center;
  width: 68px;
  height: 28px;
  line-height: 28px;
  border-radius: 6px;
  box-shadow: 0 1px 2px #666;
  margin: 0 5px;
  vertical-align: middle;
  transition: all 0.2s;
  color: #fff !important;
  display: inline-block;
  cursor: pointer;
}
.tlc-sign-header .tlc-sign .notice-section-btn {
  margin-bottom: 4px;
  vertical-align: bottom;
}
.tlc-sign-header .tlc-sign > .notice-section-btn.login-btn,
.header-warp .ant-menu-item.header-btn.login-btn {
  background-image: linear-gradient(260deg, #52c2ff 0%, #00a6ff 100%);
  background-image: -webkit-linear-gradient(260deg, #52c2ff 0%, #00a6ff 100%);
}
.tlc-sign-header .tlc-sign > .notice-section-btn.register-btn,
.header-warp .ant-menu-item.header-btn.register-btn {
  background-image: linear-gradient(260deg, #0ccb3b 0%, #0ccb3b 100%);
  background-image: -webkit-linear-gradient(260deg, #0ccb3b 0%, #0ccb3b 100%);
}
.header-warp .menu-placeholder-bar .menu-section .ant-btn-background-ghost.ant-btn-link {
  padding: 0;
  position: absolute;
  bottom: 0px;
  right: 0;
  font-size: 16px;
  color: #000;
  font-weight: bold;
  transition-duration: 0.1s;
}
.header-warp .menu-placeholder-bar .menu-section .anticon {
  letter-spacing: 28px;
  font-size: 16px;
  margin: 0;
  font-weight: bold;
}
.header-warp .menu-placeholder-bar .menu-section .anticon:last-child {
  margin-left: -8px;
}
.header-warp .menu-placeholder-bar.casino .menu-bar-pages,
.header-warp .menu-placeholder-bar.slotgame .menu-bar-pages {
  margin-top: 5px;
}
.header-warp .menu-placeholder-bar.instantGames .sign-color,
.header-warp .menu-placeholder-bar.instantGames .menu-section .ant-btn-background-ghost.ant-btn-link:hover {
  color: #b620e0;
  transform: scale(1.2);
  transition-duration: 0.1s;
  transform: translateX(6px);
}
.header-warp .menu-placeholder-bar.lottery .sign-color,
.header-warp .menu-placeholder-bar.lottery .menu-section .ant-btn-background-ghost.ant-btn-link:hover {
  color: #2994ff;
  transform: scale(1.2);
  transition-duration: 0.1s;
  transform: translateX(6px);
}
.header-warp .menu-placeholder-bar.sport .sign-color,
.header-warp .menu-placeholder-bar.sport .menu-section .ant-btn-background-ghost.ant-btn-link:hover {
  color: #2994ff;
  transform: scale(1.2);
  transition-duration: 0.1s;
  transform: translateX(6px);
}
.header-warp .menu-placeholder-bar.imsport .sign-color,
.header-warp .menu-placeholder-bar.imsport .menu-section .ant-btn-background-ghost.ant-btn-link:hover {
  color: #2994ff;
  transform: scale(1.2);
  transition-duration: 0.1s;
  transform: translateX(6px);
}
.header-warp .menu-placeholder-bar.casino .sign-color,
.header-warp .menu-placeholder-bar.casino .menu-section .ant-btn-background-ghost.ant-btn-link:hover {
  color: #2994ff;
  transform: scale(1.2);
  transition-duration: 0.1s;
  transform: translateX(6px);
}
.header-warp .menu-placeholder-bar.chess .sign-color,
.header-warp .menu-placeholder-bar.chess .menu-section .ant-btn-background-ghost.ant-btn-link:hover {
  color: #2994ff;
  transform: scale(1.2);
  transition-duration: 0.1s;
  transform: translateX(6px);
}
.header-warp .menu-placeholder-bar.slotgame .sign-color,
.header-warp .menu-placeholder-bar.slotgame .menu-section .ant-btn-background-ghost.ant-btn-link:hover {
  color: #2994ff;
  transform: scale(1.2);
  transition-duration: 0.1s;
  transform: translateX(6px);
}
.header-warp .menu-placeholder-bar.shop .sign-color,
.header-warp .menu-placeholder-bar.more .menu-section .ant-btn-background-ghost.ant-btn-link:hover {
  color: #00a6ff;
  transform: scale(1.2);
  transition-duration: 0.1s;
  transform: translateX(6px);
}
.header-warp .menu-placeholder-bar.more .sign-color,
.header-warp .menu-placeholder-bar.more .menu-section .ant-btn-background-ghost.ant-btn-link:hover {
  color: #00a6ff;
  transform: scale(1.2);
  transition-duration: 0.1s;
  transform: translateX(6px);
}
.header-warp .ant-menu-item:hover .menu-placeholder-bar {
  height: auto;
}
.header-warp .ant-menu-item:hover .menu-placeholder-inner-bar {
  top: 0;
  display: flex;
  flex-flow: column;
}
.header-warp .head-menu-right {
  position: absolute;
  top: 0;
  right: 5px;
}
.header-warp .ant-menu-item.header-btn {
  box-sizing: border-box;
  margin: 0 5px;
  vertical-align: middle;
  transition: all 0.2s;
  color: #fff !important;
  width: auto;
}
.header-warp .ant-menu-item {
  position: relative;
  display: block;
  margin: 0;
  padding: 0 10px;
  white-space: nowrap;
  cursor: pointer;
  transition: color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), border-color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), background 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), padding 0.15s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.header-warp .ant-menu-item.header-btn:hover,
.header-warp .ant-menu-item.header-btn:active {
  border: 1px solid rgba(255, 255, 255, 0.5) !important;
  box-shadow: 0 5px 3px #131313;
  transform: translateY(-2px);
}
.header-warp .logo-wrap {
  width: 140px;
  cursor: pointer;
  margin-left: 20px;
}
.header-warp .next-menu-bar {
  height: 40px;
  line-height: 40px;
  transition: height 0.3s;
  margin-top: 12px;
  margin-bottom: 12px;
  display: flex;
}
.header-warp .next-menu-bar .tlc-title-partner {
  vertical-align: baseline;
  line-height: 1.3;
  margin-top: 1px;
  margin-right: 10px;
  margin-bottom: 1px;
}
.header-warp .next-menu-bar .tlc-sprite {
  display: inline-block;
  width: 32px;
  height: 32px;
}
.header-warp .next-menu-bar .tlc-sprite.xijia {
  background-position: -29px -52px;
  background-size: 214px;
  vertical-align: -0.4em;
  width: 22px;
  height: 22px;
  margin-left: 12px;
}
.header-warp .next-menu-bar .tlc-sprite.ehome {
  background-position: -87px -79px;
}
.header-warp .next-menu-bar .ant-col:nth-child(2) {
  text-align: right;
}
.drawer-content .live-service,
.drawer-content .live-service {
  margin: 3px 10px;
  box-shadow: 0 1px 2px #666;
  vertical-align: -0.8em;
  background-position: 0 -81px;
  transition: all 0.2s;
  cursor: pointer;
}
.header-warp .header-Row .live-server-btn {
  display: grid;
  place-items: center;
  padding-top: 5px;
}
.header-warp .header-Row .live-server-btn .live-service {
  width: 34px;
  height: 34px;
  background-position: 0 -81px;
  transition: all 0.2s;
  cursor: pointer;
  transform: scale(0.86);
}
.header-warp .next-menu-bar .zoom-in-section {
  height: 0;
  margin: 0;
  padding: 0;
  opacity: 0;
  overflow: hidden;
  transform: translateZ(0deg);
  transition: opacity 0.3s, width 0s ease 0s, height 0s ease 0.3s, margin 0s ease 0.3s, padding 0s ease 0.3s;
}
.header-warp .next-menu-bar .zoom-out-section {
  width: 400px;
  height: 38px;
  line-height: 38px;
  margin: 0;
  padding: 0;
  opacity: 0;
  overflow: hidden;
  opacity: 1;
  transition: opacity 0.3s, width 0s ease 0s, height 0s ease 0s, margin 0s ease 0s, padding 0s ease 0s;
  transform: translateZ(0deg);
}
.header-warp.zoom-out .zoom-in-section {
  height: 50px;
  margin: 0;
  padding: 0;
  opacity: 1;
  transform: translateZ(0px);
  transition: opacity 0.3s, width 0s ease 0s, height 0s ease 0s, margin 0s ease 0s, padding 0s ease 0s;
}
.header-warp.zoom-out .zoom-in-section li:nth-child(2) {
  padding: 0 5px;
}
.header-warp.zoom-out .zoom-out-section {
  height: 0;
  margin: 0;
  padding: 0;
  opacity: 0;
  transform: translateZ(0px);
  transition: opacity 0.3s, width 0s ease 0s, height 0s ease 0.3s, margin 0s ease 0.3s, padding 0s ease 0.3s;
}
.menu-bar-children {
  display: inline-block;
  padding: 4px 8px;
  cursor: pointer;
  font-weight: unset;
  transition: all 0.3s;
  position: relative;
}
.menu-bar-children.NEW::before,
.menu-bar-children.HOT::before,
.menu-bar-children.RECOMMEND::before {
  background-image: url("/vn/img/home/sprite20230330.png");
  background-repeat: no-repeat;
  content: "";
  position: absolute;
  width: 50px;
  height: 50px;
  left: 8px;
  top: 4px;
}
.menu-bar-children.NEW::before {
  background-position: 0 -161px;
}
.menu-bar-children.HOT::before {
  background-position: -54px -161px;
}
.menu-bar-children.RECOMMEND::before {
  background-position: 0 -318px;
}
.menu-bar-children.NEW p::after,
.menu-bar-children.HOT p::after,
.menu-bar-children.RECOMMEND p::after,
.txt-right.NEW h3::after,
.txt-right.HOT h3::after,
.txt-right.RECOMMEND h3::after {
  width: 42px;
  height: 20px;
  line-height: 20px;
  vertical-align: baseline;
  border-radius: 10px;
  display: inline-block;
  font-weight: normal;
  font-weight: initial;
  font-size: 12px;
  transform: scale(0.82);
  text-align: center;
}
.menu-bar-children.NEW p::after,
.txt-right.NEW h3::after {
  content: "新品";
  background-color: #2190f4;
  color: #fff;
}
.menu-bar-children.HOT p::after,
.txt-right.HOT h3::after {
  content: "热门";
  background-color: #00a6ff;
  color: #fafafa;
}
.menu-bar-children.RECOMMEND p::after,
.txt-right.RECOMMEND h3::after {
  content: "推荐";
  background-color: #393939;
  color: #fdeb01;
}
.menu-bar-children p {
  color: #222222;
  font-weight: 500;
  margin: 0;
  margin-top: 4px;
  text-shadow: none;
  text-align: center;
  width: 120px;
  line-height: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
}
.menu-bar-children:hover {
  transform: translateY(-4px);
}
.menu-bar-children img {
  border-radius: 10px;
}
.ant-menu-submenu .ant-menu-vertical > .ant-menu-item {
  height: 30px;
  line-height: 30px;
  margin: 0;
}
.ant-menu-submenu .ant-menu-vertical > .ant-menu-item a {
  color: rgba(0, 0, 0, 0.9);
}
.ant-menu-submenu .ant-menu-vertical > .ant-menu-item:nth-child(2n) {
  background-color: rgba(0, 0, 0, 0.1);
}
.ant-menu-submenu .ant-menu-vertical > .ant-menu-item.ant-menu-item-disabled {
  cursor: default;
  color: rgba(0, 0, 0, 0.8) !important;
}
.ant-menu-submenu .ant-menu-vertical > .ant-menu-item:hover,
.ant-menu-submenu .ant-menu-vertical > .ant-menu-item:hover a {
  color: #00a6ff;
}
.header-warp .ant-menu-horizontal {
  border-bottom: 0 !important;
  line-height: inherit;
}
.header-warp .ant-menu-horizontal.ant-menu-horizontal > .ant-menu-item:hover {
  color: #fff;
}
.ant-menu-horizontal .ant-menu-item,
.ant-menu-horizontal .ant-menu-item:hover,
.ant-menu-horizontal .ant-menu-item:active,
.ant-menu-horizontal .ant-menu-submenu {
  border-bottom: 0 !important;
}
#t_header_notice_wrapper.notice-wrap {
  height: 48px;
  line-height: 48px;
  position: relative;
}
#t_header_notice_wrapper .manager-picture {
  position: absolute;
  left: 0;
  top: 0;
  width: 48px;
  height: 48px;
  overflow: hidden;
  border-radius: 50%;
}
#t_header_notice_wrapper .manager-picture > img {
  width: 100%;
  cursor: pointer;
}
#t_header_notice_wrapper .marquee-container-wrapper {
  font-size: 12px;
  line-height: 2;
  padding-left: 60px;
  color: #222;
  cursor: pointer;
}
#t_header_notice_wrapper .marquee-title > span:first-child {
  color: #000;
  font-weight: bold;
  margin-right: 10px;
}
#t_header_notice_wrapper .marquee-title > span:last-child {
  color: #999;
  font-size: 12px;
  transform: scale(0.84, 0.84);
  *font-size: 10px;
}
#t_header_notice_wrapper .tlc-notice-wrap {
  overflow: hidden;
  height: 26px;
  line-height: 26px;
  padding: 0 10px;
  background-color: #ebebeb;
  border-radius: 13px;
  margin-right: 20px;
}
@keyframes popverIn {
  0% {
    transform: scale(0);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}
@keyframes popverOut {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  100% {
    transform: scale(0);
    opacity: 0;
  }
}
.HoverMenuBox {
  display: flex;
  background-color: white;
  height: 430px;
  width: 100%;
  position: absolute;
  left: 0;
  right: 0;
  top: 41px;
  border-radius: 0px 0px 20px 20px;
}
.btn-header-register {
  background-color: #0ccb3b;
}
.btn-header-register:hover {
  background-color: #0ccb3b !important;
}
.menu-bar-pages .ant-carousel {
  width: 95%;
  margin: auto;
}
.menu-bar-pages .ant-carousel .slick-slider {
  display: flex;
  justify-content: center;
}
.menu-bar-pages .ant-carousel .slick-list .slick-slide.slick-active {
  max-width: 128px;
}
.menu-bar-pages .ant-carousel .slick-arrow.slick-prev {
  left: -14px !important;
}
.menu-bar-pages .ant-carousel .slick-arrow.slick-next {
  right: -21px !important;
}
.head-menu-left {
  color: black;
}
.head-menu-left .ant-menu-item {
  padding: 0;
  margin: 0 10px;
}
.head-menu-left .ant-menu-item > a {
  padding: 0 10px;
}
.head-menu-left .ant-menu-item > a:hover {
  background: #ffffff;
  color: #00a6ff;
  border-radius: 18px;
}
.head-menu-left .ant-menu-item:active {
  background: #ffffff;
  color: #00a6ff;
  border-radius: 18px;
}
.head-menu-left .ant-menu-item-selected {
  color: #fff;
}
.head-menu-left.ant-menu-horizontal .ant-menu-item-selected > a {
  color: #fff;
}
.firstcasino .slick-prev {
  display: none !important;
}
.lastcasino .slick-next {
  display: none !important;
}
.firstslot .slick-prev {
  display: none !important;
}
.lastslot .slick-next {
  display: none !important;
}
.firstmore .slick-prev {
  display: none !important;
}
.lastmore .slick-next {
  display: none !important;
}
.ant-menu-item .SetNew {
  position: absolute;
  display: flex;
  border-radius: 4px;
  height: 20px;
  border: 1px solid #ff4141;
  background-color: #ff4141;
  justify-content: center;
  align-items: center;
  color: white;
  top: -10px;
  right: 0;
  font-size: 12px;
  padding: 0 3px;
  transform: scale(0.8);
}
.ant-menu-item .SetHot {
  position: absolute;
  display: flex;
  border-radius: 4px;
  height: 20px;
  border: 1px solid #fdb454;
  background-color: #fdb454;
  justify-content: center;
  align-items: center;
  color: white;
  top: -10px;
  right: 0;
  font-size: 12px;
  padding: 0 3px;
  transform: scale(0.8);
}
.header-warp .menu-placeholder-bar {
  position: absolute;
  left: 0;
  top: 44px;
  background-color: rgba(255, 255, 255, 0.95);
  width: 100%;
  height: 0;
  border-radius: 0 0 16px 16px;
  overflow: hidden;
  transition: height 0.3s ease-in-out 0.08s;
}
.Header-Menu {
  display: flex !important;
  justify-content: space-between;
  align-items: center;
}
.Header-Menu .ant-menu-item {
  padding-bottom: 8px !important;
}
.Header-Menu .ant-menu-item-active .Menu_item {
  background-color: white !important;
  color: #00a6ff !important;
  border-radius: 20px !important;
  padding: 0px 5px;
}
.Header-Menu .ant-menu-item-active .Menu_item .tlc-sprite {
  background-position: -81px -136px;
}
.Header-Menu .Menu_item {
  display: flex;
  align-items: center;
}
.Header-Menu {
  width: 685px;
}
.Header-Menu .Home-Icon a {
  display: flex;
  height: 25px;
  align-items: center;
  font-size: 12px;
}
.Right_Menu {
  width: 270px;
}
.text-right {
  text-align: right !important;
}
@use autoprefixer;
.ant-menu-horizontal {
  border-bottom: 0 !important;
  line-height: inherit;
}
.user-center-wrap {
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 0 6px #ddd;
  margin-bottom: 40px;
  min-height: 986px;
  position: relative;
  overflow: hidden;
}
.user-center-wrap .home-section-title {
  margin-bottom: 21px;
}
.user-center-wrap .ant-form-item-label {
  padding-bottom: 0 !important;
  margin-bottom: 5px !important;
}
.user-center-wrap .user-center-account-profile-input-item {
  position: relative;
}
.user-center-wrap .user-center-account-profile-input-item .user-center-account-profile-input:disabled {
  background-color: #f4f2f2 !important;
  color: #222222;
  border-color: #e0e0e0;
  padding-right: 33%;
  text-overflow: ellipsis;
}
.user-center-wrap .user-center-account-profile-input-item .user-center-account-profile-input:disabled.realName,
.user-center-wrap .user-center-account-profile-input-item .user-center-account-profile-input:disabled.cardID {
  color: #999999;
}
.user-center-wrap .user-center-account-profile-input-item .verifiled-status-box {
  position: absolute;
  top: -91%;
  right: 0%;
  font-size: 12px;
  padding: 0 10px;
  display: flex;
  align-items: center;
  height: 45px;
  border-radius: 0 10px 10px 0;
  gap: 8px;
  background-color: #00a6ff;
  color: #fff;
  cursor: pointer;
}
.user-center-wrap .user-center-account-profile-input-item .verifiled-status-box img {
  width: 14px;
  height: 14px;
}
.user-center-wrap .user-center-account-profile-input-item .verifiled-status-box p {
  margin: 0;
  font-weight: bold;
}
.user-center-wrap .user-center-account-profile-input-item .verifiled-status-box.verified {
  color: #34c759;
  background-color: transparent;
  cursor: default;
}
.user-center-wrap .user-center-account-profile-input-item .verifiled-status-box.verified p {
  font-weight: 400;
}
.user-center-wrap .general-check-box .ant-checkbox-inner {
  border: #fff;
}
.user-center-wrap .general-check-box .ant-checkbox-wrapper:hover .ant-checkbox-inner,
.user-center-wrap .general-check-box .ant-checkbox:hover .ant-checkbox-inner .ant-checkbox.ant-checkbox-checked .ant-checkbox-input:focus + .ant-checkbox-inner {
  border-color: #fff !important;
}
.user-center-wrap .general-check-box .ant-checkbox-checked::after {
  border-color: #fff;
}
.user-center-wrap .general-check-box .ant-checkbox .ant-checkbox-inner {
  background-color: #ffffff;
  border-color: #fff;
}
.user-center-wrap .general-check-box .ant-checkbox .ant-checkbox-inner::after {
  border-color: #fff;
}
.user-center-wrap .general-check-box .ant-checkbox + span {
  color: #222222;
}
.user-center-wrap .general-check-box .ant-checkbox-checked.ant-checkbox-disabled + span {
  color: #222222;
}
.user-center-userinfo-comfirm-modal .ant-btn {
  color: #00a5fd;
  border: 1px solid #00a5fd;
}
.user-center-userinfo-comfirm-modal .ant-btn:hover,
.user-center-userinfo-comfirm-modal .ant-btn:focus {
  color: #29bbff;
  border-color: #29bbff;
}
.user-center-userinfo-comfirm-modal .ant-btn-primary {
  color: #fff;
  background-color: #00a6ff;
  border-color: #00a6ff;
}
.user-center-userinfo-comfirm-modal .ant-btn-primary:hover,
.user-center-userinfo-comfirm-modal .ant-btn-primary:focus {
  color: #fff;
}
.user-center-loading.ant-spin.ant-spin-spinning {
  text-align: center;
  position: fixed;
  padding-top: 20%;
  z-index: 2;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  background-color: rgba(255, 255, 255, 0.2);
}
.left-nav-wrap {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 240px;
  padding: 30px 0;
  margin: auto;
  color: #fff;
  text-align: center;
  background-color: #00a6ff;
}
.usercenter-mask {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 2;
}
.usercenter-mask.transparent {
  background-color: transparent;
}
.usercenter-mask.my-promotion {
  background-image: url("/vn/img/learn/my_promotion_learn.png");
  background-repeat: no-repeat;
  background-position: right top;
}
.usercenter-mask.my-promotion .learn-knew {
  margin-left: 600px;
  margin-top: 428px;
}
.usercenter-mask.records {
  background-image: url("/vn/img/learn/records_learn_1.png");
  background-repeat: no-repeat;
  background-position: right top;
}
.usercenter-mask.records .learn-knew {
  margin-left: 600px;
  margin-top: 540px;
}
.tlc-user-picture {
  width: 80px;
  height: 80px;
  line-height: 2.2;
  margin: auto;
  background-color: #00a6ff;
  border-radius: 50%;
  margin-bottom: 10px;
  overflow: hidden;
}
.tlc-user-picture > img {
  width: 100%;
}
.tlc-user-name {
  color: inherit;
  font-weight: bold;
}
.tlc-user-picture-wrap {
  padding-bottom: 20px;
}
.tlc-user-picture-wrap .user-info-thumb > span:nth-child(2) {
  background-color: #ffffff61;
}
.tlc-user-wallet-wrap {
  padding: 20px 25px;
}
.user-nav-list {
  line-height: 36px;
  padding-top: 20px;
  padding-bottom: 20px;
}
.user-nav-list > li {
  cursor: pointer;
  position: relative;
}
.user-nav-list > li span {
  color: #bfe9ff;
}
.user-nav-list > li .remind-circle {
  position: absolute;
  top: 5px;
  right: 80px;
}
.user-nav-list > li:hover,
.user-nav-list > li.active {
  font-weight: bold;
}
.user-nav-list > li:hover span,
.user-nav-list > li.active span {
  background-color: white;
  padding: 8px 10px;
  color: #00a6ff;
  border-radius: 8px;
}
.tlc-all-balance-title,
.tlc-currency-title {
  display: flex;
  justify-content: space-between;
  text-align: left;
  padding: 0 8px;
  padding-bottom: 4px;
}
.tlc-currency-title .anticon {
  opacity: 0.5;
  transition: opacity 0.3s;
  cursor: pointer;
}
.tlc-currency-title .anticon:hover {
  opacity: 1;
}
.tlc-all-balance,
.tlc-currency {
  border-radius: 5px;
  background-color: #fff;
  padding: 3px 10px;
  color: #000;
  border: 1px solid #999;
  font-weight: bold;
  margin-bottom: 8px;
}
.tlc-currency {
  color: #fff;
  background-color: #000;
  border-color: #000;
  box-shadow: 0 0 3px #363646;
}
.tlc-all-balance .inline-block:nth-child(1) {
  width: 90%;
  text-align: left;
}
.tlc-all-balance .inline-block:nth-child(2) {
  width: 10%;
}
.tlc-currency .inline-block:nth-child(2) {
  width: 80%;
}
.tlc-currency-wrap .small-sign {
  text-align: left;
  margin-bottom: 0;
  font-size: 12px;
  transform: scale(0.84, 0.84);
  *font-size: 10px;
  margin-left: -7px;
}
.tlc-currency-wrap .small-sign.gray {
  color: #999999;
}
.tlc-user-wallet-wrap .ant-btn-group {
  margin-bottom: 20px;
  border-radius: 5px;
  display: flex;
  justify-content: center;
}
.tlc-user-wallet-wrap .ant-btn {
  font-size: 12px;
}
.tlc-user-wallet-wrap .ant-btn-group > .ant-btn:first-child:not(:last-child),
.ant-btn-group > span:first-child:not(:last-child) > .ant-btn {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}
.tlc-user-wallet-wrap .ant-btn-group > .ant-btn:last-child:not(:first-child),
.ant-btn-group > span:last-child:not(:first-child) > .ant-btn {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}
.right-content-wrap {
  padding: 30px 50px 30px 290px;
}
.account-wrap > h2 {
  font-weight: bold;
  border-bottom: 1px solid #e7e7e7;
  padding-bottom: 6px;
  font-size: 24px;
}
.account-wrap.clear-border > h2 {
  border-bottom: 0;
}
.user-form-wrap .input-box {
  margin-bottom: 20px;
}
.user-form-wrap .ant-form-item .ant-form-explain {
  margin-top: 4px;
  background-color: #fee5e5;
  padding: 10px 16px;
  border-radius: 8px;
}
.user-form-wrap .ant-form-item .select-box .ant-select-selection__rendered > div {
  width: 100%;
  text-align: center;
}
.user-form-wrap .ant-form-item .select-box .ant-select-selection__rendered .ant-select-selection-selected-value {
  color: #222;
}
.user-form-wrap .ant-form-item .safety-question-select-box .ant-select-selection__rendered > div,
.user-form-wrap .ant-form-item .account-select-box .ant-select-selection__rendered > div {
  width: 100%;
  text-align: left;
}
.user-form-wrap .ant-form-item .ant-select-disabled .ant-select-selection {
  background-color: #f4f2f2;
  border-color: #e0e0e0;
}
.user-form-wrap .ant-form-item .select-box.ant-select-open {
  position: relative;
  z-index: 1;
}
.user-form-wrap .ant-form-item .select-box.ant-select-open::before {
  content: "请选择";
  width: 60px;
  height: 20px;
  background-color: transparent;
  position: absolute;
  z-index: 100;
  font-size: 14px;
  color: transparent;
  top: 25%;
  left: 5%;
  text-align: end;
}
.user-form-wrap .ant-form-item .select-box.ant-select-open .ant-select-selection {
  border-radius: 8px 8px 0 0 !important;
  border: 1px solid #cccccc;
  border-width: thin;
  box-shadow: unset;
}
.user-form-wrap .ant-form-item .ant-form-item-control-wrapper .has-error .ant-form-explain {
  font-size: 14px;
  font-weight: 500;
}
.user-form-wrap .user-info-dob-picker > div > input {
  cursor: default;
}
.user-form-wrap .user-info-dob-picker > div > input:disabled {
  background-color: #f4f2f2;
  border-color: #e0e0e0;
  color: #222;
}
.user-form-wrap .user-info-dob-picker > div > i {
  background-image: none !important;
  top: 12px !important;
  right: 5px !important;
  cursor: pointer;
}
.user-form-wrap .user-info-dob-picker > div > i svg {
  width: 30px;
  height: 30px;
  color: #cccccc;
}
.user-form-wrap .user-info-dob-picker.hasDob input::-moz-placeholder {
  color: #222;
}
.user-form-wrap .user-info-dob-picker.hasDob input:-ms-input-placeholder {
  color: #222;
}
.user-form-wrap .user-info-dob-picker.hasDob input::placeholder {
  color: #222;
}
.user-form-wrap .usercenter-button {
  text-align: right;
  border-bottom: 1px solid #e0e0e0;
  padding-bottom: 10px;
  position: relative;
  padding-left: 8px;
}
.user-form-wrap .usercenter-button .ant-btn-lg {
  padding: 0 40px;
  margin-left: 20px;
}
.user-form-wrap .usercenter-button .bottom-sign {
  position: absolute;
  top: 20px;
  left: 0;
}
.user-form-wrap .usercenter-button .btn-submit:disabled {
  background-color: #cccccc;
  color: #fff;
  border-color: #cccccc;
}
.user-form-wrap .ant-form-item {
  margin-bottom: 8px;
}
.bottom-sign {
  color: #999;
  line-height: normal;
  line-height: initial;
  margin: 0;
}
.bottom-sign-2 {
  background-color: #f6f6f6;
  padding: 15px;
}
.edit-disabeld {
  position: relative;
}
.edit-disabeld::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 6;
}
.edit-disabeld.enable::before {
  display: none;
}
.user-form-wrap .ant-checkbox-group {
  line-height: inherit;
}
.user-form-wrap .checkbox-correct .ant-form-item-children {
  height: 45px;
  display: block;
}
.usercenter-title-link {
  font-size: 14px;
  float: right;
  line-height: 40px;
}
.null-bankcards {
  margin-top: 80px;
}
.null-bankcards > p {
  color: #999;
  margin: 16px 0 40px 0;
}
.bank-list-item {
  float: left;
  width: 240px;
  height: 130px;
  margin-right: 25px;
  color: #fff;
  line-height: 1.4;
  margin-bottom: 18px;
  border-radius: 10px;
  padding: 10px;
  background: #3779d9;
  box-shadow: 0 2px 3px #ccc;
  z-index: 1;
  cursor: pointer;
}
.bank-list-item:nth-child(3n) {
  margin-right: 0;
}
.bank-list-item .bank-img {
  width: 40px;
  float: left;
  vertical-align: top;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.8);
}
.bank-list-item .bank-img > img {
  width: 40px;
  height: 40px;
}
.bank-list-item .bank-info {
  width: 180px;
  float: left;
  padding-left: 10px;
  white-space: nowrap;
  overflow: hidden;
}
.bank-list-item .bank-info > h3 {
  color: #fff;
  font-size: 14px;
  font-weight: bold;
  margin-top: 0.5em;
  margin-bottom: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.bank-list-item .bank-info > p {
  color: #f3f3f3;
}
.bank-list-item .bank-info .bank-number {
  margin-top: 5px;
  position: relative;
  z-index: 2;
}
.bank-list-item .bank-backgroundImg .bank-background {
  background-repeat: no-repeat;
  background-size: cover;
  opacity: 0.4;
  width: 40px;
  height: 40px;
  margin-top: 40px;
}
.bank-list-item .bank-backgroundImg .anticon {
  font-size: 16px;
  opacity: 0.8;
  transition: all 0.3s;
  margin-left: 20px;
}
.bank-list-item .bank-backgroundImg .anticon:hover {
  opacity: 1;
}
.bank-list-item .bank-details {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.bank-list-item .default {
  font-size: 12px;
  bottom: 0;
  text-align: center;
  margin-left: 10px;
}
.gongshangyinhang {
  background-image: linear-gradient(130deg, #faa5a6, #e14c59);
}
.gongshangyinhang .bank-background {
  background-image: url("/vn/img/bank/gongshangyinhang.png");
}
.zhaoshangyinhang {
  background-image: linear-gradient(130deg, #faa5a6, #e14c59);
}
.zhaoshangyinhang .bank-background {
  background-image: url("/vn/img/bank/zhaoshangyinhang.png");
}
.zhongguojiansheyinhang {
  background-image: linear-gradient(130deg, #8ccdff, #368ee8);
}
.zhongguojiansheyinhang .bank-background {
  background-image: url("/vn/img/bank/zhongguojiansheyinhang.png");
}
.zhongguonongyeyinhang {
  background-image: linear-gradient(130deg, #44c7a8, #04ad90);
}
.zhongguonongyeyinhang .bank-background {
  background-image: url("/vn/img/bank/zhongguonongyeyinhang.png");
}
.jiaotongyinhang {
  background-image: linear-gradient(130deg, #8ccdff, #368ee8);
}
.jiaotongyinhang .bank-background {
  background-image: url("/vn/img/bank/jiaotongyinhang.png");
}
.zhongguoyinhang {
  background-image: linear-gradient(130deg, #faa5a6, #e14c59);
}
.zhongguoyinhang .bank-background {
  background-image: url("/vn/img/bank/zhongguoyinhang.png");
}
.shanghaipudongfazhanyinhang {
  background-image: linear-gradient(130deg, #8ccdff, #368ee8);
}
.shanghaipudongfazhanyinhang .bank-background {
  background-image: url("/vn/img/bank/shanghaipudongfazhanyinhang.png");
}
.guangfayinhang {
  background-image: linear-gradient(130deg, #faa5a6, #e14c59);
}
.guangfayinhang .bank-background {
  background-image: url("/vn/img/bank/guangfayinhang.png");
}
.dongkuannongcunshangyeyinhang {
  background-image: linear-gradient(130deg, #faa5a6, #e14c59);
}
.dongkuannongcunshangyeyinhang .bank-background {
  background-image: url("/vn/img/bank/dongkuannongcunshangyeyinhang.png");
}
.zhongxinyinhang {
  background-image: linear-gradient(130deg, #faa5a6, #e14c59);
}
.zhongxinyinhang .bank-background {
  background-image: url("/vn/img/bank/zhongxinyinhang.png");
}
.zhongguominshengyinhang {
  background-image: linear-gradient(130deg, #44c7a8, #04ad90);
}
.zhongguominshengyinhang .bank-background {
  background-image: url("/vn/img/bank/zhongguominshengyinhang.png");
}
.zhongguoyouzhengcuxuyinhang {
  background-image: linear-gradient(130deg, #44c7a8, #04ad90);
}
.zhongguoyouzhengcuxuyinhang .bank-background {
  background-image: url("/vn/img/bank/zhongguoyouzhengcuxuyinhang.png");
}
.xinyeyinhang {
  background-image: linear-gradient(130deg, #8ccdff, #368ee8);
}
.xinyeyinhang .bank-background {
  background-image: url("/vn/img/bank/xinyeyinhang.png");
}
.huaxiayinhang {
  background-image: linear-gradient(130deg, #faa5a6, #e14c59);
}
.huaxiayinhang .bank-background {
  background-image: url("/vn/img/bank/huaxiayinhang.png");
}
.pinganyinhang {
  background-image: linear-gradient(130deg, #ffd991, #f7a93e);
}
.pinganyinhang .bank-background {
  background-image: url("/vn/img/bank/pinganyinhang.png");
}
.nongcunxingyonghezuoshe {
  background-image: linear-gradient(130deg, #44c7a8, #04ad90);
}
.nongcunxingyonghezuoshe .bank-background {
  background-image: url("/vn/img/bank/nongcunxingyonghezuoshe.png");
}
.guangzhouyinhang {
  background-image: linear-gradient(130deg, #faa5a6, #e14c59);
}
.guangzhouyinhang .bank-background {
  background-image: url("/vn/img/bank/guangzhouyinhang.png");
}
.nanjingyinhang {
  background-image: linear-gradient(130deg, #faa5a6, #e14c59);
}
.nanjingyinhang .bank-background {
  background-image: url("/vn/img/bank/nanjingyinhang.png");
}
.guangzhounongcunshangyeyinhang {
  background-image: linear-gradient(130deg, #ffd991, #f7a93e);
}
.guangzhounongcunshangyeyinhang .bank-background {
  background-image: url("/vn/img/bank/guangzhounongcunshangyeyinhang.png");
}
.zhongguoguangdayinhang {
  background-image: linear-gradient(130deg, #e59df5, #b546db);
}
.zhongguoguangdayinhang .bank-background {
  background-image: url("/vn/img/bank/zhongguoguangdayinhang.png");
}
.wenzhouyinhang {
  background-image: linear-gradient(130deg, #ffd991, #f7a93e);
}
.wenzhouyinhang .bank-background {
  background-image: url("/vn/img/bank/wenzhouyinhang.png");
}
.chongqingnongcunshangyeyinhang {
  background-image: linear-gradient(130deg, #faa5a6, #e14c59);
}
.chongqingnongcunshangyeyinhang .bank-background {
  background-image: url("/vn/img/bank/chongqingnongcunshangyeyinhang.png");
}
.shanghainongshangyinhang {
  background-image: linear-gradient(130deg, #8ccdff, #368ee8);
}
.shanghainongshangyinhang .bank-background {
  background-image: url("/vn/img/bank/shanghainongshangyinhang.png");
}
.nongcunxingyonghezuoshe {
  background-image: linear-gradient(130deg, #44c7a8, #04ad90);
}
.nongcunxingyonghezuoshe .bank-background {
  background-image: url("/vn/img/bank/nongcunxingyonghezuoshe.png");
}
.nongcunxingyonghezuoshe {
  background-image: linear-gradient(130deg, #44c7a8, #04ad90);
}
.nongcunxingyonghezuoshe .bank-background {
  background-image: url("/vn/img/bank/nongcunxingyonghezuoshe.png");
}
.xiamenyinhang {
  background-image: linear-gradient(130deg, #8ccdff, #368ee8);
}
.xiamenyinhang .bank-background {
  background-image: url("/vn/img/bank/xiamenyinhang.png");
}
.nongcunxingyonghezuoshe {
  background-image: linear-gradient(130deg, #44c7a8, #04ad90);
}
.nongcunxingyonghezuoshe .bank-background {
  background-image: url("/vn/img/bank/nongcunxingyonghezuoshe.png");
}
.zhuhaihuarunyinhang {
  background-image: linear-gradient(130deg, #ffd991, #f7a93e);
}
.zhuhaihuarunyinhang .bank-background {
  background-image: url("/vn/img/bank/zhuhaihuarunyinhang.png");
}
.guangdongshengnongxing {
  background-image: linear-gradient(130deg, #ffd991, #f7a93e);
}
.guangdongshengnongxing .bank-background {
  background-image: url("/vn/img/bank/guangdongshengnongxing.png");
}
.beijingyinhang {
  background-image: linear-gradient(130deg, #faa5a6, #e14c59);
}
.beijingyinhang .bank-background {
  background-image: url("/vn/img/bank/beijingyinhang.png");
}
.dongguanyinhang {
  background-image: linear-gradient(130deg, #faa5a6, #e14c59);
}
.dongguanyinhang .bank-background {
  background-image: url("/vn/img/bank/dongguanyinhang.png");
}
.shanghaiyinhang {
  background-image: linear-gradient(130deg, #ffd991, #f7a93e);
}
.shanghaiyinhang .bank-background {
  background-image: url("/vn/img/bank/shanghaiyinhang.png");
}
.bohaiyinhang {
  background-image: linear-gradient(130deg, #8ccdff, #368ee8);
}
.bohaiyinhang .bank-background {
  background-image: url("/vn/img/bank/bohaiyinhang.png");
}
.zheshangyinhang {
  background-image: linear-gradient(130deg, #faa5a6, #e14c59);
}
.zheshangyinhang .bank-background {
  background-image: url("/vn/img/bank/zheshangyinhang.png");
}
.hangzhouyinhang {
  background-image: linear-gradient(130deg, #8ccdff, #368ee8);
}
.hangzhouyinhang .bank-background {
  background-image: url("/vn/img/bank/hangzhouyinhang.png");
}
.ningboyinhang {
  background-image: linear-gradient(130deg, #ffd991, #f7a93e);
}
.ningboyinhang .bank-background {
  background-image: url("/vn/img/bank/ningboyinhang.png");
}
.jiangsuyinhang {
  background-image: linear-gradient(130deg, #ffd991, #f7a93e);
}
.jiangsuyinhang .bank-background {
  background-image: url("/vn/img/bank/jiangsuyinhang.png");
}
.guangdongnanyueyinhang {
  background-image: linear-gradient(130deg, #faa5a6, #e14c59);
}
.guangdongnanyueyinhang .bank-background {
  background-image: url("/vn/img/bank/guangdongnanyueyinhang.png");
}
.nongcunxingyonghezuoshe {
  background-image: linear-gradient(130deg, #44c7a8, #04ad90);
}
.nongcunxingyonghezuoshe .bank-background {
  background-image: url("/vn/img/bank/nongcunxingyonghezuoshe.png");
}
.nongcunxingyonghezuoshe {
  background-image: linear-gradient(130deg, #44c7a8, #04ad90);
}
.nongcunxingyonghezuoshe .bank-background {
  background-image: url("/vn/img/bank/nongcunxingyonghezuoshe.png");
}
.shenzhenfazhanyinhang {
  background-image: linear-gradient(130deg, #8ccdff, #368ee8);
}
.shenzhenfazhanyinhang .bank-background {
  background-image: url("/vn/img/bank/shenzhenfazhanyinhang.png");
}
.guangfayinhang {
  background-image: linear-gradient(130deg, #faa5a6, #e14c59);
}
.guangfayinhang .bank-background {
  background-image: url("/vn/img/bank/guangfayinhang.png");
}
.chengduyinhang {
  background-image: linear-gradient(130deg, #ffd991, #f7a93e);
}
.chengduyinhang .bank-background {
  background-image: url("/vn/img/bank/chengduyinhang.png");
}
.hebeiyinhang {
  background-image: linear-gradient(130deg, #faa5a6, #e14c59);
}
.hebeiyinhang .bank-background {
  background-image: url("/vn/img/bank/hebeiyinhang.png");
}
.fujianhaixiayinhang {
  background-image: linear-gradient(130deg, #8ccdff, #368ee8);
}
.fujianhaixiayinhang .bank-background {
  background-image: url("/vn/img/bank/fujianhaixiayinhang.png");
}
.fudianyinhang {
  background-image: linear-gradient(130deg, #faa5a6, #e14c59);
}
.fudianyinhang .bank-background {
  background-image: url("/vn/img/bank/fudianyinhang.png");
}
.haerbinyinhang {
  background-image: linear-gradient(130deg, #ffd991, #f7a93e);
}
.haerbinyinhang .bank-background {
  background-image: url("/vn/img/bank/haerbinyinhang.png");
}
.hankouyinhang {
  background-image: linear-gradient(130deg, #8ccdff, #368ee8);
}
.hankouyinhang .bank-background {
  background-image: url("/vn/img/bank/hankouyinhang.png");
}
.langfangyinhang {
  background-image: linear-gradient(130deg, #8ccdff, #368ee8);
}
.langfangyinhang .bank-background {
  background-image: url("/vn/img/bank/langfangyinhang.png");
}
.zhongguoguangdayinhang {
  background-image: linear-gradient(130deg, #e59df5, #b546db);
}
.zhongguoguangdayinhang .bank-background {
  background-image: url("/vn/img/bank/zhongguoguangdayinhang.png");
}
.dongguanyinhang {
  background-image: linear-gradient(130deg, #faa5a6, #e14c59);
}
.dongguanyinhang .bank-background {
  background-image: url("/vn/img/bank/dongguanyinhang.png");
}
.zhongyuanyinhang {
  background-image: linear-gradient(130deg, #8ccdff, #368ee8);
}
.zhongyuanyinhang .bank-background {
  background-image: url("/vn/img/bank/zhongyuanyinhang.png");
}
.pingdingshanyinhang {
  background-image: linear-gradient(130deg, #faa5a6, #e14c59);
}
.pingdingshanyinhang .bank-background {
  background-image: url("/vn/img/bank/pingdingshanyinhang.png");
}
.zhengzhouyinhang {
  background-image: linear-gradient(130deg, #ffd991, #f7a93e);
}
.zhengzhouyinhang .bank-background {
  background-image: url("/vn/img/bank/zhengzhouyinhang.png");
}
.baoshangyinhang {
  background-image: linear-gradient(130deg, #ffd991, #f7a93e);
}
.baoshangyinhang .bank-background {
  background-image: url("/vn/img/bank/baoshangyinhang.png");
}
.huishangyinhang {
  background-image: linear-gradient(130deg, #faa5a6, #e14c59);
}
.huishangyinhang .bank-background {
  background-image: url("/vn/img/bank/huishangyinhang.png");
}
.shaoxinyinhang {
  background-image: linear-gradient(130deg, #8ccdff, #368ee8);
}
.shaoxinyinhang .bank-background {
  background-image: url("/vn/img/bank/shaoxinyinhang.png");
}
.nongcunxingyonghezuoshe {
  background-image: linear-gradient(130deg, #44c7a8, #04ad90);
}
.nongcunxingyonghezuoshe .bank-background {
  background-image: url("/vn/img/bank/nongcunxingyonghezuoshe.png");
}
.message-button {
  text-align: right;
  overflow: hidden;
  width: 99.99%;
}
.message-button > .ant-btn,
.message-button > .ant-radio-group {
  margin-left: 10px;
}
.message-button .usercenter-title-brief {
  float: left;
  line-height: 1;
  vertical-align: top;
}
.message-button .usercenter-title-brief .ant-radio-button-wrapper {
  vertical-align: top;
}
.message-button .usercenter-title-brief .ant-radio-button-wrapper > span:last-child > span {
  font-size: 12px;
  position: absolute;
  left: 100px;
  top: -7px;
}
.custom-dropdown-recordPage .ant-select-dropdown-menu-item {
  background-color: #fff;
  /* Example background color */
  color: #000;
  /* Example text color */
  text-align: left !important;
  font-weight: bold;
  padding-left: 1.2rem;
}
.custom-dropdown-recordPage .ant-select-dropdown-menu-item:hover {
  background-color: #e0e0e0;
  /* Example hover background color */
  color: #000;
  /* Example hover text color */
}
.recordDateRange .ant-calendar {
  width: 352px;
  top: 48px;
}
.recordDateRange .ant-calendar .ant-calendar-input-wrap {
  display: none;
}
.betRecords-dateRange .ant-modal-close-x {
  color: #222222 !important;
}
.betRecords-dateRange .ant-modal-content {
  border-radius: 20px;
}
.betRecords-dateRange .ant-modal-header {
  background-color: white !important;
  border-radius: 20px 20px 0px 0px;
}
.betRecords-dateRange .ant-modal-header .ant-modal-title {
  color: #222222 !important;
}
.betRecords-dateRange .ant-modal-body .ant-form .betRecords-dateRange-hint {
  margin-bottom: 17px;
  text-align: left;
  letter-spacing: 0px;
  color: #222222;
  font-size: 14px;
}
.betRecords-dateRange .ant-modal-body .ant-form label {
  text-align: left;
  letter-spacing: 0px;
  color: #222222;
  font-size: 14px;
  font-weight: bold;
}
.betRecords-dateRange .ant-modal-body .ant-form .ant-calendar-picker .anticon-calendar.ant-calendar-picker-icon {
  background-image: none;
  color: #000000;
  background-color: #d8d8d8;
}
.ant-calendar-ym-select .ant-calendar-header .ant-calendar-year-select {
  color: #000;
}
.message-wrap .message-button .usercenter-title-brief .ant-radio-button-wrapper {
  padding: 0 40px;
}
.message-list {
  margin-top: 10px;
  margin-bottom: 60px;
}
.teal-color {
  color: #1af1dd;
}
.message-item {
  display: flex;
  border-bottom: 1px solid #e0e0e0;
}
.message-item .message {
  display: flex;
}
.message-item .message div:first-child {
  align-self: center;
}
.message-item .message-arrow {
  position: relative;
  width: 100%;
}
.message-item .message-arrow > i {
  position: absolute;
  top: 43.63px;
  right: 25.5px;
}
.message-item .anticon {
  transition: all 0.3s;
}
.message-item.open .anticon {
  transform: rotate(-180deg);
}
.message-item.close .anticon {
  transform: rotate(0deg);
}
.message-item.open .message .message-title-inner-wrap .message-title {
  white-space: normal !important;
}
.message-item.open .message .message-title-wrap h3 {
  white-space: normal !important;
}
.message-item .message {
  padding: 15px 0px 15px 20px;
  line-height: 90px;
  position: relative;
  cursor: pointer;
}
.message-item .message > div {
  vertical-align: middle;
}
.message-item .message .message-remind-circle {
  width: 8px;
  height: 8px;
  background-color: #fff;
  border-radius: 50%;
  margin-right: 20px;
}
.message-item .message .message-photo {
  background-repeat: no-repeat;
  width: 50px;
  height: 50px;
  margin-right: 20px;
  vertical-align: top;
  margin-top: 20px;
  flex-shrink: 0;
}
.message-item .message .message-title-wrap {
  line-height: 1.7;
  vertical-align: top;
  margin-top: 4px;
  max-width: 600px;
  width: 600px;
}
.message-item .message .message-title-wrap > h3 {
  color: #000;
  margin-bottom: 0;
  max-width: 600px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.message-item .message .message-title-wrap .message-title-inner-wrap {
  margin-bottom: 0;
  max-width: 600px;
  overflow: hidden;
  line-height: 24px;
  height: 0px;
  transition: height 0.3s;
}
.message-item .message .message-title-wrap .message-title-inner-wrap .message-title {
  margin: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.message-item .message.unread .message-remind-circle {
  background-color: #f53d3d;
  flex-shrink: 0;
}
.message-item .message.bank .message-photo {
  background-image: url("/vn/img/user/message-banking.png");
}
.message-item .message.bank .message-title-wrap > h3 > span {
  color: #78909c;
}
.message-item .message.game .message-photo {
  background-image: url("/vn/img/user/message-product.png");
}
.message-item .message.game .message-title-wrap > h3 > span {
  color: #00acc1;
}
.message-item .message.withdraw .message-photo {
  background-image: url("/vn/img/user/message-withdraw.png");
}
.message-item .message.withdraw .message-title-wrap > h3 > span {
  color: #78909c;
}
.message-item .message.promotion .message-photo {
  background-image: url("/vn/img/user/message-promo.png");
}
.message-item .message.promotion .message-title-wrap > h3 > span {
  color: #78909c;
}
.message-item .message.transfer .message-photo {
  background-image: url("/vn/img/user/message-transfer.png");
}
.message-item .message.transfer .message-title-wrap > h3 > span {
  color: #78909c;
}
.message-item .message.general .message-photo {
  background-image: url("/vn/img/user/message-general.png");
}
.message-item .message.general .message-title-wrap > h3 > span {
  color: #78909c;
}
.message-item .message.bonus .message-photo {
  background-image: url("/vn/img/user/message-bonus.png");
}
.message-item .message.bonus .message-title-wrap > h3 > span {
  color: #78909c;
}
.message-item .message.product .message-photo {
  background-image: url("/vn/img/user/message-product.png");
}
.message-item .message.product .message-title-wrap > h3 > span {
  color: #78909c;
}
.message-item .message.personal .message-photo {
  background-image: url("/vn/img/user/message-personal.png");
}
.message-item .message.personal .message-title-wrap > h3 > span {
  color: #78909c;
}
.message-item .message.transition .message-photo {
  background-image: url("/vn/img/user/message-inbox.png");
}
.message-item .message.eight-shop .message-photo {
  background-image: url("/vn/img/user/eight-shop.png");
}
.message-item .message.eight-shop .message-title-wrap > h3 > span {
  color: #f2b9a3;
}
.my-promo-list-wrap {
  margin: 20px 0;
}
.my-promo-list-wrap .my-promo-item {
  border-radius: 10px;
  border: 1px solid #e0e0e0;
  padding: 15px;
  margin-bottom: 10px;
}
.my-promo-list-wrap .my-promo-item h3,
.my-promo-list-wrap .my-promo-item p {
  margin: 0;
  vertical-align: middle;
}
.my-promo-list-wrap .my-promo-item .promo-brief {
  padding-bottom: 10px;
  border-bottom: 1px solid #f0f0f2;
  position: relative;
}
.my-promo-list-wrap .my-promo-item .promo-brief > div {
  vertical-align: middle;
}
.my-promo-list-wrap .my-promo-item .brief-img {
  width: 80px;
  height: 50px;
  border-radius: 6px;
  background-color: #e0e0e0;
  margin-right: 20px;
  overflow: hidden;
}
.my-promo-list-wrap .my-promo-item .brief-img > img {
  width: 100%;
}
.my-promo-list-wrap .my-promo-item .brief-info > h3 {
  color: #222;
  padding-right: 30px;
}
.my-promo-list-wrap .my-promo-item .brief-info > p {
  color: #999;
}
.my-promo-list-wrap .my-promo-item .brief-detail {
  width: 300px;
}
.my-promo-list-wrap .my-promo-item .brief-detail.light > h3 {
  color: #00a6ff;
}
.my-promo-list-wrap .my-promo-item .brief-detail.light .line-process {
  background-color: #00a6ff;
}
.my-promo-list-wrap .my-promo-item .brief-detail.dark > h3 {
  color: #999;
}
.my-promo-list-wrap .my-promo-item .brief-detail.dark .line-process {
  background-color: #999;
}
.my-promo-list-wrap .my-promo-item .brief-detail-wrap {
  margin-top: 10px;
}
.my-promo-list-wrap .my-promo-item .line-process-wrap {
  border-radius: 2px;
  height: 4px;
  width: 100%;
  margin-bottom: 6px;
  background-color: #f5f5f5;
  position: relative;
  overflow: hidden;
}
.my-promo-list-wrap .my-promo-item .line-process-wrap .line-process {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 50%;
}
.my-promo-list-wrap .my-promo-item .line-process-wrap .line-process.light {
  background-color: #00a6ff;
}
.my-promo-list-wrap .my-promo-item .line-process-wrap .line-process.dark {
  background-color: #999;
}
.my-promo-list-wrap .my-promo-item .brief-button {
  float: right;
}
.my-promo-list-wrap .my-promo-item .anticon {
  font-size: 22px;
  cursor: pointer;
  color: #828282;
  position: absolute;
  right: 0;
  top: 0;
}
.my-promo-list-wrap .my-promo-item .ant-btn {
  margin-top: 7px;
  width: 146px;
}
.my-promo-list-wrap .my-promo-item .item-wrap > span:nth-child(2) {
  color: #222;
}
.remove-promo-wrap .ant-dropdown-menu {
  padding: 0;
  overflow: hidden;
}
.remove-promo-wrap .ant-dropdown-menu .ant-dropdown-menu-item:hover {
  color: #00a6ff;
}
.remove-promo-wrap.small .ant-dropdown-menu .ant-dropdown-menu-item,
.remove-promo-wrap.small .ant-dropdown-menu .ant-dropdown-menu-submenu-title {
  line-height: 26px;
  padding: 5px 22px;
}
.my-bonus-list-wrap {
  margin-top: 20px;
  line-height: 1.4;
}
.my-bonus-list-wrap .my-bonus-item {
  cursor: pointer;
  border-radius: 10px;
  border: 1px solid #e0e0e0;
  padding: 15px;
  margin-bottom: 10px;
}
.my-bonus-list-wrap .my-bonus-item .bonus-brief {
  padding-bottom: 10px;
  border-bottom: 1px solid #f0f0f2;
}
.my-bonus-list-wrap .my-bonus-item .bonus-brief h3,
.my-bonus-list-wrap .my-bonus-item .bonus-brief p {
  margin: 0;
  vertical-align: middle;
}
.my-bonus-list-wrap .my-bonus-item .bonus-brief > div {
  vertical-align: middle;
}
.my-bonus-list-wrap .my-bonus-item .anticon {
  float: right;
  line-height: 40px;
  color: #ccc;
}
.my-bonus-list-wrap .my-bonus-item .brief-detail-wrap {
  padding-top: 20px;
}
.my-bonus-list-wrap .my-bonus-item .brief-detail {
  width: 50%;
  text-align: center;
}
.my-bonus-list-wrap .my-bonus-item .brief-detail > p {
  color: #666;
}
.my-bonus-list-wrap .my-bonus-item .brief-detail > strong {
  font-weight: 500;
}
.my-bonus-list-wrap .my-bonus-item .brief-info > h3 {
  font-weight: bold;
}
.my-bonus-list-wrap .my-bonus-item .brief-img {
  width: 40px;
  height: 40px;
  margin-right: 10px;
}
.my-bonus-list-wrap .my-bonus-item .brief-img > img {
  width: 100%;
}
.records-list-wrap {
  margin-top: 30px;
  margin-bottom: 50px;
  text-align: center;
}
.records-list-wrap._modal {
  margin-top: 0;
  margin-bottom: 20px;
}
.records-list-wrap .ant-row {
  padding: 10px 0;
  line-height: 1.7;
  min-height: 40px;
  border-bottom: 1px solid #d9d9d9;
  color: #222;
}
.records-list-wrap .ant-row:not(:first-child) {
  font-size: 12px;
}
.records-list-wrap .ant-row.withdraw-break .ant-col {
  white-space: nowrap;
  text-overflow: ellipsis;
  display: inline-block;
  vertical-align: middle;
  float: none;
}
.records-list-wrap .ant-row.withdraw-break .ant-col:last-child {
  text-align: left;
  line-height: 1.4;
  white-space: normal;
}
.records-list-wrap .ant-row .ant-col-3:last-child {
  line-height: 25px;
}
.records-list-wrap .ant-col {
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
  vertical-align: middle;
  float: none;
}
.records-list-wrap .ant-col .small-sign {
  padding-left: 14px;
  transform: scale(0.9);
  transform-origin: 0 0;
  color: #999;
}
.records-list-wrap .ant-col .anticon {
  transform: rotate(90deg);
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  right: 10px;
  width: 14px;
  height: 14px;
}
.records-list-wrap .ant-col .open {
  display: none;
}
.records-list-wrap .ant-col .open::before {
  content: "";
  width: 2px;
  height: calc(100% - 45px);
  background-color: #ccc;
  position: absolute;
  left: 13px;
  top: 10px;
}
.records-list-wrap .ant-col .open._short::before {
  height: calc(100% - 25px);
}
.records-list-wrap .ant-col .close {
  display: block;
}
.records-list-wrap .ant-col .small-circle {
  color: #999;
  position: relative;
  padding: 5px 5px 5px 14px;
}
.records-list-wrap .ant-col .small-circle::before {
  content: "";
  display: block;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: #ccc;
  position: absolute;
  top: 10px;
  left: 0;
  outline-offset: 0.1rem;
  outline: solid 1px #ccc;
}
.records-list-wrap .ant-col .record-btn {
  margin: 4px;
  border-radius: 5px;
  font-size: 12px;
  line-height: 24px;
}
.records-list-wrap .ant-col .record-btn.ant-btn-default:focus {
  border: 1px solid #ccc;
  color: #666;
}
.records-list-wrap .ant-col .record-btn.ant-btn-default:hover {
  border: 1px solid #ff5c57;
  color: #ff5c57;
}
.records-list-wrap .ant-col .ant-btn-confirmReceipt {
  background-color: #1c8eff;
  color: #ffffff;
}
.records-list-wrap .ant-col .ant-btn-confirmReceipt:hover,
.records-list-wrap .ant-col .ant-btn-confirmReceipt:focus {
  border: 1px solid #1c8eff;
}
.records-list-wrap .ant-col.record-distance {
  padding: 0 6px;
}
.records-list-wrap .ant-col.record-distance .reason-msg {
  white-space: normal;
  line-height: 1.4;
}
.records-list-wrap .ant-col.r-pending .close .small-circle,
.records-list-wrap .ant-col.r-pending .open._1 .small-circle:nth-child(1),
.records-list-wrap .ant-col.r-pending .open._2 .small-circle:nth-child(3),
.records-list-wrap .ant-col.r-pending .open._3 .small-circle:nth-child(5) {
  color: #1c8eff;
}
.records-list-wrap .ant-col.r-pending .close .small-circle::before,
.records-list-wrap .ant-col.r-pending .open._1 .small-circle:nth-child(1)::before,
.records-list-wrap .ant-col.r-pending .open._2 .small-circle:nth-child(3)::before,
.records-list-wrap .ant-col.r-pending .open._3 .small-circle:nth-child(5)::before {
  background-color: #1c8eff;
  outline-offset: 0.1rem;
  outline: solid 1px #1c8eff;
}
.records-list-wrap .ant-col.r-expired .close .small-circle,
.records-list-wrap .ant-col.r-expired .open._1 .small-circle:nth-child(1),
.records-list-wrap .ant-col.r-expired .open._2 .small-circle:nth-child(3),
.records-list-wrap .ant-col.r-expired .open._3 .small-circle:nth-child(5) {
  color: #F92D2D;
}
.records-list-wrap .ant-col.r-expired .close .small-circle::before,
.records-list-wrap .ant-col.r-expired .open._1 .small-circle:nth-child(1)::before,
.records-list-wrap .ant-col.r-expired .open._2 .small-circle:nth-child(3)::before,
.records-list-wrap .ant-col.r-expired .open._3 .small-circle:nth-child(5)::before {
  background-color: #F92D2D;
  outline-offset: 0.1rem;
  outline: solid 1px #F92D2D;
}
.records-list-wrap .ant-col.r-process .close .small-circle,
.records-list-wrap .ant-col.r-process .open._1 .small-circle:nth-child(1),
.records-list-wrap .ant-col.r-process .open._2 .small-circle:nth-child(3),
.records-list-wrap .ant-col.r-process .open._3 .small-circle:nth-child(5) {
  color: #F0A800;
}
.records-list-wrap .ant-col.r-process .close .small-circle::before,
.records-list-wrap .ant-col.r-process .open._1 .small-circle:nth-child(1)::before,
.records-list-wrap .ant-col.r-process .open._2 .small-circle:nth-child(3)::before,
.records-list-wrap .ant-col.r-process .open._3 .small-circle:nth-child(5)::before {
  background-color: #F0A800;
  outline-offset: 0.1rem;
  outline: solid 1px #F0A800;
}
.records-list-wrap .ant-col.r-success .close .small-circle,
.records-list-wrap .ant-col.r-success .open._1 .small-circle:nth-child(1),
.records-list-wrap .ant-col.r-success .open._2 .small-circle:nth-child(3),
.records-list-wrap .ant-col.r-success .open._3 .small-circle:nth-child(5) {
  color: #41d100;
}
.records-list-wrap .ant-col.r-success .close .small-circle::before,
.records-list-wrap .ant-col.r-success .open._1 .small-circle:nth-child(1)::before,
.records-list-wrap .ant-col.r-success .open._2 .small-circle:nth-child(3)::before,
.records-list-wrap .ant-col.r-success .open._3 .small-circle:nth-child(5)::before {
  background-color: #41d100;
  outline-offset: 0.1rem;
  outline: solid 1px #41d100;
}
.records-list-wrap .ant-col.r-error .close .small-circle,
.records-list-wrap .ant-col.r-error .open._1 .small-circle:nth-child(1),
.records-list-wrap .ant-col.r-error .open._2 .small-circle:nth-child(3),
.records-list-wrap .ant-col.r-error .open._3 .small-circle:nth-child(5) {
  color: #f92d2d;
}
.records-list-wrap .ant-col.r-error .close .small-circle::before,
.records-list-wrap .ant-col.r-error .open._1 .small-circle:nth-child(1)::before,
.records-list-wrap .ant-col.r-error .open._2 .small-circle:nth-child(3)::before,
.records-list-wrap .ant-col.r-error .open._3 .small-circle:nth-child(5)::before {
  background-color: #f92d2d;
  outline-offset: 0.1rem;
  outline: solid 1px #f92d2d;
}
.records-list-wrap .ant-col.left {
  padding-left: 10px;
  text-align: left;
}
.records-list-wrap .ant-col.text-left {
  padding-left: 0px;
  text-align: left;
}
.records-list-wrap .ant-col.true .anticon {
  transform: rotate(-90deg);
  top: -70%;
}
.records-list-wrap .ant-col.true .open {
  display: block;
}
.records-list-wrap .ant-col.true .close {
  display: none;
}
.records-list-wrap .record-info-icon {
  margin-left: 2px;
  display: inline-block;
  vertical-align: sub;
  width: 16px;
  height: 16px;
  background-image: url("/vn/img/user/icon-detail.png");
  background-size: contain;
}
.records-list-wrap .ant-btn.inline.ant-btn-link {
  font-size: 12px;
  margin-left: 2px;
}
.records-list-wrap .anticon {
  color: #999;
}
.records-list-wrap .copy-id-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.records-list-wrap.withdraw-record .left.method-id {
  padding-left: 0;
}
.records-modal .ant-modal-body {
  padding: 0;
}
.usercenter-title-tools {
  float: right;
  display: flex;
}
.usercenter-title-tools .ant-select-selection {
  width: 200px;
}
.usercenter-title-tools .ant-select-selection .ant-select-selection-selected-value {
  width: 100%;
  text-align: center;
}
.usercenter-title-tools .ant-select-selection__rendered {
  font-size: 14px;
  font-weight: bold;
  text-align: center;
  color: #000;
}
.usercenter-title-tools .ant-select-selection-selected-value {
  width: 100%;
}
.usercenter-title-tools .ant-select-arrow {
  color: #000;
}
.account-wrap .defined-time-wrap {
  text-align: right;
  margin-top: 15px;
  cursor: pointer;
  vertical-align: top;
  transform-origin: 0 0;
  transform: translateZ(0);
}
.account-wrap .defined-time-wrap .anticon {
  color: #999;
  font-size: 16px;
  padding: 0 10px;
}
.account-wrap .defined-time-wrap .anticon:last-child {
  padding-right: 0;
}
.account-wrap .defined-time-wrap .change {
  color: #00a6ff;
  margin-left: 5px;
}
.account-wrap .ant-empty {
  margin: 200px auto 0;
}
.security-code-wrap {
  width: -moz-min-content;
  width: min-content;
  height: auto;
  background: #f6f6f6 0% 0% no-repeat padding-box;
  border-radius: 10px;
  margin: 50px auto;
  padding: 50px;
  text-align: center;
}
.security-code-wrap > p {
  width: 340px;
  margin: 0 auto 50px auto;
  text-align: left;
  color: #222222;
  font-size: 14px;
  line-height: 22px;
  padding: 0 5px;
}
.security-code-wrap .ant-btn.ant-btn-primary.security-code-btn {
  color: #fff;
  padding: 0 120px;
  width: 100%;
}
.security-code-wrap .ant-btn.ant-btn-primary.security-code-btn.tlc-button-disabled {
  border-color: #dbdbdb;
  background-color: #b9b9b9;
  text-shadow: none;
  box-shadow: 0 3px 5px #a0a0a0;
}
.security-realm.border {
  transform: translateY(-20px);
}
.security-realm.border .code-box {
  width: 386px;
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 30px;
  position: relative;
}
.security-realm.border .code-box .copy-result-box {
  background-color: #daffe3;
  position: absolute;
  z-index: 100;
  display: flex;
  gap: 10px;
  border-radius: 64px;
  padding: 17px 20px;
  align-items: center;
  left: 50%;
  top: 15%;
  transform: translateX(-50%);
  box-shadow: 0px 3px 6px #00000029;
}
.security-realm.border .code-box .copy-result-box img {
  width: 24px;
  height: 24px;
}
.security-realm.border .code-box .copy-result-box p {
  color: #0ccc3c;
  font-size: 16px;
  margin: 0;
  white-space: nowrap;
}
.security-realm.border .code-box .security-code {
  cursor: pointer;
  display: flex;
  position: relative;
  gap: 10px;
  justify-content: center;
}
.security-realm.border .code-box .security-code .code-letter {
  width: 50px;
  height: 50px;
  background-color: #fff;
  color: #0ccc3c;
  border-radius: 6px;
  font-size: 30px;
  font-weight: bold;
}
.security-realm.border .code-box .security-code .anticon {
  font-size: 26px;
  color: #cbcbcb;
  vertical-align: 0;
}
.security-realm.border .code-box .btn-copy {
  border: none;
  background-color: transparent;
  cursor: pointer;
}
.security-realm.border .countdown-message {
  color: #999999;
  margin-bottom: 30px;
}
.security-realm.border .line-distance {
  height: 0;
}
.security-realm.border .still-valid-text {
  color: #83630b;
  background-color: #fff5bf;
  border-radius: 8px;
  padding: 12px 10px;
  text-align: left;
  margin-bottom: 30px;
}
.security-realm.border .btn-create-code {
  width: 100%;
  font-size: 16px;
  font-weight: bold;
}
.security-realm.border .btn-create-code:disabled {
  background-color: #cccccc;
  color: #fff;
  border: none;
}
.security-realm.border .expired-reminder-box {
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
  justify-content: center;
}
.security-realm.border .expired-reminder-box p {
  color: #ee0001;
  margin-bottom: 30px;
}
.add-wallet-item {
  float: left;
  width: 240px;
  height: 130px;
  margin-right: 0px;
  color: #58c5ff;
  text-align: center;
  line-height: 108px;
  margin-bottom: 18px;
  border-radius: 10px;
  padding: 10px;
  background: #fff;
  border: 2px dashed #58c5ff;
  cursor: pointer;
}
.addExchangeRateWalletModal {
  margin-top: 8%;
}
.addExchangeRateWalletModal .modal-wallet-info {
  line-height: 24px;
}
.addExchangeRateWalletModal .btn-wrap Button {
  width: 100%;
}
.addExchangeRateWalletModal .btn-wrap Button:nth-child(1) {
  border: 1px solid #cccccc;
  color: #ffffff;
  background: #cccccc;
}
.addExchangeRateWalletModal .btn-wrap Button:nth-child(2) {
  background-color: #cccccc;
  color: #fff;
}
.addExchangeRateWalletModal .btn-wrap Button:nth-child(2):hover {
  border: 1px solid #cccccc;
}
.addExchangeRateWalletModal .btn-wrap .active {
  border: 1px solid #00a6ff;
  background-color: #00a6ff !important;
  color: #ffffff !important;
}
.addExchangeRateWalletModal .WalletModal-form-wrap .errorTip {
  width: 100%;
  line-height: 20px;
  color: #f92d2d;
  font-size: 12px;
}
.addExchangeRateWalletModal .WalletModal-form-wrap .item-wrap .item-label {
  line-height: 30px;
}
.addExchangeRateWalletModal .WalletModal-form-wrap .item-wrap .item-label::after {
  content: "*";
  color: #f00;
}
.wllet-list-item {
  float: left;
  width: 240px;
  height: 130px;
  position: relative;
  margin-right: 30px;
  color: #fff;
  line-height: 1.4;
  margin-bottom: 18px;
  border-radius: 10px;
  padding: 10px;
  background: #26a17b;
  box-shadow: 0 2px 3px #ccc;
  cursor: pointer;
}
.wllet-list-item:nth-child(3n) {
  margin-right: 0;
}
.wllet-list-item .bank-img {
  vertical-align: top;
  border-radius: 50%;
}
.wllet-list-item .bank-img > img {
  width: 40px;
  height: 40px;
  vertical-align: top;
}
.wllet-list-item .bank-info {
  padding-left: 10px;
  max-width: 148px;
  white-space: nowrap;
}
.wllet-list-item .bank-info > h3 {
  color: #fff;
  font-size: 14px;
  font-weight: bold;
  margin-top: 0.5em;
  margin-bottom: 0;
  overflow: hidden;
  text-overflow: ellipsis;
}
.wllet-list-item .bank-info > p {
  color: #f3f3f3;
}
.wllet-list-item .bank-number {
  position: relative;
  z-index: 2;
  font-size: 10px;
  white-space: normal;
  white-space: initial;
  overflow-wrap: break-word;
  padding: 8px 0;
  height: 55px;
}
.wllet-list-item .copyBtn {
  width: 70px;
  height: 24px;
  margin: auto;
  line-height: 24px;
  text-align: center;
  color: #26a17b;
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 14px;
  opacity: 1;
  font-size: 12px;
}
.wllet-list-item .default {
  width: 100%;
  line-height: 16px;
  font-size: 12px;
  text-align: left;
  margin-left: 10px;
}
.wllet-list-item .anticon {
  position: absolute;
  right: 12px;
  top: 12px;
  font-size: 16px;
  opacity: 0.8;
  transition: all 0.3s;
}
.wllet-list-item .anticon:hover {
  opacity: 1;
}
.account-wrap .walletTip {
  width: 100%;
  text-align: center;
  color: #999999;
  overflow: hidden;
}
.account-wrap .walletTip .chatCs {
  color: #00a6ff;
  cursor: pointer;
}
.account-wrap .walletTips {
  width: 100%;
  text-align: center;
  color: #999999;
  border-bottom: 1px solid #f0f0f0;
  padding-bottom: 1em;
}
.account-wrap .walletTips .chatCs {
  color: #00a6ff;
  cursor: pointer;
}
.account-wrap .bank-account-limit {
  width: 100%;
  height: 135px;
  border: 1px solid #e0e0e0;
  border-radius: 10px;
  margin: 20px auto;
}
.account-wrap .bank-account-limit p {
  width: 94%;
  margin: 0 3%;
  height: 30px;
  line-height: 30px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  font-size: 12px;
  color: #666666;
}
.account-wrap .bank-account-limit p:first-child {
  margin-top: 7.5px;
}
.account-wrap .bank-account-limit p:first-child span:first-child {
  font-size: 14px;
  color: #222222;
}
.account-wrap .bank-account-limit p:first-child span:last-child {
  color: #f92d2d;
  font-size: 12px;
  cursor: pointer;
}
.account-wrap .bank-account-limit p:last-child {
  font-size: 12px;
  color: #666666;
  margin-bottom: 7.5px;
}
.t-resubmit-list {
  width: 100%;
}
.t-resubmit-list li {
  border-bottom: 1px solid #e0e0e0;
  height: 46px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-weight: bold;
}
.t-resubmit-list li > div:first-child {
  text-align: left;
  color: #666;
}
.t-resubmit-list li > div:last-child {
  color: #000;
  text-align: right;
}
.t-resubmit-list li.t-resubmit-tip {
  background: #fff5bf;
  color: #83630b;
  border-radius: 8px;
  padding: 18px;
  border: 0;
  line-height: 1.4;
  height: auto;
  margin-top: 14px;
}
.t-resubmit-list li.exchangeRate {
  border: 0;
}
.t-resubmit-list li.gray {
  border-radius: 8px;
  background: #F5F5F5;
  color: #666;
  padding: 10px 20px;
  height: 110px;
  text-align: left;
  border: 0;
  font-weight: normal;
}
.t-resubmit-list li.gray .list2 li {
  list-style: disc;
  border: 0;
  height: auto;
  font-weight: normal;
  display: revert;
}
.t-resubmit-list li.line {
  height: 16px;
  border-bottom: 1px solid #e0e0e0;
}
.t-resubmit-list li .bank-info {
  display: flex;
  align-items: center;
  gap: 5px;
}
.t-resubmit-list li .CCWWalletAddressStr {
  font-size: 14px;
}
.t-resubmit-list li .CCWWalletAddressStr p {
  text-align: left;
}
.ccw li {
  min-height: 60px;
}
.t-resubmit-list2 .greyWord {
  background: #EFEFF4;
  font-size: 12px;
  padding: 0rem 0.5rem;
  border-radius: 0.2rem;
  line-height: 22px;
  margin: 0 0.4rem;
}
.t-resubmit-list2 li {
  color: #666 !important;
  border-bottom: none !important;
  height: 36px;
  text-align: left;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.t-resubmit-list2 li > div:first-child {
  width: 30%;
}
.t-resubmit-list2 li > div:last-child {
  width: 70%;
  text-align: right;
  display: flex;
  justify-content: flex-end;
}
.t-resubmit-list2 li.t-resubmit-tip {
  background: #fff5bf;
  color: #83630b;
  border-radius: 8px;
  padding: 18px;
  border: 0;
  line-height: 1.4;
  height: auto;
  margin-top: 14px;
}
.t-resubmit-list2 li .record-btn {
  font-size: 12px !important;
  width: 4rem;
}
.confirmModalRecord .ant-modal-close-x {
  background-image: url("/vn/img/icon/icon-close.svg");
  background-repeat: no-repeat;
  background-position: center;
  color: transparent;
}
.confirmModalRecord .anticon-info-circle {
  display: none;
}
.w-min-line-height {
  line-height: 1.2;
}
.w-amount-tip {
  margin: 0;
  padding: 0;
  font-size: 12px;
  color: #999;
}
.lbwicon-success {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  display: inline-block;
  margin-bottom: 20px;
}
.lbwicon-success img {
  width: 100%;
  height: 100%;
}
.withdrawRecordsInfo-modal {
  width: 150px !important;
}
.withdrawRecordsInfo-modal.opacity .ant-modal-content {
  background-color: transparent;
}
.withdrawRecordsInfo-modal.opacity .ant-modal-body {
  background-color: rgba(0, 0, 0, 0.7);
}
.withdrawRecordsInfo-modal.hidden-btn .ant-modal-confirm-btns {
  margin-top: 20px;
}
.withdrawRecordsInfo-modal .ant-modal-body {
  padding: 0 !important;
  background-color: #000000cc;
  border-radius: 6px;
}
.withdrawRecordsInfo-modal .ant-modal-body .ant-modal-confirm .ant-modal-body {
  padding: 0;
}
.withdrawRecordsInfo-modal .ant-modal-body .ant-modal-confirm-body .ant-modal-confirm-content {
  padding: 0;
  margin-top: 25px;
  color: #fff;
}
.withdrawRecordsInfo-modal .ant-btn-primary,
.withdrawRecordsInfo-modal .ant-modal-close-x {
  display: none;
}
.Progress {
  overflow: hidden;
  height: 10px;
  background-color: #f3f5f9;
  background-image: linear-gradient(to bottom, #f3f5f9, #f3f5f9);
  background-repeat: repeat-x;
  border-radius: 8px;
  margin-top: 10px;
}
.Progress .Bar {
  width: 0%;
  height: 100%;
  color: #ffffff;
  border-radius: 8px;
  font-size: 12px;
  text-align: center;
  align-items: center;
  display: flex;
  justify-content: center;
  background-repeat: repeat-x;
  box-sizing: border-box;
  transition: width 0.6s ease;
}
.Progress .redBar {
  background-color: #ee0001;
  background-image: linear-gradient(to bottom, #ee0001, #ee0001);
}
.Progress .greenBar {
  background-color: #42d200;
  background-image: linear-gradient(to bottom, #42d200, #42d200);
}
.Progress .yellowBar {
  background-color: #f0a800;
  background-image: linear-gradient(to bottom, #ffbf58, #ffbf58);
}
.SecurityCheck .TypeName {
  font: normal normal normal 12px/16px Roboto;
  letter-spacing: 0px;
  color: #999999;
  padding-bottom: 10px;
}
.SecurityCheck .txt {
  color: #999999;
  padding: 10px 0;
  display: block;
}
.SecurityCheck .note {
  color: #666666;
  font-size: 14px;
}
.SecurityCheck .CheckBox {
  display: flex;
  justify-content: space-between;
  width: 80%;
}
.SecurityCheck .List {
  width: 180px;
  height: 197px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #cccccc;
  border-radius: 10px;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  line-height: 30px;
}
.SecurityCheck .List .typeTitle {
  text-align: center;
  line-height: 20px;
  margin: 10px 0 5px;
}
.SecurityCheck .Progress {
  width: 254px;
}
.SecurityCheck .iconChecked {
  color: #0ccc3c;
}
.SecurityCheck .iconChecked img {
  margin-right: 5px;
}
.UploadFilesUserCenter .UploadFilesBox {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column;
}
.account-wrap.betrecord .betRecords-select {
  width: 132px;
  text-align: center;
  margin-right: 10px !important;
}
.account-wrap.betrecord .betRecords-select .ant-select-selection {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #e0e0e0;
  border-radius: 5px;
  font-size: 12px;
  color: #222222;
}
.account-wrap.betrecord .betRecords-select .ant-select-selection__rendered {
  margin-left: 10px !important;
  margin-right: 20px;
}
.account-wrap.betrecord .betRecords-select .ant-select-selection__rendered > div {
  width: 100%;
}
.account-wrap.betrecord .betRecords-select .ant-select-arrow {
  right: 5px;
}
.account-wrap.betrecord .betRecords-select .ant-select-open .ant-select-selection {
  border: 1px solid #e0e0e0 !important;
}
.account-wrap.betrecord .ant-popover.betRecords-popover-dark .ant-popover-inner-content {
  color: #fff;
  opacity: 1;
}
.account-wrap.betrecord .ant-popover.betRecords-popover-dark > .ant-popover-content > .ant-popover-arrow {
  border-top-color: #2c2c2c;
  border-left-color: #2c2c2c;
  left: 23px;
}
.account-wrap.betrecord .betRecords-popover-dark .ant-popover-inner-content {
  padding: 15px;
}
.account-wrap.betrecord .betRecord-message-button {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.account-wrap.betrecord .betRecord-message-button .ant-select-dropdown-menu-item {
  padding: 8px 29px !important;
}
.account-wrap.betrecord .betRecord-message-button .ant-select-dropdown-menu-item .ant-select-dropdown-menu-item-active {
  background-color: #0000000a !important;
}
.account-wrap.betrecord .betRecord-message-button .ant-select-dropdown-menu-item-selected {
  background-color: #0000000a !important;
}
.account-wrap.betrecord .betRecord-radio-group .ant-radio-button-wrapper:hover {
  color: #000;
}
.account-wrap.betrecord .betRecord-radio-group .ant-radio-button-wrapper {
  padding: 8px;
  text-align: left;
  letter-spacing: 0px;
  color: #222222;
  opacity: 1;
  line-height: 16px;
}
.account-wrap.betrecord .betRecord-radio-group.ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  color: white;
  background: #00a6ff;
  border-color: #00a6ff;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.account-wrap.betrecord .betRecord-radio-group.ant-radio-group-solid .ant-radio-button-wrapper-checked:not(
            .ant-radio-button-wrapper-disabled
        ):hover {
  background: #858585;
  border-color: #858585;
  box-shadow: none;
}
.account-wrap.betrecord .betRecord-radio-group.ant-radio-group-solid .ant-radio-button-wrapper-checked:not(
            .ant-radio-button-wrapper-disabled
        ):active {
  background: #525252;
  border-color: #525252;
}
.account-wrap.betrecord .question-popover-tip {
  width: 24px;
  height: 24px;
  line-height: 24px;
  text-align: center;
  font-size: 20px;
  background-color: #00a6ff;
  border-radius: 12px;
  padding: 0;
  margin-left: 10px;
  cursor: pointer;
}
.account-wrap.betrecord .question-popover-tip .anticon-info {
  color: #ffff;
  font-weight: bold;
}
.ant-pagination.betrecord-pagination .ant-pagination-item,
.ant-pagination.betrecord-pagination .ant-pagination-item-link {
  border-color: #e0e0e0;
}
.ant-pagination.betrecord-pagination .ant-pagination-item-active {
  border-color: #f0f0f2;
  background-color: #f0f0f2;
}
.ant-pagination.betrecord-pagination .ant-pagination-item-active a {
  color: #222;
}
.ant-pagination.betrecord-pagination .ant-pagination-item:focus,
.ant-pagination.betrecord-pagination .ant-pagination-item:hover,
.ant-pagination.betrecord-pagination .ant-pagination-prev:focus .ant-pagination-item-link,
.ant-pagination.betrecord-pagination .ant-pagination-next:focus .ant-pagination-item-link,
.ant-pagination.betrecord-pagination .ant-pagination-prev:hover .ant-pagination-item-link,
.ant-pagination.betrecord-pagination .ant-pagination-next:hover .ant-pagination-item-link {
  color: #222;
  border-color: #888;
}
.ant-pagination.betrecord-pagination .ant-pagination-item:focus a,
.ant-pagination.betrecord-pagination .ant-pagination-item:hover a,
.ant-pagination.betrecord-pagination .ant-pagination-prev:focus .ant-pagination-item-link a,
.ant-pagination.betrecord-pagination .ant-pagination-next:focus .ant-pagination-item-link a,
.ant-pagination.betrecord-pagination .ant-pagination-prev:hover .ant-pagination-item-link a,
.ant-pagination.betrecord-pagination .ant-pagination-next:hover .ant-pagination-item-link a {
  color: #222;
}
.ant-pagination.betrecord-pagination .ant-pagination-disabled .ant-pagination-item-link {
  color: #cccccc;
}
.ant-pagination.betrecord-pagination .ant-pagination-disabled .ant-pagination-item-link:hover {
  border-color: #e0e0e0;
  color: #222;
}
.ant-select-dropdown-menu-item-active:not(
        .ant-select-dropdown-menu-item-disabled
    ) {
  background-color: #0000000a !important;
}
.disabled-date .ant-calendar-disabled-cell .ant-calendar-date {
  background: none;
}
.disabled-date .ant-calendar-disabled-cell.ant-calendar-selected-day .ant-calendar-date::before {
  background: none;
}
.security-code-reminder-modal .ant-modal-content {
  border-radius: 20px;
}
.security-code-reminder-modal .ant-modal-content .ant-modal-header div {
  text-align: center;
  color: #fff;
}
.security-code-reminder-modal .ant-modal-content .ant-modal-body {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 30px;
}
.security-code-reminder-modal .ant-modal-content .ant-modal-body img {
  margin-bottom: 20px;
}
.security-code-reminder-modal .ant-modal-content .ant-modal-body .sub-title {
  display: block;
  margin-bottom: 10px;
  font: normal normal 600 14px/24px Roboto;
  letter-spacing: 0px;
  color: #222222;
  font-weight: 600;
}
.security-code-reminder-modal .ant-modal-content .ant-modal-body p {
  margin: 0;
  text-align: center;
  font: normal normal normal 14px/24px Roboto;
  color: #222222;
}
.security-code-reminder-modal .ant-modal-content .ant-modal-footer {
  padding: 0 28px 28px 28px;
}
.security-code-reminder-modal .ant-modal-content .ant-modal-footer div {
  display: flex;
  justify-content: space-between;
}
.security-code-reminder-modal .ant-modal-content .ant-modal-footer button {
  width: 45%;
  margin: 0;
  height: -moz-fit-content;
  height: fit-content;
}
.security-code-reminder-modal .ant-modal-content .ant-modal-footer button:first-child {
  border: 1px solid #00a6ff;
  color: #00a6ff;
}
.security-code-reminder-modal .ant-modal-content .ant-modal-footer button span {
  display: block;
  padding: 7px 0;
}
.usercenter-dropdown {
  border-radius: 0 0 8px 8px !important;
  box-shadow: unset !important;
  border: 1px solid #cccccc;
  border-top: unset;
}
.usercenter-dropdown .ant-select-dropdown-menu-item-selected {
  background-color: transparent;
  font-weight: unset;
}
.user-info-dob-picker-dropdown .ant-calendar-input-wrap {
  display: none;
}
.user-info-dob-picker-dropdown .ant-calendar-date-panel .ant-calendar-header > div > a::before,
.user-info-dob-picker-dropdown .ant-calendar-date-panel .ant-calendar-header > div > a::after {
  border: 0 solid #000;
  border-width: 2px 0 0 2px;
}
.user-info-dob-picker-dropdown .ant-calendar-date-panel .ant-calendar-header .ant-calendar-my-select a {
  color: black;
}
.user-info-dob-picker-dropdown .ant-calendar-date-panel .ant-calendar-body .ant-calendar-disabled-cell .ant-calendar-date::before,
.user-info-dob-picker-dropdown .ant-calendar-date-panel .ant-calendar-body .ant-calendar-selected-day .ant-calendar-date::before {
  background: none;
}
.ant-modal.blueHeaderModal .ant-modal-content {
  width: 100%;
}
.ant-modal.blueHeaderModal .ant-modal-content .ant-modal-close-x {
  display: none !important;
}
.ant-modal.blueHeaderModal .ant-modal-body {
  padding: 0 !important;
}
.ant-modal.blueHeaderModal .ant-modal-confirm-title {
  color: white !important;
  background: #00a6ff;
  padding: 0.8rem;
  border-radius: 10px 10px 0 0;
  text-align: center;
}
.ant-modal.blueHeaderModal .ant-modal-confirm-content {
  padding: 0 20px;
}
.ant-modal.blueHeaderModal .ant-modal-confirm-btns {
  margin-bottom: 20px;
  padding: 0 30px;
  float: inherit;
}
.ant-modal.blueHeaderModal .ant-modal-confirm-btns .ant-btn {
  border: 1px solid #00a6ff;
  color: #00a6ff;
  width: 80%;
}
.ant-modal.blueHeaderModal .ant-modal-confirm-btns .ant-btn-primary {
  color: #fff !important;
}
.settingRequirement {
  background: #f5f5f5;
  padding: 0.6rem;
  border-radius: 0.4rem;
  color: #8f8f8f;
}
.verificationSubmitBtn .ant-btn-primary[disabled],
.verificationSubmitBtn .ant-btn-primary[disabled]:hover {
  background: #cccccc;
  color: #ffffff;
  border-color: #cccccc;
}
.verificationSubmitBtn .ant-btn-primary,
.verificationSubmitBtn .ant-btn-primary:hover,
.verificationSubmitBtn .ant-btn-primary:focus {
  background: #35c95b;
  border-color: #35c95b;
}
.addBankModal .ant-form-explain {
  order: 1 !important;
  text-align: left;
  background: #fee0e0;
  color: #eb2121;
  border-radius: 5px;
  padding: 0.4rem 0.6rem;
  margin-top: 0.3rem !important;
}
.addBankModal .defaultCheckbox .ant-checkbox-inner {
  border-radius: 5px;
}
.addBankModal .bankNameSelectDropdown .option-item {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
  width: 100%;
}
.addBankModal .bankNameSelectDropdown .option-item div {
  width: 10%;
}
.addBankModal .bankNameSelectDropdown .option-item span {
  display: inline-block;
  width: 80%;
  overflow: hidden;
  text-overflow: ellipsis;
}
.safety-question-dropdown .ant-select-dropdown-menu,
.account-dropdown .ant-select-dropdown-menu {
  max-height: unset;
}
.safety-question-dropdown .ant-select-dropdown-menu li,
.account-dropdown .ant-select-dropdown-menu li {
  text-align: left !important;
}
#maintain.common-distance-wrap {
  margin: 0 auto;
  min-width: 1200px;
  min-height: 100vh;
  background-color: #efeff4;
  overflow: hidden;
}
#maintain.common-distance-wrap .maintain-header-wrap {
  width: 100%;
  height: 120px;
  min-width: 1200px;
  background-color: #00a6ff;
}
#maintain.common-distance-wrap .maintain-header-wrap .common-distance {
  width: 1200px;
  height: 120px;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  color: #fff;
}
#maintain.common-distance-wrap .maintain-header-wrap .common-distance .live-server-btn {
  display: grid;
  place-items: center;
  padding-top: 5px;
}
#maintain.common-distance-wrap .maintain-header-wrap .common-distance .live-server-btn .live-service {
  width: 34px;
  height: 34px;
  background-position: 0 -81px;
  transition: all 0.2s;
  cursor: pointer;
  transform: scale(0.86);
}
#maintain.common-distance-wrap.black {
  background-image: url("/vn/img/maintain/y-so-serious.png");
}
#maintain.common-distance-wrap .tlc-sprite {
  background-image: url("/vn/img/home/sprite20220315.png");
  background-repeat: no-repeat;
}
#maintain.common-distance-wrap .center {
  text-align: center;
}
#maintain.common-distance-wrap .inline-block {
  display: inline-block;
}
#maintain.common-distance-wrap .maintain-content-wrap .tlc-sprite {
  width: 16px;
  height: 16px;
  display: inline-block;
  margin-right: 10px;
  vertical-align: -0.26em;
}
#maintain.common-distance-wrap .maintain-content-wrap .tlc-sprite.maintain-email {
  background-position: -247px -134px;
}
#maintain.common-distance-wrap .maintain-content-wrap .tlc-sprite.maintain-service {
  background-position: -293px -133px;
}
#maintain.common-distance-wrap .maintain-content-wrap .tlc-sprite.maintain-phone {
  background-position: -269px -133px;
}
#maintain.common-distance-wrap .maintain-content {
  padding: 50px 70px;
  height: 800px;
  width: 1100px;
  background-image: url("/vn/img/closeIcon/Mask_no.png");
  background-repeat: no-repeat;
  background-size: 100% auto;
  border-radius: 10px;
  overflow: hidden;
  margin-top: 50px;
}
#maintain.common-distance-wrap .maintain-content .maintain-img {
  vertical-align: top;
  margin-top: 10px;
}
#maintain.common-distance-wrap .maintain-content.black {
  background-image: url("/vn/img/maintain/black.png");
}
#maintain.common-distance-wrap .maintain-content .tlc-date .ant-calendar-picker-icon {
  background-image: none;
}
#maintain.common-distance-wrap .maintain-content > div {
  width: 50%;
  vertical-align: middle;
}
#maintain.common-distance-wrap .maintain-info {
  box-shadow: 0 0 5px #ccc;
  background-color: #fff;
  border-radius: 8px;
  padding-top: 50px;
  padding-bottom: 40px;
  padding-left: 30px;
  padding-right: 30px;
  width: 40% !important;
  margin-left: 5%;
}
#maintain.common-distance-wrap .maintain-info h2 {
  text-align: center;
  font-weight: bold;
  font-size: 24px;
  margin-top: 2rem;
}
#maintain.common-distance-wrap .maintain-info img {
  margin: auto;
  display: flex;
}
#maintain.common-distance-wrap .maintain-info p {
  padding-bottom: 30px;
  padding-left: 20px;
  padding-right: 20px;
}
#maintain.common-distance-wrap .maintain-info p.description {
  padding-left: 0px;
  padding-right: 0px;
  font-size: 16px;
}
#maintain.common-distance-wrap .maintain-info p.description.verify-des {
  padding: 0 0 0.5rem 0;
}
#maintain.common-distance-wrap .maintain-info button {
  width: 100%;
  height: 40px;
  border-radius: 8px;
  text-align: center;
  transition: 0.2s all;
  margin-top: 10px;
  outline: none;
  margin-bottom: 30px;
}
#maintain.common-distance-wrap .maintain-info button.white-btn {
  border: 1px solid #666;
  background-color: #fff;
  margin-bottom: 0px;
}
#maintain.common-distance-wrap .maintain-info button.theme-btn {
  border: 1px solid #00a6ff;
  background-color: #00a6ff;
  cursor: pointer;
  color: #fff;
}
#maintain.common-distance-wrap .maintain-info button.verify-btn {
  border: 1px solid #1c8eff;
  background-color: #1c8eff;
  cursor: pointer;
  color: #fff;
  margin-bottom: 0px;
}
#maintain.common-distance-wrap .maintain-info button.verify-btn.isDisable {
  border-color: #fff;
  background-color: #00000033;
}
#maintain.common-distance-wrap .maintain-info button.verify-btn.isDisable:hover {
  border-color: #fff;
  background-color: #00000033;
}
#maintain.common-distance-wrap .maintain-info button.verify-btn:hover {
  border-color: #1c66ff;
  background-color: #1c66ff;
}
#maintain.common-distance-wrap .maintain-info button.theme-btn:hover {
  border-color: #d21212;
  background-color: #d21212;
}
#maintain.common-distance-wrap .reminder {
  text-align: center;
  margin: -1rem auto 0rem;
}
#maintain.common-distance-wrap .red {
  color: #f92d2d;
}
#maintain.common-distance-wrap .cs-blue {
  color: #1c8eff;
  cursor: pointer;
}
.header-common-distance-wrap .maintain-header-wrap {
  width: 100%;
  height: 120px;
  min-width: 1200px;
  background-color: #00a6ff;
}
.header-common-distance-wrap .maintain-header-wrap .tlc-sprite {
  display: inline-block;
  width: 32px;
  height: 32px;
}
.header-common-distance-wrap .maintain-header-wrap .tlc-sprite.xijia {
  background-position: -48px -81px;
}
.header-common-distance-wrap .maintain-header-wrap .tlc-sprite.ehome {
  background-position: -87px -79px;
}
.header-common-distance-wrap .maintain-header-wrap .common-distance > div.inline-block {
  width: 50%;
  color: #fff;
  line-height: 1.3;
}
.header-common-distance-wrap .maintain-header-wrap .common-distance > div.inline-block:last-child {
  text-align: right;
}
.header-common-distance-wrap .maintain-header-wrap .common-distance > div.inline-block .tlc-partner-section {
  text-align: left;
}
.header-common-distance-wrap .maintain-header-wrap .common-distance .logo-wrap img {
  margin-top: 10px;
  height: 100px;
}
.header-common-distance-wrap .maintain-header-wrap .common-distance .sponsor-wrap {
  vertical-align: -2.4em;
}
.reset-wrap {
  width: 430px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 0 5px #cacaca;
  margin: 60px auto;
}
.reset-wrap > h3 {
  font-weight: bold;
  text-align: center;
  background-color: #00a5fd;
  padding: 15px;
  color: white;
  border-radius: 8px 8px 0 0;
}
.reset-wrap .line-distance {
  height: 10px;
}
.reset-wrap .ant-form-item-required::before {
  content: "";
}
.link-resetpassword-page .maintain-header-wrap {
  background-color: #00A6FF;
  height: 120px;
}
.link-resetpassword-page .maintain-header-wrap .common-distance {
  flex-direction: row;
  justify-content: space-between;
}
.link-resetpassword-page .maintain-header-wrap .common-distance .sponsor-wrap {
  font-size: 12px;
  color: #fff;
}
.defined-error .ant-input,
.defined-error .ant-input:hover {
  background-color: #fff;
  border-color: #ff4d4f;
}
.defined-error .ant-form-explain,
.defined-error .ant-form-split,
.defined-error .ant-form-extra {
  color: #ff4d4f;
}
.modal-pubilc {
  text-align: center;
}
.modal-pubilc .ant-tabs {
  overflow: visible;
}
.modal-pubilc .ant-modal-header {
  background: #00a5fd;
  border-radius: 20px 20px 0 0;
}
.modal-pubilc .ant-modal-header .ant-modal-title {
  color: #fff;
  font-size: 20px;
  height: 27px;
  text-align: center;
}
.modal-pubilc .ant-modal-content {
  border-radius: 20px;
}
.modal-pubilc .ant-modal-content .ant-modal-body {
  padding: 30px 35px;
}
.modal-pubilc .ant-modal-content .ant-modal-body .ant-input-prefix img {
  width: 20px;
  height: 20px;
}
.modal-pubilc .ant-modal-content .ant-modal-body .ant-input-affix-wrapper .ant-input:not(:first-child) {
  padding-left: 38px;
}
.modal-pubilc .ant-modal-content .ant-modal-body .IputBox {
  margin-bottom: 16px;
}
.modal-pubilc .ant-modal-content .ant-modal-body .ant-form.ant-form-horizontal .ant-form-item-control.has-error {
  display: flex;
  flex-direction: column;
}
.modal-pubilc .ant-modal-content .ant-modal-body .ant-form.ant-form-horizontal .ant-form-item-control.has-error .ant-form-explain {
  order: -1;
  margin-top: 0px;
  margin-bottom: 10px;
}
.modal-pubilc .ant-modal-content .ant-modal-body .ant-btn-primary {
  font-weight: 700;
}
.modal-pubilc .ant-modal-content .ant-modal-body .login-error {
  margin-bottom: 10px;
  font-size: 12px;
  text-align: left;
  letter-spacing: 0px;
  color: #eb2121;
  background: #fee5e5 0% 0% no-repeat padding-box;
  border-radius: 8px;
  opacity: 1;
  padding: 10px;
}
.modal-pubilc .ant-modal-content .ant-modal-body .loginError_MEM00061 {
  position: absolute;
  left: -110px;
  top: -240px;
  width: 550px;
  text-align: center;
  border-radius: 50px;
  padding: 15px;
}
.modal-pubilc .ant-modal-content .ant-modal-body .loginError_MEM00061 img {
  width: 30px;
  padding-right: 5px;
}
.modal-pubilc .ant-modal-content button:disabled {
  background-color: #ccc;
  border-color: #ccc;
  color: #fff;
}
.modal-pubilc.login-and-registration .ant-modal-header {
  text-align: center;
}
.modal-pubilc.login-and-registration .ant-modal-header .ant-modal-title {
  font-weight: 400;
}
.modal-pubilc.login-and-registration .ant-tabs-nav-wrap {
  width: 100%;
  height: 40px;
}
.modal-pubilc.login-and-registration .ant-modal-content {
  border-radius: 20px;
}
.modal-pubilc.login-and-registration .ant-modal-content .ant-modal-body .ant-form-item {
  margin-bottom: 0px;
}
.modal-pubilc.login-and-registration .ant-modal-content .ant-modal-body .IputBox {
  margin-bottom: 10px;
}
.modal-pubilc.login-and-registration .ant-modal-content .ant-modal-body .IputBox.emailInput {
  position: relative;
}
.modal-pubilc.login-and-registration .ant-modal-content .ant-modal-body .IputBox.emailInput .emailList {
  width: 100%;
  height: 225px;
  background-color: #fff;
  border: 1px solid #BCBEC3;
  border-radius: 10px;
  overflow-y: scroll;
  position: absolute;
  left: 0;
  top: 46px;
  z-index: 3;
}
.modal-pubilc.login-and-registration .ant-modal-content .ant-modal-body .IputBox.emailInput .emailList .ant-col {
  line-height: 45px;
  text-align: left;
  padding: 0 10px;
  cursor: pointer;
}
.modal-pubilc.login-and-registration .ant-modal-content .ant-modal-body .IputBox.emailInput .emailList .ant-col:hover {
  background-color: #E5F6FF;
}
.modal-pubilc.login-and-registration .ant-modal-content .ant-modal-body .IputBox.emailInput .emailList .ant-col:first-child:hover {
  border-radius: 10px 10px 0 0;
}
.modal-pubilc.login-and-registration .ant-modal-content .ant-modal-body .IputBox.emailInput .emailList .ant-col:last-child:hover {
  border-radius: 0 0 10px 10px;
}
.modal-pubilc.login-and-registration .ant-modal-content .ant-modal-body .IputBox .ant-input-affix-wrapper .ant-input {
  border: 1px solid #BCBEC3;
}
.modal-pubilc.login-and-registration .ant-modal-content .ant-modal-body .IputBox .ant-input-affix-wrapper .ant-input::-moz-placeholder {
  color: #BCBEC3;
}
.modal-pubilc.login-and-registration .ant-modal-content .ant-modal-body .IputBox .ant-input-affix-wrapper .ant-input:-ms-input-placeholder {
  color: #BCBEC3;
}
.modal-pubilc.login-and-registration .ant-modal-content .ant-modal-body .IputBox .ant-input-affix-wrapper .ant-input::placeholder {
  color: #BCBEC3;
}
.modal-pubilc.login-and-registration .ant-modal-content .ant-modal-body .IputBox .ant-input-affix-wrapper .ant-input:hover {
  border-color: #00a5fd;
}
.modal-pubilc.login-and-registration .ant-modal-content .ant-modal-body .has-error .ant-input-affix-wrapper .ant-input {
  border-color: #ff4d4f;
}
.modal-pubilc.login-and-registration .ant-modal-content .ant-modal-body .has-error .ant-input-affix-wrapper .ant-input:hover {
  border-color: #ff4d4f;
}
.modal-pubilc.login-and-registration .ant-modal-content .ant-modal-body .ant-form.ant-form-horizontal .ant-form-item-control.has-error {
  display: flex;
  flex-direction: column;
}
.modal-pubilc.login-and-registration .ant-modal-content .ant-modal-body .ant-form.ant-form-horizontal .ant-form-item-control.has-error .ant-form-explain {
  order: -1;
  margin-top: 0px;
  margin-bottom: 10px;
}
.modal-pubilc .Forget-pwd-tabs .ant-tabs-bar {
  margin-bottom: 32px;
}
.modal-pubilc .Forget-pwd-tabs .ant-tabs-ink-bar {
  height: 98%;
  z-index: -1;
  border-radius: 50px;
}
.modal-pubilc .Forget-pwd-tabs .ant-tabs-tabpane label {
  font-size: 12px;
  text-align: left;
}
.modal-pubilc .Forget-pwd-tabs .IputBox {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.modal-pubilc .Forget-pwd-tabs .invalid-input-error {
  margin-bottom: 10px;
  font-size: 12px;
  text-align: left;
  letter-spacing: 0px;
  color: #eb2121;
  background: #fee5e5 0% 0% no-repeat padding-box;
  border-radius: 8px;
  opacity: 1;
  padding: 10px;
}
.modal-pubilc .Forget-pwd-tabs .invalid-input-box-error {
  border-color: #ff4d4f;
}
.modal-pubilc .Forget-pwd-tabs .error {
  margin-top: 10px;
  font-size: 12px;
  text-align: left;
  letter-spacing: 0px;
  color: #eb2121;
  background: #fee5e5 0% 0% no-repeat padding-box;
  border-radius: 8px;
  opacity: 1;
  padding: 10px;
}
.modal-pubilc .Forget-pwd-tabs label {
  display: block;
  margin: 0px 0px 5px 5px;
}
.modal-pubilc .Forget-pwd-tabs.tabs-modal-pubilc {
  overflow: hidden;
}
.modal-pubilc .Forget-pwd-tabs.tabs-modal-pubilc .ant-tabs-content .ant-tabs-tabpane label {
  color: #222;
  font-weight: 500;
}
.modal-pubilc .Forget-pwd-tabs.tabs-modal-pubilc .ant-tabs-content .ant-tabs-tabpane .IputBox {
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-bottom: 32px;
}
.modal-pubilc .Forget-pwd-tabs.tabs-modal-pubilc .ant-tabs-content .ant-tabs-tabpane .IputBox.forgetuser-input {
  gap: 0;
}
.modal-pubilc .Forget-pwd-tabs.tabs-modal-pubilc .ant-tabs-content .ant-tabs-tabpane .IputBox.forgetuser-input button {
  background-color: #00a6ff;
}
.modal-pubilc .IputBox .registerPhone.ant-input-affix-wrapper .ant-input-prefix span {
  margin-left: 5px;
}
.modal-pubilc .IputBox .registerPhone.ant-input-affix-wrapper .ant-input:not(:first-child) {
  padding-left: 65px;
}
.modal-pubilc .IputBox.fix-not-required-text {
  position: relative;
}
.modal-pubilc .IputBox.fix-not-required-text .not-required-text {
  position: absolute;
  right: 20px;
  bottom: 10px;
  letter-spacing: 0px;
  color: #999999;
  opacity: 1;
  font-size: 14px;
}
.modal-pubilc .tabs-modal-pubilc .ant-tabs-bar {
  background-color: #7676801f;
  border-radius: 50px;
  margin-bottom: 30px;
}
.modal-pubilc .tabs-modal-pubilc .ant-tabs-bar .ant-tabs-nav-container {
  border-radius: 50px;
  border: 3px solid #EFEFF0;
}
.modal-pubilc .tabs-modal-pubilc .ant-tabs-bar .ant-tabs-nav-container .ant-tabs-nav-scroll {
  width: 100%;
}
.modal-pubilc .tabs-modal-pubilc .ant-tabs-bar .ant-tabs-nav-container .ant-tabs-nav-scroll .ant-tabs-nav {
  width: 100%;
  z-index: 10;
}
.modal-pubilc .tabs-modal-pubilc .ant-tabs-bar .ant-tabs-nav-container .ant-tabs-nav-scroll .ant-tabs-nav > div:not(:last-child) {
  width: 100%;
  display: flex;
}
.modal-pubilc .tabs-modal-pubilc .ant-tabs-bar .ant-tabs-nav-container .ant-tabs-nav-scroll .ant-tabs-nav > div:not(:last-child) > div {
  width: 100%;
  margin: 0;
  font-size: 16px;
  font-weight: 400;
}
.modal-pubilc .tabs-modal-pubilc .ant-tabs-bar .ant-tabs-nav-container .ant-tabs-nav-scroll .ant-tabs-nav > div:not(:last-child) .ant-tabs-tab-active {
  color: white !important;
  font-weight: 500;
}
.modal-pubilc .tabs-modal-pubilc .ant-tabs-bar .ant-tabs-nav-container .ant-tabs-nav-scroll .ant-tabs-nav .ant-tabs-ink-bar {
  height: 98%;
  z-index: -1;
  border-radius: 50px;
}
.modal-pubilc .link-box {
  text-align: right;
  height: 16px;
  margin-bottom: 30px;
}
.modal-pubilc .link-box button:first-child {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 0;
  height: 16px;
  margin-left: auto;
}
.modal-pubilc .link-box span {
  font-size: 12px;
}
.modal-pubilc .protocol-box {
  padding: 0 15px;
}
.modal-pubilc .protocol-box .protocol {
  font-size: 12px;
  text-align: center;
  margin-top: 20px;
  color: #999;
}
.modal-pubilc.exceed-modal .ant-modal-body {
  background-color: white;
  text-align: center;
}
.modal-pubilc.exceed-modal .ant-modal-body .otp-modal-title {
  font-size: 18px;
  margin: 13px auto;
}
.modal-pubilc.exceed-modal .ant-modal-body .otp-cs-tip {
  text-align: center;
}
.modal-pubilc.exceed-modal .ant-modal-footer {
  margin: auto;
  padding: 0 20px 20px;
  text-align: center;
}
.modal-pubilc.exceed-modal .ant-modal-footer .ant-btn {
  width: 45%;
}
.modal-pubilc.exceed-modal .ant-modal-footer .ant-btn:first-child {
  color: #00a6ff;
  border-color: #00a6ff;
}
.modal-pubilc.OTP-modal .ant-modal-content {
  background-color: white;
}
.modal-pubilc.OTP-modal .ant-modal-content .ant-modal-header .ant-modal-title {
  text-align: center;
}
.modal-pubilc.OTP-modal .ant-modal-body {
  padding: 30px 32px 24px 32px;
  background-color: white;
  border-radius: 20px;
}
.modal-pubilc.OTP-modal .ant-modal-body .otp-modal-title {
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  margin: 20px auto;
  color: #222222;
}
.modal-pubilc.OTP-modal .ant-modal-body .otp-warn {
  display: block;
  margin: 0 auto;
}
.modal-pubilc.OTP-modal .ant-modal-body .otp-modal-banner {
  margin-left: -32px;
  margin-top: -30px;
}
.modal-pubilc.OTP-modal .ant-modal-body .otp-modal-description {
  margin: 20px 0;
  padding: 10px;
  background-color: white;
  border-radius: 10px;
  font-size: 14px;
}
.modal-pubilc.OTP-modal .ant-modal-body .otp-modal-description h4 {
  font-weight: 600;
  margin: 10px 0;
}
.modal-pubilc.OTP-modal .ant-modal-body .otp-modal-description .otp-cs {
  color: #1c8eff;
  cursor: pointer;
}
.modal-pubilc.OTP-modal .ant-modal-body .verify-icon-wrap {
  width: 70px;
  height: 70px;
  margin: 0 auto;
  display: block;
  background: white;
  border-radius: 10px;
  position: relative;
  cursor: pointer;
  transition: 0.5s;
}
.modal-pubilc.OTP-modal .ant-modal-body .verify-icon-wrap .verify-icon {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.modal-pubilc.OTP-modal .ant-modal-body .verify-icon-wrap.red-email,
.modal-pubilc.OTP-modal .ant-modal-body .verify-icon-wrap.red-phone,
.modal-pubilc.OTP-modal .ant-modal-body .verify-icon-wrap.red-cs {
  background: #66caff;
}
.modal-pubilc.OTP-modal .ant-modal-body .verify-icon-wrap:checked {
  background: #0095e6;
}
.modal-pubilc.OTP-modal .ant-modal-body .verify-icon-content {
  text-align: center;
  padding-top: 10px;
}
.modal-pubilc.OTP-modal .ant-modal-body .OtpList {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.modal-pubilc.OTP-modal .ant-modal-body .otpBox {
  background-color: white;
  padding: 10px;
  border-radius: 8px;
  text-align: center;
}
.modal-pubilc.OTP-modal .ant-modal-body .otpBtn {
  margin: 30px auto 10px;
  color: #00a6ff;
  background: #fff;
  border: 1px solid #00a6ff;
  border-radius: 8px;
}
.modal-pubilc.OTP-modal .ant-modal-body .otpBtn.readMore {
  color: #fff;
  background: #00a6ff;
  margin-top: 10px;
}
.modal-pubilc.OTP-modal .ant-modal-body .otpBtn.securityAnnouncement {
  width: 332px;
  margin-left: 54px;
  margin-right: 54px;
}
.modal-pubilc.OTP-modal .ant-modal-body .verification-form-wrap .timeTip {
  width: 100%;
  text-align: center;
}
.modal-pubilc.OTP-modal .ant-modal-body .verification-form-wrap .error-tip {
  width: 100%;
  height: 20px;
  line-height: 20px;
  text-align: left;
  color: #f00;
  font-size: 12px;
}
.modal-pubilc.OTP-modal .ant-modal-body .verification-form-wrap .greyText {
  border-radius: 10px;
  background: #F7F7FC;
  display: flex;
  padding: 8px 10px;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  margin: 10px auto 0;
  color: #666;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
}
.modal-pubilc.OTP-modal .ant-modal-body .verification-form-wrap .text-tip span {
  color: #1c8eff;
  cursor: pointer;
}
.modal-pubilc.OTP-modal .ant-modal-body .verification-form-wrap .tlc-input-disabled .icon-text-eyes {
  width: 20px;
  height: 20px;
  display: inline-block;
}
.modal-pubilc.OTP-modal .ant-modal-body .verification-form-wrap .tlc-input-disabled .icon-password-eyes {
  width: 20px;
  height: 20px;
  display: inline-block;
}
.modal-pubilc.OTP-modal .ant-modal-body .verification-form-wrap .Item-bankName.inactive .ant-form-item-control {
  box-shadow: 0;
  width: 100%;
}
.modal-pubilc.OTP-modal .ant-modal-body .verification-form-wrap .Item-bankName.active .ant-form-item-control {
  box-shadow: 0 0 1px 1px #0000001a, 0 1px 2px 0 #00000014, 0 2px 8px 2px #0000000d;
  border-bottom: none;
  border-radius: 8px 8px 0 0;
}
.modal-pubilc.OTP-modal .ant-modal-body .verification-form-wrap .bankNamewrap .ant-input:not(:first-child) {
  padding-left: 13px;
}
.modal-pubilc.OTP-modal .ant-modal-body .verification-form-wrap .bankNamewrap.have .ant-input:not(:first-child) {
  padding-left: 38px;
}
.modal-pubilc.OTP-modal .ant-modal-body .verification-form-wrap .bankNamewrap.active .ant-input {
  border-right: 0;
  border-bottom: 0;
  border-left: 0;
  border-radius: 0px;
  border: none;
  background: transparent;
}
.modal-pubilc.OTP-modal .ant-modal-body .verification-form-wrap .bankNameSelect .ant-select-selection {
  border: 1px solid #cccccc;
  box-shadow: none;
  border-radius: 4px;
  height: 35px;
  width: 96%;
  margin: 0 2%;
}
.modal-pubilc.OTP-modal .ant-modal-body .verification-form-wrap .bankNameSelect .ant-select-selection .searchwrap {
  width: 100%;
  height: 20px;
  line-height: 20px;
  background: none;
  display: flex;
  align-items: center;
}
.modal-pubilc.OTP-modal .ant-modal-body .verification-form-wrap .bankNameSelect .ant-select-selection .ant-select-selection__rendered {
  line-height: 35px;
}
.modal-pubilc.OTP-modal .ant-modal-body .verification-form-wrap .Item-bankName .notfind {
  width: 100%;
  text-align: center;
}
.modal-pubilc.OTP-modal .ant-modal-body .verification-form-wrap .Item-bankName .notfind img {
  width: 80px;
  height: 80px;
}
.modal-pubilc.OTP-modal .ant-modal-body .verification-form-wrap .Item-bankName .notfind p {
  font-size: 12px;
  color: #999999;
}
.modal-pubilc.OTP-modal.addBankModal .ant-modal-body {
  padding: 0 30px 30px 30px;
}
.modal-pubilc.OTP-modal.addBankModal .ant-modal-body .ant-row.ant-form-item:last-child {
  margin-bottom: 0;
}
.modal-pubilc.OTP-modal.addBankModal .forAddBankCard .ant-select .ant-select-selection {
  display: flex;
  align-items: center;
  height: 100%;
}
.modal-pubilc.OTP-modal.addBankModal .forAddBankCard .ant-select .ant-select-selection .ant-select-selection__rendered {
  margin-left: 20px;
}
.modal-pubilc.OTP-modal.addBankModal .forAddBankCard .ant-select-disabled .ant-select-selection .ant-select-arrow {
  display: block;
}
.modal-pubilc.otp-revalidate-time-out .ant-modal-title {
  font-weight: normal;
}
.modal-pubilc.otp-revalidate-time-out .otpPwdModal-Item {
  padding: 10px 0;
  font-size: 14px;
  color: #222222;
  text-align: center;
}
.modal-pubilc.CTC-INVOICE-AUT .ant-col-12 {
  text-align: center;
  border: 1px solid #00a6ff;
  border-radius: 6px;
  width: 45%;
  height: 40px;
  line-height: 40px;
  margin: 6% 2.5% 0;
  cursor: pointer;
}
.modal-pubilc.CTC-INVOICE-AUT .ant-col-12:first-child {
  color: #00a6ff;
}
.modal-pubilc.CTC-INVOICE-AUT .ant-col-12:last-child {
  color: #fff;
  background-color: #00a6ff;
}
.modal-pubilc.bankDetailsModal .ant-modal-body {
  padding: 24px;
}
.modal-pubilc.bankDetailsModal .ant-modal-body .ant-row {
  margin-left: 0px !important;
  margin-right: 0px !important;
}
.modal-pubilc.bankDetailsModal .ant-modal-body .ant-row .ant-col-12:first-child {
  padding-left: 0px !important;
  padding-right: 15px !important;
}
.modal-pubilc.bankDetailsModal .ant-modal-body .ant-row .ant-col-12:first-child div {
  width: 100%;
  height: 50px;
  line-height: 50px;
  border-bottom: 1px solid #1a1a1a1a;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #666666;
}
.modal-pubilc.bankDetailsModal .ant-modal-body .ant-row .ant-col-12:first-child div:last-child {
  border-bottom: 0;
}
.modal-pubilc.bankDetailsModal .ant-modal-body .ant-row .ant-col-12:last-child {
  padding-left: 15px !important;
  padding-right: 0px !important;
}
.modal-pubilc.bankDetailsModal .ant-modal-body .ant-row .ant-col-12:last-child label {
  color: #999999;
  font-size: 12px;
}
.modal-pubilc.bankDetailsModal .ant-modal-body .ant-row .ant-col-12:last-child .text1 {
  font-size: 16px;
  margin-bottom: 10px;
}
.modal-pubilc.bankDetailsModal .ant-modal-body .ant-row .ant-col-12:last-child .text2 {
  font-size: 12px;
  height: 20px;
  line-height: 20px;
}
.modal-pubilc.bankDetailsModal .ant-modal-body .ant-row .ant-col-12:last-child .text3 {
  font-size: 12px;
  color: #999999;
  border-bottom: 1px solid #1a1a1a1a;
  height: 20px;
  line-height: 20px;
  margin-bottom: 10px;
  padding-bottom: 10px;
}
.modal-pubilc.bankDetailsModal .ant-modal-body .ant-row .ant-col-12:last-child .text4,
.modal-pubilc.bankDetailsModal .ant-modal-body .ant-row .ant-col-12:last-child .text5 {
  width: 100%;
  color: #999999;
  height: 40px;
  line-height: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #1a1a1a1a;
}
.modal-pubilc.bankDetailsModal .ant-modal-body .ant-row .ant-col-12:last-child .text6 {
  font-size: 12px;
  background: #fff5bf;
  color: #a4893b;
  padding: 0.5rem;
  border-radius: 10px;
}
.modal-pubilc.bankDetailsModal .ant-modal-body .ant-row .ant-col-12:last-child .text6 span {
  color: #f00;
}
.modal-pubilc.bankDetailsModal .ant-modal-body .ant-row .ant-col-12:last-child .startDate-Item {
  position: relative;
}
.modal-pubilc.bankDetailsModal .ant-modal-body .ant-row .ant-col-12:last-child .startDate-Item .clearItem {
  position: absolute;
  right: 10px;
  top: -50px;
  color: #1c8eff;
  font-size: 12px;
  cursor: pointer;
}
.modal-pubilc.bankDetailsModal .ant-modal-body .ant-row .ant-col-24 div {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  border-bottom: 1px solid #f3f3f3;
  min-height: 50px;
  font-weight: bold;
  padding: 10px 0px;
}
.modal-pubilc.bankDetailsModal .ant-modal-body .ant-row .ant-col-24 div span {
  display: inline-block;
  width: 50%;
  overflow: hidden;
}
.modal-pubilc.bankDetailsModal .ant-modal-body .ant-row .ant-col-24 div span:last-child {
  text-align: right;
}
.modal-pubilc.bankDetailsModal .ant-modal-footer {
  padding: 0px 16px 24px 16px;
}
.modal-pubilc.bankDetailsModal .ant-modal-footer .ant-btn {
  width: 100%;
  line-height: 40px;
}
.modal-pubilc.finnInNameModal .ant-modal-body {
  padding: 30px 35px 0;
}
.modal-pubilc.finnInNameModal .ant-modal-body .ant-form-item .ant-form-item-label {
  font-weight: bold;
  line-height: 24px;
  padding-top: 20px;
}
.modal-pubilc.finnInNameModal .ant-modal-footer {
  padding: 0 35px 30px;
  height: 70px;
}
.modal-pubilc.finnInNameModal .ant-modal-footer div {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.modal-pubilc.finnInNameModal .ant-modal-footer div .ant-btn {
  width: 48%;
  height: 40px;
}
.modal-pubilc.finnInNameModal .ant-modal-footer div .ant-btn:first-child {
  border-color: #00a6ff;
  color: #00a6ff;
}
.modal-pubilc.news-modal .ant-checkbox-wrapper {
  width: 100%;
  text-align: center;
}
.modal-pubilc.news-modal .ant-checkbox-wrapper .ant-checkbox-inner {
  border-radius: 5px;
}
.modal-pubilc.news-modal .footer-btn {
  width: 100%;
  margin: 20px 0 0;
}
.modal-pubilc.RegisterBonus-modal .ant-modal-body {
  padding: 0 !important;
  border-radius: 10px;
  overflow: hidden;
}
.modal-pubilc.RegisterBonus-modal .headtext {
  width: 100%;
  height: 200px;
  margin-bottom: 10px;
  background-image: url("/vn/img/home/registerBanner.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
.modal-pubilc.RegisterBonus-modal .ant-col-18 {
  min-height: 40px;
  line-height: 18px;
}
.modal-pubilc.RegisterBonus-modal .ant-col-18.havePromotion {
  border-radius: 8px;
  background: #F5F5F5;
  padding: 10px;
  margin-top: 8px;
  color: #464646;
  cursor: pointer;
}
.modal-pubilc.RegisterBonus-modal .ant-col-18.havePromotion.active {
  background: #00a6ff;
  color: #fff;
}
.modal-pubilc.RegisterBonus-modal .ant-col-18.check-box .ant-checkbox-wrapper-checked {
  color: #000;
  padding: 20px 0 10px 0;
}
.modal-pubilc.RegisterBonus-modal .ant-modal-footer {
  width: 81%;
  margin: auto;
  padding-top: 20px;
  padding-bottom: 20px;
}
.modal-pubilc.RegisterBonus-modal .ant-modal-footer .ant-btn:last-child {
  padding-top: 10px;
  margin-left: 0;
}
.modal-pubilc.RegisterBonus-modal .ant-modal-footer .ant-btn:last-child span {
  text-decoration: underline;
}
.modal-pubilc.UploadFileModal .btn-content-set {
  text-align: left;
}
.modal-pubilc.UploadFileModal .btn-content-set p {
  color: #666;
}
.modal-pubilc.UploadFileModal .btn-content-set label {
  color: #222;
}
.modal-pubilc.UploadFileModal .btn-content-set h5 {
  display: inline-block;
  border-radius: 4px;
  background: #FFE273;
  padding: 5px;
  margin-left: 10px;
}
.modal-pubilc.UploadFileModal .btn-content-set .btn-content-file {
  background-color: #f5f5f5;
  text-align: right;
  border-radius: 10px;
  margin-top: 8px;
  display: flex;
  align-items: center;
  padding-left: 10px;
  margin-bottom: 10px;
}
.modal-pubilc.UploadFileModal .btn-content-set .btn-content-file .red-set {
  color: #F11818;
  font-size: 12px;
}
.modal-pubilc.UploadFileModal .btn-content-set .btn-content-file .imgname {
  text-align: left;
  width: 76%;
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.modal-pubilc.UploadFileModal .btn-content-set .btn-content-file button {
  border-radius: 0px 8px 8px 0px;
  border: 0;
  box-shadow: unset;
}
.modal-pubilc.UploadFileModal .btn-content-set .btn-content {
  display: flex;
  margin-top: 30px;
}
.modal-pubilc.UploadFileModal .btn-content-set .btn-content .ant-btn-background-ghost {
  margin-right: 10px;
}
.modal-pubilc.BalanceList .ant-modal-body {
  padding: 24px;
}
.modal-pubilc.BalanceList .Content {
  display: flex;
  flex-flow: wrap;
}
.modal-pubilc.BalanceList .Content .balance-box {
  flex: 0 0 50%;
  padding-bottom: 15px;
}
.modal-pubilc.BalanceList .Content .balance-box .num {
  color: #222222;
}
.modal-pubilc.BalanceList .Content .balance-box .localizedName {
  font-weight: 700;
  color: #666;
  font-size: 12px;
}
.modal-pubilc.BalanceList .Content .UnderMaintenance .localizedName,
.modal-pubilc.BalanceList .Content .UnderMaintenance .num {
  filter: grayscale(100%);
  opacity: 0.4;
}
.Spin_modal {
  top: 35%;
}
.Spin_modal .ant-modal-header {
  display: none;
}
.Spin_modal .ant-modal-body {
  text-align: center;
  height: 95px;
}
.Spin_modal .ant-modal-body .ant-spin {
  padding-top: 20%;
  border-radius: 8px;
}
.ant-modal-confirm .ant-modal-confirm-btns {
  text-align: center;
}
.ant-message {
  z-index: 10000 !important;
}
.bankAccountModals .ant-modal-content {
  position: relative;
  border-radius: 20px;
}
.bankAccountModals .ant-modal-content .ant-modal-header {
  border-radius: 20px 20px 0 0;
}
.bankAccountModals .ant-modal-content .ant-modal-header .ant-modal-title {
  font-weight: normal;
}
.bankAccountModals .ant-modal-content .ant-modal-body {
  padding: 0 40px 20px 40px;
}
.bankAccountModals .ant-modal-content .ant-modal-body .hoverShow {
  display: none;
  position: relative;
}
.bankAccountModals .ant-modal-content .ant-modal-body .hoverShow p {
  line-height: 16px;
  height: 16px;
}
.bankAccountModals .ant-modal-content .ant-modal-body .hoverShow span {
  width: 0;
  height: 0;
  border-width: 0 10px 10px;
  border-style: solid;
  border-color: transparent transparent black;
  position: absolute;
  left: 46.5%;
  top: -10px;
}
.bankAccountModals .ant-modal-content .ant-modal-body .iconTip img {
  width: 20px;
  height: 20px;
  display: inline-block;
  position: absolute;
  left: 63%;
  top: -39px;
  cursor: pointer;
}
.bankAccountModals .ant-modal-content .ant-modal-body .iconTip:hover .hoverShow {
  display: block;
  width: 350px;
  height: 180px;
  color: #fff;
  font-size: 12px;
  background-color: black;
  position: absolute;
  left: 38%;
  top: -5px;
  z-index: 100;
  padding: 10px 8px;
  border-radius: 10px;
}
.bankAccountModals .ant-modal-content .ant-modal-body .ant-row {
  margin-left: 0px !important;
  margin-right: 0px !important;
  margin-bottom: 5px !important;
}
.bankAccountModals .ant-modal-content .ant-modal-body .ant-row .ant-col-12 {
  margin-bottom: 35px;
  font-size: 12px;
  height: 120px;
}
.bankAccountModals .ant-modal-content .ant-modal-body .ant-row .ant-col-12:nth-child(odd) {
  padding-left: 0px !important;
  padding-right: 20px !important;
}
.bankAccountModals .ant-modal-content .ant-modal-body .ant-row .ant-col-12:nth-child(even) {
  padding-left: 20px !important;
  padding-right: 0px !important;
}
.bankAccountModals .ant-modal-content .ant-modal-body .ant-row .ant-col-12:last-child {
  padding-top: 21px;
}
.bankAccountModals .ant-modal-content .ant-modal-body .ant-row .ant-col-12 span {
  color: #666666;
}
.bankAccountModals .ant-modal-content .ant-modal-body .ant-row .ant-col-12 label {
  font-size: 12px;
  color: #222222;
}
.bankAccountModals .ant-modal-content .ant-modal-body .ant-row .ant-col-12 .ant-form-item-control .ant-form-item-children {
  position: relative;
}
.bankAccountModals .ant-modal-content .ant-modal-body .ant-row .ant-col-12 .ant-form-item-control .ant-form-item-children .money-icon {
  position: absolute;
  left: 10px;
}
.bankAccountModals .ant-modal-content .ant-modal-body .ant-row .ant-col-12 .ant-form-item-control .ant-form-item-children .ant-input {
  padding-left: 20px;
}
.bankAccountModals .ant-modal-content .ant-modal-body .ant-row .ant-col-12 .ant-form-item-control .ant-form-item-children .baifen-icon {
  position: absolute;
  right: 10px;
}
.bankAccountModals .ant-modal-content .ant-modal-body .settingRequirement {
  padding: 12px 16px;
}
.bankAccountModals .ant-modal-content .ant-modal-body .btnRow-wrap {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
}
.bankAccountModals .ant-modal-content .ant-modal-body .btnRow-wrap .ant-col-6 {
  text-align: center;
  border-radius: 6px;
  height: 40px;
  line-height: 40px;
  cursor: pointer;
}
.bankAccountModals .ant-modal-content .ant-modal-body .btnRow-wrap .ant-col-6:first-child {
  color: #000;
  border: 1px solid #cccccc;
}
.bankAccountModals .ant-modal-content .ant-modal-body .ant-btn {
  width: 320px;
}
.bankAccountModals .ant-modal-content .ant-modal-body .btnactive {
  color: #fff;
  background-color: #00a6ff;
}
.bankAccountModals .ant-modal-content .ant-modal-body .btnnoactive {
  color: #fff;
  background-color: #cccccc;
}
.tip-modal .ant-modal-body {
  background-color: transparent !important;
  padding: 0 !important;
}
.ant-modal.ant-modal-confirm.right-angle .ant-modal-content {
  border-radius: 0;
}
.fpcaptch-modal {
  z-index: 2002;
}
.fpcaptch-modal .ant-modal-close-x {
  color: #222;
}
.fpcaptch-modal .ant-modal-body {
  padding: 46px 30px 10px 16px;
}
.fpcaptch-modal .fpcontent .fpSection {
  width: 100%;
  height: 150px;
}
.fpcaptch-modal .fpcontent .fpSection .moveElement {
  width: 60px;
  height: 60px;
  position: relative;
  cursor: pointer;
}
.fpcaptch-modal .fpcontent .fpSection .info {
  width: 100%;
  position: absolute;
  background-color: #000;
  opacity: 0.7;
  left: 0;
  top: 0;
  color: #fff;
}
.fpcaptch-modal .fpcontent .fpSection .info img {
  margin: 10px 0 0 90%;
  width: 18px;
  height: auto;
  cursor: pointer;
}
.fpcaptch-modal .fpcontent .fpSection .info p {
  padding: 15px;
}
.fpcaptch-modal .fpcontent .verifySuccess {
  width: 100%;
  height: 40px;
  line-height: 40px;
  background-color: #10a0004a;
  color: #42d200;
}
.fpcaptch-modal .fpcontent .verifySuccess img {
  width: 18px;
  height: auto;
  margin-left: 10px;
  margin-right: 10px;
}
.fpcaptch-modal .fpcontent .verifyFail {
  width: 100%;
  height: 40px;
  line-height: 40px;
  background-color: rgba(255, 16, 0, 0.2);
  color: #F11818;
  padding-left: 10px;
}
.fpcaptch-modal .fpcontent .verifyFail img {
  width: 18px;
  height: auto;
  margin-left: 10px;
  margin-right: 10px;
}
.fpcaptch-modal .fpcontent .fpFooter {
  margin: 10px 0 0;
}
.fpcaptch-modal .fpcontent .fpFooter img {
  cursor: pointer;
}
.fpcaptch-modal .fpcontent .fpFooter img:first-child {
  margin-right: 15px;
}
.uploadImages-modal .ant-modal-title {
  font-size: 16px;
}
.uploadImages-modal .tipTextp {
  font-size: 14px;
  color: #222222;
}
.uploadImages-modal ul li {
  margin-bottom: 10px;
}
.uploadImages-modal ul li > span {
  font-size: 12px;
  color: #666;
}
.uploadImages-modal ul li .ant-btn {
  height: 40px;
  background-color: #f3f3f3;
  margin-top: 8px;
}
.uploadImages-modal ul li .ant-btn > span {
  color: #1c8eff;
}
.uploadImages-modal ul li .ant-btn:hover,
.uploadImages-modal ul li .ant-btn:focus {
  border-color: 1px solid #ccc;
}
.uploadImages-modal ul li .conbtn {
  width: 100%;
  height: 43px;
  border-radius: 8px;
  padding: 0 10px;
  border: 1px solid #e0e0e0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #f3f3f3;
}
.uploadImages-modal ul li .conbtn .ant-btn:hover,
.uploadImages-modal ul li .conbtn .ant-btn:focus {
  border: 1px solid #e0e0e0;
  color: #999999;
}
.uploadImages-modal ul li .conbtn span {
  width: 90%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.uploadImages-modal ul li .conbtn div {
  width: 20px;
  height: 20px;
  background-color: #00000066;
  border-radius: 13px;
  line-height: 18px;
  text-align: center;
}
.uploadImages-modal ul li .conbtn div img {
  width: 12px;
  height: 12px;
  vertical-align: middle;
}
.uploadImages-modal ul li:last-child .ant-btn {
  width: 46%;
  font-size: 14px;
  border-radius: 19px;
}
.uploadImages-modal ul li:last-child .ant-btn:first-child {
  margin-right: 4%;
}
.uploadImages-modal ul li:last-child .ant-btn:first-child > span {
  color: #666666;
}
.uploadImages-modal ul li:last-child .ant-btn:last-child {
  background-color: #cccccc;
  margin-left: 4%;
}
.uploadImages-modal ul li:last-child .ant-btn:last-child > span {
  color: #fff;
}
.uploadImages-modal ul li:last-child .ant-btn.active {
  background-color: #00a6ff;
  color: #ffffff;
}
.UsdtTeachModalStyle .ant-modal-body {
  padding: 0;
}
.UsdtTeachModalStyle .ant-modal-body .ant-tabs-bar {
  border-bottom: none;
  margin: 0;
  padding-top: 15px;
}
.UsdtTeachModalStyle .ant-modal-body .ant-tabs-tab {
  font-weight: bold;
}
.UsdtTeachModalStyle .ant-modal-close-x {
  font-size: 20px;
  color: white;
  height: 51px;
  line-height: 51px;
}
.Otptxt {
  color: #222222;
  font-weight: bold;
}
.confirm-modal-of-public .ant-modal-content,
.confirm-modal-of-forgetuser .ant-modal-content {
  border-radius: 20px;
}
.confirm-modal-of-public .ant-modal-content .ant-modal-body,
.confirm-modal-of-forgetuser .ant-modal-content .ant-modal-body {
  padding: 0;
}
.confirm-modal-of-public .ant-modal-content .ant-modal-confirm-body .ant-modal-confirm-title,
.confirm-modal-of-forgetuser .ant-modal-content .ant-modal-confirm-body .ant-modal-confirm-title {
  color: #fff;
  background-color: #00a6ff;
  padding: 19px 10px;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  text-align: center;
  font-size: 20px;
}
.confirm-modal-of-public .ant-modal-content .ant-modal-confirm-body .ant-modal-confirm-content,
.confirm-modal-of-forgetuser .ant-modal-content .ant-modal-confirm-body .ant-modal-confirm-content {
  padding-bottom: 0;
  margin-bottom: 39px;
  color: #222222;
  padding-left: 30px;
  padding-right: 30px;
}
.confirm-modal-of-public .ant-modal-content .ant-modal-confirm-btns,
.confirm-modal-of-forgetuser .ant-modal-content .ant-modal-confirm-btns {
  margin: 0 auto 30px;
  float: inherit;
}
.confirm-modal-of-public .ant-modal-content .ant-modal-confirm-btns .ant-btn,
.confirm-modal-of-forgetuser .ant-modal-content .ant-modal-confirm-btns .ant-btn {
  width: 40%;
  height: 40px;
}
.confirm-modal-of-public .ant-modal-content .ant-modal-confirm-btns .ant-btn:first-child,
.confirm-modal-of-forgetuser .ant-modal-content .ant-modal-confirm-btns .ant-btn:first-child {
  color: #00a6ff;
}
.confirm-modal-of-public .ant-modal-content .ant-modal-confirm-btns .ant-btn .whiteText,
.confirm-modal-of-forgetuser .ant-modal-content .ant-modal-confirm-btns .ant-btn .whiteText {
  color: #fff;
}
.confirm-modal-of-public .ant-modal-content .WithdrawKyc .WithdrawKycIcon,
.confirm-modal-of-forgetuser .ant-modal-content .WithdrawKyc .WithdrawKycIcon {
  width: 60px;
  height: 60px;
  background-image: url("/vn/img/icons/icon-warn.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  margin: 0 auto 15px;
}
.confirm-modal-of-public.oneButton .ant-modal-confirm-btns .ant-btn,
.confirm-modal-of-forgetuser.oneButton .ant-modal-confirm-btns .ant-btn {
  width: 86% !important;
}
.confirm-modal-of-public.elementTextLeft .ant-modal-confirm-body .ant-modal-confirm-content,
.confirm-modal-of-forgetuser.elementTextLeft .ant-modal-confirm-body .ant-modal-confirm-content {
  text-align: left;
}
.confirm-modal-of-public.dont-show-close-button .ant-modal-close,
.confirm-modal-of-forgetuser.dont-show-close-button .ant-modal-close {
  display: none;
}
.confirm-modal-of-public.first-btn-color .ant-modal-confirm-btns .ant-btn:first-child,
.confirm-modal-of-forgetuser.first-btn-color .ant-modal-confirm-btns .ant-btn:first-child {
  color: #00a6ff;
}
.confirm-modal-of-public.withdrawalOTPModal .divCircleList,
.confirm-modal-of-forgetuser.withdrawalOTPModal .divCircleList {
  display: flex;
  align-items: center;
}
.confirm-modal-of-public.withdrawalOTPModal .divCircle,
.confirm-modal-of-forgetuser.withdrawalOTPModal .divCircle {
  width: 4px;
  height: 4px;
  background-color: #000;
  border-radius: 50%;
  margin: 0 5px;
}
.confirm-modal-of-public.withdrawalOTPModal .ant-btn:first-child,
.confirm-modal-of-forgetuser.withdrawalOTPModal .ant-btn:first-child {
  color: #00a6ff;
}
.confirm-modal-of-public.goDeposit .ant-btn:first-child,
.confirm-modal-of-forgetuser.goDeposit .ant-btn:first-child {
  color: #00a6ff;
}
.keepingFeeModal.hideCancelBtnWithdraw .ant-modal-content .ant-modal-confirm-btns .ant-btn:first-child {
  display: none !important;
}
.keepingFeeModal.hideCancelBtnWithdraw .ant-modal-content .ant-modal-confirm-btns .ant-btn:nth-child(2) {
  width: 80% !important;
}
.maintenance-modal .ant-modal-confirm-btns .ant-btn-primary {
  color: #fff !important;
}
.wallet-deposit-warning.confirm-modal-of-public .ant-modal-content .ant-modal-confirm-body .ant-modal-confirm-content {
  margin-bottom: 0;
  padding: 0 25px;
}
.confirm-modal-of-public.okbtn .ant-modal-content .ant-modal-confirm-btns .ant-btn:first-child,
.confirm-modal-of-forgetuser.okbtn .ant-modal-content .ant-modal-confirm-btns .ant-btn:first-child {
  color: #fff !important;
  /* Overrides the previous rule */
}
.ReferModal .ant-modal-title {
  text-align: center;
}
.ReferModal .RecommendedGames {
  text-align: left;
  padding-top: 20px;
  font-weight: bold;
}
.ReferModal .RecommendedGames h4 {
  text-align: left;
  padding-top: 20px;
}
.large-feedback-status-modal.ant-modal-confirm .ant-modal-body {
  padding: 0 24px 24px;
}
.large-feedback-status-modal .anticon {
  display: none;
}
.large-feedback-status-modal .ant-modal-confirm-content img {
  width: 60px;
  height: 60px;
}
.large-feedback-status-modal .ant-modal-confirm-content .note {
  padding: 0 20px;
}
.large-feedback-status-modal .ant-modal-confirm-btns {
  display: flex;
}
.ant-modal-content {
  box-shadow: unset;
}
.SecurityAnnouncement .ant-modal-content .ant-modal-body {
  padding: 30px;
  background-color: white !important;
}
.SecurityAnnouncement .ant-modal-content .ant-modal-body .otp-modal-banner {
  margin-left: -30px;
}
.SecurityAnnouncement .ant-modal-content .ant-modal-body .otp-modal-description {
  margin: 30px 0 20px 0;
  padding: 0;
  color: #6c6c6c;
}
.StepsBox .ant-steps-item-wait .ant-steps-item-icon {
  border-color: #ccc !important;
}
.StepsBox .ant-steps-item-wait .ant-steps-item-icon > .ant-steps-icon {
  color: #ccc !important;
}
.loginOTPModal .ant-modal-content .ant-modal-header .ant-modal-title {
  text-align: center;
}
.loginOTPModal .ant-modal-content .ant-modal-body {
  padding: 22px 30px 30px 30px;
  background-color: #efeff4;
  border-radius: 0 0 20px 20px;
}
.loginOTPModal .ant-modal-content .ant-modal-body .otp-modal-description {
  padding: 20px;
  margin: 0 0 20px 0;
  color: #000;
}
.loginOTPModal .ant-modal-content .ant-modal-body .otp-modal-description .otp-modal-title {
  font-size: 14px;
  text-align: left;
}
.loginOTPModal .ant-modal-content .ant-modal-body .otp-modal-description .ant-btn.otpBtn {
  height: 40px;
  margin: 16px 0 0 0;
}
.loginOTPModal .ant-modal-content .ant-modal-body .OtpList {
  justify-content: center;
  gap: 16px;
}
.loginOTPModal .ant-modal-content .ant-modal-body .OtpList .otpBox {
  width: 100%;
  padding: 20px 16px;
}
.loginOTPModal .ant-modal-content .ant-modal-body .OtpList .otpBox .ant-btn-primary {
  width: 120px;
  height: 54px;
  line-height: 22px;
  font-weight: normal;
  white-space: normal;
  padding: 5px;
}
.loginOTPModal .ant-modal-content .ant-modal-body .OtpList .otpBox .one-button {
  width: auto;
  height: 44px;
}
.loginOTPModal .ant-modal-content .ant-modal-body .ant-btn-background-ghost {
  height: 40px;
}
.StepsBox {
  width: 200px;
  margin: 0 auto;
  padding-bottom: 20px;
}
.forgetPwdModal .ant-modal-content .ant-modal-body .ant-modal-confirm-content,
.confirm-modal-of-forgetuser .ant-modal-content .ant-modal-body .ant-modal-confirm-content {
  padding: 0 29px;
}
.forgetPwdModal .ant-modal-content .ant-modal-body .ant-modal-confirm-title,
.confirm-modal-of-forgetuser .ant-modal-content .ant-modal-body .ant-modal-confirm-title {
  text-align: center;
}
.forgetPwdModal .ant-modal-content .ant-modal-body .ant-modal-confirm-btns,
.confirm-modal-of-forgetuser .ant-modal-content .ant-modal-body .ant-modal-confirm-btns {
  width: 80%;
  height: 40px;
  margin: 0 auto 20px;
  float: inherit;
}
.forgetPwdModal .ant-modal-content .ant-modal-body .ant-modal-confirm-btns .ant-btn,
.confirm-modal-of-forgetuser .ant-modal-content .ant-modal-body .ant-modal-confirm-btns .ant-btn {
  width: 100%;
  height: 40px;
  color: #fff;
}
.modal-otpVerification .ant-modal-content .ant-modal-body .WithdrawalVerification .verificationSubmitBtn .ant-btn {
  width: 48%;
}
.modal-otpVerification .ant-modal-content .ant-modal-body .WithdrawalVerification .verificationSubmitBtn .ant-btn:first-child {
  margin-right: 4%;
}
.modal-otpVerification .ant-modal-content .ant-modal-body .ant-form.ant-form-horizontal .ant-form-item-control.has-error {
  display: flex;
  flex-direction: column;
}
.modal-otpVerification .ant-modal-content .ant-modal-body .ant-form.ant-form-horizontal .ant-form-item-control.has-error .ant-form-explain {
  order: 1;
  margin-top: 3px;
}
.modal-otpVerification .ant-modal-content .ant-modal-body .verification-form-wrap .otp-phone-verification-Item .ant-col-7 .ant-input.tlc-input-disabled[disabled],
.modal-otpVerification .ant-modal-content .ant-modal-body .verification-form-wrap .otp-phone-verification-Item .ant-col-17 .ant-input.tlc-input-disabled[disabled] {
  color: #222;
  font-weight: 700;
}
.modal-otpVerification .ant-modal-content .ant-modal-body .verification-form-wrap .otp-email-verification-Item .ant-input.tlc-input-disabled[disabled] {
  color: #222;
  font-weight: 700;
}
.modal-otpVerification .ant-modal-content .ant-modal-body .verification-form-wrap .ant-form-item .ant-form-item-control-wrapper .has-error .ant-form-item-children .ant-input-wrapper .ant-input {
  border-right: 0px;
}
.modal-otpVerification .ant-modal-content .ant-modal-body .verification-form-wrap .ant-form-item .ant-form-item-control-wrapper .has-error .ant-form-explain {
  margin-top: 8px;
  font-weight: bold;
  font-size: 13px;
}
.modal-otpVerification .ant-modal-content .ant-modal-body .verification-form-wrap .otp-phone-verifyCode-Item .ant-form-item-label label::before {
  content: "";
}
.modal-otpVerification .ant-modal-content .ant-modal-body .verification-form-wrap .otp-phone-verifyCode-Item .ant-form-item-control-wrapper {
  padding-bottom: 15px;
}
.modal-otpVerification .ant-modal-content .ant-modal-body .verification-form-wrap .otp-phone-verifyCode-Item .ant-form-item-control-wrapper .has-error .ant-form-item-children .ant-input-wrapper .ant-input {
  border-right: 0px;
}
.modal-otpVerification .ant-modal-content .ant-modal-body .verification-form-wrap .otp-phone-verifyCode-Item .ant-form-item-control-wrapper .has-error .ant-form-explain {
  margin-top: 8px;
  font-weight: bold;
  font-size: 13px;
}
.modal-otpVerification .ant-modal-content .ant-modal-body .verification-form-wrap .change-loginOTP-method span {
  cursor: pointer;
  text-decoration: underline;
}
.modal-otpVerification .ant-modal-content .ant-modal-body .verification-form-wrap .otp-btn-wrap .changeVerify:disabled {
  border-color: #ccc;
  color: #ccc;
  background-color: #fff;
}
.loadingModal {
  width: 100vw;
  height: 100vh;
  position: fixed;
  z-index: 10000;
  display: flex;
  top: 0;
  left: 0;
  background-color: #00000011;
}
.loadingModal .loading {
  margin: auto;
  vertical-align: middle;
  width: 250px;
  background-color: #000000cc;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 25px;
}
.loadingModal .loading p {
  font-size: 16px;
  color: #fff;
  text-align: center;
}
.confirm-modal-of-public.oneButton.resubmit-modal .ant-modal-content .ant-modal-confirm-btns .ant-btn.ant-btn-primary:first-child {
  color: #fff !important;
}
@use autoprefixer;
.sponsor-wrap .ant-col {
  transition: opacity 0.3s;
  cursor: pointer;
}
.sponsor-wrap .ant-col:hover {
  opacity: 0.8;
}
.sponsor-wrap .ant-col:hover::before {
  opacity: 1;
}
.sponsor-wrap .sponsor {
  border-radius: 15px;
  overflow: hidden;
}
.sponsor-wrap img {
  width: 100%;
}
.ant-tabs.ant-tabs-card.home-promotion-wrap {
  overflow: visible;
}
.ant-tabs.ant-tabs-card.home-promotion-wrap._2 .ant-tabs-nav-wrap {
  justify-content: flex-start !important;
}
.ant-tabs.ant-tabs-card.home-promotion-wrap._2 .ant-tabs-nav-container {
  height: 120px;
}
.ant-tabs.ant-tabs-card.home-promotion-wrap._2 .ant-tabs-nav-container .ant-tabs-tab {
  height: 120px;
}
.ant-tabs.ant-tabs-card.home-promotion-wrap .ant-tabs-bar {
  height: 100px;
  border-width: 0;
}
.ant-tabs.ant-tabs-card.home-promotion-wrap .ant-tabs-nav-container {
  height: 100px;
  padding: 0;
  font-size: 1.5em;
  overflow: visible;
}
.ant-tabs.ant-tabs-card.home-promotion-wrap .ant-tabs-nav-container .ant-tabs-tab-prev.ant-tabs-tab-arrow-show,
.ant-tabs.ant-tabs-card.home-promotion-wrap .ant-tabs-nav-container .ant-tabs-tab-next.ant-tabs-tab-arrow-show {
  top: -2.2em;
  height: 40px;
}
.ant-tabs.ant-tabs-card.home-promotion-wrap .ant-tabs-nav-container .ant-tabs-tab-prev.ant-tabs-tab-arrow-show.ant-tabs-tab-prev,
.ant-tabs.ant-tabs-card.home-promotion-wrap .ant-tabs-nav-container .ant-tabs-tab-next.ant-tabs-tab-arrow-show.ant-tabs-tab-prev {
  left: 94%;
}
.ant-tabs.ant-tabs-card.home-promotion-wrap .ant-tabs-nav-container .ant-tabs-tab-prev.ant-tabs-tab-arrow-show .ant-tabs-tab-next-icon,
.ant-tabs.ant-tabs-card.home-promotion-wrap .ant-tabs-nav-container .ant-tabs-tab-next.ant-tabs-tab-arrow-show .ant-tabs-tab-next-icon,
.ant-tabs.ant-tabs-card.home-promotion-wrap .ant-tabs-nav-container .ant-tabs-tab-prev.ant-tabs-tab-arrow-show .ant-tabs-tab-prev-icon,
.ant-tabs.ant-tabs-card.home-promotion-wrap .ant-tabs-nav-container .ant-tabs-tab-next.ant-tabs-tab-arrow-show .ant-tabs-tab-prev-icon {
  width: 22px;
  height: 22px;
  border: 2px solid #666;
  color: #666;
  border-radius: 50%;
  line-height: 16px;
  font-weight: bold;
}
.ant-tabs.ant-tabs-card.home-promotion-wrap .ant-tabs-nav-container .ant-tabs-tab-prev.ant-tabs-tab-arrow-show .ant-tabs-tab-next-icon .ant-tabs-tab-prev-icon-target,
.ant-tabs.ant-tabs-card.home-promotion-wrap .ant-tabs-nav-container .ant-tabs-tab-next.ant-tabs-tab-arrow-show .ant-tabs-tab-next-icon .ant-tabs-tab-prev-icon-target,
.ant-tabs.ant-tabs-card.home-promotion-wrap .ant-tabs-nav-container .ant-tabs-tab-prev.ant-tabs-tab-arrow-show .ant-tabs-tab-prev-icon .ant-tabs-tab-prev-icon-target,
.ant-tabs.ant-tabs-card.home-promotion-wrap .ant-tabs-nav-container .ant-tabs-tab-next.ant-tabs-tab-arrow-show .ant-tabs-tab-prev-icon .ant-tabs-tab-prev-icon-target,
.ant-tabs.ant-tabs-card.home-promotion-wrap .ant-tabs-nav-container .ant-tabs-tab-prev.ant-tabs-tab-arrow-show .ant-tabs-tab-next-icon .ant-tabs-tab-next-icon-target,
.ant-tabs.ant-tabs-card.home-promotion-wrap .ant-tabs-nav-container .ant-tabs-tab-next.ant-tabs-tab-arrow-show .ant-tabs-tab-next-icon .ant-tabs-tab-next-icon-target,
.ant-tabs.ant-tabs-card.home-promotion-wrap .ant-tabs-nav-container .ant-tabs-tab-prev.ant-tabs-tab-arrow-show .ant-tabs-tab-prev-icon .ant-tabs-tab-next-icon-target,
.ant-tabs.ant-tabs-card.home-promotion-wrap .ant-tabs-nav-container .ant-tabs-tab-next.ant-tabs-tab-arrow-show .ant-tabs-tab-prev-icon .ant-tabs-tab-next-icon-target {
  font-size: 14px;
}
.ant-tabs.ant-tabs-card.home-promotion-wrap .ant-tabs-nav-container .ant-tabs-tab-prev.ant-tabs-tab-arrow-show:not(.ant-tabs-tab-btn-disabled) .ant-tabs-tab-next-icon:hover,
.ant-tabs.ant-tabs-card.home-promotion-wrap .ant-tabs-nav-container .ant-tabs-tab-next.ant-tabs-tab-arrow-show:not(.ant-tabs-tab-btn-disabled) .ant-tabs-tab-next-icon:hover,
.ant-tabs.ant-tabs-card.home-promotion-wrap .ant-tabs-nav-container .ant-tabs-tab-prev.ant-tabs-tab-arrow-show:not(.ant-tabs-tab-btn-disabled) .ant-tabs-tab-prev-icon:hover,
.ant-tabs.ant-tabs-card.home-promotion-wrap .ant-tabs-nav-container .ant-tabs-tab-next.ant-tabs-tab-arrow-show:not(.ant-tabs-tab-btn-disabled) .ant-tabs-tab-prev-icon:hover {
  border-color: #111;
  color: #111;
}
.ant-tabs.ant-tabs-card.home-promotion-wrap .ant-tabs-nav-container .ant-tabs-tab {
  height: 100px;
  background: transparent;
  border: 0;
  padding: 0;
  transition: none;
}
.ant-tabs.ant-tabs-card.home-promotion-wrap .ant-tabs-nav-container .ant-tabs-tab .home-sponsor {
  border-radius: 15px;
  overflow: hidden;
  margin-right: 20px;
}
.ant-tabs.ant-tabs-card.home-promotion-wrap .ant-tabs-nav-container .ant-tabs-tab .home-sponsor img {
  height: 119px;
  max-width: 540px;
}
.ant-tabs.ant-tabs-card.home-promotion-wrap .ant-tabs-nav-container .ant-tabs-tab:last-child .home-sponsor,
.ant-tabs.ant-tabs-card.home-promotion-wrap .ant-tabs-nav-container .ant-tabs-tab:last-child .home-promotion {
  margin-right: 0;
}
.ant-tabs.ant-tabs-card.home-promotion-wrap .ant-tabs-nav-scroll {
  padding-top: 4px;
}
.ant-tabs.ant-tabs-card.home-promotion-wrap.sponsor .ant-tabs-bar,
.ant-tabs.ant-tabs-card.home-promotion-wrap.sponsor .ant-tabs-nav-container,
.ant-tabs.ant-tabs-card.home-promotion-wrap.sponsor .ant-tabs-nav-container .ant-tabs-tab {
  height: 120px;
}
.home-split-page-btn-wrap {
  position: absolute;
  width: 99%;
  top: 0;
  height: 30px;
}
.home-split-page-btn-wrap .ant-tabs-tab-prev.ant-tabs-tab-arrow-show,
.home-split-page-btn-wrap .ant-tabs-tab-next.ant-tabs-tab-arrow-show {
  position: absolute;
  top: 0;
  height: 30px;
}
.home-split-page-btn-wrap .ant-tabs-tab-prev.ant-tabs-tab-arrow-show.ant-tabs-tab-prev,
.home-split-page-btn-wrap .ant-tabs-tab-next.ant-tabs-tab-arrow-show.ant-tabs-tab-prev {
  left: 94%;
}
.home-split-page-btn-wrap .ant-tabs-tab-prev.ant-tabs-tab-arrow-show .ant-tabs-tab-next-icon,
.home-split-page-btn-wrap .ant-tabs-tab-next.ant-tabs-tab-arrow-show .ant-tabs-tab-next-icon,
.home-split-page-btn-wrap .ant-tabs-tab-prev.ant-tabs-tab-arrow-show .ant-tabs-tab-prev-icon,
.home-split-page-btn-wrap .ant-tabs-tab-next.ant-tabs-tab-arrow-show .ant-tabs-tab-prev-icon {
  width: 22px;
  height: 22px;
  border: 2px solid #666;
  color: #666;
  border-radius: 50%;
  line-height: 16px;
  font-weight: bold;
}
.home-split-page-btn-wrap .ant-tabs-tab-prev.ant-tabs-tab-arrow-show .ant-tabs-tab-next-icon .ant-tabs-tab-prev-icon-target,
.home-split-page-btn-wrap .ant-tabs-tab-next.ant-tabs-tab-arrow-show .ant-tabs-tab-next-icon .ant-tabs-tab-prev-icon-target,
.home-split-page-btn-wrap .ant-tabs-tab-prev.ant-tabs-tab-arrow-show .ant-tabs-tab-prev-icon .ant-tabs-tab-prev-icon-target,
.home-split-page-btn-wrap .ant-tabs-tab-next.ant-tabs-tab-arrow-show .ant-tabs-tab-prev-icon .ant-tabs-tab-prev-icon-target,
.home-split-page-btn-wrap .ant-tabs-tab-prev.ant-tabs-tab-arrow-show .ant-tabs-tab-next-icon .ant-tabs-tab-next-icon-target,
.home-split-page-btn-wrap .ant-tabs-tab-next.ant-tabs-tab-arrow-show .ant-tabs-tab-next-icon .ant-tabs-tab-next-icon-target,
.home-split-page-btn-wrap .ant-tabs-tab-prev.ant-tabs-tab-arrow-show .ant-tabs-tab-prev-icon .ant-tabs-tab-next-icon-target,
.home-split-page-btn-wrap .ant-tabs-tab-next.ant-tabs-tab-arrow-show .ant-tabs-tab-prev-icon .ant-tabs-tab-next-icon-target {
  font-size: 14px;
}
.home-split-page-btn-wrap .ant-tabs-tab-prev.ant-tabs-tab-arrow-show:not(.ant-tabs-tab-btn-disabled) .ant-tabs-tab-next-icon:hover,
.home-split-page-btn-wrap .ant-tabs-tab-next.ant-tabs-tab-arrow-show:not(.ant-tabs-tab-btn-disabled) .ant-tabs-tab-next-icon:hover,
.home-split-page-btn-wrap .ant-tabs-tab-prev.ant-tabs-tab-arrow-show:not(.ant-tabs-tab-btn-disabled) .ant-tabs-tab-prev-icon:hover,
.home-split-page-btn-wrap .ant-tabs-tab-next.ant-tabs-tab-arrow-show:not(.ant-tabs-tab-btn-disabled) .ant-tabs-tab-prev-icon:hover {
  border-color: #111;
  color: #111;
}
.ant-tabs.ant-tabs-line.home-split-page-promotion-wrap {
  padding-top: 4px;
  margin-top: -4px;
}
.ant-tabs.ant-tabs-line.home-split-page-promotion-wrap .ant-tabs-bar {
  display: none;
}
.ant-tabs.ant-tabs-line.home-split-page-promotion-wrap .ant-tabs-tabpane {
  white-space: nowrap;
}
.ant-tabs.ant-tabs-line.home-split-page-promotion-wrap .home-promotion {
  width: 480px;
  display: inline-block;
  border-radius: 50px;
  overflow: hidden;
  margin-right: 20px;
  cursor: pointer;
}
.ant-tabs.ant-tabs-line.home-split-page-promotion-wrap .home-promotion img {
  height: 100px;
}
/* 健康博彩 从小同开始 */
h1.title {
  margin: 0;
  font-size: 32px;
  font-weight: bold;
  line-height: 44px;
  padding-left: 0px;
  color: #222;
}
.app-qrcode-wrap {
  background-color: #00a6ff;
  color: #fff;
  height: 240px;
  margin-top: 162px !important;
  margin-bottom: 102px !important;
  font-size: 12px;
  text-align: center;
}
.app-qrcode-wrap .copy-icon {
  padding-left: 10px;
  padding-bottom: 5px;
}
.app-qrcode-wrap .common-distance {
  padding: 0;
}
.app-qrcode-wrap .homeinstallurl {
  cursor: pointer;
  white-space: normal;
  word-break: break-all;
  width: 322px;
  overflow: auto;
}
.app-qrcode-wrap .app-picture {
  width: 100%;
  height: 240px;
  background-image: url("/cn/img/home/part/app-download-3.png");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 100%;
}
.app-qrcode-wrap .app-picture .AppDownloadSection {
  position: absolute;
  top: -78px;
}
.app-qrcode-wrap .app-picture img {
  margin-top: -18%;
}
.app-qrcode-wrap .qrcode-picture {
  margin-top: 25px;
  height: 175px;
  width: 175px;
  background-repeat: no-repeat;
  position: relative;
  background-size: cover;
}
.app-qrcode-wrap .qrcode-picture canvas {
  border: 15px solid #fff;
  margin-left: 2px;
  margin-top: 2px;
  border-radius: 20px;
}
.app-qrcode-wrap .qrcode-picture > img {
  width: 166px;
  height: 166px;
  line-height: 166px;
  border: 1px solid #1d1d1d;
}
.app-qrcode-wrap .qrcode-picture > p {
  font-size: 12px;
  position: absolute;
  left: 56px;
  bottom: 0;
  margin-bottom: 0.28em;
}
.app-qrcode-wrap .qrcode-picture .text2 {
  left: 32px;
}
.app-qrcode-wrap .qrcode h1 {
  position: absolute;
  top: -54px;
  left: 0px;
}
.app-qrcode-wrap .qrcode .qrcode-article-wrap {
  line-height: 1.8;
  text-align: left;
}
.app-qrcode-wrap .qrcode .qrcode-article-wrap h4 {
  color: #fff;
  margin: 0;
}
.app-qrcode-wrap .qrcode .qrcode-article-wrap p {
  font-size: 12px;
}
.app-qrcode-wrap .qrcode .qrcode-article-wrap .app-down-icons {
  display: block;
  width: 70px;
  height: 30px;
  background-position: -51px -81px;
  background-repeat: no-repeat;
  margin-bottom: 10px;
}
.app-qrcode-wrap .qrcode .qrcode-article-wrap .qrcode-article {
  color: #ffffff;
  padding: 14px 14px 14px 0px;
  line-height: 20px;
}
.app-qrcode-wrap .qrcode .qrcode-article-wrap > div {
  color: #ffee00;
  font-size: 16px;
  text-decoration: underline;
  line-height: 1.2;
  overflow: hidden;
  width: 100%;
}
.app-qrcode-wrap .qrcode .qrcode-article-wrap > div .anticon {
  margin-left: 6px;
}
/* 8号商城 */
.eight-shoping {
  padding-top: 20px;
  margin-bottom: 38px;
  text-align: center;
}
.eight-shoping h1 {
  margin-top: 70px;
  margin-bottom: 15px;
}
.eight-shoping .shop-brief {
  padding-left: 40px;
  padding-right: 40px;
  padding-bottom: 20px;
  text-align: left;
}
.eight-shoping .eight-shop-list a {
  margin-bottom: 15px;
  display: inline-block;
  border-radius: 10px;
  box-shadow: 4px 6px 4px #ddd;
  overflow: hidden;
}
#game_banner.tlc-banner-list {
  position: relative;
  height: 150px;
  line-height: 150px;
  top: 0;
  left: 0;
}
#game_banner .tlc-banner-item {
  display: flex;
  width: 500px;
  height: 161px;
  line-height: inherit;
  text-align: center;
  color: #fff;
  border-radius: 10px;
  box-sizing: border-box;
  color: transparent;
  letter-spacing: -9999px;
  overflow: hidden;
}
#game_banner .tlc-banner-item .img-left {
  width: 30%;
  background-size: 400% 100%;
  animation: ant-skeleton-loading 1.4s ease infinite;
}
#game_banner .tlc-banner-item .txt-right h3 {
  font-weight: bold;
}
#game_banner .tlc-banner-item .txt-right {
  align-items: flex-start;
  padding: 15px;
  justify-content: flex-start;
  line-height: 1;
  letter-spacing: 0;
  display: flex;
  flex-direction: column;
  width: 70%;
  color: #000;
}
#game_banner .tlc-banner-item .txt-end {
  font-size: 25px;
  padding: 30px 5px;
  letter-spacing: 1px;
}
#game_banner .tlc-banner-item img {
  height: 100%;
  width: 100%;
  vertical-align: top;
  border-radius: 20px;
}
#game_banner .tlc-carousel-controller {
  position: absolute;
  height: inherit;
  top: 0;
  left: 0;
  right: 0;
  margin: auto;
  z-index: 5;
}
#game_banner .slide-arrow {
  width: 40px;
  height: 80px;
  position: absolute;
  top: 25%;
  outline: none;
  border-width: 0;
  border-radius: 4px;
  cursor: pointer;
  background-color: #ffffff40;
  transition: background-color 0.3s;
}
#game_banner .slide-arrow:hover {
  background-color: #00000080;
}
#game_banner .slide-prev {
  left: -40px;
  background-image: url("/vn/img/home/part/arrow-left.svg");
  opacity: 0.8;
}
#game_banner .slide-next {
  right: -40px;
  background-image: url("/vn/img/home/part/arrow-right.svg");
  opacity: 0.8;
}
#game_banner .slide-list {
  width: 100%;
  height: 4px;
  position: absolute;
  text-align: center;
  bottom: 20px;
}
#game_banner .slide-item {
  display: inline-block;
  margin: 0 5px;
  width: 50px;
  height: inherit;
  background-color: #fff;
  opacity: 0.3;
  transition: opacity 0.3s;
  text-indent: 99999px;
}
#game_banner .slide-item.slide-item-active {
  opacity: 1;
}
/* 合作伙伴 */
.cooperate-wrap {
  padding-top: 20px;
}
.cooperate-wrap h2 {
  padding-left: 230px;
  font-size: 32px;
}
.cooperate-wrap .cooperate-content {
  height: 262px;
  line-height: 238px;
  background-repeat: no-repeat;
  background-position: center 0;
  text-align: center;
}
.cooperate-wrap .cooperate-content._1 {
  background-image: url("/vn/img/home/part/NUFC.png");
}
.cooperate-wrap .cooperate-content._2 {
  background-image: url("/vn/img/home/part/SPURS.png");
}
.cooperate-wrap .cooperate-content p {
  display: inline-block;
  text-align: center;
  vertical-align: bottom;
  line-height: 26px;
  font-size: 12px;
}
.cooperate-wrap .cooperate-content p > span {
  display: block;
}
.cooperate-wrap .cooperate-content .cooperate-name {
  color: #00a6ff;
  font-size: 16px;
}
#t_banner_wrapper.tlc-banner-list {
  position: relative;
  height: 400px;
  line-height: 400px;
  top: 0;
  left: 0;
}
#t_banner_wrapper .tlc-banner-item {
  width: 1120px;
  height: 400px;
  line-height: inherit;
  text-align: center;
  color: #fff;
  border-radius: 10px;
  box-sizing: border-box;
  color: transparent;
  overflow: hidden;
  background-color: #ebebeb;
}
#t_banner_wrapper .tlc-banner-item.pointer {
  cursor: pointer;
}
#t_banner_wrapper .tlc-banner-item img {
  height: 100%;
  vertical-align: top;
  border-radius: 10px;
}
#t_banner_wrapper .tlc-carousel-controller {
  position: absolute;
  height: 0;
  top: 0;
  left: 0;
  right: 0;
  margin: auto;
  z-index: 5;
}
#t_banner_wrapper .slide-arrow {
  width: 40px;
  height: 80px;
  position: absolute;
  top: 152px;
  outline: none;
  border-width: 0;
  border-radius: 4px;
  cursor: pointer;
  background-color: #00000040;
  transition: background-color 0.3s;
}
#t_banner_wrapper .slide-arrow:hover {
  background-color: #00000080;
}
#t_banner_wrapper .slide-prev {
  left: 60px;
  background-image: url("/vn/img/home/part/arrow-to-left.png");
}
#t_banner_wrapper .slide-next {
  right: 60px;
  background-image: url("/vn/img/home/part/arrow-to-right.png");
}
#t_banner_wrapper .slide-list {
  width: 93%;
  height: 4px;
  position: absolute;
  text-align: center;
  top: 370px;
  white-space: nowrap;
  overflow: hidden;
}
#t_banner_wrapper .slide-item {
  display: inline-block;
  margin: 0 5px;
  width: 50px;
  height: inherit;
  background-color: #fff;
  opacity: 0.3;
  transition: opacity 0.3s;
  text-indent: -999999px;
}
#t_banner_wrapper .slide-item.slide-item-active {
  opacity: 1;
}
.Right-Login-Action {
  display: flex;
  align-items: center;
}
.Right-Login-Action .BtnList {
  display: flex;
  justify-content: space-between;
}
.Right-Login-Action .BtnList div {
  cursor: pointer;
}
.App-Qrcode-List {
  display: flex;
  padding-top: 10px;
}
.App-Qrcode-List .App-Name {
  color: white;
  text-align: center;
}
#register-success {
  background: #daffe3 0% 0% no-repeat padding-box !important;
  border-radius: 64px !important;
  box-shadow: 0px 3px 6px #00000029;
  height: 56px;
  font: normal normal normal 15px/24px Roboto;
  letter-spacing: 0px;
  color: #222222;
  opacity: 1;
}
#register-success .Toastify__toast-body .Toastify__zoom-enter {
  display: none;
}
#register-success .Toastify__toast-body .custom-toast {
  display: flex;
  justify-content: center;
  align-items: center;
}
#register-success .Toastify__toast-body .custom-toast svg {
  width: 24px;
  height: 24px;
}
#register-success .Toastify__toast-body span {
  margin-left: 15px;
  font: normal normal normal 16px/21px Roboto;
  letter-spacing: 0px;
  color: #0ccc3c;
  opacity: 1;
  white-space: nowrap;
}
#login-success {
  background: #daffe3 0% 0% no-repeat padding-box !important;
  border-radius: 64px !important;
  box-shadow: 0px 3px 6px #00000029;
  height: 56px;
  font: normal normal normal 15px/24px Roboto;
  letter-spacing: 0px;
  color: #222222;
  opacity: 1;
  margin: 0 auto;
}
#login-success .Toastify__toast-body .Toastify__zoom-enter {
  display: none;
}
#login-success .Toastify__toast-body .custom-toast {
  display: flex;
  justify-content: center;
  align-items: center;
}
#login-success .Toastify__toast-body .custom-toast svg {
  width: 24px;
  height: 24px;
}
#login-success .Toastify__toast-body span {
  margin-left: 15px;
  font: normal normal normal 16px/21px Roboto;
  letter-spacing: 0px;
  color: #0ccc3c;
  opacity: 1;
  white-space: nowrap;
}
.promotionBanner .ant-tabs-tab-btn-disabled {
  display: none;
}
.BannerBox span {
  display: block !important;
}
.GameReferImg .GameName {
  display: none !important;
}
.bankBusinessTime {
  z-index: 3;
}
.bankBusinessTime .default {
  width: 80px !important;
  height: 80px;
  position: fixed;
  right: 0;
  top: 190px;
  border-radius: 16px 0px 0px 16px;
  background-color: #00a6ff;
  background-image: url("/vn/img/icon/Float-IconBank.svg");
  background-position: center;
  background-repeat: no-repeat;
  cursor: pointer;
  transition: transform 1s ease;
  z-index: 3;
}
.bankBusinessTime .default.moveToLeft {
  transform: translateX(-367px);
  transition: transform 2s ease;
}
.bankBusinessTime .showMore {
  width: 367px !important;
  position: fixed;
  right: -367px;
  top: 150px;
  border-radius: 20px 0px 0px 20px;
  transition: transform 1s ease;
  z-index: 3;
  overflow: auto;
  max-height: 700px;
}
.bankBusinessTime .showMore.moveToLeft {
  transform: translateX(-367px);
  transition: transform 2s ease;
}
.bankBusinessTime .showMore .ant-collapse {
  background-color: #fff;
  border: 0;
  border-radius: 20px;
}
.bankBusinessTime .showMore .ant-collapse .ant-collapse-header {
  color: #222;
}
.bankBusinessTime .showMore .ant-collapse .ant-collapse-header .ant-row {
  line-height: 36px;
}
.bankBusinessTime .showMore .ant-collapse .ant-collapse-header .ant-row .ant-col-2 div {
  display: flex;
  align-items: center;
  height: 36px;
}
.bankBusinessTime .showMore .ant-collapse .ant-collapse-header .ant-row .ant-col-16 {
  font-weight: bold;
}
.bankBusinessTime .showMore .ant-collapse .ant-collapse-header .ant-row .ant-col-6 {
  color: #35C95B;
  font-weight: bold;
}
.bankBusinessTime .showMore .ant-collapse .ant-collapse-header .ant-row .ant-col-6.Offline {
  color: #BCBEC3;
}
.bankBusinessTime .showMore .ant-collapse .ant-collapse-content {
  width: 90%;
  margin: auto;
  border-top: 1px solid #eee;
}
.bankBusinessTime .showMore .ant-collapse .ant-collapse-content .ant-row .ant-col:last-child {
  color: #999;
}
.bankBusinessTime .showMore .ant-collapse .ant-collapse-item {
  border: 0;
}
.bankBusinessTime .showMore .ant-collapse .ant-collapse-item .ant-collapse-arrow svg {
  transform: rotate(-90deg);
}
.im-wrap {
  color: #222;
  box-shadow: 0 0 6px #ebebeb;
  background-color: #fff;
  padding: 18px 0;
  border-radius: 20px;
  margin-bottom: 30px;
}
.im-wrap .invite-friend-banner {
  width: 900px;
  height: 200px;
  margin: auto;
  border-radius: 10px;
  background: linear-gradient(90deg, #f2f2f2 25%, #e6e6e6 37%, #f2f2f2 63%);
  background-size: 400% 100%;
  animation: ant-skeleton-loading 1.4s ease infinite;
  background-position: center;
  background-repeat: no-repeat;
}
.im-wrap .invite-friend-banner.iswebp_false {
  background-image: url("/vn/img/ref/referer-banner.png");
  background-size: contain;
  animation: none;
}
.im-wrap .invite-friend-banner.iswebp_true {
  background-image: url("/vn/img/ref/referer-banner.webp");
  background-size: contain;
  animation: none;
}
.im-wrap .invite-friend-content {
  padding: 30px;
  width: 900px;
  margin: auto;
  overflow: hidden;
}
.im-wrap .invite-friend-content h4 {
  font-weight: bold;
}
.im-wrap .invite-friend-content .invite-title {
  margin-bottom: 18px;
  color: #222;
}
.im-wrap .invite-friend-content .invite-step-wrap {
  width: 370px;
  float: left;
}
.im-wrap .invite-friend-content .invite-step-wrap > .ant-btn {
  margin: 24px 0;
  background-color: #00a6ff;
  border-color: #00a6ff;
}
.im-wrap .invite-friend-content .invite-step-wrap > .ant-btn:hover,
.im-wrap .invite-friend-content .invite-step-wrap > .ant-btn:focus {
  background-color: #45a8ff;
  border-color: #45a8ff;
}
.im-wrap .invite-friend-content .invite-step-wrap .ant-btn-danger[disabled] {
  color: #999 !important;
  background-color: #f0f0f2 !important;
  border: 0 !important;
}
.im-wrap .invite-friend-content .invite-prize-wrap {
  width: 410px;
  float: right;
}
.im-wrap .invite-friend-content .invite-prize-wrap .invite-question .question-list .black-color {
  display: flex;
}
.im-wrap .invite-friend-content .invite-prize-wrap .invite-question .question-list .black-color b:last-child {
  padding-left: 5px;
}
.im-wrap .invite-friend-content .invite-prize-wrap .invite-question .question-list .gray-color {
  display: flex;
}
.im-wrap .invite-friend-content .invite-prize-wrap .invite-question .question-list .gray-color p {
  margin-bottom: 0;
}
.im-wrap .invite-friend-content .invite-prize-wrap .invite-question .question-list .gray-color p:last-child {
  padding-left: 5px;
}
.im-wrap .invite-friend-content .invite-prize-wrap .invite-question .question-list .gray-color .inline {
  border-bottom: 1px solid #00a6ff;
  border-radius: 0;
}
.im-wrap .invite-friend-content .invite-list-table {
  width: 100%;
  text-align: center;
  margin-bottom: 20px;
  border-collapse: separate;
  border-spacing: 0;
}
.im-wrap .invite-friend-content .invite-list-table .head {
  background-color: #00a6ff;
  color: #fff;
}
.im-wrap .invite-friend-content .invite-list-table thead tr {
  height: 40px;
  border-bottom: 1px solid #e4e4e4;
}
.im-wrap .invite-friend-content .invite-list-table thead th:first-child {
  border-radius: 12px 0 0 0;
}
.im-wrap .invite-friend-content .invite-list-table thead th:last-child {
  border-radius: 0 12px 0 0;
}
.im-wrap .invite-friend-content .invite-list-table tbody tr {
  height: 40px;
}
.im-wrap .invite-friend-content .invite-list-table tbody tr td:first-child {
  border-left: 1px solid #e0e0e0;
}
.im-wrap .invite-friend-content .invite-list-table tbody tr td:last-child {
  border-right: 1px solid #e0e0e0;
}
.im-wrap .invite-friend-content .invite-list-table tbody tr td {
  border-bottom: 1px solid #e4e4e4;
}
.im-wrap .invite-friend-content .invite-list-table tbody tr:last-child td:first-child {
  border-radius: 0 0 0 12px;
}
.im-wrap .invite-friend-content .invite-list-table tbody tr:last-child td:last-child {
  border-radius: 0 0 12px 0;
}
.im-wrap .invite-friend-content .prize-example-wrap,
.im-wrap .invite-friend-content .question-list {
  margin-bottom: 20px;
}
.im-wrap .invite-friend-content .prize-example-wrap li,
.im-wrap .invite-friend-content .question-list li {
  margin-bottom: 18px;
}
.im-wrap .invite-friend-content .prize-example-list,
.im-wrap .invite-friend-content .decimal-list {
  padding-left: 20px;
}
.im-wrap .invite-friend-content .prize-example-list > li,
.im-wrap .invite-friend-content .decimal-list > li {
  list-style: decimal;
}
.im-wrap .invite-friend-content .prize-process {
  margin-bottom: 20px;
  border-radius: 10px;
  font-size: 12px;
  border: 1px solid #e0e0e0;
  overflow: hidden;
}
.im-wrap .invite-friend-content .prize-process p > span:nth-child(2) {
  float: right;
}
.im-wrap .invite-friend-content .prize-process .prize-list {
  border-bottom: 1px solid #e0e0e0;
  padding: 36px;
  padding-bottom: 10px;
}
.im-wrap .invite-friend-content .prize-process .prize-list .process-step {
  padding-bottom: 20px;
  padding-left: 20px;
  position: relative;
}
.im-wrap .invite-friend-content .prize-process .prize-list .process-step::before {
  content: "";
  position: absolute;
  width: 1px;
  height: 100%;
  background: #e0e0e0;
  top: 5px;
  left: 4px;
}
.im-wrap .invite-friend-content .prize-process .prize-list .process-step::after {
  content: "";
  display: block;
  border-radius: 50%;
  width: 8px;
  height: 8px;
  border: 1px solid #ccc;
  background-color: #fff;
  position: absolute;
  left: 0;
  top: 5px;
}
.im-wrap .invite-friend-content .prize-process .prize-list .process-step.light-red {
  color: #222;
}
.im-wrap .invite-friend-content .prize-process .prize-list .process-step.light-red::after {
  border: 1px solid #f92d2d;
  background-color: #f92d2d;
}
.im-wrap .invite-friend-content .prize-process .prize-list .process-step.light-blue {
  color: #222;
}
.im-wrap .invite-friend-content .prize-process .prize-list .process-step.light-blue::after {
  border: 1px solid #1c8eff;
  background-color: #1c8eff;
}
.im-wrap .invite-friend-content .prize-process .prize-list .process-step.light-blue .active-black {
  color: #222222;
  font-weight: bold;
}
.im-wrap .invite-friend-content .prize-process .prize-list .process-step.light-blue .active-blue {
  color: #1c8eff;
  font-weight: bold;
}
.im-wrap .invite-friend-content .prize-process .prize-list .process-step:last-child::before {
  background-color: #fff;
}
.im-wrap .invite-friend-content .prize-process .prize-list .process-step > p:nth-child(2) {
  transform: scale(0.9, 0.9) translateX(-18px);
}
.im-wrap .invite-friend-content .prize-process .prize-total {
  padding: 16px 30px;
  font-weight: bold;
}
.im-wrap .invite-friend-content .prize-process .prize-total.light-red {
  color: #222;
}
.im-wrap .invite-friend-content .prize-process .prize-total > p {
  margin: 0;
  font-size: 14px;
}
.im-wrap .refer-href-link {
  height: 40px;
  line-height: 40px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  border: 1px solid #e0e0e0;
  border-radius: 10px;
  padding: 0 10px;
}
.im-wrap .margin-distance {
  margin-bottom: 10px;
}
.im-wrap .prompt-center-box.refer-friend-prompt {
  left: 304px;
  top: 272px;
  right: auto;
  bottom: auto;
  height: 40px;
  line-height: 40px;
  border-radius: 20px;
}
.im-wrap .prompt-center-box.refer-friend-prompt._2 {
  top: 406px;
}
.referFriendLearn .ant-modal-header {
  padding: 20px 0;
}
.referFriendLearn .ant-modal-title {
  text-align: center;
  font-size: 16px;
}
.referFriendLearn .ant-modal-body {
  padding: 20px;
}
.referFriendLearn .ant-modal-body .ant-btn-link {
  font-weight: bold;
}
.referFriendLearn .learn .step1 p {
  width: 87%;
}
.invite-list-step > li {
  width: 100%;
  line-height: 100px;
  margin-bottom: 16px;
  border-radius: 10px;
  border: 1px solid #e0e0e0;
  position: relative;
}
.invite-list-step > li > div {
  display: inline-block;
}
.invite-list-step > li > i.anticon svg {
  font-size: 18px;
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  right: 20px;
  color: #ccc;
}
.invite-list-step > li > i.anticon.success svg {
  color: #52c41a;
}
.invite-list-step > li .invite-step-number {
  width: 80px;
  font-size: 62px;
  color: #00a6ff;
  vertical-align: middle;
  text-align: center;
}
.invite-list-step > li .invite-step-content {
  vertical-align: middle;
  line-height: 1.5;
  max-width: 252px;
  color: #222;
  margin: 10px 0;
}
.invite-list-step > li .invite-step-content .tlc-sprite {
  width: 14px;
  height: 14px;
  display: inline-block;
  vertical-align: -0.2em;
  margin-right: 6px;
  border-radius: 50%;
}
.invite-list-step > li .invite-step-content .tlc-sprite:nth-child(3) {
  margin-left: 10px;
}
.invite-list-step > li .invite-step-content .tlc-sprite.user-email {
  background-color: #cccccc;
  background-position: -185px -135px;
  background-image: url("/vn/img/home/sprite20230330.png");
}
.invite-list-step > li .invite-step-content .tlc-sprite.user-phone {
  background-color: #cccccc;
  background-position: -204px -135px;
  background-image: url("/vn/img/home/sprite20230330.png");
}
.invite-list-step > li .invite-step-content .tlc-sprite.user-email.curr,
.invite-list-step > li .invite-step-content .tlc-sprite.user-phone.curr {
  background-color: #42d200;
}
.invite-list-step > li .invite-step-content > .ant-btn {
  margin-top: 6px;
  border-radius: 5px;
  font-size: 12px;
  background-color: #00a6ff;
  border-color: #00a6ff;
}
.invite-list-step > li .invite-step-content > .ant-btn:hover,
.invite-list-step > li .invite-step-content > .ant-btn:focus {
  background-color: #45a8ff;
  border-color: #45a8ff;
}
.invite-list-step > li .invite-step-content > p {
  color: #666;
  font-size: 12px;
  margin: 0;
}
.invite-list-step > li .ant-btn-danger[disabled] {
  color: #fff;
  background-color: #cccccc;
  border-color: #bfbfbf;
}
.invite-list-step.has-login.learn > li {
  padding-left: 18px;
}
#about .about-content {
  min-height: 900px;
  display: flex;
}
#about .about-content .ant-skeleton-header {
  padding: 10px 20px;
}
#about .about-content .ant-skeleton-header .ant-skeleton-avatar-lg {
  width: 170px;
  height: 500px;
  border-radius: 15px !important;
}
#about .about-content .ant-empty {
  padding-top: 15%;
}
#about .about-content .ant-empty-image {
  height: 150px;
}
#about .about-content .ant-empty-description {
  margin-top: 20px;
  text-indent: 50px;
}
#about .about-content .right {
  flex: 1 1;
  margin: 0 25px;
  border-radius: 20px;
}
#about .about-content .right .box_body {
  height: auto;
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 20px;
  margin-bottom: 50px;
  padding: 5px 15px;
  min-height: 500px;
}
#about .about-content .ant-menu {
  color: #222222;
}
#about .about-content .ant-menu-root {
  width: 200px;
  color: #222222;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 2px #00000029;
  border-radius: 20px;
  overflow: hidden;
}
#about .about-content .ant-menu-submenu-selected {
  color: #00a6ff;
}
#about .about-content .ant-menu-submenu-title:hover {
  color: #00a6ff;
}
#about .about-content .ant-menu-item-selected {
  color: #00a6ff;
}
#about .about-content .ant-menu-item:hover,
#about .about-content .ant-menu-item-active,
#about .about-content .ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open,
#about .about-content .ant-menu-submenu-active,
#about .about-content .ant-menu-submenu-title:hover {
  color: #00a6ff;
}
#about .about-content .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: #e0e0e0;
}
#about .about-content .ant-menu-vertical .ant-menu-item::after,
#about .about-content .ant-menu-vertical-left .ant-menu-item::after,
#about .about-content .ant-menu-vertical-right .ant-menu-item::after,
#about .about-content .ant-menu-inline .ant-menu-item::after {
  border-right: 4px solid #00a6ff;
  left: 0 !important;
  right: auto !important;
}
#about .about-content .ant-menu-submenu-open.ant-menu-submenu-inline > .ant-menu-submenu-title .ant-menu-submenu-arrow::before {
  transform: rotate(45deg) translateX(2px);
  background: #222;
}
#about .about-content .ant-menu-submenu-open.ant-menu-submenu-inline > .ant-menu-submenu-title .ant-menu-submenu-arrow::after {
  background: #222;
  transform: rotate(-45deg) translateX(-2px);
}
#about .about-content .ant-menu-submenu-inline > .ant-menu-submenu-title .ant-menu-submenu-arrow::before {
  transform: rotate(-45deg) translateX(2px);
  background: #222;
}
#about .about-content .ant-menu-submenu-inline > .ant-menu-submenu-title .ant-menu-submenu-arrow::after {
  transform: rotate(45deg) translateX(-2px);
  background: #222;
}
#about .about-content .ant-timeline-item {
  padding-left: 35px;
}
#about .about-content .ant-timeline-item-content {
  top: -15.001px;
}
#about .about-content .ant-divider-horizontal {
  margin-bottom: 14px;
}
#about .about-content .ant-timeline-item-tail {
  border-color: #e32424;
}
#about .about-content .ant-timeline-item-head-custom {
  padding: 0px 1px;
}
#about .about-content .ant-timeline {
  margin-left: 15px;
}
#about .about-content .ant-table {
  margin-bottom: 15px;
}
#about .about-content .ant-table-tbody > tr td:not(:first-child) {
  transition: all 0.3s, height 0s;
  color: red;
}
#about #ID_129 img[alt="571"] {
  width: 780px;
}
#about #ID_128 img {
  margin-bottom: 30px;
}
#about #ID_128 img[alt="group3"] {
  margin-top: 20px;
  width: 414px;
}
#about #tlc_about .Carouselbody {
  position: relative;
  min-height: 270px;
}
#about #tlc_about .Carouselbody img {
  width: 414px;
}
#about #tlc_about .partners,
#about #tlc_about .licenses {
  position: relative;
  display: inline-block;
  margin: 0;
  top: -276px;
  right: -86%;
  cursor: pointer;
}
#about #tlc_about .licenses {
  top: -136px;
}
#about #tlc_about caption {
  caption-side: top;
  color: #000;
  font-size: 1.17em;
  font-weight: bold;
  white-space: pre;
}
#about #tlc_about h2 {
  font-weight: bold;
  border-bottom: 1px solid #f0f0f0;
  padding-bottom: 20px;
  margin-bottom: 30px;
}
#about #tlc_about #e_mail caption {
  padding-left: 28px;
  background-image: url("/vn/img/about/email.svg");
  background-repeat: no-repeat;
  background-position: 0 17px;
}
#about #tlc_about #e_mail phone {
  padding-left: 28px;
  background-image: url("/vn/img/about/phone.svg");
  background-repeat: no-repeat;
  background-position: 0 17px;
}
#about #tlc_about #e_mail tr:last-child {
  color: #999999;
  font-size: 12px;
}
#about #tlc_about #about_us caption,
#about #tlc_about #about_us > p {
  display: none;
}
#about #tlc_about #about_us img {
  width: 890px;
}
#about #tlc_about #partners img {
  width: 100px;
  padding: 25px 0;
  margin: 0 29px;
}
#about #tlc_about #partners,
#about #tlc_about #licenses {
  padding: 25px 50px;
}
#about #tlc_about #partners {
  text-align: center;
}
#about #tlc_about #licenses caption,
#about #tlc_about #slogan caption {
  padding-bottom: 20px;
}
#about #tlc_about #cooperation_partner img {
  width: 649px;
  margin-top: 6px;
}
#about #tlc_about #tlc_member_power,
#about #tlc_about .currency-introduction {
  line-height: 2;
}
#about #tlc_about .currency-introduction table,
#about #tlc_about .help-table table {
  width: 100%;
  border-radius: 8px 8px 0 0;
  border-collapse: separate;
  border-spacing: 0;
}
#about #tlc_about .currency-introduction table table,
#about #tlc_about .help-table table table {
  line-height: 2;
  margin: 10px 0;
  border: 1px solid #f0f0f0;
  text-align: center;
}
#about #tlc_about .currency-introduction table table td,
#about #tlc_about .help-table table table td {
  border-right: 1px solid #f0f0f0;
  border-bottom: 1px solid #f0f0f0;
}
#about #tlc_about .currency-introduction table table td:last-child,
#about #tlc_about .help-table table table td:last-child {
  border-right: none;
}
#about #tlc_about .currency-introduction table table tr:last-child td,
#about #tlc_about .help-table table table tr:last-child td {
  border-bottom: none;
}
#about #tlc_about .currency-introduction table table tr:not(:first-child) td:not(:first-child),
#about #tlc_about .help-table table table tr:not(:first-child) td:not(:first-child) {
  transition: all 0.3s, height 0s;
  color: red;
}
#about #tlc_about .contact-we {
  padding-bottom: 20px;
  border-bottom: 1px solid #f0f0f0;
}
.lesson-box.deposit-ctc .crypto-lesson-wrap {
  position: relative;
  width: 100%;
  height: 324px;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
}
.lesson-box.deposit-ctc .crypto-lesson-wrap.rapid-ls-1 {
  background-image: url("/vn/img/depositlesson/CTC/rapid-ls-1.png");
}
.lesson-box.deposit-ctc .crypto-lesson-wrap.rapid-ls-2 {
  background-image: url("/vn/img/depositlesson/CTC/rapid-ls-2.png");
}
.lesson-box.deposit-ctc .crypto-lesson-wrap.rapid-ls-3 {
  background-image: url("/vn/img/depositlesson/CTC/rapid-ls-3.png");
}
.lesson-box.deposit-ctc .crypto-lesson-wrap.rapid-ls-4 {
  background-image: url("/vn/img/depositlesson/CTC/rapid-ls-4.png");
}
.lesson-box.deposit-ctc .crypto-lesson-wrap.rapid-ls-5 {
  background-image: url("/vn/img/depositlesson/CTC/rapid-ls-5.png");
}
.lesson-box.deposit-ctc .crypto-lesson-wrap.otc-ls-1 {
  background-image: url("/vn/img/depositlesson/CTC/otc-ls-1.png");
}
.lesson-box.deposit-ctc .crypto-lesson-wrap.otc-ls-2 {
  background-image: url("/vn/img/depositlesson/CTC/otc-ls-2.png");
}
.lesson-box.deposit-ctc .crypto-lesson-wrap.otc-ls-3 {
  background-image: url("/vn/img/depositlesson/CTC/otc-ls-3.png");
}
.lesson-box.deposit-ctc .crypto-lesson-wrap.otc-ls-4 {
  background-image: url("/vn/img/depositlesson/CTC/otc-ls-4.png");
}
.lesson-box.deposit-ctc .crypto-lesson-wrap.otc-ls-5 {
  background-image: url("/vn/img/depositlesson/CTC/otc-ls-5.png");
}
.lesson-box.deposit-phc .phc-lesson-wrap {
  position: relative;
  width: 100%;
  height: 324px;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  margin-top: 30px;
}
.lesson-box.deposit-phc .phc-lesson-wrap.rapid-ls-1 {
  background-image: url("/vn/img/depositlesson/PHC/Rectangle1.png");
}
.lesson-box.deposit-phc .phc-lesson-wrap.rapid-ls-2 {
  background-image: url("/vn/img/depositlesson/PHC/Rectangle2.png");
}
.lesson-box.deposit-phc .phc-lesson-wrap.rapid-ls-3 {
  background-image: url("/vn/img/depositlesson/PHC/Rectangle3.png");
}
.lesson-box.deposit-phc .phc-lesson-wrap.rapid-ls-4 {
  background-image: url("/vn/img/depositlesson/PHC/Rectangle4.png");
}
.lesson-box.deposit-phc .phc-lesson-wrap.rapid-ls-5 {
  background-image: url("/vn/img/depositlesson/PHC/Rectangle5.png");
}
.lesson-box .left-next,
.lesson-box .right-next {
  top: 0;
  bottom: 0;
  width: 36px;
  height: 100px;
  margin: auto;
  position: absolute;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  z-index: 1;
}
.lesson-box .left-next img,
.lesson-box .right-next img {
  width: 32px;
  height: 64px;
}
.lesson-box .left-next {
  left: 4px;
}
.lesson-box .right-next {
  right: 4px;
}
.lesson-box .ant-tabs-tab {
  color: #999999;
}
.lesson-box .ant-tabs-nav .ant-tabs-tab-active {
  font-weight: 1000 !important;
  color: #222222 !important;
}
.lesson-box .crypto-lesson-body {
  margin-left: 6rem;
  margin-right: 6rem;
}
.lesson-box .deposit-comment-title {
  text-align: left;
  font-weight: bold;
  font-size: 14px;
  margin-top: 25px;
  margin-bottom: 10px;
  min-height: 16px;
}
.lesson-box .deposit-comment-content {
  text-align: left;
  font-size: 12px;
  min-height: 16px;
  color: #222;
}
.lesson-box .custom-Carousel .slick-dots {
  bottom: -5px !important;
  z-index: 999;
}
.lesson-box .custom-Carousel .slick-dots button {
  margin-right: 5px;
}
.lesson-box .ant-carousel .custom-Carousel .slick-dots li.slick-active {
  background: transparent;
}
.lesson-box .ant-carousel .custom-Carousel .slick-dots li.slick-active button {
  background: #000000bf;
  border-radius: 50%;
  height: 8px;
  width: 8px;
}
.lesson-box .ant-carousel .custom-Carousel .slick-dots li {
  background: transparent;
}
.lesson-box .ant-carousel .custom-Carousel .slick-dots li button {
  background: #00000040;
  border-radius: 50%;
  height: 8px;
  width: 8px;
}
.lesson-box .arrowLeftRight {
  background-color: #00000040;
  padding-top: 10px;
  padding-bottom: 10px;
  border-radius: 5px;
}
.lesson-box .comment-title {
  text-align: center;
  color: #f92d2d;
  font-size: 18px;
  font-weight: 1000;
  padding-top: 2rem;
}
.lesson-box .comment-content {
  text-align: left;
  color: #000000;
  font-weight: 500;
  font-size: 16px;
  margin-top: 3rem;
  margin-bottom: 3rem;
  margin-left: 10rem;
  margin-right: 10rem;
  line-height: 20px;
  text-shadow: 0.2px 0.2px, 0.2px 0.2px, 0.2px 0.2px;
}
.lesson-box .deposit-comment-button,
.lesson-box .deposit-comment-button:active,
.lesson-box .deposit-comment-button:focus {
  background-color: #00a6ff;
  text-align: center;
  color: white;
  border-radius: 8px;
  border: 1px solid #00a6ff;
  font-size: 14px;
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 5px;
  padding-bottom: 5px;
  width: 334px;
  height: 40px;
  margin: 10px auto;
}
.lesson-box .titleHeader {
  background-color: #00a6ff;
  color: white;
}
.account-wrap.message-wrap .ant-tabs-nav {
  font-weight: 500;
  color: #888;
}
.account-wrap.message-wrap .ant-tabs-nav .ant-tabs-tab-active {
  font-weight: bold;
  color: #000;
}
.account-wrap.message-wrap .ant-select-selection-selected-value {
  width: 100%;
  text-align: center;
}
.account-wrap.message-wrap .ant-select-selection--single .ant-select-selection__rendered,
.account-wrap.message-wrap .message-button > .ant-btn {
  font-size: 12px;
}
.message-option.small-option {
  width: 82px;
  box-shadow: none;
  border: 1px solid #ccc;
  border-radius: 5px;
}
.message-option.small-option .ant-select-dropdown-menu {
  padding: 0;
}
.message-option.small-option .ant-select-dropdown-menu-item {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  border-radius: 5px;
  height: 32px;
  font-size: 12px;
  text-align: center;
}
.message-option.small-option .ant-select-dropdown-menu-item.ant-select-dropdown-menu-item-selected {
  background: none;
}
.message-selection .ant-select-selection {
  border-radius: 5px;
}
.message-container .ant-tabs-bar.ant-tabs-top-bar {
  margin-bottom: 20px;
}
.message-container .border-tabs .ant-tabs-nav-wrap {
  justify-content: flex-start;
}
.message-container .border-tabs .ant-tabs-ink-bar {
  height: 3px;
}
.message-container .message-button .ant-select {
  width: 97px;
}
.message-container .message-button .ant-btn {
  width: 155px;
  border-radius: 5px;
  padding: 0;
  margin-right: 1px;
}
.message-container .message-button .ant-select-selection__rendered {
  margin-left: 7px;
  margin-right: 20px;
}
.message-container .message-button .ant-select-arrow {
  right: 7px;
}
.markAllRead.ant-modal-confirm .ant-modal-close {
  display: none;
}
.markAllRead.ant-modal-confirm .ant-modal-content {
  border-radius: 20px;
}
.markAllRead.ant-modal-confirm .ant-modal-content .ant-modal-body {
  padding: 0;
}
.markAllRead.ant-modal-confirm .ant-modal-content .ant-modal-body .ant-modal-confirm-title {
  color: #ffffff;
  background-color: #00a6ff;
  width: 100%;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-top-right-radius: 20px;
  border-top-left-radius: 20px;
}
.markAllRead.ant-modal-confirm .ant-modal-content .ant-modal-body .ant-modal-confirm-content {
  color: #222;
  padding: 30px;
}
.markAllRead.ant-modal-confirm .ant-modal-content .ant-modal-body .ant-modal-confirm-btns {
  margin: 0 auto 30px;
  width: 100%;
}
.markAllRead.ant-modal-confirm .ant-modal-content .ant-modal-body .ant-modal-confirm-btns .ant-btn {
  color: #00a6ff;
  width: 40%;
}
.markAllRead.ant-modal-confirm .ant-modal-content .ant-modal-body .ant-modal-confirm-btns .ant-btn-primary {
  color: #ffffff;
}
.message-pagination .ant-pagination-prev a,
.message-pagination .ant-pagination-next a {
  border: 1px solid #e0e0e0;
  color: #222222;
  border-radius: 5px;
}
.message-pagination .ant-pagination-prev:hover a,
.message-pagination .ant-pagination-next:hover a,
.message-pagination .ant-pagination-prev:active a,
.message-pagination .ant-pagination-next:active a,
.message-pagination .ant-pagination-prev:focus a,
.message-pagination .ant-pagination-next:focus a {
  border: 1px solid #e0e0e0;
  color: #222222;
  border-radius: 5px;
}
.message-pagination .ant-pagination-item {
  border: 1px solid #e0e0e0;
  border-radius: 5px;
}
.message-pagination .ant-pagination-item a {
  color: #222222;
}
.message-pagination .ant-pagination-item:hover,
.message-pagination .ant-pagination-item:focus {
  border: 1px solid #f0f0f2;
  border-radius: 5px;
  background-color: #f0f0f2;
}
.message-pagination .ant-pagination-item:hover a,
.message-pagination .ant-pagination-item:focus a {
  color: #222222;
}
.message-pagination .ant-pagination-item-active {
  background-color: #f0f0f2;
  border: 1px solid #f0f0f2;
  border-radius: 5px;
}
.message-pagination .ant-pagination-item-active a {
  color: #222222;
}
.message-pagination .ant-pagination-jump-next .ant-pagination-item-container .ant-pagination-item-link-icon,
.message-pagination .ant-pagination-jump-prev .ant-pagination-item-container .ant-pagination-item-link-icon {
  color: #e0e0e0;
}
.card-id-step-wrap {
  color: #000;
  padding: 20px 0;
}
.card-id-step-wrap._1 .card-round-step .circle-line:first-child .circle-num {
  border-color: #42d200;
  color: #42d200;
}
.card-id-step-wrap._2 .card-round-step .circle-line:first-child .circle-num {
  display: none;
}
.card-id-step-wrap._2 .card-round-step .circle-line:first-child .circle-suc {
  display: block;
}
.card-id-step-wrap._2 .card-round-step .circle-line:last-child .circle-num {
  border-color: #42d200;
  color: #42d200;
}
.card-id-step-wrap._2 .card-info-step .line-info .success-line {
  width: 50%;
  height: 2px;
  background-color: #42d200;
}
.card-id-step-wrap._3 .card-round-step .circle-line .circle-num {
  display: none;
}
.card-id-step-wrap._3 .card-round-step .circle-line .circle-suc {
  display: block;
}
.card-id-step-wrap._3 .card-info-step .line-info .success-line {
  width: 100%;
  height: 2px;
  background-color: #42d200;
}
.card-id-step-wrap._3 .card-info-step .text-info {
  color: #42d200;
}
.card-id-step-wrap .card-info-step .text-info:first-child {
  color: #42d200;
}
.card-id-step-wrap .card-round-step {
  width: 234px;
  overflow: hidden;
  margin: auto;
}
.card-id-step-wrap .circle-line:first-child {
  float: left;
}
.card-id-step-wrap .circle-line:last-child {
  float: right;
}
.card-id-step-wrap .circle-line .circle-num {
  border: 2px solid #ccc;
  color: #ccc;
  width: 30px;
  height: 30px;
  line-height: 28px;
  text-align: center;
  border-radius: 50%;
}
.card-id-step-wrap .circle-line .circle-num.success {
  border-color: #42d200;
  background-color: #42d200;
  color: #fff;
}
.card-id-step-wrap .circle-line .circle-suc {
  display: none;
  height: 30px;
  line-height: 30px;
  color: #42d200;
  font-size: 30px;
}
.card-id-step-wrap .card-info-step {
  text-align: center;
  width: 300px;
  line-height: 30px;
  height: 30px;
  margin: auto;
  margin-top: 6px;
}
.card-id-step-wrap .card-info-step .text-info {
  display: inline-block;
  width: 56px;
  text-align: center;
  vertical-align: middle;
  font-size: 14px;
  font-weight: bold;
  color: #555;
}
.card-id-step-wrap .card-info-step .line-info {
  display: inline-block;
  width: 128px;
  margin: 0 10px;
  height: 2px;
  background-color: #ccc;
  vertical-align: middle;
}
.finish-form-wrap .ant-form-item {
  margin-bottom: 26px;
}
.finish-form-wrap .ant-col-24.ant-form-item-label {
  padding: 0;
  font-weight: bold;
}
.finish-form-wrap .ant-col-24.ant-form-item-label label {
  color: #555;
}
.finish-form-wrap .card-tip {
  height: 22px;
  line-height: 22px;
  color: #999;
}
.finish-form-wrap .Item-bankName.inactive .ant-form-item-control {
  box-shadow: 0;
  width: 100%;
}
.finish-form-wrap .Item-bankName.active .ant-form-item-control {
  box-shadow: 0 8px 20px;
}
.finish-form-wrap .bankNamewrap .ant-input:not(:first-child) {
  padding-left: 13px;
}
.finish-form-wrap .bankNamewrap.have .ant-input:not(:first-child) {
  padding-left: 38px;
}
.finish-form-wrap .bankNamewrap.active .ant-input {
  border-right: 0;
  border-bottom: 0;
  border-left: 0;
  border-radius: 0px;
}
.finish-form-wrap .bankNameSelect .ant-select-selection {
  background-color: #f3f5f9;
  border: 0;
  box-shadow: none;
  border-radius: 4px;
  height: 35px;
  width: 96%;
  margin: 0 2%;
}
.finish-form-wrap .bankNameSelect .ant-select-selection .searchwrap {
  width: 100%;
  height: 20px;
  line-height: 20px;
  background-color: #f3f5f9;
  display: flex;
  align-items: center;
}
.finish-form-wrap .bankNameSelect .ant-select-selection .ant-select-selection__rendered {
  line-height: 35px;
}
.finish-form-wrap .contentBtn {
  width: 100%;
  height: 43px;
  border-radius: 8px;
  padding: 0 10px;
  border: 1px solid #e0e0e0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.finish-form-wrap .contentBtn .ant-btn:hover,
.finish-form-wrap .contentBtn .ant-btn:focus {
  border: 1px solid #e0e0e0;
  color: #999999;
}
.finish-form-wrap .contentBtn span {
  color: #999999;
}
.bankNameSelectDropdown .ant-select-dropdown-menu-item-active:not(
            .ant-select-dropdown-menu-item-disabled
        ) {
  background-image: url("/vn/img/icon/miniTick.svg") !important;
  background-repeat: no-repeat;
  background-position: 95%;
  transition: none;
}
.bankNameSelectDropdown .ant-select-dropdown-menu {
  max-height: 215px;
}
.bankNameSelectDropdown .ant-select-dropdown-menu-item {
  text-align: left !important;
}
@use autoprefixer;
.wallet-modal.ant-modal {
  min-width: 750px;
}
.wallet-modal .ant-modal-body {
  min-height: 588px;
  margin-top: 18px;
  position: relative;
}
.wallet-modal .ant-modal-header {
  padding-top: 20px;
  text-align: center;
  background: white;
}
.wallet-modal .ant-modal-header .ant-modal-title {
  color: #222222 !important;
  font-size: 17px;
  font-weight: bold;
}
.wallet-modal .ant-modal-content .ant-modal-close .ant-modal-close-x {
  color: #000;
}
.allOption {
  display: flex;
  justify-content: space-between;
  padding-right: 5%;
}
.drop-area {
  position: relative;
  margin-bottom: 6px;
}
.transferWellet .ant-select-dropdown-menu-item-active {
  background-color: #f0f0f2 !important;
}
.transferWellet .ant-select-dropdown-menu-item-selected {
  background-color: #f0f0f2 !important;
}
.drop-area .ant-select-dropdown-menu-item-selected .allOption .optionItem2::after {
  content: url("/vn/img/wallet/icon-add.svg");
  margin-left: 5px;
  position: absolute;
  right: 10px;
}
.drop-area-promot {
  display: flex;
  justify-content: space-around;
  margin-bottom: 25px;
}
.drop-area-promot .ant-select-dropdown {
  top: 47px !important;
}
.drop-area-promot .ant-select-dropdown-menu {
  max-height: 160px;
}
.drop-area-promot .ant-select-dropdown-menu-item .option-item {
  width: 280px;
  text-align: left;
  white-space: normal;
}
.drop-area-promot .ant-select-dropdown-menu-item-selected .option-item::after {
  content: url("/vn/img/wallet/icon-add.svg");
  position: absolute;
  right: 15px;
  top: 50%;
  transform: translateY(-50%);
}
.walletLoading .ant-modal-body {
  padding: 0;
}
.walletLoading .ant-modal-body .loaderWrapper > .loaderText {
  color: #fff;
}
.walletLoading .loaderWrapper {
  padding: 24px;
  background-color: #000000CC;
  border-radius: 10px;
}
.walletLoading img {
  width: 40px;
  height: 40px;
}
.walletLoading {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  background-color: transparent;
  box-shadow: none;
  position: absolute !important;
  top: 0 !important;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 11;
}
.loaderWrapper img {
  width: 40px;
  /* Default width */
  height: 40px;
  /* Default height */
}
.loaderText {
  margin-top: 10px;
  margin-bottom: 0;
  padding: 0;
}
.deposit-list-wrap {
  height: 0;
  overflow: hidden;
  transition: height 0.3s;
  border-bottom: 1px solid #e0e0e0;
}
.wallet-wrap {
  position: relative;
  display: flex;
}
.wallet-nav-wrap {
  flex: 0 0 30%;
  left: 0;
  top: 0;
  width: 180px;
  z-index: 2;
  padding: 0 24px 0 0;
}
.wallet-nav-item {
  padding: 12px 0;
  text-align: center;
  border-bottom: 1px solid #e0e0e0;
  position: relative;
  cursor: pointer;
}
.wallet-nav-item.active,
.wallet-nav-item.open {
  color: #00a6ff;
  font-weight: bold;
}
.wallet-nav-item .anticon {
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  color: #999;
  line-height: 48px;
  transform: rotate(270deg);
  transition: transform 0.3s;
}
.wallet-nav-item.open .anticon {
  transform: rotate(360deg);
}
.wallet-nav-item:last-child,
.wallet-nav-item.border-clear {
  border: 0;
}
.deposit-list {
  overflow: hidden;
}
.deposit-list .deposit-item {
  width: 100%;
  box-sizing: border-box;
  line-height: 40px;
  height: 40px;
  border-radius: 5px;
  background-color: #fff;
  transition: background-color 0.3s;
  position: relative;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 3px 0;
  z-index: 1;
}
.deposit-list .deposit-item .isFast::after {
  content: "Siêu Tốc";
  height: 18px;
  font-size: 10px;
  background-color: #2157eb;
  border-radius: 4px;
  text-align: center;
  margin-left: 2px;
  font-weight: 500;
  color: #fff;
  padding: 3px;
}
.deposit-list .deposit-item .isNew::after {
  content: "Mới";
  height: 18px;
  text-align: center;
  font-size: 10px;
  background-color: #f92d2d;
  border-radius: 4px;
  margin-left: 2px;
  font-weight: 500;
  color: #fff;
  padding: 3px;
}
.deposit-list .deposit-item .isHot::after {
  content: "HOT";
  height: 18px;
  text-align: center;
  font-size: 10px;
  background-color: #FFE046;
  border-radius: 4px;
  margin-left: 2px;
  font-weight: 500;
  color: #836915;
  padding: 3px;
}
.deposit-list .deposit-item:last-child {
  margin-bottom: 16px;
}
.deposit-list .deposit-item .deposit-sprite {
  vertical-align: middle;
  display: inline-block;
}
.deposit-list .deposit-item > span {
  vertical-align: middle;
  white-space: nowrap;
  max-width: 143px;
  display: inline-block;
  font-size: 10px;
  font-weight: 700;
}
.deposit-list .deposit-item:hover,
.deposit-list .deposit-item.active {
  background-color: #f0f0f2;
  font-weight: 700;
  justify-content: start !important;
}
.deposit-list .deposit-item:hover.isNew::after,
.deposit-list .deposit-item.active.isNew::after,
.deposit-list .deposit-item:hover.isFast::after,
.deposit-list .deposit-item.active.isFast::after {
  font-weight: normal;
}
.wallet-content-wrap {
  flex: 0 0 70%;
  padding: 30px;
  min-height: 562px;
  background-color: #f0f0f2;
  border-radius: 10px;
  position: relative;
  overflow: hidden;
}
.wallet-content-wrap .ant-calendar-picker {
  display: block;
}
.wallet-content-wrap .PromoContent {
  text-align: center;
  padding: 20px;
  background-color: #fff;
  border: 1px solid #bfbfbf;
  border-radius: 10px;
  margin-bottom: 20px;
  margin-top: -25px;
}
.wallet-content-wrap .PromoContent .list {
  display: flex;
  justify-content: space-between;
}
.ant-form-item-with-help.deposit-moneyinput {
  margin-bottom: 10px;
}
.ant-form-item-with-help.deposit-moneyinput .has-error .ant-form-explain:empty {
  display: none;
}
.ant-form-item-with-help.deposit-moneyinput .has-error:has(.ant-form-explain:empty) input {
  border: 1px solid #bfbfbf;
}
.ant-form-item-with-help.deposit-moneyinput .has-error:has(.ant-form-explain:empty) input:hover {
  border-color: #333;
}
.ant-form-item-with-help.deposit-moneyinput .has-error:has(.ant-form-explain:empty) input:focus {
  border-color: #00a5fd;
}
.deposit-help-link {
  text-align: right;
  margin-bottom: 16px;
  display: block;
}
.ant-tabs-nav-wrap {
  display: flex;
  justify-content: center;
}
.ant-select-dropdown-menu-item .ant-select-dropdown-menu-item-active {
  color: #00a5fd;
}
.ant-select-dropdown-menu-item-disabled:hover {
  opacity: 0.5;
  /* 修改禁用选项的透明度 */
  color: #bcbec3 !important;
}
.ant-select-dropdown-menu-item-disabled {
  opacity: 0.5;
  /* 修改禁用选项的透明度 */
  cursor: not-allowed;
  /* 修改鼠标样式为不允许（禁用） */
  /* 其他样式调整 */
  color: #bcbec3 !important;
  /* 修改文本颜色 */
}
.transferDisclaimer {
  background-color: #FFF5BF;
  padding: 8px 13px;
  border-radius: 10px;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  color: #83630B;
}
.transferAllItem {
  border-radius: 10px;
}
.transferAllItem .ant-select-dropdown-menu-item {
  border-bottom: 1px solid #f2f2f2;
}
.transferAllItem .transferItem.UnderMaintenance i,
.transferAllItem .transferItem.UnderMaintenance .localizedName,
.transferAllItem .transferItem.UnderMaintenance .Maintenance {
  filter: grayscale(100%);
  opacity: 0.4;
}
.transferAllItem .transferItem.UnderMaintenance .Maintenance {
  padding-right: 10px;
}
.transferAllItem .transferItem {
  background-color: white;
  display: flex;
  justify-content: space-between;
  padding: 2% 2%;
}
.transferAllItem .transferItem .transferDotName i {
  padding-left: 10px;
}
.transferAllItem .transferItem .transferDotName span {
  padding-left: 10px;
}
.transferAllItem .transferItem .transferMonImg {
  cursor: pointer;
}
.transferAllItem .transferItem .transferMonImg span {
  padding-right: 10px;
}
.transferAllItem .transferItem .transferMonImg img {
  padding-right: 10px;
}
.transferAllItem .totalBalItem {
  display: flex;
  justify-content: space-between;
  padding: 2% 2%;
  border-radius: 10px;
  background-color: white;
  margin-bottom: 15px;
  font-weight: bold;
}
.transferAllItem .totalBalItem .totalBal span {
  padding-left: 10px;
}
.transferAllItem .totalBalMonImg {
  padding-right: 10px;
}
.transferAllItem .totalBalMonImg span {
  padding-right: 10px;
}
.modal-prompt-info {
  padding: 12px;
  background-color: #fff5bf;
  border-radius: 8px;
  color: #83630b;
  margin: 10px 0;
}
.modal-prompt-info .spanText {
  border-bottom: 1px solid #2962ff;
}
.modal-prompt-info .pText {
  margin: 0.2rem 0 0;
  color: #222;
  font-size: 12px;
}
.modal-prompt-info .DONENOTE {
  font-size: 12px;
  background-color: #fff5bf;
  padding: 5px;
  line-height: 20px;
  color: #83630b;
}
.modal-prompt-info .DONENOTE h3 {
  font-weight: bold;
  color: #83630b;
}
.modal-prompt-info .DONENOTE .red {
  color: red;
}
.moneyError .modal-prompt-info {
  padding: 0px 12px;
  background-color: #fee0e0;
  margin-bottom: 5px;
  border-radius: 8px;
  color: #eb2121;
  margin-top: 20px;
}
.ant-steps-vertical.ant-steps-small .ant-steps-item-container .ant-steps-item-title {
  color: #00a5fd !important;
  font-weight: bold;
}
.ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-description {
  color: rgba(0, 0, 0, 0.45);
  font-size: 12px;
}
.ant-steps-item-wait > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-description {
  font-size: 12px;
}
.modal-waining-info {
  padding-right: 6px;
  padding-left: 6px;
  margin-top: -6px;
  margin-bottom: 10px;
}
.deposit-help-wrap {
  margin-top: 20px;
}
.deposit-help-wrap.smallRiver-help-wrap .smallRiver-warnstop {
  width: 100%;
  text-align: center;
}
.deposit-help-wrap.smallRiver-help-wrap .smallRiver-warnstop img {
  width: 64px;
  height: 60px;
  margin-bottom: 20px;
  margin-top: 30px;
}
.deposit-help-wrap.smallRiver-help-wrap .smallRiver-warnstop p {
  text-align: center;
  color: #f53d3d;
  font-weight: bold;
  font-size: 17px;
  margin-bottom: 0.5rem !important;
}
.deposit-help-wrap.smallRiver-help-wrap ul {
  background: #fff5bf;
  border-radius: 8px;
  padding: 10px 25px 10px 35px !important;
}
.deposit-help-wrap.smallRiver-help-wrap ul li {
  color: #83630b !important;
  padding: 0px !important;
  line-height: 2;
}
.deposit-help-wrap.smallRiver-help-wrap .footerbtn {
  width: 100%;
  line-height: 40px;
  margin-top: 20px;
  cursor: pointer;
}
.deposit-help-wrap.smallRiver-help-wrap .footerbtn p {
  float: left;
  width: 45%;
  height: 38px;
  line-height: 38px;
  text-align: center;
  border-radius: 8px;
  color: #00a6ff;
  border: 1px solid #00a6ff;
  margin: 0 2.5%;
}
.deposit-help-wrap.smallRiver-help-wrap .footerbtn div {
  float: left;
  width: 45%;
  text-align: center;
  border-radius: 8px;
  margin: 0 2.5%;
  color: #ffffff;
}
.deposit-help-wrap.smallRiver-help-wrap .footerbtn .inactiv {
  background-color: #cccccc;
  pointer-events: none;
  position: relative;
}
.deposit-help-wrap.smallRiver-help-wrap .footerbtn .inactiv img {
  width: 22px;
  height: 22px;
  margin-right: 5px;
}
.deposit-help-wrap.smallRiver-help-wrap .footerbtn .inactiv span {
  position: absolute;
  left: 26%;
  top: 2px;
  font-size: 12px;
}
.deposit-help-wrap.smallRiver-help-wrap .footerbtn .active {
  background-color: #00a6ff;
}
.deposit-help-wrap > h4 {
  color: #222;
}
.deposit-help-wrap > ul {
  padding-left: 20px;
  margin-bottom: 10px;
}
.deposit-help-wrap > ul > li {
  color: #666;
  padding: 4px 0;
  line-height: 1.4;
  list-style: decimal;
}
.deposit-help-wrap .ant-btn.ant-btn-link,
.inline.ant-btn.ant-btn-link {
  padding: 0;
  height: auto;
}
.deposit-help-wrap .displayTip {
  margin-top: 15px;
}
.deposit-help-wrap .displayTip li {
  margin: 10px 0;
}
.deposit-help-wrap .displayTip li .tipSurContent {
  margin: 10px 0;
}
.tlc-deposit-receipt {
  color: #222;
  box-shadow: 0px 2px 4px #ddd;
  background-color: #fff;
  margin-bottom: 20px;
  margin-top: 10px;
  font-size: 13px;
  position: relative;
}
.tlc-deposit-receipt.radius {
  border-radius: 8px;
}
.tlc-deposit-receipt h2 {
  font-weight: bold;
  font-size: 14px;
  line-height: 1.4;
  margin: 0;
}
.tlc-deposit-receipt .item-label {
  color: #222222;
}
.tlc-deposit-receipt span:not(.ant-form-item-children) {
  display: inline-block;
  padding: 3px 0;
}
.tlc-deposit-receipt span:not(.item-label):not(.ant-form-item-children) {
  max-width: 60%;
  text-align: right;
}
.tlc-deposit-receipt ul > li {
  padding: 10px 15px;
  border-bottom: 1px solid #f3f3f3;
}
.tlc-deposit-receipt .ant-form-item-control {
  line-height: 32px;
}
.tlc-deposit-receipt .other-account-wrap {
  padding: 10px 15px;
}
.tlc-deposit-receipt .ant-btn.ant-btn-link {
  height: auto;
  text-align: left;
  padding: 0;
}
.tlc-deposit-receipt .ant-btn.ant-btn-link.tlc-inline-btn {
  padding: 0;
  position: absolute;
  top: -1px;
  right: 6px;
  line-height: 28px;
}
.tlc-deposit-receipt .ant-btn.ant-btn-link.tlc-inline-btn > span {
  max-width: none;
  max-width: initial;
}
.tlc-deposit-receipt .tipBox {
  padding: 10px;
  position: absolute;
  top: 120px;
  left: 0;
  z-index: 1;
}
.tlc-deposit-receipt .tipBox .tipContent {
  background: rgba(0, 0, 0, 0.8);
  color: #ffffff;
  padding: 10px;
  border-radius: 10px;
  text-align: justify;
}
.tlc-deposit-receipt .tipBox .tipContent .tipArrow {
  width: 0px;
  height: 0px;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-bottom: 10px solid #000000cc;
  display: inline-block;
  position: absolute;
  top: 0;
  right: 7%;
}
.tlc-deposit-receipt .tipBox .tipContent .closeTip {
  color: #ffee00;
  text-align: right;
  cursor: pointer;
}
.tlc-deposit-receipt .plus-upload {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #999999;
  margin-top: 10px;
}
.tlc-deposit-receipt .plus-upload img {
  margin-right: 5px;
}
.tlc-deposit-receipt .upload-wrapper .BtnBg {
  background: #ffe273;
  font: normal normal 600 12px/17px Roboto;
  padding: 3px 10px;
  margin-left: 5px;
  border-radius: 4px;
}
.tlc-deposit-receipt .upload-wrapper > h2 {
  font-weight: 400;
  color: #000;
}
.tlc-deposit-receipt .upload-wrapper .item-label {
  padding-bottom: 10px;
}
.tlc-deposit-receipt .upload-wrapper .ant-btn.ant-btn-block {
  background-color: #f0f0f2;
  border-color: #f0f0f2;
  color: #000;
  text-align: left;
  cursor: default;
  padding: 0 15px;
  margin: 10px 0;
}
.tlc-deposit-receipt .upload-wrapper .ant-btn.ant-btn-block > span {
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}
.tlc-deposit-receipt .upload-wrapper .ant-btn.ant-btn-block.link {
  cursor: pointer;
  color: #999999;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}
.tlc-deposit-receipt .smdeposit {
  padding: 10px 15px;
}
.tlc-deposit-receipt .smdeposit .item-label {
  width: 100%;
  text-align: left;
}
.INVOICE-AUT-form .INVOICE_AUT_Row .ant-form-item .ant-form-item-label > label::after {
  content: " *";
  color: #00a6ff;
  display: inherit;
}
.INVOICE-AUT-form .INVOICE_AUT_Row .errorTip {
  background: #fee5e5 0% 0% no-repeat padding-box;
  border-radius: 8px;
  padding: 10px;
  color: #eb2121;
  margin-top: 10px;
  height: auto !important;
  width: 100%;
  display: block;
}
.INVOICE-AUT-form .btnActive {
  background-color: #00a6ff;
  color: #fff;
}
.INVOICE-AUT-form .btnActive:hover {
  background-color: #00a6ff;
  color: #fff;
}
.INVOICE-AUT-form .btnNoActive {
  background-color: #cccccc;
  color: #fff;
}
.INVOICE-AUT-form .btnNoActive:hover {
  background-color: #cccccc;
  color: #fff;
}
.lb-third-step-wrap {
  text-align: center;
}
.lb-third-step-wrap .bold {
  font: normal normal bold 14px/17px Roboto;
}
.lb-third-step-wrap .ant-steps-vertical .ant-steps-item-description {
  color: #999999 !important;
  font-size: 12px;
}
.lb-third-step-wrap .note {
  padding: 10px 0;
  color: #999999 !important;
}
.lb-third-step-wrap .StepsBox {
  width: 100%;
  text-align: left;
  font-size: 12px;
  padding: 15px;
}
.lb-third-step-wrap .StepsBox .firstStep .ant-steps-item-tail {
  position: absolute !important;
  top: 7px !important;
  left: 8px !important;
  padding: 10px 0 0px !important;
}
.lb-third-step-wrap .StepsBox .firstStep .ant-steps-item-tail::after {
  background-color: #cccccc !important;
  width: 2px !important;
}
.lb-third-step-wrap .StepsBox .lastStep {
  left: 2px !important;
}
.lb-third-step-wrap .StepsBox .lastStep .ant-steps-item-description {
  position: relative !important;
  top: 3.5px !important;
}
.lb-third-step-wrap .stepDone {
  background-color: white;
  padding-bottom: 1rem;
}
.lb-third-step-wrap .stepDone .successtext {
  padding: 10px;
  font: normal normal 600 18px/24px Roboto;
}
.lb-third-step-wrap .stepDone .iconstatus {
  padding-top: 30px;
}
.lb-third-step-wrap .cuccess {
  color: #52c41a;
}
.lb-third-step-wrap .check-success > div:not(.success) {
  font-weight: bold;
}
.lb-third-step-wrap .anticon {
  font-size: 64px;
  margin: 15px 0 20px 0;
  color: #42d200;
}
.lb-third-step-wrap > p {
  margin: 20px 0 40px;
}
.lb-third-step-wrap .smallRiver-p {
  font-size: 13px;
  padding: 0 20px 0px 20px;
  text-align: left;
  color: #666666;
  margin-top: -20px;
}
.lb-third-step-wrap .smallRiver-p span {
  color: #f92d2d;
  padding: 0 0 0 5px;
}
.lb-third-step-wrap .smallRiver-border {
  padding: 8px 20px;
  border: 1px solid #f0f0f2;
  border-right: none;
  border-left: none;
  font-size: 13px;
}
.showList {
  display: flex;
  justify-content: space-between;
}
.wallet-radio-wrap {
  display: block;
}
.wallet-radio-wrap .wallet-radio {
  box-sizing: border-box;
  padding: 0 11px;
  line-height: 45px;
}
.wallet-radio-wrap .wallet-radio:not(:last-child) {
  margin-bottom: 5px;
}
.ant-select-dropdown-menu-item.withdrawal-new-bank {
  color: #52a9ff !important;
}
.ant-select-dropdown-menu-item.withdrawal-new-bank::after {
  content: "+";
  font-size: 20px;
  text-indent: -13px;
  float: right;
}
.ant-select-dropdown.widthdraw-bank-card .ant-select-dropdown-menu-item .anticon.anticon-check {
  float: right;
  color: #fff;
  line-height: 32px;
}
.ant-select-dropdown.widthdraw-bank-card .ant-select-dropdown-menu-item.ant-select-dropdown-menu-item-selected .anticon.anticon-check {
  color: #52a9ff;
}
.ant-select-selection-selected-value .anticon.anticon-check {
  display: none;
}
.ant-select-lg.placeholder-block .ant-select-selection__placeholder {
  display: block !important;
  color: rgba(34, 34, 34, 0.8);
}
.split-line {
  width: 100%;
  height: 1px;
  background-color: #ccc;
  margin-bottom: 20px;
}
.card-balance-wrap {
  color: #222;
  min-height: 72px;
  box-shadow: 0px 2px 4px #ddd;
  background-color: #fff;
  border-radius: 10px;
  padding-bottom: 6px;
  padding-top: 14px;
  margin-bottom: 20px;
  overflow: hidden;
  text-align: center;
}
.card-balance-wrap:nth-child(2) {
  padding-bottom: 14px;
  line-height: 44px;
}
.card-balance-wrap:nth-child(2) .ant-col:first-child {
  line-height: 1.6;
}
.card-balance-wrap:nth-child(2) .anticon {
  margin-left: 6px;
  -moz-columns: #111;
       columns: #111;
  cursor: pointer;
}
.card-balance-wrap .balance-box {
  width: 126px;
  float: left;
  margin: 0 6px;
  padding-top: 4px;
  border-radius: 5px;
  border: 2px solid transparent;
  overflow: hidden;
  transition: all 0.2s;
  cursor: pointer;
}
.card-balance-wrap .balance-box div:not(.btn) {
  width: 86%;
  margin: auto;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.card-balance-wrap .balance-box .btn {
  color: #fff;
}
.card-balance-wrap .balance-box:hover {
  border-color: #00a6ff;
}
.card-balance-wrap .balance-box:hover .btn {
  background-color: #00a6ff;
}
.wallet-learn-wrap {
  position: absolute;
  top: 0;
  z-index: 10;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #666;
  background-repeat: no-repeat;
  border-radius: 6px;
}
.wallet-learn-wrap.withdraw {
  background-image: url("/vn/img/learn/withdraw_learn.png");
}
.wallet-learn-wrap.withdraw .learn-knew {
  margin-top: 462px;
  margin-left: 400px;
}
.wallet-learn-wrap.transfer {
  height: 1034px;
  background-image: url("/vn/img/learn/transfer_learn.png");
}
.wallet-learn-wrap.transfer .learn-knew {
  margin-top: 810px;
  margin-left: 400px;
}
/* 充值渠道 */
.Pay_Radio .ant-radio-wrapper {
  width: 148px;
  height: 46px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #e0e0e0;
  border-radius: 10px;
  line-height: 46px;
  padding-left: 10px;
  margin-bottom: 10px;
  padding: 0 5px;
}
.Pay_Radio .ant-radio-wrapper.AliPOB::after {
  content: "新";
  width: 48px;
  height: 29px;
  line-height: 29px;
  font-size: 14px;
  border-radius: 0px 15px 0px;
  background-color: #eb2121;
  transform: scale(0.7);
  position: absolute;
  top: -4px;
  text-align: center;
  right: -7px;
  color: #fff;
}
.Pay_Radio.OA .ant-radio-wrapper,
.Pay_Radio.WC .ant-radio-wrapper {
  width: 202px;
  padding-left: 12px;
}
.Pay_Radio.OA .ant-radio-wrapper:nth-child(2n),
.Pay_Radio.WC .ant-radio-wrapper:nth-child(2n) {
  margin-right: 0;
}
.suggested-amount-wrap {
  overflow: hidden;
}
.suggested-amount-wrap ul {
  margin-top: -8px;
}
.suggested-amount-wrap li {
  float: left;
  text-align: center;
  cursor: pointer;
  border: 1px solid #e0e0e0;
  background-color: #fff;
  width: 96px;
  height: 45px;
  line-height: 45px;
  border-radius: 10px;
  margin-top: 8px;
  margin-right: 10px;
}
.suggested-amount-wrap li.true {
  line-height: 41px;
  color: #00a6ff;
  border-color: #00a6ff;
}
.suggested-amount-wrap li.disabled {
  cursor: default;
  color: #cccccc;
}
.suggested-amount-wrap li:nth-child(4n) {
  margin-right: 0;
}
.SuggestedAmountDiv {
  position: relative;
  width: 100%;
  height: auto;
  background-color: #e0e0e0;
  border-radius: 4px;
  padding: 15px 0 15px 15px;
  color: #000;
  font-size: 13px;
  border: 0;
  margin-top: 10px;
}
.SuggestedAmountDiv:after {
  display: table;
  content: "";
  clear: both;
}
.SuggestedAmountDiv .arrow {
  position: absolute;
  top: -10px;
  left: 10px;
  border-bottom: 12px solid #e0e0e0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
}
.SuggestedAmountDiv p {
  text-align: left;
  height: 10px;
  line-height: 10px;
}
.SuggestedAmountDiv ul li {
  float: left;
  width: 90px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  margin-right: 8px;
  margin-top: 8px;
  border: 0;
  border-radius: 8px;
  background: white;
  cursor: pointer;
}
.SuggestedAmountDiv ul li:hover {
  background-color: #00a6ff;
  color: white;
}
.SuggestedAmountDiv ul li.true {
  background-color: #00a6ff;
  color: white;
}
.ctc-wrapper .ant-radio-wrapper {
  margin: 0;
}
.ctc-wrapper .ant-radio {
  vertical-align: text-bottom;
}
.ctc-wrapper .ant-form-item-label label[for="CTC_money"]::after {
  content: " ";
  color: #00a6ff;
  display: inline-block;
}
.ctc-wrapper .wallet-radio-wrap {
  width: 100%;
}
.ctc-wrapper .wallet-radio-wrap .wallet-radio {
  width: 49%;
}
.ctc-wrapper .wallet-radio-wrap .wallet-radio:nth-child(odd) {
  margin-right: 2%;
}
.ctc-wrapper .ctc-pay-method-item {
  width: 49%;
  float: left;
  height: 122px;
  text-align: center;
  background-color: #fff;
  border: 2px solid #fff;
  border-radius: 10px;
  margin-bottom: 10px;
  margin-right: 2%;
  box-shadow: 0px 2px 4px #ebebeb;
  cursor: pointer;
  position: relative;
}
.ctc-wrapper .ctc-pay-method-item:nth-child(2n) {
  margin-right: 0;
}
.ctc-wrapper .ctc-pay-method-item.curr {
  color: #00a6ff;
  border-color: #00a6ff;
}
.ctc-wrapper .ctc-pay-method-item.USDT-ERC20,
.ctc-wrapper .ctc-pay-method-item.USDT-TRC20 {
  height: 122px;
}
.ctc-wrapper .ctc-pay-method-item.USDT-ERC20 > p,
.ctc-wrapper .ctc-pay-method-item.USDT-TRC20 > p {
  width: 70%;
  margin: auto;
}
.ctc-wrapper .ctc-pay-method-item.USDT-TRC20 .ctc-currency-tip {
  display: block;
}
.ctc-wrapper .ctc-pay-method-item i {
  width: 60px;
  height: 70px;
  display: block;
  margin: auto;
}
.ctc-wrapper .ctc-pay-method-item i.BTC {
  background-position: -122px -149px !important;
}
.ctc-wrapper .ctc-pay-method-item i.USDT-ERC20 {
  width: 122px !important;
  height: 62px !important;
  background-position: -64px -300px !important;
}
.ctc-wrapper .ctc-pay-method-item i.USDT-TRC20 {
  width: 122px !important;
  height: 62px !important;
  background-position: -185px -306px !important;
}
.ctc-wrapper .ctc-pay-method-item i.ETH {
  background-position: -183px -149px !important;
}
.ctc-wrapper .ctc-currency-tip {
  display: none;
  background-color: #2962ff;
  color: #fff;
  font-size: 12px;
  position: absolute;
  padding: 0 10px;
  right: 4px;
  top: 6px;
  border-radius: 5px;
  transform: scale(0.9);
}
.wallet-prompt-ctc .ant-modal-confirm-content {
  margin: 0;
}
.ctc-prompt .ctc-pay-method-item {
  display: block;
  box-shadow: none;
  float: none;
  cursor: default;
  margin: auto;
}
.prompt-center-box {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  top: 0;
  width: 116px;
  height: 66px;
  line-height: 66px;
  text-align: center;
  overflow: hidden;
  margin: auto;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 10px;
  color: #fff;
  z-index: 1;
}
.currentWithdraw-lb .wallet-radio-wrap {
  width: 100%;
}
.currentWithdraw-lb .wallet-radio-wrap .ant-radio-wrapper {
  width: 49%;
  margin-right: 2%;
}
.currentWithdraw-lb .wallet-radio-wrap .ant-radio-wrapper:nth-child(even) {
  margin-right: 0;
}
.currentWithdraw-lb .ant-form-item-label > label::after {
  content: "*";
  color: #f00;
  display: inherit !important;
}
.currentWithdraw-ccw .add-ccw-withdrawMethod-btn {
  width: 100%;
  height: 43px;
  color: #1c8eff;
  font-size: 14px;
  line-height: 1.5715;
  background-color: #fff;
  background-image: none;
  border: 1px solid #bfbfbf;
  border-radius: 8px;
  transition: all 0.3s;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0 11px;
  cursor: pointer;
}
.currentWithdraw-ccw .btn-wrap .active {
  background-color: #00a6ff;
  color: #fff;
}
.currentWithdraw-ccw .btn-wrap .noactive {
  background-color: #cccccc;
  border: 1px solid #ccc;
  color: #fff;
}
.currentWithdraw-ccw .btn-wrap .ant-btn[disabled],
.currentWithdraw-ccw .btn-wrap .ant-btn-disabled:hover,
.currentWithdraw-ccw .btn-wrap .ant-btn[disabled]:hover {
  background-color: #cccccc;
  color: #fff;
}
.currentWithdraw-ccw .errorTip {
  width: 100%;
  line-height: 20px;
  color: #f92d2d;
  font-size: 12px;
}
.currentWithdraw-ccw .ant-select-lg .ant-select-selection__rendered {
  line-height: 22px;
}
.currentWithdraw-ccw .ant-select-selection-selected-value {
  line-height: 22px;
}
.currentWithdraw-ccw .modal-prompt-info .goPage {
  color: #52a9ff;
  cursor: pointer;
}
.currentWithdraw-ccw .ant-form-item-label > label::after {
  content: "*";
  color: #f00;
  display: inherit !important;
}
.currentWithdraw-ccw .modal-prompt-others {
  width: 100%;
}
.currentWithdraw-ccw .modal-prompt-others .modal-prompt-label {
  width: 100%;
  height: 43px;
  line-height: 43px;
  padding: 0 12px;
  background-color: #e0e0e0;
  margin-bottom: 20px;
  border-radius: 8px;
}
.currentWithdraw-ccw .ccw-withdrawal-amount-label label::after {
  content: normal;
  content: initial;
}
.currentWithdraw-ccw .ccw-withdrawal-amount-label label > div {
  display: flex;
  justify-content: space-between;
}
.currentWithdraw-ccw .ccw-withdrawal-amount-label label > div > span:first-child::after {
  content: "*";
  color: #f00;
  margin: 0 8px 0 2px;
}
.currentWithdraw-ccw .ccw-withdrawal-hint {
  width: 100%;
  background-color: #e8e8eb;
  padding: 13px 15px;
  border: 1px solid #ccc;
  border-radius: 10px;
  color: #222;
  line-height: 20px;
}
.ccw-locked-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: 502px;
  padding: 30px;
  background-color: #fff;
  border-radius: 10px;
}
.ccw-locked-container > p {
  color: #222;
  margin: 28px 0 0 0;
}
.ant-select-dropdown-menu-item.selectTDBWallet-addBtn {
  color: #52a9ff !important;
}
.ant-select-dropdown-menu-item.selectTDBWallet-addBtn::after {
  content: "+";
  font-size: 20px;
  text-indent: -13px;
  float: right;
}
.ant-select-dropdown.selectTDBWallet .ant-select-dropdown-menu-item {
  text-align: left;
}
.ant-select-dropdown.selectTDBWallet .ant-select-dropdown-menu-item .anticon.anticon-check {
  float: right;
  line-height: 32px;
  color: #52a9ff;
}
.ant-select-dropdown.selectTDBWallet .ant-select-dropdown-menu-item.ant-select-dropdown-menu-item-selected {
  background-color: #f0f0f2;
}
.ant-select-dropdown.selectTDBWallet .ant-select-dropdown-menu-item.ant-select-dropdown-menu-item-selected .anticon.anticon-check {
  color: #52a9ff;
}
.addExchangeRateWalletModal {
  margin-top: 8%;
}
.addExchangeRateWalletModal .modal-wallet-info-container {
  background-color: #f5f5f5;
  padding: 15px 5px 15px 15px;
  border-radius: 10px;
  margin-top: 5px;
}
.addExchangeRateWalletModal .modal-wallet-info-container .modal-wallet-info {
  font-size: 12px;
  line-height: 24px;
}
.addExchangeRateWalletModal .btn-wrap Button {
  width: 100%;
}
.addExchangeRateWalletModal .btn-wrap Button:nth-child(1) {
  border: 1px solid #cccccc;
  color: #ffffff;
  background: #cccccc;
}
.addExchangeRateWalletModal .btn-wrap Button:nth-child(2) {
  background-color: #cccccc;
  color: #fff;
}
.addExchangeRateWalletModal .btn-wrap Button:nth-child(2):hover {
  border: 1px solid #cccccc;
}
.addExchangeRateWalletModal .btn-wrap .active {
  border: 1px solid #00a6ff;
  background-color: #00a6ff !important;
  color: #ffffff !important;
}
.addExchangeRateWalletModal .WalletModal-form-wrap .errorTip {
  width: 100%;
  line-height: 20px;
  color: #f92d2d;
  font-size: 12px;
}
.showInfoWalletModal {
  width: 150px !important;
}
.showInfoWalletModal .ant-modal-body {
  padding: 0 !important;
  background-color: #000000cc;
  border-radius: 6px;
}
.showInfoWalletModal .ant-modal-body .ant-modal-confirm .ant-modal-body {
  padding: 0 !important;
}
.showInfoWalletModal .ant-modal-body .ant-modal-confirm-body .ant-modal-confirm-content {
  padding: 0 !important;
  margin-top: 25px;
  color: #fff;
}
.showInfoWalletModal .ant-btn-primary,
.showInfoWalletModal .ant-modal-close-x {
  display: none;
}
.redTip {
  color: #f92d2d;
}
.fontBold {
  font-weight: bold;
}
.USDT-promotion-wrap {
  padding-bottom: 15px;
  position: relative;
  cursor: pointer;
}
.USDT-promotion-wrap .anticon.anticon-close {
  position: absolute;
  top: 8px;
  right: 14px;
  color: white;
}
.verify-code-wrap .input-otp-wrap {
  display: flex;
  transform: scale(1);
  position: relative;
  justify-content: space-between;
}
.verify-code-wrap .input-otp-wrap input.ant-input {
  width: 38px;
  height: 44px;
  line-height: 60px;
  font-size: 16px;
  text-align: center;
}
.banknumber-verify-otp h3 {
  font-weight: bold;
  padding-top: 20px;
  padding-bottom: 20px;
}
.banknumber-verify-otp .inline-link-btn {
  padding: 10px 0;
}
.banknumber-verify-otp .retry-send.ant-btn-background-ghost.ant-btn-link {
  margin: 10px 0;
  color: #999;
}
.banknumber-verify-otp .retry-send.ant-btn-background-ghost.ant-btn-link > span {
  color: #42d200;
}
.confirm-clear-mar .ant-modal-confirm-body .ant-modal-confirm-content {
  margin-top: 0;
}
.bankinfo-verify-otp h3 {
  font-weight: bold;
  margin-top: 10px;
}
.loading-animation {
  animation-name: loadingRotate;
  animation-duration: 2000ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}
@keyframes loadingRotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.deposit-result {
  text-align: left;
  color: #222222;
  margin-bottom: 0;
}
.deposit-result dd {
  margin-bottom: 0;
}
.deposit-result dt span,
.deposit-result dd span {
  color: #f92d2d;
  font-weight: normal;
}
.ant-modal.modal-Info .ant-modal-body .ant-modal-confirm-content {
  margin-top: 0;
  padding-bottom: 0;
}
.ant-modal.modal-Info .ant-modal-body .ant-modal-confirm-content .content {
  margin: 20px 0;
}
.ant-modal.modal-Info .ant-modal-body .ant-modal-confirm-content .content p {
  margin-bottom: 0.5em;
}
.ant-modal.modal-Info .ant-modal-body .ant-modal-confirm-content .content p:last-child {
  width: 78%;
  margin: auto;
}
.MainBlance {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding: 2% 5%;
  background-color: white;
  margin: 5% 0;
  border-radius: 5px;
}
.MainBlance .item-label {
  margin: auto;
}
.MainBlance .item-label img {
  padding-left: 5px;
  width: 24px;
  height: 24px;
  cursor: pointer;
}
.MainBlance .item-blance {
  display: grid;
}
.successPaper {
  background-color: white;
  padding: 10px 30px;
}
.radioCon {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.radioCon .radio__btn {
  background-color: white;
  border-radius: 10px;
  padding: 0 10px;
  margin-top: 2%;
  width: 48%;
  height: 100%;
}
.radioCon .radio__btn label {
  font-size: 11px;
  display: flex;
  align-items: center;
}
.radioCon .radio__btn input[type="radio"] {
  display: none;
}
.radioCon .radio__btn label:before {
  content: " ";
  display: inline-block;
  position: relative;
  margin: 0 5px 0 0;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  border: 1px solid #cccccc;
  background-color: transparent;
}
.radioCon .radio__btn input[type="radio"]:checked + label:before {
  border-radius: 50%;
  width: 13px;
  height: 13px;
  content: "";
  display: block;
  background: #00a5fd;
  border: 3px solid white;
  outline: #00a5fd solid 1px;
  outline-offset: -1px;
}
.ant-divider {
  background-color: #cccccc !important;
}
.transfer-form-wrap .ant-btn-primary[disabled] {
  background-color: #cccccc;
  color: #ffffff;
}
.transfer-form-wrap .drop-area-promot .ant-select-selection-selected-value {
  width: 280px;
}
.transfer-form-wrap .drop-area-promot .ant-select-selection-selected-value > div {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.transfer-form-wrap .drop-area-promot .ant-select-dropdown-menu {
  max-height: 132px;
}
.DepositInfo .list {
  display: flex;
  justify-content: space-between;
  padding: 10px 15px;
  border-top: 1px solid #0000000a;
  align-items: center;
}
.ctc-confirm .ctcfreeCharge {
  position: relative;
}
.ctc-confirm .ctcfreeCharge::after {
  top: -32px;
  right: -30px;
  border-radius: 0px 0px 0px 4px;
}
.ctcfreeCharge {
  position: relative;
}
.ctcfreeCharge::after {
  content: "免手续费";
  width: 54px;
  height: 18px;
  line-height: 18px;
  font-size: 10px;
  background-color: #eb2121;
  position: absolute;
  top: -1px;
  text-align: center;
  right: -1px;
  color: #fff;
  border-radius: 0 8px 0 8px;
}
.WithdrawalVerification .ant-steps-item-wait .ant-steps-item-icon {
  background-color: #fff;
  border: 2px solid #D4D4D4;
}
.WithdrawalVerification .ant-steps-item-wait .ant-steps-item-icon .ant-steps-icon {
  color: #D4D4D4;
  font-weight: bold;
}
.WithdrawalVerification .ant-steps-item-active .ant-steps-item-icon {
  background-color: #00a6ff;
  border-color: #00a6ff;
}
.WithdrawalVerification .ant-steps-item-active .ant-steps-item-icon .ant-steps-icon {
  color: #fff;
  font-weight: bold;
}
.WithdrawalVerification .IDCardVerify {
  padding: 25px 0;
}
.WithdrawalVerification .VerificationSteps {
  padding: 10px 40px;
}
.WithdrawalVerification .VerificationSteps .ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item {
  margin-right: 0;
}
.WithdrawalVerification .VerificationSteps .ant-steps-item-title::after {
  left: -8px;
  height: 2px;
}
.WithdrawalVerification .form-item label {
  display: block;
  padding-bottom: 10px;
}
.WithdrawalVerification .note {
  color: #999999;
}
.WithdrawalVerification .AddressVerify {
  padding-bottom: 30px;
}
.BankList .ant-select-dropdown-menu .ant-select-dropdown-menu-item {
  text-align: left;
}
.wallet-deposit .ant-modal-header .ant-modal-title {
  color: white;
}
.wallet-deposit .ant-modal-body {
  letter-spacing: 1px;
  line-height: 1.5rem;
  padding: 20px 30px;
}
.wallet-deposit .ant-modal-body .modal-prompt-info {
  background-color: transparent;
  color: #999999;
  line-height: 1.5rem;
  padding: 0;
  margin: 0;
}
.wallet-deposit .ant-modal-body .userNames-input.havename .ant-input-disabled {
  background-color: transparent;
  color: #000;
}
.wallet-deposit .ant-modal-body .userNames-input.havename .ant-input-group-addon {
  background-color: transparent;
  color: #000;
  font-weight: bold;
}
.wallet-deposit .ant-modal-body .ant-form-item {
  margin-bottom: 20px;
}
.wallet-deposit .ant-modal-body .ant-form-item.wallet-deposit_phoneEdit {
  margin-bottom: 10px;
}
.wallet-deposit .ant-modal-body .ant-form-item.wallet-deposit_phoneEdit .ant-form-item-label > label {
  margin-bottom: 4px;
  font-size: 12px;
  font-weight: normal;
}
.wallet-deposit .ant-modal-body .ant-form-item .ant-form-explain {
  font-size: 12px;
  margin-bottom: 0;
}
.wallet-deposit .ant-modal-body .wallet-label-phoneNum {
  margin-bottom: 0;
}
.wallet-deposit .ant-modal-body .wallet-label-otp {
  margin-bottom: 10px;
}
.wallet-deposit .ant-modal-body .wallet-label-otp .ant-form-item-label {
  font-weight: normal;
  margin-bottom: 4px;
}
.wallet-deposit .ant-modal-body .wallet-label-otp .ant-form-item-label label {
  font-size: 12px;
}
.wallet-deposit .ant-modal-body .wallet-label-otp .ant-input-group-addon {
  padding: 0 16px;
}
.wallet-deposit .ant-modal-body .ant-form-item-required {
  font-size: 12px;
  font-weight: normal;
}
.wallet-deposit .ant-modal-body .realName label {
  font-size: 14px;
  font-weight: bold;
}
.wallet-deposit .ant-modal-body .ant-form-item-label {
  font-weight: bold;
  padding: 0;
  margin-bottom: 10px;
}
.wallet-deposit .ant-modal-body .otp-cs-tip {
  font-size: 12px;
  margin: 0 0 15px 0;
  line-height: 1rem;
}
.wallet-deposit .ant-modal-body .otp-cs-tip button {
  font-size: 12px;
}
.wallet-deposit .ant-modal-body .otp-cs-tip.last-cs-tip {
  margin-bottom: 20px;
}
.wallet-deposit .ant-modal-body .input-label {
  font-size: 12px;
  line-height: 1rem;
  margin-bottom: 4px;
}
.wallet-deposit .ant-modal-body .has-error .disabled-time .ant-input {
  border-right: 0;
}
.wallet-deposit .ant-modal-body .has-error .disabled-time .ant-input-group-addon {
  border-color: #ff4d4f;
  border-left: 0;
}
.wallet-deposit .ant-modal-body .forWalletDeposit .ant-input-group-addon {
  background-color: #35c95b;
  border-color: #35c95b;
}
.wallet-deposit .ant-modal-body .has-error .forWalletDeposit .ant-input {
  border-right: 0;
}
.wallet-deposit .ant-modal-body .has-error .forWalletDeposit .ant-input-group-addon {
  border-color: #ff4d4f;
  border-left: 0;
}
.wallet-deposit .ant-modal-body .btn-wrap .ant-btn {
  letter-spacing: -2px;
}
.wallet-deposit .ant-modal-body .remain {
  font-size: 12px;
  color: #999;
  margin-bottom: 10px;
}
.wallet-deposit .ant-modal-body .remain span {
  color: #00a5fd;
}
.wallet-deposit .ant-modal-body .skip {
  text-align: center;
  font-size: 12px;
  color: #00a5fd;
}
.exchange-hint {
  width: 284px;
  margin: 0 auto;
}
.OrderContent {
  background-color: #f0f0f2;
  color: #000;
  text-align: left;
  padding: 10px;
  margin: 10px;
  border-radius: 8px;
}
.currentWithdraw-lb .ant-select-dropdown-menu .ant-select-dropdown-menu-item {
  text-align: left;
}
.UploadFileData {
  text-align: left;
  background-color: white;
  margin-top: 15px;
  padding: 10px;
}
.UploadFileData .note {
  background: #ffe273;
  border-radius: 4px;
  color: #000;
  padding: 5px 10px;
  font-size: 12px;
}
.UploadFileData button {
  background: #f0f0f2 0% 0% no-repeat padding-box;
  border-radius: 10px;
  color: #999999;
  border: 0;
  margin: 10px 0;
  height: 40px;
}
.PickerBox {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.ant-calendar-ym-select a {
  color: #1c8eff !important;
}
.Usdt-Exchange .DepositMoeny {
  background: #f5f5f5;
  border-radius: 10px;
  padding: 10px;
  margin: 10px 0;
  display: flex;
  justify-content: space-between;
  color: #222222;
}
.Usdt-Exchange .DepositMoenyList {
  background: #f5f5f5;
  border-radius: 10px;
  text-align: left;
  padding: 15px;
}
.Usdt-Exchange .DepositMoenyList li {
  margin-bottom: 5px;
}
.Usdt-Exchange .DepositMoenyList small {
  color: #999999;
}
.Usdt-Exchange .ChangeList {
  text-align: left;
}
.Usdt-Exchange .ChangeList li {
  margin-bottom: 10px;
}
.UnfinishedGamePopUp {
  width: 359px;
}
.UnfinishedGamePopUp .Content {
  padding: 40px 20px 20px;
}
.UnfinishedGamePopUp .GamesContent {
  text-align: center;
}
.UnfinishedGamePopUp .GamesContent .Modal-text {
  color: #000;
  padding: 10px;
}
.UnfinishedGamePopUp .GamesContent .GameMiniLogo {
  background-color: #efeff4;
  border-radius: 8px;
  padding: 20px;
  margin-top: 20px;
}
.UnfinishedGamePopUp .GamesContent .GameMiniLogo .openGame {
  flex: 0 0 50%;
}
.UnfinishedGamePopUp .GamesContent .GameMiniLogo .list {
  margin-bottom: 10px;
}
.UnfinishedGamePopUp .GamesContent .GameOpen {
  flex: 0 0 45%;
}
.Modal_file_btn {
  width: 100%;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
  opacity: 0;
  filter: alpha(opacity=0);
  height: 100%;
}
.smallCountDownModal .ant-modal-close-x {
  color: transparent;
}
.depositSuccess .ant-steps-item-tail {
  top: 20px !important;
  left: 8px !important;
  padding: 0px 0 8px !important;
}
.depositSuccess .ant-steps-item-wait .ant-steps-item-icon {
  margin-left: 2px !important;
  margin-top: 2px !important;
}
.depositSuccess .ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-tail::after {
  background-color: #bfbfbf !important;
}
.left-next,
.right-next {
  top: -80px;
  bottom: 0;
  width: 36px;
  height: 64px;
  margin: auto;
  position: absolute;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  z-index: 1;
}
.left-next img,
.right-next img {
  width: 32px;
  height: 64px;
}
.left-next {
  left: 4px;
}
.right-next {
  right: 4px;
}
.crypto-lesson-wrap {
  position: relative;
  width: 100%;
  height: 323px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}
.crypto-lesson-wrap.withdraw-ls-1 {
  background-image: url("/vn/img/withdrawlesson/tutorial1.png");
}
.crypto-lesson-wrap.withdraw-ls-2 {
  background-image: url("/vn/img/withdrawlesson/tutorial2.png");
}
.crypto-lesson-wrap.withdraw-ls-3 {
  background-image: url("/vn/img/withdrawlesson/tutorial3.png");
}
.crypto-lesson-wrap.withdraw-ls-4 {
  background-image: url("/vn/img/withdrawlesson/tutorial4.png");
}
.crypto-lesson-wrap.address-ls-1 {
  background-image: url("/vn/img/withdrawlesson/add-tutorial1.png");
}
.crypto-lesson-wrap.address-ls-2 {
  background-image: url("/vn/img/withdrawlesson/add-tutorial2.png");
}
.crypto-lesson-wrap.address-ls-3 {
  background-image: url("/vn/img/withdrawlesson/add-tutorial3.png");
}
.crypto-lesson-wrap.address-ls-4 {
  background-image: url("/vn/img/withdrawlesson/add-tutorial4.png");
}
.crypto-lesson-wrap.address-ls-5 {
  background-image: url("/vn/img/withdrawlesson/add-tutorial5.png");
}
.ant-tabs-tab {
  color: #999999;
}
.crypto-lesson-body {
  margin-left: 6rem;
  margin-right: 6rem;
}
.deposit-comment-title {
  text-align: left;
  font-weight: bold;
  font-size: 14px;
  margin-top: 18px;
  margin-bottom: 7px;
}
.deposit-comment-content {
  text-align: left;
  font-size: 12px;
}
.slick-dots .custom-Carousel {
  bottom: -5px !important;
  z-index: 999;
}
.ant-carousel .custom-Carousel .slick-dots li.slick-active {
  background: transparent;
}
.ant-carousel .custom-Carousel .slick-dots li.slick-active button {
  background: #000000bf;
  border-radius: 8px;
  height: 8px;
  width: 8px;
}
.ant-carousel .custom-Carousel .slick-dots li {
  background: transparent;
}
.ant-carousel .custom-Carousel .slick-dots li button {
  background: #00000040;
  border-radius: 50%;
  height: 8px;
  width: 8px;
}
.arrowLeftRight {
  background-color: #00000040;
  padding-top: 10px;
  padding-bottom: 10px;
  border-radius: 5px;
}
.comment-title {
  text-align: center;
  color: #f92d2d;
  font-size: 18px;
  font-weight: 600;
  padding-top: 2rem;
}
.comment-content {
  text-align: left;
  color: #000000;
  font-weight: 500;
  font-size: 18px;
  margin-top: 3rem;
  margin-bottom: 3rem;
  margin-left: 10rem;
  margin-right: 10rem;
  line-height: 20px;
  text-shadow: 0.2px 0.2px, 0.2px 0.2px, 0.2px 0.2px;
}
.ccw-withdrawLesson .ant-btn {
  width: 70%;
  margin: 30px 15% 0;
}
.IMPOinfo .ant-modal-close-x {
  display: none !important;
}
.promotion-tabs .ant-radio-group {
  display: flex;
}
.promotion-deposit-wrap {
  padding: 70px 10px;
  padding-top: 40px;
}
.promotion-deposit-wrap > .ant-col:first-child {
  text-align: center;
  margin-top: 40px;
}
.promotion-deposit-wrap .slider-title {
  margin-top: 16px;
  margin-bottom: 20px;
}
.promotion-deposit-wrap .ant-input-number {
  width: 100%;
}
.promotion-deposit-wrap .ant-input-number-handler-wrap {
  display: none;
}
.promotion-deposit-wrap .ant-select {
  display: block;
}
.promotion-modal.title-background.Form-modal input.ant-input-disabled {
  background: #fff;
  border: 1px solid #bfbfbf;
  border-radius: 8px;
  height: 43px;
}
.promotion-modal.title-background.Form-modal input.ant-input-number-input {
  border-radius: 20px;
  text-align: right;
  font-weight: bold;
  color: #00a6ff;
  font-size: 20px;
}
.promotion-modal .ant-slider-handle {
  background-color: #00a6ff;
}
.promotion-transfer-modal._2 .ant-modal-body {
  height: 576px;
}
.promotion-transfer-modal.promotion-modal .ps_bottom {
  position: relative;
}
.step-line-row {
  margin-left: 10px;
  margin-right: 20px;
}
.step-line-row .ant-col {
  height: 3px;
  border-left: 1px solid #bfbfbf;
}
.step-line-row .ant-col:last-child {
  border-right: 1px solid #bfbfbf;
}
.step-percentage-row .ant-col:nth-child(1) {
  text-align: left;
}
.step-percentage-row .ant-col:nth-child(2) {
  text-align: center;
}
.step-percentage-row .ant-col:nth-child(3) {
  text-align: right;
}
.promotion-search.ant-input-group-wrapper {
  width: 180px;
  margin-bottom: 10px;
  position: relative;
  z-index: 1;
}
.promotion-search.ant-input-group-wrapper .ant-input {
  border-left: 2px solid #f92d2d;
  border-bottom: 2px solid #f92d2d;
  border-top: 2px solid #f92d2d;
  border-right: 0;
  box-shadow: none;
  padding-left: 38px;
  background-image: url("/vn/img/promotions/search.png");
  background-repeat: no-repeat;
  background-position: 10px center;
}
.tlc-promotions-wrap {
  margin-top: -55px !important;
}
.tlc-promotions-wrap .ant-tabs-bar.ant-tabs-left-bar {
  margin-top: 60px;
}
.tlc-promotions-wrap.ant-tabs {
  overflow: visible;
  padding-bottom: 30px;
}
.tlc-promotions-wrap.ant-tabs .ant-tabs-left-content {
  border-left: 0;
}
.transfer-money-wrap {
  position: relative;
  border-radius: 5px;
  border: 1px solid #bfbfbf;
  padding: 20px;
}
.transfer-to-target-btn {
  position: absolute;
  right: 20px;
  top: 0;
  bottom: 0;
  margin: auto;
  display: block;
  width: 26px;
  height: 26px;
  background-image: url("/vn/img/promotions/sb_transfer.png");
  cursor: pointer;
}
.transfer-to-target-btn.curr {
  background-image: url("/vn/img/promotions/sb_transfer_disable.png");
  cursor: default;
}
.pormotion-confirm .svg {
  color: #00a6ff;
  font-size: 66px;
  margin-bottom: 0;
}
.pormotion-confirm .ant-modal-close {
  display: none;
}
.pormotion-confirm .ant-modal-confirm-body .ant-modal-confirm-content {
  margin-top: 0;
}
.pormotion-confirm.ant-modal-confirm .ant-modal-confirm-btns {
  border-radius: 22.5px;
}
.pormotion-confirm.ant-modal-confirm .ant-modal-confirm-btns .ant-btn span {
  color: #00a6ff;
}
.pormotion-confirm.ant-modal-confirm .ant-modal-confirm-btns .ant-btn-primary span {
  color: white;
}
.loading_card_list {
  display: flex;
  flex-flow: wrap;
}
.loading_card_list li {
  width: 33.333%;
  height: 230px;
  border-radius: 20px;
  margin-bottom: 20px;
}
.loading_card_list li .box {
  border: 1px solid #ddd;
  height: 100%;
  margin: 10px;
  border-radius: 20px;
  overflow: hidden;
}
.animationcard,
.loading_card_list li .box {
  background: linear-gradient(90deg, #f2f2f2 25%, #e6e6e6 37%, #f2f2f2 63%);
  background-size: 400% 100%;
  animation: ant-skeleton-loading 1.4s ease infinite;
}
@keyframes ant-skeleton-loading {
  0% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0 50%;
  }
}
.promotions-content .ant-tabs-top-bar {
  margin-bottom: 30px;
}
.promotions-content .ant-tabs-top-bar .ant-tabs-nav-wrap {
  display: unset;
}
.promotions-content .ant-tabs-nav .ant-tabs-tab {
  margin: 0;
}
.promotions-content .tlc-promotions-sidebar .ant-tabs-left-bar,
.promotions-content .rebate-leftside-tab .ant-tabs-left-bar {
  width: 200px;
  background-color: white;
  text-align: center;
}
.promotions-content .tlc-promotions-sidebar .ant-tabs-left-bar .ant-tabs-nav-scroll,
.promotions-content .rebate-leftside-tab .ant-tabs-left-bar .ant-tabs-nav-scroll {
  width: 100%;
}
.promotions-content .tlc-promotions-sidebar .ant-tabs-left-bar .ant-tabs-nav-wrap,
.promotions-content .rebate-leftside-tab .ant-tabs-left-bar .ant-tabs-nav-wrap {
  display: unset;
}
.promotions-content .tlc-promotions-sidebar .ant-tabs-left-bar .ant-tabs-nav > div,
.promotions-content .rebate-leftside-tab .ant-tabs-left-bar .ant-tabs-nav > div {
  display: flex;
  flex-direction: column;
}
.promotions-content .tlc-promotions-sidebar .ant-tabs-left-bar .ant-tabs-nav > div .ant-tabs-tab,
.promotions-content .rebate-leftside-tab .ant-tabs-left-bar .ant-tabs-nav > div .ant-tabs-tab {
  padding: 10px 20px;
  font: normal normal 14px/19px "Roboto", Arial, sans-serif;
}
.promotions-content .tlc-promotions-sidebar .ant-tabs-left-bar .ant-tabs-nav > div .ant-tabs-tab .icon-list,
.promotions-content .rebate-leftside-tab .ant-tabs-left-bar .ant-tabs-nav > div .ant-tabs-tab .icon-list {
  width: 160px;
  text-align: left;
  white-space: break-spaces;
  overflow-wrap: break-word;
}
.promotions-content .tlc-promotions-sidebar .ant-tabs-left-bar .ant-tabs-nav > div .ant-tabs-tab-active,
.promotions-content .rebate-leftside-tab .ant-tabs-left-bar .ant-tabs-nav > div .ant-tabs-tab-active {
  background-color: #e0e0e0;
  color: #000;
}
.promotions-content .tlc-promotions-sidebar .ant-tabs-left-bar .ant-tabs-ink-bar,
.promotions-content .rebate-leftside-tab .ant-tabs-left-bar .ant-tabs-ink-bar {
  width: 6px;
}
.promotions-content .ant-tabs-left-bar {
  background-color: white;
  border-radius: 10px;
  padding: 15px 0 0 0;
}
.promotions-content .ant-tabs-ink-bar {
  left: 0 !important;
}
.promotions-content .PromotionsFreeBet {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  margin-top: 30px;
  grid-column-gap: 30px;
  -moz-column-gap: 30px;
       column-gap: 30px;
  grid-row-gap: 32px;
  row-gap: 32px;
}
.promotions-content .PromotionsFreeBet .FreeBet-item {
  width: 100%;
  height: 180px;
  border-radius: 8px;
  background: white;
  -webkit-mask-image: radial-gradient(circle at 15px 50%, transparent 15px, white 15.5px), radial-gradient(closest-side circle at 50%, white 99%, transparent 100%);
  -webkit-mask-size: 100%, 12px 4px;
  -webkit-mask-repeat: repeat, repeat-x;
  -webkit-mask-position: -15px, 50% 50%;
  -webkit-mask-composite: source-out;
  mask-composite: subtract;
  position: relative;
}
.promotions-content .PromotionsFreeBet .FreeBet-item .Content {
  display: flex;
  flex-direction: column;
  height: 100%;
  gap: 34px;
  padding: 20px 32px;
}
.promotions-content .PromotionsFreeBet .FreeBet-item .Content .content {
  display: flex;
  flex-direction: column;
}
.promotions-content .PromotionsFreeBet .FreeBet-item .Content .content .Name {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
}
.promotions-content .PromotionsFreeBet .FreeBet-item .Content .content .Name h4 {
  display: -webkit-box;
  line-height: 18px;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  /* 指定显示的行数 */
  -webkit-box-orient: vertical;
  width: calc(100% - 20px);
}
.promotions-content .PromotionsFreeBet .FreeBet-item .Content .content .Time {
  color: #999999;
}
.promotions-content .PromotionsFreeBet .FreeBet-item .Content .btn-apply-freebet {
  width: 38.187%;
}
.promotions-content .PromotionsFreeBet .FreeBet-item .Freebet-popover {
  cursor: pointer;
}
.Form-modal .ant-modal-body {
  min-height: 360px !important;
}
.Form-modal .ant-input-group-addon,
.Form-modal input {
  border: 0;
  border-radius: 0;
  background-color: #ffffff;
  box-shadow: 0px 2px 0px #0000000a;
}
.Form-modal textarea {
  border: 0;
  height: 100% !important;
}
.promotion-modal .ant-modal-content .ant-modal-header .ant-modal-title {
  text-align: center;
  color: #ffffff;
  font-weight: 700;
}
.promotion-modal .ant-modal-content .ant-modal-body {
  padding: 30px;
  display: flex;
  flex-direction: column;
}
.promotion-modal .ant-modal-content .ant-modal-body .ant-spin-container {
  padding: 0;
}
.promotion-modal .ant-modal-content .ant-modal-body .promotion-info-box {
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  padding: 20px 0;
  margin-bottom: 16px;
}
.promotion-modal .ant-modal-content .ant-modal-body .promotion-info-box h3 {
  text-align: center;
  font: normal normal bold 14px/19px Roboto;
  margin-bottom: 20px;
}
.promotion-modal .ant-modal-content .ant-modal-body .promotion-info-box .amount-status-box {
  display: flex;
}
.promotion-modal .ant-modal-content .ant-modal-body .promotion-info-box .amount-status-box div {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.promotion-modal .ant-modal-content .ant-modal-body .promotion-info-box .amount-status-box div .amount {
  font: normal normal bold 14px/19px Roboto;
  color: #222222;
}
.promotion-modal .ant-modal-content .ant-modal-body textarea {
  resize: none;
}
.promotion-modal .ant-modal-content .ant-modal-body textarea::-webkit-resizer {
  display: none;
}
.promotion-modal .ant-modal-content .ant-modal-footer {
  padding: 0 30px 30px 30px;
}
.promotion-modal .ant-modal-content .ant-modal-footer .ant-btn-primary {
  margin: 0;
  width: 100%;
  font: normal normal bold 14px/19px Roboto;
}
.deposit-warning-modal .ant-modal-footer > div {
  display: flex;
  width: 100%;
  gap: 20px;
}
.deposit-warning-modal .ant-modal-footer > div button {
  width: 100%;
  height: 40px;
  border-radius: 8px;
  padding: 10px 0;
  cursor: pointer;
}
.deposit-warning-modal .ant-modal-footer > div button:nth-child(1) {
  background-color: transparent;
  border: 1px solid #00a6ff;
  letter-spacing: 0px;
  color: #00a6ff;
}
.deposit-warning-modal .ant-modal-footer > div button:nth-child(2) {
  background: #00a6ff 0% 0% no-repeat padding-box;
  color: #fff;
  border: 1px solid #fff;
}
.deposit-warning-modal .ant-modal-footer > div button span {
  display: block;
  margin: 0;
}
.promotion-detail-modal {
  width: 1120px;
}
.promotion-detail-modal .ant-modal-header {
  padding: 0px;
  background-color: #00a6ff;
}
.promotion-detail-modal .ant-modal-header .ant-modal-title {
  font: normal normal bold 18px/24px Roboto;
  padding: 18px 0;
  color: #fff;
  text-align: center;
}
.promotion-detail-modal .ant-modal-body {
  padding: 30px 50px;
}
.promotion-detail-modal .ant-modal-body .ant-spin-container {
  padding: 0;
}
.promotion-detail-modal .ant-modal-body .ant-spin-container .apply-promotion-detail-box {
  margin-bottom: 30px;
  border: none;
  padding-bottom: 30px;
}
.promotion-detail-modal .button-box {
  display: flex;
  justify-content: center;
}
.promotion-detail-modal .button-box .rate-viewer-box {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.promotion-detail-modal .button-box .rate-viewer-box .rate-base {
  height: 10px;
  width: 600px;
  background-color: #eae7e7;
  border-radius: 50px;
  margin-bottom: 12px;
}
.promotion-detail-modal .button-box .rate-viewer-box .rate-base .percentage {
  height: 100%;
  background-color: #00a6ff;
  position: relative;
  border-radius: 50px;
}
.promotion-detail-modal .button-box .rate-viewer-box .rate-base .percentage .indicator {
  position: absolute;
  right: 0%;
  top: 0%;
  width: 22px;
  height: 22px;
  border-radius: 50px;
  background-color: #21acf7;
  border: 2px solid #ffffffe6;
  transform: translate(50%, -25%);
  margin-bottom: 22px;
}
.promotion-detail-modal .button-box .rate-viewer-box .scale-box {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 25px;
}
.promotion-detail-modal .button-box .rate-viewer-box .scale-box .scale-text-box {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.promotion-detail-modal .button-box .rate-viewer-box .scale-box .scale-text-box .scale-line {
  position: relative;
  width: 1px;
  height: 5px;
  background-color: #a1a1a1;
  margin-bottom: 10px;
}
.promotion-detail-modal .button-box .rate-viewer-box .scale-box .scale-text-box .scale-line p {
  position: absolute;
  top: 0%;
  margin: 0;
  transform: translate(-25%, 50%);
  color: #00a6ff;
}
.promotion-detail-modal .button-box .rate-viewer-box p {
  font: normal normal normal 12px/16px Roboto;
  letter-spacing: 0px;
  color: #222222;
}
.promotion-detail-modal .button-box button {
  width: 30.8%;
  border: none;
  font: normal normal bold 14px/19px Roboto;
  color: #fff;
  background-color: #00a6ff;
  padding: 10px 0;
  border-radius: 10px;
  cursor: pointer;
}
.promotion-detail-modal .button-box button:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}
.promotion-detail-modal .button-box .btn-get-bonus {
  background-color: #0ccc3c;
  display: flex;
  justify-content: center;
  align-items: center;
}
.promotion-transfer-modal .ant-modal-body {
  padding: 30px;
}
.promotion-transfer-modal .ant-modal-body .promotion-deposit-wrap {
  padding: 0;
}
.promotion-transfer-modal .ant-modal-body .promotion-deposit-wrap > div:first-child {
  margin-top: 0;
  text-align: left;
}
.promotion-transfer-modal .ant-modal-body .promotion-deposit-wrap .ant-input-number-input-wrap input {
  text-align: left;
  font: normal normal normal 16px/22px Roboto;
  color: #222222;
}
.promotion-transfer-modal .ant-modal-body .ant-spin-container {
  padding: 0;
}
.promotion-transfer-modal .ant-modal-body .ant-spin-container .transfer-function-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}
.promotion-transfer-modal .ant-modal-body .ant-spin-container .transfer-function-box > div {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 4px;
}
.promotion-transfer-modal .ant-modal-body .ant-spin-container .transfer-function-box > div input {
  background: #fff 0% 0% no-repeat padding-box;
  border: 1px solid #cccccc;
  border-radius: 8px;
  padding: 23px 15px;
  display: block;
  cursor: auto;
}
.promotion-transfer-modal .ant-modal-body .ant-spin-container .transfer-function-box > div .input-transfer-to {
  background: #f4f2f2 0% 0% no-repeat padding-box;
  cursor: default;
  color: #999999;
}
.promotion-transfer-modal .ant-modal-body .ant-spin-container .transfer-function-box > div .input-amount {
  padding: 10px 15px;
}
.promotion-transfer-modal .ant-modal-body .ant-spin-container .transfer-function-box > div .invalid-input {
  border: 1px solid #F11818;
}
.promotion-transfer-modal .ant-modal-body .ant-spin-container .transfer-function-box > div .error-message {
  background-color: #FEE5E5;
  color: #F11818;
  border-radius: 8px;
  font-size: 14px;
  padding: 12px;
  margin-top: 5px;
}
.promotion-transfer-modal .ant-modal-body .ant-spin-container .transfer-function-box .from-wallet-select-box {
  position: relative;
}
.promotion-transfer-modal .ant-modal-body .ant-spin-container .transfer-function-box .from-wallet-select-box .button-transfer {
  display: block;
  cursor: pointer;
  background-color: transparent;
  border: none;
  position: absolute;
  top: 49%;
  right: 10%;
  z-index: 100;
}
.promotion-transfer-modal .ant-modal-body .ant-spin-container .transfer-function-box .from-wallet-select-box .ant-select .ant-select-selection-selected-value {
  width: 100%;
}
.promotion-transfer-modal .ant-modal-body .ant-spin-container .transfer-function-box .from-wallet-select-box .ant-select .ant-select-selection-selected-value .option-item {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.promotion-transfer-modal .ant-modal-body .ant-spin-container .transfer-function-box .from-wallet-select-box .ant-select .ant-select-selection-selected-value .option-item p {
  margin: 0;
}
.promotion-transfer-modal .ant-modal-body .ant-spin-container .transfer-function-box .warm-reminder {
  text-align: left;
  background: #fff5bf 0% 0% no-repeat padding-box;
  border-radius: 10px;
  font: normal normal normal 12px/20px Roboto;
  letter-spacing: 0px;
  color: #83630b;
  padding: 10px 15px;
}
.promotion-transfer-modal .ant-modal-body .ant-spin-container .transfer-function-box .account-box,
.promotion-transfer-modal .ant-modal-body .ant-spin-container .transfer-function-box .amount-box {
  width: 100%;
}
.promotion-transfer-modal .ant-modal-body .ant-spin-container .transfer-function-box .transfer-money-wrap {
  border: none;
  border-radius: none;
  padding: 0px;
}
.promotion-transfer-modal .ant-modal-body .ant-spin-container .transfer-function-box .promotion-info-box {
  margin: 0 0 20px 0;
  padding: 20px 30px;
  text-align: center;
  width: 100%;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  gap: 15px;
}
.promotion-transfer-modal .ant-modal-body .ant-spin-container .transfer-function-box .promotion-info-box .promotion-title {
  font: normal normal bold 14px/17px Roboto;
  letter-spacing: 0px;
  color: #222222;
}
.promotion-transfer-modal .ant-modal-body .ant-spin-container .transfer-function-box .promotion-info-box .amount-box {
  display: flex;
  justify-content: space-between;
}
.promotion-transfer-modal .ant-modal-body .ant-spin-container .transfer-function-box .promotion-info-box .amount-box .amount-item .title {
  text-align: center;
  letter-spacing: 0px;
  color: #999999;
}
.promotion-transfer-modal .ant-modal-body .ant-spin-container .transfer-function-box .promotion-info-box .amount-box .amount-item .amount {
  font: normal normal bold 14px/17px Roboto;
  letter-spacing: 0px;
  color: #222222;
}
.promotion-transfer-modal .ant-modal-body .ant-spin-container .ps_button {
  display: flex;
  flex-direction: column;
  gap: 15px;
}
.promotion-transfer-modal .ant-modal-body .ant-spin-container .ps_button button {
  width: 100%;
  padding: 10px 0;
  border-radius: 10px;
  border: none;
  background-color: #00a6ff;
  border: 1px solid #fff;
  color: #fff;
  cursor: pointer;
}
.promotion-transfer-modal .ant-modal-body .ant-spin-container .ps_button .btn-submit-transfer:disabled {
  color: #fff;
  background-color: #cccccc;
}
.promotion-transfer-modal .ant-modal-body .ant-spin-container .ps_button .btn-go-deposit {
  background-color: #fff;
  border: 1px solid #00a6ff;
  color: #00a6ff;
}
.bonus-apply-success-modal .ant-modal-header {
  border-radius: 10px 10px 0 0;
  background: #00a5fd;
}
.bonus-apply-success-modal .ant-modal-header .ant-modal-title {
  color: #fff;
  font-size: 20px;
  height: 24px;
  text-align: center;
}
.bonus-apply-success-modal .ant-modal-body {
  padding: 25px 35px 20px 35px;
  display: flex;
  justify-content: center;
  font-size: 16px;
  font-weight: 900;
}
.bonus-apply-success-modal .ant-modal-footer {
  padding: 0 35px 30px;
  height: 70px;
}
.bonus-apply-success-modal .ant-modal-footer div {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.bonus-apply-success-modal .ant-modal-footer div .ant-btn {
  width: 48%;
  height: 40px;
}
.bonus-apply-success-modal .ant-modal-footer div .ant-btn:first-child {
  border-color: #00a6ff;
  color: #00a6ff;
  font-weight: 600;
}
.apply-fail-modal .ant-modal-body {
  padding: 25px 35px 20px 35px;
  display: flex;
  justify-content: center;
  font-size: 16px;
}
.apply-fail-modal .ant-modal-footer {
  padding: 0 35px 30px;
  height: 70px;
}
.apply-fail-modal .ant-modal-footer div .ant-btn {
  width: 100%;
  height: 40px;
  border-color: #00a6ff;
  font-weight: 500;
}
.check-apply-data-container-modal-bonus-details .ant-modal-body {
  color: #222222;
}
.check-apply-data-container-modal-bonus-details.physical h3 {
  font-size: 16px;
  margin-bottom: 24px;
}
.check-apply-data-container-modal-bonus-details.physical label {
  margin-bottom: 8px;
}
.check-apply-data-container-modal-bonus-details.physical .info {
  margin-bottom: 24px;
  background-color: #efeff4;
  border-radius: 8px;
  padding: 16px;
}
.check-apply-data-container-modal-bonus-details.physical .info .name {
  font-size: 16px;
  font-weight: 500;
}
.check-apply-data-container-modal-bonus-details.physical .info .phone {
  color: #999999;
  margin-bottom: 16px;
}
.check-apply-data-container-modal-bonus-details.physical .info .address {
  color: #666666;
  font-weight: 500;
}
.check-apply-data-container-modal-bonus-details.physical .remark {
  background-color: #efeff4;
  border-radius: 8px;
  padding: 12px;
  min-height: 88px;
}
.check-apply-data-container-modal-bonus-details.non-physical h3 {
  font: normal normal 500 16px/18px Roboto;
  margin-bottom: 30px;
}
.check-apply-data-container-modal-bonus-details.non-physical .user-info {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.check-apply-data-container-modal-bonus-details.non-physical .user-info .ant-input-group-wrapper + p {
  padding: 3px 0 0 11px;
  margin: 0;
  font-size: 12px;
  color: #666666;
}
.check-apply-data-container-modal-bonus-details.non-physical .user-info .ant-input-group-wrapper + p span {
  color: #00a6ff;
  cursor: pointer;
}
.check-apply-data-container-modal-bonus-details.non-physical .user-info .ant-input-group-wrapper label {
  display: block;
  padding-left: 11px;
  margin-bottom: 8px;
  color: #222222;
}
.check-apply-data-container-modal-bonus-details.non-physical .user-info .ant-input-group-wrapper textarea {
  max-height: 80px;
  padding: 17px 20px;
  color: #999999;
  background-color: #f4f2f2;
}
.check-apply-data-container-modal-bonus-details.non-physical .user-info .ant-input-group-wrapper .ant-input-wrapper {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.check-apply-data-container-modal-bonus-details.non-physical .user-info .ant-input-group-wrapper .ant-input-wrapper .ant-input-group-addon {
  background-color: transparent;
  border: none;
  color: #222222;
}
.check-apply-data-container-modal-bonus-details.non-physical .user-info .ant-input-group-wrapper .ant-input-wrapper input {
  display: block;
  background: #f4f2f2 0% 0% no-repeat padding-box;
  border: 1px solid #cccccc;
  border-radius: 8px;
  color: #999999;
  padding: 20px 13px;
}
.check-apply-data-container .ant-modal-body h3 {
  font: normal normal 600 14px/18px Roboto;
  color: #000;
  margin-bottom: 32px;
}
.check-apply-data-container .ant-modal-body > div {
  display: flex;
  flex-direction: column;
  gap: 15px;
  color: #000;
}
.check-apply-data-container .ant-modal-body > div .ant-input-group-wrapper {
  background-color: #EFEFF4;
  border-radius: 8px;
  padding: 10px;
}
.check-apply-data-container .ant-modal-body > div .ant-input-group-wrapper p {
  max-height: 100px;
  color: #666;
  border: none;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-all;
  min-height: 22px;
}
.manual-apply-modal .Formbox .user-info-box {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.manual-apply-modal .Formbox .user-info-box .ant-input-group-wrapper {
  color: #999999;
}
.manual-apply-modal .Formbox .user-info-box .ant-input-group-wrapper .ant-input-wrapper {
  gap: 10px;
}
.manual-apply-modal .Formbox .user-info-box .ant-input-group-wrapper .ant-input-wrapper span {
  padding: 0;
  color: #222222;
  font-weight: 500;
}
.manual-apply-modal .Formbox .user-info-box .ant-input-group-wrapper .ant-input-wrapper input {
  border-radius: 8px;
  padding: 20px 13px;
}
.manual-apply-modal .Formbox .user-info-box .ant-input-group-wrapper .ant-input-wrapper .ant-input[disabled] {
  background-color: #f4f2f2;
}
.manual-apply-modal .Formbox .user-info-box .ant-input-group-wrapper + p {
  font-size: smaller;
  margin-top: 5px;
}
.manual-apply-modal .Formbox .user-info-box .ant-input-group-wrapper + p span {
  color: #00a6ff;
  cursor: pointer;
}
.manual-apply-modal .Formbox .user-info-box > .ant-input-group-wrapper input {
  color: #222222;
}
.manual-apply-modal .Formbox .user-info-box div input {
  color: #999999;
}
.manual-apply-modal .Formbox .remarks-box {
  display: flex;
  flex-direction: column;
}
.manual-apply-modal .Formbox .remarks-box textarea {
  padding: 17px 20px;
}
.manual-apply-modal .Formbox .remarks-box textarea:focus-visible {
  outline: none;
}
.manual-result-modal {
  display: flex;
  align-items: center;
}
.manual-result-modal .ant-modal-content {
  width: 100%;
}
.manual-result-modal .ant-modal-content .ant-modal-body {
  padding: 30px;
}
.manual-result-modal .ant-modal-content .ant-modal-body .result-content-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}
.manual-result-modal .ant-modal-content .ant-modal-body .result-content-box img {
  height: 60px;
  width: 60px;
  margin-bottom: 10px;
}
.manual-result-modal .ant-modal-content .ant-modal-body .result-content-box p {
  margin: 0;
}
.manual-result-modal .ant-modal-content .ant-modal-body .result-content-box .text-status {
  font-size: 20px;
  font-weight: 700;
  color: #222222;
}
.manual-result-modal .ant-modal-content .ant-modal-body .result-content-box .text-desription {
  width: 300px;
  font-weight: 400;
  color: #222222;
  text-align: center;
}
.manual-result-modal .ant-modal-content .ant-modal-body .result-content-box .button-box {
  display: flex;
  width: 100%;
  margin-top: 10px;
  gap: 25px;
}
.manual-result-modal .ant-modal-content .ant-modal-body .result-content-box .button-box button {
  font-size: 14px;
  width: 100%;
  white-space: nowrap;
  padding: 10px 0;
  border: 1px solid #26a9e1;
  border-radius: 8px;
  cursor: pointer;
}
.manual-result-modal .ant-modal-content .ant-modal-body .result-content-box .button-box button:nth-child(1) {
  background-color: #fff;
  color: #26a9e1;
  font-weight: 700;
}
.manual-result-modal .ant-modal-content .ant-modal-body .result-content-box .button-box button:nth-child(2) {
  background-color: #00a6ff;
  color: #fff;
  font-size: 12px;
}
.warm-reminder-modal span {
  margin-bottom: 40px;
}
.warm-reminder-modal .ant-modal-footer > div {
  display: flex;
  width: 100%;
  gap: 20px;
}
.warm-reminder-modal .ant-modal-footer > div button {
  width: 100%;
  height: 40px;
  border-radius: 8px;
  padding: 10px 0;
  cursor: pointer;
}
.warm-reminder-modal .ant-modal-footer > div button:nth-child(1) {
  background-color: transparent;
  border: 1px solid #00a6ff;
  letter-spacing: 0px;
  color: #00a6ff;
}
.warm-reminder-modal .ant-modal-footer > div button:nth-child(2) {
  background: #00a6ff 0% 0% no-repeat padding-box;
  color: #fff;
  border: 1px solid #fff;
}
.warm-reminder-modal .ant-modal-footer > div button span {
  display: block;
  margin: 0;
}
.rebate-time-picker-modal .ant-modal-content > button {
  margin-top: 10px;
}
.rebate-time-picker-modal .ant-modal-content > button svg {
  color: black;
}
.rebate-time-picker-modal .ant-modal-content .ant-modal-header {
  background-color: transparent;
}
.rebate-time-picker-modal .ant-modal-content .ant-modal-header .ant-modal-title {
  color: #222;
  padding: 10px 0;
}
.icon-list {
  display: flex;
}
.icon-list .icon-image {
  filter: grayscale(1);
  padding-right: 10px;
  height: 20px;
  vertical-align: -0.4em;
}
.icon-list .icon-image-active {
  filter: unset;
}
.Formbox {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.Formbox h3 {
  font: normal normal 600 14px/18px Roboto;
  letter-spacing: 0px;
  color: #222222;
  text-align: left;
}
.Formbox .ant-input-group {
  display: flex;
  flex-direction: column;
}
.Formbox .ant-input-group input {
  border: 1px solid #cccccc;
}
.Formbox textarea {
  border: 1px solid #cccccc;
  border-radius: 8px;
  margin-bottom: 25px;
  margin-top: 10px;
}
.Formbox + .ps_bottom button {
  width: 100% !important;
}
.Formbox + .ps_bottom button:disabled {
  background-color: #cccccc !important;
  color: #fff !important;
  border: none !important;
  cursor: not-allowed;
}
.htmlbox {
  padding-bottom: 100px;
}
.ps_bottom {
  text-align: center;
  margin: 0 auto;
}
.ps_bottom button.button {
  width: 345px;
  height: 40px;
  background: #00a6ff 0% 0% no-repeat padding-box;
  border-radius: 10px;
  border: 0;
  color: white;
  cursor: pointer;
}
.ps_bottom button.button[disabled] {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  border: 1px solid #bfbfbf;
  text-shadow: none;
  box-shadow: none;
}
.ps_bottom p {
  color: #1c8eff;
  padding: 15px;
  padding-bottom: 10px;
  font-size: 12px;
  cursor: pointer;
}
.promotion-list-box .Pms_data_list {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(3, 1fr);
  grid-column-gap: 25px;
  -moz-column-gap: 25px;
       column-gap: 25px;
  grid-row-gap: 30px;
  row-gap: 30px;
  margin-bottom: 30px;
  min-height: 629px;
  position: relative;
}
.promotion-list-box .Pms_data_list .item-list {
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0px 2px 4px #00000014;
}
.promotion-list-box .Pms_data_list .promotion-item-box {
  width: 100%;
  height: 100%;
  cursor: pointer;
  overflow: hidden;
}
.promotion-list-box .Pms_data_list .promotion-item-box .content-box {
  height: 100%;
  display: grid;
  grid-template-rows: auto 1fr;
}
.promotion-list-box .Pms_data_list .promotion-item-box .content-box .promotion-image {
  width: 100%;
  height: -moz-fit-content;
  height: fit-content;
  aspect-ratio: 2.857;
  background-color: #ddd;
}
.promotion-list-box .Pms_data_list .promotion-item-box .content-box .text-box {
  background-color: white;
  padding: 10px 8px 10px 10px;
  cursor: pointer;
  display: flex;
  flex-flow: column;
  justify-content: center;
}
.promotion-list-box .Pms_data_list .promotion-item-box .content-box .text-box .title {
  font: normal normal bold 16px/21px "Roboto", Arial, sans-serif;
  margin: 0 0 8px 0;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  /* 指定显示的行数 */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
.promotion-list-box .Pms_data_list .promotion-item-box .content-box .text-box .period {
  display: flex;
  align-items: center;
  font: normal normal bold 12px/16px "Roboto", Arial, sans-serif;
  color: #999999;
}
.promotion-list-box .Pms_data_list .promotion-item-box .content-box .text-box .period p {
  margin: 0;
  letter-spacing: -0.3px;
}
.promotion-list-box .Pms_data_list .promotion-item-box .content-box .text-box .period span {
  margin-right: 5px;
  width: 14px;
  height: 18px;
}
.promotion-list-box .Pms_data_list .promotion-item-box .content-box .text-box .period span img {
  width: 100%;
}
.promotion-list-box .Pms_data_list .ant-empty {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  width: 100%;
  height: 85%;
}
.promotion-list-box .Pms_data_list .ant-empty .ant-empty-image {
  width: 13.637%;
  height: -moz-max-content;
  height: max-content;
  margin: 0;
}
.promotion-list-box .Pms_data_list .ant-empty .ant-empty-image img {
  width: 76%;
  height: auto;
}
.promotion-list-box .Pms_data_list .ant-empty .ant-empty-description {
  white-space: nowrap;
  font: normal normal normal 18px/24px Roboto;
  letter-spacing: 0px;
  color: #999999;
  margin-top: 20px;
}
.promotions-banner {
  height: 160px;
  width: 1120px;
  margin: auto;
  border-radius: 20px;
  overflow: hidden;
  background: linear-gradient(90deg, #f2f2f2 25%, #e6e6e6 37%, #f2f2f2 63%);
  background-size: 400% 100%;
  animation: ant-skeleton-loading 1.4s ease infinite;
}
.promotions-banner img {
  height: 100%;
  width: 100%;
}
.promotions-content {
  padding-top: 20px;
  min-height: 900px;
}
/*2023.01.17 add*/
.promotionCard {
  overflow: auto;
}
.promotionCard .Card {
  height: 160px;
  width: 327px;
  border-radius: 10px;
  background-color: #ffffff;
  display: inline-block;
  margin-right: 10px;
  vertical-align: top;
  overflow: hidden;
}
.promotionCard .Card img {
  width: 100%;
  height: 114px;
  border-radius: 10px 10px 0px 0px;
}
.blue {
  color: #00a6ff;
}
/*Rebate*/
.total_reabate {
  display: flex;
  justify-content: center;
  width: 100%;
  background-color: white;
  border-radius: 20px;
  padding: 3% 0;
}
.total_reabate span {
  font-size: large;
  padding: 0 5%;
}
.promotion-modal .ant-spin-container {
  padding: 3% 5%;
}
.content_list {
  background-color: #e0e0e0;
  border-radius: 20px;
}
.content_list h3 {
  padding-left: 5%;
}
.rebate-container .filter-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.rebate-container .filter-box .interval-selector-box {
  display: flex;
  justify-content: flex-end;
  border: 1px solid #e0e0e0;
  border-radius: 5px;
  overflow: hidden;
}
.rebate-container .filter-box .interval-selector-box .ant-radio-button-wrapper {
  height: 32px;
  padding: 6px 0 0 0;
  border: 0;
  text-align: center;
  font: normal normal normal 12px/16px Roboto;
  background-color: transparent;
  border-radius: unset;
}
.rebate-container .filter-box .interval-selector-box .ant-radio-button-wrapper span:nth-child(2) {
  padding: 8px 20px;
}
.rebate-container .filter-box .interval-selector-box .ant-radio-button-wrapper-checked,
.rebate-container .filter-box .interval-selector-box .ant-radio-button-wrapper-checked:hover {
  background-color: #00a6ff !important;
  border: none;
  box-shadow: none !important;
}
.rebate-container .Pms_data_rebate .defined-date-description {
  width: 100%;
  text-align: right;
  margin-top: 15px;
}
.rebate-container .Pms_data_rebate .defined-date-description span {
  margin-left: 10px;
  color: #1c8eff;
  cursor: pointer;
}
.rebate-container .Pms_data_rebate .rebate-list {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: repeat(5, 1fr);
  grid-column-gap: 30px;
  -moz-column-gap: 30px;
       column-gap: 30px;
  margin-bottom: 30px;
  min-height: 629px;
  position: relative;
}
.rebate-container .Pms_data_rebate .rebate-list .rebate-item-box {
  background-color: #ffffff;
  border: 1px solid #e0e0e0;
  border-radius: 10px;
  padding: 15px 20px;
  margin-bottom: 20px;
}
.rebate-container .Pms_data_rebate .rebate-list .rebate-item-box .header-box {
  display: flex;
  justify-content: space-between;
  padding-bottom: 15px;
  border-bottom: 1px solid #e0e0e0;
}
.rebate-container .Pms_data_rebate .rebate-list .rebate-item-box .header-box .profile {
  display: flex;
}
.rebate-container .Pms_data_rebate .rebate-list .rebate-item-box .header-box .profile .icon-image {
  width: 48px;
  height: 48px;
}
.rebate-container .Pms_data_rebate .rebate-list .rebate-item-box .header-box .profile .text-box {
  display: flex;
  gap: 12px;
}
.rebate-container .Pms_data_rebate .rebate-list .rebate-item-box .header-box .profile .text-box h3 {
  color: #222222;
  font-weight: 700;
  margin: 0;
}
.rebate-container .Pms_data_rebate .rebate-list .rebate-item-box .header-box .profile .text-box p {
  margin: 0;
}
.rebate-container .Pms_data_rebate .rebate-list .rebate-item-box .header-box button {
  cursor: pointer;
  background-color: transparent;
  border: none;
}
.rebate-container .Pms_data_rebate .rebate-list .rebate-item-box .rebate-info-box {
  padding-top: 15px;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  text-align: center;
}
.rebate-container .Pms_data_rebate .rebate-list .rebate-item-box .rebate-info-box .content_item p {
  color: #222222;
}
.rebate-container .Pms_data_rebate .rebate-list .rebate-item-box .rebate-info-box .content_item .title {
  color: #999999;
}
.rebate-container .Pms_data_rebate .rebate-list .rebate-item-box .rebate-info-box .content_item .total-amount {
  color: #f92d2d;
}
.rebate-container .Pms_data_rebate .rebate-list .ant-empty {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
  position: absolute;
  width: 100%;
  height: 70%;
}
.rebate-container .Pms_data_rebate .rebate-list .ant-empty .ant-empty-image {
  width: 160px;
  height: -moz-max-content;
  height: max-content;
  margin: 0;
}
.rebate-container .Pms_data_rebate .rebate-list .ant-empty .ant-empty-image img {
  width: 100%;
}
.rebate-container .Pms_data_rebate .rebate-list .ant-empty .ant-empty-description {
  white-space: nowrap;
  font: normal normal normal 18px/24px Roboto;
  letter-spacing: 0px;
  color: #999999;
}
.rebate-time-picker-container .ant-calendar-input-wrap {
  display: none;
}
.rebate-time-picker-container .ant-calendar-date-panel .ant-calendar-ym-select a {
  color: black;
}
.table-rebate-detail .ant-table-content .ant-table-thead tr th {
  color: black;
  text-align: center;
  background-color: transparent;
}
.table-rebate-detail .ant-table-content .ant-table-tbody tr {
  text-align: center;
}
.table-rebate-detail .ant-table-content .ant-table-placeholder .ant-empty-description {
  color: #c7c7c7;
}
.table-rebate-detail .ant-pagination {
  width: 100% !important;
  margin: 30px 0 0 0 !important;
}
.table-rebate-detail .ant-pagination li a {
  color: #222222;
}
.table-rebate-detail .ant-pagination .ant-pagination-item-active {
  border-color: transparent;
  background-color: #f0f0f2;
}
.wallet-list-box .option-item {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.wallet-list-box .option-item p {
  margin: 0;
}
.wallet-list-box .option-item .button-transfer {
  background-color: transparent;
  border: none;
  display: none;
}
.manual-apply-modal-read-only .Formbox .title {
  margin-top: 28px;
  margin-bottom: 10px;
}
.manual-apply-modal-read-only .Formbox .user-info-box {
  display: flex;
  flex-direction: column;
}
.manual-apply-modal-read-only .Formbox .user-info-box .ant-input-group-wrapper {
  color: #999999;
  background-color: white;
  padding: 0px;
}
.manual-apply-modal-read-only .Formbox .user-info-box .ant-input-group-wrapper .ant-input-wrapper {
  gap: 10px;
}
.manual-apply-modal-read-only .Formbox .user-info-box .ant-input-group-wrapper .ant-input-wrapper span {
  margin-top: 20px;
  border: none;
  padding: 0;
  color: #222222;
  font-weight: 400;
}
.manual-apply-modal-read-only .Formbox .user-info-box .ant-input-group-wrapper .ant-input-wrapper input {
  border-radius: 8px;
  padding: 20px 13px;
}
.manual-apply-modal-read-only .Formbox .user-info-box .ant-input-group-wrapper .ant-input-wrapper .ant-input[disabled] {
  background-color: #f4f2f2;
}
.manual-apply-modal-read-only .Formbox .user-info-box .ant-input-group-wrapper + p {
  font-size: smaller;
  margin-top: 5px;
}
.manual-apply-modal-read-only .Formbox .user-info-box .ant-input-group-wrapper + p span {
  color: #00a6ff;
  cursor: pointer;
}
.manual-apply-modal-read-only .Formbox .user-info-box > .ant-input-group-wrapper input {
  color: #222222;
}
.manual-apply-modal-read-only .Formbox .user-info-box div input {
  color: #999999;
}
.manual-apply-modal-read-only .Formbox .remarks-box {
  display: flex;
  flex-direction: column;
}
.promotion-card {
  width: 100%;
  min-height: 147px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #e0e0e0;
  border-radius: 10px;
}
.promotion-card .promotion-card-container {
  position: relative;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  margin: auto;
  padding: 25px 15px 19px 15px;
}
.promotion-card .promotion-card-container .promotion-info-box {
  display: flex;
}
.promotion-card .promotion-card-container .promotion-info-box .promotion-card-list-img {
  width: 48px;
  height: 48px;
}
.promotion-card .promotion-card-container .promotion-info-box .promotion-card-list-img img {
  width: 48px;
  height: 48px;
  border-radius: 10px 10px 0px 0px;
  opacity: 1;
  background: transparent 0% 0% no-repeat padding-box;
}
.promotion-card .promotion-card-container .promotion-info-box .promotion-card-list-title {
  margin-left: 12px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding-right: 20px;
}
.promotion-card .promotion-card-container .promotion-info-box .promotion-card-list-title .promotion-card-list-title-name {
  font-size: 14px;
  text-align: left;
  font-weight: bold;
}
.promotion-card .promotion-card-container .promotion-info-box .promotion-card-list-title .promotion-card-list-title-time {
  height: 16px;
  font-size: 12px;
  text-align: left;
  letter-spacing: 0px;
  color: #999999;
  opacity: 1;
}
.promotion-card .promotion-card-container .promotion-info-box .promotion-card-list-title .promotion-card-list-title-time-icon {
  position: absolute;
  right: 6%;
  top: 15%;
}
.promotion-card .promotion-card-container .button-box {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.promotion-card .promotion-card-container .button-box .promotion-card-info-bonustext {
  color: #46a5f8;
  margin-bottom: 10px;
}
.promotion-card .promotion-card-container .button-box .promotion-card-info-bonustext span {
  font-size: 12px;
}
.promotion-card .promotion-card-container .button-box > div {
  padding-left: 60px;
}
.promotion-card .promotion-card-container .button-box > div button {
  padding: 0px 33px;
  border-radius: 4px;
  cursor: pointer;
  height: 30px;
  font-size: 12px;
}
.promotion-card .promotion-card-container .button-box > div .promotion-card-info-btn-pending button {
  background-color: #fff;
  color: #00a6ff;
  border: 1px solid #00a6ff;
}
.promotion-card .promotion-card-container .button-box > div .promotion-card-info-btn-disabled button {
  background-color: #cccccc;
  color: #fff !important;
  border: 1px solid #fff !important;
  cursor: not-allowed !important;
}
.promotion-card .promotion-card-container .button-box > div .promotion-card-release-btn button {
  background-color: #65c469;
  color: #fff;
  border: 1px solid #fff;
}
.promotion-card .promotion-card-container .button-box .progress-box {
  padding-left: 0px;
}
.promotion-card .promotion-card-container .button-box .progress-box .ProgressBar {
  width: 100%;
  height: 8px;
  background-color: #e0e0e0;
  border-radius: 15px;
  margin: 10px 0px;
}
.promotion-card .promotion-card-container .button-box .progress-box .ProgressBar .Progress {
  height: 100%;
  background-color: #46a5f8;
  border-radius: 8px;
  background-image: none;
  box-shadow: none;
}
.promotion-card .promotion-card-container .button-box .progress-box .progress-text {
  display: flex;
  justify-content: space-between;
}
.promotion-CancellPopup {
  position: absolute;
  right: -15px;
  width: 206px;
  height: 50px;
  background: #333333 0% 0% no-repeat padding-box;
  border-radius: 5px;
  opacity: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
}
.promotion-CancellPopupAsk {
  position: absolute;
  right: -15px;
  width: 206px;
  height: 86px;
  /* UI Properties */
  background: #333333 0% 0% no-repeat padding-box;
  border-radius: 5px;
  opacity: 1;
  display: flex;
  flex-direction: column;
  z-index: 999;
}
.promotion-card .Invaliddata-card {
  filter: grayscale(1);
  opacity: 0.7;
}
.HidePromos-box {
  height: 16px;
  /* UI Properties */
  text-align: left;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0px;
  text-align: center;
}
ul,
li {
  list-style: none;
}
.progressbar_container {
  width: 100%;
  z-index: 1;
  margin-top: 20px;
}
.progressbar {
  counter-reset: step;
  display: flex;
}
.progressbar li {
  flex: 1 1;
  position: relative;
  text-align: center;
}
.progressbar li:before {
  content: counter(step);
  counter-increment: step;
  width: 24px;
  height: 24px;
  border: 2px solid #bebebe;
  display: block;
  margin: 0 auto 10px auto;
  border-radius: 50%;
  line-height: 24px;
  background: white;
  color: #bebebe;
  text-align: center;
  font-weight: bold;
}
.progressbar li:after {
  content: "";
  position: absolute;
  width: 100%;
  height: 2px;
  background: #c4c4c4;
  top: 15px;
  left: -50%;
  z-index: -1;
}
.progressbar li.active:before {
  border-color: #00a6ff;
  background: #00a6ff;
  color: white;
}
.progressbar li:not(:last-child).active:before {
  content: "";
  background-repeat: no-repeat;
  background-position: center center;
  display: flex;
  justify-content: center;
  align-items: center;
}
.progressbar li.active:after {
  background: #00a6ff;
}
.progressbar li:first-child:after {
  content: none;
}
.cap-list {
  border-radius: 8px;
  overflow: hidden;
  margin-top: 10px;
  margin-bottom: 20px;
  background-color: #fff;
}
.cap-list .cap-item {
  margin-bottom: 0;
}
.cap-list .cap-item div.cap-item-circle {
  background-color: #fff;
  border-radius: 50%;
  border: 1px solid #888888;
  width: 18px;
  height: 18px;
  vertical-align: middle;
  box-sizing: border-box;
  transition: all 0.2s;
  display: inline-block;
}
.cap-list .cap-item div.cap-item-circle.curr {
  border: 4px solid #00a6ff;
}
.Proms {
  width: 400px !important;
}
.Proms .ant-modal-header {
  background-color: #00a6ff;
  border-radius: 20px 20px 0 0;
}
.Proms .ant-modal-content {
  border-radius: 20px;
  background-clip: none;
}
.Proms .ant-modal-content .ant-modal-close {
  color: white;
}
.Proms .ant-modal-content .ant-modal-close svg {
  width: 18px;
  height: 18px;
}
.Proms .ant-modal-title {
  color: white;
  text-align: center;
  letter-spacing: 0px;
  font-size: 20px;
  font-weight: bold;
}
.Proms .ant-modal-body {
  padding: 30px;
}
.Proms .ant-modal-body label {
  text-align: left;
  font-size: 16px;
  line-height: 21px;
  letter-spacing: 0px;
  color: #222222;
  opacity: 1;
}
.Proms .cap-item {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: 20px;
}
.Proms .cap-item .padding-left-xs {
  text-align: left;
  margin-left: 10px;
  font-size: 14px;
  line-height: 19px;
  letter-spacing: 0px;
  color: #666666;
  opacity: 1;
}
.Proms .cap-item > input {
  width: 80%;
  border: none;
  border-radius: 0px;
  border-bottom: 1px solid #666666;
}
.Proms .cap-item > input:focus {
  box-shadow: none;
}
.Proms .cancel-btn {
  width: 160px;
  height: 40px;
  /* UI Properties */
  font-weight: 600;
  color: #cccccc;
  background: white;
  border: 2px solid #cccccc;
  border-radius: 8px;
  opacity: 1;
  cursor: pointer;
}
.Proms .remarked {
  color: #00a6ff;
  border-color: #00a6ff;
}
.Proms .keep-btn {
  width: 160px;
  height: 40px;
  /* UI Properties */
  background: #00a6ff 0% 0% no-repeat padding-box;
  border: 1px solid #00a6ff;
  border-radius: 8px;
  color: white;
  opacity: 1;
  cursor: pointer;
}
.Proms .keep-btn:disabled {
  color: white;
  background-color: #cccccc;
  border: 1px solid #cccccc;
  cursor: not-allowed;
}
.valid-list-box {
  margin-bottom: 10px;
}
.valid-list-box .applied-list-box {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 30px;
  -moz-column-gap: 30px;
       column-gap: 30px;
  grid-row-gap: 30px;
  row-gap: 30px;
  position: relative;
  margin-bottom: 30px;
}
.invalid-list-box {
  margin-bottom: 50px;
}
.invalid-list-box p {
  text-align: center;
}
.invalid-list-box p span {
  color: #46a5f8;
  cursor: pointer;
  margin-left: 10px;
}
.invalid-list-box .invalid-list {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 30px;
  -moz-column-gap: 30px;
       column-gap: 30px;
  grid-row-gap: 30px;
  row-gap: 30px;
  position: relative;
  margin-bottom: 30px;
}
.invalid-list-box .invalid-list .promotion-card-disabled .promotion-card-container img {
  filter: grayscale(100%);
}
.invalid-list-box .invalid-list .promotion-card-disabled .promotion-card-container p,
.invalid-list-box .invalid-list .promotion-card-disabled .promotion-card-container span {
  color: #bcbec3;
}
.invalid-list-box .invalid-list .promotion-card-disabled .promotion-card-container button {
  color: #bcbec3 !important;
  border-color: #bcbec3 !important;
  cursor: default !important;
}
.invalid-list-box .invalid-list .promotion-card-disabled .promotion-card-container .progress-box .ProgressBar .Progress {
  background-color: #bcbec2;
}
.invalid-list-box .invalid-list .promotion-card-disabled .promotion-card-container .progress-box .progress-text {
  display: flex;
  justify-content: space-between;
}
.invalid-list-box .invalid-list .promotion-card-disabled .promotion-card-container .progress-box .progress-text span {
  color: #bcbec3 !important;
}
.appliedHistory-popover .ant-popover-arrow {
  border-top-color: #333333 !important;
  border-left-color: #333333 !important;
}
.appliedHistory-popover .ant-popover-inner {
  background-color: #333333;
}
.appliedHistory-popover .ant-popover-inner-content {
  display: flex;
  justify-content: center;
  align-items: center;
}
.appliedHistory-popover .ant-popover-inner-content .popover-text-container {
  display: flex;
  justify-content: space-between;
  position: relative;
}
.appliedHistory-popover .ant-popover-inner-content .popover-text-container .promotion-CancellPopupAsk-text {
  padding-bottom: 30px;
}
.appliedHistory-popover .ant-popover-inner-content .popover-text-container .promotion-CancellPopupAsk-text .promotion-CancellPopupAsk-btn {
  position: absolute;
  right: 0%;
  bottom: 0%;
}
.appliedHistory-popover .ant-popover-inner-content .popover-text-container .promotion-CancellPopupAsk-text .promotion-CancellPopupAsk-btn button {
  border: 0;
  padding: 5px 20px;
  /* UI Properties */
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 4px;
  text-align: center;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0px;
  color: #222222;
  opacity: 1;
  cursor: pointer;
}
.appliedHistory-popover .ant-popover-inner-content .popover-text-container .promotion-CancellPopup-text {
  width: 200px;
  text-align: left;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
}
.appliedHistory-popover .ant-popover-inner-content .popover-text-container .promotion-CancellPopup-close {
  width: 24px;
  height: 18px;
  text-align: center;
  cursor: pointer;
}
.appliedHistory-popover .ant-popover-inner-content .popover-text-container .promotion-CancellPopup-close svg {
  color: white;
}
@use autoprefixer;
.Sponsorbox .ant-skeleton-content .ant-skeleton-paragraph li:first-child {
  height: 500px;
  width: 100% !important;
}
.Sponsorbox .ant-skeleton-content .ant-skeleton-title {
  height: 65px;
  width: 100% !important;
  border-radius: 35px;
}
.Sponsorbox .Sponsorbottom .ant-carousel .slick-slide {
  text-align: center;
  height: 500px;
  background: white;
  overflow: hidden;
}
.Sponsorbox .Sponsorbottom .ant-carousel .slick-slide h3 {
  color: #fff;
}
.Sponsorbox .Sponsorbottom .slick-list {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 2px 16px #00000014;
  border-radius: 20px;
}
.Sponsorbox .Sponsorbottom .content {
  display: flex !important;
  justify-content: center;
}
.Sponsorbox .Sponsorbottom .content .banner_left {
  width: 50%;
}
.Sponsorbox .Sponsorbottom .content .banner_left img {
  -o-object-fit: contain;
     object-fit: contain;
  width: 1120px;
  height: 500px;
  scale: 1.1;
}
.Sponsorbox .Sponsorbottom .content .banner_right {
  display: flex;
  flex-direction: column;
  background: transparent;
  color: #ccc;
  width: 50%;
  text-align: left;
  padding: 30px 50px 50px 0px;
  z-index: 3;
}
.Sponsorbox .Sponsorbottom .content .banner_right .banner_right__sponsorHeader {
  font-weight: bold;
}
.Sponsorbox .Sponsorbottom .content .banner_right .banner_right__sponsorHeader .banner_right__sponsorHeader-name {
  color: #efd088;
  font-size: 28px;
}
.Sponsorbox .Sponsorbottom .content .banner_right .banner_right__sponsorHeader .banner_right__sponsorHeader-title {
  color: #fff;
  font-size: 24px;
  padding-bottom: 12px;
}
.Sponsorbox .Sponsorbottom .content .banner_right .banner_right__sponsorBody {
  margin-top: 10px;
  width: 500px;
}
.Sponsorbox .Sponsorbottom .content .banner_right .redirect-to-sponsor {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 300px;
  height: 54px;
  background-color: transparent;
  border: 1px solid #fff;
  border-radius: 8px;
  color: #fff;
  margin-top: 20px;
  font-size: 16px;
  white-space: normal;
  line-height: 25px;
}
.Sponsorbox .Sponsorbottom .anticon {
  font-size: 35px;
}
.Sponsorbox .Sponsorbottom .special_right h2 {
  text-align: right;
  color: white;
}
.Sponsorbox .Sponsorbottom .special_left h2 {
  text-align: left;
  color: white;
}
.Sponsorbox .Sponsorbottom .special_left p {
  padding-right: 95px;
}
.Sponsorbox .Sponsorbottom .special_right p {
  padding-left: 95px;
  text-align: right;
}
.Sponsorbox .Sponsorbottom .small_box {
  position: relative;
  cursor: pointer;
}
.Sponsorbox .Sponsorbottom .small_box img {
  border-radius: 10px;
}
.Sponsorbox .Sponsorbottom .small_box .change_big {
  display: none;
}
.Sponsorbox .Sponsorbottom .small_box:hover .change_big {
  display: block;
  position: absolute;
  height: 100%;
  width: 100%;
  background: #00000033;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
}
.Sponsorbox .Sponsorbottom .special_right,
.Sponsorbox .Sponsorbottom .special_left {
  width: 50%;
  text-align: left;
  padding: 30px 50px 60px;
  color: white;
}
.Sponsorbox .Sponsorbottom .special_box {
  position: relative;
}
.Sponsorbox .Sponsorbottom .special-content {
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
}
.Sponsorbox .Sponsorbottom .small_banner {
  display: flex;
  align-items: center;
  margin-top: auto;
  gap: 20px;
}
.Sponsorbox .Sponsorbottom .next_banner {
  position: absolute;
  width: 100%;
  top: 50%;
  left: 0;
  right: 0;
  margin: 0 auto;
}
.Sponsorbox .Sponsorbottom .next_banner .n_left {
  height: 88px;
  width: 88px;
  border: 5px solid #f5f5f5;
  border-radius: 50px;
  background: white;
  float: left;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #000;
  cursor: pointer;
}
.Sponsorbox .Sponsorbottom .next_banner .n_right {
  height: 88px;
  width: 88px;
  border: 5px solid #f5f5f5;
  border-radius: 50px;
  background: white;
  float: right;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #000;
  cursor: pointer;
}
.Sponsorbox .Sponsorbottom .see_all {
  text-align: right;
  padding: 5px !important;
  cursor: pointer;
}
.Sponsorbox .Sponsorbottom .small_branner {
  display: flex;
  justify-content: center;
  align-items: center;
}
.Sponsorbox .Sponsorbottom .small_branner li {
  background: red;
  width: 140px;
  height: 90px;
  margin: 5px;
  cursor: pointer;
}
.Sponsorbox .top-container {
  display: flex;
  position: relative;
}
.Sponsorbox .top-container .Sponsortop .Sponsortop-container {
  width: 1120px;
  height: 72px;
  white-space: nowrap;
  overflow: hidden;
  margin-bottom: 23px;
}
.Sponsorbox .top-container .Sponsortop .Sponsortop-container ul {
  position: relative;
  left: 0;
  display: flex;
  gap: 20px;
  margin-bottom: 0;
  transition: left 0.3s;
}
.Sponsorbox .top-container .Sponsortop .Sponsortop-container ul li {
  min-width: 218px;
}
.Sponsorbox .top-container .Sponsortop .Sponsortop-container ul li .navbtn {
  height: 70px;
  display: flex;
  border-radius: 35px;
  border: 2px solid #d8d8d8;
  cursor: pointer;
  background: white;
  align-items: center;
}
.Sponsorbox .top-container .Sponsortop .Sponsortop-container ul li .navbtn .navbtn-container {
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 12px;
  margin-left: 20px;
}
.Sponsorbox .top-container .Sponsortop .Sponsortop-container ul li .navbtn .navbtn-container img {
  width: 45px;
  height: 45px;
}
.Sponsorbox .top-container .Sponsortop .Sponsortop-container ul li .navbtn .navbtn-container .navbtn-content__title {
  font-weight: bold;
  white-space: normal;
}
.Sponsorbox .top-container .Sponsortop .Sponsortop-container ul li .navbtn.invert img {
  filter: invert(1);
}
.Sponsorbox .top-container .Sponsortop .Sponsortop-container ul li .navbtn.active {
  background: transparent linear-gradient(111deg, #00a6ff 0%, #00a6ff 100%) 0% 0% no-repeat padding-box !important;
  border-radius: 80px;
  color: white;
  cursor: pointer;
  border: 0 !important;
}
.Sponsorbox .top-container .Sponsortop .Sponsortop-container ul li .navbtn .left {
  width: 25%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.Sponsorbox .top-container .Sponsortop .Sponsortop-container ul li .navbtn .right {
  width: 75%;
  display: flex;
  align-items: flex-start;
  flex-flow: column;
  justify-content: center;
}
.Sponsorbox .top-container .Sponsortop-left-arrow-btn,
.Sponsorbox .top-container .Sponsortop-right-arrow-btn {
  width: 40px;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  background-color: transparent;
}
.Sponsorbox .top-container .Sponsortop-left-arrow-btn svg,
.Sponsorbox .top-container .Sponsortop-right-arrow-btn svg {
  width: 30px;
  height: 30px;
}
.Sponsorbox .top-container .Sponsortop-left-arrow-btn {
  position: absolute;
  left: -40px;
}
.common-distance-wrap .ant-btn.more-vids-btn {
  width: 230px;
  height: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  margin: 30px auto 50px auto;
}
.common-distance-wrap .ant-btn.more-vids-btn .btn-content-container {
  line-height: 0;
}
.common-distance-wrap .ant-btn.more-vids-btn .btn-content-container img {
  width: 20px;
  height: 20px;
  margin-right: 10px;
}
.usdt-introduce-layout {
  background-color: #0c0a30;
  width: 100%;
  overflow: hidden;
  position: relative;
  z-index: 10;
}
.usdt-introduce-layout .tlc-sign-header .notice-wrap .marquee-container-wrapper .marquee-title span {
  color: #fff !important;
}
.usdt-introduce-layout .tlc-sign-header .notice-wrap .marquee-container-wrapper .marquee-container .tlc-notice-wrap {
  border-radius: 0 13px 13px 13px !important;
}
.usdt-introduce-layout .tlc-sign-header .login-wrap .good-greeting {
  color: #fff;
}
.usdt-introduce-layout .header-usdt {
  height: 100px;
  width: 100px;
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
}
.usdt-introduce-layout .footer-usdt {
  height: 100px;
  width: 100px;
  opacity: 0;
  position: absolute;
  bottom: 0;
  left: 0;
}
.usdt-introduce-layout .page-navi-dot-box {
  color: white;
  position: fixed;
  top: 50%;
  right: 16px;
  display: flex;
  flex-direction: column;
  gap: 15px;
}
.usdt-introduce-layout .page-navi-dot-box .navi-dot {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.4);
  transition: all 0.2s, background-color 0.5s;
}
.usdt-introduce-layout .page-navi-dot-box .current-section-dot {
  background-color: #fff;
  height: 20px;
  border-radius: 20px;
}
.usdt-introduce-layout .hero-image-ustd {
  width: 100%;
  position: absolute;
  z-index: -1;
  transform: translateY(-63px);
}
.usdt-introduce-layout .usdt-content {
  width: 1200px;
  margin: auto;
  color: #fff;
  text-align: center;
  padding-top: 820px;
}
.usdt-introduce-layout .usdt-content .usdt-question-feedback {
  margin-bottom: 50px;
}
.usdt-introduce-layout .usdt-content .usdt-description {
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 50px;
}
.usdt-introduce-layout .usdt-content .usdt-list {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  margin-bottom: 30px;
}
.usdt-introduce-layout .usdt-content .usdt-list .usdt-item {
  padding: 0 15px;
  margin-right: 15px;
  overflow: hidden;
  background-color: #0c113d;
  border-radius: 8px;
}
.usdt-introduce-layout .usdt-content .usdt-list .usdt-item:last-child {
  margin-right: 0;
}
.usdt-introduce-layout .usdt-content .usdt-list .usdt-item-img > img {
  width: 100%;
  vertical-align: middle;
}
.usdt-introduce-layout .usdt-content .usdt-list .usdt-item-text {
  text-align: left;
  margin-left: 15px;
}
.usdt-introduce-layout .usdt-content .usdt-list .usdt-item-text > h3 {
  color: #ffe4c4;
  font-size: 18px;
  font-weight: 400;
}
.usdt-introduce-layout .usdt-content .usdt-list .usdt-item-text > p {
  color: #dfdfdf;
  font-size: 14px;
  font-weight: 400;
}
.usdt-introduce-layout .usdt-content .usdt-btn-wrapper .usdt-btn {
  width: 500px;
  height: 50px;
  letter-spacing: 1px;
  display: inline-block;
  border-color: #ffe4c4;
  color: #ffe4c4;
  opacity: 0.8;
}
.usdt-introduce-layout .usdt-content .usdt-btn-wrapper .usdt-btn:hover,
.usdt-introduce-layout .usdt-content .usdt-btn-wrapper .usdt-btn:focus {
  color: #fff;
  border-color: #fff;
}
.usdt-introduce-layout .usdt-content .usdt-btn-wrapper .usdt-btn span {
  margin-right: 20px;
}
.usdt-introduce-layout .usdt-content .usdt-btn-wrapper .usdt-btn .anticon.anticon-right:last-child {
  text-indent: -6px;
}
.usdt-introduce-layout .usdt-content .usdt-introduce {
  padding-bottom: 50px;
}
.usdt-introduce-layout .usdt-content .usdt-introduce .usdt-list .usdt-item {
  display: flex;
  flex-direction: row;
  margin-right: 30px;
  width: 380px;
  height: 180px;
  padding: 30px 35px 30px 20px;
}
.usdt-introduce-layout .usdt-content .usdt-introduce .usdt-list .usdt-item:last-child {
  margin-right: 0;
}
.usdt-introduce-layout .usdt-content .usdt-introduce .usdt-list .usdt-item-img {
  width: 90px;
  flex: none;
  align-self: center;
}
.usdt-introduce-layout .usdt-content .usdt-advantage {
  padding: 30px 0 50px 0;
}
.usdt-introduce-layout .usdt-content .usdt-advantage .usdt-list {
  justify-content: space-between;
}
.usdt-introduce-layout .usdt-content .usdt-advantage .usdt-list .usdt-item {
  display: flex;
  flex-direction: column;
  width: 175px;
  height: 200px;
  border-radius: 8px;
  justify-content: center;
  align-items: center;
  float: none;
  margin: 0;
}
.usdt-introduce-layout .usdt-content .usdt-advantage .usdt-list .usdt-item-img {
  margin-bottom: 20px;
}
.usdt-introduce-layout .usdt-content .usdt-advantage .usdt-list .usdt-item-img > img {
  width: 40px;
}
.usdt-introduce-layout .usdt-content .usdt-advantage .usdt-list .usdt-item-text {
  margin: 0;
}
.usdt-introduce-layout .usdt-content .usdt-advantage .usdt-list .usdt-item-text > p {
  color: #dfdfdf;
  font-size: 18px;
  font-weight: 300;
}
.usdt-introduce-layout .usdt-content .usdt-deposit {
  padding: 30px 0 0 0;
}
.usdt-introduce-layout .usdt-content .usdt-deposit-table {
  width: 100%;
  background-color: #141337;
  display: grid;
  grid-gap: 2px;
  gap: 2px;
  margin: 0 0 42px 0;
  color: #fff;
}
.usdt-introduce-layout .usdt-content .usdt-deposit-table-td {
  width: 100%;
  padding: 18px;
  float: left;
  box-sizing: border-box;
  background-color: #141337;
  font-size: 15px;
  line-height: 24px;
}
.usdt-introduce-layout .usdt-content .usdt-deposit-table-tr {
  overflow: hidden;
  display: grid;
  grid-gap: 2px;
  gap: 2px;
  grid-template-columns: repeat(2, minmax(0, 1fr));
}
.usdt-introduce-layout .usdt-content .usdt-deposit-table-tr:nth-child(odd) div {
  background-color: #1b1a3d;
}
.usdt-introduce-layout .usdt-content .usdt-deposit-table-tr:first-child > div {
  font-weight: bold;
  background-color: #1a2151;
  padding: 8px;
}
.usdt-introduce-layout .usdt-content .usdt-deposit-type .usdt-list {
  text-align: center;
}
.usdt-introduce-layout .usdt-content .usdt-deposit-type .usdt-list .usdt-item {
  float: none;
  margin-right: 30px;
  width: 175px;
  margin-bottom: 20px;
  height: 234px;
  padding-top: 37px;
  text-align: center;
  cursor: pointer;
  display: inline-block;
}
.usdt-introduce-layout .usdt-content .usdt-deposit-type .usdt-list .usdt-item:last-child {
  margin-right: 0;
}
.usdt-introduce-layout .usdt-content .usdt-deposit-type .usdt-list .usdt-item > a {
  display: block;
  width: 100%;
  height: 100%;
  text-align: center;
}
.usdt-introduce-layout .usdt-content .usdt-deposit-type .usdt-list .usdt-item .usdt-item-img {
  float: none;
  width: 60px;
  height: 80px;
  display: inline-block;
  vertical-align: top;
}
.usdt-introduce-layout .usdt-content .usdt-deposit-type .usdt-list .usdt-item .usdt-item-img > img {
  width: 100%;
  vertical-align: top;
}
.usdt-introduce-layout .usdt-content .usdt-deposit-type .usdt-list .usdt-item .usdt-item-text {
  width: 100%;
  margin: 0;
  margin-top: 8px;
  vertical-align: top;
}
.usdt-introduce-layout .usdt-content .usdt-deposit-type .usdt-list .usdt-item .usdt-item-text > h3 {
  font-weight: bold;
  font-size: 18px;
  margin: 0;
  margin-bottom: 17px;
}
.usdt-introduce-layout .usdt-content .usdt-deposit-type .usdt-list .usdt-item .usdt-item-text > p {
  color: #323232;
}
.usdt-introduce-layout .usdt-content .usdt-deposit-type .usdt-list .usdt-item .usdt-item-text > i {
  color: #efa4a4;
  border: 1px solid #efa4a4;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  font-size: 16px;
}
.usdt-introduce-layout .usdt-content .usdt-deposit-type .usdt-list .usdt-item .usdt-item-text > i svg {
  vertical-align: -0.14em;
}
.usdt-introduce-layout .usdt-content .usdt-deposit .usdt-btn {
  border-color: #fff;
}
.usdt-introduce-layout .usdt-content .usdt-question {
  padding: 30px 0 50px 0;
}
.usdt-introduce-layout .usdt-content .usdt-question .usdt-list {
  margin-bottom: 30px;
  flex-direction: column;
}
.usdt-introduce-layout .usdt-content .usdt-question .usdt-list .usdt-item {
  padding: 16px 23px;
  width: 1040px;
  background-color: #0d133e;
  margin-bottom: 15px;
  margin-right: 0;
  border-radius: 6px;
}
.usdt-introduce-layout .usdt-content .usdt-question .usdt-list .usdt-item:last-child {
  margin-bottom: 0;
}
.usdt-introduce-layout .usdt-content .usdt-question .usdt-list .usdt-item-text {
  width: 100%;
  margin: 0;
}
.usdt-introduce-layout .usdt-content .usdt-question .usdt-list .usdt-item-text > h3 {
  font-size: 20px;
  color: #dfdfdf;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  margin-bottom: 0;
  font-weight: 500;
}
.usdt-introduce-layout .usdt-content .usdt-question .usdt-list .usdt-item-text .faqInfo {
  font-size: 20px;
  margin-top: 0.5em;
}
.usdt-introduce-layout .usdt-content .usdt-question .usdt-list .usdt-item-text .faqInfo p {
  margin: 0;
  color: #dfdfdf;
  font-weight: 500;
}
.usdt-introduce-layout .usdt-content .usdt-question .usdt-list .usdt-item.usdt-faq-selected {
  padding: 16px 23px 40px 23px;
  margin-bottom: 15px;
  margin-right: 0;
}
.usdt-introduce-layout .usdt-content .usdt-question .usdt-list .usdt-item.usdt-faq-selected .usdt-item-text {
  width: 100%;
  margin: 0;
}
.usdt-introduce-layout .usdt-content .usdt-question .usdt-list .usdt-item.usdt-faq-selected .usdt-item-text > h3 {
  margin-bottom: 26px;
}
.usdt-introduce-layout .usdt-content .usdt-question .usdt-list .usdt-item.usdt-faq-selected .usdt-item-text .faqInfo p {
  padding-right: 43px;
}
.usdt-introduce-layout .usdt-content .usdt-question .usdt-btn-wrapper {
  margin-bottom: 74px;
}
.usdt-introduce-layout .usdt-content .usdt-question .usdt-btn-wrapper .usdt-btn {
  width: 447px;
}
.usdt-introduce-layout .usdt-content .usdt-question .usdt-btn-wrapper .usdt-btn span {
  margin-right: 0;
}
.usdt-introduce-layout .usdt-content .usdt-question-feedback-form {
  position: relative;
  width: 1200px;
  padding: 30px 50px;
  border-radius: 6px;
  background-color: #212750;
}
.usdt-introduce-layout .usdt-content .usdt-question-feedback-form input.ant-input,
.usdt-introduce-layout .usdt-content .usdt-question-feedback-form textarea.ant-input {
  margin-bottom: 16px;
}
.usdt-introduce-layout .usdt-content .usdt-question-feedback-form .ant-input {
  background: transparent;
  border: 1px solid #ffe4c4;
  color: #fff;
}
.usdt-introduce-layout .usdt-content .usdt-question-feedback-form .ant-input:focus {
  border: 1px solid #fff;
}
.usdt-introduce-layout .usdt-content .usdt-question-feedback-form .ant-input::-moz-placeholder {
  color: #999;
  font-size: 14px;
}
.usdt-introduce-layout .usdt-content .usdt-question-feedback-form .ant-input:-ms-input-placeholder {
  color: #999;
  font-size: 14px;
}
.usdt-introduce-layout .usdt-content .usdt-question-feedback-form .ant-input::placeholder {
  color: #999;
  font-size: 14px;
}
.usdt-introduce-layout .usdt-content .usdt-question-feedback-form .ant-btn-primary {
  background-color: #00a6ff;
  width: 430px;
  font-size: 16px;
  font-weight: bold;
  color: #fff;
  border: none;
  cursor: pointer;
}
.usdt-introduce-layout .usdt-content .usdt-question-feedback-form .ant-btn-primary:disabled {
  background-color: #2e345d;
  color: #acacac;
  cursor: not-allowed;
}
.usdt-introduce-layout .usdt-content .usdt-question-feedback-form .errorTip {
  font-size: 14px;
  color: red;
  text-align: left;
  margin-top: -10px;
  padding: 0;
}
.usdt-introduce-layout .usdt-content .usdt-question-feedback-hint {
  margin: -10px 0 10px 0;
  color: #dfdfdf;
  font-size: 12px;
  text-align: left;
}
.UsdtTeachModalStyle {
  border-radius: 20px !important;
  overflow: hidden;
}
.UsdtTeachModalStyle .ant-modal-header {
  padding: 0;
}
.UsdtTeachModalStyle .ant-modal-header .ant-modal-title {
  padding: 18px 0;
  color: #fff;
  text-align: center;
}
.UsdtTeachModalStyle .ant-modal-body {
  padding: 20px 67px 36px 67px;
}
.UsdtTeachModalStyle .ant-modal-body:has(.FAQ-wrapper) {
  padding: 49px 30px 31px 67px;
}
.UsdtTeachModalStyle .ant-modal-body:has(.lesson-box) {
  padding: 20px 17px 67px 17px;
}
.UsdtTeachModalStyle .ant-modal-body:has(.lesson-box) .ant-tabs-bar {
  margin-bottom: 20px;
}
.UsdtTeachModalStyle .ant-modal-body:has(.lesson-box) .deposit-comment-title {
  font-weight: unset !important;
  font: normal normal bold 14px/20px Roboto;
  color: #220000;
  margin-bottom: 0;
}
.UsdtTeachModalStyle .ant-modal-body .usdt-introduce-model-wrapper {
  width: 100%;
  text-align: center;
}
.UsdtTeachModalStyle .ant-modal-body .usdt-introduce-model-wrapper .ant-tabs-top {
  padding: 0;
}
.UsdtTeachModalStyle .ant-modal-body .usdt-introduce-model-wrapper .ant-tabs-top .ant-tabs-top-bar {
  padding-top: 0;
  margin-bottom: 20px;
}
.UsdtTeachModalStyle .ant-modal-body .usdt-introduce-model-wrapper .ant-tabs-top .ant-tabs-top-bar .ant-tabs-nav-wrap .ant-tabs-tab {
  padding: 0 16px 12px 16px;
}
.UsdtTeachModalStyle .ant-modal-body .usdt-introduce-model-wrapper .ant-tabs-content .usdt-panel {
  text-align: left;
  height: 590px;
  overflow-y: scroll;
}
.UsdtTeachModalStyle .ant-modal-body .usdt-introduce-model-wrapper .ant-tabs-content .usdt-panel::-webkit-scrollbar {
  display: none;
}
.UsdtTeachModalStyle .ant-modal-body .usdt-introduce-model-wrapper .ant-tabs-content .usdt-panel .panel-Table {
  margin-bottom: 10px;
}
.UsdtTeachModalStyle .ant-modal-body .usdt-introduce-model-wrapper .ant-tabs-content .usdt-panel .table-remarks {
  margin-bottom: 30px;
  font-size: 12px;
}
.UsdtTeachModalStyle .ant-modal-body .usdt-introduce-model-wrapper .ant-tabs-content .usdt-panel .panel-Title {
  color: #222;
  font-weight: bold;
  font-size: 18px;
  line-height: 24px;
  margin-bottom: 14px;
}
.UsdtTeachModalStyle .ant-modal-body .usdt-introduce-model-wrapper .ant-tabs-content .usdt-panel .panel-List {
  color: #222;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  margin-bottom: 0;
}
.UsdtTeachModalStyle .ant-modal-body .usdt-introduce-model-wrapper .ant-tabs-content .usdt-panel .panel-List-Title {
  color: #222;
  font-size: 16px;
  font-weight: bold;
  line-height: 28px;
  margin-bottom: 0;
  font: normal normal bold 16px/28px Roboto;
}
.UsdtTeachModalStyle .ant-modal-body .usdt-introduce-model-wrapper .ant-tabs-content .usdt-panel .panel-List li {
  margin-bottom: 30px;
}
.UsdtTeachModalStyle .ant-modal-body .usdt-introduce-model-wrapper .ant-tabs-content .usdt-panel .panel-List li p {
  font-size: 16px;
  margin-bottom: 14px;
}
.UsdtTeachModalStyle .ant-modal-body .usdt-introduce-model-wrapper .ant-tabs-content .usdt-panel .panel-Table {
  width: 100%;
  background-color: #fff;
  display: grid;
  grid-gap: 1px;
  gap: 1px;
  color: #232323;
  border-radius: 8px;
  overflow: hidden;
  text-align: center;
}
.UsdtTeachModalStyle .ant-modal-body .usdt-introduce-model-wrapper .ant-tabs-content .usdt-panel .panel-Table-td {
  width: 100%;
  padding: 10px;
  background-color: #f3f5f9;
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  display: grid;
  align-items: center;
}
.UsdtTeachModalStyle .ant-modal-body .usdt-introduce-model-wrapper .ant-tabs-content .usdt-panel .panel-Table-tr4 {
  overflow: hidden;
  display: grid;
  grid-gap: 1px;
  gap: 1px;
  grid-template-columns: repeat(4, minmax(0, 1fr));
}
.UsdtTeachModalStyle .ant-modal-body .usdt-introduce-model-wrapper .ant-tabs-content .usdt-panel .panel-Table-tr4:nth-child(2n) > div {
  background-color: #e0eefa;
}
.UsdtTeachModalStyle .ant-modal-body .usdt-introduce-model-wrapper .ant-tabs-content .usdt-panel .panel-Table-tr4:first-child > div {
  font-size: 14px;
  font-weight: bold;
  color: #fff;
  background-color: #369bf2;
}
.UsdtTeachModalStyle .ant-modal-body .usdt-introduce-model-wrapper .ant-tabs-content .wallet-agreement-box .panel-List {
  margin-bottom: 30px;
}
.UsdtTeachModalStyle .ant-modal-body .usdt-introduce-model-wrapper .ant-tabs-content .wallet-agreement-box .panel-List li p {
  font-size: 14px;
}
.UsdtTeachModalStyle .FAQ-wrapper {
  width: 100%;
  height: 341px;
  overflow-y: scroll;
  background-color: #fff;
}
.UsdtTeachModalStyle .FAQ-wrapper::-webkit-scrollbar {
  width: 8px;
}
.UsdtTeachModalStyle .FAQ-wrapper::-webkit-scrollbar-thumb {
  background: #f0f0f2;
}
.UsdtTeachModalStyle .FAQ-wrapper::-webkit-scrollbar-track {
  background: transparent;
}
.UsdtTeachModalStyle .FAQ-wrapper .ant-collapse {
  padding-right: 27px;
}
.UsdtTeachModalStyle .FAQ-wrapper .ant-collapse .ant-collapse-item {
  border-bottom: 3px solid #f0f0f2;
}
.UsdtTeachModalStyle .FAQ-wrapper .ant-collapse .ant-collapse-item .ant-collapse-header {
  font-weight: bold;
}
.UsdtTeachModalStyle .FAQ-wrapper .ant-collapse .ant-collapse-item .ant-collapse-header .anticon svg {
  color: #999999;
}
.UsdtTeachModalStyle .FAQ-wrapper .ant-collapse .ant-collapse-item .ant-collapse-content-active {
  border-top: 3px solid #f0f0f2;
}
.UsdtTeachModalStyle .FAQ-wrapper .ant-collapse .ant-collapse-item .ant-collapse-content-active .ant-collapse-content-box {
  padding: 33px 45px 33px 30px;
}
.UsdtTeachModalStyle .FAQ-wrapper .ant-collapse .ant-collapse-item .ant-collapse-content-active .ant-collapse-content-box p {
  margin: 0;
  font-size: 12px;
}
.UsdtTeachModalStyle .FAQ-wrapper .ant-collapse-borderless {
  background: transparent;
}
.UsdtTeachModalStyle .FAQ-wrapper .ant-collapse-header {
  color: #222 !important;
  font-size: 14px;
  font-weight: bold;
}
.udt-withdrawl-modal .ant-modal-body {
  padding: 30px 20px 67px 20px;
}
.udt-withdrawl-modal .ant-modal-body .ant-tabs-bar {
  padding: 0;
  margin-bottom: 20px;
}
.udt-withdrawl-modal .ant-modal-body .deposit-comment-title {
  font-weight: unset;
  color: #220000;
}
.feedback-status-modal .ant-modal-content {
  box-shadow: none;
  background-color: transparent;
}
.feedback-status-modal .ant-modal-content .ant-modal-close {
  display: none;
}
.feedback-status-modal .ant-modal-content .ant-modal-body {
  padding: 0 !important;
  background-color: transparent !important;
  border: none;
}
.feedback-status-modal .ant-modal-content .ant-modal-body .ant-modal-confirm-body > i {
  display: none;
}
.feedback-status-modal .ant-modal-content .ant-modal-body .ant-modal-confirm-body .ant-modal-confirm-content {
  margin: 0;
}
.feedback-status-modal .ant-modal-content .ant-modal-body .ant-modal-confirm-body .ant-modal-confirm-content > div {
  padding: 17px 22px;
  display: flex;
  align-items: center;
  gap: 15px;
  justify-content: center;
  border-radius: 64px;
  box-shadow: 0px 3px 6px #00000029;
}
.feedback-status-modal .ant-modal-content .ant-modal-body .ant-modal-confirm-body .ant-modal-confirm-content > div svg {
  width: 24px;
  height: 24px;
}
.feedback-status-modal .ant-modal-content .ant-modal-body .ant-modal-confirm-body .ant-modal-confirm-content > div p {
  margin: 0;
  padding: 0;
  font: normal normal normal 16px/21px Roboto;
}
.feedback-status-modal .ant-modal-content .ant-modal-body .ant-modal-confirm-btns {
  display: none;
}
.heading-container {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
  align-items: center;
  margin-top: 40px;
  margin-bottom: 33px;
  gap: 30px;
}
.heading-container img {
  height: -moz-max-content;
  height: max-content;
}
.heading-container .usdt-weight-title {
  color: #ffe4c4;
  font-size: 35px;
  font-weight: 300;
  margin: 0;
}
.down-wrap {
  min-height: 800px;
  background: url(/img/down/bg.jpg) center top no-repeat;
  background-size: cover;
}
.down-wrap .dow-distance {
  text-align: center;
}
.down-wrap .dow-distance .content {
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  align-items: center;
}
.down-wrap .dow-distance .content .hero-banner {
  width: 100%;
  grid-column: 1 / span 2;
}
.down-wrap .dow-distance .content .app-lists-box {
  width: 100%;
}
.down-wrap .dow-distance .content .app-lists-box .app-list-box {
  padding: 57px 0px;
}
.down-wrap .dow-distance .content .app-lists-box .app-list-box:nth-child(even) {
  background-color: #fff;
}
.down-wrap .dow-distance .content .app-lists-box .app-list-box:nth-child(even) .app-item {
  grid-template-columns: 40fr 60fr;
}
.down-wrap .dow-distance .content .app-lists-box .app-list-box:nth-child(even) .app-item .img-app-demo {
  margin-left: 12%;
}
.down-wrap .dow-distance .content .app-lists-box .app-list-box .app-item {
  display: grid;
  grid-template-columns: 55fr 45fr;
  width: 70%;
  margin: auto;
}
.down-wrap .dow-distance .content .app-lists-box .app-list-box .app-item .img-app-demo {
  width: 100%;
}
.down-wrap .dow-distance .content .app-lists-box .app-list-box .app-item .text-box {
  align-self: center;
}
.down-wrap .dow-distance .content .app-lists-box .app-list-box .app-item .text-box h1 {
  color: #00a6ff;
  font: bold 40px/3rem "Roboto", Arial, sans-serif;
  margin-bottom: 18px;
}
.down-wrap .dow-distance .content .app-lists-box .app-list-box .app-item .text-box p {
  font: 14px/21px "Roboto", Arial, sans-serif;
  margin: 0;
}
.down-wrap .dow-distance .content .app-lists-box .app-list-box .app-item .text-box .app-description {
  text-align: left;
  width: 75%;
  margin: auto;
}
.down-wrap .dow-distance .content .app-lists-box .app-list-box .app-item .text-box .qr-box {
  background-color: #fff;
  width: 160px;
  height: 160px;
  margin: 21px auto;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
}
.drawer-wrap {
  position: fixed;
  top: 0;
  left: 0;
  width: 0%;
  height: 0%;
  transition: width 0s ease 0.3s, height 0s ease 0.3s;
  z-index: 1001;
}
.drawer-wrap.drawer-open.learn {
  z-index: 1004;
  pointer-events: none;
}
.drawer-wrap.drawer-open.learn .drawer-mask {
  background-color: transparent;
}
.drawer-wrap .drawer-content-wrapper {
  position: fixed;
  top: 0;
  right: 0;
  width: 300px;
  height: 100%;
  z-index: 199;
  overflow: auto;
  background-color: #fff;
  transform: translate(100%, 0);
  transition: transform 0.3s cubic-bezier(0.9, 0, 0.3, 0.7), box-shadow 0.3s cubic-bezier(0.9, 0, 0.3, 0.7);
}
.drawer-wrap.drawer-open .drawer-content-wrapper {
  overflow: visible;
}
.drawer-content-wrapper .drawer-content {
  height: 100%;
  overflow: auto;
  padding-bottom: 60x;
  background-color: #000000;
}
.drawer-wrap .drawer-mask {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 0%;
  background-color: rgba(0, 0, 0, 0.65);
  opacity: 0;
  z-index: 99;
  filter: alpha(opacity=50);
  transition: opacity 0.3s linear, height 0s ease 0.3s;
}
.drawer-wrap.drawer-open {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: none;
}
.drawer-wrap.drawer-open .drawer-mask {
  height: 100%;
  opacity: 0.5;
  transition: none;
  animation: antdDrawerFadeIn 0.3s cubic-bezier(0.7, 0.3, 0.1, 1);
}
.drawer-wrap.drawer-open .drawer-content-wrapper {
  transform: translate(0, 0);
  box-shadow: -2px 0 8px rgba(0, 0, 0, 0.15);
}
.games-slider {
  width: 30px;
  height: 100px;
  background-color: rgba(255, 255, 255, 0.2);
  position: absolute;
  right: 300px;
  top: 0;
  bottom: 0;
  margin: auto;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  background-image: url("/vn/img/home/part/arrow-to-right.png");
  background-position: 0px center;
  opacity: 0.6;
  cursor: pointer;
  transition: all 0.3s;
  background-size: 100%;
  background-repeat: no-repeat;
}
.games-slider.inner:hover {
  opacity: 0.8;
  right: 300px;
}
@keyframes antdDrawerFadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 0.5;
  }
}
.footer-platform-wrap {
  background-color: #eeeff0;
  padding-top: 36px;
  padding-bottom: 36px;
  background-image: linear-gradient(0deg, #eeeff0, #f8f9fa);
}
.footer-platform-wrap .ant-select {
  font-size: 12px;
}
.footer-platform-wrap .ant-select-selector {
  margin-top: 2px;
  border-radius: 6px !important;
  padding-left: 34px !important;
  background-image: url("/vn/img/home/sprite20220315.png");
  background-repeat: no-repeat;
  background-position: -285px -288px;
}
.footer-platform-wrap h5 {
  color: #666666;
  font-size: 12px;
  font-weight: bold;
  margin-bottom: 10px;
}
.footer-platform-wrap .footer-platform {
  padding-bottom: 20px;
  margin-bottom: 30px;
  border-bottom: 1px solid #cccccc;
}
.footer-platform-wrap .icon-list {
  line-height: 26px;
  display: block;
}
.footer-platform-wrap .icon-list.pay-kind,
.footer-platform-wrap .icon-list.about-we,
.footer-platform-wrap .icon-list.regulation {
  display: block;
  /* override promotions.less display:flex */
}
.footer-platform-wrap .icon-item1 {
  margin: 5px 30px 5px 0;
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  cursor: pointer;
}
.footer-platform-wrap .icon-item1._1 {
  width: 56px;
  height: 16px;
  background-position: -10px -46px;
}
.footer-platform-wrap .icon-item1._1:hover {
  background-position: -10px -10px;
}
.footer-platform-wrap .icon-item1._2 {
  width: 62px;
  height: 20px;
  background-position: -10px -228px;
}
.footer-platform-wrap .icon-item1._2:hover {
  background-position: -10px -350px;
}
.footer-platform-wrap .icon-item1._3 {
  width: 54px;
  height: 16px;
  background-position: -10px -118px;
}
.footer-platform-wrap .icon-item1._3:hover {
  background-position: -10px -82px;
}
.footer-platform-wrap .icon-item1._4 {
  width: 59px;
  height: 21px;
  background-position: -10px -309px;
}
.footer-platform-wrap .icon-item1._4:hover {
  background-position: -10px -268px;
}
.footer-platform-wrap .icon-item1._5 {
  width: 35px;
  height: 24px;
  background-position: -10px -436px;
}
.footer-platform-wrap .icon-item1._5:hover {
  background-position: -10px -392px;
}
.footer-platform-wrap .icon-item1._6 {
  width: 55px;
  height: 17px;
  background-position: -10px -191px;
}
.footer-platform-wrap .icon-item1._6:hover {
  background-position: -10px -154px;
}
.footer-platform-wrap .icon-item2 {
  margin-right: 10px;
  vertical-align: middle;
  display: inline-block;
  cursor: pointer;
  background-repeat: no-repeat;
  background-size: 47px 408px;
}
.footer-platform-wrap .icon-item2._7 {
  width: 24px;
  height: 21px;
  background-position: -10px -213px;
}
.footer-platform-wrap .icon-item2._7:hover {
  background-position: -10px -377px;
}
.footer-platform-wrap .icon-item2._8 {
  width: 27px;
  height: 21px;
  background-position: -10px -131px;
}
.footer-platform-wrap .icon-item2._8:hover {
  background-position: -10px -336px;
}
.footer-platform-wrap .icon-item2._9 {
  width: 23px;
  height: 21px;
  background-position: -10px -254px;
}
.footer-platform-wrap .icon-item2._9:hover {
  background-position: -10px -295px;
}
.footer-platform-wrap .icon-item2._10 {
  width: 20px;
  height: 20px;
  background-position: -10px -10px;
}
.footer-platform-wrap .icon-item2._10:hover {
  background-position: -10px -50px;
}
.footer-platform-wrap .icon-item2._11 {
  width: 26px;
  height: 21px;
  background-position: -10px -90px;
}
.footer-platform-wrap .icon-item2._11:hover {
  background-position: -10px -172px;
}
.footer-platform-wrap .icon-item3 {
  margin-right: 20px;
  vertical-align: middle;
  display: inline-block;
  cursor: pointer;
  background-repeat: no-repeat;
  background-size: 147px 274px;
}
.footer-platform-wrap .icon-item3._12 {
  width: 24px;
  height: 26px;
  background-position: -10px -192px;
}
.footer-platform-wrap .icon-item3._12:hover {
  background-position: -10px -146px;
}
.footer-platform-wrap .icon-item3._13 {
  width: 27px;
  height: 26px;
  background-position: -10px -238px;
}
.footer-platform-wrap .icon-item3._13:hover {
  background-position: -10px -100px;
}
.footer-platform-wrap .icon-item3._14 {
  width: 127px;
  height: 25px;
  background-position: -10px -55px;
}
.footer-platform-wrap .icon-item3._14:hover {
  background-position: -10px -10px;
}
.footer-platform-wrap .select-language-box {
  width: 90%;
  height: 30px;
}
.footer-platform-wrap .select-language-box.ant-select-lg .ant-select-selection--single {
  height: 30px;
  border-radius: 15px;
}
.footer-platform-wrap .select-language-box .ant-select-selection__rendered {
  position: relative;
  height: 30px;
  line-height: 30px;
}
.footer-platform-wrap .select-language-box .ant-select-selection__rendered img {
  width: 18px;
  height: auto;
}
.footer-platform-wrap .select-language-box .ant-select-selection__rendered span {
  margin-left: 5px;
}
.footer-platform-wrap .select-language-box .ant-select-selection__rendered .ant-select-selection-selected-value {
  margin-left: 23px;
}
.footer-platform-wrap .select-language-box .ant-select-selection__rendered .ant-select-selection-selected-value::before {
  content: "";
  width: 18px;
  height: 18px;
  background: url("/vn/img/footer/language/Vietnam.svg") 0 center no-repeat;
  background-size: cover;
  position: absolute;
  left: 0;
  top: 5px;
}
.footer-platform-wrap .select-language-box .ant-select-arrow {
  top: 40%;
}
.footer-platform-wrap .select-language-box .ant-select-arrow img {
  width: 22px;
  height: 22px;
}
.footer-platform-wrap .select-language-box.Thailand .ant-select-selection-selected-value {
  margin-left: 0px;
}
.footer-platform-wrap .select-language-box.Thailand .ant-select-selection-selected-value::before {
  background: url("/vn/img/footer/language/Thailand.svg") 0 center no-repeat;
}
.footer-platform-wrap .select-language-box.China .ant-select-selection-selected-value {
  margin-left: 0px;
}
.footer-platform-wrap .select-language-box.China .ant-select-selection-selected-value::before {
  background: url("/vn/img/footer/language/China.svg") 0 center no-repeat;
}
.footer-platform-wrap .select-language-box.Vietnam .ant-select-selection-selected-value {
  margin-left: 0px;
}
.footer-platform-wrap .select-language-box.Vietnam .ant-select-selection-selected-value::before {
  background: url("/vn/img/footer/language/Vietnam.svg") 0 center no-repeat;
}
.footer-small-drop .ant-select-dropdown {
  padding: 0;
}
.footer-small-drop .ant-select-item {
  min-height: 22px;
  font-size: 12px;
}
.footer-small-drop .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  padding-top: 0;
  padding-bottom: 0;
}
.footer-small-drop .ant-select-dropdown-menu .ant-select-dropdown-menu-item {
  text-align: left;
  border-bottom: 1px solid #E0E0E0;
}
.footer-small-drop .ant-select-dropdown-menu .ant-select-dropdown-menu-item img {
  width: 18px;
  height: auto;
}
.footer-small-drop .ant-select-dropdown-menu .ant-select-dropdown-menu-item span {
  margin-left: 5px;
}
.footer-small-drop .ant-select-dropdown-menu .ant-select-dropdown-menu-item:last-child {
  border: 0;
}
.footer-copyright-wrap {
  background-color: #00a6ff;
  font-size: 12px;
  color: #fff;
  padding: 20px 0;
}
.footer-copyright-wrap .copyright-picture {
  padding: 10px 0;
}
.footer-copyright-wrap .copyright-picture img {
  width: 122px;
}
.footer-copyright-wrap .copyright-article {
  padding: 16px 0;
}
.footer-copyright-wrap .copyright-content {
  margin: 0;
  text-align: center;
}
.footer-copyright-wrap .copyright-content p {
  margin: 0;
}
.ant-modal.tlc-wechat-modal._white .ant-modal-content {
  background-color: #fff;
  color: #000;
}
.ant-modal.tlc-wechat-modal._white .ant-modal-content .ant-modal-close {
  color: #000;
}
.ant-modal.tlc-wechat-modal .ant-modal-content {
  background-color: #000;
  color: #fff;
}
.ant-modal.tlc-wechat-modal .ant-modal-content .ant-modal-body {
  padding: 50px;
}
.ant-modal.tlc-wechat-modal .ant-modal-content .ant-modal-body > img {
  width: 100%;
  margin-top: 10px;
}
.ant-modal.tlc-wechat-modal .ant-modal-content .ant-modal-body > p {
  margin-bottom: 0.2em;
}
.ant-modal.tlc-wechat-modal .ant-modal-content .ant-modal-close {
  color: #fff;
}
.ant-popover.footer-popover .ant-popover-inner {
  border-radius: 4px;
}
.ant-popover.footer-popover .ant-popover-inner-content {
  padding: 6px;
  line-height: 20px;
  text-align: center;
}
.ant-popover.footer-popover.aboutUS .ant-popover-inner-content {
  width: 120px;
}
.tlc-container-wrapper-Diamond .tlc-sign-header .notice-wrap .marquee-container-wrapper .marquee-title span {
  color: #fff !important;
}
.tlc-container-wrapper-Diamond .tlc-sign-header .notice-wrap .marquee-container-wrapper .marquee-container .tlc-notice-wrap {
  background-color: transparent !important;
  color: #fff;
}
.tlc-container-wrapper-Diamond .tlc-sign-header .login-wrap .good-greeting {
  color: #fff;
}
.tlc-container-wrapper-Diamond .diamond-club-tob-tab .ant-tabs-nav-container .ant-tabs-nav-wrap .ant-tabs-nav-scroll {
  border-radius: 0px;
  border-bottom: 2px solid #e0e0e0;
}
.tlc-container-wrapper-Diamond .diamond-club-tob-tab .ant-tabs-nav-container .ant-tabs-nav-wrap .ant-tabs-nav-scroll .ant-tabs-nav {
  position: static;
  position: initial;
}
.tlc-container-wrapper-Diamond .diamond-club-tob-tab .ant-tabs-nav-container .ant-tabs-nav-wrap .ant-tabs-nav-scroll .ant-tabs-nav .ant-tabs-tab:hover {
  color: #c7c7c7;
}
.tlc-container-wrapper-Diamond .diamond-club-tob-tab .ant-tabs-nav-container .ant-tabs-nav-wrap .ant-tabs-nav-scroll .ant-tabs-nav .ant-tabs-tab-active {
  color: #c5af5f !important;
}
.tlc-container-wrapper-Diamond .diamond-club-tob-tab .redirct-background {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: flex-end;
}
.tlc-container-wrapper-Diamond .diamond-club-tob-tab .redirct-background .redirct-btn {
  background-color: transparent;
  width: 90px;
  height: 30px;
  position: absolute;
  top: 70%;
  border: none;
  transform: translateX(-50%);
}
.tlc-container-wrapper-Diamond .diamond-club-tob-tab .redirct-background .left-btn {
  left: 16%;
}
.tlc-container-wrapper-Diamond .diamond-club-tob-tab .redirct-background .middle-btn {
  left: 49%;
}
.tlc-container-wrapper-Diamond .diamond-club-tob-tab .redirct-background .right-btn {
  left: 82%;
}
.tlc-container-wrapper-Diamond .diamond-club-tob-tab .redirct-background .button {
  width: 120px;
  position: absolute;
  top: 545px;
  left: 23%;
}
.tlc-container-wrapper-Diamond .tlc-about {
  padding: 0;
}
.tlc-container-wrapper-Diamond .ant-tabs-bar {
  margin-bottom: 40px;
}
.tlc-container-wrapper-Diamond .ant-tabs.border-tabs .ant-tabs-nav-container::after {
  display: block;
  height: 2px;
  background: #E0E0E0;
}
.tlc-container-wrapper-Diamond .ant-tabs.border-tabs .ant-tabs-tab-active {
  color: #c5af5f !important;
  background-color: transparent !important;
}
.tlc-container-wrapper-Diamond .ant-tabs.border-tabs .ant-tabs-ink-bar {
  bottom: 0;
  background-color: #c5af5f;
}
.tlc-container-wrapper-Diamond .ant-tabs.border-tabs hr {
  border: none;
  height: 1px;
  background: #393B40;
  margin-top: 40px;
}
.tlc-container-wrapper-Diamond caption,
.tlc-container-wrapper-Diamond .FaqTitle,
.tlc-container-wrapper-Diamond h3 {
  color: #c5af5f;
  caption-side: top;
  font-size: 15px;
  padding: 15px 0 14px 0;
  font-weight: bold;
}
.tlc-container-wrapper-Diamond .ant-tabs {
  max-width: 1000px;
  margin: 0 auto;
}
.tlc-container-wrapper-Diamond .ViPLevel {
  min-height: 100vh;
  position: relative;
}
.tlc-container-wrapper-Diamond .ViPLevel caption,
.tlc-container-wrapper-Diamond .ViPLevel h3 {
  padding: 0 0 24px 0;
}
.tlc-container-wrapper-Diamond .ViPLevel .swiper-wrapper {
  display: flex;
  width: 100%;
}
.tlc-container-wrapper-Diamond .ViPLevel .normal-member-box {
  margin-bottom: 60px;
}
.tlc-container-wrapper-Diamond .ViPLevel .normal-member-box .diamond-slider {
  margin-bottom: 24px;
}
.tlc-container-wrapper-Diamond .ViPLevel .normal-member-box .rule {
  color: #f6f6f6;
  font-size: 12px;
}
.tlc-container-wrapper-Diamond .ViPLevel .normal-member-box .rule table tbody tr:not(:first-child) {
  display: block;
  margin-bottom: 10px;
}
.tlc-container-wrapper-Diamond .ViPLevel .vip-member-box {
  margin-bottom: 60px;
}
.tlc-container-wrapper-Diamond .ViPLevel .vip-right-table {
  margin-bottom: 24px;
}
.tlc-container-wrapper-Diamond .vip-right-condition {
  margin-bottom: 2.5rem;
}
.tlc-container-wrapper-Diamond .diamond-club-tob-tab .vip-member-level-tabs .ant-tabs-bar div {
  display: flex;
  padding-left: 0 !important;
  font-size: 14px;
  align-items: center;
}
.tlc-container-wrapper-Diamond .diamond-club-tob-tab .vip-member-level-tabs .ant-tabs-bar div img {
  margin-right: 10px;
  width: 20px;
  height: 20px;
}
.tlc-container-wrapper-Diamond .diamond-club-tob-tab .vip-member-level-tabs .ant-tabs-bar,
.tlc-container-wrapper-Diamond .diamond-club-tob-tab .vip-member-level-tabs .ant-tabs-nav-scroll {
  border-bottom: none !important;
}
.tlc-container-wrapper-Diamond .diamond-club-tob-tab .vip-member-level-tabs .ant-tabs-nav-container::after {
  background: none !important;
}
.tlc-container-wrapper-Diamond .diamond-club-tob-tab .vip-member-level-tabs .ant-tabs-ink-bar {
  height: 3px !important;
  width: 30px !important;
}
.tlc-container-wrapper-Diamond .diamond-club-tob-tab .vip-member-level-tabs-normal .ant-tabs-nav-container .ant-tabs-nav .ant-tabs-tab-active,
.tlc-container-wrapper-Diamond .diamond-club-tob-tab .vip-member-level-tabs-normal .ant-tabs-nav-container .ant-tabs-nav-wrap .ant-tabs-nav-scroll .ant-tabs-nav .ant-tabs-tab-active {
  color: #fff !important;
}
.tlc-container-wrapper-Diamond .diamond-club-tob-tab .vip-member-level-tabs-normal .ant-tabs-ink-bar {
  background-color: #fff !important;
  transform: translateX(240%) !important;
}
.tlc-container-wrapper-Diamond .diamond-club-tob-tab .vip-member-level-tabs-star .ant-tabs-nav-container .ant-tabs-nav .ant-tabs-tab-active,
.tlc-container-wrapper-Diamond .diamond-club-tob-tab .vip-member-level-tabs-star .ant-tabs-nav-container .ant-tabs-nav-wrap .ant-tabs-nav-scroll .ant-tabs-nav .ant-tabs-tab-active {
  color: #9B9EEC !important;
}
.tlc-container-wrapper-Diamond .diamond-club-tob-tab .vip-member-level-tabs-star .ant-tabs-ink-bar {
  background-color: #9B9EEC !important;
  transform: translateX(750%) !important;
}
.tlc-container-wrapper-Diamond .diamond-club-tob-tab .vip-member-level-tabs-vip .ant-tabs-nav-container .ant-tabs-nav .ant-tabs-tab-active,
.tlc-container-wrapper-Diamond .diamond-club-tob-tab .vip-member-level-tabs-vip .ant-tabs-nav-container .ant-tabs-nav-wrap .ant-tabs-nav-scroll .ant-tabs-nav .ant-tabs-tab-active {
  color: #B29E57 !important;
}
.tlc-container-wrapper-Diamond .diamond-club-tob-tab .vip-member-level-tabs-vip .ant-tabs-ink-bar {
  background-color: #B29E57 !important;
  transform: translateX(1230%) !important;
}
.tlc-container-wrapper-Diamond .vip-detail-container .score-box,
.tlc-container-wrapper-Diamond .ant-tabs-tabpane .score-box {
  width: 100%;
  display: flex;
  gap: 52px;
  margin-bottom: 42px;
}
.tlc-container-wrapper-Diamond .vip-detail-container .score-box > div,
.tlc-container-wrapper-Diamond .ant-tabs-tabpane .score-box > div {
  width: 100%;
}
.tlc-container-wrapper-Diamond .vip-detail-container .score-box.slotAtCenter,
.tlc-container-wrapper-Diamond .ant-tabs-tabpane .score-box.slotAtCenter {
  width: 100%;
  margin: 1rem 0;
}
.tlc-container-wrapper-Diamond .vip-detail-container .score-box.slotAtCenter h3,
.tlc-container-wrapper-Diamond .ant-tabs-tabpane .score-box.slotAtCenter h3 {
  text-align: left;
}
.tlc-container-wrapper-Diamond .vip-detail-container .faq-box,
.tlc-container-wrapper-Diamond .ant-tabs-tabpane .faq-box {
  color: white;
  margin-bottom: 20px;
}
.tlc-container-wrapper-Diamond .vip-detail-container .faq-box .rc-collapse,
.tlc-container-wrapper-Diamond .ant-tabs-tabpane .faq-box .rc-collapse {
  background-color: #17191c;
  border: 0;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 52px;
  -moz-column-gap: 52px;
       column-gap: 52px;
  grid-row-gap: 10px;
  row-gap: 10px;
}
.tlc-container-wrapper-Diamond .vip-detail-container .faq-box .rc-collapse .rc-collapse-item,
.tlc-container-wrapper-Diamond .ant-tabs-tabpane .faq-box .rc-collapse .rc-collapse-item {
  background-color: #2d2f33;
  padding: 13px 15px;
  border-radius: 10px;
  width: 100%;
  height: -moz-fit-content;
  height: fit-content;
}
.tlc-container-wrapper-Diamond .vip-detail-container .faq-box .rc-collapse .rc-collapse-item .rc-collapse-header,
.tlc-container-wrapper-Diamond .ant-tabs-tabpane .faq-box .rc-collapse .rc-collapse-item .rc-collapse-header {
  color: #d6d1c2;
  font-size: 14px;
  line-height: 19px;
  cursor: default;
  position: relative;
  width: 100%;
}
.tlc-container-wrapper-Diamond .vip-detail-container .faq-box .rc-collapse .rc-collapse-item .rc-collapse-header .rc-collapse-expand-icon,
.tlc-container-wrapper-Diamond .ant-tabs-tabpane .faq-box .rc-collapse .rc-collapse-item .rc-collapse-header .rc-collapse-expand-icon {
  position: absolute;
  right: 0;
  top: 0;
  cursor: pointer;
}
.tlc-container-wrapper-Diamond .vip-detail-container .faq-box .rc-collapse .rc-collapse-item .rc-collapse-header .rc-collapse-expand-icon svg,
.tlc-container-wrapper-Diamond .ant-tabs-tabpane .faq-box .rc-collapse .rc-collapse-item .rc-collapse-header .rc-collapse-expand-icon svg {
  color: #fff;
  width: 20px;
  height: 20px;
}
.tlc-container-wrapper-Diamond .vip-detail-container .faq-box .rc-collapse .rc-collapse-item .rc-collapse-header .rc-collapse-header-text,
.tlc-container-wrapper-Diamond .ant-tabs-tabpane .faq-box .rc-collapse .rc-collapse-item .rc-collapse-header .rc-collapse-header-text {
  display: inline-block;
  padding-right: 60px;
}
.tlc-container-wrapper-Diamond .vip-detail-container .faq-box .rc-collapse .rc-collapse-item .rc-collapse-content-box table,
.tlc-container-wrapper-Diamond .ant-tabs-tabpane .faq-box .rc-collapse .rc-collapse-item .rc-collapse-content-box table {
  width: -moz-fit-content !important;
  width: fit-content !important;
  max-width: 100%;
}
.tlc-container-wrapper-Diamond .vip-detail-container .faq-box .rc-collapse .rc-collapse-item .rc-collapse-content-box .FaqContent,
.tlc-container-wrapper-Diamond .ant-tabs-tabpane .faq-box .rc-collapse .rc-collapse-item .rc-collapse-content-box .FaqContent {
  background-color: #2d2f33;
  padding: 20px 24px 0px 0px;
  color: #d6d1c2;
  font-weight: 300;
}
.tlc-container-wrapper-Diamond .vip-detail-container .faq-box .rc-collapse .rc-collapse-item .rc-collapse-content-box .FaqContent *,
.tlc-container-wrapper-Diamond .ant-tabs-tabpane .faq-box .rc-collapse .rc-collapse-item .rc-collapse-content-box .FaqContent * {
  max-width: 100%;
}
.tlc-container-wrapper-Diamond .vip-detail-container .faq-box .rc-collapse .rc-collapse-item .rc-collapse-content-box .FaqContent p,
.tlc-container-wrapper-Diamond .ant-tabs-tabpane .faq-box .rc-collapse .rc-collapse-item .rc-collapse-content-box .FaqContent p {
  font-size: 12px;
  line-height: 20px;
  margin: 0;
}
.tlc-container-wrapper-Diamond .vip-detail-container .rule-box,
.tlc-container-wrapper-Diamond .ant-tabs-tabpane .rule-box {
  margin-bottom: 2rem;
}
.tlc-container-wrapper-Diamond .vip-detail-container .rule-box .rule-list li,
.tlc-container-wrapper-Diamond .ant-tabs-tabpane .rule-box .rule-list li {
  color: #aba79d;
}
.tlc-container-wrapper-Diamond .vip-score-table .ant-table-content > .ant-table-body .ant-table-thead th,
.tlc-container-wrapper-Diamond .vip-score-table .ant-table-content > .ant-table-body .ant-table-tbody td {
  width: 25%;
  padding: 16px;
}
.tlc-container-wrapper-Diamond .vip-score-table .ant-table-content > .ant-table-body .ant-table-thead th .ant-table-column-title,
.tlc-container-wrapper-Diamond .vip-score-table .ant-table-content > .ant-table-body .ant-table-tbody td .ant-table-column-title {
  font-weight: normal;
}
.tlc-container-wrapper-Diamond .vip-score-table .ant-table-content > .ant-table-body .ant-table-thead th {
  border-left: 1px solid #393b40;
}
.tlc-container-wrapper-Diamond .vip-score-table .ant-table-content > .ant-table-body .ant-table-thead th:first-child {
  border-left: none;
}
.tlc-container-wrapper-Diamond .vip-score-table .ant-table-content > .ant-table-body .ant-table-tbody > tr > td p span {
  font-size: 12px;
}
.tlc-container-wrapper-Diamond .vip-score-table .ant-table-content > .ant-table-body .ant-table-tbody img {
  width: 12px !important;
  height: 12px !important;
}
.tlc-container-wrapper-Diamond .vip-score-table .ant-table-content > .ant-table-body:first-child .ant-table-tbody > tr > td {
  text-align: center;
  border-left: 1px solid #393b40;
}
.tlc-container-wrapper-Diamond .vip-score-table .ant-table-content > .ant-table-body:first-child .ant-table-tbody > tr > td:first-child {
  border-left: none;
  text-align: left;
}
.tlc-container-wrapper-Diamond .vip-gift-table .ant-table-thead > tr > th,
.tlc-container-wrapper-Diamond .vip-gift-table .ant-table-tbody > tr > td {
  width: 16.6667%;
  color: #aba79d;
  text-align: center;
}
.tlc-container-wrapper-Diamond .vip-gift-table th:first-child,
.tlc-container-wrapper-Diamond .vip-gift-table td:first-child {
  border-left: none;
}
.tlc-container-wrapper-Diamond .vip-gift-table th,
.tlc-container-wrapper-Diamond .vip-gift-table td {
  border-left: 1px solid #393b40;
}
.tlc-container-wrapper-Diamond .vip-gift-table .ant-table-tbody img {
  width: 12px !important;
  height: 12px !important;
}
.tlc-container-wrapper-Diamond .smallFontSize {
  font-size: 14px !important;
}
.tlc-container-wrapper-Diamond .withBorder {
  border-left: 1px solid #393b40 !important;
}
.tlc-container-wrapper-Diamond .aba79d {
  color: #aba79d !important;
  width: 20% !important;
  text-align: left !important;
}
.tlc-container-wrapper-Diamond .tableLeft {
  text-align: left !important;
  width: 22% !important;
}
.tlc-container-wrapper-Diamond .tip-color,
.tlc-container-wrapper-Diamond .tip-color > p {
  color: #C4B590 !important;
}
.ant-modal.cookiePopup .ant-modal-confirm-body .ant-modal-confirm-content {
  text-align: left !important;
}
.ifarme-learn-wrap {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1003;
}
.ifarme-learn-wrap .tlc-learn-shadow img {
  position: absolute;
  right: 40px;
  top: -66px;
  bottom: 0;
  margin: auto;
}
.ifarme-learn-wrap .tlc-learn-shadow._2 img {
  right: 360px;
}
.ifarme-learn-wrap .learn-knew {
  margin-top: 0;
  position: absolute;
  right: 528px;
  top: 180px;
  bottom: 0;
  margin: auto;
}
.ifarme-learn-wrap .next-knew {
  right: 216px;
  background-image: url("/vn/img/learn/next_step.png");
}
.tlc-user-info {
  color: #fff;
  padding: 20px 60px;
  background-color: #222;
}
.tlc-user-info .tlc-user-wallet-wrap {
  border: 0;
  padding: 0;
}
.tlc-user-info .tlc-user-picture-wrap {
  padding: 0;
}
.tlc-iframe-bar > li {
  line-height: 50px;
  height: 50px;
  color: #ddd;
  cursor: pointer;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.tlc-iframe-bar > li:hover,
.tlc-iframe-bar > li:active {
  background-color: #121212;
  color: #ffee00;
}
.tlc-iframe-live-btn {
  position: fixed;
  text-align: center;
  left: 0;
  right: 0;
  bottom: 12px;
  line-height: 32px;
  cursor: pointer;
}
.tlc-iframe-live-btn .live-service {
  width: 32px;
  height: 32px;
  display: inline-block;
  vertical-align: middle;
}
.tlc-iframe-live-btn > span {
  color: #ddd;
  font-size: 16px;
  vertical-align: middle;
}
.tlc-user-wallet-wrap.draw .tlc-user-wallet {
  width: 150px;
  margin-right: 10px;
  display: inline-block;
  vertical-align: middle;
}
.tlc-user-wallet-wrap.draw > .inline-block {
  cursor: pointer;
  font-size: 16px;
  height: 16px;
  line-height: 16px;
  vertical-align: middle;
}
.tlc-user-wallet-wrap.draw .tlc-all-balance {
  margin: 0;
}
iframe {
  max-width: 1400px;
  margin: 0 auto;
}
.ant-modal-confirm .ant-modal-confirm-btns {
  text-align: center;
}
.small-screen {
  width: 64px;
  position: absolute;
  left: 0;
  right: 0;
  top: 10px;
  margin: auto;
}
.acreen-btn {
  color: #fff;
  width: 64px;
  height: 64px;
  line-height: 64px;
  text-align: center;
  border-radius: 50%;
  font-weight: bold;
  text-indent: -200px;
  overflow: hidden;
  margin-bottom: 15px;
  background-color: rgba(0, 0, 0, 0.2);
  background-repeat: no-repeat;
  background-position: center center;
  cursor: pointer;
}
.acreen-btn:hover {
  background-image: none !important;
  background-color: rgba(0, 0, 0, 0.4);
  text-indent: 0;
  text-indent: initial;
}
.acreen-btn.scale-large {
  background-image: url("/vn/img/game/iframe/entry_full_screen.png");
}
.acreen-btn.scale-small {
  background-image: url("/vn/img/game/iframe/exit_full_screen.png");
  background-color: rgba(255, 255, 255, 0.4);
  position: fixed;
  top: 20px;
  right: 20px;
  z-index: 1000;
}
.acreen-btn.page-back {
  background-image: url("/vn/img/game/iframe/game_back.png");
}
.acreen-btn.scale-small.drawer {
  right: 320px;
}
.games-slider {
  width: 40px;
  height: 100px;
  background-color: rgba(255, 255, 255, 0.2);
  position: absolute;
  right: -8px;
  top: 0;
  bottom: 0;
  margin: auto;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  background-image: url("/vn/vn/img/home/part/arrow-to-left.png");
  background-position: -4px center;
  opacity: 0.6;
  cursor: pointer;
  transition: all 0.3s;
  background-size: 100%;
  background-repeat: no-repeat;
}
.games-slider.learn {
  z-index: 1005;
  background-color: #909090;
  pointer-events: none;
}
.games-slider:hover {
  opacity: 0.8;
  right: 0px;
}
@media (max-width: 1498px) {
  .small-screen-default {
    position: fixed;
    right: 4px !important;
    top: 106px;
  }
}
@media (max-width: 1578px) {
  .small-screen-YBK {
    position: fixed;
    right: 4px !important;
    top: 106px;
  }
}
.top-svg {
  position: absolute;
  right: 0;
  width: 80px;
  height: 35px;
}
.list-content img {
  height: 100px;
}
.green {
  font-size: 12px;
  margin: 0px 10px;
  padding: 2px 5px;
  text-align: center;
  background: #6dd400 0% 0% no-repeat padding-box;
  border-radius: 2px;
}
.hot {
  font-size: 12px;
  margin-right: 10px;
  padding: 2px 5px;
  text-align: center;
  background: #fa6400 0% 0% no-repeat padding-box;
  border-radius: 2px;
}
.click-btn .open {
  color: #222222;
  width: 100%;
  font-size: 12px;
}
.click-btn .try {
  color: #f7b500;
  border-left: 1px solid #99999929;
}
.click-btn {
  display: flex;
  background: white;
  font: Bold 14px/14px Roboto;
  height: 36px;
  align-items: center;
}
.click-btn div {
  width: 50%;
  cursor: pointer;
}
.topbanner img {
  width: 100%;
  border-radius: 34px;
  padding: 0 20px;
}
.box-list {
  width: 100%;
  height: 100%;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 20px;
}
.box-list .active .DefaultTag {
  width: 60px !important;
  height: 32px !important;
  font-weight: normal !important;
}
.box-list .active .openGame:hover::before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.4);
  width: 100%;
  height: 215px;
  z-index: 2;
}
.box-list .active .openGame:hover::after {
  content: "Chơi Ngay";
  font-size: 14px;
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 96px;
  height: 44px;
  background: transparent linear-gradient(180deg, #79d1ff 0%, #00a6ff 100%) 0% 0% no-repeat padding-box;
  border-radius: 30px;
  z-index: 2;
}
.box-list .active .openGame .GameName {
  height: 79px;
  display: flex;
  align-items: center;
  font-size: 24px;
  line-height: 28px;
  white-space: normal;
  padding: 0 0 0 24px;
  z-index: 3;
  text-transform: capitalize;
}
.RecommendGames > div .openGame:hover::before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.4);
  width: 100%;
  height: 215px;
  z-index: 2;
}
.RecommendGames > div .openGame:hover::after {
  content: "Chơi Ngay";
  font-size: 14px;
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 96px;
  height: 44px;
  color: #fff;
  background: transparent linear-gradient(180deg, #79d1ff 0%, #00a6ff 100%) 0% 0% no-repeat padding-box;
  border-radius: 30px;
  z-index: 2;
}
.RecommendGames > div .openGame:hover .GameName {
  z-index: 3;
}
.box-list section {
  margin-bottom: 17px;
  margin-right: 15px;
  border-radius: 10px;
  background-color: white;
  border: 1px solid #ddd;
  color: #fff;
  font-size: 36px;
  text-transform: uppercase;
  overflow: hidden;
  position: relative;
}
.box-list section .title {
  position: absolute;
  left: 0;
  right: 0;
  margin: 0 auto;
  padding-left: 10px;
  min-height: 30px;
  bottom: 36px;
  font: Bold 14px/29px Roboto;
  background: rgba(0, 0, 0, 0.5);
  text-align: left;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.LiveCasino .active,
.P2P .active,
.Slot .active {
  height: 295px !important;
}
.LiveCasino section,
.P2P section,
.Slot section {
  height: 140px !important;
}
.list-content {
  position: relative;
  overflow: hidden;
  margin-right: -10px;
}
.menu-bar-children {
  position: relative;
}
.menu-bar-children .isHot::before {
  content: "";
  font-size: 18px;
  position: absolute;
  top: 3%;
  left: 7%;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  background: transparent;
  background-image: url("/vn/img/icon/hot.svg");
  z-index: 2;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}
.menu-bar-children .isNew::before {
  content: "";
  font-size: 18px;
  position: absolute;
  top: 3%;
  left: 7%;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  background: transparent;
  background-image: url("/vn/img/icon/new.svg");
  z-index: 2;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}
.list-content section {
  width: 165px;
  height: 137px;
  float: left;
  box-shadow: 0px 0px 3px #99999929;
}
.list-content .active {
  width: 349px;
  height: 290px;
}
.list-content section#VTG {
  width: 165px;
  height: 161px;
}
.list-content .active#VTG {
  width: 351px !important;
  height: 338px !important;
}
.list-content .active img {
  height: 215px;
}
.list-content .active .click-btn div {
  font: Bold 17px/17px Roboto;
}
.list-content .active .title {
  position: absolute;
  left: 0;
  right: 0;
  margin: 0 auto;
  bottom: 74px;
  font: Bold 24px/45px Roboto;
  text-align: left;
}
.list-content .active .click-btn {
  height: 72px;
}
.list-content .active .title .hot,
.list-content .active .title .green {
  font-size: 18px;
  vertical-align: middle;
}
.game-type {
  display: flex;
  flex-flow: wrap;
  margin-top: 15px;
  margin-bottom: 30px;
  justify-content: space-between;
}
.game-type li {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  min-height: 31px;
}
.game-type li .name {
  display: block;
  padding: 0px 3px;
}
.game-type .isNew::after {
  content: "NEW";
  display: block;
  border-radius: 4px;
  height: 18px;
  line-height: 18px;
  border: 1px solid #ff4141;
  background-color: #ff4141;
  position: absolute;
  right: -4px;
  top: -13px;
  color: white;
  font-size: 8px;
  padding: 0 2px;
}
.game-type .isHot::after {
  content: "HOT";
  display: block;
  border-radius: 4px;
  height: 18px;
  line-height: 18px;
  border: 1px solid #fdb454;
  background-color: #fdb454;
  position: absolute;
  right: -6px;
  top: -13px;
  color: white;
  font-size: 8px;
  padding: 0 2px;
}
.menu-list .game-type li.curr {
  background-color: #00a6ff;
  color: #fff;
  border: 1px solid #00a6ff;
}
.game-type li:hover {
  background-color: #00a6ff38;
  color: #00a6ff;
  border: 1px solid #00a6ff;
}
.game-type i {
  width: 24px;
  height: 18px;
  vertical-align: -0.28em;
  background-size: 100% auto;
  display: inline-block;
}
.game-type li.curr i.BGG {
  background-image: url("/vn/img/game/icon/Casino/BG-c.png");
}
.game-type li.curr i.GPI {
  background-image: url("/vn/img/game/icon/Casino/GP-c.png");
}
.game-type li.curr i.AGL {
  background-image: url("/vn/img/game/icon/Casino/AG-c.png");
}
.game-type li.curr i.GDL {
  background-image: url("/vn/img/game/icon/Casino/gd-c.png");
}
.game-type li.curr i.SAL {
  background-image: url("/vn/img/game/icon/Casino/SA-c.png");
}
.game-type li.curr i.ABT {
  background-image: url("/vn/img/game/icon/Casino/AB-c.png");
}
.game-type li.curr i.NLE {
  background-image: url("/vn/img/game/icon/Casino/N2-c.png");
}
.game-type li.curr i.YBL {
  background-image: url("/vn/img/game/icon/Casino/YBL-c.png");
}
.game-type li.curr i.EVO {
  background-image: url("/vn/img/game/icon/Casino/EVO-c.png");
}
.game-type li.curr i.MGSQF {
  background-image: url("/vn/img/game/icon/Slot/MGSQF_c.png");
}
.game-type li.curr i.PT {
  background-image: url("/vn/img/game/icon/Slot/PT_c.png");
}
.game-type li.curr i.TG {
  background-image: url("/vn/img/game/icon/Slot/TG_c.png");
}
.game-type li.curr i.DTG {
  background-image: url("/vn/img/game/icon/Slot/DTG_c.png");
}
.game-type li.curr i.SPG {
  background-image: url("/vn/img/game/icon/Slot/SPG_c.png");
}
.game-type li.curr i.CW {
  background-image: url("/vn/img/game/icon/Slot/CW_c.png");
}
.game-type li.curr i.BSG {
  background-image: url("/vn/img/game/icon/Slot/BSG_c.png");
}
.game-type i.BGG {
  background-image: url("/vn/img/game/icon/Casino/BG-n.png");
}
.game-type i.GPI {
  background-image: url("/vn/img/game/icon/Casino/GP-n.png");
}
.game-type i.AGL {
  background-image: url("/vn/img/game/icon/Casino/AG-n.png");
}
.game-type i.GDL {
  background-image: url("/vn/img/game/icon/Casino/gd-n.png");
}
.game-type i.SAL {
  background-image: url("/vn/img/game/icon/Casino/SA-n.png");
}
.game-type i.ABT {
  background-image: url("/vn/img/game/icon/Casino/AB-n.png");
}
.game-type i.NLE {
  background-image: url("/vn/img/game/icon/Casino/N2-n.png");
}
.game-type i.MGSQF {
  background-image: url("/vn/img/game/icon/Slot/MGSQF.png");
}
.game-type i.PT {
  background-image: url("/vn/img/game/icon/Slot/PT.png");
}
.game-type i.YBL {
  background-image: url("/vn/img/game/icon/Casino/YBL.png");
}
.game-type i.EVO {
  background-image: url("/vn/img/game/icon/Casino/EVO.png");
}
.game-type i.IMOPT {
  background-image: url("/vn/img/game/icon/Slot/PT.png");
}
.game-type i.TG {
  background-image: url("/vn/img/game/icon/Slot/TG.png");
}
.game-type i.DTG {
  background-image: url("/vn/img/game/icon/Slot/DTG.png");
}
.game-type i.SPG {
  background-image: url("/vn/img/game/icon/Slot/SPG.png");
}
.game-type i.CW {
  background-image: url("/vn/img/game/icon/Slot/CW.png");
}
.game-type i.BSG {
  background-image: url("/vn/img/game/icon/Slot/BSG.png");
}
.game-type i.PGS {
  background-image: url("/vn/img/game/icon/Slot/PGS.png");
  vertical-align: -2px;
  margin-right: 3px;
}
.game-type li {
  width: 47%;
  text-align: center;
  border: 1px solid #cccccc;
  border-radius: 5px;
  margin: 7px 0px;
  cursor: pointer;
  font-size: 12px;
  padding: 5px 0px;
  color: #999999;
}
.game-type li img {
  width: 25px;
}
.top-title {
  border-bottom: 1px solid #9e9e9e66;
  margin: 10px 10px 25px 10px;
}
.top-title h3 {
  color: #222222;
  font-weight: bold;
  font-size: 18px;
  margin-bottom: 15px;
}
.lobby-box-content {
  display: flex;
  justify-content: center;
}
.lobby-box-content.lobby-box-content_slot {
  padding-bottom: 30px;
}
.games_search svg {
  fill: #999999;
}
.games-menu {
  height: auto;
  width: 20%;
  padding: 10px 20px 0 0;
  position: relative;
}
.games-menu > .games-menu_search {
  height: 40px;
  border-radius: 10px;
  margin-bottom: 30px;
  box-shadow: 0px 0px 2px #00000029;
}
.games-menu > .games-menu_search .ant-input-wrapper {
  height: 40px;
}
.games-menu > .games-menu_search .ant-input-wrapper .ant-input {
  padding-left: 28px;
}
.games-menu > .games-menu_search .ant-input-wrapper .ant-input-search,
.games-menu > .games-menu_search .ant-input-wrapper .ant-input-search-button {
  height: 40px;
}
.games-menu > .games-menu_search .ant-input-wrapper .ant-input,
.games-menu > .games-menu_search .ant-input-wrapper .ant-input:hover {
  border: 2px solid #00a6ff;
  border-right-width: 0;
}
.games-menu > .games-menu_search .ant-input-wrapper .ant-input-search-button:hover {
  background-color: #00a6ff;
  border-color: transparent;
}
.games-content {
  min-height: 600px;
  width: 80%;
  position: relative;
}
.games-content h2 {
  padding: 0 10px;
}
.filterSlot {
  position: absolute;
  top: 10px;
  right: 10px;
  width: 120px;
  height: 32px;
}
.filterSlot .ant-select {
  width: 100%;
  height: 100%;
}
.filterSlot .ant-select .ant-select-selection {
  border-radius: 5px;
}
.filterSlot .ant-select .ant-select-selection .ant-select-selection__rendered {
  display: flex;
  justify-content: center;
}
.filterSlot .ant-select .ant-select-selection .ant-select-selection__rendered .ant-select-selection-selected-value {
  font-size: 12px;
}
.filterSlot .ant-select .ant-select-selection .ant-select-arrow-icon {
  position: relative;
  top: -2px;
}
.menu-list {
  height: auto;
  background: white;
  border-radius: 10px;
  padding: 30px 10px;
  box-shadow: 0px 0px 3px #00000029;
}
.menu-list.vtg-menu-list .game-type {
  margin-bottom: 0;
}
.menu-list h4 {
  font-weight: bold;
  color: #222;
}
.clear-search {
  display: flex;
  justify-content: center;
  align-items: center;
}
.clear-search div {
  color: #0091ff;
  cursor: pointer;
}
.ant-divider {
  margin: 10px 0 !important;
}
.games-content .ant-empty {
  width: 285px;
  margin: 120px auto;
}
.games-content .ant-empty-image {
  width: auto;
  height: 150px;
}
.games-content .ant-empty-image img {
  width: 135px;
  height: auto;
}
.PaginationBox {
  display: flex;
  justify-content: flex-start;
  padding-top: 15px;
}
.DefaultTag {
  top: 10px;
  left: 10px;
  width: 33px !important;
  height: 17px !important;
  color: white;
  position: absolute;
  font-size: 10px;
  font-weight: normal;
  z-index: 2;
}
.DefaultTag span {
  background-color: #00a6ff;
  width: 100%;
  display: flex;
  border-radius: 4px;
  height: 100%;
  align-items: center;
  justify-content: center;
}
.forGameLobby.small-option {
  overflow: hidden;
  border-radius: 5px;
}
.forGameLobby.small-option .ant-select-dropdown-menu {
  padding: 0;
}
.forGameLobby.small-option .ant-select-dropdown-menu .ant-select-dropdown-menu-item-selected {
  font-weight: normal;
  background-color: transparent;
}
.forGameLobby.small-option .ant-select-dropdown-menu .ant-select-dropdown-menu-item {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 32px;
  font-size: 12px;
}
.gameLobby-pagination .ant-pagination-prev a,
.gameLobby-pagination .ant-pagination-next a {
  background-color: transparent;
  border: 1px solid #e0e0e0;
  color: #222222;
  border-radius: 5px;
}
.gameLobby-pagination .ant-pagination-prev:hover a,
.gameLobby-pagination .ant-pagination-next:hover a,
.gameLobby-pagination .ant-pagination-prev:active a,
.gameLobby-pagination .ant-pagination-next:active a,
.gameLobby-pagination .ant-pagination-prev:focus a,
.gameLobby-pagination .ant-pagination-next:focus a {
  border: 1px solid #e0e0e0;
  color: #222222;
  border-radius: 5px;
}
.gameLobby-pagination .ant-pagination-item {
  background-color: transparent;
  border: 1px solid #e0e0e0;
  border-radius: 5px;
}
.gameLobby-pagination .ant-pagination-item a {
  color: #222222;
}
.gameLobby-pagination .ant-pagination-item:hover,
.gameLobby-pagination .ant-pagination-item:focus {
  border: 1px solid #ffffff;
  border-radius: 5px;
  background-color: #ffffff;
}
.gameLobby-pagination .ant-pagination-item:hover a,
.gameLobby-pagination .ant-pagination-item:focus a {
  color: #222222;
}
.gameLobby-pagination .ant-pagination-item-active {
  background-color: #ffffff;
  border: 1px solid #ffffff;
  border-radius: 5px;
}
.gameLobby-pagination .ant-pagination-item-active a {
  color: #222222;
}
.gameLobby-pagination .ant-pagination-jump-next .ant-pagination-item-container .ant-pagination-item-link-icon,
.gameLobby-pagination .ant-pagination-jump-prev .ant-pagination-item-container .ant-pagination-item-link-icon {
  color: #e0e0e0;
}
.gamesMaintopContainer {
  width: 1120px;
  margin: 34px auto 30px auto;
}
.submit_btn {
  background: #2994ff 0% 0% no-repeat padding-box;
  border-radius: 8px;
  width: 200px;
  height: 40px;
  color: white;
  border: 0;
  font-size: 15px;
  cursor: pointer;
}
.ant-collapse-borderless {
  background-color: #fafafa00;
}
.help-txt-box h1 {
  margin: 0;
  color: #222;
}
.help-txt-box center {
  color: #e91e63;
  font-weight: bold;
}
.help-txt-box {
  width: 680px;
  margin: 0 auto;
}
.top-banner {
  height: auto;
  box-shadow: 0px 4px 8px #00000014;
  border-radius: 20px;
  position: relative;
  min-height: 200px;
  overflow: hidden;
}
.top-banner-txt {
  position: absolute;
  top: 40%;
  margin: 0 auto;
  left: 0;
  right: 0;
  color: white;
}
h1 {
  color: white;
}
.game-box-content {
  padding: 29px 0 0 0;
  text-align: center;
  margin-bottom: 100px;
}
.down-banner {
  margin: 45px 15px;
  background-image: linear-gradient(14deg, #d9d9d9 0%, #ffffff 50%, #ffffff 100%);
  margin-top: 45px;
  border-radius: 20px;
  height: 332px;
}
.content-down-banner {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 332px;
  background-position: center;
  position: relative;
}
.content-down-banner::after {
  content: "";
  display: block;
  width: 0;
  height: 0;
  border: 113px solid;
  border-color: transparent transparent #ffffffc2;
  position: absolute;
  left: 320px;
  bottom: 0;
}
.content-down-banner::before {
  content: "";
  display: block;
  width: 0;
  height: 0;
  border: 113px solid;
  border-color: transparent transparent #ffffffc2;
  position: absolute;
  left: 0;
  top: -136px;
  right: 0;
  margin: 0 auto;
}
.content-down-banner center {
  position: absolute;
  top: 15px;
  z-index: 9;
}
.content-down-banner h4 {
  color: #00a6ff;
  font-weight: bold;
}
.content-down-banner h2 {
  margin: 0;
  color: #222;
}
.content-down-banner .left {
  width: 300px;
  text-align: left;
  color: #222222;
  margin: 0 50px;
}
.content-down-banner .right {
  width: 450px;
  text-align: left;
  color: #222222;
  background: #ffffffbf 0% 0% no-repeat padding-box;
  height: 111px;
  margin: 0 10px;
}
.content-down-banner .right p {
  text-align: center;
  padding-top: 10px;
  margin: 0;
  line-height: 40px;
}
.content-down-banner .right .icon {
  display: flex;
  justify-content: center;
  align-items: center;
}
.content-down-banner .right .icon li {
  width: 33.3333%;
  text-align: center;
  font-size: 12px;
}
.content-down-banner .right .icon li p {
  padding: 0;
  line-height: 30px;
}
.content-down-banner .right .icon .number {
  font-size: 25px;
  font-weight: bold;
  color: #00a6ff;
}
.content-down-banner .right .icon .number p {
  padding: 0;
  font-size: 12px;
  font-weight: 500;
  color: #222;
}
.content-down-banner .right .icon .number span {
  font-size: 14px;
}
.live-item {
  margin-bottom: 29px;
}
.ant-collapse-icon-position-right > .ant-collapse-item > .ant-collapse-header {
  padding: 12px 16px;
  padding-right: 40px;
}
.ant-collapse-icon-position-right > .ant-collapse-item > .ant-collapse-header {
  text-align: left;
}
.ant-collapse-borderless {
  border: 0;
  background-color: transparent;
}
.ant-collapse-content-box {
  text-align: left;
}
.Gameslobby {
  padding: 30px 0px;
  min-height: 300px;
}
.Gameslobby .Title {
  text-align: left;
  border-left: 4px solid #00a6ff;
  padding-left: 10px;
  margin: 15px 0px;
  font-size: 20px;
  color: #222;
}
.GamesHome .Top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #00a6ff;
  cursor: pointer;
}
.GamesHome #InstantGames .item:first-child {
  height: 353px;
  width: 370px;
}
.GamesHome #InstantGames .item {
  width: 177px;
  height: 169px;
}
.GamesHome #P2P .item:first-child {
  height: 303px;
  width: 370px;
}
.GamesHome #P2P .item {
  width: 177px;
  height: 145px;
}
.GamesHome #LiveCasino .item:first-child {
  height: 303px;
  width: 370px;
}
.GamesHome #LiveCasino .item {
  width: 177px;
  height: 145px;
}
.GamesHome #Slot .item:first-child {
  height: 303px;
  width: 370px;
}
.GamesHome #Slot .item {
  width: 177px;
  height: 145px;
}
.GamesHome #KenoLottery .item:first-child {
  height: 303px;
  width: 370px;
}
.GamesHome #KenoLottery .item {
  width: 177px;
  height: 145px;
}
.GamesHome .RecommendGames {
  height: 290px;
  margin-bottom: 35px;
}
.GamesHome .RecommendGames .item:first-child {
  height: 290px;
  width: 349px;
}
.GamesHome .RecommendGames .item:first-child .openGame .DefaultTag {
  width: 60px !important;
  height: 32px !important;
  font-size: 14px;
}
.GamesHome .RecommendGames .item:first-child .openGame:hover::before {
  height: 73%;
}
.GamesHome .RecommendGames .item:first-child .openGame:hover::after {
  font-size: 14px;
  width: 96px;
  height: 44px;
}
.GamesHome .RecommendGames .item:first-child .openGame .GameName {
  height: 79px;
  display: flex;
  align-items: center;
  font-size: 24px;
  padding: 0 0 0 24px;
}
.GamesHome .RecommendGames .item {
  width: 180px;
  height: 151px;
  float: left;
  margin: 10px 10px 5px 0px;
  border-radius: 10px;
  overflow: hidden;
}
.GamesHome .RecommendGames .item:nth-child(5),
.GamesHome .RecommendGames .item:last-child {
  margin-right: 0;
}
.GamesHome .JackpotGamesContainer {
  position: relative;
  overflow: hidden;
  margin-bottom: 50px;
}
.GamesHome .JackpotGamesContainer::before {
  content: "";
  display: block;
  height: 100%;
  width: 200px;
  position: absolute;
  left: 920px;
  background: transparent linear-gradient(90deg, #ffffff00 0%, #f2f4f5 80%) 0% 0% no-repeat padding-box;
  z-index: 1;
}
.GamesHome .JackpotGamesContainer .JackpotGames {
  display: flex;
  position: relative;
  left: 0;
  transition: left 0.6s ease-out;
}
.GamesHome .JackpotGamesContainer .JackpotGames .item {
  width: 850px;
  height: 251px;
  background-image: url("/vn/img/game/JackpotGames.webp");
  border-radius: 20px;
  margin-right: 15px;
  flex: 0 0 75%;
  background-position: center;
  background-size: 100%;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.GamesHome .JackpotGamesContainer .JackpotGames .item .left {
  flex: 0 0 29%;
  height: 138px;
  padding-left: 20px;
}
.GamesHome .JackpotGamesContainer .JackpotGames .item .left .openGame .DefaultTag {
  display: none;
}
.GamesHome .JackpotGamesContainer .JackpotGames .item .left .openGame img {
  height: 100%;
}
.GamesHome .JackpotGamesContainer .JackpotGames .item .left .openGame .GameName {
  display: none;
}
.GamesHome .JackpotGamesContainer .JackpotGames .item .left .openGame:hover::before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.4);
  width: 100%;
  height: 180px;
  z-index: 2;
}
.GamesHome .JackpotGamesContainer .JackpotGames .item .left .openGame:hover::after {
  content: "Chơi Ngay";
  font-size: 14px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 96px;
  height: 44px;
  background: transparent linear-gradient(180deg, #79d1ff 0%, #00a6ff 100%) 0% 0% no-repeat padding-box;
  border-radius: 30px;
  z-index: 2;
}
.GamesHome .JackpotGamesContainer .JackpotGames .item .right {
  flex: 0 0 60%;
  height: 138px;
}
.GamesHome .JackpotGamesContainer .JackpotGames .item .right .JackpotName {
  position: relative;
  display: flex;
  width: 100%;
  padding-left: 30px;
  font-size: 24px;
  align-items: center;
  justify-content: flex-start;
}
.GamesHome .JackpotGamesContainer .JackpotGames .item .right .JackpotName .DefaultTag {
  position: static !important;
  margin-right: 15px;
  width: 60px !important;
  height: 38px !important;
}
.GamesHome .JackpotGamesContainer .JackpotGames .item .right .JackpotName .DefaultTag span {
  font-size: 18px;
}
.GamesHome .JackpotGamesContainer .JackpotGames .item .right .JackpotName > span {
  text-align: left;
  font-weight: bold;
}
.GamesHome .JackpotGamesContainer .JackpotGames .item .right .box-item {
  height: auto;
  display: flex;
  transform: scale(0.85);
  margin-left: -10px;
  margin-bottom: 39px;
}
.GamesHome .JackpotGamesContainer .JackpotGames .item .right .number-item {
  width: 34px;
  height: 51px;
  list-style: none;
  margin-right: 1px;
  background-image: url("/vn/img/game/Numbg.webp");
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 4px;
  border: 1px solid #dddddd;
  display: flex;
  align-items: center;
}
.GamesHome .JackpotGamesContainer .JackpotGames .item .right .number-item > span {
  position: relative;
  display: flex;
  width: 100%;
  height: 100%;
  -ms-writing-mode: tb-rl;
      writing-mode: vertical-rl;
  text-orientation: upright;
  overflow: hidden;
  align-items: center;
  justify-content: center;
  color: white;
  font-weight: bold;
}
.GamesHome .JackpotGamesContainer .JackpotGames .item .right .number-item:last-child {
  margin-right: 0;
}
.GamesHome .JackpotGamesContainer .JackpotGames .item .right .comma {
  display: inline-block;
  color: #00a6ff;
  font-size: 30px;
}
.GamesHome .JackpotGamesContainer .JackpotGames .item .right .mark-item {
  display: flex;
  align-items: flex-end;
  padding: 2px 0;
}
.GamesHome .JackpotGamesContainer .jackpot-slide-arrow {
  width: 40px;
  height: 80px;
  position: absolute;
  top: 85px;
  outline: none;
  border-width: 0;
  border-radius: 4px;
  cursor: pointer;
  background-color: #00000040;
  transition: background-color 0.3s;
  z-index: 2;
}
.GamesHome .JackpotGamesContainer .jackpot-slide-arrow:hover {
  background-color: #00000080;
}
.GamesHome .JackpotGamesContainer .jackpot-slide-arrow.slide-prev {
  left: 0px;
  background-image: url("/vn/img/home/part/arrow-to-left.png");
}
.GamesHome .JackpotGamesContainer .jackpot-slide-arrow.slide-next {
  right: 0px;
  background-image: url("/vn/img/home/part/arrow-to-right.png");
}
.GamesHome .GamesPlatform {
  display: flex;
  flex-wrap: wrap;
  gap: 31px;
  margin-bottom: 50px;
}
.GamesHome .GamesPlatform .item {
  width: 200px;
  height: auto;
  background: linear-gradient(90deg, rgba(0, 166, 255) 0%, rgba(0, 166, 255, 0.6) 50%, #00a6ff 100%) 0% 0% no-repeat padding-box padding-box transparent;
  background-size: 400% 100%;
  animation: ant-skeleton-loading 1.4s ease infinite;
  border-radius: 10px;
  overflow: hidden;
  flex: 0 0 17.66%;
  position: relative;
  cursor: pointer;
  min-height: 100px;
}
.GamesHome .GamesPlatform .item img {
  border-radius: 10px;
  width: 100%;
  height: 100%;
}
.GamesHome .GamesPlatform .item .name {
  position: absolute;
  bottom: 10px;
  width: 100%;
  color: white;
}
.GamesHome .GamesPlatform .item.isHot::before {
  content: "";
  font-size: 18px;
  position: absolute;
  top: -3%;
  left: -7%;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 43%;
  height: 100px;
  background: transparent;
  background-image: url("/vn/img/icon/hot.svg");
  z-index: 2;
  transform: rotate(-2deg);
  background-position: 8px 0px;
  background-repeat: no-repeat;
  background-size: 80%;
}
.GamesHome .GamesPlatform .item.isNew::before {
  content: "";
  font-size: 18px;
  position: absolute;
  top: -3%;
  left: -7%;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 43%;
  height: 100px;
  background: transparent;
  background-image: url("/vn/img/icon/new.svg");
  z-index: 2;
  transform: rotate(-2deg);
  background-position: 8px 0px;
  background-repeat: no-repeat;
  background-size: 80%;
}
.GamesHome .GamesType {
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
}
.GamesHome .GamesType .item {
  width: 200px;
  height: 76px;
  background: white;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0 10px;
  cursor: pointer;
  gap: 8px;
}
.GamesHome .GamesType .item img {
  width: 46px;
  height: 46px;
  margin-right: 10px;
}
.openGame {
  position: relative;
  height: 100%;
  border-radius: 10px;
  overflow: hidden;
  cursor: pointer;
  margin-bottom: 10px;
  background-color: white;
}
.openGame:hover::before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.4);
  width: 100%;
  height: 74%;
  z-index: 2;
}
.openGame:hover::after {
  content: "Chơi Ngay";
  font-size: 14px;
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 96px;
  height: 31px;
  background: transparent linear-gradient(180deg, #79d1ff 0%, #00a6ff 100%) 0% 0% no-repeat padding-box;
  border-radius: 30px;
  z-index: 2;
}
.openGame .GameName {
  height: 38px;
  padding: 12px 10px;
  background: white;
  text-align: left;
  position: absolute;
  width: 100%;
  bottom: 0;
  color: #222222;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font: normal normal 700 12px / 16px Roboto;
  z-index: 3;
  text-transform: capitalize;
}
.img-container-game-lobby > span > span {
  background-color: #00a6ff !important;
  padding: 61.5646% 0px 0px !important;
}
.Selfexclusion-content {
  width: 1120px;
  padding: 138px 46px 85px 74px;
  background: url("/vn/img/selfexclusion/bg.png") center center no-repeat;
  height: 655px;
  background-size: 100%;
  margin-bottom: 50px;
}
.Selfexclusion-content button {
  width: 127px;
  height: 40px;
  line-height: 35px;
  background: #00a6ff;
  border: 0;
  opacity: 1;
  border-radius: 8px;
  cursor: pointer;
  color: white;
}
.Selfexclusion-content button:disabled {
  background-color: #ccc;
}
.Selfexclusion-content .ant-input-number {
  width: 354px;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  opacity: 0.6;
}
.Selfexclusion-content .form-submit {
  display: flex;
  background-color: white;
  color: #222;
  padding: 25px 40px;
  border-radius: 20px;
  margin-top: 20px;
  gap: 80px;
}
.Selfexclusion-content .form-submit .l,
.Selfexclusion-content .form-submit .c {
  display: flex;
  flex-direction: column;
  gap: 15px;
  width: 316px;
}
.Selfexclusion-content .form-submit .l label,
.Selfexclusion-content .form-submit .c label {
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 16px;
  font-weight: 600;
}
.Selfexclusion-content .form-submit .l label input,
.Selfexclusion-content .form-submit .c label input {
  width: 20px;
  height: 20px;
}
.Selfexclusion-content .form-submit .l label input::after,
.Selfexclusion-content .form-submit .c label input::after {
  content: "";
  display: block;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  border: 2px solid #bdbdbd;
  border-radius: 100%;
}
.Selfexclusion-content .form-submit .l label input:checked::after,
.Selfexclusion-content .form-submit .c label input:checked::after {
  background-color: white;
  border: 5px solid #00a6ff;
}
.Selfexclusion-content .form-submit .l .ant-input,
.Selfexclusion-content .form-submit .c .ant-input,
.Selfexclusion-content .form-submit .l .ant-select-selection,
.Selfexclusion-content .form-submit .c .ant-select-selection {
  border-color: #e0e0e0;
}
.Selfexclusion-content .form-submit .l .ant-input::-moz-placeholder, .Selfexclusion-content .form-submit .c .ant-input::-moz-placeholder, .Selfexclusion-content .form-submit .l .ant-select-selection::-moz-placeholder, .Selfexclusion-content .form-submit .c .ant-select-selection::-moz-placeholder {
  color: #999;
}
.Selfexclusion-content .form-submit .l .ant-input:-ms-input-placeholder, .Selfexclusion-content .form-submit .c .ant-input:-ms-input-placeholder, .Selfexclusion-content .form-submit .l .ant-select-selection:-ms-input-placeholder, .Selfexclusion-content .form-submit .c .ant-select-selection:-ms-input-placeholder {
  color: #999;
}
.Selfexclusion-content .form-submit .l .ant-input::placeholder,
.Selfexclusion-content .form-submit .c .ant-input::placeholder,
.Selfexclusion-content .form-submit .l .ant-select-selection::placeholder,
.Selfexclusion-content .form-submit .c .ant-select-selection::placeholder {
  color: #999;
}
.Selfexclusion-content .form-submit .l .ant-select-selection,
.Selfexclusion-content .form-submit .c .ant-select-selection {
  height: 45px;
}
.Selfexclusion-content .form-submit .l .ant-select-selection .ant-select-selection__rendered,
.Selfexclusion-content .form-submit .c .ant-select-selection .ant-select-selection__rendered {
  padding-top: 13px;
}
.Selfexclusion-content .form-submit .l .ant-select-selection .ant-select-selection__rendered .ant-select-selection__placeholder,
.Selfexclusion-content .form-submit .c .ant-select-selection .ant-select-selection__rendered .ant-select-selection__placeholder {
  color: #999;
}
.Selfexclusion-content .form-submit .l .ant-select-selection .ant-select-selection__rendered .ant-select-selection-selected-value,
.Selfexclusion-content .form-submit .c .ant-select-selection .ant-select-selection__rendered .ant-select-selection-selected-value {
  line-height: normal;
}
.Selfexclusion-content .form-submit .l .ant-select-selection .ant-select-arrow img,
.Selfexclusion-content .form-submit .c .ant-select-selection .ant-select-arrow img {
  opacity: 0.5;
}
.Selfexclusion-content .form-submit .l span,
.Selfexclusion-content .form-submit .c span {
  font-size: 12px;
  margin-left: 10px;
}
.Selfexclusion-content .About_Search {
  width: 200px;
  padding-bottom: 10px;
}
.Selfexclusion-content .box_body {
  height: auto;
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 20px;
  margin-top: 25px;
  margin-bottom: 50px;
  padding: 5px 15px;
  min-height: 300px;
}
.Selfexclusion-content h2 {
  font-weight: bold;
}
.Selfexclusion-content .txtbox {
  width: 480px;
  height: 280px;
  position: relative;
}
.Selfexclusion-content .txtbox img {
  height: 100%;
  width: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}
.Selfexclusion-content .txtbox .selfexclusion-text h3 {
  position: absolute;
  top: 30px;
  left: 190px;
  font-size: large;
  color: #ffffff;
}
.Selfexclusion-content .txtbox .selfexclusion-text p {
  position: absolute;
  top: 100px;
  left: 35px;
  width: 410px;
  font-size: 16px;
  font-weight: 500;
  color: #000000;
}
.selfExclusion-selectDays-dropdown .ant-select-dropdown-menu .ant-select-dropdown-menu-item {
  display: flex;
  justify-content: space-between;
  transition: none;
}
.selfExclusion-selectDays-dropdown .ant-select-dropdown-menu .ant-select-dropdown-menu-item-selected {
  font-weight: normal;
  background-color: white !important;
  background-image: url("/vn/img/selfexclusion/icon-add.png");
  background-repeat: no-repeat;
  background-position-x: 287px;
  background-position-y: 16px;
}
.selfExclusion-selectDays-dropdown .ant-select-dropdown-menu li:first-child,
.selfExclusion-selectDays-dropdown .ant-select-dropdown-menu li:nth-child(2) {
  border-bottom: 1px solid #e0e0e0;
}
.selfExclusion-selectDays-dropdown .ant-select-dropdown-menu li:hover {
  color: #00a6ff;
  background-color: white !important;
}
.SelfExclusionModal .ant-modal-content {
  border-radius: 20px;
}
.SelfExclusionModal .ant-modal-header {
  height: 60px;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.SelfExclusionModal .ant-modal-header .ant-modal-title {
  color: white;
}
.SelfExclusionModal .ant-modal-body {
  padding: 30px;
}
.SelfExclusionModal .ant-modal-body .SelfExclusionModalButton {
  width: 100%;
  height: 40px;
  margin-top: 29px;
  color: white;
  border: none;
  background-color: #00a6ff;
}
.dailydeals-wrap {
  margin: 0 auto;
  min-width: 1120px;
  min-height: 885px;
}
.dailydeals-wrap .yellowNote {
  padding: 16px;
  margin-bottom: 32px;
  margin-top: 2px;
  background-color: #FFF5BF;
  color: #83630B;
  width: -moz-fit-content;
  width: fit-content;
}
.daily-banner-wrap {
  top: 168px;
  left: 400px;
  width: 1120px;
  height: 160px;
  /* UI Properties */
  border-radius: 20px;
  opacity: 1;
}
.daily-content {
  width: 1120px;
  margin: auto;
  color: #fff;
  text-align: center;
}
.daily-content .my-promotion-filter-box .ant-radio-button-wrapper-checked,
.daily-content .my-promotion-filter-box .ant-radio-button-wrapper-checked:hover {
  background-color: #00a6ff;
  color: #fff !important;
  border: none !important;
  box-shadow: none !important;
  margin-bottom: 30px;
}
.daily-content .ant-tabs-tab {
  text-align: center;
  font: normal normal normal 18px/36px Roboto !important;
  letter-spacing: 0px;
  color: #999999;
  opacity: 1;
  height: 36px;
}
.daily-content .ant-tabs-tab.ant-tabs-tab-active {
  color: #00a5fe !important;
  text-align: center;
  font: normal normal bold 18px/24px Roboto !important;
  letter-spacing: 0px;
  color: #00a5fe;
  opacity: 1;
  height: 36px;
}
.daily-content .ant-tabs-ink-bar {
  width: 120px;
  height: 6px;
  /* UI Properties */
  background: var(--unnamed-color-00a6ff) 0% 0% no-repeat padding-box;
  background: #00a6ff 0% 0% no-repeat padding-box;
  opacity: 1;
}
.daily-content .ant-tabs-nav-wrap {
  justify-content: flex-start;
}
.daily-content .daily-rewards {
  width: 100%;
}
.daily-content .daily-deals-list {
  width: 354px;
  height: 265px;
  box-shadow: 0px 2px 4px #00000014;
  border-radius: 10px;
  opacity: 1;
  background-color: white;
}
.daily-content .daily-deals-list .daily-deals-list-img {
  width: 354px;
  height: auto;
}
.daily-content .daily-deals-list .daily-deals-list-img img {
  width: 100%;
  height: 100%;
  border-radius: 10px 10px 0px 0px;
  opacity: 1;
  background: transparent 0% 0% no-repeat padding-box;
}
.daily-content .daily-deals-list .daily-deals-item {
  display: flex;
  justify-content: space-between;
  padding: 16px 0;
}
.daily-content .daily-deals-list .daily-deals-item .daily-deals-item-left {
  width: 199px;
  height: 62px;
  position: relative;
  margin-left: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.daily-content .daily-deals-list .daily-deals-item .daily-deals-item-left .daily-deals-item-left-title {
  width: 224px;
  height: auto;
}
.daily-content .daily-deals-list .daily-deals-item .daily-deals-item-left .daily-deals-item-left-title h5 {
  text-align: left;
  font: normal normal bold 16px/21px Roboto;
  letter-spacing: 0px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  /* 指定显示的行数 */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #222222;
  opacity: 1;
}
.daily-content .daily-deals-list .daily-deals-item .daily-deals-item-left .daily-deals-item-left-info {
  padding-bottom: 5px;
  display: flex;
  align-items: center;
}
.daily-content .daily-deals-list .daily-deals-item .daily-deals-item-left .daily-deals-item-left-icon {
  width: 16px;
  height: 16px;
}
.daily-content .daily-deals-list .daily-deals-item .daily-deals-item-left .daily-deals-item-left-text {
  margin-left: 10px;
}
.daily-content .daily-deals-list .daily-deals-item .daily-deals-item-left .daily-deals-item-left-text span {
  text-align: left;
  font: normal normal normal 12px/16px Roboto;
  letter-spacing: 0px;
  color: #999999;
  opacity: 1;
}
.daily-content .daily-deals-list .daily-deals-item .daily-deals-item-btn {
  display: flex;
  align-items: center;
  margin-right: 10px;
}
.daily-content .daily-deals-list .daily-deals-item .daily-deals-item-btn button {
  height: 30px;
  border: none;
  background: #33c85d 0% 0% no-repeat padding-box;
  border-radius: 4px;
  opacity: 1;
  color: #ffffff;
  letter-spacing: 0px;
  cursor: pointer;
}
.daily-content .daily-deals-list .daily-deals-item .daily-deals-item-btn button.out-of-stock {
  background: #cccccc 0% 0% no-repeat padding-box;
}
.daily-content .daily-gift-banner-container {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  height: 160px;
  background-color: #e0e0e0;
  border-radius: 20px;
  margin-bottom: 40px;
}
.history-list-wrap {
  margin-top: 30px;
  margin-bottom: 50px;
  line-height: 50px;
  text-align: center;
}
.history-list-wrap._modal {
  margin-top: 0;
  margin-bottom: 20px;
}
.history-list-wrap.has-detail .ant-row:not(:first-child) {
  cursor: pointer;
}
.history-list-wrap .ant-row {
  border-bottom: 1px solid #e0e0e0;
  color: #222;
}
.history-list-wrap .ant-row:not(:first-child) {
  font-size: 12px;
}
.history-list-wrap .ant-row .ant-col-3:last-child {
  line-height: 25px;
}
.history-list-wrap .ant-row .lastChildSpan {
  height: 40px;
  line-height: 40px !important;
}
.history-list-wrap .ant-col {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.history-list-wrap .anticon {
  color: #999;
}
.bonusHistory {
  width: 100%;
  position: relative;
}
.bonusHistory .dailyGiftHistoryBtn {
  width: 40%;
  position: absolute;
  right: 0;
  top: -92px;
}
.bonusHistory .dailyGiftHistoryBtn .ant-radio-group {
  justify-content: flex-end;
}
.bonusHistory .dailyGiftHistoryBtn .defined-date-description {
  width: 100%;
  text-align: right;
  margin-top: 15px;
  color: #222;
}
.bonusHistory .dailyGiftHistoryBtn .defined-date-description span {
  margin-left: 10px;
  color: #1c8eff;
  cursor: pointer;
}
.message-button.dailyGiftHistoryBtn .ant-radio-button-wrapper {
  background: transparent;
  color: #222222;
  border: 1px solid #e0e0e0;
}
.message-button.dailyGiftHistoryBtn .ant-radio-button-wrapper:not(:first-child) {
  border-left: none;
}
.message-button.dailyGiftHistoryBtn .ant-radio-button-wrapper.ant-radio-button-wrapper-checked {
  height: auto;
  transition: none;
  box-shadow: none;
  border: 1px solid #e0e0e0;
}
.message-button.dailyGiftHistoryBtn label:first-child {
  border-radius: 5px 0px 0px 5px;
}
.message-button.dailyGiftHistoryBtn label:last-child {
  border-radius: 0px 5px 5px 0px;
}
.PromoDetail {
  min-width: 1120px;
  height: 1346px;
}
.PromoDetail .ant-modal-title {
  text-align: center;
  font: normal normal bold 20px/27px Roboto;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
}
.PromoDetail .PromoDetail-info {
  padding-top: 24px;
  min-height: 305px;
}
.PromoDetail .PromoDetail-submitBtn {
  width: 100%;
  display: flex;
  justify-content: center;
}
.PromoDetail .PromoDetail-submitBtn button {
  width: 345px;
  height: 40px;
  /* UI Properties */
  background: #00a6ff 0% 0% no-repeat padding-box;
  border-radius: 10px;
  border: 0;
  text-align: center;
  font: normal normal 600 14px/19px Roboto;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
  cursor: pointer;
}
.PromoDetail .PromoDetail-submitBtn button.sold-out {
  background: #cccccc 0% 0% no-repeat padding-box;
}
.commonModal.ant-modal-confirm {
  width: 400px !important;
}
.commonModal.ant-modal-confirm .ant-modal-close {
  display: none;
}
.commonModal.ant-modal-confirm .ant-modal-body {
  padding: 0;
}
.commonModal.ant-modal-confirm .ant-modal-confirm-body i {
  display: none;
}
.commonModal.ant-modal-confirm .ant-modal-confirm-title {
  height: 60px;
  font-size: 20px;
  font-weight: bold;
  color: #ffffff;
  padding-top: 14.5px;
  background: #00a6ff 0% 0% no-repeat padding-box;
  border-radius: 10px 10px 0px 0px;
}
.commonModal.ant-modal-confirm .note {
  height: 20px;
  text-align: center;
  font-size: 14px;
  letter-spacing: 0px;
  color: #222222;
  opacity: 1;
}
.commonModal.ant-modal-confirm .ant-modal-confirm-btns {
  padding: 0 30px 30px 30px;
}
.DailyGiftAddress {
  width: 400px !important;
}
.DailyGiftAddress .ant-modal-content {
  border-radius: 20px;
}
.DailyGiftAddress .ant-modal-content .ant-modal-header {
  border-top-right-radius: 20px;
  border-top-left-radius: 20px;
}
.DailyGiftAddress .ant-modal-content .ant-modal-header .ant-modal-title {
  color: #fff;
  text-align: center;
}
.DailyGiftAddress .ant-modal-content .ant-modal-body {
  padding-bottom: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.DailyGiftAddress .DailyGiftAddress-info {
  margin: 24px auto;
  width: 343px;
  height: 65px;
  /* UI Properties */
  background: #fff5bf 0% 0% no-repeat padding-box;
  border-radius: 8px;
  opacity: 1;
  padding: 12px 16px;
  text-align: left;
  font: normal normal bold 12px/20px Roboto;
  letter-spacing: 0px;
  color: #83630b;
}
.DailyGiftAddress .DailyGiftAddress-list {
  display: flex;
  align-items: center;
  width: 340px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #cccccc;
  border-radius: 10px;
  opacity: 1;
  padding: 20px 0;
}
.DailyGiftAddress .DailyGiftAddress-list .radioBox {
  margin-left: 10px;
  width: 20px;
  height: 20px;
}
.DailyGiftAddress .DailyGiftAddress-list .DailyGiftAddress-list-info {
  position: relative;
  width: calc(100% - 50px);
  overflow: hidden;
  margin-left: 10px;
}
.DailyGiftAddress .DailyGiftAddress-list .DailyGiftAddress-list-info .mid-info-nameAndPhone {
  margin-bottom: 10px;
  width: calc(100% - 100px);
}
.DailyGiftAddress .DailyGiftAddress-list .DailyGiftAddress-list-info .mid-info-nameAndPhone .name {
  color: #222222;
  font-weight: bold;
  margin-right: 10px;
}
.DailyGiftAddress .DailyGiftAddress-list .DailyGiftAddress-list-info .mid-info-nameAndPhone .phone {
  font-size: 12px;
  color: #999999;
}
.DailyGiftAddress .DailyGiftAddress-list .DailyGiftAddress-list-info .mid-info-address {
  text-align: left;
  font: normal normal normal 12px/16px Roboto;
  letter-spacing: 0px;
  color: #666666;
  opacity: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-all;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}
.DailyGiftAddress .DailyGiftAddress-list .DailyGiftAddress-list-info .editBox {
  position: absolute;
  cursor: pointer;
  width: 16px;
  height: 16px;
  top: 0;
  right: 0;
  opacity: 0.6;
}
.DailyGiftAddress .DailyGiftAddress-list .DailyGiftAddress-list-info div:first-child,
.DailyGiftAddress .DailyGiftAddress-list .DailyGiftAddress-list-info div:nth-child(3) {
  display: flex;
  flex-direction: column;
}
.DailyGiftAddress .DailyGiftAddress-list .DailyGiftAddress-list-info div:first-child span,
.DailyGiftAddress .DailyGiftAddress-list .DailyGiftAddress-list-info div:nth-child(3) span {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  display: block;
}
.DailyGiftAddress .DailyGiftAddress-list .default {
  position: absolute;
  right: 50px;
  top: 20px;
  padding: 5px;
  border-radius: 2px;
  font: normal normal normal 10px/14px Roboto !important;
  color: #0CCC3C;
  display: block;
  width: 70px;
  background: url("/vn/img/icon/greenTick.svg") 0 center no-repeat;
  padding-left: 20px;
}
.DailyGiftAddress .DailyGiftAddress-btn {
  display: flex;
  justify-content: center;
}
.DailyGiftAddress .DailyGiftAddress-btn button {
  letter-spacing: -2px;
  width: 340px;
  height: 40px;
  padding: 10px 0;
  border: 1px solid var(--unnamed-color-00a6ff);
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #00a6ff;
  border-radius: 10px;
  opacity: 1;
  font: normal normal normal 14px/22px Roboto;
  letter-spacing: 0px;
  color: #00a6ff;
  cursor: pointer;
}
.DailyGiftAddress .DailyGiftAddress-remark {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 340px;
}
.DailyGiftAddress .DailyGiftAddress-remark div {
  display: flex;
  justify-content: space-between;
  margin: 10px 0px;
  color: #222222;
  opacity: 1;
  font-size: 14px;
}
.DailyGiftAddress .DailyGiftAddress-submit {
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
}
.DailyGiftAddress .DailyGiftAddress-submit button {
  width: 340px;
  height: 40px;
  padding: 10px 0;
  background: #00a6ff 0% 0% no-repeat padding-box;
  border-radius: 8px;
  opacity: 1;
  font: normal normal normal 14px/22px Roboto;
  font-weight: bold;
  color: #ffffff;
  cursor: pointer;
  margin-top: 30px;
}
.DailyGiftAddressDetail {
  width: 400px !important;
}
.DailyGiftAddressDetail .ant-modal-title {
  color: #fff;
  text-align: center;
}
.DailyGiftAddressDetail .ant-modal-content .ant-modal-body {
  padding: 0 30px 30px 30px;
}
.DailyGiftAddressDetail .DailyGiftAddressDetail-text {
  display: flex;
  align-items: center;
  width: 340px;
  padding: 10px;
  background: #fff5bf 0% 0% no-repeat padding-box;
  border-radius: 8px;
  text-align: left;
  color: #83630b;
  margin-top: 30px;
  font-size: 12px;
}
.DailyGiftAddressDetail .item-text {
  height: 20px;
  /* UI Properties */
  margin-left: 4px;
  letter-spacing: 0px;
  color: #222222;
  opacity: 1;
  margin-bottom: 10px;
  font-size: 14px;
  text-align: left;
  font-weight: bold;
}
.DailyGiftAddressDetail .ant-checkbox-inner {
  border-radius: 0;
}
.DailyGiftAddressDetail .ant-select-selection--single {
  height: 45px;
}
.DailyGiftAddressDetail .DailyGiftAddressDetail-btn {
  margin: 31px 0 15px;
}
.DailyGiftAddressDetail .DailyGiftAddressDetail-btn .ant-btn {
  height: 40px;
}
.DailyGiftAddressDetail .delete-btn .ant-btn {
  height: 40px;
  border-color: #00a6ff;
  color: #00a6ff;
  font-weight: bold;
}
.DailyGiftAddressDetail .ant-input[disabled],
.DailyGiftAddressDetail .ant-select-disabled .ant-select-selection.ant-select-selection--single {
  background-color: #F4F2F2;
}
.DailyGiftAddressDetail .ant-select-selection--single .ant-select-selection__rendered {
  display: flex;
  align-items: center;
  margin-left: 5px;
  margin-right: 5px;
  line-height: 42px;
  text-align: center;
}
.DailyGiftAddressDetail .ant-select-selection-selected-value {
  vertical-align: bottom;
  font-size: 14px;
  color: #222222;
}
.DailyGiftAddressDetail .ant-input-affix-wrapper .ant-input-prefix {
  text-align: left;
  letter-spacing: 0px;
  color: #999999;
  opacity: 1;
}
.DailyGiftAddressDetail .ant-input-affix-wrapper .ant-input:not(:first-child) {
  padding-left: 8px;
}
.DailyGiftAddressDetail .ant-modal-content {
  border-radius: 20px;
}
.DailyGiftAddressDetail .ant-modal-header {
  border-top-right-radius: 20px;
  border-top-left-radius: 20px;
}
.DailyGiftAddressDetail .input-error-message {
  margin-top: 10px;
}
.DailyGiftAddressDetail .ant-select-disabled .ant-select-selection-selected-value {
  color: #222;
}
.DailyGiftAddressDetail .lastname-input,
.DailyGiftAddressDetail .firstname-input {
  border: 1px solid #bfbfbf;
  border-radius: 10px;
}
.DailyGiftAddressDetail .phone-numbner-row .ant-col-4 {
  border-radius: 8px;
  background: #F4F2F2;
  line-height: 45px;
  text-align: center;
}
.DailyGiftAddressDetail .selectArddress-wrap .ant-select-dropdown-menu-item {
  padding: 5px;
}
.DailyGiftAddressDetail .selectArddress-wrap .ant-select-arrow {
  right: 3px;
}
#SelectArddress .cover {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: black;
  opacity: 0.5;
  top: 0;
  left: 0;
}
#SelectArddress .bottom {
  position: absolute;
  top: 0;
  width: 100%;
  left: 0;
  z-index: 10;
  background-color: white;
  padding: 8px 16px;
  box-shadow: 0 0 5px #f3f3f3;
}
#SelectArddress .AreaSelection {
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 10;
  background-color: white;
  animation: showUp 0.3s ease both 1;
}
#SelectArddress .AreaSelection .Header__TOP {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  border-bottom: 1px solid #d6d6d6;
}
#SelectArddress .AreaSelection .selectArr {
  display: flex;
  position: relative;
  overflow: hidden;
}
#SelectArddress .AreaSelection .containerCover {
  width: 100%;
  height: 40px;
  border: 1px solid #d6d6d6;
  border-left: none;
  border-right: none;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 9;
  pointer-events: none;
}
#SelectArddress .AreaSelection .container {
  flex-grow: 1;
  flex-basis: 0;
  text-align: center;
  background-color: white;
  height: 224px;
  padding-top: 60px;
}
@keyframes showUp {
  0% {
    opacity: 0;
    transform: translateY(100%);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
#SelectArddress .swiper-container {
  height: 170px;
}
.page {
  width: 100vw;
  height: 100vh;
  position: fixed;
  z-index: 100;
  left: 0;
  top: 0;
}
#AreaSelection .swiper-slide {
  width: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #888888;
}
#AreaSelection .swiper-slide-active {
  color: #2d353a;
  font-size: 16px;
}
.ant-select-dropdown-menu .ant-select-dropdown-menu-item {
  text-align: center;
  color: #222;
}
.form-item .input-error-message {
  background-color: #fee5e5;
  border-radius: 8px;
  text-align: left;
  font: normal normal normal 12px/17px Roboto;
  letter-spacing: 0px;
  color: #eb2121;
  opacity: 1;
  padding: 10px;
  margin-bottom: 10px;
  margin-top: 10px;
}
.live-server-btn-wrap .live-server-btn {
  margin-left: 18px;
  display: inline-block;
  padding: 0 16px 0 6px;
  line-height: 34px;
  height: 34px;
  background-color: #fff;
  border-radius: 20px;
  color: #222;
  font-weight: bold;
  box-shadow: 0 3px 3px #131313;
  transition: all 0.2s;
  cursor: pointer;
}
.live-server-btn-wrap .live-server-btn .live-service {
  border-radius: 50%;
  vertical-align: -0.8em;
  background-position: 0 -81px;
  transition: all 0.2s;
  cursor: pointer;
}
.live-server-btn-wrap .live-server-btn span {
  vertical-align: middle;
}
#help .box_body {
  height: auto;
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 20px;
  margin-bottom: 50px;
  padding: 20px 15px;
}
#help .box_body img {
  max-width: 100%;
  margin-top: 10px;
  margin-bottom: 10px;
}
#help .box_body caption {
  caption-side: top;
  color: #000000;
  font-size: 1.17em;
  font-weight: bold;
  white-space: pre;
}
#help h2 {
  font-weight: bold;
}
#help .about-content {
  min-height: 900px;
  display: flex;
}
#help .about-content .ant-skeleton-header {
  padding: 10px 20px;
}
#help .about-content .ant-skeleton-header .ant-skeleton-avatar-lg {
  width: 170px;
  height: 500px;
  border-radius: 15px !important;
}
#help .about-content .ant-empty {
  padding-top: 15%;
}
#help .about-content .ant-empty-image {
  height: 150px;
}
#help .about-content .ant-empty-description {
  margin-top: 20px;
  text-indent: 50px;
}
#help .about-content .right {
  flex: 1 1;
  margin: 0 22px;
  border-radius: 20px;
  width: 900px;
}
#help .about-content .right h2 {
  margin-bottom: 28px;
}
#help .about-content .right .box_body {
  height: auto;
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 20px;
  margin-bottom: 50px;
  padding: 40px 60px;
}
#help .about-content .ant-menu {
  color: #222222;
}
#help .about-content .ant-menu-root {
  width: 200px;
  color: #222222;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 2px #00000029;
  border-radius: 10px;
  padding: 20px 0;
  overflow: hidden;
}
#help .about-content .ant-menu-submenu {
  font-weight: bold;
}
#help .about-content .ant-menu-submenu-selected {
  color: #222222;
}
#help .about-content .ant-menu-submenu-title:hover {
  color: #222222;
}
#help .about-content .ant-menu-item {
  display: flex;
  align-items: center;
  height: 44px;
  font-weight: normal;
  margin: 0;
  padding-left: 37px !important;
}
#help .about-content .ant-menu-item-selected {
  color: #00a6ff;
  font-weight: bold;
}
#help .about-content .ant-menu-item:hover,
#help .about-content .ant-menu-item-active,
#help .about-content .ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open,
#help .about-content .ant-menu-submenu-active,
#help .about-content .ant-menu-submenu-title:hover {
  color: #00a6ff;
}
#help .about-content .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: #e0e0e0;
}
#help .about-content .ant-menu-vertical .ant-menu-item::after,
#help .about-content .ant-menu-vertical-left .ant-menu-item::after,
#help .about-content .ant-menu-vertical-right .ant-menu-item::after,
#help .about-content .ant-menu-inline .ant-menu-item::after {
  border-right: 6px solid #00a6ff;
  left: 0 !important;
  right: auto !important;
}
#help .about-content .ant-menu-submenu-open.ant-menu-submenu-inline > .ant-menu-submenu-title .ant-menu-submenu-arrow::before {
  transform: rotate(45deg) translateX(2px);
  background: #222;
}
#help .about-content .ant-menu-submenu-open.ant-menu-submenu-inline > .ant-menu-submenu-title .ant-menu-submenu-arrow::after {
  background: #222;
  transform: rotate(-45deg) translateX(-2px);
}
#help .about-content .ant-menu-submenu-inline > .ant-menu-submenu-title .ant-menu-submenu-arrow::before {
  transform: rotate(-45deg) translateX(2px);
  background: #222;
}
#help .about-content .ant-menu-submenu-inline > .ant-menu-submenu-title .ant-menu-submenu-arrow::after {
  transform: rotate(45deg) translateX(-2px);
  background: #222;
}
#help .about-content .ant-timeline-item {
  padding-left: 35px;
}
#help .about-content .ant-timeline-item-content {
  top: -15.001px;
}
#help .about-content .ant-divider-horizontal {
  margin-bottom: 14px;
}
#help .about-content .ant-timeline-item-tail {
  border-color: #e32424;
}
#help .about-content .ant-timeline-item-head-custom {
  padding: 0px 1px;
}
#help .about-content .ant-timeline {
  margin-left: 15px;
}
#help .about-content .ant-table {
  margin-bottom: 15px;
}
#help .about-content .ant-table-tbody > tr td:not(:first-child) {
  transition: all 0.3s, height 0s;
  color: red;
}
#help .ant-menu.tlc-list-toggle {
  width: 838px;
  box-shadow: none;
  margin-top: 16px;
}
#help .ant-menu.tlc-list-toggle .ant-menu-submenu {
  border: 1px solid #ddd;
  border-radius: 8px;
  margin-bottom: 10px;
  overflow: hidden;
}
#help .ant-menu.tlc-list-toggle .ant-menu-submenu .ant-menu-item {
  padding-left: 48px;
  height: auto;
  white-space: normal;
  color: #222 !important;
  cursor: default;
  line-height: 2;
  margin-bottom: 0;
  padding-bottom: 6px;
}
#help .ant-menu.tlc-list-toggle .ant-menu-submenu .ant-menu-item li {
  list-style: disc outside none;
  list-style: initial;
}
#help .help-tabBar .ant-tabs-nav-wrap {
  justify-content: flex-start;
}
#help .help-tabBar .ant-tabs-nav-wrap .ant-tabs-tab {
  font-size: 15px !important;
  padding: 12px 0;
  margin-right: 14px;
}
#help .help-tabBar .ant-tabs-nav-wrap .ant-tabs-tab.ant-tabs-tab-active {
  color: #00a6ff !important;
  font-weight: bold !important;
}
#help .help-tabBar .ant-tabs-nav-wrap .ant-tabs-ink-bar {
  height: 5px;
}
#help .help-tabBar .sub-title {
  color: #000;
  font-weight: bold;
  margin-bottom: 5px;
}
#help .help-tabBar .ant-tabs-tabpane .content-container_small {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  text-align: justify;
}
#help .help-tabBar .ant-tabs-tabpane .content-container_small p {
  margin-bottom: 0px;
  color: #333;
}
#help .help-tabBar .ant-tabs-tabpane .content-container_small p:last-child {
  margin-bottom: 0;
}
#help .help-tabBar .ant-tabs-tabpane .content-container_small img {
  margin: 0;
}
#help .help-tabBar .ant-tabs-tabpane .content-container_small a {
  color: #00a6ff;
}
#help .help-tabBar .ant-tabs-tabpane .content-container_small h3 {
  color: #000;
  margin-bottom: 0;
  font-size: 14px;
}
#help .help-tabBar .table-container {
  display: grid;
  grid-template-columns: 124px 154px 154px 184px 154px;
  grid-column-gap: 2px;
  -moz-column-gap: 2px;
       column-gap: 2px;
  grid-row-gap: 2px;
  row-gap: 2px;
}
#help .help-tabBar .table-container.promotion {
  grid-template-rows: 40px 50px 50px;
}
#help .help-tabBar .table-container.promotion .table-content {
  font-size: 12px;
}
#help .help-tabBar .table-container.deposit {
  grid-template-rows: repeat(10, 40px);
  margin-bottom: 20px;
}
#help .help-tabBar .table-container.withdraw {
  grid-template-columns: 124px 374px;
  grid-template-rows: 40px 40px 40px 40px 40px 106px;
}
#help .help-tabBar .table-container.membership {
  grid-template-columns: 124px 154px 154px 184px 154px;
  grid-template-rows: repeat(6, 40px);
}
#help .help-tabBar .table-container.expiration {
  grid-template-columns: 124px 154px 154px 184px 154px;
  grid-template-rows: repeat(2, 40px) repeat(10, 30px) 40px 107px;
}
#help .help-tabBar .table-container .table-title {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #369bf2;
  color: #fff;
  font-size: 14px;
  font-weight: bold;
}
#help .help-tabBar .table-container .table-content {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #e0eefa;
  text-align: center;
}
@keyframes PhotoView__rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes PhotoView__delayShow {
  0%,
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.PhotoView__Spinner {
  animation: PhotoView__delayShow 0.4s linear both;
}
.PhotoView__Spinner svg {
  animation: PhotoView__rotate 0.6s linear infinite;
}
.PhotoView__Photo {
  will-change: transform;
  cursor: grab;
}
.PhotoView__Photo:active {
  cursor: grabbing;
}
@keyframes PhotoView__animateIn {
  from {
    opacity: 0.4;
    transform: scale(0.2);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}
@keyframes PhotoView__animateOut {
  from {
    opacity: 1;
    transform: scale(1);
  }
  to {
    opacity: 0;
    transform: scale(0.2);
  }
}
.PhotoView__animateIn {
  opacity: 0.4;
  animation: PhotoView__animateIn 0.4s cubic-bezier(0.25, 0.8, 0.25, 1) both;
}
.PhotoView__animateOut {
  opacity: 1;
  animation: PhotoView__animateOut 0.4s cubic-bezier(0.25, 0.8, 0.25, 1) both;
}
.PhotoView__PhotoWrap {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  z-index: 10;
  overflow: hidden;
}
.PhotoView__PhotoMask {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}
.PhotoView-SlideWrap {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2000;
  overflow: hidden;
}
@keyframes PhotoView__fade {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.PhotoView-PhotoSlider__Backdrop {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  z-index: -1;
}
.PhotoView-PhotoSlider__fadeIn {
  opacity: 0;
  animation: PhotoView__fade 0.4s linear both;
}
.PhotoView-PhotoSlider__fadeOut {
  opacity: 0;
  animation: PhotoView__fade 0.4s linear both reverse;
}
.PhotoView-PhotoSlider__BannerWrap {
  position: absolute;
  left: 0;
  top: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 44px;
  color: white;
  background-color: rgba(0, 0, 0, 0.5);
  transition: opacity 0.2s ease-out;
  z-index: 20;
}
.PhotoView-PhotoSlider__Counter {
  padding: 0 10px;
  font-size: 14px;
  opacity: 0.75;
}
.PhotoView-PhotoSlider__BannerRight {
  height: 100%;
}
.PhotoView-PhotoSlider__Close {
  box-sizing: border-box;
  padding: 10px;
  opacity: 0.75;
  cursor: pointer;
  transition: opacity 0.2s linear;
}
.PhotoView-PhotoSlider__Close:hover {
  opacity: 1;
}
.PhotoView-PhotoSlider__FooterWrap {
  box-sizing: border-box;
  position: absolute;
  left: 0;
  bottom: 0;
  padding: 10px;
  width: 100%;
  min-height: 44px;
  line-height: 1.5;
  font-size: 14px;
  color: #ccc;
  background-color: rgba(0, 0, 0, 0.5);
  text-align: justify;
  transition: opacity 0.2s ease-out;
  z-index: 20;
}
/* stylelint-disable at-rule-no-unknown */
html,
body {
  width: 100%;
  height: 100%;
}
input::-ms-clear,
input::-ms-reveal {
  display: none;
}
*,
*::before,
*::after {
  box-sizing: border-box;
}
html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -ms-overflow-style: scrollbar;
  -webkit-tap-highlight-color: rgba(34, 34, 34, 0);
}
@-ms-viewport {
  width: device-width;
}
article,
aside,
dialog,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section {
  display: block;
}
body {
  margin: 0;
  color: rgba(34, 34, 34, 0.8);
  font-size: 14px;
  font-family: Roboto, Arial, San-serif;
  font-variant: tabular-nums;
  line-height: 1.5715;
  background-color: #f5f5f5;
  font-feature-settings: "tnum";
}
[tabindex='-1']:focus {
  outline: none !important;
}
hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  margin-bottom: 0.5em;
  color: rgba(255, 255, 255, 0.85);
  font-weight: 500;
}
p {
  margin-top: 0;
  margin-bottom: 1em;
}
abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  -webkit-text-decoration: underline dotted;
          text-decoration: underline dotted;
  border-bottom: 0;
  cursor: help;
}
address {
  margin-bottom: 1em;
  font-style: normal;
  line-height: inherit;
}
input[type='text'],
input[type='password'],
input[type='number'],
textarea {
  -webkit-appearance: none;
}
ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1em;
}
ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}
dt {
  font-weight: 500;
}
dd {
  margin-bottom: 0.5em;
  margin-left: 0;
}
blockquote {
  margin: 0 0 1em;
}
dfn {
  font-style: italic;
}
b,
strong {
  font-weight: bolder;
}
small {
  font-size: 80%;
}
sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}
sub {
  bottom: -0.25em;
}
sup {
  top: -0.5em;
}
a {
  color: #1c8eff;
  text-decoration: none;
  background-color: transparent;
  outline: none;
  cursor: pointer;
  transition: color 0.3s;
  -webkit-text-decoration-skip: objects;
}
a:hover {
  color: #45a8ff;
}
a:active {
  color: #0d6cd9;
}
a:active,
a:hover {
  text-decoration: none;
  outline: 0;
}
a[disabled] {
  color: #fff;
  cursor: not-allowed;
  pointer-events: none;
}
pre,
code,
kbd,
samp {
  font-size: 1em;
  font-family: "Roboto", "SFMono-Regular", Consolas, "Liberation Mono", Menlo, Courier, monospace;
}
pre {
  margin-top: 0;
  margin-bottom: 1em;
  overflow: auto;
}
figure {
  margin: 0 0 1em;
}
img {
  vertical-align: middle;
  border-style: none;
}
svg:not(:root) {
  overflow: hidden;
}
a,
area,
button,
[role='button'],
input:not([type='range']),
label,
select,
summary,
textarea {
  touch-action: manipulation;
}
table {
  border-collapse: collapse;
}
caption {
  padding-top: 0.75em;
  padding-bottom: 0.3em;
  color: rgba(34, 34, 34, 0.45);
  text-align: left;
  caption-side: bottom;
}
th {
  text-align: inherit;
}
input,
button,
select,
optgroup,
textarea {
  margin: 0;
  color: inherit;
  font-size: inherit;
  font-family: inherit;
  line-height: inherit;
}
button,
input {
  overflow: visible;
}
button,
select {
  text-transform: none;
}
button,
html [type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}
button::-moz-focus-inner,
[type='button']::-moz-focus-inner,
[type='reset']::-moz-focus-inner,
[type='submit']::-moz-focus-inner {
  padding: 0;
  border-style: none;
}
input[type='radio'],
input[type='checkbox'] {
  box-sizing: border-box;
  padding: 0;
}
input[type='date'],
input[type='time'],
input[type='datetime-local'],
input[type='month'] {
  -webkit-appearance: listbox;
}
textarea {
  overflow: auto;
  resize: vertical;
}
fieldset {
  min-width: 0;
  margin: 0;
  padding: 0;
  border: 0;
}
legend {
  display: block;
  width: 100%;
  max-width: 100%;
  margin-bottom: 0.5em;
  padding: 0;
  color: inherit;
  font-size: 1.5em;
  line-height: inherit;
  white-space: normal;
}
progress {
  vertical-align: baseline;
}
[type='number']::-webkit-inner-spin-button,
[type='number']::-webkit-outer-spin-button {
  height: auto;
}
[type='search'] {
  outline-offset: -2px;
  -webkit-appearance: none;
}
[type='search']::-webkit-search-cancel-button,
[type='search']::-webkit-search-decoration {
  -webkit-appearance: none;
}
::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}
output {
  display: inline-block;
}
summary {
  display: list-item;
}
template {
  display: none;
}
[hidden] {
  display: none !important;
}
mark {
  padding: 0.2em;
  background-color: #feffe6;
}
::-moz-selection {
  color: #fff;
  background: #00a6ff;
}
::selection {
  color: #fff;
  background: #00a6ff;
}
.clearfix {
  zoom: 1;
}
.clearfix::before,
.clearfix::after {
  display: table;
  content: '';
}
.clearfix::after {
  clear: both;
}
.anticon {
  display: inline-block;
  color: inherit;
  font-style: normal;
  line-height: 0;
  text-align: center;
  text-transform: none;
  vertical-align: -0.125em;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.anticon > * {
  line-height: 1;
}
.anticon svg {
  display: inline-block;
}
.anticon::before {
  display: none;
}
.anticon .anticon-icon {
  display: block;
}
.anticon[tabindex] {
  cursor: pointer;
}
.anticon-spin::before {
  display: inline-block;
  animation: loadingCircle 1s infinite linear;
}
.anticon-spin {
  display: inline-block;
  animation: loadingCircle 1s infinite linear;
}
.fade-enter,
.fade-appear {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.fade-leave {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.fade-enter.fade-enter-active,
.fade-appear.fade-appear-active {
  animation-name: antFadeIn;
  animation-play-state: running;
}
.fade-leave.fade-leave-active {
  animation-name: antFadeOut;
  animation-play-state: running;
  pointer-events: none;
}
.fade-enter,
.fade-appear {
  opacity: 0;
  animation-timing-function: linear;
}
.fade-leave {
  animation-timing-function: linear;
}
@keyframes antFadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes antFadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
.move-up-enter,
.move-up-appear {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.move-up-leave {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.move-up-enter.move-up-enter-active,
.move-up-appear.move-up-appear-active {
  animation-name: antMoveUpIn;
  animation-play-state: running;
}
.move-up-leave.move-up-leave-active {
  animation-name: antMoveUpOut;
  animation-play-state: running;
  pointer-events: none;
}
.move-up-enter,
.move-up-appear {
  opacity: 0;
  animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
}
.move-up-leave {
  animation-timing-function: cubic-bezier(0.6, 0.04, 0.98, 0.34);
}
.move-down-enter,
.move-down-appear {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.move-down-leave {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.move-down-enter.move-down-enter-active,
.move-down-appear.move-down-appear-active {
  animation-name: antMoveDownIn;
  animation-play-state: running;
}
.move-down-leave.move-down-leave-active {
  animation-name: antMoveDownOut;
  animation-play-state: running;
  pointer-events: none;
}
.move-down-enter,
.move-down-appear {
  opacity: 0;
  animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
}
.move-down-leave {
  animation-timing-function: cubic-bezier(0.6, 0.04, 0.98, 0.34);
}
.move-left-enter,
.move-left-appear {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.move-left-leave {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.move-left-enter.move-left-enter-active,
.move-left-appear.move-left-appear-active {
  animation-name: antMoveLeftIn;
  animation-play-state: running;
}
.move-left-leave.move-left-leave-active {
  animation-name: antMoveLeftOut;
  animation-play-state: running;
  pointer-events: none;
}
.move-left-enter,
.move-left-appear {
  opacity: 0;
  animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
}
.move-left-leave {
  animation-timing-function: cubic-bezier(0.6, 0.04, 0.98, 0.34);
}
.move-right-enter,
.move-right-appear {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.move-right-leave {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.move-right-enter.move-right-enter-active,
.move-right-appear.move-right-appear-active {
  animation-name: antMoveRightIn;
  animation-play-state: running;
}
.move-right-leave.move-right-leave-active {
  animation-name: antMoveRightOut;
  animation-play-state: running;
  pointer-events: none;
}
.move-right-enter,
.move-right-appear {
  opacity: 0;
  animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
}
.move-right-leave {
  animation-timing-function: cubic-bezier(0.6, 0.04, 0.98, 0.34);
}
@keyframes antMoveDownIn {
  0% {
    transform: translateY(100%);
    transform-origin: 0 0;
    opacity: 0;
  }
  100% {
    transform: translateY(0%);
    transform-origin: 0 0;
    opacity: 1;
  }
}
@keyframes antMoveDownOut {
  0% {
    transform: translateY(0%);
    transform-origin: 0 0;
    opacity: 1;
  }
  100% {
    transform: translateY(100%);
    transform-origin: 0 0;
    opacity: 0;
  }
}
@keyframes antMoveLeftIn {
  0% {
    transform: translateX(-100%);
    transform-origin: 0 0;
    opacity: 0;
  }
  100% {
    transform: translateX(0%);
    transform-origin: 0 0;
    opacity: 1;
  }
}
@keyframes antMoveLeftOut {
  0% {
    transform: translateX(0%);
    transform-origin: 0 0;
    opacity: 1;
  }
  100% {
    transform: translateX(-100%);
    transform-origin: 0 0;
    opacity: 0;
  }
}
@keyframes antMoveRightIn {
  0% {
    transform: translateX(100%);
    transform-origin: 0 0;
    opacity: 0;
  }
  100% {
    transform: translateX(0%);
    transform-origin: 0 0;
    opacity: 1;
  }
}
@keyframes antMoveRightOut {
  0% {
    transform: translateX(0%);
    transform-origin: 0 0;
    opacity: 1;
  }
  100% {
    transform: translateX(100%);
    transform-origin: 0 0;
    opacity: 0;
  }
}
@keyframes antMoveUpIn {
  0% {
    transform: translateY(-100%);
    transform-origin: 0 0;
    opacity: 0;
  }
  100% {
    transform: translateY(0%);
    transform-origin: 0 0;
    opacity: 1;
  }
}
@keyframes antMoveUpOut {
  0% {
    transform: translateY(0%);
    transform-origin: 0 0;
    opacity: 1;
  }
  100% {
    transform: translateY(-100%);
    transform-origin: 0 0;
    opacity: 0;
  }
}
@keyframes loadingCircle {
  100% {
    transform: rotate(360deg);
  }
}
[ant-click-animating='true'],
[ant-click-animating-without-extra-node='true'] {
  position: relative;
}
html {
  --antd-wave-shadow-color: #00a6ff;
}
[ant-click-animating-without-extra-node='true']::after,
.ant-click-animating-node {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: block;
  border-radius: inherit;
  box-shadow: 0 0 0 0 #00a6ff;
  box-shadow: 0 0 0 0 var(--antd-wave-shadow-color);
  opacity: 0.2;
  animation: fadeEffect 2s cubic-bezier(0.08, 0.82, 0.17, 1), waveEffect 0.4s cubic-bezier(0.08, 0.82, 0.17, 1);
  animation-fill-mode: forwards;
  content: '';
  pointer-events: none;
}
@keyframes waveEffect {
  100% {
    box-shadow: 0 0 0 #00a6ff;
    box-shadow: 0 0 0 6px var(--antd-wave-shadow-color);
  }
}
@keyframes fadeEffect {
  100% {
    opacity: 0;
  }
}
.slide-up-enter,
.slide-up-appear {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.slide-up-leave {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.slide-up-enter.slide-up-enter-active,
.slide-up-appear.slide-up-appear-active {
  animation-name: antSlideUpIn;
  animation-play-state: running;
}
.slide-up-leave.slide-up-leave-active {
  animation-name: antSlideUpOut;
  animation-play-state: running;
  pointer-events: none;
}
.slide-up-enter,
.slide-up-appear {
  opacity: 0;
  animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
}
.slide-up-leave {
  animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
}
.slide-down-enter,
.slide-down-appear {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.slide-down-leave {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.slide-down-enter.slide-down-enter-active,
.slide-down-appear.slide-down-appear-active {
  animation-name: antSlideDownIn;
  animation-play-state: running;
}
.slide-down-leave.slide-down-leave-active {
  animation-name: antSlideDownOut;
  animation-play-state: running;
  pointer-events: none;
}
.slide-down-enter,
.slide-down-appear {
  opacity: 0;
  animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
}
.slide-down-leave {
  animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
}
.slide-left-enter,
.slide-left-appear {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.slide-left-leave {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.slide-left-enter.slide-left-enter-active,
.slide-left-appear.slide-left-appear-active {
  animation-name: antSlideLeftIn;
  animation-play-state: running;
}
.slide-left-leave.slide-left-leave-active {
  animation-name: antSlideLeftOut;
  animation-play-state: running;
  pointer-events: none;
}
.slide-left-enter,
.slide-left-appear {
  opacity: 0;
  animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
}
.slide-left-leave {
  animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
}
.slide-right-enter,
.slide-right-appear {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.slide-right-leave {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.slide-right-enter.slide-right-enter-active,
.slide-right-appear.slide-right-appear-active {
  animation-name: antSlideRightIn;
  animation-play-state: running;
}
.slide-right-leave.slide-right-leave-active {
  animation-name: antSlideRightOut;
  animation-play-state: running;
  pointer-events: none;
}
.slide-right-enter,
.slide-right-appear {
  opacity: 0;
  animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
}
.slide-right-leave {
  animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
}
@keyframes antSlideUpIn {
  0% {
    transform: scaleY(0.8);
    transform-origin: 0% 0%;
    opacity: 0;
  }
  100% {
    transform: scaleY(1);
    transform-origin: 0% 0%;
    opacity: 1;
  }
}
@keyframes antSlideUpOut {
  0% {
    transform: scaleY(1);
    transform-origin: 0% 0%;
    opacity: 1;
  }
  100% {
    transform: scaleY(0.8);
    transform-origin: 0% 0%;
    opacity: 0;
  }
}
@keyframes antSlideDownIn {
  0% {
    transform: scaleY(0.8);
    transform-origin: 100% 100%;
    opacity: 0;
  }
  100% {
    transform: scaleY(1);
    transform-origin: 100% 100%;
    opacity: 1;
  }
}
@keyframes antSlideDownOut {
  0% {
    transform: scaleY(1);
    transform-origin: 100% 100%;
    opacity: 1;
  }
  100% {
    transform: scaleY(0.8);
    transform-origin: 100% 100%;
    opacity: 0;
  }
}
@keyframes antSlideLeftIn {
  0% {
    transform: scaleX(0.8);
    transform-origin: 0% 0%;
    opacity: 0;
  }
  100% {
    transform: scaleX(1);
    transform-origin: 0% 0%;
    opacity: 1;
  }
}
@keyframes antSlideLeftOut {
  0% {
    transform: scaleX(1);
    transform-origin: 0% 0%;
    opacity: 1;
  }
  100% {
    transform: scaleX(0.8);
    transform-origin: 0% 0%;
    opacity: 0;
  }
}
@keyframes antSlideRightIn {
  0% {
    transform: scaleX(0.8);
    transform-origin: 100% 0%;
    opacity: 0;
  }
  100% {
    transform: scaleX(1);
    transform-origin: 100% 0%;
    opacity: 1;
  }
}
@keyframes antSlideRightOut {
  0% {
    transform: scaleX(1);
    transform-origin: 100% 0%;
    opacity: 1;
  }
  100% {
    transform: scaleX(0.8);
    transform-origin: 100% 0%;
    opacity: 0;
  }
}
.swing-enter,
.swing-appear {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.swing-enter.swing-enter-active,
.swing-appear.swing-appear-active {
  animation-name: antSwingIn;
  animation-play-state: running;
}
@keyframes antSwingIn {
  0%,
  100% {
    transform: translateX(0);
  }
  20% {
    transform: translateX(-10px);
  }
  40% {
    transform: translateX(10px);
  }
  60% {
    transform: translateX(-5px);
  }
  80% {
    transform: translateX(5px);
  }
}
.zoom-enter,
.zoom-appear {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.zoom-leave {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.zoom-enter.zoom-enter-active,
.zoom-appear.zoom-appear-active {
  animation-name: antZoomIn;
  animation-play-state: running;
}
.zoom-leave.zoom-leave-active {
  animation-name: antZoomOut;
  animation-play-state: running;
  pointer-events: none;
}
.zoom-enter,
.zoom-appear {
  transform: scale(0);
  opacity: 0;
  animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
}
.zoom-leave {
  animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
}
.zoom-big-enter,
.zoom-big-appear {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.zoom-big-leave {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.zoom-big-enter.zoom-big-enter-active,
.zoom-big-appear.zoom-big-appear-active {
  animation-name: antZoomBigIn;
  animation-play-state: running;
}
.zoom-big-leave.zoom-big-leave-active {
  animation-name: antZoomBigOut;
  animation-play-state: running;
  pointer-events: none;
}
.zoom-big-enter,
.zoom-big-appear {
  transform: scale(0);
  opacity: 0;
  animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
}
.zoom-big-leave {
  animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
}
.zoom-big-fast-enter,
.zoom-big-fast-appear {
  animation-duration: 0.1s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.zoom-big-fast-leave {
  animation-duration: 0.1s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.zoom-big-fast-enter.zoom-big-fast-enter-active,
.zoom-big-fast-appear.zoom-big-fast-appear-active {
  animation-name: antZoomBigIn;
  animation-play-state: running;
}
.zoom-big-fast-leave.zoom-big-fast-leave-active {
  animation-name: antZoomBigOut;
  animation-play-state: running;
  pointer-events: none;
}
.zoom-big-fast-enter,
.zoom-big-fast-appear {
  transform: scale(0);
  opacity: 0;
  animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
}
.zoom-big-fast-leave {
  animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
}
.zoom-up-enter,
.zoom-up-appear {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.zoom-up-leave {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.zoom-up-enter.zoom-up-enter-active,
.zoom-up-appear.zoom-up-appear-active {
  animation-name: antZoomUpIn;
  animation-play-state: running;
}
.zoom-up-leave.zoom-up-leave-active {
  animation-name: antZoomUpOut;
  animation-play-state: running;
  pointer-events: none;
}
.zoom-up-enter,
.zoom-up-appear {
  transform: scale(0);
  opacity: 0;
  animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
}
.zoom-up-leave {
  animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
}
.zoom-down-enter,
.zoom-down-appear {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.zoom-down-leave {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.zoom-down-enter.zoom-down-enter-active,
.zoom-down-appear.zoom-down-appear-active {
  animation-name: antZoomDownIn;
  animation-play-state: running;
}
.zoom-down-leave.zoom-down-leave-active {
  animation-name: antZoomDownOut;
  animation-play-state: running;
  pointer-events: none;
}
.zoom-down-enter,
.zoom-down-appear {
  transform: scale(0);
  opacity: 0;
  animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
}
.zoom-down-leave {
  animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
}
.zoom-left-enter,
.zoom-left-appear {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.zoom-left-leave {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.zoom-left-enter.zoom-left-enter-active,
.zoom-left-appear.zoom-left-appear-active {
  animation-name: antZoomLeftIn;
  animation-play-state: running;
}
.zoom-left-leave.zoom-left-leave-active {
  animation-name: antZoomLeftOut;
  animation-play-state: running;
  pointer-events: none;
}
.zoom-left-enter,
.zoom-left-appear {
  transform: scale(0);
  opacity: 0;
  animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
}
.zoom-left-leave {
  animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
}
.zoom-right-enter,
.zoom-right-appear {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.zoom-right-leave {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.zoom-right-enter.zoom-right-enter-active,
.zoom-right-appear.zoom-right-appear-active {
  animation-name: antZoomRightIn;
  animation-play-state: running;
}
.zoom-right-leave.zoom-right-leave-active {
  animation-name: antZoomRightOut;
  animation-play-state: running;
  pointer-events: none;
}
.zoom-right-enter,
.zoom-right-appear {
  transform: scale(0);
  opacity: 0;
  animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
}
.zoom-right-leave {
  animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
}
@keyframes antZoomIn {
  0% {
    transform: scale(0.2);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}
@keyframes antZoomOut {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0.2);
    opacity: 0;
  }
}
@keyframes antZoomBigIn {
  0% {
    transform: scale(0.8);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}
@keyframes antZoomBigOut {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0.8);
    opacity: 0;
  }
}
@keyframes antZoomUpIn {
  0% {
    transform: scale(0.8);
    transform-origin: 50% 0%;
    opacity: 0;
  }
  100% {
    transform: scale(1);
    transform-origin: 50% 0%;
  }
}
@keyframes antZoomUpOut {
  0% {
    transform: scale(1);
    transform-origin: 50% 0%;
  }
  100% {
    transform: scale(0.8);
    transform-origin: 50% 0%;
    opacity: 0;
  }
}
@keyframes antZoomLeftIn {
  0% {
    transform: scale(0.8);
    transform-origin: 0% 50%;
    opacity: 0;
  }
  100% {
    transform: scale(1);
    transform-origin: 0% 50%;
  }
}
@keyframes antZoomLeftOut {
  0% {
    transform: scale(1);
    transform-origin: 0% 50%;
  }
  100% {
    transform: scale(0.8);
    transform-origin: 0% 50%;
    opacity: 0;
  }
}
@keyframes antZoomRightIn {
  0% {
    transform: scale(0.8);
    transform-origin: 100% 50%;
    opacity: 0;
  }
  100% {
    transform: scale(1);
    transform-origin: 100% 50%;
  }
}
@keyframes antZoomRightOut {
  0% {
    transform: scale(1);
    transform-origin: 100% 50%;
  }
  100% {
    transform: scale(0.8);
    transform-origin: 100% 50%;
    opacity: 0;
  }
}
@keyframes antZoomDownIn {
  0% {
    transform: scale(0.8);
    transform-origin: 50% 100%;
    opacity: 0;
  }
  100% {
    transform: scale(1);
    transform-origin: 50% 100%;
  }
}
@keyframes antZoomDownOut {
  0% {
    transform: scale(1);
    transform-origin: 50% 100%;
  }
  100% {
    transform: scale(0.8);
    transform-origin: 50% 100%;
    opacity: 0;
  }
}
.ant-motion-collapse-legacy {
  overflow: hidden;
}
.ant-motion-collapse-legacy-active {
  transition: height 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), opacity 0.15s cubic-bezier(0.645, 0.045, 0.355, 1) !important;
}
.ant-motion-collapse {
  overflow: hidden;
  transition: height 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), opacity 0.15s cubic-bezier(0.645, 0.045, 0.355, 1) !important;
}
.ant-affix {
  position: fixed;
  z-index: 10;
}
.ant-alert {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(34, 34, 34, 0.8);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: "tnum";
  position: relative;
  padding: 8px 15px 8px 37px;
  word-wrap: break-word;
  border-radius: 10px;
}
.ant-alert.ant-alert-no-icon {
  padding: 8px 15px;
}
.ant-alert.ant-alert-closable {
  padding-right: 30px;
}
.ant-alert-icon {
  position: absolute;
  top: 12.0005px;
  left: 16px;
}
.ant-alert-description {
  display: none;
  font-size: 14px;
  line-height: 22px;
}
.ant-alert-success {
  background-color: #f6ffed;
  border: 1px solid #b7eb8f;
}
.ant-alert-success .ant-alert-icon {
  color: #52c41a;
}
.ant-alert-info {
  background-color: #e6f7ff;
  border: 1px solid #91d5ff;
}
.ant-alert-info .ant-alert-icon {
  color: #1890ff;
}
.ant-alert-warning {
  background-color: #fffbe6;
  border: 1px solid #ffe58f;
}
.ant-alert-warning .ant-alert-icon {
  color: #faad14;
}
.ant-alert-error {
  background-color: #fff2f0;
  border: 1px solid #ffccc7;
}
.ant-alert-error .ant-alert-icon {
  color: #ff4d4f;
}
.ant-alert-close-icon {
  position: absolute;
  top: 8px;
  right: 16px;
  padding: 0;
  overflow: hidden;
  font-size: 12px;
  line-height: 22px;
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;
}
.ant-alert-close-icon .anticon-close {
  color: rgba(34, 34, 34, 0.45);
  transition: color 0.3s;
}
.ant-alert-close-icon .anticon-close:hover {
  color: rgba(34, 34, 34, 0.75);
}
.ant-alert-close-text {
  color: rgba(34, 34, 34, 0.45);
  transition: color 0.3s;
}
.ant-alert-close-text:hover {
  color: rgba(34, 34, 34, 0.75);
}
.ant-alert-with-description {
  position: relative;
  padding: 15px 15px 15px 64px;
  color: rgba(34, 34, 34, 0.8);
  line-height: 1.5715;
  border-radius: 10px;
}
.ant-alert-with-description.ant-alert-no-icon {
  padding: 15px;
}
.ant-alert-with-description .ant-alert-icon {
  position: absolute;
  top: 16px;
  left: 24px;
  font-size: 24px;
}
.ant-alert-with-description .ant-alert-close-icon {
  position: absolute;
  top: 16px;
  right: 16px;
  font-size: 14px;
  cursor: pointer;
}
.ant-alert-with-description .ant-alert-message {
  display: block;
  margin-bottom: 4px;
  color: rgba(255, 255, 255, 0.85);
  font-size: 14px;
}
.ant-alert-message {
  color: rgba(255, 255, 255, 0.85);
}
.ant-alert-with-description .ant-alert-description {
  display: block;
}
.ant-alert.ant-alert-closing {
  height: 0 !important;
  margin: 0;
  padding-top: 0;
  padding-bottom: 0;
  transform-origin: 50% 0;
  transition: all 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
}
.ant-alert-slide-up-leave {
  animation: antAlertSlideUpOut 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
  animation-fill-mode: both;
}
.ant-alert-banner {
  margin-bottom: 0;
  border: 0;
  border-radius: 0;
}
@keyframes antAlertSlideUpIn {
  0% {
    transform: scaleY(0);
    transform-origin: 0% 0%;
    opacity: 0;
  }
  100% {
    transform: scaleY(1);
    transform-origin: 0% 0%;
    opacity: 1;
  }
}
@keyframes antAlertSlideUpOut {
  0% {
    transform: scaleY(1);
    transform-origin: 0% 0%;
    opacity: 1;
  }
  100% {
    transform: scaleY(0);
    transform-origin: 0% 0%;
    opacity: 0;
  }
}
.ant-anchor {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(34, 34, 34, 0.8);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: "tnum";
  position: relative;
  padding-left: 2px;
}
.ant-anchor-wrapper {
  margin-left: -4px;
  padding-left: 4px;
  overflow: auto;
  background-color: #fff;
}
.ant-anchor-ink {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
}
.ant-anchor-ink::before {
  position: relative;
  display: block;
  width: 2px;
  height: 100%;
  margin: 0 auto;
  background-color: #f0f0f0;
  content: ' ';
}
.ant-anchor-ink-ball {
  position: absolute;
  left: 50%;
  display: none;
  width: 8px;
  height: 8px;
  background-color: #fff;
  border: 2px solid #00a6ff;
  border-radius: 8px;
  transform: translateX(-50%);
  transition: top 0.3s ease-in-out;
}
.ant-anchor-ink-ball.visible {
  display: inline-block;
}
.ant-anchor.fixed .ant-anchor-ink .ant-anchor-ink-ball {
  display: none;
}
.ant-anchor-link {
  padding: 7px 0 7px 16px;
  line-height: 1.143;
}
.ant-anchor-link-title {
  position: relative;
  display: block;
  margin-bottom: 6px;
  overflow: hidden;
  color: rgba(34, 34, 34, 0.8);
  white-space: nowrap;
  text-overflow: ellipsis;
  transition: all 0.3s;
}
.ant-anchor-link-title:only-child {
  margin-bottom: 0;
}
.ant-anchor-link-active > .ant-anchor-link-title {
  color: #00a6ff;
}
.ant-anchor-link .ant-anchor-link {
  padding-top: 5px;
  padding-bottom: 5px;
}
.ant-select-auto-complete {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(34, 34, 34, 0.8);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: "tnum";
}
.ant-select-auto-complete.ant-select .ant-select-selection {
  border: 0;
  box-shadow: none;
}
.ant-select-auto-complete.ant-select .ant-select-selection__rendered {
  height: 100%;
  margin-right: 0;
  margin-left: 0;
  line-height: 32px;
}
.ant-select-auto-complete.ant-select .ant-select-selection__placeholder {
  margin-right: 12px;
  margin-left: 12px;
}
.ant-select-auto-complete.ant-select .ant-select-selection--single {
  height: auto;
}
.ant-select-auto-complete.ant-select .ant-select-search--inline {
  position: static;
  float: left;
}
.ant-select-auto-complete.ant-select-allow-clear .ant-select-selection:hover .ant-select-selection__rendered {
  margin-right: 0 !important;
}
.ant-select-auto-complete.ant-select .ant-input {
  height: 32px;
  line-height: 1.5715;
  background: transparent;
  border-width: 1px;
}
.ant-select-auto-complete.ant-select .ant-input:focus,
.ant-select-auto-complete.ant-select .ant-input:hover {
  border-color: #333;
  border-right-width: 1px !important;
}
.ant-select-auto-complete.ant-select .ant-input[disabled] {
  color: #fff;
  background-color: #cccccc;
  cursor: not-allowed;
  opacity: 1;
  background-color: transparent;
}
.ant-select-auto-complete.ant-select .ant-input[disabled]:hover {
  border-color: #bfbfbf;
  border-right-width: 1px !important;
}
.ant-select-auto-complete.ant-select-lg .ant-select-selection__rendered {
  line-height: 45px;
}
.ant-select-auto-complete.ant-select-lg .ant-input {
  height: 45px;
  padding-top: 10.5px;
  padding-bottom: 10.5px;
}
.ant-select-auto-complete.ant-select-sm .ant-select-selection__rendered {
  line-height: 24px;
}
.ant-select-auto-complete.ant-select-sm .ant-input {
  height: 24px;
  padding-top: 0px;
  padding-bottom: 0px;
}
.ant-input-group > .ant-select-auto-complete .ant-select-search__field.ant-input-affix-wrapper {
  display: inline;
  float: none;
}
.ant-avatar {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(34, 34, 34, 0.8);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: "tnum";
  position: relative;
  display: inline-block;
  overflow: hidden;
  color: #fff;
  white-space: nowrap;
  text-align: center;
  vertical-align: middle;
  background: #ccc;
  width: 32px;
  height: 32px;
  line-height: 32px;
  border-radius: 50%;
}
.ant-avatar-image {
  background: transparent;
}
.ant-avatar-string {
  position: absolute;
  left: 50%;
  transform-origin: 0 center;
}
.ant-avatar.ant-avatar-icon {
  font-size: 18px;
}
.ant-avatar-lg {
  width: 40px;
  height: 40px;
  line-height: 40px;
  border-radius: 50%;
}
.ant-avatar-lg-string {
  position: absolute;
  left: 50%;
  transform-origin: 0 center;
}
.ant-avatar-lg.ant-avatar-icon {
  font-size: 24px;
}
.ant-avatar-sm {
  width: 24px;
  height: 24px;
  line-height: 24px;
  border-radius: 50%;
}
.ant-avatar-sm-string {
  position: absolute;
  left: 50%;
  transform-origin: 0 center;
}
.ant-avatar-sm.ant-avatar-icon {
  font-size: 14px;
}
.ant-avatar-square {
  border-radius: 10px;
}
.ant-avatar > img {
  display: block;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
.ant-back-top {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(34, 34, 34, 0.8);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: "tnum";
  position: fixed;
  right: 100px;
  bottom: 50px;
  z-index: 10;
  width: 40px;
  height: 40px;
  cursor: pointer;
}
.ant-back-top-content {
  width: 40px;
  height: 40px;
  overflow: hidden;
  color: #fff;
  text-align: center;
  background-color: rgba(34, 34, 34, 0.45);
  border-radius: 20px;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.ant-back-top-content:hover {
  background-color: rgba(34, 34, 34, 0.8);
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.ant-back-top-icon {
  width: 14px;
  height: 16px;
  margin: 12px auto;
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACQAAAAoCAYAAACWwljjAAAABGdBTUEAALGPC/xhBQAAAbtJREFUWAntmMtKw0AUhhMvS5cuxILgQlRUpIggIoKIIoigG1eC+AA+jo+i6FIXBfeuXIgoeKVeitVWJX5HWhhDksnUpp3FDPyZk3Nm5nycmZKkXhAEOXSA3lG7muTeRzmfy6HneUvIhnYkQK+Q9NhAA0Opg0vBEhjBKHiyb8iGMyQMOYuK41BcBSypAL+MYXSKjtFAW7EAGEO3qN4uMQbbAkXiSfRQJ1H6a+yhlkKRcAoVFYiweYNjtCVQJJpBz2GCiPt7fBOZQpFgDpUikse5HgnkM4Fi4QX0Fpc5wf9EbLqpUCy4jMoJSXWhFwbMNgWKhVbRhy5jirhs9fy/oFhgHVVTJEs7RLZ8sSEoJm6iz7SZDMbJ+/OKERQTttCXQRLToRUmrKWCYuA2+jbN0MB4OQobYShfdTCgn/sL1K36M7TLrN3n+758aPy2rrpR6+/od5E8tf/A1uLS9aId5T7J3CNYihkQ4D9PiMdMC7mp4rjB9kjFjZp8BlnVHJBuO1yFXIV0FdDF3RlyFdJVQBdv5AxVdIsq8apiZ2PyYO1EVykesGfZEESsCkweyR8MUW+V8uJ1gkYipmpdP1pm2aJVPEGzAAAAAElFTkSuQmCC) 100%/100% no-repeat;
}
@media screen and (max-width: 768px) {
  .ant-back-top {
    right: 60px;
  }
}
@media screen and (max-width: 480px) {
  .ant-back-top {
    right: 20px;
  }
}
.ant-badge {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(34, 34, 34, 0.8);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: "tnum";
  position: relative;
  display: inline-block;
  color: unset;
  line-height: 1;
}
.ant-badge-count {
  min-width: 20px;
  height: 20px;
  padding: 0 6px;
  color: #fff;
  font-weight: normal;
  font-size: 12px;
  line-height: 20px;
  white-space: nowrap;
  text-align: center;
  background: #ff4d4f;
  border-radius: 10px;
  box-shadow: 0 0 0 1px #fff;
}
.ant-badge-count a,
.ant-badge-count a:hover {
  color: #fff;
}
.ant-badge-multiple-words {
  padding: 0 8px;
}
.ant-badge-dot {
  width: 6px;
  height: 6px;
  background: #ff4d4f;
  border-radius: 100%;
  box-shadow: 0 0 0 1px #fff;
}
.ant-badge-count,
.ant-badge-dot,
.ant-badge .ant-scroll-number-custom-component {
  position: absolute;
  top: 0;
  right: 0;
  z-index: auto;
  transform: translate(50%, -50%);
  transform-origin: 100% 0%;
}
.ant-badge-status {
  line-height: inherit;
  vertical-align: baseline;
}
.ant-badge-status-dot {
  position: relative;
  top: -1px;
  display: inline-block;
  width: 6px;
  height: 6px;
  vertical-align: middle;
  border-radius: 50%;
}
.ant-badge-status-success {
  background-color: #52c41a;
}
.ant-badge-status-processing {
  position: relative;
  background-color: #1890ff;
}
.ant-badge-status-processing::after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 1px solid #1890ff;
  border-radius: 50%;
  animation: antStatusProcessing 1.2s infinite ease-in-out;
  content: '';
}
.ant-badge-status-default {
  background-color: #d9d9d9;
}
.ant-badge-status-error {
  background-color: #ff4d4f;
}
.ant-badge-status-warning {
  background-color: #faad14;
}
.ant-badge-status-pink {
  background: #eb2f96;
}
.ant-badge-status-magenta {
  background: #eb2f96;
}
.ant-badge-status-red {
  background: #f5222d;
}
.ant-badge-status-volcano {
  background: #fa541c;
}
.ant-badge-status-orange {
  background: #fa8c16;
}
.ant-badge-status-yellow {
  background: #fadb14;
}
.ant-badge-status-gold {
  background: #faad14;
}
.ant-badge-status-cyan {
  background: #13c2c2;
}
.ant-badge-status-lime {
  background: #a0d911;
}
.ant-badge-status-green {
  background: #52c41a;
}
.ant-badge-status-blue {
  background: #1890ff;
}
.ant-badge-status-geekblue {
  background: #2f54eb;
}
.ant-badge-status-purple {
  background: #722ed1;
}
.ant-badge-status-text {
  margin-left: 8px;
  color: rgba(34, 34, 34, 0.8);
  font-size: 14px;
}
.ant-badge-zoom-appear,
.ant-badge-zoom-enter {
  animation: antZoomBadgeIn 0.3s cubic-bezier(0.12, 0.4, 0.29, 1.46);
  animation-fill-mode: both;
}
.ant-badge-zoom-leave {
  animation: antZoomBadgeOut 0.3s cubic-bezier(0.71, -0.46, 0.88, 0.6);
  animation-fill-mode: both;
}
.ant-badge-not-a-wrapper:not(.ant-badge-status) {
  vertical-align: middle;
}
.ant-badge-not-a-wrapper .ant-scroll-number {
  position: relative;
  top: auto;
  display: block;
}
.ant-badge-not-a-wrapper .ant-badge-count {
  transform: none;
}
@keyframes antStatusProcessing {
  0% {
    transform: scale(0.8);
    opacity: 0.5;
  }
  100% {
    transform: scale(2.4);
    opacity: 0;
  }
}
.ant-scroll-number {
  overflow: hidden;
}
.ant-scroll-number-only {
  display: inline-block;
  height: 20px;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.ant-scroll-number-only > p.ant-scroll-number-only-unit {
  height: 20px;
  margin: 0;
}
.ant-scroll-number-symbol {
  vertical-align: top;
}
@keyframes antZoomBadgeIn {
  0% {
    transform: scale(0) translate(50%, -50%);
    opacity: 0;
  }
  100% {
    transform: scale(1) translate(50%, -50%);
  }
}
@keyframes antZoomBadgeOut {
  0% {
    transform: scale(1) translate(50%, -50%);
  }
  100% {
    transform: scale(0) translate(50%, -50%);
    opacity: 0;
  }
}
.ant-breadcrumb {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(34, 34, 34, 0.8);
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: "tnum";
  color: rgba(34, 34, 34, 0.45);
  font-size: 14px;
}
.ant-breadcrumb .anticon {
  font-size: 14px;
}
.ant-breadcrumb a {
  color: rgba(34, 34, 34, 0.45);
  transition: color 0.3s;
}
.ant-breadcrumb a:hover {
  color: #29bbff;
}
.ant-breadcrumb > span:last-child {
  color: rgba(34, 34, 34, 0.8);
}
.ant-breadcrumb > span:last-child a {
  color: rgba(34, 34, 34, 0.8);
}
.ant-breadcrumb > span:last-child .ant-breadcrumb-separator {
  display: none;
}
.ant-breadcrumb-separator {
  margin: 0 8px;
  color: rgba(34, 34, 34, 0.45);
}
.ant-breadcrumb-link > .anticon + span {
  margin-left: 4px;
}
.ant-breadcrumb-overlay-link > .anticon {
  margin-left: 4px;
}
.ant-btn {
  line-height: 1.5705;
  position: relative;
  display: inline-block;
  font-weight: 400;
  white-space: nowrap;
  text-align: center;
  background-image: none;
  border: 1px solid transparent;
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.015);
  cursor: pointer;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  touch-action: manipulation;
  height: 32px;
  padding: 0 15px;
  font-size: 14px;
  border-radius: 10px;
  color: #666;
  background-color: #fff;
  border-color: #ccc;
}
.ant-btn > .anticon {
  line-height: 1;
}
.ant-btn,
.ant-btn:active,
.ant-btn:focus {
  outline: 0;
}
.ant-btn:not([disabled]):hover {
  text-decoration: none;
}
.ant-btn:not([disabled]):active {
  outline: 0;
  box-shadow: none;
}
.ant-btn.disabled,
.ant-btn[disabled] {
  cursor: not-allowed;
}
.ant-btn.disabled > *,
.ant-btn[disabled] > * {
  pointer-events: none;
}
.ant-btn-lg {
  height: 40px;
  padding: 0 15px;
  font-size: 14px;
  border-radius: 10px;
}
.ant-btn-sm {
  height: 24px;
  padding: 0 7px;
  font-size: 14px;
  border-radius: 10px;
}
.ant-btn > a:only-child {
  color: currentColor;
}
.ant-btn > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn:hover,
.ant-btn:focus {
  color: #29bbff;
  background-color: #fff;
  border-color: #29bbff;
}
.ant-btn:hover > a:only-child,
.ant-btn:focus > a:only-child {
  color: currentColor;
}
.ant-btn:hover > a:only-child::after,
.ant-btn:focus > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn:active,
.ant-btn.active {
  color: #0086d9;
  background-color: #fff;
  border-color: #0086d9;
}
.ant-btn:active > a:only-child,
.ant-btn.active > a:only-child {
  color: currentColor;
}
.ant-btn:active > a:only-child::after,
.ant-btn.active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-disabled,
.ant-btn.disabled,
.ant-btn[disabled],
.ant-btn-disabled:hover,
.ant-btn.disabled:hover,
.ant-btn[disabled]:hover,
.ant-btn-disabled:focus,
.ant-btn.disabled:focus,
.ant-btn[disabled]:focus,
.ant-btn-disabled:active,
.ant-btn.disabled:active,
.ant-btn[disabled]:active,
.ant-btn-disabled.active,
.ant-btn.disabled.active,
.ant-btn[disabled].active {
  color: #fff;
  background-color: #cccccc;
  border-color: #bfbfbf;
  text-shadow: none;
  box-shadow: none;
}
.ant-btn-disabled > a:only-child,
.ant-btn.disabled > a:only-child,
.ant-btn[disabled] > a:only-child,
.ant-btn-disabled:hover > a:only-child,
.ant-btn.disabled:hover > a:only-child,
.ant-btn[disabled]:hover > a:only-child,
.ant-btn-disabled:focus > a:only-child,
.ant-btn.disabled:focus > a:only-child,
.ant-btn[disabled]:focus > a:only-child,
.ant-btn-disabled:active > a:only-child,
.ant-btn.disabled:active > a:only-child,
.ant-btn[disabled]:active > a:only-child,
.ant-btn-disabled.active > a:only-child,
.ant-btn.disabled.active > a:only-child,
.ant-btn[disabled].active > a:only-child {
  color: currentColor;
}
.ant-btn-disabled > a:only-child::after,
.ant-btn.disabled > a:only-child::after,
.ant-btn[disabled] > a:only-child::after,
.ant-btn-disabled:hover > a:only-child::after,
.ant-btn.disabled:hover > a:only-child::after,
.ant-btn[disabled]:hover > a:only-child::after,
.ant-btn-disabled:focus > a:only-child::after,
.ant-btn.disabled:focus > a:only-child::after,
.ant-btn[disabled]:focus > a:only-child::after,
.ant-btn-disabled:active > a:only-child::after,
.ant-btn.disabled:active > a:only-child::after,
.ant-btn[disabled]:active > a:only-child::after,
.ant-btn-disabled.active > a:only-child::after,
.ant-btn.disabled.active > a:only-child::after,
.ant-btn[disabled].active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn:hover,
.ant-btn:focus,
.ant-btn:active,
.ant-btn.active {
  text-decoration: none;
  background: #fff;
}
.ant-btn > i,
.ant-btn > span {
  display: inline-block;
  transition: margin-left 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  pointer-events: none;
}
.ant-btn-primary {
  color: #fff;
  background-color: #00a6ff;
  border-color: #00a6ff;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
}
.ant-btn-primary > a:only-child {
  color: currentColor;
}
.ant-btn-primary > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-primary:hover,
.ant-btn-primary:focus {
  color: #fff;
  background-color: #29bbff;
  border-color: #29bbff;
}
.ant-btn-primary:hover > a:only-child,
.ant-btn-primary:focus > a:only-child {
  color: currentColor;
}
.ant-btn-primary:hover > a:only-child::after,
.ant-btn-primary:focus > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-primary:active,
.ant-btn-primary.active {
  color: #fff;
  background-color: #0086d9;
  border-color: #0086d9;
}
.ant-btn-primary:active > a:only-child,
.ant-btn-primary.active > a:only-child {
  color: currentColor;
}
.ant-btn-primary:active > a:only-child::after,
.ant-btn-primary.active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-primary-disabled,
.ant-btn-primary.disabled,
.ant-btn-primary[disabled],
.ant-btn-primary-disabled:hover,
.ant-btn-primary.disabled:hover,
.ant-btn-primary[disabled]:hover,
.ant-btn-primary-disabled:focus,
.ant-btn-primary.disabled:focus,
.ant-btn-primary[disabled]:focus,
.ant-btn-primary-disabled:active,
.ant-btn-primary.disabled:active,
.ant-btn-primary[disabled]:active,
.ant-btn-primary-disabled.active,
.ant-btn-primary.disabled.active,
.ant-btn-primary[disabled].active {
  color: #fff;
  background-color: #cccccc;
  border-color: #bfbfbf;
  text-shadow: none;
  box-shadow: none;
}
.ant-btn-primary-disabled > a:only-child,
.ant-btn-primary.disabled > a:only-child,
.ant-btn-primary[disabled] > a:only-child,
.ant-btn-primary-disabled:hover > a:only-child,
.ant-btn-primary.disabled:hover > a:only-child,
.ant-btn-primary[disabled]:hover > a:only-child,
.ant-btn-primary-disabled:focus > a:only-child,
.ant-btn-primary.disabled:focus > a:only-child,
.ant-btn-primary[disabled]:focus > a:only-child,
.ant-btn-primary-disabled:active > a:only-child,
.ant-btn-primary.disabled:active > a:only-child,
.ant-btn-primary[disabled]:active > a:only-child,
.ant-btn-primary-disabled.active > a:only-child,
.ant-btn-primary.disabled.active > a:only-child,
.ant-btn-primary[disabled].active > a:only-child {
  color: currentColor;
}
.ant-btn-primary-disabled > a:only-child::after,
.ant-btn-primary.disabled > a:only-child::after,
.ant-btn-primary[disabled] > a:only-child::after,
.ant-btn-primary-disabled:hover > a:only-child::after,
.ant-btn-primary.disabled:hover > a:only-child::after,
.ant-btn-primary[disabled]:hover > a:only-child::after,
.ant-btn-primary-disabled:focus > a:only-child::after,
.ant-btn-primary.disabled:focus > a:only-child::after,
.ant-btn-primary[disabled]:focus > a:only-child::after,
.ant-btn-primary-disabled:active > a:only-child::after,
.ant-btn-primary.disabled:active > a:only-child::after,
.ant-btn-primary[disabled]:active > a:only-child::after,
.ant-btn-primary-disabled.active > a:only-child::after,
.ant-btn-primary.disabled.active > a:only-child::after,
.ant-btn-primary[disabled].active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-group .ant-btn-primary:not(:first-child):not(:last-child) {
  border-right-color: #29bbff;
  border-left-color: #29bbff;
}
.ant-btn-group .ant-btn-primary:not(:first-child):not(:last-child):disabled {
  border-color: #ccc;
}
.ant-btn-group .ant-btn-primary:first-child:not(:last-child) {
  border-right-color: #29bbff;
}
.ant-btn-group .ant-btn-primary:first-child:not(:last-child)[disabled] {
  border-right-color: #ccc;
}
.ant-btn-group .ant-btn-primary:last-child:not(:first-child),
.ant-btn-group .ant-btn-primary + .ant-btn-primary {
  border-left-color: #29bbff;
}
.ant-btn-group .ant-btn-primary:last-child:not(:first-child)[disabled],
.ant-btn-group .ant-btn-primary + .ant-btn-primary[disabled] {
  border-left-color: #ccc;
}
.ant-btn-ghost {
  color: rgba(34, 34, 34, 0.8);
  background-color: transparent;
  border-color: #bfbfbf;
}
.ant-btn-ghost > a:only-child {
  color: currentColor;
}
.ant-btn-ghost > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-ghost:hover,
.ant-btn-ghost:focus {
  color: #29bbff;
  background-color: transparent;
  border-color: #29bbff;
}
.ant-btn-ghost:hover > a:only-child,
.ant-btn-ghost:focus > a:only-child {
  color: currentColor;
}
.ant-btn-ghost:hover > a:only-child::after,
.ant-btn-ghost:focus > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-ghost:active,
.ant-btn-ghost.active {
  color: #0086d9;
  background-color: transparent;
  border-color: #0086d9;
}
.ant-btn-ghost:active > a:only-child,
.ant-btn-ghost.active > a:only-child {
  color: currentColor;
}
.ant-btn-ghost:active > a:only-child::after,
.ant-btn-ghost.active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-ghost-disabled,
.ant-btn-ghost.disabled,
.ant-btn-ghost[disabled],
.ant-btn-ghost-disabled:hover,
.ant-btn-ghost.disabled:hover,
.ant-btn-ghost[disabled]:hover,
.ant-btn-ghost-disabled:focus,
.ant-btn-ghost.disabled:focus,
.ant-btn-ghost[disabled]:focus,
.ant-btn-ghost-disabled:active,
.ant-btn-ghost.disabled:active,
.ant-btn-ghost[disabled]:active,
.ant-btn-ghost-disabled.active,
.ant-btn-ghost.disabled.active,
.ant-btn-ghost[disabled].active {
  color: #fff;
  background-color: #cccccc;
  border-color: #bfbfbf;
  text-shadow: none;
  box-shadow: none;
}
.ant-btn-ghost-disabled > a:only-child,
.ant-btn-ghost.disabled > a:only-child,
.ant-btn-ghost[disabled] > a:only-child,
.ant-btn-ghost-disabled:hover > a:only-child,
.ant-btn-ghost.disabled:hover > a:only-child,
.ant-btn-ghost[disabled]:hover > a:only-child,
.ant-btn-ghost-disabled:focus > a:only-child,
.ant-btn-ghost.disabled:focus > a:only-child,
.ant-btn-ghost[disabled]:focus > a:only-child,
.ant-btn-ghost-disabled:active > a:only-child,
.ant-btn-ghost.disabled:active > a:only-child,
.ant-btn-ghost[disabled]:active > a:only-child,
.ant-btn-ghost-disabled.active > a:only-child,
.ant-btn-ghost.disabled.active > a:only-child,
.ant-btn-ghost[disabled].active > a:only-child {
  color: currentColor;
}
.ant-btn-ghost-disabled > a:only-child::after,
.ant-btn-ghost.disabled > a:only-child::after,
.ant-btn-ghost[disabled] > a:only-child::after,
.ant-btn-ghost-disabled:hover > a:only-child::after,
.ant-btn-ghost.disabled:hover > a:only-child::after,
.ant-btn-ghost[disabled]:hover > a:only-child::after,
.ant-btn-ghost-disabled:focus > a:only-child::after,
.ant-btn-ghost.disabled:focus > a:only-child::after,
.ant-btn-ghost[disabled]:focus > a:only-child::after,
.ant-btn-ghost-disabled:active > a:only-child::after,
.ant-btn-ghost.disabled:active > a:only-child::after,
.ant-btn-ghost[disabled]:active > a:only-child::after,
.ant-btn-ghost-disabled.active > a:only-child::after,
.ant-btn-ghost.disabled.active > a:only-child::after,
.ant-btn-ghost[disabled].active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-dashed {
  color: #666;
  background-color: #fff;
  border-color: #ccc;
  border-style: dashed;
}
.ant-btn-dashed > a:only-child {
  color: currentColor;
}
.ant-btn-dashed > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-dashed:hover,
.ant-btn-dashed:focus {
  color: #29bbff;
  background-color: #fff;
  border-color: #29bbff;
}
.ant-btn-dashed:hover > a:only-child,
.ant-btn-dashed:focus > a:only-child {
  color: currentColor;
}
.ant-btn-dashed:hover > a:only-child::after,
.ant-btn-dashed:focus > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-dashed:active,
.ant-btn-dashed.active {
  color: #0086d9;
  background-color: #fff;
  border-color: #0086d9;
}
.ant-btn-dashed:active > a:only-child,
.ant-btn-dashed.active > a:only-child {
  color: currentColor;
}
.ant-btn-dashed:active > a:only-child::after,
.ant-btn-dashed.active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-dashed-disabled,
.ant-btn-dashed.disabled,
.ant-btn-dashed[disabled],
.ant-btn-dashed-disabled:hover,
.ant-btn-dashed.disabled:hover,
.ant-btn-dashed[disabled]:hover,
.ant-btn-dashed-disabled:focus,
.ant-btn-dashed.disabled:focus,
.ant-btn-dashed[disabled]:focus,
.ant-btn-dashed-disabled:active,
.ant-btn-dashed.disabled:active,
.ant-btn-dashed[disabled]:active,
.ant-btn-dashed-disabled.active,
.ant-btn-dashed.disabled.active,
.ant-btn-dashed[disabled].active {
  color: #fff;
  background-color: #cccccc;
  border-color: #bfbfbf;
  text-shadow: none;
  box-shadow: none;
}
.ant-btn-dashed-disabled > a:only-child,
.ant-btn-dashed.disabled > a:only-child,
.ant-btn-dashed[disabled] > a:only-child,
.ant-btn-dashed-disabled:hover > a:only-child,
.ant-btn-dashed.disabled:hover > a:only-child,
.ant-btn-dashed[disabled]:hover > a:only-child,
.ant-btn-dashed-disabled:focus > a:only-child,
.ant-btn-dashed.disabled:focus > a:only-child,
.ant-btn-dashed[disabled]:focus > a:only-child,
.ant-btn-dashed-disabled:active > a:only-child,
.ant-btn-dashed.disabled:active > a:only-child,
.ant-btn-dashed[disabled]:active > a:only-child,
.ant-btn-dashed-disabled.active > a:only-child,
.ant-btn-dashed.disabled.active > a:only-child,
.ant-btn-dashed[disabled].active > a:only-child {
  color: currentColor;
}
.ant-btn-dashed-disabled > a:only-child::after,
.ant-btn-dashed.disabled > a:only-child::after,
.ant-btn-dashed[disabled] > a:only-child::after,
.ant-btn-dashed-disabled:hover > a:only-child::after,
.ant-btn-dashed.disabled:hover > a:only-child::after,
.ant-btn-dashed[disabled]:hover > a:only-child::after,
.ant-btn-dashed-disabled:focus > a:only-child::after,
.ant-btn-dashed.disabled:focus > a:only-child::after,
.ant-btn-dashed[disabled]:focus > a:only-child::after,
.ant-btn-dashed-disabled:active > a:only-child::after,
.ant-btn-dashed.disabled:active > a:only-child::after,
.ant-btn-dashed[disabled]:active > a:only-child::after,
.ant-btn-dashed-disabled.active > a:only-child::after,
.ant-btn-dashed.disabled.active > a:only-child::after,
.ant-btn-dashed[disabled].active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-danger {
  color: #fff;
  background-color: #1c8eff;
  border-color: #1c8eff;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
}
.ant-btn-danger > a:only-child {
  color: currentColor;
}
.ant-btn-danger > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-danger:hover,
.ant-btn-danger:focus {
  color: #fff;
  background-color: #45a8ff;
  border-color: #45a8ff;
}
.ant-btn-danger:hover > a:only-child,
.ant-btn-danger:focus > a:only-child {
  color: currentColor;
}
.ant-btn-danger:hover > a:only-child::after,
.ant-btn-danger:focus > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-danger:active,
.ant-btn-danger.active {
  color: #fff;
  background-color: #0d6cd9;
  border-color: #0d6cd9;
}
.ant-btn-danger:active > a:only-child,
.ant-btn-danger.active > a:only-child {
  color: currentColor;
}
.ant-btn-danger:active > a:only-child::after,
.ant-btn-danger.active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-danger-disabled,
.ant-btn-danger.disabled,
.ant-btn-danger[disabled],
.ant-btn-danger-disabled:hover,
.ant-btn-danger.disabled:hover,
.ant-btn-danger[disabled]:hover,
.ant-btn-danger-disabled:focus,
.ant-btn-danger.disabled:focus,
.ant-btn-danger[disabled]:focus,
.ant-btn-danger-disabled:active,
.ant-btn-danger.disabled:active,
.ant-btn-danger[disabled]:active,
.ant-btn-danger-disabled.active,
.ant-btn-danger.disabled.active,
.ant-btn-danger[disabled].active {
  color: #fff;
  background-color: #cccccc;
  border-color: #bfbfbf;
  text-shadow: none;
  box-shadow: none;
}
.ant-btn-danger-disabled > a:only-child,
.ant-btn-danger.disabled > a:only-child,
.ant-btn-danger[disabled] > a:only-child,
.ant-btn-danger-disabled:hover > a:only-child,
.ant-btn-danger.disabled:hover > a:only-child,
.ant-btn-danger[disabled]:hover > a:only-child,
.ant-btn-danger-disabled:focus > a:only-child,
.ant-btn-danger.disabled:focus > a:only-child,
.ant-btn-danger[disabled]:focus > a:only-child,
.ant-btn-danger-disabled:active > a:only-child,
.ant-btn-danger.disabled:active > a:only-child,
.ant-btn-danger[disabled]:active > a:only-child,
.ant-btn-danger-disabled.active > a:only-child,
.ant-btn-danger.disabled.active > a:only-child,
.ant-btn-danger[disabled].active > a:only-child {
  color: currentColor;
}
.ant-btn-danger-disabled > a:only-child::after,
.ant-btn-danger.disabled > a:only-child::after,
.ant-btn-danger[disabled] > a:only-child::after,
.ant-btn-danger-disabled:hover > a:only-child::after,
.ant-btn-danger.disabled:hover > a:only-child::after,
.ant-btn-danger[disabled]:hover > a:only-child::after,
.ant-btn-danger-disabled:focus > a:only-child::after,
.ant-btn-danger.disabled:focus > a:only-child::after,
.ant-btn-danger[disabled]:focus > a:only-child::after,
.ant-btn-danger-disabled:active > a:only-child::after,
.ant-btn-danger.disabled:active > a:only-child::after,
.ant-btn-danger[disabled]:active > a:only-child::after,
.ant-btn-danger-disabled.active > a:only-child::after,
.ant-btn-danger.disabled.active > a:only-child::after,
.ant-btn-danger[disabled].active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-link {
  color: #1c8eff;
  background-color: transparent;
  border-color: transparent;
  box-shadow: none;
}
.ant-btn-link > a:only-child {
  color: currentColor;
}
.ant-btn-link > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-link:hover,
.ant-btn-link:focus {
  color: #29bbff;
  background-color: transparent;
  border-color: #29bbff;
}
.ant-btn-link:hover > a:only-child,
.ant-btn-link:focus > a:only-child {
  color: currentColor;
}
.ant-btn-link:hover > a:only-child::after,
.ant-btn-link:focus > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-link:active,
.ant-btn-link.active {
  color: #0086d9;
  background-color: transparent;
  border-color: #0086d9;
}
.ant-btn-link:active > a:only-child,
.ant-btn-link.active > a:only-child {
  color: currentColor;
}
.ant-btn-link:active > a:only-child::after,
.ant-btn-link.active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-link-disabled,
.ant-btn-link.disabled,
.ant-btn-link[disabled],
.ant-btn-link-disabled:hover,
.ant-btn-link.disabled:hover,
.ant-btn-link[disabled]:hover,
.ant-btn-link-disabled:focus,
.ant-btn-link.disabled:focus,
.ant-btn-link[disabled]:focus,
.ant-btn-link-disabled:active,
.ant-btn-link.disabled:active,
.ant-btn-link[disabled]:active,
.ant-btn-link-disabled.active,
.ant-btn-link.disabled.active,
.ant-btn-link[disabled].active {
  color: #fff;
  background-color: #cccccc;
  border-color: #bfbfbf;
  text-shadow: none;
  box-shadow: none;
}
.ant-btn-link-disabled > a:only-child,
.ant-btn-link.disabled > a:only-child,
.ant-btn-link[disabled] > a:only-child,
.ant-btn-link-disabled:hover > a:only-child,
.ant-btn-link.disabled:hover > a:only-child,
.ant-btn-link[disabled]:hover > a:only-child,
.ant-btn-link-disabled:focus > a:only-child,
.ant-btn-link.disabled:focus > a:only-child,
.ant-btn-link[disabled]:focus > a:only-child,
.ant-btn-link-disabled:active > a:only-child,
.ant-btn-link.disabled:active > a:only-child,
.ant-btn-link[disabled]:active > a:only-child,
.ant-btn-link-disabled.active > a:only-child,
.ant-btn-link.disabled.active > a:only-child,
.ant-btn-link[disabled].active > a:only-child {
  color: currentColor;
}
.ant-btn-link-disabled > a:only-child::after,
.ant-btn-link.disabled > a:only-child::after,
.ant-btn-link[disabled] > a:only-child::after,
.ant-btn-link-disabled:hover > a:only-child::after,
.ant-btn-link.disabled:hover > a:only-child::after,
.ant-btn-link[disabled]:hover > a:only-child::after,
.ant-btn-link-disabled:focus > a:only-child::after,
.ant-btn-link.disabled:focus > a:only-child::after,
.ant-btn-link[disabled]:focus > a:only-child::after,
.ant-btn-link-disabled:active > a:only-child::after,
.ant-btn-link.disabled:active > a:only-child::after,
.ant-btn-link[disabled]:active > a:only-child::after,
.ant-btn-link-disabled.active > a:only-child::after,
.ant-btn-link.disabled.active > a:only-child::after,
.ant-btn-link[disabled].active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-link:hover,
.ant-btn-link:focus,
.ant-btn-link:active {
  border-color: transparent;
}
.ant-btn-link-disabled,
.ant-btn-link.disabled,
.ant-btn-link[disabled],
.ant-btn-link-disabled:hover,
.ant-btn-link.disabled:hover,
.ant-btn-link[disabled]:hover,
.ant-btn-link-disabled:focus,
.ant-btn-link.disabled:focus,
.ant-btn-link[disabled]:focus,
.ant-btn-link-disabled:active,
.ant-btn-link.disabled:active,
.ant-btn-link[disabled]:active,
.ant-btn-link-disabled.active,
.ant-btn-link.disabled.active,
.ant-btn-link[disabled].active {
  color: #fff;
  background-color: transparent;
  border-color: transparent;
  text-shadow: none;
  box-shadow: none;
}
.ant-btn-link-disabled > a:only-child,
.ant-btn-link.disabled > a:only-child,
.ant-btn-link[disabled] > a:only-child,
.ant-btn-link-disabled:hover > a:only-child,
.ant-btn-link.disabled:hover > a:only-child,
.ant-btn-link[disabled]:hover > a:only-child,
.ant-btn-link-disabled:focus > a:only-child,
.ant-btn-link.disabled:focus > a:only-child,
.ant-btn-link[disabled]:focus > a:only-child,
.ant-btn-link-disabled:active > a:only-child,
.ant-btn-link.disabled:active > a:only-child,
.ant-btn-link[disabled]:active > a:only-child,
.ant-btn-link-disabled.active > a:only-child,
.ant-btn-link.disabled.active > a:only-child,
.ant-btn-link[disabled].active > a:only-child {
  color: currentColor;
}
.ant-btn-link-disabled > a:only-child::after,
.ant-btn-link.disabled > a:only-child::after,
.ant-btn-link[disabled] > a:only-child::after,
.ant-btn-link-disabled:hover > a:only-child::after,
.ant-btn-link.disabled:hover > a:only-child::after,
.ant-btn-link[disabled]:hover > a:only-child::after,
.ant-btn-link-disabled:focus > a:only-child::after,
.ant-btn-link.disabled:focus > a:only-child::after,
.ant-btn-link[disabled]:focus > a:only-child::after,
.ant-btn-link-disabled:active > a:only-child::after,
.ant-btn-link.disabled:active > a:only-child::after,
.ant-btn-link[disabled]:active > a:only-child::after,
.ant-btn-link-disabled.active > a:only-child::after,
.ant-btn-link.disabled.active > a:only-child::after,
.ant-btn-link[disabled].active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-icon-only {
  width: 32px;
  height: 32px;
  padding: 0;
  font-size: 16px;
  border-radius: 10px;
}
.ant-btn-icon-only.ant-btn-lg {
  width: 40px;
  height: 40px;
  padding: 0;
  font-size: 16px;
  border-radius: 10px;
}
.ant-btn-icon-only.ant-btn-sm {
  width: 24px;
  height: 24px;
  padding: 0;
  font-size: 14px;
  border-radius: 10px;
}
.ant-btn-icon-only > i {
  vertical-align: middle;
}
.ant-btn-round {
  height: 32px;
  padding: 0 16px;
  font-size: 14px;
  border-radius: 32px;
}
.ant-btn-round.ant-btn-lg {
  height: 40px;
  padding: 0 20px;
  font-size: 14px;
  border-radius: 40px;
}
.ant-btn-round.ant-btn-sm {
  height: 24px;
  padding: 0 12px;
  font-size: 14px;
  border-radius: 24px;
}
.ant-btn-round.ant-btn-icon-only {
  width: auto;
}
.ant-btn-circle,
.ant-btn-circle-outline {
  min-width: 32px;
  padding-right: 0;
  padding-left: 0;
  text-align: center;
  border-radius: 50%;
}
.ant-btn-circle.ant-btn-lg,
.ant-btn-circle-outline.ant-btn-lg {
  min-width: 40px;
  border-radius: 50%;
}
.ant-btn-circle.ant-btn-sm,
.ant-btn-circle-outline.ant-btn-sm {
  min-width: 24px;
  border-radius: 50%;
}
.ant-btn::before {
  position: absolute;
  top: -1px;
  right: -1px;
  bottom: -1px;
  left: -1px;
  z-index: 1;
  display: none;
  background: #fff;
  border-radius: inherit;
  opacity: 0.35;
  transition: opacity 0.2s;
  content: '';
  pointer-events: none;
}
.ant-btn .anticon {
  transition: margin-left 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.ant-btn .anticon.anticon-plus > svg,
.ant-btn .anticon.anticon-minus > svg {
  shape-rendering: optimizeSpeed;
}
.ant-btn.ant-btn-loading {
  position: relative;
}
.ant-btn.ant-btn-loading:not([disabled]) {
  pointer-events: none;
}
.ant-btn.ant-btn-loading::before {
  display: block;
}
.ant-btn.ant-btn-loading:not(.ant-btn-circle):not(.ant-btn-circle-outline):not(.ant-btn-icon-only) {
  padding-left: 29px;
}
.ant-btn.ant-btn-loading:not(.ant-btn-circle):not(.ant-btn-circle-outline):not(.ant-btn-icon-only) .anticon:not(:last-child) {
  margin-left: -14px;
}
.ant-btn-sm.ant-btn-loading:not(.ant-btn-circle):not(.ant-btn-circle-outline):not(.ant-btn-icon-only) {
  padding-left: 24px;
}
.ant-btn-sm.ant-btn-loading:not(.ant-btn-circle):not(.ant-btn-circle-outline):not(.ant-btn-icon-only) .anticon {
  margin-left: -17px;
}
.ant-btn-group {
  position: relative;
  display: inline-block;
}
.ant-btn-group > .ant-btn,
.ant-btn-group > span > .ant-btn {
  position: relative;
}
.ant-btn-group > .ant-btn:hover,
.ant-btn-group > span > .ant-btn:hover,
.ant-btn-group > .ant-btn:focus,
.ant-btn-group > span > .ant-btn:focus,
.ant-btn-group > .ant-btn:active,
.ant-btn-group > span > .ant-btn:active,
.ant-btn-group > .ant-btn.active,
.ant-btn-group > span > .ant-btn.active {
  z-index: 2;
}
.ant-btn-group > .ant-btn:disabled,
.ant-btn-group > span > .ant-btn:disabled {
  z-index: 0;
}
.ant-btn-group > .ant-btn-icon-only {
  font-size: 14px;
}
.ant-btn-group-lg > .ant-btn,
.ant-btn-group-lg > span > .ant-btn {
  height: 40px;
  padding: 0 15px;
  font-size: 14px;
  border-radius: 0;
  line-height: 38px;
}
.ant-btn-group-lg > .ant-btn.ant-btn-icon-only {
  width: 40px;
  height: 40px;
  padding-right: 0;
  padding-left: 0;
}
.ant-btn-group-sm > .ant-btn,
.ant-btn-group-sm > span > .ant-btn {
  height: 24px;
  padding: 0 7px;
  font-size: 14px;
  border-radius: 0;
  line-height: 22px;
}
.ant-btn-group-sm > .ant-btn > .anticon,
.ant-btn-group-sm > span > .ant-btn > .anticon {
  font-size: 14px;
}
.ant-btn-group-sm > .ant-btn.ant-btn-icon-only {
  width: 24px;
  height: 24px;
  padding-right: 0;
  padding-left: 0;
}
.ant-btn-group .ant-btn + .ant-btn,
.ant-btn + .ant-btn-group,
.ant-btn-group span + .ant-btn,
.ant-btn-group .ant-btn + span,
.ant-btn-group > span + span,
.ant-btn-group + .ant-btn,
.ant-btn-group + .ant-btn-group {
  margin-left: -1px;
}
.ant-btn-group .ant-btn-primary + .ant-btn:not(.ant-btn-primary):not([disabled]) {
  border-left-color: transparent;
}
.ant-btn-group .ant-btn {
  border-radius: 0;
}
.ant-btn-group > .ant-btn:first-child,
.ant-btn-group > span:first-child > .ant-btn {
  margin-left: 0;
}
.ant-btn-group > .ant-btn:only-child {
  border-radius: 10px;
}
.ant-btn-group > span:only-child > .ant-btn {
  border-radius: 10px;
}
.ant-btn-group > .ant-btn:first-child:not(:last-child),
.ant-btn-group > span:first-child:not(:last-child) > .ant-btn {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}
.ant-btn-group > .ant-btn:last-child:not(:first-child),
.ant-btn-group > span:last-child:not(:first-child) > .ant-btn {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}
.ant-btn-group-sm > .ant-btn:only-child {
  border-radius: 10px;
}
.ant-btn-group-sm > span:only-child > .ant-btn {
  border-radius: 10px;
}
.ant-btn-group-sm > .ant-btn:first-child:not(:last-child),
.ant-btn-group-sm > span:first-child:not(:last-child) > .ant-btn {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}
.ant-btn-group-sm > .ant-btn:last-child:not(:first-child),
.ant-btn-group-sm > span:last-child:not(:first-child) > .ant-btn {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}
.ant-btn-group > .ant-btn-group {
  float: left;
}
.ant-btn-group > .ant-btn-group:not(:first-child):not(:last-child) > .ant-btn {
  border-radius: 0;
}
.ant-btn-group > .ant-btn-group:first-child:not(:last-child) > .ant-btn:last-child {
  padding-right: 8px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.ant-btn-group > .ant-btn-group:last-child:not(:first-child) > .ant-btn:first-child {
  padding-left: 8px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.ant-btn:focus > span,
.ant-btn:active > span {
  position: relative;
}
.ant-btn > .anticon + span,
.ant-btn > span + .anticon {
  margin-left: 8px;
}
.ant-btn-background-ghost {
  color: #fff;
  background: transparent !important;
  border-color: #fff;
}
.ant-btn-background-ghost.ant-btn-primary {
  color: #00a6ff;
  background-color: transparent;
  border-color: #00a6ff;
  text-shadow: none;
}
.ant-btn-background-ghost.ant-btn-primary > a:only-child {
  color: currentColor;
}
.ant-btn-background-ghost.ant-btn-primary > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-background-ghost.ant-btn-primary:hover,
.ant-btn-background-ghost.ant-btn-primary:focus {
  color: #29bbff;
  background-color: transparent;
  border-color: #29bbff;
}
.ant-btn-background-ghost.ant-btn-primary:hover > a:only-child,
.ant-btn-background-ghost.ant-btn-primary:focus > a:only-child {
  color: currentColor;
}
.ant-btn-background-ghost.ant-btn-primary:hover > a:only-child::after,
.ant-btn-background-ghost.ant-btn-primary:focus > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-background-ghost.ant-btn-primary:active,
.ant-btn-background-ghost.ant-btn-primary.active {
  color: #0086d9;
  background-color: transparent;
  border-color: #0086d9;
}
.ant-btn-background-ghost.ant-btn-primary:active > a:only-child,
.ant-btn-background-ghost.ant-btn-primary.active > a:only-child {
  color: currentColor;
}
.ant-btn-background-ghost.ant-btn-primary:active > a:only-child::after,
.ant-btn-background-ghost.ant-btn-primary.active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-background-ghost.ant-btn-primary-disabled,
.ant-btn-background-ghost.ant-btn-primary.disabled,
.ant-btn-background-ghost.ant-btn-primary[disabled],
.ant-btn-background-ghost.ant-btn-primary-disabled:hover,
.ant-btn-background-ghost.ant-btn-primary.disabled:hover,
.ant-btn-background-ghost.ant-btn-primary[disabled]:hover,
.ant-btn-background-ghost.ant-btn-primary-disabled:focus,
.ant-btn-background-ghost.ant-btn-primary.disabled:focus,
.ant-btn-background-ghost.ant-btn-primary[disabled]:focus,
.ant-btn-background-ghost.ant-btn-primary-disabled:active,
.ant-btn-background-ghost.ant-btn-primary.disabled:active,
.ant-btn-background-ghost.ant-btn-primary[disabled]:active,
.ant-btn-background-ghost.ant-btn-primary-disabled.active,
.ant-btn-background-ghost.ant-btn-primary.disabled.active,
.ant-btn-background-ghost.ant-btn-primary[disabled].active {
  color: #fff;
  background-color: #cccccc;
  border-color: #bfbfbf;
  text-shadow: none;
  box-shadow: none;
}
.ant-btn-background-ghost.ant-btn-primary-disabled > a:only-child,
.ant-btn-background-ghost.ant-btn-primary.disabled > a:only-child,
.ant-btn-background-ghost.ant-btn-primary[disabled] > a:only-child,
.ant-btn-background-ghost.ant-btn-primary-disabled:hover > a:only-child,
.ant-btn-background-ghost.ant-btn-primary.disabled:hover > a:only-child,
.ant-btn-background-ghost.ant-btn-primary[disabled]:hover > a:only-child,
.ant-btn-background-ghost.ant-btn-primary-disabled:focus > a:only-child,
.ant-btn-background-ghost.ant-btn-primary.disabled:focus > a:only-child,
.ant-btn-background-ghost.ant-btn-primary[disabled]:focus > a:only-child,
.ant-btn-background-ghost.ant-btn-primary-disabled:active > a:only-child,
.ant-btn-background-ghost.ant-btn-primary.disabled:active > a:only-child,
.ant-btn-background-ghost.ant-btn-primary[disabled]:active > a:only-child,
.ant-btn-background-ghost.ant-btn-primary-disabled.active > a:only-child,
.ant-btn-background-ghost.ant-btn-primary.disabled.active > a:only-child,
.ant-btn-background-ghost.ant-btn-primary[disabled].active > a:only-child {
  color: currentColor;
}
.ant-btn-background-ghost.ant-btn-primary-disabled > a:only-child::after,
.ant-btn-background-ghost.ant-btn-primary.disabled > a:only-child::after,
.ant-btn-background-ghost.ant-btn-primary[disabled] > a:only-child::after,
.ant-btn-background-ghost.ant-btn-primary-disabled:hover > a:only-child::after,
.ant-btn-background-ghost.ant-btn-primary.disabled:hover > a:only-child::after,
.ant-btn-background-ghost.ant-btn-primary[disabled]:hover > a:only-child::after,
.ant-btn-background-ghost.ant-btn-primary-disabled:focus > a:only-child::after,
.ant-btn-background-ghost.ant-btn-primary.disabled:focus > a:only-child::after,
.ant-btn-background-ghost.ant-btn-primary[disabled]:focus > a:only-child::after,
.ant-btn-background-ghost.ant-btn-primary-disabled:active > a:only-child::after,
.ant-btn-background-ghost.ant-btn-primary.disabled:active > a:only-child::after,
.ant-btn-background-ghost.ant-btn-primary[disabled]:active > a:only-child::after,
.ant-btn-background-ghost.ant-btn-primary-disabled.active > a:only-child::after,
.ant-btn-background-ghost.ant-btn-primary.disabled.active > a:only-child::after,
.ant-btn-background-ghost.ant-btn-primary[disabled].active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-background-ghost.ant-btn-danger {
  color: #1c8eff;
  background-color: transparent;
  border-color: #1c8eff;
  text-shadow: none;
}
.ant-btn-background-ghost.ant-btn-danger > a:only-child {
  color: currentColor;
}
.ant-btn-background-ghost.ant-btn-danger > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-background-ghost.ant-btn-danger:hover,
.ant-btn-background-ghost.ant-btn-danger:focus {
  color: #45a8ff;
  background-color: transparent;
  border-color: #45a8ff;
}
.ant-btn-background-ghost.ant-btn-danger:hover > a:only-child,
.ant-btn-background-ghost.ant-btn-danger:focus > a:only-child {
  color: currentColor;
}
.ant-btn-background-ghost.ant-btn-danger:hover > a:only-child::after,
.ant-btn-background-ghost.ant-btn-danger:focus > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-background-ghost.ant-btn-danger:active,
.ant-btn-background-ghost.ant-btn-danger.active {
  color: #0d6cd9;
  background-color: transparent;
  border-color: #0d6cd9;
}
.ant-btn-background-ghost.ant-btn-danger:active > a:only-child,
.ant-btn-background-ghost.ant-btn-danger.active > a:only-child {
  color: currentColor;
}
.ant-btn-background-ghost.ant-btn-danger:active > a:only-child::after,
.ant-btn-background-ghost.ant-btn-danger.active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-background-ghost.ant-btn-danger-disabled,
.ant-btn-background-ghost.ant-btn-danger.disabled,
.ant-btn-background-ghost.ant-btn-danger[disabled],
.ant-btn-background-ghost.ant-btn-danger-disabled:hover,
.ant-btn-background-ghost.ant-btn-danger.disabled:hover,
.ant-btn-background-ghost.ant-btn-danger[disabled]:hover,
.ant-btn-background-ghost.ant-btn-danger-disabled:focus,
.ant-btn-background-ghost.ant-btn-danger.disabled:focus,
.ant-btn-background-ghost.ant-btn-danger[disabled]:focus,
.ant-btn-background-ghost.ant-btn-danger-disabled:active,
.ant-btn-background-ghost.ant-btn-danger.disabled:active,
.ant-btn-background-ghost.ant-btn-danger[disabled]:active,
.ant-btn-background-ghost.ant-btn-danger-disabled.active,
.ant-btn-background-ghost.ant-btn-danger.disabled.active,
.ant-btn-background-ghost.ant-btn-danger[disabled].active {
  color: #fff;
  background-color: #cccccc;
  border-color: #bfbfbf;
  text-shadow: none;
  box-shadow: none;
}
.ant-btn-background-ghost.ant-btn-danger-disabled > a:only-child,
.ant-btn-background-ghost.ant-btn-danger.disabled > a:only-child,
.ant-btn-background-ghost.ant-btn-danger[disabled] > a:only-child,
.ant-btn-background-ghost.ant-btn-danger-disabled:hover > a:only-child,
.ant-btn-background-ghost.ant-btn-danger.disabled:hover > a:only-child,
.ant-btn-background-ghost.ant-btn-danger[disabled]:hover > a:only-child,
.ant-btn-background-ghost.ant-btn-danger-disabled:focus > a:only-child,
.ant-btn-background-ghost.ant-btn-danger.disabled:focus > a:only-child,
.ant-btn-background-ghost.ant-btn-danger[disabled]:focus > a:only-child,
.ant-btn-background-ghost.ant-btn-danger-disabled:active > a:only-child,
.ant-btn-background-ghost.ant-btn-danger.disabled:active > a:only-child,
.ant-btn-background-ghost.ant-btn-danger[disabled]:active > a:only-child,
.ant-btn-background-ghost.ant-btn-danger-disabled.active > a:only-child,
.ant-btn-background-ghost.ant-btn-danger.disabled.active > a:only-child,
.ant-btn-background-ghost.ant-btn-danger[disabled].active > a:only-child {
  color: currentColor;
}
.ant-btn-background-ghost.ant-btn-danger-disabled > a:only-child::after,
.ant-btn-background-ghost.ant-btn-danger.disabled > a:only-child::after,
.ant-btn-background-ghost.ant-btn-danger[disabled] > a:only-child::after,
.ant-btn-background-ghost.ant-btn-danger-disabled:hover > a:only-child::after,
.ant-btn-background-ghost.ant-btn-danger.disabled:hover > a:only-child::after,
.ant-btn-background-ghost.ant-btn-danger[disabled]:hover > a:only-child::after,
.ant-btn-background-ghost.ant-btn-danger-disabled:focus > a:only-child::after,
.ant-btn-background-ghost.ant-btn-danger.disabled:focus > a:only-child::after,
.ant-btn-background-ghost.ant-btn-danger[disabled]:focus > a:only-child::after,
.ant-btn-background-ghost.ant-btn-danger-disabled:active > a:only-child::after,
.ant-btn-background-ghost.ant-btn-danger.disabled:active > a:only-child::after,
.ant-btn-background-ghost.ant-btn-danger[disabled]:active > a:only-child::after,
.ant-btn-background-ghost.ant-btn-danger-disabled.active > a:only-child::after,
.ant-btn-background-ghost.ant-btn-danger.disabled.active > a:only-child::after,
.ant-btn-background-ghost.ant-btn-danger[disabled].active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-background-ghost.ant-btn-link {
  color: #1c8eff;
  background-color: transparent;
  border-color: transparent;
  text-shadow: none;
  color: #fff;
}
.ant-btn-background-ghost.ant-btn-link > a:only-child {
  color: currentColor;
}
.ant-btn-background-ghost.ant-btn-link > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-background-ghost.ant-btn-link:hover,
.ant-btn-background-ghost.ant-btn-link:focus {
  color: #45a8ff;
  background-color: transparent;
  border-color: transparent;
}
.ant-btn-background-ghost.ant-btn-link:hover > a:only-child,
.ant-btn-background-ghost.ant-btn-link:focus > a:only-child {
  color: currentColor;
}
.ant-btn-background-ghost.ant-btn-link:hover > a:only-child::after,
.ant-btn-background-ghost.ant-btn-link:focus > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-background-ghost.ant-btn-link:active,
.ant-btn-background-ghost.ant-btn-link.active {
  color: #0d6cd9;
  background-color: transparent;
  border-color: transparent;
}
.ant-btn-background-ghost.ant-btn-link:active > a:only-child,
.ant-btn-background-ghost.ant-btn-link.active > a:only-child {
  color: currentColor;
}
.ant-btn-background-ghost.ant-btn-link:active > a:only-child::after,
.ant-btn-background-ghost.ant-btn-link.active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-background-ghost.ant-btn-link-disabled,
.ant-btn-background-ghost.ant-btn-link.disabled,
.ant-btn-background-ghost.ant-btn-link[disabled],
.ant-btn-background-ghost.ant-btn-link-disabled:hover,
.ant-btn-background-ghost.ant-btn-link.disabled:hover,
.ant-btn-background-ghost.ant-btn-link[disabled]:hover,
.ant-btn-background-ghost.ant-btn-link-disabled:focus,
.ant-btn-background-ghost.ant-btn-link.disabled:focus,
.ant-btn-background-ghost.ant-btn-link[disabled]:focus,
.ant-btn-background-ghost.ant-btn-link-disabled:active,
.ant-btn-background-ghost.ant-btn-link.disabled:active,
.ant-btn-background-ghost.ant-btn-link[disabled]:active,
.ant-btn-background-ghost.ant-btn-link-disabled.active,
.ant-btn-background-ghost.ant-btn-link.disabled.active,
.ant-btn-background-ghost.ant-btn-link[disabled].active {
  color: #fff;
  background-color: #cccccc;
  border-color: #bfbfbf;
  text-shadow: none;
  box-shadow: none;
}
.ant-btn-background-ghost.ant-btn-link-disabled > a:only-child,
.ant-btn-background-ghost.ant-btn-link.disabled > a:only-child,
.ant-btn-background-ghost.ant-btn-link[disabled] > a:only-child,
.ant-btn-background-ghost.ant-btn-link-disabled:hover > a:only-child,
.ant-btn-background-ghost.ant-btn-link.disabled:hover > a:only-child,
.ant-btn-background-ghost.ant-btn-link[disabled]:hover > a:only-child,
.ant-btn-background-ghost.ant-btn-link-disabled:focus > a:only-child,
.ant-btn-background-ghost.ant-btn-link.disabled:focus > a:only-child,
.ant-btn-background-ghost.ant-btn-link[disabled]:focus > a:only-child,
.ant-btn-background-ghost.ant-btn-link-disabled:active > a:only-child,
.ant-btn-background-ghost.ant-btn-link.disabled:active > a:only-child,
.ant-btn-background-ghost.ant-btn-link[disabled]:active > a:only-child,
.ant-btn-background-ghost.ant-btn-link-disabled.active > a:only-child,
.ant-btn-background-ghost.ant-btn-link.disabled.active > a:only-child,
.ant-btn-background-ghost.ant-btn-link[disabled].active > a:only-child {
  color: currentColor;
}
.ant-btn-background-ghost.ant-btn-link-disabled > a:only-child::after,
.ant-btn-background-ghost.ant-btn-link.disabled > a:only-child::after,
.ant-btn-background-ghost.ant-btn-link[disabled] > a:only-child::after,
.ant-btn-background-ghost.ant-btn-link-disabled:hover > a:only-child::after,
.ant-btn-background-ghost.ant-btn-link.disabled:hover > a:only-child::after,
.ant-btn-background-ghost.ant-btn-link[disabled]:hover > a:only-child::after,
.ant-btn-background-ghost.ant-btn-link-disabled:focus > a:only-child::after,
.ant-btn-background-ghost.ant-btn-link.disabled:focus > a:only-child::after,
.ant-btn-background-ghost.ant-btn-link[disabled]:focus > a:only-child::after,
.ant-btn-background-ghost.ant-btn-link-disabled:active > a:only-child::after,
.ant-btn-background-ghost.ant-btn-link.disabled:active > a:only-child::after,
.ant-btn-background-ghost.ant-btn-link[disabled]:active > a:only-child::after,
.ant-btn-background-ghost.ant-btn-link-disabled.active > a:only-child::after,
.ant-btn-background-ghost.ant-btn-link.disabled.active > a:only-child::after,
.ant-btn-background-ghost.ant-btn-link[disabled].active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-two-chinese-chars::first-letter {
  letter-spacing: 0.34em;
}
.ant-btn-two-chinese-chars > *:not(.anticon) {
  margin-right: -0.34em;
  letter-spacing: 0.34em;
}
.ant-btn-block {
  width: 100%;
}
.ant-btn:empty {
  vertical-align: top;
}
a.ant-btn {
  padding-top: 0.1px;
  line-height: 30px;
}
a.ant-btn-lg {
  line-height: 38px;
}
a.ant-btn-sm {
  line-height: 22px;
}
.ant-fullcalendar {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(34, 34, 34, 0.8);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: "tnum";
  border-top: 1px solid #bfbfbf;
  outline: none;
}
.ant-select.ant-fullcalendar-year-select {
  min-width: 90px;
}
.ant-select.ant-fullcalendar-year-select.ant-select-sm {
  min-width: 70px;
}
.ant-select.ant-fullcalendar-month-select {
  min-width: 80px;
  margin-left: 8px;
}
.ant-select.ant-fullcalendar-month-select.ant-select-sm {
  min-width: 70px;
}
.ant-fullcalendar-header {
  padding: 11px 16px 11px 0;
  text-align: right;
}
.ant-fullcalendar-header .ant-select-dropdown {
  text-align: left;
}
.ant-fullcalendar-header .ant-radio-group {
  margin-left: 8px;
  text-align: left;
}
.ant-fullcalendar-header label.ant-radio-button {
  height: 22px;
  padding: 0 10px;
  line-height: 20px;
}
.ant-fullcalendar-date-panel {
  position: relative;
  outline: none;
}
.ant-fullcalendar-calendar-body {
  padding: 8px 12px;
}
.ant-fullcalendar table {
  width: 100%;
  max-width: 100%;
  height: 256px;
  background-color: transparent;
  border-collapse: collapse;
}
.ant-fullcalendar table,
.ant-fullcalendar th,
.ant-fullcalendar td {
  border: 0;
}
.ant-fullcalendar td {
  position: relative;
}
.ant-fullcalendar-calendar-table {
  margin-bottom: 0;
  border-spacing: 0;
}
.ant-fullcalendar-column-header {
  width: 33px;
  padding: 0;
  line-height: 18px;
  text-align: center;
}
.ant-fullcalendar-column-header .ant-fullcalendar-column-header-inner {
  display: block;
  font-weight: normal;
}
.ant-fullcalendar-week-number-header .ant-fullcalendar-column-header-inner {
  display: none;
}
.ant-fullcalendar-month,
.ant-fullcalendar-date {
  text-align: center;
  transition: all 0.3s;
}
.ant-fullcalendar-value {
  display: block;
  width: 24px;
  height: 24px;
  margin: 0 auto;
  padding: 0;
  color: rgba(34, 34, 34, 0.8);
  line-height: 24px;
  background: transparent;
  border-radius: 10px;
  transition: all 0.3s;
}
.ant-fullcalendar-value:hover {
  background: #f5f5f5;
  cursor: pointer;
}
.ant-fullcalendar-value:active {
  color: #fff;
  background: #00a6ff;
}
.ant-fullcalendar-month-panel-cell .ant-fullcalendar-value {
  width: 48px;
}
.ant-fullcalendar-today .ant-fullcalendar-value,
.ant-fullcalendar-month-panel-current-cell .ant-fullcalendar-value {
  box-shadow: 0 0 0 1px #00a6ff inset;
}
.ant-fullcalendar-selected-day .ant-fullcalendar-value,
.ant-fullcalendar-month-panel-selected-cell .ant-fullcalendar-value {
  color: #fff;
  background: #00a6ff;
}
.ant-fullcalendar-disabled-cell-first-of-row .ant-fullcalendar-value {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}
.ant-fullcalendar-disabled-cell-last-of-row .ant-fullcalendar-value {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}
.ant-fullcalendar-last-month-cell .ant-fullcalendar-value,
.ant-fullcalendar-next-month-btn-day .ant-fullcalendar-value {
  color: #fff;
}
.ant-fullcalendar-month-panel-table {
  width: 100%;
  table-layout: fixed;
  border-collapse: separate;
}
.ant-fullcalendar-content {
  position: absolute;
  bottom: -9px;
  left: 0;
  width: 100%;
}
.ant-fullcalendar-fullscreen {
  border-top: 0;
}
.ant-fullcalendar-fullscreen .ant-fullcalendar-table {
  table-layout: fixed;
}
.ant-fullcalendar-fullscreen .ant-fullcalendar-header .ant-radio-group {
  margin-left: 16px;
}
.ant-fullcalendar-fullscreen .ant-fullcalendar-header label.ant-radio-button {
  height: 32px;
  line-height: 30px;
}
.ant-fullcalendar-fullscreen .ant-fullcalendar-month,
.ant-fullcalendar-fullscreen .ant-fullcalendar-date {
  display: block;
  height: 116px;
  margin: 0 4px;
  padding: 4px 8px;
  color: rgba(34, 34, 34, 0.8);
  text-align: left;
  border-top: 2px solid #f0f0f0;
  transition: background 0.3s;
}
.ant-fullcalendar-fullscreen .ant-fullcalendar-month:hover,
.ant-fullcalendar-fullscreen .ant-fullcalendar-date:hover {
  background: #f5f5f5;
  cursor: pointer;
}
.ant-fullcalendar-fullscreen .ant-fullcalendar-month:active,
.ant-fullcalendar-fullscreen .ant-fullcalendar-date:active {
  background: #a3ebff;
}
.ant-fullcalendar-fullscreen .ant-fullcalendar-column-header {
  padding-right: 12px;
  padding-bottom: 5px;
  text-align: right;
}
.ant-fullcalendar-fullscreen .ant-fullcalendar-value {
  width: auto;
  text-align: right;
  background: transparent;
}
.ant-fullcalendar-fullscreen .ant-fullcalendar-today .ant-fullcalendar-value {
  color: rgba(34, 34, 34, 0.8);
}
.ant-fullcalendar-fullscreen .ant-fullcalendar-month-panel-current-cell .ant-fullcalendar-month,
.ant-fullcalendar-fullscreen .ant-fullcalendar-today .ant-fullcalendar-date {
  background: transparent;
  border-top-color: #00a6ff;
}
.ant-fullcalendar-fullscreen .ant-fullcalendar-month-panel-current-cell .ant-fullcalendar-value,
.ant-fullcalendar-fullscreen .ant-fullcalendar-today .ant-fullcalendar-value {
  box-shadow: none;
}
.ant-fullcalendar-fullscreen .ant-fullcalendar-month-panel-selected-cell .ant-fullcalendar-month,
.ant-fullcalendar-fullscreen .ant-fullcalendar-selected-day .ant-fullcalendar-date {
  background: #e6faff;
}
.ant-fullcalendar-fullscreen .ant-fullcalendar-month-panel-selected-cell .ant-fullcalendar-value,
.ant-fullcalendar-fullscreen .ant-fullcalendar-selected-day .ant-fullcalendar-value {
  color: #00a6ff;
}
.ant-fullcalendar-fullscreen .ant-fullcalendar-last-month-cell .ant-fullcalendar-date,
.ant-fullcalendar-fullscreen .ant-fullcalendar-next-month-btn-day .ant-fullcalendar-date {
  color: #fff;
}
.ant-fullcalendar-fullscreen .ant-fullcalendar-content {
  position: static;
  width: auto;
  height: 88px;
  overflow-y: auto;
}
.ant-fullcalendar-disabled-cell .ant-fullcalendar-date,
.ant-fullcalendar-disabled-cell .ant-fullcalendar-date:hover {
  cursor: not-allowed;
}
.ant-fullcalendar-disabled-cell:not(.ant-fullcalendar-today) .ant-fullcalendar-date,
.ant-fullcalendar-disabled-cell:not(.ant-fullcalendar-today) .ant-fullcalendar-date:hover {
  background: transparent;
}
.ant-fullcalendar-disabled-cell .ant-fullcalendar-value {
  width: auto;
  color: #fff;
  border-radius: 0;
  cursor: not-allowed;
}
.ant-card {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(34, 34, 34, 0.8);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: "tnum";
  position: relative;
  background: #fff;
  border-radius: 10px;
  transition: all 0.3s;
}
.ant-card-hoverable {
  cursor: pointer;
}
.ant-card-hoverable:hover {
  border-color: rgba(34, 34, 34, 0.09);
  box-shadow: 0 1px 2px -2px rgba(0, 0, 0, 0.16), 0 3px 6px 0 rgba(0, 0, 0, 0.12), 0 5px 12px 4px rgba(0, 0, 0, 0.09);
}
.ant-card-bordered {
  border: 1px solid #f0f0f0;
}
.ant-card-head {
  min-height: 48px;
  margin-bottom: -1px;
  padding: 0 24px;
  color: rgba(255, 255, 255, 0.85);
  font-weight: 500;
  font-size: 14px;
  background: transparent;
  border-bottom: 1px solid #f0f0f0;
  border-radius: 10px 10px 0 0;
  zoom: 1;
}
.ant-card-head::before,
.ant-card-head::after {
  display: table;
  content: '';
}
.ant-card-head::after {
  clear: both;
}
.ant-card-head::before,
.ant-card-head::after {
  display: table;
  content: '';
}
.ant-card-head::after {
  clear: both;
}
.ant-card-head-wrapper {
  display: flex;
  align-items: center;
}
.ant-card-head-title {
  display: inline-block;
  flex: 1 1;
  padding: 16px 0;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.ant-card-head .ant-tabs {
  clear: both;
  margin-bottom: -17px;
  color: rgba(34, 34, 34, 0.8);
  font-weight: normal;
  font-size: 14px;
}
.ant-card-head .ant-tabs-bar {
  border-bottom: 1px solid #f0f0f0;
}
.ant-card-extra {
  float: right;
  margin-left: auto;
  padding: 16px 0;
  color: rgba(34, 34, 34, 0.8);
  font-weight: normal;
  font-size: 14px;
}
.ant-card-body {
  padding: 24px;
  zoom: 1;
}
.ant-card-body::before,
.ant-card-body::after {
  display: table;
  content: '';
}
.ant-card-body::after {
  clear: both;
}
.ant-card-body::before,
.ant-card-body::after {
  display: table;
  content: '';
}
.ant-card-body::after {
  clear: both;
}
.ant-card-contain-grid:not(.ant-card-loading) .ant-card-body {
  margin: -1px 0 0 -1px;
  padding: 0;
}
.ant-card-grid {
  float: left;
  width: 33.33%;
  padding: 24px;
  border: 0;
  border-radius: 0;
  box-shadow: 1px 0 0 0 #f0f0f0, 0 1px 0 0 #f0f0f0, 1px 1px 0 0 #f0f0f0, 1px 0 0 0 #f0f0f0 inset, 0 1px 0 0 #f0f0f0 inset;
  transition: all 0.3s;
}
.ant-card-grid-hoverable:hover {
  position: relative;
  z-index: 1;
  box-shadow: 0 0 1px 1px rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.08), 0 2px 8px 2px rgba(0, 0, 0, 0.05);
}
.ant-card-contain-tabs > .ant-card-head .ant-card-head-title {
  min-height: 32px;
  padding-bottom: 0;
}
.ant-card-contain-tabs > .ant-card-head .ant-card-extra {
  padding-bottom: 0;
}
.ant-card-cover > * {
  display: block;
  width: 100%;
}
.ant-card-cover img {
  border-radius: 10px 10px 0 0;
}
.ant-card-actions {
  margin: 0;
  padding: 0;
  list-style: none;
  background: #fafafa;
  border-top: 1px solid #f0f0f0;
  zoom: 1;
}
.ant-card-actions::before,
.ant-card-actions::after {
  display: table;
  content: '';
}
.ant-card-actions::after {
  clear: both;
}
.ant-card-actions::before,
.ant-card-actions::after {
  display: table;
  content: '';
}
.ant-card-actions::after {
  clear: both;
}
.ant-card-actions > li {
  float: left;
  margin: 12px 0;
  color: rgba(34, 34, 34, 0.45);
  text-align: center;
}
.ant-card-actions > li > span {
  position: relative;
  display: block;
  min-width: 32px;
  font-size: 14px;
  line-height: 22px;
  cursor: pointer;
}
.ant-card-actions > li > span:hover {
  color: #00a6ff;
  transition: color 0.3s;
}
.ant-card-actions > li > span a:not(.ant-btn),
.ant-card-actions > li > span > .anticon {
  display: inline-block;
  width: 100%;
  color: rgba(34, 34, 34, 0.45);
  line-height: 22px;
  transition: color 0.3s;
}
.ant-card-actions > li > span a:not(.ant-btn):hover,
.ant-card-actions > li > span > .anticon:hover {
  color: #00a6ff;
}
.ant-card-actions > li > span > .anticon {
  font-size: 16px;
  line-height: 22px;
}
.ant-card-actions > li:not(:last-child) {
  border-right: 1px solid #f0f0f0;
}
.ant-card-type-inner .ant-card-head {
  padding: 0 24px;
  background: #fafafa;
}
.ant-card-type-inner .ant-card-head-title {
  padding: 12px 0;
  font-size: 14px;
}
.ant-card-type-inner .ant-card-body {
  padding: 16px 24px;
}
.ant-card-type-inner .ant-card-extra {
  padding: 13.5px 0;
}
.ant-card-meta {
  margin: -4px 0;
  zoom: 1;
}
.ant-card-meta::before,
.ant-card-meta::after {
  display: table;
  content: '';
}
.ant-card-meta::after {
  clear: both;
}
.ant-card-meta::before,
.ant-card-meta::after {
  display: table;
  content: '';
}
.ant-card-meta::after {
  clear: both;
}
.ant-card-meta-avatar {
  float: left;
  padding-right: 16px;
}
.ant-card-meta-detail {
  overflow: hidden;
}
.ant-card-meta-detail > div:not(:last-child) {
  margin-bottom: 8px;
}
.ant-card-meta-title {
  overflow: hidden;
  color: rgba(255, 255, 255, 0.85);
  font-weight: 500;
  font-size: 14px;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.ant-card-meta-description {
  color: rgba(34, 34, 34, 0.45);
}
.ant-card-loading {
  overflow: hidden;
}
.ant-card-loading .ant-card-body {
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.ant-card-loading-content p {
  margin: 0;
}
.ant-card-loading-block {
  height: 14px;
  margin: 4px 0;
  background: linear-gradient(90deg, rgba(207, 216, 220, 0.2), rgba(207, 216, 220, 0.4), rgba(207, 216, 220, 0.2));
  background-size: 600% 600%;
  border-radius: 10px;
  animation: card-loading 1.4s ease infinite;
}
@keyframes card-loading {
  0%,
  100% {
    background-position: 0 50%;
  }
  50% {
    background-position: 100% 50%;
  }
}
.ant-card-small > .ant-card-head {
  min-height: 36px;
  padding: 0 12px;
  font-size: 14px;
}
.ant-card-small > .ant-card-head > .ant-card-head-wrapper > .ant-card-head-title {
  padding: 8px 0;
}
.ant-card-small > .ant-card-head > .ant-card-head-wrapper > .ant-card-extra {
  padding: 8px 0;
  font-size: 14px;
}
.ant-card-small > .ant-card-body {
  padding: 12px;
}
.ant-carousel {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(34, 34, 34, 0.8);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: "tnum";
}
.ant-carousel .slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}
.ant-carousel .slick-list {
  position: relative;
  display: block;
  margin: 0;
  padding: 0;
  overflow: hidden;
}
.ant-carousel .slick-list:focus {
  outline: none;
}
.ant-carousel .slick-list.dragging {
  cursor: pointer;
}
.ant-carousel .slick-list .slick-slide {
  pointer-events: none;
}
.ant-carousel .slick-list .slick-slide input.ant-radio-input,
.ant-carousel .slick-list .slick-slide input.ant-checkbox-input {
  visibility: hidden;
}
.ant-carousel .slick-list .slick-slide.slick-active {
  pointer-events: auto;
}
.ant-carousel .slick-list .slick-slide.slick-active input.ant-radio-input,
.ant-carousel .slick-list .slick-slide.slick-active input.ant-checkbox-input {
  visibility: visible;
}
.ant-carousel .slick-slider .slick-track,
.ant-carousel .slick-slider .slick-list {
  transform: translate3d(0, 0, 0);
}
.ant-carousel .slick-track {
  position: relative;
  top: 0;
  left: 0;
  display: block;
}
.ant-carousel .slick-track::before,
.ant-carousel .slick-track::after {
  display: table;
  content: '';
}
.ant-carousel .slick-track::after {
  clear: both;
}
.slick-loading .ant-carousel .slick-track {
  visibility: hidden;
}
.ant-carousel .slick-slide {
  display: none;
  float: left;
  height: 100%;
  min-height: 1px;
}
[dir='rtl'] .ant-carousel .slick-slide {
  float: right;
}
.ant-carousel .slick-slide img {
  display: block;
}
.ant-carousel .slick-slide.slick-loading img {
  display: none;
}
.ant-carousel .slick-slide.dragging img {
  pointer-events: none;
}
.ant-carousel .slick-initialized .slick-slide {
  display: block;
}
.ant-carousel .slick-loading .slick-slide {
  visibility: hidden;
}
.ant-carousel .slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}
.ant-carousel .slick-arrow.slick-hidden {
  display: none;
}
.ant-carousel .slick-prev,
.ant-carousel .slick-next {
  position: absolute;
  top: 50%;
  display: block;
  width: 20px;
  height: 20px;
  margin-top: -10px;
  padding: 0;
  color: transparent;
  font-size: 0;
  line-height: 0;
  background: transparent;
  border: 0;
  outline: none;
  cursor: pointer;
}
.ant-carousel .slick-prev:hover,
.ant-carousel .slick-next:hover,
.ant-carousel .slick-prev:focus,
.ant-carousel .slick-next:focus {
  color: transparent;
  background: transparent;
  outline: none;
}
.ant-carousel .slick-prev:hover::before,
.ant-carousel .slick-next:hover::before,
.ant-carousel .slick-prev:focus::before,
.ant-carousel .slick-next:focus::before {
  opacity: 1;
}
.ant-carousel .slick-prev.slick-disabled::before,
.ant-carousel .slick-next.slick-disabled::before {
  opacity: 0.25;
}
.ant-carousel .slick-prev {
  left: -25px;
}
.ant-carousel .slick-prev::before {
  content: '←';
}
.ant-carousel .slick-next {
  right: -25px;
}
.ant-carousel .slick-next::before {
  content: '→';
}
.ant-carousel .slick-dots {
  position: absolute;
  display: block;
  width: 100%;
  height: 3px;
  margin: 0;
  padding: 0;
  text-align: center;
  list-style: none;
}
.ant-carousel .slick-dots-bottom {
  bottom: 12px;
}
.ant-carousel .slick-dots-top {
  top: 12px;
}
.ant-carousel .slick-dots li {
  position: relative;
  display: inline-block;
  margin: 0 2px;
  padding: 0;
  text-align: center;
  vertical-align: top;
}
.ant-carousel .slick-dots li button {
  display: block;
  width: 16px;
  height: 3px;
  padding: 0;
  color: transparent;
  font-size: 0;
  background: #fff;
  border: 0;
  border-radius: 1px;
  outline: none;
  cursor: pointer;
  opacity: 0.3;
  transition: all 0.5s;
}
.ant-carousel .slick-dots li button:hover,
.ant-carousel .slick-dots li button:focus {
  opacity: 0.75;
}
.ant-carousel .slick-dots li.slick-active button {
  width: 24px;
  background: #fff;
  opacity: 1;
}
.ant-carousel .slick-dots li.slick-active button:hover,
.ant-carousel .slick-dots li.slick-active button:focus {
  opacity: 1;
}
.ant-carousel-vertical .slick-dots {
  top: 50%;
  bottom: auto;
  width: 3px;
  height: auto;
  transform: translateY(-50%);
}
.ant-carousel-vertical .slick-dots-left {
  left: 12px;
}
.ant-carousel-vertical .slick-dots-right {
  right: 12px;
}
.ant-carousel-vertical .slick-dots li {
  margin: 0 2px;
  vertical-align: baseline;
}
.ant-carousel-vertical .slick-dots li button {
  width: 3px;
  height: 16px;
}
.ant-carousel-vertical .slick-dots li.slick-active button {
  width: 3px;
  height: 24px;
}
.ant-cascader {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(34, 34, 34, 0.8);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: "tnum";
}
.ant-cascader-input.ant-input {
  position: static;
  width: 100%;
  padding-right: 24px;
  background-color: transparent !important;
  cursor: pointer;
}
.ant-cascader-picker-show-search .ant-cascader-input.ant-input {
  position: relative;
}
.ant-cascader-picker {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(34, 34, 34, 0.8);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: "tnum";
  position: relative;
  display: inline-block;
  background-color: #fff;
  border-radius: 10px;
  outline: 0;
  cursor: pointer;
  transition: color 0.3s;
}
.ant-cascader-picker-with-value .ant-cascader-picker-label {
  color: transparent;
}
.ant-cascader-picker-disabled {
  color: #fff;
  background: #cccccc;
  cursor: not-allowed;
}
.ant-cascader-picker-disabled .ant-cascader-input {
  cursor: not-allowed;
}
.ant-cascader-picker:focus .ant-cascader-input {
  border-color: #666060;
  border-right-width: 1px !important;
  outline: 0;
  box-shadow: 0 0 0 1px rgba(89, 89, 89, 0.2);
}
.ant-cascader-picker-show-search.ant-cascader-picker-focused {
  color: #fff;
}
.ant-cascader-picker-label {
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  height: 20px;
  margin-top: -10px;
  padding: 0 20px 0 12px;
  overflow: hidden;
  line-height: 20px;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.ant-cascader-picker-clear {
  position: absolute;
  top: 50%;
  right: 12px;
  z-index: 2;
  width: 12px;
  height: 12px;
  margin-top: -6px;
  color: #fff;
  font-size: 12px;
  line-height: 12px;
  background: #fff;
  cursor: pointer;
  opacity: 0;
  transition: color 0.3s ease, opacity 0.15s ease;
}
.ant-cascader-picker-clear:hover {
  color: rgba(34, 34, 34, 0.45);
}
.ant-cascader-picker:hover .ant-cascader-picker-clear {
  opacity: 1;
}
.ant-cascader-picker-arrow {
  position: absolute;
  top: 50%;
  right: 12px;
  z-index: 1;
  width: 12px;
  height: 12px;
  margin-top: -6px;
  color: #fff;
  font-size: 12px;
  line-height: 12px;
  transition: transform 0.2s;
}
.ant-cascader-picker-arrow.ant-cascader-picker-arrow-expand {
  transform: rotate(180deg);
}
.ant-cascader-picker-label:hover + .ant-cascader-input {
  border-color: #333;
  border-right-width: 1px !important;
}
.ant-cascader-picker-small .ant-cascader-picker-clear,
.ant-cascader-picker-small .ant-cascader-picker-arrow {
  right: 8px;
}
.ant-cascader-menus {
  position: absolute;
  z-index: 9999;
  font-size: 14px;
  white-space: nowrap;
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 0 1px 1px rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.08), 0 2px 8px 2px rgba(0, 0, 0, 0.05);
}
.ant-cascader-menus ul,
.ant-cascader-menus ol {
  margin: 0;
  list-style: none;
}
.ant-cascader-menus-empty,
.ant-cascader-menus-hidden {
  display: none;
}
.ant-cascader-menus.slide-up-enter.slide-up-enter-active.ant-cascader-menus-placement-bottomLeft,
.ant-cascader-menus.slide-up-appear.slide-up-appear-active.ant-cascader-menus-placement-bottomLeft {
  animation-name: antSlideUpIn;
}
.ant-cascader-menus.slide-up-enter.slide-up-enter-active.ant-cascader-menus-placement-topLeft,
.ant-cascader-menus.slide-up-appear.slide-up-appear-active.ant-cascader-menus-placement-topLeft {
  animation-name: antSlideDownIn;
}
.ant-cascader-menus.slide-up-leave.slide-up-leave-active.ant-cascader-menus-placement-bottomLeft {
  animation-name: antSlideUpOut;
}
.ant-cascader-menus.slide-up-leave.slide-up-leave-active.ant-cascader-menus-placement-topLeft {
  animation-name: antSlideDownOut;
}
.ant-cascader-menu {
  display: inline-block;
  min-width: 111px;
  height: 180px;
  margin: 0;
  padding: 4px 0;
  overflow: auto;
  vertical-align: top;
  list-style: none;
  border-right: 1px solid #f0f0f0;
  -ms-overflow-style: -ms-autohiding-scrollbar;
}
.ant-cascader-menu:first-child {
  border-radius: 10px 0 0 10px;
}
.ant-cascader-menu:last-child {
  margin-right: -1px;
  border-right-color: transparent;
  border-radius: 0 10px 10px 0;
}
.ant-cascader-menu:only-child {
  border-radius: 10px;
}
.ant-cascader-menu-item {
  padding: 5px 12px;
  line-height: 32px;
  white-space: nowrap;
  cursor: pointer;
  transition: all 0.3s;
}
.ant-cascader-menu-item:hover {
  background: #f5f5f5;
}
.ant-cascader-menu-item-disabled {
  color: #fff;
  cursor: not-allowed;
}
.ant-cascader-menu-item-disabled:hover {
  background: transparent;
}
.ant-cascader-menu-item-active:not(.ant-cascader-menu-item-disabled),
.ant-cascader-menu-item-active:not(.ant-cascader-menu-item-disabled):hover {
  font-weight: 600;
  background-color: #fafafa;
}
.ant-cascader-menu-item-expand {
  position: relative;
  padding-right: 24px;
}
.ant-cascader-menu-item-expand .ant-cascader-menu-item-expand-icon,
.ant-cascader-menu-item-loading-icon {
  display: inline-block;
  font-size: 12px;
  font-size: 10px \9;
  transform: scale(0.83333333) rotate(0deg);
  position: absolute;
  right: 12px;
  color: rgba(34, 34, 34, 0.45);
}
:root .ant-cascader-menu-item-expand .ant-cascader-menu-item-expand-icon,
:root .ant-cascader-menu-item-loading-icon {
  font-size: 12px;
}
.ant-cascader-menu-item .ant-cascader-menu-item-keyword {
  color: #ff4d4f;
}
@keyframes antCheckboxEffect {
  0% {
    transform: scale(1);
    opacity: 0.5;
  }
  100% {
    transform: scale(1.6);
    opacity: 0;
  }
}
.ant-checkbox {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(34, 34, 34, 0.8);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: "tnum";
  position: relative;
  top: -0.09em;
  display: inline-block;
  line-height: 1;
  white-space: nowrap;
  vertical-align: middle;
  outline: none;
  cursor: pointer;
}
.ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner,
.ant-checkbox-input:focus + .ant-checkbox-inner {
  border-color: #00a6ff;
}
.ant-checkbox-checked::after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 1px solid #00a6ff;
  border-radius: 10px;
  visibility: hidden;
  animation: antCheckboxEffect 0.36s ease-in-out;
  animation-fill-mode: backwards;
  content: '';
}
.ant-checkbox:hover::after,
.ant-checkbox-wrapper:hover .ant-checkbox::after {
  visibility: visible;
}
.ant-checkbox-inner {
  position: relative;
  top: 0;
  left: 0;
  display: block;
  width: 16px;
  height: 16px;
  background-color: #fff;
  border: 1px solid #bfbfbf;
  border-radius: 10px;
  border-collapse: separate;
  transition: all 0.3s;
}
.ant-checkbox-inner::after {
  position: absolute;
  top: 50%;
  left: 22%;
  display: table;
  width: 5.71428571px;
  height: 9.14285714px;
  border: 2px solid #fff;
  border-top: 0;
  border-left: 0;
  transform: rotate(45deg) scale(0) translate(-50%, -50%);
  opacity: 0;
  transition: all 0.1s cubic-bezier(0.71, -0.46, 0.88, 0.6), opacity 0.1s;
  content: ' ';
}
.ant-checkbox-input {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  cursor: pointer;
  opacity: 0;
}
.ant-checkbox-checked .ant-checkbox-inner::after {
  position: absolute;
  display: table;
  border: 2px solid #fff;
  border-top: 0;
  border-left: 0;
  transform: rotate(45deg) scale(1) translate(-50%, -50%);
  opacity: 1;
  transition: all 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46) 0.1s;
  content: ' ';
}
.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #00a6ff;
  border-color: #00a6ff;
}
.ant-checkbox-disabled {
  cursor: not-allowed;
}
.ant-checkbox-disabled.ant-checkbox-checked .ant-checkbox-inner::after {
  border-color: #fff;
  animation-name: none;
}
.ant-checkbox-disabled .ant-checkbox-input {
  cursor: not-allowed;
}
.ant-checkbox-disabled .ant-checkbox-inner {
  background-color: #cccccc;
  border-color: #bfbfbf !important;
}
.ant-checkbox-disabled .ant-checkbox-inner::after {
  border-color: #cccccc;
  border-collapse: separate;
  animation-name: none;
}
.ant-checkbox-disabled + span {
  color: #fff;
  cursor: not-allowed;
}
.ant-checkbox-disabled:hover::after,
.ant-checkbox-wrapper:hover .ant-checkbox-disabled::after {
  visibility: hidden;
}
.ant-checkbox-wrapper {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(34, 34, 34, 0.8);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: "tnum";
  display: inline-block;
  line-height: unset;
  cursor: pointer;
}
.ant-checkbox-wrapper.ant-checkbox-wrapper-disabled {
  cursor: not-allowed;
}
.ant-checkbox-wrapper + .ant-checkbox-wrapper {
  margin-left: 8px;
}
.ant-checkbox + span {
  padding-right: 8px;
  padding-left: 8px;
}
.ant-checkbox-group {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(34, 34, 34, 0.8);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: "tnum";
  display: inline-block;
}
.ant-checkbox-group-item {
  display: inline-block;
  margin-right: 8px;
}
.ant-checkbox-group-item:last-child {
  margin-right: 0;
}
.ant-checkbox-group-item + .ant-checkbox-group-item {
  margin-left: 0;
}
.ant-checkbox-indeterminate .ant-checkbox-inner {
  background-color: #fff;
  border-color: #bfbfbf;
}
.ant-checkbox-indeterminate .ant-checkbox-inner::after {
  top: 50%;
  left: 50%;
  width: 8px;
  height: 8px;
  background-color: #00a6ff;
  border: 0;
  transform: translate(-50%, -50%) scale(1);
  opacity: 1;
  content: ' ';
}
.ant-checkbox-indeterminate.ant-checkbox-disabled .ant-checkbox-inner::after {
  background-color: #fff;
  border-color: #fff;
}
.ant-collapse {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(34, 34, 34, 0.8);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: "tnum";
  background-color: #fafafa;
  border: 1px solid #bfbfbf;
  border-bottom: 0;
  border-radius: 10px;
}
.ant-collapse > .ant-collapse-item {
  border-bottom: 1px solid #bfbfbf;
}
.ant-collapse > .ant-collapse-item:last-child,
.ant-collapse > .ant-collapse-item:last-child > .ant-collapse-header {
  border-radius: 0 0 10px 10px;
}
.ant-collapse > .ant-collapse-item > .ant-collapse-header {
  position: relative;
  padding: 12px 16px;
  padding-left: 40px;
  color: rgba(255, 255, 255, 0.85);
  line-height: 22px;
  cursor: pointer;
  transition: all 0.3s;
}
.ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow {
  color: inherit;
  font-style: normal;
  line-height: 0;
  text-align: center;
  text-transform: none;
  vertical-align: -0.125em;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: absolute;
  top: 50%;
  left: 16px;
  display: inline-block;
  font-size: 12px;
  transform: translateY(-50%);
}
.ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow > * {
  line-height: 1;
}
.ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow svg {
  display: inline-block;
}
.ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow::before {
  display: none;
}
.ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow .ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow-icon {
  display: block;
}
.ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow svg {
  transition: transform 0.24s;
}
.ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-extra {
  float: right;
}
.ant-collapse > .ant-collapse-item > .ant-collapse-header:focus {
  outline: none;
}
.ant-collapse > .ant-collapse-item.ant-collapse-no-arrow > .ant-collapse-header {
  padding-left: 12px;
}
.ant-collapse-icon-position-right > .ant-collapse-item > .ant-collapse-header {
  padding: 12px 16px;
  padding-right: 40px;
}
.ant-collapse-icon-position-right > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow {
  right: 16px;
  left: auto;
}
.ant-collapse-anim-active {
  transition: height 0.2s cubic-bezier(0.215, 0.61, 0.355, 1);
}
.ant-collapse-content {
  overflow: hidden;
  color: rgba(34, 34, 34, 0.8);
  background-color: #fff;
  border-top: 1px solid #bfbfbf;
}
.ant-collapse-content > .ant-collapse-content-box {
  padding: 16px;
}
.ant-collapse-content-inactive {
  display: none;
}
.ant-collapse-item:last-child > .ant-collapse-content {
  border-radius: 0 0 10px 10px;
}
.ant-collapse-borderless {
  background-color: #fafafa;
  border: 0;
}
.ant-collapse-borderless > .ant-collapse-item {
  border-bottom: 1px solid #bfbfbf;
}
.ant-collapse-borderless > .ant-collapse-item:last-child,
.ant-collapse-borderless > .ant-collapse-item:last-child .ant-collapse-header {
  border-radius: 0;
}
.ant-collapse-borderless > .ant-collapse-item > .ant-collapse-content {
  background-color: transparent;
  border-top: 0;
}
.ant-collapse-borderless > .ant-collapse-item > .ant-collapse-content > .ant-collapse-content-box {
  padding-top: 4px;
}
.ant-collapse .ant-collapse-item-disabled > .ant-collapse-header,
.ant-collapse .ant-collapse-item-disabled > .ant-collapse-header > .arrow {
  color: #fff;
  cursor: not-allowed;
}
.ant-comment {
  position: relative;
}
.ant-comment-inner {
  display: flex;
  padding: 16px 0;
}
.ant-comment-avatar {
  position: relative;
  flex-shrink: 0;
  margin-right: 12px;
  cursor: pointer;
}
.ant-comment-avatar img {
  width: 32px;
  height: 32px;
  border-radius: 50%;
}
.ant-comment-content {
  position: relative;
  flex: 1 1 auto;
  min-width: 1px;
  font-size: 14px;
  word-wrap: break-word;
}
.ant-comment-content-author {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin-bottom: 4px;
  font-size: 14px;
}
.ant-comment-content-author > a,
.ant-comment-content-author > span {
  padding-right: 8px;
  font-size: 12px;
  line-height: 18px;
}
.ant-comment-content-author-name {
  color: rgba(34, 34, 34, 0.45);
  font-size: 14px;
  transition: color 0.3s;
}
.ant-comment-content-author-name > * {
  color: rgba(34, 34, 34, 0.45);
}
.ant-comment-content-author-name > *:hover {
  color: rgba(34, 34, 34, 0.45);
}
.ant-comment-content-author-time {
  color: #ccc;
  white-space: nowrap;
  cursor: auto;
}
.ant-comment-content-detail p {
  white-space: pre-wrap;
}
.ant-comment-actions {
  margin-top: 12px;
  padding-left: 0;
}
.ant-comment-actions > li {
  display: inline-block;
  color: rgba(34, 34, 34, 0.45);
}
.ant-comment-actions > li > span {
  padding-right: 10px;
  color: rgba(34, 34, 34, 0.45);
  font-size: 12px;
  cursor: pointer;
  transition: color 0.3s;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.ant-comment-actions > li > span:hover {
  color: #595959;
}
.ant-comment-nested {
  margin-left: 44px;
}
.ant-descriptions-title {
  margin-bottom: 20px;
  color: rgba(255, 255, 255, 0.85);
  font-weight: bold;
  font-size: 14px;
  line-height: 1.5715;
}
.ant-descriptions-view {
  width: 100%;
  overflow: hidden;
  border-radius: 10px;
}
.ant-descriptions-view table {
  width: 100%;
  table-layout: fixed;
}
.ant-descriptions-row > th,
.ant-descriptions-row > td {
  padding-bottom: 16px;
}
.ant-descriptions-row:last-child {
  border-bottom: none;
}
.ant-descriptions-item-label {
  color: rgba(255, 255, 255, 0.85);
  font-weight: normal;
  font-size: 14px;
  line-height: 1.5715;
}
.ant-descriptions-item-label::after {
  position: relative;
  top: -0.5px;
  margin: 0 8px 0 2px;
  content: ' ';
}
.ant-descriptions-item-colon::after {
  content: ':';
}
.ant-descriptions-item-no-label::after {
  margin: 0;
  content: '';
}
.ant-descriptions-item-content {
  display: table-cell;
  color: rgba(34, 34, 34, 0.8);
  font-size: 14px;
  line-height: 1.5715;
}
.ant-descriptions-item {
  padding-bottom: 0;
}
.ant-descriptions-item > span {
  display: inline-block;
}
.ant-descriptions-middle .ant-descriptions-row > th,
.ant-descriptions-middle .ant-descriptions-row > td {
  padding-bottom: 12px;
}
.ant-descriptions-small .ant-descriptions-row > th,
.ant-descriptions-small .ant-descriptions-row > td {
  padding-bottom: 8px;
}
.ant-descriptions-bordered .ant-descriptions-view {
  border: 1px solid #f0f0f0;
}
.ant-descriptions-bordered .ant-descriptions-view > table {
  table-layout: auto;
}
.ant-descriptions-bordered .ant-descriptions-item-label,
.ant-descriptions-bordered .ant-descriptions-item-content {
  padding: 16px 24px;
  border-right: 1px solid #f0f0f0;
}
.ant-descriptions-bordered .ant-descriptions-item-label:last-child,
.ant-descriptions-bordered .ant-descriptions-item-content:last-child {
  border-right: none;
}
.ant-descriptions-bordered .ant-descriptions-item-label {
  background-color: #fafafa;
}
.ant-descriptions-bordered .ant-descriptions-item-label::after {
  display: none;
}
.ant-descriptions-bordered .ant-descriptions-row {
  border-bottom: 1px solid #f0f0f0;
}
.ant-descriptions-bordered .ant-descriptions-row:last-child {
  border-bottom: none;
}
.ant-descriptions-bordered.ant-descriptions-middle .ant-descriptions-item-label,
.ant-descriptions-bordered.ant-descriptions-middle .ant-descriptions-item-content {
  padding: 12px 24px;
}
.ant-descriptions-bordered.ant-descriptions-small .ant-descriptions-item-label,
.ant-descriptions-bordered.ant-descriptions-small .ant-descriptions-item-content {
  padding: 8px 16px;
}
.ant-divider {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(34, 34, 34, 0.8);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: "tnum";
  background: #f0f0f0;
}
.ant-divider,
.ant-divider-vertical {
  position: relative;
  top: -0.06em;
  display: inline-block;
  width: 1px;
  height: 0.9em;
  margin: 0 8px;
  vertical-align: middle;
}
.ant-divider-horizontal {
  display: block;
  clear: both;
  width: 100%;
  min-width: 100%;
  height: 1px;
  margin: 24px 0;
}
.ant-divider-horizontal.ant-divider-with-text-center,
.ant-divider-horizontal.ant-divider-with-text-left,
.ant-divider-horizontal.ant-divider-with-text-right {
  display: table;
  margin: 16px 0;
  color: rgba(255, 255, 255, 0.85);
  font-weight: 500;
  font-size: 14px;
  white-space: nowrap;
  text-align: center;
  background: transparent;
}
.ant-divider-horizontal.ant-divider-with-text-center::before,
.ant-divider-horizontal.ant-divider-with-text-left::before,
.ant-divider-horizontal.ant-divider-with-text-right::before,
.ant-divider-horizontal.ant-divider-with-text-center::after,
.ant-divider-horizontal.ant-divider-with-text-left::after,
.ant-divider-horizontal.ant-divider-with-text-right::after {
  position: relative;
  top: 50%;
  display: table-cell;
  width: 50%;
  border-top: 1px solid #f0f0f0;
  transform: translateY(50%);
  content: '';
}
.ant-divider-horizontal.ant-divider-with-text-left .ant-divider-inner-text,
.ant-divider-horizontal.ant-divider-with-text-right .ant-divider-inner-text {
  display: inline-block;
  padding: 0 10px;
}
.ant-divider-horizontal.ant-divider-with-text-left::before {
  top: 50%;
  width: 5%;
}
.ant-divider-horizontal.ant-divider-with-text-left::after {
  top: 50%;
  width: 95%;
}
.ant-divider-horizontal.ant-divider-with-text-right::before {
  top: 50%;
  width: 95%;
}
.ant-divider-horizontal.ant-divider-with-text-right::after {
  top: 50%;
  width: 5%;
}
.ant-divider-inner-text {
  display: inline-block;
  padding: 0 24px;
}
.ant-divider-dashed {
  background: none;
  border-color: #f0f0f0;
  border-style: dashed;
  border-width: 1px 0 0;
}
.ant-divider-horizontal.ant-divider-with-text-center.ant-divider-dashed,
.ant-divider-horizontal.ant-divider-with-text-left.ant-divider-dashed,
.ant-divider-horizontal.ant-divider-with-text-right.ant-divider-dashed {
  border-top: 0;
}
.ant-divider-horizontal.ant-divider-with-text-center.ant-divider-dashed::before,
.ant-divider-horizontal.ant-divider-with-text-left.ant-divider-dashed::before,
.ant-divider-horizontal.ant-divider-with-text-right.ant-divider-dashed::before,
.ant-divider-horizontal.ant-divider-with-text-center.ant-divider-dashed::after,
.ant-divider-horizontal.ant-divider-with-text-left.ant-divider-dashed::after,
.ant-divider-horizontal.ant-divider-with-text-right.ant-divider-dashed::after {
  border-style: dashed none none;
}
.ant-divider-vertical.ant-divider-dashed {
  border-width: 0 0 0 1px;
}
.ant-drawer {
  position: fixed;
  z-index: 1000;
  width: 0%;
  height: 100%;
  transition: transform 0.3s cubic-bezier(0.7, 0.3, 0.1, 1), height 0s ease 0.3s, width 0s ease 0.3s;
}
.ant-drawer > * {
  transition: transform 0.3s cubic-bezier(0.7, 0.3, 0.1, 1), box-shadow 0.3s cubic-bezier(0.7, 0.3, 0.1, 1);
}
.ant-drawer-content-wrapper {
  position: absolute;
}
.ant-drawer .ant-drawer-content {
  width: 100%;
  height: 100%;
}
.ant-drawer-left,
.ant-drawer-right {
  top: 0;
  width: 0%;
  height: 100%;
}
.ant-drawer-left .ant-drawer-content-wrapper,
.ant-drawer-right .ant-drawer-content-wrapper {
  height: 100%;
}
.ant-drawer-left.ant-drawer-open,
.ant-drawer-right.ant-drawer-open {
  width: 100%;
  transition: transform 0.3s cubic-bezier(0.7, 0.3, 0.1, 1);
}
.ant-drawer-left.ant-drawer-open.no-mask,
.ant-drawer-right.ant-drawer-open.no-mask {
  width: 0%;
}
.ant-drawer-left.ant-drawer-open .ant-drawer-content-wrapper {
  box-shadow: 1px 0 1px -2px rgba(0, 0, 0, 0.08), 1px 0 2px 0 rgba(0, 0, 0, 0.05), 2px 0 8px 2px rgba(0, 0, 0, 0.03);
}
.ant-drawer-right {
  right: 0;
}
.ant-drawer-right .ant-drawer-content-wrapper {
  right: 0;
}
.ant-drawer-right.ant-drawer-open .ant-drawer-content-wrapper {
  box-shadow: -1px 0 1px -2px rgba(0, 0, 0, 0.08), -1px 0 2px 0 rgba(0, 0, 0, 0.05), -2px 0 8px 2px rgba(0, 0, 0, 0.03);
}
.ant-drawer-right.ant-drawer-open.no-mask {
  right: 1px;
  transform: translateX(1px);
}
.ant-drawer-top,
.ant-drawer-bottom {
  left: 0;
  width: 100%;
  height: 0%;
}
.ant-drawer-top .ant-drawer-content-wrapper,
.ant-drawer-bottom .ant-drawer-content-wrapper {
  width: 100%;
}
.ant-drawer-top.ant-drawer-open,
.ant-drawer-bottom.ant-drawer-open {
  height: 100%;
  transition: transform 0.3s cubic-bezier(0.7, 0.3, 0.1, 1);
}
.ant-drawer-top.ant-drawer-open.no-mask,
.ant-drawer-bottom.ant-drawer-open.no-mask {
  height: 0%;
}
.ant-drawer-top {
  top: 0;
}
.ant-drawer-top.ant-drawer-open .ant-drawer-content-wrapper {
  box-shadow: 0 1px 1px -2px rgba(0, 0, 0, 0.08), 0 1px 2px 0 rgba(0, 0, 0, 0.05), 0 2px 8px 2px rgba(0, 0, 0, 0.03);
}
.ant-drawer-bottom {
  bottom: 0;
}
.ant-drawer-bottom .ant-drawer-content-wrapper {
  bottom: 0;
}
.ant-drawer-bottom.ant-drawer-open .ant-drawer-content-wrapper {
  box-shadow: 0 -1px 1px -2px rgba(0, 0, 0, 0.08), 0 -1px 2px 0 rgba(0, 0, 0, 0.05), 0 -2px 8px 2px rgba(0, 0, 0, 0.03);
}
.ant-drawer-bottom.ant-drawer-open.no-mask {
  bottom: 1px;
  transform: translateY(1px);
}
.ant-drawer.ant-drawer-open .ant-drawer-mask {
  height: 100%;
  opacity: 1;
  transition: none;
  animation: antdDrawerFadeIn 0.3s cubic-bezier(0.7, 0.3, 0.1, 1);
}
.ant-drawer-title {
  margin: 0;
  color: rgba(255, 255, 255, 0.85);
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
}
.ant-drawer-content {
  position: relative;
  z-index: 1;
  overflow: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 0;
}
.ant-drawer-close {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 10;
  display: block;
  width: 56px;
  height: 56px;
  padding: 0;
  color: rgba(34, 34, 34, 0.45);
  font-weight: 700;
  font-size: 14px;
  font-style: normal;
  line-height: 56px;
  text-align: center;
  text-transform: none;
  text-decoration: none;
  background: transparent;
  border: 0;
  outline: 0;
  cursor: pointer;
  transition: color 0.3s;
  text-rendering: auto;
}
.ant-drawer-close:focus,
.ant-drawer-close:hover {
  color: rgba(34, 34, 34, 0.75);
  text-decoration: none;
}
.ant-drawer-header {
  position: relative;
  padding: 16px 24px;
  color: rgba(34, 34, 34, 0.8);
  background: #fff;
  border-bottom: 1px solid #f0f0f0;
  border-radius: 10px 10px 0 0;
}
.ant-drawer-header-no-title {
  color: rgba(34, 34, 34, 0.8);
  background: #fff;
}
.ant-drawer-body {
  padding: 24px;
  font-size: 14px;
  line-height: 1.5715;
  word-wrap: break-word;
}
.ant-drawer-wrapper-body {
  height: 100%;
  overflow: auto;
}
.ant-drawer-mask {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 0;
  background-color: rgba(34, 34, 34, 0.45);
  opacity: 0;
  filter: alpha(opacity=45);
  transition: opacity 0.3s linear, height 0s ease 0.3s;
}
.ant-drawer-open-content {
  box-shadow: 0 0 1px 1px rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.08), 0 2px 8px 2px rgba(0, 0, 0, 0.05);
}
@keyframes antdDrawerFadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.ant-dropdown {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(34, 34, 34, 0.8);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: "tnum";
  position: absolute;
  top: -9999px;
  left: -9999px;
  z-index: 9999;
  display: block;
}
.ant-dropdown::before {
  position: absolute;
  top: -7px;
  right: 0;
  bottom: -7px;
  left: -7px;
  z-index: -9999;
  opacity: 0.0001;
  content: ' ';
}
.ant-dropdown-wrap {
  position: relative;
}
.ant-dropdown-wrap .ant-btn > .anticon-down {
  display: inline-block;
  font-size: 12px;
  font-size: 10px \9;
  transform: scale(0.83333333) rotate(0deg);
}
:root .ant-dropdown-wrap .ant-btn > .anticon-down {
  font-size: 12px;
}
.ant-dropdown-wrap .anticon-down::before {
  transition: transform 0.2s;
}
.ant-dropdown-wrap-open .anticon-down::before {
  transform: rotate(180deg);
}
.ant-dropdown-hidden,
.ant-dropdown-menu-hidden {
  display: none;
}
.ant-dropdown-menu {
  position: relative;
  margin: 0;
  padding: 4px 0;
  text-align: left;
  list-style-type: none;
  background-color: #fff;
  background-clip: padding-box;
  border-radius: 10px;
  outline: none;
  box-shadow: 0 0 1px 1px rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.08), 0 2px 8px 2px rgba(0, 0, 0, 0.05);
  -webkit-transform: translate3d(0, 0, 0);
}
.ant-dropdown-menu-item-group-title {
  padding: 5px 12px;
  color: rgba(34, 34, 34, 0.45);
  transition: all 0.3s;
}
.ant-dropdown-menu-submenu-popup {
  position: absolute;
  z-index: 9999;
}
.ant-dropdown-menu-submenu-popup > .ant-dropdown-menu {
  transform-origin: 0 0;
}
.ant-dropdown-menu-submenu-popup ul,
.ant-dropdown-menu-submenu-popup li {
  list-style: none;
}
.ant-dropdown-menu-submenu-popup ul {
  margin-right: 0.3em;
  margin-left: 0.3em;
  padding: 0;
}
.ant-dropdown-menu-item,
.ant-dropdown-menu-submenu-title {
  clear: both;
  margin: 0;
  padding: 5px 12px;
  color: rgba(34, 34, 34, 0.8);
  font-weight: normal;
  font-size: 14px;
  line-height: 32px;
  white-space: nowrap;
  cursor: pointer;
  transition: all 0.3s;
}
.ant-dropdown-menu-item > .anticon:first-child,
.ant-dropdown-menu-submenu-title > .anticon:first-child,
.ant-dropdown-menu-item > span > .anticon:first-child,
.ant-dropdown-menu-submenu-title > span > .anticon:first-child {
  min-width: 12px;
  margin-right: 8px;
  font-size: 12px;
}
.ant-dropdown-menu-item > a,
.ant-dropdown-menu-submenu-title > a {
  display: block;
  margin: -5px -12px;
  padding: 5px 12px;
  color: rgba(34, 34, 34, 0.8);
  transition: all 0.3s;
}
.ant-dropdown-menu-item-selected,
.ant-dropdown-menu-submenu-title-selected,
.ant-dropdown-menu-item-selected > a,
.ant-dropdown-menu-submenu-title-selected > a {
  color: #00a6ff;
  background-color: #e6faff;
}
.ant-dropdown-menu-item:hover,
.ant-dropdown-menu-submenu-title:hover {
  background-color: #f5f5f5;
}
.ant-dropdown-menu-item-disabled,
.ant-dropdown-menu-submenu-title-disabled {
  color: #fff;
  cursor: not-allowed;
}
.ant-dropdown-menu-item-disabled:hover,
.ant-dropdown-menu-submenu-title-disabled:hover {
  color: #fff;
  background-color: #fff;
  cursor: not-allowed;
}
.ant-dropdown-menu-item-divider,
.ant-dropdown-menu-submenu-title-divider {
  height: 1px;
  margin: 4px 0;
  overflow: hidden;
  line-height: 0;
  background-color: #f0f0f0;
}
.ant-dropdown-menu-item .ant-dropdown-menu-submenu-arrow,
.ant-dropdown-menu-submenu-title .ant-dropdown-menu-submenu-arrow {
  position: absolute;
  right: 8px;
}
.ant-dropdown-menu-item .ant-dropdown-menu-submenu-arrow-icon,
.ant-dropdown-menu-submenu-title .ant-dropdown-menu-submenu-arrow-icon {
  color: rgba(34, 34, 34, 0.45);
  font-style: normal;
  display: inline-block;
  font-size: 12px;
  font-size: 10px \9;
  transform: scale(0.83333333) rotate(0deg);
}
:root .ant-dropdown-menu-item .ant-dropdown-menu-submenu-arrow-icon,
:root .ant-dropdown-menu-submenu-title .ant-dropdown-menu-submenu-arrow-icon {
  font-size: 12px;
}
.ant-dropdown-menu-item-group-list {
  margin: 0 8px;
  padding: 0;
  list-style: none;
}
.ant-dropdown-menu-submenu-title {
  padding-right: 26px;
}
.ant-dropdown-menu-submenu-vertical {
  position: relative;
}
.ant-dropdown-menu-submenu-vertical > .ant-dropdown-menu {
  position: absolute;
  top: 0;
  left: 100%;
  min-width: 100%;
  margin-left: 4px;
  transform-origin: 0 0;
}
.ant-dropdown-menu-submenu.ant-dropdown-menu-submenu-disabled .ant-dropdown-menu-submenu-title,
.ant-dropdown-menu-submenu.ant-dropdown-menu-submenu-disabled .ant-dropdown-menu-submenu-title .ant-dropdown-menu-submenu-arrow-icon {
  color: #fff;
  background-color: #fff;
  cursor: not-allowed;
}
.ant-dropdown-menu-submenu-selected .ant-dropdown-menu-submenu-title {
  color: #00a6ff;
}
.ant-dropdown.slide-down-enter.slide-down-enter-active.ant-dropdown-placement-bottomLeft,
.ant-dropdown.slide-down-appear.slide-down-appear-active.ant-dropdown-placement-bottomLeft,
.ant-dropdown.slide-down-enter.slide-down-enter-active.ant-dropdown-placement-bottomCenter,
.ant-dropdown.slide-down-appear.slide-down-appear-active.ant-dropdown-placement-bottomCenter,
.ant-dropdown.slide-down-enter.slide-down-enter-active.ant-dropdown-placement-bottomRight,
.ant-dropdown.slide-down-appear.slide-down-appear-active.ant-dropdown-placement-bottomRight {
  animation-name: antSlideUpIn;
}
.ant-dropdown.slide-up-enter.slide-up-enter-active.ant-dropdown-placement-topLeft,
.ant-dropdown.slide-up-appear.slide-up-appear-active.ant-dropdown-placement-topLeft,
.ant-dropdown.slide-up-enter.slide-up-enter-active.ant-dropdown-placement-topCenter,
.ant-dropdown.slide-up-appear.slide-up-appear-active.ant-dropdown-placement-topCenter,
.ant-dropdown.slide-up-enter.slide-up-enter-active.ant-dropdown-placement-topRight,
.ant-dropdown.slide-up-appear.slide-up-appear-active.ant-dropdown-placement-topRight {
  animation-name: antSlideDownIn;
}
.ant-dropdown.slide-down-leave.slide-down-leave-active.ant-dropdown-placement-bottomLeft,
.ant-dropdown.slide-down-leave.slide-down-leave-active.ant-dropdown-placement-bottomCenter,
.ant-dropdown.slide-down-leave.slide-down-leave-active.ant-dropdown-placement-bottomRight {
  animation-name: antSlideUpOut;
}
.ant-dropdown.slide-up-leave.slide-up-leave-active.ant-dropdown-placement-topLeft,
.ant-dropdown.slide-up-leave.slide-up-leave-active.ant-dropdown-placement-topCenter,
.ant-dropdown.slide-up-leave.slide-up-leave-active.ant-dropdown-placement-topRight {
  animation-name: antSlideDownOut;
}
.ant-dropdown-trigger > .anticon.anticon-down,
.ant-dropdown-link > .anticon.anticon-down {
  display: inline-block;
  font-size: 12px;
  font-size: 10px \9;
  transform: scale(0.83333333) rotate(0deg);
}
:root .ant-dropdown-trigger > .anticon.anticon-down,
:root .ant-dropdown-link > .anticon.anticon-down {
  font-size: 12px;
}
.ant-dropdown-button {
  white-space: nowrap;
}
.ant-dropdown-button.ant-btn-group > .ant-btn:last-child:not(:first-child) {
  padding-right: 8px;
  padding-left: 8px;
}
.ant-dropdown-button .anticon.anticon-down {
  display: inline-block;
  font-size: 12px;
  font-size: 10px \9;
  transform: scale(0.83333333) rotate(0deg);
}
:root .ant-dropdown-button .anticon.anticon-down {
  font-size: 12px;
}
.ant-dropdown-menu-dark,
.ant-dropdown-menu-dark .ant-dropdown-menu {
  background: #001529;
}
.ant-dropdown-menu-dark .ant-dropdown-menu-item,
.ant-dropdown-menu-dark .ant-dropdown-menu-submenu-title,
.ant-dropdown-menu-dark .ant-dropdown-menu-item > a {
  color: rgba(255, 255, 255, 0.65);
}
.ant-dropdown-menu-dark .ant-dropdown-menu-item .ant-dropdown-menu-submenu-arrow::after,
.ant-dropdown-menu-dark .ant-dropdown-menu-submenu-title .ant-dropdown-menu-submenu-arrow::after,
.ant-dropdown-menu-dark .ant-dropdown-menu-item > a .ant-dropdown-menu-submenu-arrow::after {
  color: rgba(255, 255, 255, 0.65);
}
.ant-dropdown-menu-dark .ant-dropdown-menu-item:hover,
.ant-dropdown-menu-dark .ant-dropdown-menu-submenu-title:hover,
.ant-dropdown-menu-dark .ant-dropdown-menu-item > a:hover {
  color: #fff;
  background: transparent;
}
.ant-dropdown-menu-dark .ant-dropdown-menu-item-selected,
.ant-dropdown-menu-dark .ant-dropdown-menu-item-selected:hover,
.ant-dropdown-menu-dark .ant-dropdown-menu-item-selected > a {
  color: #fff;
  background: #00a6ff;
}
.ant-empty {
  margin: 0 8px;
  font-size: 14px;
  line-height: 22px;
  text-align: center;
}
.ant-empty-image {
  height: 100px;
  margin-bottom: 8px;
}
.ant-empty-image img {
  height: 100%;
}
.ant-empty-image svg {
  height: 100%;
  margin: auto;
}
.ant-empty-description {
  margin: 0;
}
.ant-empty-footer {
  margin-top: 16px;
}
.ant-empty-normal {
  margin: 32px 0;
  color: #fff;
}
.ant-empty-normal .ant-empty-image {
  height: 40px;
}
.ant-empty-small {
  margin: 8px 0;
  color: #fff;
}
.ant-empty-small .ant-empty-image {
  height: 35px;
}
.ant-form {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(34, 34, 34, 0.8);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: "tnum";
}
.ant-form legend {
  display: block;
  width: 100%;
  margin-bottom: 20px;
  padding: 0;
  color: rgba(34, 34, 34, 0.45);
  font-size: 14px;
  line-height: inherit;
  border: 0;
  border-bottom: 1px solid #bfbfbf;
}
.ant-form label {
  font-size: 14px;
}
.ant-form input[type='search'] {
  box-sizing: border-box;
}
.ant-form input[type='radio'],
.ant-form input[type='checkbox'] {
  line-height: normal;
}
.ant-form input[type='file'] {
  display: block;
}
.ant-form input[type='range'] {
  display: block;
  width: 100%;
}
.ant-form select[multiple],
.ant-form select[size] {
  height: auto;
}
.ant-form input[type='file']:focus,
.ant-form input[type='radio']:focus,
.ant-form input[type='checkbox']:focus {
  outline: thin dotted;
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px;
}
.ant-form output {
  display: block;
  padding-top: 15px;
  color: rgba(34, 34, 34, 0.8);
  font-size: 14px;
  line-height: 1.5715;
}
.ant-form-item-label > label {
  color: #222;
}
.ant-form-item-label > label::after {
  content: ":";
  position: relative;
  top: -0.5px;
  margin: 0 8px 0 2px;
}
.ant-form-item-label > label.ant-form-item-no-colon::after {
  content: " ";
}
.ant-form-item {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(34, 34, 34, 0.8);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: "tnum";
  margin-bottom: 16px;
  vertical-align: top;
}
.ant-form-item label {
  position: relative;
}
.ant-form-item label > .anticon {
  font-size: 14px;
  vertical-align: top;
}
.ant-form-item-control {
  position: relative;
  line-height: 45px;
  zoom: 1;
}
.ant-form-item-control::before,
.ant-form-item-control::after {
  display: table;
  content: '';
}
.ant-form-item-control::after {
  clear: both;
}
.ant-form-item-control::before,
.ant-form-item-control::after {
  display: table;
  content: '';
}
.ant-form-item-control::after {
  clear: both;
}
.ant-form-item-children {
  position: relative;
}
.ant-form-item-with-help {
  margin-bottom: 0;
}
.ant-form-item-label {
  display: inline-block;
  overflow: hidden;
  line-height: 44.9999px;
  white-space: nowrap;
  text-align: right;
  vertical-align: middle;
}
.ant-form-item-label-left {
  text-align: left;
}
.ant-form-item .ant-switch {
  margin: 2px 0 4px;
}
.ant-form-explain,
.ant-form-extra {
  clear: both;
  min-height: 23px;
  margin-top: -0.5px;
  color: rgba(34, 34, 34, 0.45);
  font-size: 14px;
  line-height: 1.5715;
  transition: color 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
}
.ant-form-explain {
  margin-bottom: -1px;
}
.ant-form-extra {
  padding-top: 4px;
}
.ant-form-text {
  display: inline-block;
  padding-right: 8px;
}
.ant-form-split {
  display: block;
  text-align: center;
}
form .has-feedback .ant-input {
  padding-right: 30px;
}
form .has-feedback .ant-input-affix-wrapper .ant-input-suffix {
  padding-right: 18px;
}
form .has-feedback .ant-input-affix-wrapper .ant-input {
  padding-right: 49px;
}
form .has-feedback .ant-input-affix-wrapper.ant-input-affix-wrapper-input-with-clear-btn .ant-input {
  padding-right: 68px;
}
form .has-feedback > .ant-select .ant-select-arrow,
form .has-feedback > .ant-select .ant-select-selection__clear,
form .has-feedback :not(.ant-input-group-addon) > .ant-select .ant-select-arrow,
form .has-feedback :not(.ant-input-group-addon) > .ant-select .ant-select-selection__clear {
  right: 28px;
}
form .has-feedback > .ant-select .ant-select-selection-selected-value,
form .has-feedback :not(.ant-input-group-addon) > .ant-select .ant-select-selection-selected-value {
  padding-right: 42px;
}
form .has-feedback .ant-cascader-picker-arrow {
  margin-right: 17px;
}
form .has-feedback .ant-cascader-picker-clear {
  right: 28px;
}
form .has-feedback .ant-input-search:not( .ant-input-search-enter-button) .ant-input-suffix {
  right: 28px;
}
form .has-feedback .ant-calendar-picker-icon,
form .has-feedback .ant-time-picker-icon,
form .has-feedback .ant-calendar-picker-clear,
form .has-feedback .ant-time-picker-clear {
  right: 28px;
}
form .ant-mentions,
form textarea.ant-input {
  height: auto;
  margin-bottom: 4px;
}
form .ant-upload {
  background: transparent;
}
form input[type="radio"],
form input[type="checkbox"] {
  width: 14px;
  height: 14px;
}
form .ant-radio-inline,
form .ant-checkbox-inline {
  display: inline-block;
  margin-left: 8px;
  font-weight: normal;
  vertical-align: middle;
  cursor: pointer;
}
form .ant-radio-inline:first-child,
form .ant-checkbox-inline:first-child {
  margin-left: 0;
}
form .ant-checkbox-vertical,
form .ant-radio-vertical {
  display: block;
}
form .ant-checkbox-vertical + .ant-checkbox-vertical,
form .ant-radio-vertical + .ant-radio-vertical {
  margin-left: 0;
}
form .ant-input-number + .ant-form-text {
  margin-left: 8px;
}
form .ant-input-number-handler-wrap {
  z-index: 2;
}
form .ant-select,
form .ant-cascader-picker {
  width: 100%;
}
form .ant-input-group .ant-select,
form .ant-input-group .ant-cascader-picker {
  width: auto;
}
form :not(.ant-input-group-wrapper) > .ant-input-group,
form .ant-input-group-wrapper {
  display: inline-block;
  vertical-align: middle;
}
form:not(.ant-form-vertical) :not(.ant-input-group-wrapper) > .ant-input-group,
form:not(.ant-form-vertical) .ant-input-group-wrapper {
  position: relative;
  top: -1px;
}
.ant-form-vertical .ant-form-item-label,
.ant-col-24.ant-form-item-label,
.ant-col-xl-24.ant-form-item-label {
  display: block;
  margin: 0;
  padding: 0 6px 2px;
  line-height: 1.5715;
  white-space: normal;
  white-space: initial;
  text-align: left;
}
.ant-form-vertical .ant-form-item-label label::after,
.ant-col-24.ant-form-item-label label::after,
.ant-col-xl-24.ant-form-item-label label::after {
  display: none;
}
.ant-form-vertical .ant-form-item {
  padding-bottom: 8px;
}
.ant-form-vertical .ant-form-item-control {
  line-height: 1.5715;
}
.ant-form-vertical .ant-form-explain {
  margin-top: 2px;
  margin-bottom: -5px;
}
.ant-form-vertical .ant-form-extra {
  margin-top: 2px;
  margin-bottom: -4px;
}
@media (max-width: 575px) {
  .ant-form-item-label,
  .ant-form-item-control-wrapper {
    display: block;
    width: 100%;
  }
  .ant-form-item-label {
    display: block;
    margin: 0;
    padding: 0 6px 2px;
    line-height: 1.5715;
    white-space: normal;
    white-space: initial;
    text-align: left;
  }
  .ant-form-item-label label::after {
    display: none;
  }
  .ant-col-xs-24.ant-form-item-label {
    display: block;
    margin: 0;
    padding: 0 6px 2px;
    line-height: 1.5715;
    white-space: normal;
    white-space: initial;
    text-align: left;
  }
  .ant-col-xs-24.ant-form-item-label label::after {
    display: none;
  }
}
@media (max-width: 767px) {
  .ant-col-sm-24.ant-form-item-label {
    display: block;
    margin: 0;
    padding: 0 6px 2px;
    line-height: 1.5715;
    white-space: normal;
    white-space: initial;
    text-align: left;
  }
  .ant-col-sm-24.ant-form-item-label label::after {
    display: none;
  }
}
@media (max-width: 991px) {
  .ant-col-md-24.ant-form-item-label {
    display: block;
    margin: 0;
    padding: 0 6px 2px;
    line-height: 1.5715;
    white-space: normal;
    white-space: initial;
    text-align: left;
  }
  .ant-col-md-24.ant-form-item-label label::after {
    display: none;
  }
}
@media (max-width: 1199px) {
  .ant-col-lg-24.ant-form-item-label {
    display: block;
    margin: 0;
    padding: 0 6px 2px;
    line-height: 1.5715;
    white-space: normal;
    white-space: initial;
    text-align: left;
  }
  .ant-col-lg-24.ant-form-item-label label::after {
    display: none;
  }
}
@media (max-width: 1599px) {
  .ant-col-xl-24.ant-form-item-label {
    display: block;
    margin: 0;
    padding: 0 6px 2px;
    line-height: 1.5715;
    white-space: normal;
    white-space: initial;
    text-align: left;
  }
  .ant-col-xl-24.ant-form-item-label label::after {
    display: none;
  }
}
.ant-form-inline .ant-form-item {
  display: inline-block;
  margin-right: 16px;
  margin-bottom: 0;
}
.ant-form-inline .ant-form-item-with-help {
  margin-bottom: 16px;
}
.ant-form-inline .ant-form-item > .ant-form-item-control-wrapper,
.ant-form-inline .ant-form-item > .ant-form-item-label {
  display: inline-block;
  vertical-align: top;
}
.ant-form-inline .ant-form-text {
  display: inline-block;
}
.ant-form-inline .has-feedback {
  display: inline-block;
}
.has-success.has-feedback .ant-form-item-children-icon,
.has-warning.has-feedback .ant-form-item-children-icon,
.has-error.has-feedback .ant-form-item-children-icon,
.is-validating.has-feedback .ant-form-item-children-icon {
  position: absolute;
  top: 50%;
  right: 0;
  z-index: 1;
  width: 32px;
  height: 20px;
  margin-top: -10px;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  visibility: visible;
  animation: zoomIn 0.3s cubic-bezier(0.12, 0.4, 0.29, 1.46);
  pointer-events: none;
}
.has-success.has-feedback .ant-form-item-children-icon svg,
.has-warning.has-feedback .ant-form-item-children-icon svg,
.has-error.has-feedback .ant-form-item-children-icon svg,
.is-validating.has-feedback .ant-form-item-children-icon svg {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
}
.has-success.has-feedback .ant-form-item-children-icon {
  color: #52c41a;
  animation-name: diffZoomIn1 !important;
}
.has-warning .ant-form-explain,
.has-warning .ant-form-split {
  color: #faad14;
}
.has-warning .ant-input,
.has-warning .ant-input:hover {
  background-color: #fff;
  border-color: #faad14;
}
.has-warning .ant-input:focus {
  border-color: #ffc53d;
  border-right-width: 1px !important;
  outline: 0;
  box-shadow: 0 0 0 1px rgba(250, 173, 20, 0.2);
}
.has-warning .ant-input:not([disabled]):hover {
  border-color: #faad14;
}
.has-warning .ant-calendar-picker-open .ant-calendar-picker-input {
  border-color: #ffc53d;
  border-right-width: 1px !important;
  outline: 0;
  box-shadow: 0 0 0 1px rgba(250, 173, 20, 0.2);
}
.has-warning .ant-input-affix-wrapper .ant-input,
.has-warning .ant-input-affix-wrapper .ant-input:hover {
  background-color: #fff;
  border-color: #faad14;
}
.has-warning .ant-input-affix-wrapper .ant-input:focus {
  border-color: #ffc53d;
  border-right-width: 1px !important;
  outline: 0;
  box-shadow: 0 0 0 1px rgba(250, 173, 20, 0.2);
}
.has-warning .ant-input-affix-wrapper:hover .ant-input:not(.ant-input-disabled) {
  border-color: #faad14;
}
.has-warning .ant-input-prefix {
  color: #faad14;
}
.has-warning .ant-input-group-addon {
  color: #faad14;
  background-color: #fff;
  border-color: #faad14;
}
.has-warning .has-feedback {
  color: #faad14;
}
.has-warning.has-feedback .ant-form-item-children-icon {
  color: #faad14;
  animation-name: diffZoomIn3 !important;
}
.has-warning .ant-select-selection {
  border-color: #faad14;
}
.has-warning .ant-select-selection:hover {
  border-color: #faad14;
}
.has-warning .ant-select-open .ant-select-selection,
.has-warning .ant-select-focused .ant-select-selection {
  border-color: #ffc53d;
  border-right-width: 1px !important;
  outline: 0;
  box-shadow: 0 0 0 1px rgba(250, 173, 20, 0.2);
}
.has-warning .ant-calendar-picker-icon::after,
.has-warning .ant-time-picker-icon::after,
.has-warning .ant-picker-icon::after,
.has-warning .ant-select-arrow,
.has-warning .ant-cascader-picker-arrow {
  color: #faad14;
}
.has-warning .ant-input-number,
.has-warning .ant-time-picker-input {
  border-color: #faad14;
}
.has-warning .ant-input-number-focused,
.has-warning .ant-time-picker-input-focused,
.has-warning .ant-input-number:focus,
.has-warning .ant-time-picker-input:focus {
  border-color: #ffc53d;
  border-right-width: 1px !important;
  outline: 0;
  box-shadow: 0 0 0 1px rgba(250, 173, 20, 0.2);
}
.has-warning .ant-input-number:not([disabled]):hover,
.has-warning .ant-time-picker-input:not([disabled]):hover {
  border-color: #faad14;
}
.has-warning .ant-cascader-picker:focus .ant-cascader-input {
  border-color: #ffc53d;
  border-right-width: 1px !important;
  outline: 0;
  box-shadow: 0 0 0 1px rgba(250, 173, 20, 0.2);
}
.has-warning .ant-cascader-picker:hover .ant-cascader-input {
  border-color: #faad14;
}
.has-error .ant-form-explain,
.has-error .ant-form-split {
  color: #ff4d4f;
}
.has-error .ant-input,
.has-error .ant-input:hover {
  background-color: #fff;
  border-color: #ff4d4f;
}
.has-error .ant-input:focus {
  border-color: #ff7875;
  border-right-width: 1px !important;
  outline: 0;
  box-shadow: 0 0 0 1px rgba(255, 77, 79, 0.2);
}
.has-error .ant-input:not([disabled]):hover {
  border-color: #ff4d4f;
}
.has-error .ant-calendar-picker-open .ant-calendar-picker-input {
  border-color: #ff7875;
  border-right-width: 1px !important;
  outline: 0;
  box-shadow: 0 0 0 1px rgba(255, 77, 79, 0.2);
}
.has-error .ant-input-affix-wrapper .ant-input,
.has-error .ant-input-affix-wrapper .ant-input:hover {
  background-color: #fff;
  border-color: #ff4d4f;
}
.has-error .ant-input-affix-wrapper .ant-input:focus {
  border-color: #ff7875;
  border-right-width: 1px !important;
  outline: 0;
  box-shadow: 0 0 0 1px rgba(255, 77, 79, 0.2);
}
.has-error .ant-input-affix-wrapper:hover .ant-input:not(.ant-input-disabled) {
  border-color: #ff4d4f;
}
.has-error .ant-input-prefix {
  color: #ff4d4f;
}
.has-error .ant-input-group-addon {
  color: #ff4d4f;
  background-color: #fff;
  border-color: #ff4d4f;
}
.has-error .has-feedback {
  color: #ff4d4f;
}
.has-error.has-feedback .ant-form-item-children-icon {
  color: #ff4d4f;
  animation-name: diffZoomIn2 !important;
}
.has-error .ant-select-selection {
  border-color: #ff4d4f;
}
.has-error .ant-select-selection:hover {
  border-color: #ff4d4f;
}
.has-error .ant-select-open .ant-select-selection,
.has-error .ant-select-focused .ant-select-selection {
  border-color: #ff7875;
  border-right-width: 1px !important;
  outline: 0;
  box-shadow: 0 0 0 1px rgba(255, 77, 79, 0.2);
}
.has-error .ant-select.ant-select-auto-complete .ant-input:focus {
  border-color: #ff4d4f;
}
.has-error .ant-input-group-addon .ant-select-selection {
  border-color: transparent;
  box-shadow: none;
}
.has-error .ant-calendar-picker-icon::after,
.has-error .ant-time-picker-icon::after,
.has-error .ant-picker-icon::after,
.has-error .ant-select-arrow,
.has-error .ant-cascader-picker-arrow {
  color: #ff4d4f;
}
.has-error .ant-input-number,
.has-error .ant-time-picker-input {
  border-color: #ff4d4f;
}
.has-error .ant-input-number-focused,
.has-error .ant-time-picker-input-focused,
.has-error .ant-input-number:focus,
.has-error .ant-time-picker-input:focus {
  border-color: #ff7875;
  border-right-width: 1px !important;
  outline: 0;
  box-shadow: 0 0 0 1px rgba(255, 77, 79, 0.2);
}
.has-error .ant-input-number:not([disabled]):hover,
.has-error .ant-time-picker-input:not([disabled]):hover {
  border-color: #ff4d4f;
}
.has-error .ant-mention-wrapper .ant-mention-editor,
.has-error .ant-mention-wrapper .ant-mention-editor:not([disabled]):hover {
  border-color: #ff4d4f;
}
.has-error .ant-mention-wrapper.ant-mention-active:not([disabled]) .ant-mention-editor,
.has-error .ant-mention-wrapper .ant-mention-editor:not([disabled]):focus {
  border-color: #ff7875;
  border-right-width: 1px !important;
  outline: 0;
  box-shadow: 0 0 0 1px rgba(255, 77, 79, 0.2);
}
.has-error .ant-cascader-picker:focus .ant-cascader-input {
  border-color: #ff7875;
  border-right-width: 1px !important;
  outline: 0;
  box-shadow: 0 0 0 1px rgba(255, 77, 79, 0.2);
}
.has-error .ant-cascader-picker:hover .ant-cascader-input {
  border-color: #ff4d4f;
}
.has-error .ant-transfer-list {
  border-color: #ff4d4f;
}
.has-error .ant-transfer-list-search:not([disabled]) {
  border-color: #bfbfbf;
}
.has-error .ant-transfer-list-search:not([disabled]):hover {
  border-color: #333;
  border-right-width: 1px !important;
}
.has-error .ant-transfer-list-search:not([disabled]):focus {
  border-color: #666060;
  border-right-width: 1px !important;
  outline: 0;
  box-shadow: 0 0 0 1px rgba(89, 89, 89, 0.2);
}
.is-validating.has-feedback .ant-form-item-children-icon {
  display: inline-block;
  color: #00a6ff;
}
.ant-advanced-search-form .ant-form-item {
  margin-bottom: 16px;
}
.ant-advanced-search-form .ant-form-item-with-help {
  margin-bottom: -5.5px;
}
.show-help-enter,
.show-help-appear {
  animation-duration: 0.3s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.show-help-leave {
  animation-duration: 0.3s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.show-help-enter.show-help-enter-active,
.show-help-appear.show-help-appear-active {
  animation-name: antShowHelpIn;
  animation-play-state: running;
}
.show-help-leave.show-help-leave-active {
  animation-name: antShowHelpOut;
  animation-play-state: running;
  pointer-events: none;
}
.show-help-enter,
.show-help-appear {
  opacity: 0;
  animation-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
}
.show-help-leave {
  animation-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
}
@keyframes antShowHelpIn {
  0% {
    transform: translateY(-5px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}
@keyframes antShowHelpOut {
  to {
    transform: translateY(-5px);
    opacity: 0;
  }
}
@keyframes diffZoomIn1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes diffZoomIn2 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes diffZoomIn3 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
.ant-row {
  position: relative;
  height: auto;
  margin-right: 0;
  margin-left: 0;
  zoom: 1;
  display: block;
  box-sizing: border-box;
}
.ant-row::before,
.ant-row::after {
  display: table;
  content: '';
}
.ant-row::after {
  clear: both;
}
.ant-row::before,
.ant-row::after {
  display: table;
  content: '';
}
.ant-row::after {
  clear: both;
}
.ant-row + .ant-row::before {
  clear: both;
}
.ant-row-flex {
  display: flex;
  flex-flow: row wrap;
}
.ant-row-flex::before,
.ant-row-flex::after {
  display: flex;
}
.ant-row-flex-start {
  justify-content: flex-start;
}
.ant-row-flex-center {
  justify-content: center;
}
.ant-row-flex-end {
  justify-content: flex-end;
}
.ant-row-flex-space-between {
  justify-content: space-between;
}
.ant-row-flex-space-around {
  justify-content: space-around;
}
.ant-row-flex-top {
  align-items: flex-start;
}
.ant-row-flex-middle {
  align-items: center;
}
.ant-row-flex-bottom {
  align-items: flex-end;
}
.ant-col {
  position: relative;
  min-height: 1px;
}
.ant-col-1,
.ant-col-xs-1,
.ant-col-sm-1,
.ant-col-md-1,
.ant-col-lg-1,
.ant-col-2,
.ant-col-xs-2,
.ant-col-sm-2,
.ant-col-md-2,
.ant-col-lg-2,
.ant-col-3,
.ant-col-xs-3,
.ant-col-sm-3,
.ant-col-md-3,
.ant-col-lg-3,
.ant-col-4,
.ant-col-xs-4,
.ant-col-sm-4,
.ant-col-md-4,
.ant-col-lg-4,
.ant-col-5,
.ant-col-xs-5,
.ant-col-sm-5,
.ant-col-md-5,
.ant-col-lg-5,
.ant-col-6,
.ant-col-xs-6,
.ant-col-sm-6,
.ant-col-md-6,
.ant-col-lg-6,
.ant-col-7,
.ant-col-xs-7,
.ant-col-sm-7,
.ant-col-md-7,
.ant-col-lg-7,
.ant-col-8,
.ant-col-xs-8,
.ant-col-sm-8,
.ant-col-md-8,
.ant-col-lg-8,
.ant-col-9,
.ant-col-xs-9,
.ant-col-sm-9,
.ant-col-md-9,
.ant-col-lg-9,
.ant-col-10,
.ant-col-xs-10,
.ant-col-sm-10,
.ant-col-md-10,
.ant-col-lg-10,
.ant-col-11,
.ant-col-xs-11,
.ant-col-sm-11,
.ant-col-md-11,
.ant-col-lg-11,
.ant-col-12,
.ant-col-xs-12,
.ant-col-sm-12,
.ant-col-md-12,
.ant-col-lg-12,
.ant-col-13,
.ant-col-xs-13,
.ant-col-sm-13,
.ant-col-md-13,
.ant-col-lg-13,
.ant-col-14,
.ant-col-xs-14,
.ant-col-sm-14,
.ant-col-md-14,
.ant-col-lg-14,
.ant-col-15,
.ant-col-xs-15,
.ant-col-sm-15,
.ant-col-md-15,
.ant-col-lg-15,
.ant-col-16,
.ant-col-xs-16,
.ant-col-sm-16,
.ant-col-md-16,
.ant-col-lg-16,
.ant-col-17,
.ant-col-xs-17,
.ant-col-sm-17,
.ant-col-md-17,
.ant-col-lg-17,
.ant-col-18,
.ant-col-xs-18,
.ant-col-sm-18,
.ant-col-md-18,
.ant-col-lg-18,
.ant-col-19,
.ant-col-xs-19,
.ant-col-sm-19,
.ant-col-md-19,
.ant-col-lg-19,
.ant-col-20,
.ant-col-xs-20,
.ant-col-sm-20,
.ant-col-md-20,
.ant-col-lg-20,
.ant-col-21,
.ant-col-xs-21,
.ant-col-sm-21,
.ant-col-md-21,
.ant-col-lg-21,
.ant-col-22,
.ant-col-xs-22,
.ant-col-sm-22,
.ant-col-md-22,
.ant-col-lg-22,
.ant-col-23,
.ant-col-xs-23,
.ant-col-sm-23,
.ant-col-md-23,
.ant-col-lg-23,
.ant-col-24,
.ant-col-xs-24,
.ant-col-sm-24,
.ant-col-md-24,
.ant-col-lg-24 {
  position: relative;
  padding-right: 0;
  padding-left: 0;
}
.ant-col-1,
.ant-col-2,
.ant-col-3,
.ant-col-4,
.ant-col-5,
.ant-col-6,
.ant-col-7,
.ant-col-8,
.ant-col-9,
.ant-col-10,
.ant-col-11,
.ant-col-12,
.ant-col-13,
.ant-col-14,
.ant-col-15,
.ant-col-16,
.ant-col-17,
.ant-col-18,
.ant-col-19,
.ant-col-20,
.ant-col-21,
.ant-col-22,
.ant-col-23,
.ant-col-24 {
  flex: 0 0 auto;
  float: left;
}
.ant-col-24 {
  display: block;
  box-sizing: border-box;
  width: 100%;
}
.ant-col-push-24 {
  left: 100%;
}
.ant-col-pull-24 {
  right: 100%;
}
.ant-col-offset-24 {
  margin-left: 100%;
}
.ant-col-order-24 {
  order: 24;
}
.ant-col-23 {
  display: block;
  box-sizing: border-box;
  width: 95.83333333%;
}
.ant-col-push-23 {
  left: 95.83333333%;
}
.ant-col-pull-23 {
  right: 95.83333333%;
}
.ant-col-offset-23 {
  margin-left: 95.83333333%;
}
.ant-col-order-23 {
  order: 23;
}
.ant-col-22 {
  display: block;
  box-sizing: border-box;
  width: 91.66666667%;
}
.ant-col-push-22 {
  left: 91.66666667%;
}
.ant-col-pull-22 {
  right: 91.66666667%;
}
.ant-col-offset-22 {
  margin-left: 91.66666667%;
}
.ant-col-order-22 {
  order: 22;
}
.ant-col-21 {
  display: block;
  box-sizing: border-box;
  width: 87.5%;
}
.ant-col-push-21 {
  left: 87.5%;
}
.ant-col-pull-21 {
  right: 87.5%;
}
.ant-col-offset-21 {
  margin-left: 87.5%;
}
.ant-col-order-21 {
  order: 21;
}
.ant-col-20 {
  display: block;
  box-sizing: border-box;
  width: 83.33333333%;
}
.ant-col-push-20 {
  left: 83.33333333%;
}
.ant-col-pull-20 {
  right: 83.33333333%;
}
.ant-col-offset-20 {
  margin-left: 83.33333333%;
}
.ant-col-order-20 {
  order: 20;
}
.ant-col-19 {
  display: block;
  box-sizing: border-box;
  width: 79.16666667%;
}
.ant-col-push-19 {
  left: 79.16666667%;
}
.ant-col-pull-19 {
  right: 79.16666667%;
}
.ant-col-offset-19 {
  margin-left: 79.16666667%;
}
.ant-col-order-19 {
  order: 19;
}
.ant-col-18 {
  display: block;
  box-sizing: border-box;
  width: 75%;
}
.ant-col-push-18 {
  left: 75%;
}
.ant-col-pull-18 {
  right: 75%;
}
.ant-col-offset-18 {
  margin-left: 75%;
}
.ant-col-order-18 {
  order: 18;
}
.ant-col-17 {
  display: block;
  box-sizing: border-box;
  width: 70.83333333%;
}
.ant-col-push-17 {
  left: 70.83333333%;
}
.ant-col-pull-17 {
  right: 70.83333333%;
}
.ant-col-offset-17 {
  margin-left: 70.83333333%;
}
.ant-col-order-17 {
  order: 17;
}
.ant-col-16 {
  display: block;
  box-sizing: border-box;
  width: 66.66666667%;
}
.ant-col-push-16 {
  left: 66.66666667%;
}
.ant-col-pull-16 {
  right: 66.66666667%;
}
.ant-col-offset-16 {
  margin-left: 66.66666667%;
}
.ant-col-order-16 {
  order: 16;
}
.ant-col-15 {
  display: block;
  box-sizing: border-box;
  width: 62.5%;
}
.ant-col-push-15 {
  left: 62.5%;
}
.ant-col-pull-15 {
  right: 62.5%;
}
.ant-col-offset-15 {
  margin-left: 62.5%;
}
.ant-col-order-15 {
  order: 15;
}
.ant-col-14 {
  display: block;
  box-sizing: border-box;
  width: 58.33333333%;
}
.ant-col-push-14 {
  left: 58.33333333%;
}
.ant-col-pull-14 {
  right: 58.33333333%;
}
.ant-col-offset-14 {
  margin-left: 58.33333333%;
}
.ant-col-order-14 {
  order: 14;
}
.ant-col-13 {
  display: block;
  box-sizing: border-box;
  width: 54.16666667%;
}
.ant-col-push-13 {
  left: 54.16666667%;
}
.ant-col-pull-13 {
  right: 54.16666667%;
}
.ant-col-offset-13 {
  margin-left: 54.16666667%;
}
.ant-col-order-13 {
  order: 13;
}
.ant-col-12 {
  display: block;
  box-sizing: border-box;
  width: 50%;
}
.ant-col-push-12 {
  left: 50%;
}
.ant-col-pull-12 {
  right: 50%;
}
.ant-col-offset-12 {
  margin-left: 50%;
}
.ant-col-order-12 {
  order: 12;
}
.ant-col-11 {
  display: block;
  box-sizing: border-box;
  width: 45.83333333%;
}
.ant-col-push-11 {
  left: 45.83333333%;
}
.ant-col-pull-11 {
  right: 45.83333333%;
}
.ant-col-offset-11 {
  margin-left: 45.83333333%;
}
.ant-col-order-11 {
  order: 11;
}
.ant-col-10 {
  display: block;
  box-sizing: border-box;
  width: 41.66666667%;
}
.ant-col-push-10 {
  left: 41.66666667%;
}
.ant-col-pull-10 {
  right: 41.66666667%;
}
.ant-col-offset-10 {
  margin-left: 41.66666667%;
}
.ant-col-order-10 {
  order: 10;
}
.ant-col-9 {
  display: block;
  box-sizing: border-box;
  width: 37.5%;
}
.ant-col-push-9 {
  left: 37.5%;
}
.ant-col-pull-9 {
  right: 37.5%;
}
.ant-col-offset-9 {
  margin-left: 37.5%;
}
.ant-col-order-9 {
  order: 9;
}
.ant-col-8 {
  display: block;
  box-sizing: border-box;
  width: 33.33333333%;
}
.ant-col-push-8 {
  left: 33.33333333%;
}
.ant-col-pull-8 {
  right: 33.33333333%;
}
.ant-col-offset-8 {
  margin-left: 33.33333333%;
}
.ant-col-order-8 {
  order: 8;
}
.ant-col-7 {
  display: block;
  box-sizing: border-box;
  width: 29.16666667%;
}
.ant-col-push-7 {
  left: 29.16666667%;
}
.ant-col-pull-7 {
  right: 29.16666667%;
}
.ant-col-offset-7 {
  margin-left: 29.16666667%;
}
.ant-col-order-7 {
  order: 7;
}
.ant-col-6 {
  display: block;
  box-sizing: border-box;
  width: 25%;
}
.ant-col-push-6 {
  left: 25%;
}
.ant-col-pull-6 {
  right: 25%;
}
.ant-col-offset-6 {
  margin-left: 25%;
}
.ant-col-order-6 {
  order: 6;
}
.ant-col-5 {
  display: block;
  box-sizing: border-box;
  width: 20.83333333%;
}
.ant-col-push-5 {
  left: 20.83333333%;
}
.ant-col-pull-5 {
  right: 20.83333333%;
}
.ant-col-offset-5 {
  margin-left: 20.83333333%;
}
.ant-col-order-5 {
  order: 5;
}
.ant-col-4 {
  display: block;
  box-sizing: border-box;
  width: 16.66666667%;
}
.ant-col-push-4 {
  left: 16.66666667%;
}
.ant-col-pull-4 {
  right: 16.66666667%;
}
.ant-col-offset-4 {
  margin-left: 16.66666667%;
}
.ant-col-order-4 {
  order: 4;
}
.ant-col-3 {
  display: block;
  box-sizing: border-box;
  width: 12.5%;
}
.ant-col-push-3 {
  left: 12.5%;
}
.ant-col-pull-3 {
  right: 12.5%;
}
.ant-col-offset-3 {
  margin-left: 12.5%;
}
.ant-col-order-3 {
  order: 3;
}
.ant-col-2 {
  display: block;
  box-sizing: border-box;
  width: 8.33333333%;
}
.ant-col-push-2 {
  left: 8.33333333%;
}
.ant-col-pull-2 {
  right: 8.33333333%;
}
.ant-col-offset-2 {
  margin-left: 8.33333333%;
}
.ant-col-order-2 {
  order: 2;
}
.ant-col-1 {
  display: block;
  box-sizing: border-box;
  width: 4.16666667%;
}
.ant-col-push-1 {
  left: 4.16666667%;
}
.ant-col-pull-1 {
  right: 4.16666667%;
}
.ant-col-offset-1 {
  margin-left: 4.16666667%;
}
.ant-col-order-1 {
  order: 1;
}
.ant-col-0 {
  display: none;
}
.ant-col-push-0 {
  left: auto;
}
.ant-col-pull-0 {
  right: auto;
}
.ant-col-push-0 {
  left: auto;
}
.ant-col-pull-0 {
  right: auto;
}
.ant-col-offset-0 {
  margin-left: 0;
}
.ant-col-order-0 {
  order: 0;
}
.ant-col-xs-1,
.ant-col-xs-2,
.ant-col-xs-3,
.ant-col-xs-4,
.ant-col-xs-5,
.ant-col-xs-6,
.ant-col-xs-7,
.ant-col-xs-8,
.ant-col-xs-9,
.ant-col-xs-10,
.ant-col-xs-11,
.ant-col-xs-12,
.ant-col-xs-13,
.ant-col-xs-14,
.ant-col-xs-15,
.ant-col-xs-16,
.ant-col-xs-17,
.ant-col-xs-18,
.ant-col-xs-19,
.ant-col-xs-20,
.ant-col-xs-21,
.ant-col-xs-22,
.ant-col-xs-23,
.ant-col-xs-24 {
  flex: 0 0 auto;
  float: left;
}
.ant-col-xs-24 {
  display: block;
  box-sizing: border-box;
  width: 100%;
}
.ant-col-xs-push-24 {
  left: 100%;
}
.ant-col-xs-pull-24 {
  right: 100%;
}
.ant-col-xs-offset-24 {
  margin-left: 100%;
}
.ant-col-xs-order-24 {
  order: 24;
}
.ant-col-xs-23 {
  display: block;
  box-sizing: border-box;
  width: 95.83333333%;
}
.ant-col-xs-push-23 {
  left: 95.83333333%;
}
.ant-col-xs-pull-23 {
  right: 95.83333333%;
}
.ant-col-xs-offset-23 {
  margin-left: 95.83333333%;
}
.ant-col-xs-order-23 {
  order: 23;
}
.ant-col-xs-22 {
  display: block;
  box-sizing: border-box;
  width: 91.66666667%;
}
.ant-col-xs-push-22 {
  left: 91.66666667%;
}
.ant-col-xs-pull-22 {
  right: 91.66666667%;
}
.ant-col-xs-offset-22 {
  margin-left: 91.66666667%;
}
.ant-col-xs-order-22 {
  order: 22;
}
.ant-col-xs-21 {
  display: block;
  box-sizing: border-box;
  width: 87.5%;
}
.ant-col-xs-push-21 {
  left: 87.5%;
}
.ant-col-xs-pull-21 {
  right: 87.5%;
}
.ant-col-xs-offset-21 {
  margin-left: 87.5%;
}
.ant-col-xs-order-21 {
  order: 21;
}
.ant-col-xs-20 {
  display: block;
  box-sizing: border-box;
  width: 83.33333333%;
}
.ant-col-xs-push-20 {
  left: 83.33333333%;
}
.ant-col-xs-pull-20 {
  right: 83.33333333%;
}
.ant-col-xs-offset-20 {
  margin-left: 83.33333333%;
}
.ant-col-xs-order-20 {
  order: 20;
}
.ant-col-xs-19 {
  display: block;
  box-sizing: border-box;
  width: 79.16666667%;
}
.ant-col-xs-push-19 {
  left: 79.16666667%;
}
.ant-col-xs-pull-19 {
  right: 79.16666667%;
}
.ant-col-xs-offset-19 {
  margin-left: 79.16666667%;
}
.ant-col-xs-order-19 {
  order: 19;
}
.ant-col-xs-18 {
  display: block;
  box-sizing: border-box;
  width: 75%;
}
.ant-col-xs-push-18 {
  left: 75%;
}
.ant-col-xs-pull-18 {
  right: 75%;
}
.ant-col-xs-offset-18 {
  margin-left: 75%;
}
.ant-col-xs-order-18 {
  order: 18;
}
.ant-col-xs-17 {
  display: block;
  box-sizing: border-box;
  width: 70.83333333%;
}
.ant-col-xs-push-17 {
  left: 70.83333333%;
}
.ant-col-xs-pull-17 {
  right: 70.83333333%;
}
.ant-col-xs-offset-17 {
  margin-left: 70.83333333%;
}
.ant-col-xs-order-17 {
  order: 17;
}
.ant-col-xs-16 {
  display: block;
  box-sizing: border-box;
  width: 66.66666667%;
}
.ant-col-xs-push-16 {
  left: 66.66666667%;
}
.ant-col-xs-pull-16 {
  right: 66.66666667%;
}
.ant-col-xs-offset-16 {
  margin-left: 66.66666667%;
}
.ant-col-xs-order-16 {
  order: 16;
}
.ant-col-xs-15 {
  display: block;
  box-sizing: border-box;
  width: 62.5%;
}
.ant-col-xs-push-15 {
  left: 62.5%;
}
.ant-col-xs-pull-15 {
  right: 62.5%;
}
.ant-col-xs-offset-15 {
  margin-left: 62.5%;
}
.ant-col-xs-order-15 {
  order: 15;
}
.ant-col-xs-14 {
  display: block;
  box-sizing: border-box;
  width: 58.33333333%;
}
.ant-col-xs-push-14 {
  left: 58.33333333%;
}
.ant-col-xs-pull-14 {
  right: 58.33333333%;
}
.ant-col-xs-offset-14 {
  margin-left: 58.33333333%;
}
.ant-col-xs-order-14 {
  order: 14;
}
.ant-col-xs-13 {
  display: block;
  box-sizing: border-box;
  width: 54.16666667%;
}
.ant-col-xs-push-13 {
  left: 54.16666667%;
}
.ant-col-xs-pull-13 {
  right: 54.16666667%;
}
.ant-col-xs-offset-13 {
  margin-left: 54.16666667%;
}
.ant-col-xs-order-13 {
  order: 13;
}
.ant-col-xs-12 {
  display: block;
  box-sizing: border-box;
  width: 50%;
}
.ant-col-xs-push-12 {
  left: 50%;
}
.ant-col-xs-pull-12 {
  right: 50%;
}
.ant-col-xs-offset-12 {
  margin-left: 50%;
}
.ant-col-xs-order-12 {
  order: 12;
}
.ant-col-xs-11 {
  display: block;
  box-sizing: border-box;
  width: 45.83333333%;
}
.ant-col-xs-push-11 {
  left: 45.83333333%;
}
.ant-col-xs-pull-11 {
  right: 45.83333333%;
}
.ant-col-xs-offset-11 {
  margin-left: 45.83333333%;
}
.ant-col-xs-order-11 {
  order: 11;
}
.ant-col-xs-10 {
  display: block;
  box-sizing: border-box;
  width: 41.66666667%;
}
.ant-col-xs-push-10 {
  left: 41.66666667%;
}
.ant-col-xs-pull-10 {
  right: 41.66666667%;
}
.ant-col-xs-offset-10 {
  margin-left: 41.66666667%;
}
.ant-col-xs-order-10 {
  order: 10;
}
.ant-col-xs-9 {
  display: block;
  box-sizing: border-box;
  width: 37.5%;
}
.ant-col-xs-push-9 {
  left: 37.5%;
}
.ant-col-xs-pull-9 {
  right: 37.5%;
}
.ant-col-xs-offset-9 {
  margin-left: 37.5%;
}
.ant-col-xs-order-9 {
  order: 9;
}
.ant-col-xs-8 {
  display: block;
  box-sizing: border-box;
  width: 33.33333333%;
}
.ant-col-xs-push-8 {
  left: 33.33333333%;
}
.ant-col-xs-pull-8 {
  right: 33.33333333%;
}
.ant-col-xs-offset-8 {
  margin-left: 33.33333333%;
}
.ant-col-xs-order-8 {
  order: 8;
}
.ant-col-xs-7 {
  display: block;
  box-sizing: border-box;
  width: 29.16666667%;
}
.ant-col-xs-push-7 {
  left: 29.16666667%;
}
.ant-col-xs-pull-7 {
  right: 29.16666667%;
}
.ant-col-xs-offset-7 {
  margin-left: 29.16666667%;
}
.ant-col-xs-order-7 {
  order: 7;
}
.ant-col-xs-6 {
  display: block;
  box-sizing: border-box;
  width: 25%;
}
.ant-col-xs-push-6 {
  left: 25%;
}
.ant-col-xs-pull-6 {
  right: 25%;
}
.ant-col-xs-offset-6 {
  margin-left: 25%;
}
.ant-col-xs-order-6 {
  order: 6;
}
.ant-col-xs-5 {
  display: block;
  box-sizing: border-box;
  width: 20.83333333%;
}
.ant-col-xs-push-5 {
  left: 20.83333333%;
}
.ant-col-xs-pull-5 {
  right: 20.83333333%;
}
.ant-col-xs-offset-5 {
  margin-left: 20.83333333%;
}
.ant-col-xs-order-5 {
  order: 5;
}
.ant-col-xs-4 {
  display: block;
  box-sizing: border-box;
  width: 16.66666667%;
}
.ant-col-xs-push-4 {
  left: 16.66666667%;
}
.ant-col-xs-pull-4 {
  right: 16.66666667%;
}
.ant-col-xs-offset-4 {
  margin-left: 16.66666667%;
}
.ant-col-xs-order-4 {
  order: 4;
}
.ant-col-xs-3 {
  display: block;
  box-sizing: border-box;
  width: 12.5%;
}
.ant-col-xs-push-3 {
  left: 12.5%;
}
.ant-col-xs-pull-3 {
  right: 12.5%;
}
.ant-col-xs-offset-3 {
  margin-left: 12.5%;
}
.ant-col-xs-order-3 {
  order: 3;
}
.ant-col-xs-2 {
  display: block;
  box-sizing: border-box;
  width: 8.33333333%;
}
.ant-col-xs-push-2 {
  left: 8.33333333%;
}
.ant-col-xs-pull-2 {
  right: 8.33333333%;
}
.ant-col-xs-offset-2 {
  margin-left: 8.33333333%;
}
.ant-col-xs-order-2 {
  order: 2;
}
.ant-col-xs-1 {
  display: block;
  box-sizing: border-box;
  width: 4.16666667%;
}
.ant-col-xs-push-1 {
  left: 4.16666667%;
}
.ant-col-xs-pull-1 {
  right: 4.16666667%;
}
.ant-col-xs-offset-1 {
  margin-left: 4.16666667%;
}
.ant-col-xs-order-1 {
  order: 1;
}
.ant-col-xs-0 {
  display: none;
}
.ant-col-push-0 {
  left: auto;
}
.ant-col-pull-0 {
  right: auto;
}
.ant-col-xs-push-0 {
  left: auto;
}
.ant-col-xs-pull-0 {
  right: auto;
}
.ant-col-xs-offset-0 {
  margin-left: 0;
}
.ant-col-xs-order-0 {
  order: 0;
}
@media (min-width: 576px) {
  .ant-col-sm-1,
  .ant-col-sm-2,
  .ant-col-sm-3,
  .ant-col-sm-4,
  .ant-col-sm-5,
  .ant-col-sm-6,
  .ant-col-sm-7,
  .ant-col-sm-8,
  .ant-col-sm-9,
  .ant-col-sm-10,
  .ant-col-sm-11,
  .ant-col-sm-12,
  .ant-col-sm-13,
  .ant-col-sm-14,
  .ant-col-sm-15,
  .ant-col-sm-16,
  .ant-col-sm-17,
  .ant-col-sm-18,
  .ant-col-sm-19,
  .ant-col-sm-20,
  .ant-col-sm-21,
  .ant-col-sm-22,
  .ant-col-sm-23,
  .ant-col-sm-24 {
    flex: 0 0 auto;
    float: left;
  }
  .ant-col-sm-24 {
    display: block;
    box-sizing: border-box;
    width: 100%;
  }
  .ant-col-sm-push-24 {
    left: 100%;
  }
  .ant-col-sm-pull-24 {
    right: 100%;
  }
  .ant-col-sm-offset-24 {
    margin-left: 100%;
  }
  .ant-col-sm-order-24 {
    order: 24;
  }
  .ant-col-sm-23 {
    display: block;
    box-sizing: border-box;
    width: 95.83333333%;
  }
  .ant-col-sm-push-23 {
    left: 95.83333333%;
  }
  .ant-col-sm-pull-23 {
    right: 95.83333333%;
  }
  .ant-col-sm-offset-23 {
    margin-left: 95.83333333%;
  }
  .ant-col-sm-order-23 {
    order: 23;
  }
  .ant-col-sm-22 {
    display: block;
    box-sizing: border-box;
    width: 91.66666667%;
  }
  .ant-col-sm-push-22 {
    left: 91.66666667%;
  }
  .ant-col-sm-pull-22 {
    right: 91.66666667%;
  }
  .ant-col-sm-offset-22 {
    margin-left: 91.66666667%;
  }
  .ant-col-sm-order-22 {
    order: 22;
  }
  .ant-col-sm-21 {
    display: block;
    box-sizing: border-box;
    width: 87.5%;
  }
  .ant-col-sm-push-21 {
    left: 87.5%;
  }
  .ant-col-sm-pull-21 {
    right: 87.5%;
  }
  .ant-col-sm-offset-21 {
    margin-left: 87.5%;
  }
  .ant-col-sm-order-21 {
    order: 21;
  }
  .ant-col-sm-20 {
    display: block;
    box-sizing: border-box;
    width: 83.33333333%;
  }
  .ant-col-sm-push-20 {
    left: 83.33333333%;
  }
  .ant-col-sm-pull-20 {
    right: 83.33333333%;
  }
  .ant-col-sm-offset-20 {
    margin-left: 83.33333333%;
  }
  .ant-col-sm-order-20 {
    order: 20;
  }
  .ant-col-sm-19 {
    display: block;
    box-sizing: border-box;
    width: 79.16666667%;
  }
  .ant-col-sm-push-19 {
    left: 79.16666667%;
  }
  .ant-col-sm-pull-19 {
    right: 79.16666667%;
  }
  .ant-col-sm-offset-19 {
    margin-left: 79.16666667%;
  }
  .ant-col-sm-order-19 {
    order: 19;
  }
  .ant-col-sm-18 {
    display: block;
    box-sizing: border-box;
    width: 75%;
  }
  .ant-col-sm-push-18 {
    left: 75%;
  }
  .ant-col-sm-pull-18 {
    right: 75%;
  }
  .ant-col-sm-offset-18 {
    margin-left: 75%;
  }
  .ant-col-sm-order-18 {
    order: 18;
  }
  .ant-col-sm-17 {
    display: block;
    box-sizing: border-box;
    width: 70.83333333%;
  }
  .ant-col-sm-push-17 {
    left: 70.83333333%;
  }
  .ant-col-sm-pull-17 {
    right: 70.83333333%;
  }
  .ant-col-sm-offset-17 {
    margin-left: 70.83333333%;
  }
  .ant-col-sm-order-17 {
    order: 17;
  }
  .ant-col-sm-16 {
    display: block;
    box-sizing: border-box;
    width: 66.66666667%;
  }
  .ant-col-sm-push-16 {
    left: 66.66666667%;
  }
  .ant-col-sm-pull-16 {
    right: 66.66666667%;
  }
  .ant-col-sm-offset-16 {
    margin-left: 66.66666667%;
  }
  .ant-col-sm-order-16 {
    order: 16;
  }
  .ant-col-sm-15 {
    display: block;
    box-sizing: border-box;
    width: 62.5%;
  }
  .ant-col-sm-push-15 {
    left: 62.5%;
  }
  .ant-col-sm-pull-15 {
    right: 62.5%;
  }
  .ant-col-sm-offset-15 {
    margin-left: 62.5%;
  }
  .ant-col-sm-order-15 {
    order: 15;
  }
  .ant-col-sm-14 {
    display: block;
    box-sizing: border-box;
    width: 58.33333333%;
  }
  .ant-col-sm-push-14 {
    left: 58.33333333%;
  }
  .ant-col-sm-pull-14 {
    right: 58.33333333%;
  }
  .ant-col-sm-offset-14 {
    margin-left: 58.33333333%;
  }
  .ant-col-sm-order-14 {
    order: 14;
  }
  .ant-col-sm-13 {
    display: block;
    box-sizing: border-box;
    width: 54.16666667%;
  }
  .ant-col-sm-push-13 {
    left: 54.16666667%;
  }
  .ant-col-sm-pull-13 {
    right: 54.16666667%;
  }
  .ant-col-sm-offset-13 {
    margin-left: 54.16666667%;
  }
  .ant-col-sm-order-13 {
    order: 13;
  }
  .ant-col-sm-12 {
    display: block;
    box-sizing: border-box;
    width: 50%;
  }
  .ant-col-sm-push-12 {
    left: 50%;
  }
  .ant-col-sm-pull-12 {
    right: 50%;
  }
  .ant-col-sm-offset-12 {
    margin-left: 50%;
  }
  .ant-col-sm-order-12 {
    order: 12;
  }
  .ant-col-sm-11 {
    display: block;
    box-sizing: border-box;
    width: 45.83333333%;
  }
  .ant-col-sm-push-11 {
    left: 45.83333333%;
  }
  .ant-col-sm-pull-11 {
    right: 45.83333333%;
  }
  .ant-col-sm-offset-11 {
    margin-left: 45.83333333%;
  }
  .ant-col-sm-order-11 {
    order: 11;
  }
  .ant-col-sm-10 {
    display: block;
    box-sizing: border-box;
    width: 41.66666667%;
  }
  .ant-col-sm-push-10 {
    left: 41.66666667%;
  }
  .ant-col-sm-pull-10 {
    right: 41.66666667%;
  }
  .ant-col-sm-offset-10 {
    margin-left: 41.66666667%;
  }
  .ant-col-sm-order-10 {
    order: 10;
  }
  .ant-col-sm-9 {
    display: block;
    box-sizing: border-box;
    width: 37.5%;
  }
  .ant-col-sm-push-9 {
    left: 37.5%;
  }
  .ant-col-sm-pull-9 {
    right: 37.5%;
  }
  .ant-col-sm-offset-9 {
    margin-left: 37.5%;
  }
  .ant-col-sm-order-9 {
    order: 9;
  }
  .ant-col-sm-8 {
    display: block;
    box-sizing: border-box;
    width: 33.33333333%;
  }
  .ant-col-sm-push-8 {
    left: 33.33333333%;
  }
  .ant-col-sm-pull-8 {
    right: 33.33333333%;
  }
  .ant-col-sm-offset-8 {
    margin-left: 33.33333333%;
  }
  .ant-col-sm-order-8 {
    order: 8;
  }
  .ant-col-sm-7 {
    display: block;
    box-sizing: border-box;
    width: 29.16666667%;
  }
  .ant-col-sm-push-7 {
    left: 29.16666667%;
  }
  .ant-col-sm-pull-7 {
    right: 29.16666667%;
  }
  .ant-col-sm-offset-7 {
    margin-left: 29.16666667%;
  }
  .ant-col-sm-order-7 {
    order: 7;
  }
  .ant-col-sm-6 {
    display: block;
    box-sizing: border-box;
    width: 25%;
  }
  .ant-col-sm-push-6 {
    left: 25%;
  }
  .ant-col-sm-pull-6 {
    right: 25%;
  }
  .ant-col-sm-offset-6 {
    margin-left: 25%;
  }
  .ant-col-sm-order-6 {
    order: 6;
  }
  .ant-col-sm-5 {
    display: block;
    box-sizing: border-box;
    width: 20.83333333%;
  }
  .ant-col-sm-push-5 {
    left: 20.83333333%;
  }
  .ant-col-sm-pull-5 {
    right: 20.83333333%;
  }
  .ant-col-sm-offset-5 {
    margin-left: 20.83333333%;
  }
  .ant-col-sm-order-5 {
    order: 5;
  }
  .ant-col-sm-4 {
    display: block;
    box-sizing: border-box;
    width: 16.66666667%;
  }
  .ant-col-sm-push-4 {
    left: 16.66666667%;
  }
  .ant-col-sm-pull-4 {
    right: 16.66666667%;
  }
  .ant-col-sm-offset-4 {
    margin-left: 16.66666667%;
  }
  .ant-col-sm-order-4 {
    order: 4;
  }
  .ant-col-sm-3 {
    display: block;
    box-sizing: border-box;
    width: 12.5%;
  }
  .ant-col-sm-push-3 {
    left: 12.5%;
  }
  .ant-col-sm-pull-3 {
    right: 12.5%;
  }
  .ant-col-sm-offset-3 {
    margin-left: 12.5%;
  }
  .ant-col-sm-order-3 {
    order: 3;
  }
  .ant-col-sm-2 {
    display: block;
    box-sizing: border-box;
    width: 8.33333333%;
  }
  .ant-col-sm-push-2 {
    left: 8.33333333%;
  }
  .ant-col-sm-pull-2 {
    right: 8.33333333%;
  }
  .ant-col-sm-offset-2 {
    margin-left: 8.33333333%;
  }
  .ant-col-sm-order-2 {
    order: 2;
  }
  .ant-col-sm-1 {
    display: block;
    box-sizing: border-box;
    width: 4.16666667%;
  }
  .ant-col-sm-push-1 {
    left: 4.16666667%;
  }
  .ant-col-sm-pull-1 {
    right: 4.16666667%;
  }
  .ant-col-sm-offset-1 {
    margin-left: 4.16666667%;
  }
  .ant-col-sm-order-1 {
    order: 1;
  }
  .ant-col-sm-0 {
    display: none;
  }
  .ant-col-push-0 {
    left: auto;
  }
  .ant-col-pull-0 {
    right: auto;
  }
  .ant-col-sm-push-0 {
    left: auto;
  }
  .ant-col-sm-pull-0 {
    right: auto;
  }
  .ant-col-sm-offset-0 {
    margin-left: 0;
  }
  .ant-col-sm-order-0 {
    order: 0;
  }
}
@media (min-width: 768px) {
  .ant-col-md-1,
  .ant-col-md-2,
  .ant-col-md-3,
  .ant-col-md-4,
  .ant-col-md-5,
  .ant-col-md-6,
  .ant-col-md-7,
  .ant-col-md-8,
  .ant-col-md-9,
  .ant-col-md-10,
  .ant-col-md-11,
  .ant-col-md-12,
  .ant-col-md-13,
  .ant-col-md-14,
  .ant-col-md-15,
  .ant-col-md-16,
  .ant-col-md-17,
  .ant-col-md-18,
  .ant-col-md-19,
  .ant-col-md-20,
  .ant-col-md-21,
  .ant-col-md-22,
  .ant-col-md-23,
  .ant-col-md-24 {
    flex: 0 0 auto;
    float: left;
  }
  .ant-col-md-24 {
    display: block;
    box-sizing: border-box;
    width: 100%;
  }
  .ant-col-md-push-24 {
    left: 100%;
  }
  .ant-col-md-pull-24 {
    right: 100%;
  }
  .ant-col-md-offset-24 {
    margin-left: 100%;
  }
  .ant-col-md-order-24 {
    order: 24;
  }
  .ant-col-md-23 {
    display: block;
    box-sizing: border-box;
    width: 95.83333333%;
  }
  .ant-col-md-push-23 {
    left: 95.83333333%;
  }
  .ant-col-md-pull-23 {
    right: 95.83333333%;
  }
  .ant-col-md-offset-23 {
    margin-left: 95.83333333%;
  }
  .ant-col-md-order-23 {
    order: 23;
  }
  .ant-col-md-22 {
    display: block;
    box-sizing: border-box;
    width: 91.66666667%;
  }
  .ant-col-md-push-22 {
    left: 91.66666667%;
  }
  .ant-col-md-pull-22 {
    right: 91.66666667%;
  }
  .ant-col-md-offset-22 {
    margin-left: 91.66666667%;
  }
  .ant-col-md-order-22 {
    order: 22;
  }
  .ant-col-md-21 {
    display: block;
    box-sizing: border-box;
    width: 87.5%;
  }
  .ant-col-md-push-21 {
    left: 87.5%;
  }
  .ant-col-md-pull-21 {
    right: 87.5%;
  }
  .ant-col-md-offset-21 {
    margin-left: 87.5%;
  }
  .ant-col-md-order-21 {
    order: 21;
  }
  .ant-col-md-20 {
    display: block;
    box-sizing: border-box;
    width: 83.33333333%;
  }
  .ant-col-md-push-20 {
    left: 83.33333333%;
  }
  .ant-col-md-pull-20 {
    right: 83.33333333%;
  }
  .ant-col-md-offset-20 {
    margin-left: 83.33333333%;
  }
  .ant-col-md-order-20 {
    order: 20;
  }
  .ant-col-md-19 {
    display: block;
    box-sizing: border-box;
    width: 79.16666667%;
  }
  .ant-col-md-push-19 {
    left: 79.16666667%;
  }
  .ant-col-md-pull-19 {
    right: 79.16666667%;
  }
  .ant-col-md-offset-19 {
    margin-left: 79.16666667%;
  }
  .ant-col-md-order-19 {
    order: 19;
  }
  .ant-col-md-18 {
    display: block;
    box-sizing: border-box;
    width: 75%;
  }
  .ant-col-md-push-18 {
    left: 75%;
  }
  .ant-col-md-pull-18 {
    right: 75%;
  }
  .ant-col-md-offset-18 {
    margin-left: 75%;
  }
  .ant-col-md-order-18 {
    order: 18;
  }
  .ant-col-md-17 {
    display: block;
    box-sizing: border-box;
    width: 70.83333333%;
  }
  .ant-col-md-push-17 {
    left: 70.83333333%;
  }
  .ant-col-md-pull-17 {
    right: 70.83333333%;
  }
  .ant-col-md-offset-17 {
    margin-left: 70.83333333%;
  }
  .ant-col-md-order-17 {
    order: 17;
  }
  .ant-col-md-16 {
    display: block;
    box-sizing: border-box;
    width: 66.66666667%;
  }
  .ant-col-md-push-16 {
    left: 66.66666667%;
  }
  .ant-col-md-pull-16 {
    right: 66.66666667%;
  }
  .ant-col-md-offset-16 {
    margin-left: 66.66666667%;
  }
  .ant-col-md-order-16 {
    order: 16;
  }
  .ant-col-md-15 {
    display: block;
    box-sizing: border-box;
    width: 62.5%;
  }
  .ant-col-md-push-15 {
    left: 62.5%;
  }
  .ant-col-md-pull-15 {
    right: 62.5%;
  }
  .ant-col-md-offset-15 {
    margin-left: 62.5%;
  }
  .ant-col-md-order-15 {
    order: 15;
  }
  .ant-col-md-14 {
    display: block;
    box-sizing: border-box;
    width: 58.33333333%;
  }
  .ant-col-md-push-14 {
    left: 58.33333333%;
  }
  .ant-col-md-pull-14 {
    right: 58.33333333%;
  }
  .ant-col-md-offset-14 {
    margin-left: 58.33333333%;
  }
  .ant-col-md-order-14 {
    order: 14;
  }
  .ant-col-md-13 {
    display: block;
    box-sizing: border-box;
    width: 54.16666667%;
  }
  .ant-col-md-push-13 {
    left: 54.16666667%;
  }
  .ant-col-md-pull-13 {
    right: 54.16666667%;
  }
  .ant-col-md-offset-13 {
    margin-left: 54.16666667%;
  }
  .ant-col-md-order-13 {
    order: 13;
  }
  .ant-col-md-12 {
    display: block;
    box-sizing: border-box;
    width: 50%;
  }
  .ant-col-md-push-12 {
    left: 50%;
  }
  .ant-col-md-pull-12 {
    right: 50%;
  }
  .ant-col-md-offset-12 {
    margin-left: 50%;
  }
  .ant-col-md-order-12 {
    order: 12;
  }
  .ant-col-md-11 {
    display: block;
    box-sizing: border-box;
    width: 45.83333333%;
  }
  .ant-col-md-push-11 {
    left: 45.83333333%;
  }
  .ant-col-md-pull-11 {
    right: 45.83333333%;
  }
  .ant-col-md-offset-11 {
    margin-left: 45.83333333%;
  }
  .ant-col-md-order-11 {
    order: 11;
  }
  .ant-col-md-10 {
    display: block;
    box-sizing: border-box;
    width: 41.66666667%;
  }
  .ant-col-md-push-10 {
    left: 41.66666667%;
  }
  .ant-col-md-pull-10 {
    right: 41.66666667%;
  }
  .ant-col-md-offset-10 {
    margin-left: 41.66666667%;
  }
  .ant-col-md-order-10 {
    order: 10;
  }
  .ant-col-md-9 {
    display: block;
    box-sizing: border-box;
    width: 37.5%;
  }
  .ant-col-md-push-9 {
    left: 37.5%;
  }
  .ant-col-md-pull-9 {
    right: 37.5%;
  }
  .ant-col-md-offset-9 {
    margin-left: 37.5%;
  }
  .ant-col-md-order-9 {
    order: 9;
  }
  .ant-col-md-8 {
    display: block;
    box-sizing: border-box;
    width: 33.33333333%;
  }
  .ant-col-md-push-8 {
    left: 33.33333333%;
  }
  .ant-col-md-pull-8 {
    right: 33.33333333%;
  }
  .ant-col-md-offset-8 {
    margin-left: 33.33333333%;
  }
  .ant-col-md-order-8 {
    order: 8;
  }
  .ant-col-md-7 {
    display: block;
    box-sizing: border-box;
    width: 29.16666667%;
  }
  .ant-col-md-push-7 {
    left: 29.16666667%;
  }
  .ant-col-md-pull-7 {
    right: 29.16666667%;
  }
  .ant-col-md-offset-7 {
    margin-left: 29.16666667%;
  }
  .ant-col-md-order-7 {
    order: 7;
  }
  .ant-col-md-6 {
    display: block;
    box-sizing: border-box;
    width: 25%;
  }
  .ant-col-md-push-6 {
    left: 25%;
  }
  .ant-col-md-pull-6 {
    right: 25%;
  }
  .ant-col-md-offset-6 {
    margin-left: 25%;
  }
  .ant-col-md-order-6 {
    order: 6;
  }
  .ant-col-md-5 {
    display: block;
    box-sizing: border-box;
    width: 20.83333333%;
  }
  .ant-col-md-push-5 {
    left: 20.83333333%;
  }
  .ant-col-md-pull-5 {
    right: 20.83333333%;
  }
  .ant-col-md-offset-5 {
    margin-left: 20.83333333%;
  }
  .ant-col-md-order-5 {
    order: 5;
  }
  .ant-col-md-4 {
    display: block;
    box-sizing: border-box;
    width: 16.66666667%;
  }
  .ant-col-md-push-4 {
    left: 16.66666667%;
  }
  .ant-col-md-pull-4 {
    right: 16.66666667%;
  }
  .ant-col-md-offset-4 {
    margin-left: 16.66666667%;
  }
  .ant-col-md-order-4 {
    order: 4;
  }
  .ant-col-md-3 {
    display: block;
    box-sizing: border-box;
    width: 12.5%;
  }
  .ant-col-md-push-3 {
    left: 12.5%;
  }
  .ant-col-md-pull-3 {
    right: 12.5%;
  }
  .ant-col-md-offset-3 {
    margin-left: 12.5%;
  }
  .ant-col-md-order-3 {
    order: 3;
  }
  .ant-col-md-2 {
    display: block;
    box-sizing: border-box;
    width: 8.33333333%;
  }
  .ant-col-md-push-2 {
    left: 8.33333333%;
  }
  .ant-col-md-pull-2 {
    right: 8.33333333%;
  }
  .ant-col-md-offset-2 {
    margin-left: 8.33333333%;
  }
  .ant-col-md-order-2 {
    order: 2;
  }
  .ant-col-md-1 {
    display: block;
    box-sizing: border-box;
    width: 4.16666667%;
  }
  .ant-col-md-push-1 {
    left: 4.16666667%;
  }
  .ant-col-md-pull-1 {
    right: 4.16666667%;
  }
  .ant-col-md-offset-1 {
    margin-left: 4.16666667%;
  }
  .ant-col-md-order-1 {
    order: 1;
  }
  .ant-col-md-0 {
    display: none;
  }
  .ant-col-push-0 {
    left: auto;
  }
  .ant-col-pull-0 {
    right: auto;
  }
  .ant-col-md-push-0 {
    left: auto;
  }
  .ant-col-md-pull-0 {
    right: auto;
  }
  .ant-col-md-offset-0 {
    margin-left: 0;
  }
  .ant-col-md-order-0 {
    order: 0;
  }
}
@media (min-width: 992px) {
  .ant-col-lg-1,
  .ant-col-lg-2,
  .ant-col-lg-3,
  .ant-col-lg-4,
  .ant-col-lg-5,
  .ant-col-lg-6,
  .ant-col-lg-7,
  .ant-col-lg-8,
  .ant-col-lg-9,
  .ant-col-lg-10,
  .ant-col-lg-11,
  .ant-col-lg-12,
  .ant-col-lg-13,
  .ant-col-lg-14,
  .ant-col-lg-15,
  .ant-col-lg-16,
  .ant-col-lg-17,
  .ant-col-lg-18,
  .ant-col-lg-19,
  .ant-col-lg-20,
  .ant-col-lg-21,
  .ant-col-lg-22,
  .ant-col-lg-23,
  .ant-col-lg-24 {
    flex: 0 0 auto;
    float: left;
  }
  .ant-col-lg-24 {
    display: block;
    box-sizing: border-box;
    width: 100%;
  }
  .ant-col-lg-push-24 {
    left: 100%;
  }
  .ant-col-lg-pull-24 {
    right: 100%;
  }
  .ant-col-lg-offset-24 {
    margin-left: 100%;
  }
  .ant-col-lg-order-24 {
    order: 24;
  }
  .ant-col-lg-23 {
    display: block;
    box-sizing: border-box;
    width: 95.83333333%;
  }
  .ant-col-lg-push-23 {
    left: 95.83333333%;
  }
  .ant-col-lg-pull-23 {
    right: 95.83333333%;
  }
  .ant-col-lg-offset-23 {
    margin-left: 95.83333333%;
  }
  .ant-col-lg-order-23 {
    order: 23;
  }
  .ant-col-lg-22 {
    display: block;
    box-sizing: border-box;
    width: 91.66666667%;
  }
  .ant-col-lg-push-22 {
    left: 91.66666667%;
  }
  .ant-col-lg-pull-22 {
    right: 91.66666667%;
  }
  .ant-col-lg-offset-22 {
    margin-left: 91.66666667%;
  }
  .ant-col-lg-order-22 {
    order: 22;
  }
  .ant-col-lg-21 {
    display: block;
    box-sizing: border-box;
    width: 87.5%;
  }
  .ant-col-lg-push-21 {
    left: 87.5%;
  }
  .ant-col-lg-pull-21 {
    right: 87.5%;
  }
  .ant-col-lg-offset-21 {
    margin-left: 87.5%;
  }
  .ant-col-lg-order-21 {
    order: 21;
  }
  .ant-col-lg-20 {
    display: block;
    box-sizing: border-box;
    width: 83.33333333%;
  }
  .ant-col-lg-push-20 {
    left: 83.33333333%;
  }
  .ant-col-lg-pull-20 {
    right: 83.33333333%;
  }
  .ant-col-lg-offset-20 {
    margin-left: 83.33333333%;
  }
  .ant-col-lg-order-20 {
    order: 20;
  }
  .ant-col-lg-19 {
    display: block;
    box-sizing: border-box;
    width: 79.16666667%;
  }
  .ant-col-lg-push-19 {
    left: 79.16666667%;
  }
  .ant-col-lg-pull-19 {
    right: 79.16666667%;
  }
  .ant-col-lg-offset-19 {
    margin-left: 79.16666667%;
  }
  .ant-col-lg-order-19 {
    order: 19;
  }
  .ant-col-lg-18 {
    display: block;
    box-sizing: border-box;
    width: 75%;
  }
  .ant-col-lg-push-18 {
    left: 75%;
  }
  .ant-col-lg-pull-18 {
    right: 75%;
  }
  .ant-col-lg-offset-18 {
    margin-left: 75%;
  }
  .ant-col-lg-order-18 {
    order: 18;
  }
  .ant-col-lg-17 {
    display: block;
    box-sizing: border-box;
    width: 70.83333333%;
  }
  .ant-col-lg-push-17 {
    left: 70.83333333%;
  }
  .ant-col-lg-pull-17 {
    right: 70.83333333%;
  }
  .ant-col-lg-offset-17 {
    margin-left: 70.83333333%;
  }
  .ant-col-lg-order-17 {
    order: 17;
  }
  .ant-col-lg-16 {
    display: block;
    box-sizing: border-box;
    width: 66.66666667%;
  }
  .ant-col-lg-push-16 {
    left: 66.66666667%;
  }
  .ant-col-lg-pull-16 {
    right: 66.66666667%;
  }
  .ant-col-lg-offset-16 {
    margin-left: 66.66666667%;
  }
  .ant-col-lg-order-16 {
    order: 16;
  }
  .ant-col-lg-15 {
    display: block;
    box-sizing: border-box;
    width: 62.5%;
  }
  .ant-col-lg-push-15 {
    left: 62.5%;
  }
  .ant-col-lg-pull-15 {
    right: 62.5%;
  }
  .ant-col-lg-offset-15 {
    margin-left: 62.5%;
  }
  .ant-col-lg-order-15 {
    order: 15;
  }
  .ant-col-lg-14 {
    display: block;
    box-sizing: border-box;
    width: 58.33333333%;
  }
  .ant-col-lg-push-14 {
    left: 58.33333333%;
  }
  .ant-col-lg-pull-14 {
    right: 58.33333333%;
  }
  .ant-col-lg-offset-14 {
    margin-left: 58.33333333%;
  }
  .ant-col-lg-order-14 {
    order: 14;
  }
  .ant-col-lg-13 {
    display: block;
    box-sizing: border-box;
    width: 54.16666667%;
  }
  .ant-col-lg-push-13 {
    left: 54.16666667%;
  }
  .ant-col-lg-pull-13 {
    right: 54.16666667%;
  }
  .ant-col-lg-offset-13 {
    margin-left: 54.16666667%;
  }
  .ant-col-lg-order-13 {
    order: 13;
  }
  .ant-col-lg-12 {
    display: block;
    box-sizing: border-box;
    width: 50%;
  }
  .ant-col-lg-push-12 {
    left: 50%;
  }
  .ant-col-lg-pull-12 {
    right: 50%;
  }
  .ant-col-lg-offset-12 {
    margin-left: 50%;
  }
  .ant-col-lg-order-12 {
    order: 12;
  }
  .ant-col-lg-11 {
    display: block;
    box-sizing: border-box;
    width: 45.83333333%;
  }
  .ant-col-lg-push-11 {
    left: 45.83333333%;
  }
  .ant-col-lg-pull-11 {
    right: 45.83333333%;
  }
  .ant-col-lg-offset-11 {
    margin-left: 45.83333333%;
  }
  .ant-col-lg-order-11 {
    order: 11;
  }
  .ant-col-lg-10 {
    display: block;
    box-sizing: border-box;
    width: 41.66666667%;
  }
  .ant-col-lg-push-10 {
    left: 41.66666667%;
  }
  .ant-col-lg-pull-10 {
    right: 41.66666667%;
  }
  .ant-col-lg-offset-10 {
    margin-left: 41.66666667%;
  }
  .ant-col-lg-order-10 {
    order: 10;
  }
  .ant-col-lg-9 {
    display: block;
    box-sizing: border-box;
    width: 37.5%;
  }
  .ant-col-lg-push-9 {
    left: 37.5%;
  }
  .ant-col-lg-pull-9 {
    right: 37.5%;
  }
  .ant-col-lg-offset-9 {
    margin-left: 37.5%;
  }
  .ant-col-lg-order-9 {
    order: 9;
  }
  .ant-col-lg-8 {
    display: block;
    box-sizing: border-box;
    width: 33.33333333%;
  }
  .ant-col-lg-push-8 {
    left: 33.33333333%;
  }
  .ant-col-lg-pull-8 {
    right: 33.33333333%;
  }
  .ant-col-lg-offset-8 {
    margin-left: 33.33333333%;
  }
  .ant-col-lg-order-8 {
    order: 8;
  }
  .ant-col-lg-7 {
    display: block;
    box-sizing: border-box;
    width: 29.16666667%;
  }
  .ant-col-lg-push-7 {
    left: 29.16666667%;
  }
  .ant-col-lg-pull-7 {
    right: 29.16666667%;
  }
  .ant-col-lg-offset-7 {
    margin-left: 29.16666667%;
  }
  .ant-col-lg-order-7 {
    order: 7;
  }
  .ant-col-lg-6 {
    display: block;
    box-sizing: border-box;
    width: 25%;
  }
  .ant-col-lg-push-6 {
    left: 25%;
  }
  .ant-col-lg-pull-6 {
    right: 25%;
  }
  .ant-col-lg-offset-6 {
    margin-left: 25%;
  }
  .ant-col-lg-order-6 {
    order: 6;
  }
  .ant-col-lg-5 {
    display: block;
    box-sizing: border-box;
    width: 20.83333333%;
  }
  .ant-col-lg-push-5 {
    left: 20.83333333%;
  }
  .ant-col-lg-pull-5 {
    right: 20.83333333%;
  }
  .ant-col-lg-offset-5 {
    margin-left: 20.83333333%;
  }
  .ant-col-lg-order-5 {
    order: 5;
  }
  .ant-col-lg-4 {
    display: block;
    box-sizing: border-box;
    width: 16.66666667%;
  }
  .ant-col-lg-push-4 {
    left: 16.66666667%;
  }
  .ant-col-lg-pull-4 {
    right: 16.66666667%;
  }
  .ant-col-lg-offset-4 {
    margin-left: 16.66666667%;
  }
  .ant-col-lg-order-4 {
    order: 4;
  }
  .ant-col-lg-3 {
    display: block;
    box-sizing: border-box;
    width: 12.5%;
  }
  .ant-col-lg-push-3 {
    left: 12.5%;
  }
  .ant-col-lg-pull-3 {
    right: 12.5%;
  }
  .ant-col-lg-offset-3 {
    margin-left: 12.5%;
  }
  .ant-col-lg-order-3 {
    order: 3;
  }
  .ant-col-lg-2 {
    display: block;
    box-sizing: border-box;
    width: 8.33333333%;
  }
  .ant-col-lg-push-2 {
    left: 8.33333333%;
  }
  .ant-col-lg-pull-2 {
    right: 8.33333333%;
  }
  .ant-col-lg-offset-2 {
    margin-left: 8.33333333%;
  }
  .ant-col-lg-order-2 {
    order: 2;
  }
  .ant-col-lg-1 {
    display: block;
    box-sizing: border-box;
    width: 4.16666667%;
  }
  .ant-col-lg-push-1 {
    left: 4.16666667%;
  }
  .ant-col-lg-pull-1 {
    right: 4.16666667%;
  }
  .ant-col-lg-offset-1 {
    margin-left: 4.16666667%;
  }
  .ant-col-lg-order-1 {
    order: 1;
  }
  .ant-col-lg-0 {
    display: none;
  }
  .ant-col-push-0 {
    left: auto;
  }
  .ant-col-pull-0 {
    right: auto;
  }
  .ant-col-lg-push-0 {
    left: auto;
  }
  .ant-col-lg-pull-0 {
    right: auto;
  }
  .ant-col-lg-offset-0 {
    margin-left: 0;
  }
  .ant-col-lg-order-0 {
    order: 0;
  }
}
@media (min-width: 1200px) {
  .ant-col-xl-1,
  .ant-col-xl-2,
  .ant-col-xl-3,
  .ant-col-xl-4,
  .ant-col-xl-5,
  .ant-col-xl-6,
  .ant-col-xl-7,
  .ant-col-xl-8,
  .ant-col-xl-9,
  .ant-col-xl-10,
  .ant-col-xl-11,
  .ant-col-xl-12,
  .ant-col-xl-13,
  .ant-col-xl-14,
  .ant-col-xl-15,
  .ant-col-xl-16,
  .ant-col-xl-17,
  .ant-col-xl-18,
  .ant-col-xl-19,
  .ant-col-xl-20,
  .ant-col-xl-21,
  .ant-col-xl-22,
  .ant-col-xl-23,
  .ant-col-xl-24 {
    flex: 0 0 auto;
    float: left;
  }
  .ant-col-xl-24 {
    display: block;
    box-sizing: border-box;
    width: 100%;
  }
  .ant-col-xl-push-24 {
    left: 100%;
  }
  .ant-col-xl-pull-24 {
    right: 100%;
  }
  .ant-col-xl-offset-24 {
    margin-left: 100%;
  }
  .ant-col-xl-order-24 {
    order: 24;
  }
  .ant-col-xl-23 {
    display: block;
    box-sizing: border-box;
    width: 95.83333333%;
  }
  .ant-col-xl-push-23 {
    left: 95.83333333%;
  }
  .ant-col-xl-pull-23 {
    right: 95.83333333%;
  }
  .ant-col-xl-offset-23 {
    margin-left: 95.83333333%;
  }
  .ant-col-xl-order-23 {
    order: 23;
  }
  .ant-col-xl-22 {
    display: block;
    box-sizing: border-box;
    width: 91.66666667%;
  }
  .ant-col-xl-push-22 {
    left: 91.66666667%;
  }
  .ant-col-xl-pull-22 {
    right: 91.66666667%;
  }
  .ant-col-xl-offset-22 {
    margin-left: 91.66666667%;
  }
  .ant-col-xl-order-22 {
    order: 22;
  }
  .ant-col-xl-21 {
    display: block;
    box-sizing: border-box;
    width: 87.5%;
  }
  .ant-col-xl-push-21 {
    left: 87.5%;
  }
  .ant-col-xl-pull-21 {
    right: 87.5%;
  }
  .ant-col-xl-offset-21 {
    margin-left: 87.5%;
  }
  .ant-col-xl-order-21 {
    order: 21;
  }
  .ant-col-xl-20 {
    display: block;
    box-sizing: border-box;
    width: 83.33333333%;
  }
  .ant-col-xl-push-20 {
    left: 83.33333333%;
  }
  .ant-col-xl-pull-20 {
    right: 83.33333333%;
  }
  .ant-col-xl-offset-20 {
    margin-left: 83.33333333%;
  }
  .ant-col-xl-order-20 {
    order: 20;
  }
  .ant-col-xl-19 {
    display: block;
    box-sizing: border-box;
    width: 79.16666667%;
  }
  .ant-col-xl-push-19 {
    left: 79.16666667%;
  }
  .ant-col-xl-pull-19 {
    right: 79.16666667%;
  }
  .ant-col-xl-offset-19 {
    margin-left: 79.16666667%;
  }
  .ant-col-xl-order-19 {
    order: 19;
  }
  .ant-col-xl-18 {
    display: block;
    box-sizing: border-box;
    width: 75%;
  }
  .ant-col-xl-push-18 {
    left: 75%;
  }
  .ant-col-xl-pull-18 {
    right: 75%;
  }
  .ant-col-xl-offset-18 {
    margin-left: 75%;
  }
  .ant-col-xl-order-18 {
    order: 18;
  }
  .ant-col-xl-17 {
    display: block;
    box-sizing: border-box;
    width: 70.83333333%;
  }
  .ant-col-xl-push-17 {
    left: 70.83333333%;
  }
  .ant-col-xl-pull-17 {
    right: 70.83333333%;
  }
  .ant-col-xl-offset-17 {
    margin-left: 70.83333333%;
  }
  .ant-col-xl-order-17 {
    order: 17;
  }
  .ant-col-xl-16 {
    display: block;
    box-sizing: border-box;
    width: 66.66666667%;
  }
  .ant-col-xl-push-16 {
    left: 66.66666667%;
  }
  .ant-col-xl-pull-16 {
    right: 66.66666667%;
  }
  .ant-col-xl-offset-16 {
    margin-left: 66.66666667%;
  }
  .ant-col-xl-order-16 {
    order: 16;
  }
  .ant-col-xl-15 {
    display: block;
    box-sizing: border-box;
    width: 62.5%;
  }
  .ant-col-xl-push-15 {
    left: 62.5%;
  }
  .ant-col-xl-pull-15 {
    right: 62.5%;
  }
  .ant-col-xl-offset-15 {
    margin-left: 62.5%;
  }
  .ant-col-xl-order-15 {
    order: 15;
  }
  .ant-col-xl-14 {
    display: block;
    box-sizing: border-box;
    width: 58.33333333%;
  }
  .ant-col-xl-push-14 {
    left: 58.33333333%;
  }
  .ant-col-xl-pull-14 {
    right: 58.33333333%;
  }
  .ant-col-xl-offset-14 {
    margin-left: 58.33333333%;
  }
  .ant-col-xl-order-14 {
    order: 14;
  }
  .ant-col-xl-13 {
    display: block;
    box-sizing: border-box;
    width: 54.16666667%;
  }
  .ant-col-xl-push-13 {
    left: 54.16666667%;
  }
  .ant-col-xl-pull-13 {
    right: 54.16666667%;
  }
  .ant-col-xl-offset-13 {
    margin-left: 54.16666667%;
  }
  .ant-col-xl-order-13 {
    order: 13;
  }
  .ant-col-xl-12 {
    display: block;
    box-sizing: border-box;
    width: 50%;
  }
  .ant-col-xl-push-12 {
    left: 50%;
  }
  .ant-col-xl-pull-12 {
    right: 50%;
  }
  .ant-col-xl-offset-12 {
    margin-left: 50%;
  }
  .ant-col-xl-order-12 {
    order: 12;
  }
  .ant-col-xl-11 {
    display: block;
    box-sizing: border-box;
    width: 45.83333333%;
  }
  .ant-col-xl-push-11 {
    left: 45.83333333%;
  }
  .ant-col-xl-pull-11 {
    right: 45.83333333%;
  }
  .ant-col-xl-offset-11 {
    margin-left: 45.83333333%;
  }
  .ant-col-xl-order-11 {
    order: 11;
  }
  .ant-col-xl-10 {
    display: block;
    box-sizing: border-box;
    width: 41.66666667%;
  }
  .ant-col-xl-push-10 {
    left: 41.66666667%;
  }
  .ant-col-xl-pull-10 {
    right: 41.66666667%;
  }
  .ant-col-xl-offset-10 {
    margin-left: 41.66666667%;
  }
  .ant-col-xl-order-10 {
    order: 10;
  }
  .ant-col-xl-9 {
    display: block;
    box-sizing: border-box;
    width: 37.5%;
  }
  .ant-col-xl-push-9 {
    left: 37.5%;
  }
  .ant-col-xl-pull-9 {
    right: 37.5%;
  }
  .ant-col-xl-offset-9 {
    margin-left: 37.5%;
  }
  .ant-col-xl-order-9 {
    order: 9;
  }
  .ant-col-xl-8 {
    display: block;
    box-sizing: border-box;
    width: 33.33333333%;
  }
  .ant-col-xl-push-8 {
    left: 33.33333333%;
  }
  .ant-col-xl-pull-8 {
    right: 33.33333333%;
  }
  .ant-col-xl-offset-8 {
    margin-left: 33.33333333%;
  }
  .ant-col-xl-order-8 {
    order: 8;
  }
  .ant-col-xl-7 {
    display: block;
    box-sizing: border-box;
    width: 29.16666667%;
  }
  .ant-col-xl-push-7 {
    left: 29.16666667%;
  }
  .ant-col-xl-pull-7 {
    right: 29.16666667%;
  }
  .ant-col-xl-offset-7 {
    margin-left: 29.16666667%;
  }
  .ant-col-xl-order-7 {
    order: 7;
  }
  .ant-col-xl-6 {
    display: block;
    box-sizing: border-box;
    width: 25%;
  }
  .ant-col-xl-push-6 {
    left: 25%;
  }
  .ant-col-xl-pull-6 {
    right: 25%;
  }
  .ant-col-xl-offset-6 {
    margin-left: 25%;
  }
  .ant-col-xl-order-6 {
    order: 6;
  }
  .ant-col-xl-5 {
    display: block;
    box-sizing: border-box;
    width: 20.83333333%;
  }
  .ant-col-xl-push-5 {
    left: 20.83333333%;
  }
  .ant-col-xl-pull-5 {
    right: 20.83333333%;
  }
  .ant-col-xl-offset-5 {
    margin-left: 20.83333333%;
  }
  .ant-col-xl-order-5 {
    order: 5;
  }
  .ant-col-xl-4 {
    display: block;
    box-sizing: border-box;
    width: 16.66666667%;
  }
  .ant-col-xl-push-4 {
    left: 16.66666667%;
  }
  .ant-col-xl-pull-4 {
    right: 16.66666667%;
  }
  .ant-col-xl-offset-4 {
    margin-left: 16.66666667%;
  }
  .ant-col-xl-order-4 {
    order: 4;
  }
  .ant-col-xl-3 {
    display: block;
    box-sizing: border-box;
    width: 12.5%;
  }
  .ant-col-xl-push-3 {
    left: 12.5%;
  }
  .ant-col-xl-pull-3 {
    right: 12.5%;
  }
  .ant-col-xl-offset-3 {
    margin-left: 12.5%;
  }
  .ant-col-xl-order-3 {
    order: 3;
  }
  .ant-col-xl-2 {
    display: block;
    box-sizing: border-box;
    width: 8.33333333%;
  }
  .ant-col-xl-push-2 {
    left: 8.33333333%;
  }
  .ant-col-xl-pull-2 {
    right: 8.33333333%;
  }
  .ant-col-xl-offset-2 {
    margin-left: 8.33333333%;
  }
  .ant-col-xl-order-2 {
    order: 2;
  }
  .ant-col-xl-1 {
    display: block;
    box-sizing: border-box;
    width: 4.16666667%;
  }
  .ant-col-xl-push-1 {
    left: 4.16666667%;
  }
  .ant-col-xl-pull-1 {
    right: 4.16666667%;
  }
  .ant-col-xl-offset-1 {
    margin-left: 4.16666667%;
  }
  .ant-col-xl-order-1 {
    order: 1;
  }
  .ant-col-xl-0 {
    display: none;
  }
  .ant-col-push-0 {
    left: auto;
  }
  .ant-col-pull-0 {
    right: auto;
  }
  .ant-col-xl-push-0 {
    left: auto;
  }
  .ant-col-xl-pull-0 {
    right: auto;
  }
  .ant-col-xl-offset-0 {
    margin-left: 0;
  }
  .ant-col-xl-order-0 {
    order: 0;
  }
}
@media (min-width: 1600px) {
  .ant-col-xxl-1,
  .ant-col-xxl-2,
  .ant-col-xxl-3,
  .ant-col-xxl-4,
  .ant-col-xxl-5,
  .ant-col-xxl-6,
  .ant-col-xxl-7,
  .ant-col-xxl-8,
  .ant-col-xxl-9,
  .ant-col-xxl-10,
  .ant-col-xxl-11,
  .ant-col-xxl-12,
  .ant-col-xxl-13,
  .ant-col-xxl-14,
  .ant-col-xxl-15,
  .ant-col-xxl-16,
  .ant-col-xxl-17,
  .ant-col-xxl-18,
  .ant-col-xxl-19,
  .ant-col-xxl-20,
  .ant-col-xxl-21,
  .ant-col-xxl-22,
  .ant-col-xxl-23,
  .ant-col-xxl-24 {
    flex: 0 0 auto;
    float: left;
  }
  .ant-col-xxl-24 {
    display: block;
    box-sizing: border-box;
    width: 100%;
  }
  .ant-col-xxl-push-24 {
    left: 100%;
  }
  .ant-col-xxl-pull-24 {
    right: 100%;
  }
  .ant-col-xxl-offset-24 {
    margin-left: 100%;
  }
  .ant-col-xxl-order-24 {
    order: 24;
  }
  .ant-col-xxl-23 {
    display: block;
    box-sizing: border-box;
    width: 95.83333333%;
  }
  .ant-col-xxl-push-23 {
    left: 95.83333333%;
  }
  .ant-col-xxl-pull-23 {
    right: 95.83333333%;
  }
  .ant-col-xxl-offset-23 {
    margin-left: 95.83333333%;
  }
  .ant-col-xxl-order-23 {
    order: 23;
  }
  .ant-col-xxl-22 {
    display: block;
    box-sizing: border-box;
    width: 91.66666667%;
  }
  .ant-col-xxl-push-22 {
    left: 91.66666667%;
  }
  .ant-col-xxl-pull-22 {
    right: 91.66666667%;
  }
  .ant-col-xxl-offset-22 {
    margin-left: 91.66666667%;
  }
  .ant-col-xxl-order-22 {
    order: 22;
  }
  .ant-col-xxl-21 {
    display: block;
    box-sizing: border-box;
    width: 87.5%;
  }
  .ant-col-xxl-push-21 {
    left: 87.5%;
  }
  .ant-col-xxl-pull-21 {
    right: 87.5%;
  }
  .ant-col-xxl-offset-21 {
    margin-left: 87.5%;
  }
  .ant-col-xxl-order-21 {
    order: 21;
  }
  .ant-col-xxl-20 {
    display: block;
    box-sizing: border-box;
    width: 83.33333333%;
  }
  .ant-col-xxl-push-20 {
    left: 83.33333333%;
  }
  .ant-col-xxl-pull-20 {
    right: 83.33333333%;
  }
  .ant-col-xxl-offset-20 {
    margin-left: 83.33333333%;
  }
  .ant-col-xxl-order-20 {
    order: 20;
  }
  .ant-col-xxl-19 {
    display: block;
    box-sizing: border-box;
    width: 79.16666667%;
  }
  .ant-col-xxl-push-19 {
    left: 79.16666667%;
  }
  .ant-col-xxl-pull-19 {
    right: 79.16666667%;
  }
  .ant-col-xxl-offset-19 {
    margin-left: 79.16666667%;
  }
  .ant-col-xxl-order-19 {
    order: 19;
  }
  .ant-col-xxl-18 {
    display: block;
    box-sizing: border-box;
    width: 75%;
  }
  .ant-col-xxl-push-18 {
    left: 75%;
  }
  .ant-col-xxl-pull-18 {
    right: 75%;
  }
  .ant-col-xxl-offset-18 {
    margin-left: 75%;
  }
  .ant-col-xxl-order-18 {
    order: 18;
  }
  .ant-col-xxl-17 {
    display: block;
    box-sizing: border-box;
    width: 70.83333333%;
  }
  .ant-col-xxl-push-17 {
    left: 70.83333333%;
  }
  .ant-col-xxl-pull-17 {
    right: 70.83333333%;
  }
  .ant-col-xxl-offset-17 {
    margin-left: 70.83333333%;
  }
  .ant-col-xxl-order-17 {
    order: 17;
  }
  .ant-col-xxl-16 {
    display: block;
    box-sizing: border-box;
    width: 66.66666667%;
  }
  .ant-col-xxl-push-16 {
    left: 66.66666667%;
  }
  .ant-col-xxl-pull-16 {
    right: 66.66666667%;
  }
  .ant-col-xxl-offset-16 {
    margin-left: 66.66666667%;
  }
  .ant-col-xxl-order-16 {
    order: 16;
  }
  .ant-col-xxl-15 {
    display: block;
    box-sizing: border-box;
    width: 62.5%;
  }
  .ant-col-xxl-push-15 {
    left: 62.5%;
  }
  .ant-col-xxl-pull-15 {
    right: 62.5%;
  }
  .ant-col-xxl-offset-15 {
    margin-left: 62.5%;
  }
  .ant-col-xxl-order-15 {
    order: 15;
  }
  .ant-col-xxl-14 {
    display: block;
    box-sizing: border-box;
    width: 58.33333333%;
  }
  .ant-col-xxl-push-14 {
    left: 58.33333333%;
  }
  .ant-col-xxl-pull-14 {
    right: 58.33333333%;
  }
  .ant-col-xxl-offset-14 {
    margin-left: 58.33333333%;
  }
  .ant-col-xxl-order-14 {
    order: 14;
  }
  .ant-col-xxl-13 {
    display: block;
    box-sizing: border-box;
    width: 54.16666667%;
  }
  .ant-col-xxl-push-13 {
    left: 54.16666667%;
  }
  .ant-col-xxl-pull-13 {
    right: 54.16666667%;
  }
  .ant-col-xxl-offset-13 {
    margin-left: 54.16666667%;
  }
  .ant-col-xxl-order-13 {
    order: 13;
  }
  .ant-col-xxl-12 {
    display: block;
    box-sizing: border-box;
    width: 50%;
  }
  .ant-col-xxl-push-12 {
    left: 50%;
  }
  .ant-col-xxl-pull-12 {
    right: 50%;
  }
  .ant-col-xxl-offset-12 {
    margin-left: 50%;
  }
  .ant-col-xxl-order-12 {
    order: 12;
  }
  .ant-col-xxl-11 {
    display: block;
    box-sizing: border-box;
    width: 45.83333333%;
  }
  .ant-col-xxl-push-11 {
    left: 45.83333333%;
  }
  .ant-col-xxl-pull-11 {
    right: 45.83333333%;
  }
  .ant-col-xxl-offset-11 {
    margin-left: 45.83333333%;
  }
  .ant-col-xxl-order-11 {
    order: 11;
  }
  .ant-col-xxl-10 {
    display: block;
    box-sizing: border-box;
    width: 41.66666667%;
  }
  .ant-col-xxl-push-10 {
    left: 41.66666667%;
  }
  .ant-col-xxl-pull-10 {
    right: 41.66666667%;
  }
  .ant-col-xxl-offset-10 {
    margin-left: 41.66666667%;
  }
  .ant-col-xxl-order-10 {
    order: 10;
  }
  .ant-col-xxl-9 {
    display: block;
    box-sizing: border-box;
    width: 37.5%;
  }
  .ant-col-xxl-push-9 {
    left: 37.5%;
  }
  .ant-col-xxl-pull-9 {
    right: 37.5%;
  }
  .ant-col-xxl-offset-9 {
    margin-left: 37.5%;
  }
  .ant-col-xxl-order-9 {
    order: 9;
  }
  .ant-col-xxl-8 {
    display: block;
    box-sizing: border-box;
    width: 33.33333333%;
  }
  .ant-col-xxl-push-8 {
    left: 33.33333333%;
  }
  .ant-col-xxl-pull-8 {
    right: 33.33333333%;
  }
  .ant-col-xxl-offset-8 {
    margin-left: 33.33333333%;
  }
  .ant-col-xxl-order-8 {
    order: 8;
  }
  .ant-col-xxl-7 {
    display: block;
    box-sizing: border-box;
    width: 29.16666667%;
  }
  .ant-col-xxl-push-7 {
    left: 29.16666667%;
  }
  .ant-col-xxl-pull-7 {
    right: 29.16666667%;
  }
  .ant-col-xxl-offset-7 {
    margin-left: 29.16666667%;
  }
  .ant-col-xxl-order-7 {
    order: 7;
  }
  .ant-col-xxl-6 {
    display: block;
    box-sizing: border-box;
    width: 25%;
  }
  .ant-col-xxl-push-6 {
    left: 25%;
  }
  .ant-col-xxl-pull-6 {
    right: 25%;
  }
  .ant-col-xxl-offset-6 {
    margin-left: 25%;
  }
  .ant-col-xxl-order-6 {
    order: 6;
  }
  .ant-col-xxl-5 {
    display: block;
    box-sizing: border-box;
    width: 20.83333333%;
  }
  .ant-col-xxl-push-5 {
    left: 20.83333333%;
  }
  .ant-col-xxl-pull-5 {
    right: 20.83333333%;
  }
  .ant-col-xxl-offset-5 {
    margin-left: 20.83333333%;
  }
  .ant-col-xxl-order-5 {
    order: 5;
  }
  .ant-col-xxl-4 {
    display: block;
    box-sizing: border-box;
    width: 16.66666667%;
  }
  .ant-col-xxl-push-4 {
    left: 16.66666667%;
  }
  .ant-col-xxl-pull-4 {
    right: 16.66666667%;
  }
  .ant-col-xxl-offset-4 {
    margin-left: 16.66666667%;
  }
  .ant-col-xxl-order-4 {
    order: 4;
  }
  .ant-col-xxl-3 {
    display: block;
    box-sizing: border-box;
    width: 12.5%;
  }
  .ant-col-xxl-push-3 {
    left: 12.5%;
  }
  .ant-col-xxl-pull-3 {
    right: 12.5%;
  }
  .ant-col-xxl-offset-3 {
    margin-left: 12.5%;
  }
  .ant-col-xxl-order-3 {
    order: 3;
  }
  .ant-col-xxl-2 {
    display: block;
    box-sizing: border-box;
    width: 8.33333333%;
  }
  .ant-col-xxl-push-2 {
    left: 8.33333333%;
  }
  .ant-col-xxl-pull-2 {
    right: 8.33333333%;
  }
  .ant-col-xxl-offset-2 {
    margin-left: 8.33333333%;
  }
  .ant-col-xxl-order-2 {
    order: 2;
  }
  .ant-col-xxl-1 {
    display: block;
    box-sizing: border-box;
    width: 4.16666667%;
  }
  .ant-col-xxl-push-1 {
    left: 4.16666667%;
  }
  .ant-col-xxl-pull-1 {
    right: 4.16666667%;
  }
  .ant-col-xxl-offset-1 {
    margin-left: 4.16666667%;
  }
  .ant-col-xxl-order-1 {
    order: 1;
  }
  .ant-col-xxl-0 {
    display: none;
  }
  .ant-col-push-0 {
    left: auto;
  }
  .ant-col-pull-0 {
    right: auto;
  }
  .ant-col-xxl-push-0 {
    left: auto;
  }
  .ant-col-xxl-pull-0 {
    right: auto;
  }
  .ant-col-xxl-offset-0 {
    margin-left: 0;
  }
  .ant-col-xxl-order-0 {
    order: 0;
  }
}
.ant-input {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-variant: tabular-nums;
  list-style: none;
  font-feature-settings: "tnum";
  position: relative;
  display: inline-block;
  width: 100%;
  height: 32px;
  padding: 4px 11px;
  color: rgba(34, 34, 34, 0.8);
  font-size: 14px;
  line-height: 1.5715;
  background-color: #fff;
  background-image: none;
  border: 1px solid #bfbfbf;
  border-radius: 10px;
  transition: all 0.3s;
}
.ant-input::-moz-placeholder {
  color: #bfbfbf;
  opacity: 1;
}
.ant-input:-ms-input-placeholder {
  color: #bfbfbf;
}
.ant-input::-webkit-input-placeholder {
  color: #bfbfbf;
}
.ant-input:-moz-placeholder-shown {
  text-overflow: ellipsis;
}
.ant-input:-ms-input-placeholder {
  text-overflow: ellipsis;
}
.ant-input:placeholder-shown {
  text-overflow: ellipsis;
}
.ant-input:hover {
  border-color: #333;
  border-right-width: 1px !important;
}
.ant-input:focus {
  border-color: #666060;
  border-right-width: 1px !important;
  outline: 0;
  box-shadow: 0 0 0 1px rgba(89, 89, 89, 0.2);
}
.ant-input-disabled {
  color: #fff;
  background-color: #cccccc;
  cursor: not-allowed;
  opacity: 1;
}
.ant-input-disabled:hover {
  border-color: #bfbfbf;
  border-right-width: 1px !important;
}
.ant-input[disabled] {
  color: #fff;
  background-color: #cccccc;
  cursor: not-allowed;
  opacity: 1;
}
.ant-input[disabled]:hover {
  border-color: #bfbfbf;
  border-right-width: 1px !important;
}
textarea.ant-input {
  max-width: 100%;
  height: auto;
  min-height: 32px;
  line-height: 1.5715;
  vertical-align: bottom;
  transition: all 0.3s, height 0s;
}
.ant-input-lg {
  height: 45px;
  padding: 10.5px 11px;
  font-size: 14px;
}
.ant-input-sm {
  height: 24px;
  padding: 0px 7px;
}
.ant-input-group {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(34, 34, 34, 0.8);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: "tnum";
  position: relative;
  display: table;
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
}
.ant-input-group[class*='col-'] {
  float: none;
  padding-right: 0;
  padding-left: 0;
}
.ant-input-group > [class*='col-'] {
  padding-right: 8px;
}
.ant-input-group > [class*='col-']:last-child {
  padding-right: 0;
}
.ant-input-group-addon,
.ant-input-group-wrap,
.ant-input-group > .ant-input {
  display: table-cell;
}
.ant-input-group-addon:not(:first-child):not(:last-child),
.ant-input-group-wrap:not(:first-child):not(:last-child),
.ant-input-group > .ant-input:not(:first-child):not(:last-child) {
  border-radius: 0;
}
.ant-input-group-addon,
.ant-input-group-wrap {
  width: 1px;
  white-space: nowrap;
  vertical-align: middle;
}
.ant-input-group-wrap > * {
  display: block !important;
}
.ant-input-group .ant-input {
  float: left;
  width: 100%;
  margin-bottom: 0;
  text-align: inherit;
}
.ant-input-group .ant-input:focus {
  z-index: 1;
  border-right-width: 1px;
}
.ant-input-group .ant-input:hover {
  z-index: 1;
  border-right-width: 1px;
}
.ant-input-group-addon {
  position: relative;
  padding: 0 11px;
  color: rgba(34, 34, 34, 0.8);
  font-weight: normal;
  font-size: 14px;
  text-align: center;
  background-color: #fafafa;
  border: 1px solid #bfbfbf;
  border-radius: 10px;
  transition: all 0.3s;
}
.ant-input-group-addon .ant-select {
  margin: -5px -11px;
}
.ant-input-group-addon .ant-select .ant-select-selection {
  margin: -1px;
  background-color: inherit;
  border: 1px solid transparent;
  box-shadow: none;
}
.ant-input-group-addon .ant-select-open .ant-select-selection,
.ant-input-group-addon .ant-select-focused .ant-select-selection {
  color: #00a6ff;
}
.ant-input-group-addon > i:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  content: '';
}
.ant-input-group > .ant-input:first-child,
.ant-input-group-addon:first-child {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.ant-input-group > .ant-input:first-child .ant-select .ant-select-selection,
.ant-input-group-addon:first-child .ant-select .ant-select-selection {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.ant-input-group > .ant-input-affix-wrapper:not(:first-child) .ant-input {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.ant-input-group > .ant-input-affix-wrapper:not(:last-child) .ant-input {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.ant-input-group-addon:first-child {
  border-right: 0;
}
.ant-input-group-addon:last-child {
  border-left: 0;
}
.ant-input-group > .ant-input:last-child,
.ant-input-group-addon:last-child {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.ant-input-group > .ant-input:last-child .ant-select .ant-select-selection,
.ant-input-group-addon:last-child .ant-select .ant-select-selection {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.ant-input-group-lg .ant-input,
.ant-input-group-lg > .ant-input-group-addon {
  height: 45px;
  padding: 10.5px 11px;
  font-size: 14px;
}
.ant-input-group-sm .ant-input,
.ant-input-group-sm > .ant-input-group-addon {
  height: 24px;
  padding: 0px 7px;
}
.ant-input-group-lg .ant-select-selection--single {
  height: 45px;
}
.ant-input-group-sm .ant-select-selection--single {
  height: 24px;
}
.ant-input-group .ant-input-affix-wrapper {
  display: table-cell;
  float: left;
  width: 100%;
}
.ant-input-group.ant-input-group-compact {
  display: block;
  zoom: 1;
}
.ant-input-group.ant-input-group-compact::before,
.ant-input-group.ant-input-group-compact::after {
  display: table;
  content: '';
}
.ant-input-group.ant-input-group-compact::after {
  clear: both;
}
.ant-input-group.ant-input-group-compact::before,
.ant-input-group.ant-input-group-compact::after {
  display: table;
  content: '';
}
.ant-input-group.ant-input-group-compact::after {
  clear: both;
}
.ant-input-group.ant-input-group-compact-addon:not(:first-child):not(:last-child),
.ant-input-group.ant-input-group-compact-wrap:not(:first-child):not(:last-child),
.ant-input-group.ant-input-group-compact > .ant-input:not(:first-child):not(:last-child) {
  border-right-width: 1px;
}
.ant-input-group.ant-input-group-compact-addon:not(:first-child):not(:last-child):hover,
.ant-input-group.ant-input-group-compact-wrap:not(:first-child):not(:last-child):hover,
.ant-input-group.ant-input-group-compact > .ant-input:not(:first-child):not(:last-child):hover {
  z-index: 1;
}
.ant-input-group.ant-input-group-compact-addon:not(:first-child):not(:last-child):focus,
.ant-input-group.ant-input-group-compact-wrap:not(:first-child):not(:last-child):focus,
.ant-input-group.ant-input-group-compact > .ant-input:not(:first-child):not(:last-child):focus {
  z-index: 1;
}
.ant-input-group.ant-input-group-compact > * {
  display: inline-block;
  float: none;
  vertical-align: top;
  border-radius: 0;
}
.ant-input-group.ant-input-group-compact > *:not(:last-child) {
  margin-right: -1px;
  border-right-width: 1px;
}
.ant-input-group.ant-input-group-compact .ant-input {
  float: none;
}
.ant-input-group.ant-input-group-compact > .ant-select > .ant-select-selection,
.ant-input-group.ant-input-group-compact > .ant-calendar-picker .ant-input,
.ant-input-group.ant-input-group-compact > .ant-select-auto-complete .ant-input,
.ant-input-group.ant-input-group-compact > .ant-cascader-picker .ant-input,
.ant-input-group.ant-input-group-compact > .ant-mention-wrapper .ant-mention-editor,
.ant-input-group.ant-input-group-compact > .ant-time-picker .ant-time-picker-input,
.ant-input-group.ant-input-group-compact > .ant-input-group-wrapper .ant-input {
  border-right-width: 1px;
  border-radius: 0;
}
.ant-input-group.ant-input-group-compact > .ant-select > .ant-select-selection:hover,
.ant-input-group.ant-input-group-compact > .ant-calendar-picker .ant-input:hover,
.ant-input-group.ant-input-group-compact > .ant-select-auto-complete .ant-input:hover,
.ant-input-group.ant-input-group-compact > .ant-cascader-picker .ant-input:hover,
.ant-input-group.ant-input-group-compact > .ant-mention-wrapper .ant-mention-editor:hover,
.ant-input-group.ant-input-group-compact > .ant-time-picker .ant-time-picker-input:hover,
.ant-input-group.ant-input-group-compact > .ant-input-group-wrapper .ant-input:hover {
  z-index: 1;
}
.ant-input-group.ant-input-group-compact > .ant-select > .ant-select-selection:focus,
.ant-input-group.ant-input-group-compact > .ant-calendar-picker .ant-input:focus,
.ant-input-group.ant-input-group-compact > .ant-select-auto-complete .ant-input:focus,
.ant-input-group.ant-input-group-compact > .ant-cascader-picker .ant-input:focus,
.ant-input-group.ant-input-group-compact > .ant-mention-wrapper .ant-mention-editor:focus,
.ant-input-group.ant-input-group-compact > .ant-time-picker .ant-time-picker-input:focus,
.ant-input-group.ant-input-group-compact > .ant-input-group-wrapper .ant-input:focus {
  z-index: 1;
}
.ant-input-group.ant-input-group-compact > *:first-child,
.ant-input-group.ant-input-group-compact > .ant-select:first-child > .ant-select-selection,
.ant-input-group.ant-input-group-compact > .ant-calendar-picker:first-child .ant-input,
.ant-input-group.ant-input-group-compact > .ant-select-auto-complete:first-child .ant-input,
.ant-input-group.ant-input-group-compact > .ant-cascader-picker:first-child .ant-input,
.ant-input-group.ant-input-group-compact > .ant-mention-wrapper:first-child .ant-mention-editor,
.ant-input-group.ant-input-group-compact > .ant-time-picker:first-child .ant-time-picker-input {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}
.ant-input-group.ant-input-group-compact > *:last-child,
.ant-input-group.ant-input-group-compact > .ant-select:last-child > .ant-select-selection,
.ant-input-group.ant-input-group-compact > .ant-calendar-picker:last-child .ant-input,
.ant-input-group.ant-input-group-compact > .ant-select-auto-complete:last-child .ant-input,
.ant-input-group.ant-input-group-compact > .ant-cascader-picker:last-child .ant-input,
.ant-input-group.ant-input-group-compact > .ant-cascader-picker-focused:last-child .ant-input,
.ant-input-group.ant-input-group-compact > .ant-mention-wrapper:last-child .ant-mention-editor,
.ant-input-group.ant-input-group-compact > .ant-time-picker:last-child .ant-time-picker-input {
  border-right-width: 1px;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}
.ant-input-group.ant-input-group-compact > .ant-select-auto-complete .ant-input {
  vertical-align: top;
}
.ant-input-group-wrapper {
  display: inline-block;
  width: 100%;
  text-align: start;
  vertical-align: top;
}
.ant-input-affix-wrapper {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(34, 34, 34, 0.8);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: "tnum";
  position: relative;
  display: inline-block;
  width: 100%;
  text-align: start;
}
.ant-input-affix-wrapper:hover .ant-input:not(.ant-input-disabled) {
  border-color: #333;
  border-right-width: 1px !important;
}
.ant-input-affix-wrapper .ant-input {
  position: relative;
  text-align: inherit;
}
.ant-input-affix-wrapper .ant-input-prefix,
.ant-input-affix-wrapper .ant-input-suffix {
  position: absolute;
  top: 50%;
  z-index: 2;
  display: flex;
  align-items: center;
  color: rgba(34, 34, 34, 0.8);
  line-height: 0;
  transform: translateY(-50%);
}
.ant-input-affix-wrapper .ant-input-prefix :not(.anticon),
.ant-input-affix-wrapper .ant-input-suffix :not(.anticon) {
  line-height: 1.5715;
}
.ant-input-affix-wrapper .ant-input-disabled ~ .ant-input-suffix .anticon {
  color: #fff;
  cursor: not-allowed;
}
.ant-input-affix-wrapper .ant-input-prefix {
  left: 12px;
}
.ant-input-affix-wrapper .ant-input-suffix {
  right: 12px;
}
.ant-input-affix-wrapper .ant-input:not(:first-child) {
  padding-left: 30px;
}
.ant-input-affix-wrapper .ant-input:not(:last-child) {
  padding-right: 30px;
}
.ant-input-affix-wrapper.ant-input-affix-wrapper-input-with-clear-btn .ant-input:not(:last-child) {
  padding-right: 49px;
}
.ant-input-affix-wrapper.ant-input-affix-wrapper-textarea-with-clear-btn .ant-input {
  padding-right: 22px;
}
.ant-input-affix-wrapper .ant-input {
  min-height: 100%;
}
.ant-input-password-icon {
  color: rgba(34, 34, 34, 0.45);
  cursor: pointer;
  transition: all 0.3s;
}
.ant-input-password-icon:hover {
  color: #333;
}
.ant-input-clear-icon {
  color: #fff;
  font-size: 12px;
  cursor: pointer;
  transition: color 0.3s;
  vertical-align: 0;
}
.ant-input-clear-icon:hover {
  color: rgba(34, 34, 34, 0.45);
}
.ant-input-clear-icon:active {
  color: rgba(34, 34, 34, 0.8);
}
.ant-input-clear-icon + i {
  margin-left: 6px;
}
.ant-input-textarea-clear-icon {
  color: #fff;
  font-size: 12px;
  cursor: pointer;
  transition: color 0.3s;
  position: absolute;
  top: 0;
  right: 0;
  margin: 8px 8px 0 0;
}
.ant-input-textarea-clear-icon:hover {
  color: rgba(34, 34, 34, 0.45);
}
.ant-input-textarea-clear-icon:active {
  color: rgba(34, 34, 34, 0.8);
}
.ant-input-textarea-clear-icon + i {
  margin-left: 6px;
}
.ant-input-search-icon {
  color: rgba(34, 34, 34, 0.45);
  cursor: pointer;
  transition: all 0.3s;
}
.ant-input-search-icon:hover {
  color: rgba(34, 34, 34, 0.8);
}
.ant-input-search-enter-button input {
  border-right: 0;
}
.ant-input-search-enter-button + .ant-input-group-addon,
.ant-input-search-enter-button input + .ant-input-group-addon {
  padding: 0;
  border: 0;
}
.ant-input-search-enter-button + .ant-input-group-addon .ant-input-search-button,
.ant-input-search-enter-button input + .ant-input-group-addon .ant-input-search-button {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.ant-input-number {
  box-sizing: border-box;
  font-variant: tabular-nums;
  list-style: none;
  font-feature-settings: "tnum";
  position: relative;
  width: 100%;
  height: 32px;
  padding: 4px 11px;
  color: rgba(34, 34, 34, 0.8);
  font-size: 14px;
  line-height: 1.5715;
  background-color: #fff;
  background-image: none;
  transition: all 0.3s;
  display: inline-block;
  width: 90px;
  margin: 0;
  padding: 0;
  border: 1px solid #bfbfbf;
  border-radius: 10px;
}
.ant-input-number::-moz-placeholder {
  color: #bfbfbf;
  opacity: 1;
}
.ant-input-number:-ms-input-placeholder {
  color: #bfbfbf;
}
.ant-input-number::-webkit-input-placeholder {
  color: #bfbfbf;
}
.ant-input-number:-moz-placeholder-shown {
  text-overflow: ellipsis;
}
.ant-input-number:-ms-input-placeholder {
  text-overflow: ellipsis;
}
.ant-input-number:placeholder-shown {
  text-overflow: ellipsis;
}
.ant-input-number:hover {
  border-color: #333;
  border-right-width: 1px !important;
}
.ant-input-number:focus {
  border-color: #666060;
  border-right-width: 1px !important;
  outline: 0;
  box-shadow: 0 0 0 1px rgba(89, 89, 89, 0.2);
}
.ant-input-number-disabled {
  color: #fff;
  background-color: #cccccc;
  cursor: not-allowed;
  opacity: 1;
}
.ant-input-number-disabled:hover {
  border-color: #bfbfbf;
  border-right-width: 1px !important;
}
.ant-input-number[disabled] {
  color: #fff;
  background-color: #cccccc;
  cursor: not-allowed;
  opacity: 1;
}
.ant-input-number[disabled]:hover {
  border-color: #bfbfbf;
  border-right-width: 1px !important;
}
textarea.ant-input-number {
  max-width: 100%;
  height: auto;
  min-height: 32px;
  line-height: 1.5715;
  vertical-align: bottom;
  transition: all 0.3s, height 0s;
}
.ant-input-number-lg {
  height: 45px;
  padding: 10.5px 11px;
  font-size: 14px;
}
.ant-input-number-sm {
  height: 24px;
  padding: 0px 7px;
}
.ant-input-number-handler {
  position: relative;
  display: block;
  width: 100%;
  height: 50%;
  overflow: hidden;
  color: rgba(34, 34, 34, 0.45);
  font-weight: bold;
  line-height: 0;
  text-align: center;
  transition: all 0.1s linear;
}
.ant-input-number-handler:active {
  background: #f4f4f4;
}
.ant-input-number-handler:hover .ant-input-number-handler-up-inner,
.ant-input-number-handler:hover .ant-input-number-handler-down-inner {
  color: #333;
}
.ant-input-number-handler-up-inner,
.ant-input-number-handler-down-inner {
  display: inline-block;
  color: inherit;
  font-style: normal;
  line-height: 0;
  text-align: center;
  text-transform: none;
  vertical-align: -0.125em;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: absolute;
  right: 4px;
  width: 12px;
  height: 12px;
  color: rgba(34, 34, 34, 0.45);
  line-height: 12px;
  transition: all 0.1s linear;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.ant-input-number-handler-up-inner > *,
.ant-input-number-handler-down-inner > * {
  line-height: 1;
}
.ant-input-number-handler-up-inner svg,
.ant-input-number-handler-down-inner svg {
  display: inline-block;
}
.ant-input-number-handler-up-inner::before,
.ant-input-number-handler-down-inner::before {
  display: none;
}
.ant-input-number-handler-up-inner .ant-input-number-handler-up-inner-icon,
.ant-input-number-handler-up-inner .ant-input-number-handler-down-inner-icon,
.ant-input-number-handler-down-inner .ant-input-number-handler-up-inner-icon,
.ant-input-number-handler-down-inner .ant-input-number-handler-down-inner-icon {
  display: block;
}
.ant-input-number:hover {
  border-color: #333;
  border-right-width: 1px !important;
}
.ant-input-number-focused {
  border-color: #666060;
  border-right-width: 1px !important;
  outline: 0;
  box-shadow: 0 0 0 1px rgba(89, 89, 89, 0.2);
}
.ant-input-number-disabled {
  color: #fff;
  background-color: #cccccc;
  cursor: not-allowed;
  opacity: 1;
}
.ant-input-number-disabled:hover {
  border-color: #bfbfbf;
  border-right-width: 1px !important;
}
.ant-input-number-disabled .ant-input-number-input {
  cursor: not-allowed;
}
.ant-input-number-disabled .ant-input-number-handler-wrap {
  display: none;
}
.ant-input-number-input {
  width: 100%;
  height: 30px;
  padding: 0 11px;
  text-align: left;
  background-color: transparent;
  border: 0;
  border-radius: 10px;
  outline: 0;
  transition: all 0.3s linear;
  -moz-appearance: textfield !important;
}
.ant-input-number-input::-moz-placeholder {
  color: #bfbfbf;
  opacity: 1;
}
.ant-input-number-input:-ms-input-placeholder {
  color: #bfbfbf;
}
.ant-input-number-input::-webkit-input-placeholder {
  color: #bfbfbf;
}
.ant-input-number-input:-moz-placeholder-shown {
  text-overflow: ellipsis;
}
.ant-input-number-input:-ms-input-placeholder {
  text-overflow: ellipsis;
}
.ant-input-number-input:placeholder-shown {
  text-overflow: ellipsis;
}
.ant-input-number-input[type='number']::-webkit-inner-spin-button,
.ant-input-number-input[type='number']::-webkit-outer-spin-button {
  margin: 0;
  -webkit-appearance: none;
}
.ant-input-number-lg {
  padding: 0;
  font-size: 14px;
}
.ant-input-number-lg input {
  height: 43px;
}
.ant-input-number-sm {
  padding: 0;
}
.ant-input-number-sm input {
  height: 22px;
  padding: 0 7px;
}
.ant-input-number-handler-wrap {
  position: absolute;
  top: 0;
  right: 0;
  width: 22px;
  height: 100%;
  background: #fff;
  border-left: 1px solid #bfbfbf;
  border-radius: 0 10px 10px 0;
  opacity: 0;
  transition: opacity 0.24s linear 0.1s;
}
.ant-input-number-handler-wrap .ant-input-number-handler .ant-input-number-handler-up-inner,
.ant-input-number-handler-wrap .ant-input-number-handler .ant-input-number-handler-down-inner {
  display: inline-block;
  font-size: 12px;
  font-size: 7px \9;
  transform: scale(0.58333333) rotate(0deg);
  min-width: auto;
  margin-right: 0;
}
:root .ant-input-number-handler-wrap .ant-input-number-handler .ant-input-number-handler-up-inner,
:root .ant-input-number-handler-wrap .ant-input-number-handler .ant-input-number-handler-down-inner {
  font-size: 12px;
}
.ant-input-number-handler-wrap:hover .ant-input-number-handler {
  height: 40%;
}
.ant-input-number:hover .ant-input-number-handler-wrap {
  opacity: 1;
}
.ant-input-number-handler-up {
  border-top-right-radius: 10px;
  cursor: pointer;
}
.ant-input-number-handler-up-inner {
  top: 50%;
  margin-top: -5px;
  text-align: center;
}
.ant-input-number-handler-up:hover {
  height: 60% !important;
}
.ant-input-number-handler-down {
  top: 0;
  border-top: 1px solid #bfbfbf;
  border-bottom-right-radius: 10px;
  cursor: pointer;
}
.ant-input-number-handler-down-inner {
  top: 50%;
  margin-top: -6px;
  text-align: center;
}
.ant-input-number-handler-down:hover {
  height: 60% !important;
}
.ant-input-number-handler-up-disabled,
.ant-input-number-handler-down-disabled {
  cursor: not-allowed;
}
.ant-input-number-handler-up-disabled:hover .ant-input-number-handler-up-inner,
.ant-input-number-handler-down-disabled:hover .ant-input-number-handler-down-inner {
  color: #fff;
}
.ant-layout {
  display: flex;
  flex: auto;
  flex-direction: column;
  /* fix firefox can't set height smaller than content on flex item */
  min-height: 0;
  background: #f0f2f5;
}
.ant-layout,
.ant-layout * {
  box-sizing: border-box;
}
.ant-layout.ant-layout-has-sider {
  flex-direction: row;
}
.ant-layout.ant-layout-has-sider > .ant-layout,
.ant-layout.ant-layout-has-sider > .ant-layout-content {
  overflow-x: hidden;
}
.ant-layout-header,
.ant-layout-footer {
  flex: 0 0 auto;
}
.ant-layout-header {
  height: 64px;
  padding: 0 50px;
  line-height: 64px;
  background: #001529;
}
.ant-layout-footer {
  padding: 24px 50px;
  color: rgba(34, 34, 34, 0.8);
  font-size: 14px;
  background: #f0f2f5;
}
.ant-layout-content {
  flex: auto;
  /* fix firefox can't set height smaller than content on flex item */
  min-height: 0;
}
.ant-layout-sider {
  position: relative;
  /* fix firefox can't set width smaller than content on flex item */
  min-width: 0;
  background: #001529;
  transition: all 0.2s;
}
.ant-layout-sider-children {
  height: 100%;
  margin-top: -0.1px;
  padding-top: 0.1px;
}
.ant-layout-sider-has-trigger {
  padding-bottom: 48px;
}
.ant-layout-sider-right {
  order: 1;
}
.ant-layout-sider-trigger {
  position: fixed;
  bottom: 0;
  z-index: 1;
  height: 48px;
  color: #fff;
  line-height: 48px;
  text-align: center;
  background: #002140;
  cursor: pointer;
  transition: all 0.2s;
}
.ant-layout-sider-zero-width > * {
  overflow: hidden;
}
.ant-layout-sider-zero-width-trigger {
  position: absolute;
  top: 64px;
  right: -36px;
  z-index: 1;
  width: 36px;
  height: 42px;
  color: #fff;
  font-size: 18px;
  line-height: 42px;
  text-align: center;
  background: #001529;
  border-radius: 0 10px 10px 0;
  cursor: pointer;
  transition: background 0.3s ease;
}
.ant-layout-sider-zero-width-trigger:hover {
  background: #192c3e;
}
.ant-layout-sider-zero-width-trigger-right {
  left: -36px;
  border-radius: 10px 0 0 10px;
}
.ant-layout-sider-light {
  background: #fff;
}
.ant-layout-sider-light .ant-layout-sider-trigger {
  color: rgba(34, 34, 34, 0.8);
  background: #fff;
}
.ant-layout-sider-light .ant-layout-sider-zero-width-trigger {
  color: rgba(34, 34, 34, 0.8);
  background: #fff;
}
.ant-list {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(34, 34, 34, 0.8);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: "tnum";
  position: relative;
}
.ant-list * {
  outline: none;
}
.ant-list-pagination {
  margin-top: 24px;
  text-align: right;
}
.ant-list-pagination .ant-pagination-options {
  text-align: left;
}
.ant-list-more {
  margin-top: 12px;
  text-align: center;
}
.ant-list-more button {
  padding-right: 32px;
  padding-left: 32px;
}
.ant-list-spin {
  min-height: 40px;
  text-align: center;
}
.ant-list-empty-text {
  padding: 16px;
  color: #fff;
  font-size: 14px;
  text-align: center;
}
.ant-list-items {
  margin: 0;
  padding: 0;
  list-style: none;
}
.ant-list-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 0;
}
.ant-list-item-content {
  color: rgba(34, 34, 34, 0.8);
}
.ant-list-item-meta {
  display: flex;
  flex: 1 1;
  align-items: flex-start;
  font-size: 0;
}
.ant-list-item-meta-avatar {
  margin-right: 16px;
}
.ant-list-item-meta-content {
  flex: 1 0;
}
.ant-list-item-meta-title {
  margin-bottom: 4px;
  color: rgba(34, 34, 34, 0.8);
  font-size: 14px;
  line-height: 22px;
}
.ant-list-item-meta-title > a {
  color: rgba(34, 34, 34, 0.8);
  transition: all 0.3s;
}
.ant-list-item-meta-title > a:hover {
  color: #00a6ff;
}
.ant-list-item-meta-description {
  color: rgba(34, 34, 34, 0.45);
  font-size: 14px;
  line-height: 22px;
}
.ant-list-item-action {
  flex: 0 0 auto;
  margin-left: 48px;
  padding: 0;
  font-size: 0;
  list-style: none;
}
.ant-list-item-action > li {
  position: relative;
  display: inline-block;
  padding: 0 8px;
  color: rgba(34, 34, 34, 0.45);
  font-size: 14px;
  line-height: 22px;
  text-align: center;
  cursor: pointer;
}
.ant-list-item-action > li:first-child {
  padding-left: 0;
}
.ant-list-item-action-split {
  position: absolute;
  top: 50%;
  right: 0;
  width: 1px;
  height: 14px;
  margin-top: -7px;
  background-color: #f0f0f0;
}
.ant-list-header {
  background: transparent;
}
.ant-list-footer {
  background: transparent;
}
.ant-list-header,
.ant-list-footer {
  padding-top: 12px;
  padding-bottom: 12px;
}
.ant-list-empty {
  padding: 16px 0;
  color: rgba(34, 34, 34, 0.45);
  font-size: 12px;
  text-align: center;
}
.ant-list-split .ant-list-item {
  border-bottom: 1px solid #f0f0f0;
}
.ant-list-split .ant-list-item:last-child {
  border-bottom: none;
}
.ant-list-split .ant-list-header {
  border-bottom: 1px solid #f0f0f0;
}
.ant-list-loading .ant-list-spin-nested-loading {
  min-height: 32px;
}
.ant-list-something-after-last-item .ant-spin-container > .ant-list-items > .ant-list-item:last-child {
  border-bottom: 1px solid #f0f0f0;
}
.ant-list-lg .ant-list-item {
  padding-top: 16px;
  padding-bottom: 16px;
}
.ant-list-sm .ant-list-item {
  padding-top: 8px;
  padding-bottom: 8px;
}
.ant-list-vertical .ant-list-item {
  align-items: initial;
}
.ant-list-vertical .ant-list-item-main {
  display: block;
  flex: 1 1;
}
.ant-list-vertical .ant-list-item-extra {
  margin-left: 40px;
}
.ant-list-vertical .ant-list-item-meta {
  margin-bottom: 16px;
}
.ant-list-vertical .ant-list-item-meta-title {
  margin-bottom: 12px;
  color: rgba(255, 255, 255, 0.85);
  font-size: 14px;
  line-height: 24px;
}
.ant-list-vertical .ant-list-item-action {
  margin-top: 16px;
  margin-left: auto;
}
.ant-list-vertical .ant-list-item-action > li {
  padding: 0 16px;
}
.ant-list-vertical .ant-list-item-action > li:first-child {
  padding-left: 0;
}
.ant-list-grid .ant-col > .ant-list-item {
  display: block;
  max-width: 100%;
  margin-bottom: 16px;
  padding-top: 0;
  padding-bottom: 0;
  border-bottom: none;
}
.ant-list-item-no-flex {
  display: block;
}
.ant-list:not(.ant-list-vertical) .ant-list-item-no-flex .ant-list-item-action {
  float: right;
}
.ant-list-bordered {
  border: 1px solid #bfbfbf;
  border-radius: 10px;
}
.ant-list-bordered .ant-list-header {
  padding-right: 24px;
  padding-left: 24px;
}
.ant-list-bordered .ant-list-footer {
  padding-right: 24px;
  padding-left: 24px;
}
.ant-list-bordered .ant-list-item {
  padding-right: 24px;
  padding-left: 24px;
  border-bottom: 1px solid #f0f0f0;
}
.ant-list-bordered .ant-list-pagination {
  margin: 16px 24px;
}
.ant-list-bordered.ant-list-sm .ant-list-item {
  padding-right: 16px;
  padding-left: 16px;
}
.ant-list-bordered.ant-list-sm .ant-list-header,
.ant-list-bordered.ant-list-sm .ant-list-footer {
  padding: 8px 16px;
}
.ant-list-bordered.ant-list-lg .ant-list-header,
.ant-list-bordered.ant-list-lg .ant-list-footer {
  padding: 16px 24px;
}
@media screen and (max-width: 768px) {
  .ant-list-item-action {
    margin-left: 24px;
  }
  .ant-list-vertical .ant-list-item-extra {
    margin-left: 24px;
  }
}
@media screen and (max-width: 576px) {
  .ant-list-item {
    flex-wrap: wrap;
  }
  .ant-list-item-action {
    margin-left: 12px;
  }
  .ant-list-vertical .ant-list-item {
    flex-wrap: wrap-reverse;
  }
  .ant-list-vertical .ant-list-item-main {
    min-width: 220px;
  }
  .ant-list-vertical .ant-list-item-extra {
    margin: auto auto 16px;
  }
}
.ant-mentions-wrapper {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(34, 34, 34, 0.8);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: "tnum";
  position: relative;
  display: inline-block;
  width: 100%;
  vertical-align: middle;
}
.ant-mentions-wrapper .ant-mentions-editor {
  position: relative;
  display: inline-block;
  width: 100%;
  height: 32px;
  padding: 4px 11px;
  color: rgba(34, 34, 34, 0.8);
  font-size: 14px;
  background-color: #fff;
  background-image: none;
  border: 1px solid #bfbfbf;
  border-radius: 10px;
  transition: all 0.3s;
  display: block;
  height: auto;
  min-height: 32px;
  padding: 0;
  line-height: 1.5715;
}
.ant-mentions-wrapper .ant-mentions-editor::-moz-placeholder {
  color: #bfbfbf;
  opacity: 1;
}
.ant-mentions-wrapper .ant-mentions-editor:-ms-input-placeholder {
  color: #bfbfbf;
}
.ant-mentions-wrapper .ant-mentions-editor::-webkit-input-placeholder {
  color: #bfbfbf;
}
.ant-mentions-wrapper .ant-mentions-editor:-moz-placeholder-shown {
  text-overflow: ellipsis;
}
.ant-mentions-wrapper .ant-mentions-editor:-ms-input-placeholder {
  text-overflow: ellipsis;
}
.ant-mentions-wrapper .ant-mentions-editor:placeholder-shown {
  text-overflow: ellipsis;
}
.ant-mentions-wrapper .ant-mentions-editor:hover {
  border-color: #333;
  border-right-width: 1px !important;
}
.ant-mentions-wrapper .ant-mentions-editor:focus {
  border-color: #666060;
  border-right-width: 1px !important;
  outline: 0;
  box-shadow: 0 0 0 1px rgba(89, 89, 89, 0.2);
}
.ant-mentions-wrapper .ant-mentions-editor-disabled {
  color: #fff;
  background-color: #cccccc;
  cursor: not-allowed;
  opacity: 1;
}
.ant-mentions-wrapper .ant-mentions-editor-disabled:hover {
  border-color: #bfbfbf;
  border-right-width: 1px !important;
}
.ant-mentions-wrapper .ant-mentions-editor[disabled] {
  color: #fff;
  background-color: #cccccc;
  cursor: not-allowed;
  opacity: 1;
}
.ant-mentions-wrapper .ant-mentions-editor[disabled]:hover {
  border-color: #bfbfbf;
  border-right-width: 1px !important;
}
textarea.ant-mentions-wrapper .ant-mentions-editor {
  max-width: 100%;
  height: auto;
  min-height: 32px;
  line-height: 1.5715;
  vertical-align: bottom;
  transition: all 0.3s, height 0s;
}
.ant-mentions-wrapper .ant-mentions-editor-lg {
  height: 45px;
  padding: 10.5px 11px;
  font-size: 14px;
}
.ant-mentions-wrapper .ant-mentions-editor-sm {
  height: 24px;
  padding: 0px 7px;
}
.ant-mentions-wrapper .ant-mentions-editor-wrapper {
  height: auto;
  overflow-y: auto;
}
.ant-mentions-wrapper.ant-mentions-active:not(.disabled) .ant-mentions-editor {
  border-color: #666060;
  border-right-width: 1px !important;
  outline: 0;
  box-shadow: 0 0 0 1px rgba(89, 89, 89, 0.2);
}
.ant-mentions-wrapper.disabled .ant-mentions-editor {
  color: #fff;
  background-color: #cccccc;
  cursor: not-allowed;
  opacity: 1;
}
.ant-mentions-wrapper.disabled .ant-mentions-editor:hover {
  border-color: #bfbfbf;
  border-right-width: 1px !important;
}
.ant-mentions-wrapper .public-DraftEditorPlaceholder-root {
  position: absolute;
  pointer-events: none;
}
.ant-mentions-wrapper .public-DraftEditorPlaceholder-root .public-DraftEditorPlaceholder-inner {
  height: auto;
  padding: 5px 11px;
  color: #bfbfbf;
  white-space: pre-wrap;
  word-wrap: break-word;
  outline: none;
  opacity: 1;
}
.ant-mentions-wrapper .DraftEditor-editorContainer .public-DraftEditor-content {
  height: auto;
  padding: 5px 11px;
}
.ant-mentions-dropdown {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(34, 34, 34, 0.8);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: "tnum";
  position: absolute;
  top: -9999px;
  left: -9999px;
  z-index: 9999;
  min-width: 120px;
  max-height: 250px;
  margin-top: 1.5em;
  overflow-x: hidden;
  overflow-y: auto;
  background-color: #fff;
  border-radius: 10px;
  outline: none;
  box-shadow: 0 0 1px 1px rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.08), 0 2px 8px 2px rgba(0, 0, 0, 0.05);
}
.ant-mentions-dropdown-placement-top {
  margin-top: -0.1em;
}
.ant-mentions-dropdown-notfound.ant-mentions-dropdown-item {
  color: #fff;
}
.ant-mentions-dropdown-notfound.ant-mentions-dropdown-item .anticon-loading {
  display: block;
  color: #00a6ff;
  text-align: center;
}
.ant-mentions-dropdown-item {
  position: relative;
  display: block;
  padding: 5px 12px;
  overflow: hidden;
  color: rgba(34, 34, 34, 0.8);
  font-weight: normal;
  line-height: 22px;
  white-space: nowrap;
  text-overflow: ellipsis;
  cursor: pointer;
  transition: background 0.3s;
}
.ant-mentions-dropdown-item:hover {
  background-color: #f5f5f5;
}
.ant-mentions-dropdown-item.focus,
.ant-mentions-dropdown-item-active {
  background-color: #e6faff;
}
.ant-mentions-dropdown-item-disabled {
  color: #fff;
  cursor: not-allowed;
}
.ant-mentions-dropdown-item-disabled:hover {
  color: #fff;
  background-color: #fff;
  cursor: not-allowed;
}
.ant-mentions-dropdown-item-selected,
.ant-mentions-dropdown-item-selected:hover {
  color: rgba(34, 34, 34, 0.8);
  font-weight: bold;
  background-color: #cccccc;
}
.ant-mentions-dropdown-item-divider {
  height: 1px;
  margin: 1px 0;
  overflow: hidden;
  line-height: 0;
  background-color: #f0f0f0;
}
.ant-mentions {
  box-sizing: border-box;
  margin: 0;
  font-variant: tabular-nums;
  list-style: none;
  font-feature-settings: "tnum";
  width: 100%;
  height: 32px;
  padding: 4px 11px;
  color: rgba(34, 34, 34, 0.8);
  font-size: 14px;
  background-color: #fff;
  background-image: none;
  border: 1px solid #bfbfbf;
  border-radius: 10px;
  transition: all 0.3s;
  position: relative;
  display: inline-block;
  height: auto;
  padding: 0;
  overflow: hidden;
  line-height: 1.5715;
  white-space: pre-wrap;
  vertical-align: bottom;
}
.ant-mentions::-moz-placeholder {
  color: #bfbfbf;
  opacity: 1;
}
.ant-mentions:-ms-input-placeholder {
  color: #bfbfbf;
}
.ant-mentions::-webkit-input-placeholder {
  color: #bfbfbf;
}
.ant-mentions:-moz-placeholder-shown {
  text-overflow: ellipsis;
}
.ant-mentions:-ms-input-placeholder {
  text-overflow: ellipsis;
}
.ant-mentions:placeholder-shown {
  text-overflow: ellipsis;
}
.ant-mentions:hover {
  border-color: #333;
  border-right-width: 1px !important;
}
.ant-mentions:focus {
  border-color: #666060;
  border-right-width: 1px !important;
  outline: 0;
  box-shadow: 0 0 0 1px rgba(89, 89, 89, 0.2);
}
.ant-mentions-disabled {
  color: #fff;
  background-color: #cccccc;
  cursor: not-allowed;
  opacity: 1;
}
.ant-mentions-disabled:hover {
  border-color: #bfbfbf;
  border-right-width: 1px !important;
}
.ant-mentions[disabled] {
  color: #fff;
  background-color: #cccccc;
  cursor: not-allowed;
  opacity: 1;
}
.ant-mentions[disabled]:hover {
  border-color: #bfbfbf;
  border-right-width: 1px !important;
}
textarea.ant-mentions {
  max-width: 100%;
  height: auto;
  min-height: 32px;
  line-height: 1.5715;
  vertical-align: bottom;
  transition: all 0.3s, height 0s;
}
.ant-mentions-lg {
  height: 45px;
  padding: 10.5px 11px;
  font-size: 14px;
}
.ant-mentions-sm {
  height: 24px;
  padding: 0px 7px;
}
.ant-mentions-disabled > textarea {
  color: #fff;
  background-color: #cccccc;
  cursor: not-allowed;
  opacity: 1;
}
.ant-mentions-disabled > textarea:hover {
  border-color: #bfbfbf;
  border-right-width: 1px !important;
}
.ant-mentions-focused {
  border-color: #666060;
  border-right-width: 1px !important;
  outline: 0;
  box-shadow: 0 0 0 1px rgba(89, 89, 89, 0.2);
}
.ant-mentions > textarea,
.ant-mentions-measure {
  min-height: 30px;
  margin: 0;
  padding: 4px 11px;
  overflow: inherit;
  overflow-x: hidden;
  overflow-y: auto;
  font-weight: inherit;
  font-size: inherit;
  font-family: inherit;
  font-style: inherit;
  font-feature-settings: inherit;
  font-variant: inherit;
  font-size-adjust: inherit;
  font-stretch: inherit;
  line-height: inherit;
  direction: inherit;
  letter-spacing: inherit;
  white-space: inherit;
  text-align: inherit;
  vertical-align: top;
  word-wrap: break-word;
  word-break: inherit;
  -moz-tab-size: inherit;
    -o-tab-size: inherit;
       tab-size: inherit;
}
.ant-mentions > textarea {
  width: 100%;
  border: none;
  outline: none;
  resize: none;
}
.ant-mentions > textarea::-moz-placeholder {
  color: #bfbfbf;
  opacity: 1;
}
.ant-mentions > textarea:-ms-input-placeholder {
  color: #bfbfbf;
}
.ant-mentions > textarea::-webkit-input-placeholder {
  color: #bfbfbf;
}
.ant-mentions > textarea:-moz-placeholder-shown {
  text-overflow: ellipsis;
}
.ant-mentions > textarea:-ms-input-placeholder {
  text-overflow: ellipsis;
}
.ant-mentions > textarea:placeholder-shown {
  text-overflow: ellipsis;
}
.ant-mentions > textarea:-moz-read-only {
  cursor: default;
}
.ant-mentions > textarea:read-only {
  cursor: default;
}
.ant-mentions-measure {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  color: transparent;
  pointer-events: none;
}
.ant-mentions-measure > span {
  display: inline-block;
  min-height: 1em;
}
.ant-mentions-dropdown {
  margin: 0;
  padding: 0;
  color: rgba(34, 34, 34, 0.8);
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: "tnum", ;
  position: absolute;
  top: -9999px;
  left: -9999px;
  z-index: 9999;
  box-sizing: border-box;
  font-size: 14px;
  font-variant: initial;
  background-color: #fff;
  border-radius: 10px;
  outline: none;
  box-shadow: 0 0 1px 1px rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.08), 0 2px 8px 2px rgba(0, 0, 0, 0.05);
}
.ant-mentions-dropdown-hidden {
  display: none;
}
.ant-mentions-dropdown-menu {
  max-height: 250px;
  margin-bottom: 0;
  padding-left: 0;
  overflow: auto;
  list-style: none;
  outline: none;
}
.ant-mentions-dropdown-menu-item {
  position: relative;
  display: block;
  min-width: 100px;
  padding: 5px 12px;
  overflow: hidden;
  color: rgba(34, 34, 34, 0.8);
  font-weight: normal;
  line-height: 22px;
  white-space: nowrap;
  text-overflow: ellipsis;
  cursor: pointer;
  transition: background 0.3s ease;
}
.ant-mentions-dropdown-menu-item:hover {
  background-color: #f5f5f5;
}
.ant-mentions-dropdown-menu-item:first-child {
  border-radius: 10px 10px 0 0;
}
.ant-mentions-dropdown-menu-item:last-child {
  border-radius: 0 0 10px 10px;
}
.ant-mentions-dropdown-menu-item-disabled {
  color: #fff;
  cursor: not-allowed;
}
.ant-mentions-dropdown-menu-item-disabled:hover {
  color: #fff;
  background-color: #fff;
  cursor: not-allowed;
}
.ant-mentions-dropdown-menu-item-selected {
  color: rgba(34, 34, 34, 0.8);
  font-weight: 600;
  background-color: #fafafa;
}
.ant-mentions-dropdown-menu-item-active {
  background-color: #e6faff;
}
.ant-menu {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(34, 34, 34, 0.8);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  font-feature-settings: "tnum";
  margin-bottom: 0;
  padding-left: 0;
  color: #fff;
  line-height: 0;
  list-style: none;
  background: transparent;
  outline: none;
  box-shadow: 0 0 1px 1px rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.08), 0 2px 8px 2px rgba(0, 0, 0, 0.05);
  transition: background 0.3s, width 0.2s;
  zoom: 1;
}
.ant-menu::before,
.ant-menu::after {
  display: table;
  content: '';
}
.ant-menu::after {
  clear: both;
}
.ant-menu::before,
.ant-menu::after {
  display: table;
  content: '';
}
.ant-menu::after {
  clear: both;
}
.ant-menu ul,
.ant-menu ol {
  margin: 0;
  padding: 0;
  list-style: none;
}
.ant-menu-hidden {
  display: none;
}
.ant-menu-item-group-title {
  padding: 8px 16px;
  color: rgba(34, 34, 34, 0.45);
  font-size: 14px;
  line-height: 1.5715;
  transition: all 0.3s;
}
.ant-menu-submenu,
.ant-menu-submenu-inline {
  transition: border-color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), background 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), padding 0.15s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.ant-menu-submenu-selected {
  color: #00a6ff;
}
.ant-menu-item:active,
.ant-menu-submenu-title:active {
  background: #e6faff;
}
.ant-menu-submenu .ant-menu-sub {
  cursor: auto;
  cursor: initial;
  transition: background 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), padding 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.ant-menu-item > a {
  display: block;
}
.ant-menu-item > a:hover {
  color: #00a6ff;
}
.ant-menu-item > a::before {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: transparent;
  content: "";
}
.ant-menu-item > .ant-badge > a {
  color: #fff;
}
.ant-menu-item > .ant-badge > a:hover {
  color: #00a6ff;
}
.ant-menu-item-divider {
  height: 1px;
  overflow: hidden;
  line-height: 0;
  background-color: #f0f0f0;
}
.ant-menu-item:hover,
.ant-menu-item-active,
.ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open,
.ant-menu-submenu-active,
.ant-menu-submenu-title:hover {
  color: #00a6ff;
}
.ant-menu-horizontal > .ant-menu-item:hover,
.ant-menu-horizontal > .ant-menu-item-active,
.ant-menu-horizontal > .ant-menu-submenu .ant-menu-submenu-title:hover {
  background-color: transparent;
}
.ant-menu-item-selected {
  color: #00a6ff;
}
.ant-menu-item-selected > a,
.ant-menu-item-selected > a:hover {
  color: #00a6ff;
}
.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: #e6faff;
}
.ant-menu-inline,
.ant-menu-vertical,
.ant-menu-vertical-left {
  border-right: 1px solid #f0f0f0;
}
.ant-menu-vertical-right {
  border-left: 1px solid #f0f0f0;
}
.ant-menu-vertical.ant-menu-sub,
.ant-menu-vertical-left.ant-menu-sub,
.ant-menu-vertical-right.ant-menu-sub {
  min-width: 160px;
  padding: 0;
  border-right: 0;
  transform-origin: 0 0;
}
.ant-menu-vertical.ant-menu-sub .ant-menu-item,
.ant-menu-vertical-left.ant-menu-sub .ant-menu-item,
.ant-menu-vertical-right.ant-menu-sub .ant-menu-item {
  left: 0;
  margin-left: 0;
  border-right: 0;
}
.ant-menu-vertical.ant-menu-sub .ant-menu-item::after,
.ant-menu-vertical-left.ant-menu-sub .ant-menu-item::after,
.ant-menu-vertical-right.ant-menu-sub .ant-menu-item::after {
  border-right: 0;
}
.ant-menu-vertical.ant-menu-sub > .ant-menu-item,
.ant-menu-vertical-left.ant-menu-sub > .ant-menu-item,
.ant-menu-vertical-right.ant-menu-sub > .ant-menu-item,
.ant-menu-vertical.ant-menu-sub > .ant-menu-submenu,
.ant-menu-vertical-left.ant-menu-sub > .ant-menu-submenu,
.ant-menu-vertical-right.ant-menu-sub > .ant-menu-submenu {
  transform-origin: 0 0;
}
.ant-menu-horizontal.ant-menu-sub {
  min-width: 114px;
}
.ant-menu-item,
.ant-menu-submenu-title {
  position: relative;
  display: block;
  margin: 0;
  padding: 0 10px;
  white-space: nowrap;
  cursor: pointer;
  transition: color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), border-color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), background 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), padding 0.15s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.ant-menu-item .anticon,
.ant-menu-submenu-title .anticon {
  min-width: 14px;
  margin-right: 10px;
  font-size: 14px;
  transition: font-size 0.15s cubic-bezier(0.215, 0.61, 0.355, 1), margin 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.ant-menu-item .anticon + span,
.ant-menu-submenu-title .anticon + span {
  opacity: 1;
  transition: opacity 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), width 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.ant-menu > .ant-menu-item-divider {
  height: 1px;
  margin: 1px 0;
  padding: 0;
  overflow: hidden;
  line-height: 0;
  background-color: #f0f0f0;
}
.ant-menu-submenu-popup {
  position: absolute;
  z-index: 9999;
  background: #fff;
  border-radius: 10px;
}
.ant-menu-submenu-popup .submenu-title-wrapper {
  padding-right: 20px;
}
.ant-menu-submenu-popup::before {
  position: absolute;
  top: -7px;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 0.0001;
  content: " ";
}
.ant-menu-submenu > .ant-menu {
  background-color: transparent;
  border-radius: 10px;
}
.ant-menu-submenu > .ant-menu-submenu-title::after {
  transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.ant-menu-submenu-vertical > .ant-menu-submenu-title .ant-menu-submenu-arrow,
.ant-menu-submenu-vertical-left > .ant-menu-submenu-title .ant-menu-submenu-arrow,
.ant-menu-submenu-vertical-right > .ant-menu-submenu-title .ant-menu-submenu-arrow,
.ant-menu-submenu-inline > .ant-menu-submenu-title .ant-menu-submenu-arrow {
  position: absolute;
  top: 50%;
  right: 16px;
  width: 10px;
  transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.ant-menu-submenu-vertical > .ant-menu-submenu-title .ant-menu-submenu-arrow::before,
.ant-menu-submenu-vertical-left > .ant-menu-submenu-title .ant-menu-submenu-arrow::before,
.ant-menu-submenu-vertical-right > .ant-menu-submenu-title .ant-menu-submenu-arrow::before,
.ant-menu-submenu-inline > .ant-menu-submenu-title .ant-menu-submenu-arrow::before,
.ant-menu-submenu-vertical > .ant-menu-submenu-title .ant-menu-submenu-arrow::after,
.ant-menu-submenu-vertical-left > .ant-menu-submenu-title .ant-menu-submenu-arrow::after,
.ant-menu-submenu-vertical-right > .ant-menu-submenu-title .ant-menu-submenu-arrow::after,
.ant-menu-submenu-inline > .ant-menu-submenu-title .ant-menu-submenu-arrow::after {
  position: absolute;
  width: 6px;
  height: 1.5px;
  background: transparent;
  background: #fff \9;
  background-image: linear-gradient(to right, #fff, #fff);
  background-image: none \9;
  border-radius: 2px;
  transition: background 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), top 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  content: "";
}
.ant-menu-submenu-vertical > .ant-menu-submenu-title .ant-menu-submenu-arrow::before,
.ant-menu-submenu-vertical-left > .ant-menu-submenu-title .ant-menu-submenu-arrow::before,
.ant-menu-submenu-vertical-right > .ant-menu-submenu-title .ant-menu-submenu-arrow::before,
.ant-menu-submenu-inline > .ant-menu-submenu-title .ant-menu-submenu-arrow::before {
  transform: rotate(45deg) translateY(-2px);
}
.ant-menu-submenu-vertical > .ant-menu-submenu-title .ant-menu-submenu-arrow::after,
.ant-menu-submenu-vertical-left > .ant-menu-submenu-title .ant-menu-submenu-arrow::after,
.ant-menu-submenu-vertical-right > .ant-menu-submenu-title .ant-menu-submenu-arrow::after,
.ant-menu-submenu-inline > .ant-menu-submenu-title .ant-menu-submenu-arrow::after {
  transform: rotate(-45deg) translateY(2px);
}
.ant-menu-submenu-vertical > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow::after,
.ant-menu-submenu-vertical-left > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow::after,
.ant-menu-submenu-vertical-right > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow::after,
.ant-menu-submenu-inline > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow::after,
.ant-menu-submenu-vertical > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow::before,
.ant-menu-submenu-vertical-left > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow::before,
.ant-menu-submenu-vertical-right > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow::before,
.ant-menu-submenu-inline > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow::before {
  background: linear-gradient(to right, #00a6ff, #00a6ff);
}
.ant-menu-submenu-inline > .ant-menu-submenu-title .ant-menu-submenu-arrow::before {
  transform: rotate(-45deg) translateX(2px);
}
.ant-menu-submenu-inline > .ant-menu-submenu-title .ant-menu-submenu-arrow::after {
  transform: rotate(45deg) translateX(-2px);
}
.ant-menu-submenu-open.ant-menu-submenu-inline > .ant-menu-submenu-title .ant-menu-submenu-arrow {
  transform: translateY(-2px);
}
.ant-menu-submenu-open.ant-menu-submenu-inline > .ant-menu-submenu-title .ant-menu-submenu-arrow::after {
  transform: rotate(-45deg) translateX(-2px);
}
.ant-menu-submenu-open.ant-menu-submenu-inline > .ant-menu-submenu-title .ant-menu-submenu-arrow::before {
  transform: rotate(45deg) translateX(2px);
}
.ant-menu-vertical .ant-menu-submenu-selected,
.ant-menu-vertical-left .ant-menu-submenu-selected,
.ant-menu-vertical-right .ant-menu-submenu-selected {
  color: #00a6ff;
}
.ant-menu-vertical .ant-menu-submenu-selected > a,
.ant-menu-vertical-left .ant-menu-submenu-selected > a,
.ant-menu-vertical-right .ant-menu-submenu-selected > a {
  color: #00a6ff;
}
.ant-menu-horizontal {
  line-height: inherit;
  white-space: nowrap;
  border: 0;
  box-shadow: none;
}
.ant-menu-horizontal > .ant-menu-item,
.ant-menu-horizontal > .ant-menu-submenu {
  position: relative;
  display: inline-block;
  vertical-align: bottom;
}
.ant-menu-horizontal > .ant-menu-item:hover,
.ant-menu-horizontal > .ant-menu-submenu:hover,
.ant-menu-horizontal > .ant-menu-item-active,
.ant-menu-horizontal > .ant-menu-submenu-active,
.ant-menu-horizontal > .ant-menu-item-open,
.ant-menu-horizontal > .ant-menu-submenu-open,
.ant-menu-horizontal > .ant-menu-item-selected,
.ant-menu-horizontal > .ant-menu-submenu-selected {
  color: #00a6ff;
}
.ant-menu-horizontal > .ant-menu-item > a {
  display: block;
}
.ant-menu-horizontal > .ant-menu-item > a:hover {
  color: #00a6ff;
}
.ant-menu-horizontal > .ant-menu-item > a::before {
  bottom: -2px;
}
.ant-menu-horizontal > .ant-menu-item-selected > a {
  color: #00a6ff;
}
.ant-menu-horizontal::after {
  display: block;
  clear: both;
  height: 0;
  content: "\20";
}
.ant-menu-horizontal a {
  color: inherit;
}
.ant-menu-vertical .ant-menu-item,
.ant-menu-vertical-left .ant-menu-item,
.ant-menu-vertical-right .ant-menu-item,
.ant-menu-inline .ant-menu-item {
  position: relative;
}
.ant-menu-vertical .ant-menu-item::after,
.ant-menu-vertical-left .ant-menu-item::after,
.ant-menu-vertical-right .ant-menu-item::after,
.ant-menu-inline .ant-menu-item::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  border-right: 3px solid #00a6ff;
  transform: scaleY(0.0001);
  opacity: 0;
  transition: transform 0.15s cubic-bezier(0.215, 0.61, 0.355, 1), opacity 0.15s cubic-bezier(0.215, 0.61, 0.355, 1);
  content: "";
}
.ant-menu-vertical .ant-menu-item,
.ant-menu-vertical-left .ant-menu-item,
.ant-menu-vertical-right .ant-menu-item,
.ant-menu-inline .ant-menu-item,
.ant-menu-vertical .ant-menu-submenu-title,
.ant-menu-vertical-left .ant-menu-submenu-title,
.ant-menu-vertical-right .ant-menu-submenu-title,
.ant-menu-inline .ant-menu-submenu-title {
  height: 40px;
  margin-top: 4px;
  margin-bottom: 4px;
  padding: 0 16px;
  overflow: hidden;
  font-size: 14px;
  line-height: 40px;
  text-overflow: ellipsis;
}
.ant-menu-vertical .ant-menu-submenu,
.ant-menu-vertical-left .ant-menu-submenu,
.ant-menu-vertical-right .ant-menu-submenu,
.ant-menu-inline .ant-menu-submenu {
  padding-bottom: 0.02px;
}
.ant-menu-vertical .ant-menu-item:not(:last-child),
.ant-menu-vertical-left .ant-menu-item:not(:last-child),
.ant-menu-vertical-right .ant-menu-item:not(:last-child),
.ant-menu-inline .ant-menu-item:not(:last-child) {
  margin-bottom: 8px;
}
.ant-menu-vertical > .ant-menu-item,
.ant-menu-vertical-left > .ant-menu-item,
.ant-menu-vertical-right > .ant-menu-item,
.ant-menu-inline > .ant-menu-item,
.ant-menu-vertical > .ant-menu-submenu > .ant-menu-submenu-title,
.ant-menu-vertical-left > .ant-menu-submenu > .ant-menu-submenu-title,
.ant-menu-vertical-right > .ant-menu-submenu > .ant-menu-submenu-title,
.ant-menu-inline > .ant-menu-submenu > .ant-menu-submenu-title {
  height: 40px;
  line-height: 40px;
}
.ant-menu-inline {
  width: 100%;
}
.ant-menu-inline .ant-menu-selected::after,
.ant-menu-inline .ant-menu-item-selected::after {
  transform: scaleY(1);
  opacity: 1;
  transition: transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), opacity 0.15s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.ant-menu-inline .ant-menu-item,
.ant-menu-inline .ant-menu-submenu-title {
  width: calc(100% + 1px);
}
.ant-menu-inline .ant-menu-submenu-title {
  padding-right: 34px;
}
.ant-menu-inline-collapsed {
  width: 80px;
}
.ant-menu-inline-collapsed > .ant-menu-item,
.ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-item,
.ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-submenu > .ant-menu-submenu-title,
.ant-menu-inline-collapsed > .ant-menu-submenu > .ant-menu-submenu-title {
  left: 0;
  padding: 0 33px !important;
  text-overflow: clip;
}
.ant-menu-inline-collapsed > .ant-menu-item .ant-menu-submenu-arrow,
.ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-item .ant-menu-submenu-arrow,
.ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-submenu > .ant-menu-submenu-title .ant-menu-submenu-arrow,
.ant-menu-inline-collapsed > .ant-menu-submenu > .ant-menu-submenu-title .ant-menu-submenu-arrow {
  display: none;
}
.ant-menu-inline-collapsed > .ant-menu-item .anticon,
.ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-item .anticon,
.ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-submenu > .ant-menu-submenu-title .anticon,
.ant-menu-inline-collapsed > .ant-menu-submenu > .ant-menu-submenu-title .anticon {
  margin: 0;
  font-size: 14px;
  line-height: 40px;
}
.ant-menu-inline-collapsed > .ant-menu-item .anticon + span,
.ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-item .anticon + span,
.ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-submenu > .ant-menu-submenu-title .anticon + span,
.ant-menu-inline-collapsed > .ant-menu-submenu > .ant-menu-submenu-title .anticon + span {
  display: inline-block;
  max-width: 0;
  opacity: 0;
}
.ant-menu-inline-collapsed-tooltip {
  pointer-events: none;
}
.ant-menu-inline-collapsed-tooltip .anticon {
  display: none;
}
.ant-menu-inline-collapsed-tooltip a {
  color: rgba(255, 255, 255, 0.85);
}
.ant-menu-inline-collapsed .ant-menu-item-group-title {
  padding-right: 4px;
  padding-left: 4px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.ant-menu-item-group-list {
  margin: 0;
  padding: 0;
}
.ant-menu-item-group-list .ant-menu-item,
.ant-menu-item-group-list .ant-menu-submenu-title {
  padding: 0 16px 0 28px;
}
.ant-menu-root.ant-menu-vertical,
.ant-menu-root.ant-menu-vertical-left,
.ant-menu-root.ant-menu-vertical-right,
.ant-menu-root.ant-menu-inline {
  box-shadow: none;
}
.ant-menu-sub.ant-menu-inline {
  padding: 0;
  border: 0;
  border-radius: 0;
  box-shadow: none;
}
.ant-menu-sub.ant-menu-inline > .ant-menu-item,
.ant-menu-sub.ant-menu-inline > .ant-menu-submenu > .ant-menu-submenu-title {
  height: 40px;
  line-height: 40px;
  list-style-position: inside;
  list-style-type: disc;
}
.ant-menu-sub.ant-menu-inline .ant-menu-item-group-title {
  padding-left: 32px;
}
.ant-menu-item-disabled,
.ant-menu-submenu-disabled {
  color: #fff !important;
  background: none;
  border-color: transparent !important;
  cursor: not-allowed;
}
.ant-menu-item-disabled > a,
.ant-menu-submenu-disabled > a {
  color: #fff !important;
  pointer-events: none;
}
.ant-menu-item-disabled > .ant-menu-submenu-title,
.ant-menu-submenu-disabled > .ant-menu-submenu-title {
  color: #fff !important;
  cursor: not-allowed;
}
.ant-menu-item-disabled > .ant-menu-submenu-title > .ant-menu-submenu-arrow::before,
.ant-menu-submenu-disabled > .ant-menu-submenu-title > .ant-menu-submenu-arrow::before,
.ant-menu-item-disabled > .ant-menu-submenu-title > .ant-menu-submenu-arrow::after,
.ant-menu-submenu-disabled > .ant-menu-submenu-title > .ant-menu-submenu-arrow::after {
  background: #fff !important;
}
.ant-menu-dark,
.ant-menu-dark .ant-menu-sub {
  color: rgba(255, 255, 255, 0.65);
  background: #001529;
}
.ant-menu-dark .ant-menu-submenu-title .ant-menu-submenu-arrow,
.ant-menu-dark .ant-menu-sub .ant-menu-submenu-title .ant-menu-submenu-arrow {
  opacity: 0.45;
  transition: all 0.3s;
}
.ant-menu-dark .ant-menu-submenu-title .ant-menu-submenu-arrow::after,
.ant-menu-dark .ant-menu-sub .ant-menu-submenu-title .ant-menu-submenu-arrow::after,
.ant-menu-dark .ant-menu-submenu-title .ant-menu-submenu-arrow::before,
.ant-menu-dark .ant-menu-sub .ant-menu-submenu-title .ant-menu-submenu-arrow::before {
  background: #fff;
}
.ant-menu-dark.ant-menu-submenu-popup {
  background: transparent;
}
.ant-menu-dark .ant-menu-inline.ant-menu-sub {
  background: #000c17;
  box-shadow: 0 2px 8px rgba(34, 34, 34, 0.45) inset;
}
.ant-menu-dark.ant-menu-horizontal {
  border-bottom: 0;
}
.ant-menu-dark.ant-menu-horizontal > .ant-menu-item,
.ant-menu-dark.ant-menu-horizontal > .ant-menu-submenu {
  top: 0;
  margin-top: 0;
  border-color: #001529;
  border-bottom: 0;
}
.ant-menu-dark.ant-menu-horizontal > .ant-menu-item > a::before {
  bottom: 0;
}
.ant-menu-dark .ant-menu-item,
.ant-menu-dark .ant-menu-item-group-title,
.ant-menu-dark .ant-menu-item > a {
  color: rgba(255, 255, 255, 0.65);
}
.ant-menu-dark.ant-menu-inline,
.ant-menu-dark.ant-menu-vertical,
.ant-menu-dark.ant-menu-vertical-left,
.ant-menu-dark.ant-menu-vertical-right {
  border-right: 0;
}
.ant-menu-dark.ant-menu-inline .ant-menu-item,
.ant-menu-dark.ant-menu-vertical .ant-menu-item,
.ant-menu-dark.ant-menu-vertical-left .ant-menu-item,
.ant-menu-dark.ant-menu-vertical-right .ant-menu-item {
  left: 0;
  margin-left: 0;
  border-right: 0;
}
.ant-menu-dark.ant-menu-inline .ant-menu-item::after,
.ant-menu-dark.ant-menu-vertical .ant-menu-item::after,
.ant-menu-dark.ant-menu-vertical-left .ant-menu-item::after,
.ant-menu-dark.ant-menu-vertical-right .ant-menu-item::after {
  border-right: 0;
}
.ant-menu-dark.ant-menu-inline .ant-menu-item,
.ant-menu-dark.ant-menu-inline .ant-menu-submenu-title {
  width: 100%;
}
.ant-menu-dark .ant-menu-item:hover,
.ant-menu-dark .ant-menu-item-active,
.ant-menu-dark .ant-menu-submenu-active,
.ant-menu-dark .ant-menu-submenu-open,
.ant-menu-dark .ant-menu-submenu-selected,
.ant-menu-dark .ant-menu-submenu-title:hover {
  color: #fff;
  background-color: transparent;
}
.ant-menu-dark .ant-menu-item:hover > a,
.ant-menu-dark .ant-menu-item-active > a,
.ant-menu-dark .ant-menu-submenu-active > a,
.ant-menu-dark .ant-menu-submenu-open > a,
.ant-menu-dark .ant-menu-submenu-selected > a,
.ant-menu-dark .ant-menu-submenu-title:hover > a {
  color: #fff;
}
.ant-menu-dark .ant-menu-item:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow,
.ant-menu-dark .ant-menu-item-active > .ant-menu-submenu-title > .ant-menu-submenu-arrow,
.ant-menu-dark .ant-menu-submenu-active > .ant-menu-submenu-title > .ant-menu-submenu-arrow,
.ant-menu-dark .ant-menu-submenu-open > .ant-menu-submenu-title > .ant-menu-submenu-arrow,
.ant-menu-dark .ant-menu-submenu-selected > .ant-menu-submenu-title > .ant-menu-submenu-arrow,
.ant-menu-dark .ant-menu-submenu-title:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow,
.ant-menu-dark .ant-menu-item:hover > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow,
.ant-menu-dark .ant-menu-item-active > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow,
.ant-menu-dark .ant-menu-submenu-active > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow,
.ant-menu-dark .ant-menu-submenu-open > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow,
.ant-menu-dark .ant-menu-submenu-selected > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow,
.ant-menu-dark .ant-menu-submenu-title:hover > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow {
  opacity: 1;
}
.ant-menu-dark .ant-menu-item:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow::after,
.ant-menu-dark .ant-menu-item-active > .ant-menu-submenu-title > .ant-menu-submenu-arrow::after,
.ant-menu-dark .ant-menu-submenu-active > .ant-menu-submenu-title > .ant-menu-submenu-arrow::after,
.ant-menu-dark .ant-menu-submenu-open > .ant-menu-submenu-title > .ant-menu-submenu-arrow::after,
.ant-menu-dark .ant-menu-submenu-selected > .ant-menu-submenu-title > .ant-menu-submenu-arrow::after,
.ant-menu-dark .ant-menu-submenu-title:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow::after,
.ant-menu-dark .ant-menu-item:hover > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow::after,
.ant-menu-dark .ant-menu-item-active > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow::after,
.ant-menu-dark .ant-menu-submenu-active > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow::after,
.ant-menu-dark .ant-menu-submenu-open > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow::after,
.ant-menu-dark .ant-menu-submenu-selected > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow::after,
.ant-menu-dark .ant-menu-submenu-title:hover > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow::after,
.ant-menu-dark .ant-menu-item:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow::before,
.ant-menu-dark .ant-menu-item-active > .ant-menu-submenu-title > .ant-menu-submenu-arrow::before,
.ant-menu-dark .ant-menu-submenu-active > .ant-menu-submenu-title > .ant-menu-submenu-arrow::before,
.ant-menu-dark .ant-menu-submenu-open > .ant-menu-submenu-title > .ant-menu-submenu-arrow::before,
.ant-menu-dark .ant-menu-submenu-selected > .ant-menu-submenu-title > .ant-menu-submenu-arrow::before,
.ant-menu-dark .ant-menu-submenu-title:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow::before,
.ant-menu-dark .ant-menu-item:hover > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow::before,
.ant-menu-dark .ant-menu-item-active > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow::before,
.ant-menu-dark .ant-menu-submenu-active > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow::before,
.ant-menu-dark .ant-menu-submenu-open > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow::before,
.ant-menu-dark .ant-menu-submenu-selected > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow::before,
.ant-menu-dark .ant-menu-submenu-title:hover > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow::before {
  background: #fff;
}
.ant-menu-dark .ant-menu-item:hover {
  background-color: transparent;
}
.ant-menu-dark .ant-menu-item-selected {
  color: #fff;
  border-right: 0;
}
.ant-menu-dark .ant-menu-item-selected::after {
  border-right: 0;
}
.ant-menu-dark .ant-menu-item-selected > a,
.ant-menu-dark .ant-menu-item-selected > a:hover {
  color: #fff;
}
.ant-menu-dark .ant-menu-item-selected .anticon {
  color: #fff;
}
.ant-menu-dark .ant-menu-item-selected .anticon + span {
  color: #fff;
}
.ant-menu.ant-menu-dark .ant-menu-item-selected,
.ant-menu-submenu-popup.ant-menu-dark .ant-menu-item-selected {
  background-color: #00a6ff;
}
.ant-menu-dark .ant-menu-item-disabled,
.ant-menu-dark .ant-menu-submenu-disabled,
.ant-menu-dark .ant-menu-item-disabled > a,
.ant-menu-dark .ant-menu-submenu-disabled > a {
  color: rgba(255, 255, 255, 0.35) !important;
  opacity: 0.8;
}
.ant-menu-dark .ant-menu-item-disabled > .ant-menu-submenu-title,
.ant-menu-dark .ant-menu-submenu-disabled > .ant-menu-submenu-title {
  color: rgba(255, 255, 255, 0.35) !important;
}
.ant-menu-dark .ant-menu-item-disabled > .ant-menu-submenu-title > .ant-menu-submenu-arrow::before,
.ant-menu-dark .ant-menu-submenu-disabled > .ant-menu-submenu-title > .ant-menu-submenu-arrow::before,
.ant-menu-dark .ant-menu-item-disabled > .ant-menu-submenu-title > .ant-menu-submenu-arrow::after,
.ant-menu-dark .ant-menu-submenu-disabled > .ant-menu-submenu-title > .ant-menu-submenu-arrow::after {
  background: rgba(255, 255, 255, 0.35) !important;
}
.ant-message {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(34, 34, 34, 0.8);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: "tnum";
  position: fixed;
  top: 16px;
  left: 0;
  z-index: 1010;
  width: 100%;
  pointer-events: none;
}
.ant-message-notice {
  padding: 8px;
  text-align: center;
}
.ant-message-notice:first-child {
  margin-top: -8px;
}
.ant-message-notice-content {
  display: inline-block;
  padding: 10px 16px;
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 0 1px 1px rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.08), 0 2px 8px 2px rgba(0, 0, 0, 0.05);
  pointer-events: all;
}
.ant-message-success .anticon {
  color: #52c41a;
}
.ant-message-error .anticon {
  color: #ff4d4f;
}
.ant-message-warning .anticon {
  color: #faad14;
}
.ant-message-info .anticon,
.ant-message-loading .anticon {
  color: #1890ff;
}
.ant-message .anticon {
  position: relative;
  top: 1px;
  margin-right: 8px;
  font-size: 16px;
}
.ant-message-notice.move-up-leave.move-up-leave-active {
  overflow: hidden;
  animation-name: MessageMoveOut;
  animation-duration: 0.3s;
}
@keyframes MessageMoveOut {
  0% {
    max-height: 150px;
    padding: 8px;
    opacity: 1;
  }
  100% {
    max-height: 0;
    padding: 0;
    opacity: 0;
  }
}
.ant-modal {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(34, 34, 34, 0.8);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: "tnum";
  position: relative;
  top: 100px;
  width: auto;
  margin: 0 auto;
  padding-bottom: 24px;
  pointer-events: none;
}
.ant-modal-wrap {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  overflow: auto;
  outline: 0;
  -webkit-overflow-scrolling: touch;
}
.ant-modal-title {
  margin: 0;
  color: rgba(255, 255, 255, 0.85);
  font-weight: bold;
  font-size: 20px;
  text-align: center;
  line-height: 22px;
  word-wrap: break-word;
}
.ant-modal-content {
  position: relative;
  background-color: #fff;
  background-clip: padding-box;
  border: 0;
  border-radius: 10px;
  box-shadow: 0 0 1px 1px rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.08), 0 2px 8px 2px rgba(0, 0, 0, 0.05);
  pointer-events: auto;
}
.ant-modal-close {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 10;
  padding: 0;
  color: rgba(34, 34, 34, 0.45);
  font-weight: 700;
  line-height: 1;
  text-decoration: none;
  background: transparent;
  border: 0;
  outline: 0;
  cursor: pointer;
  transition: color 0.3s;
}
.ant-modal-close-x {
  display: block;
  width: 56px;
  height: 56px;
  font-size: 18px;
  font-style: normal;
  line-height: 56px;
  text-align: center;
  text-transform: none;
  text-rendering: auto;
}
.ant-modal-close:focus,
.ant-modal-close:hover {
  color: rgba(34, 34, 34, 0.75);
  text-decoration: none;
}
.ant-modal-header {
  padding: 16px 24px;
  color: rgba(34, 34, 34, 0.8);
  background: #00a6ff;
  border-radius: 10px 10px 0 0;
}
.ant-modal-body {
  padding: 0 24px 24px 24px;
  font-size: 14px;
  line-height: 1.5715;
  word-wrap: break-word;
}
.ant-modal-footer {
  padding: 10px 16px;
  text-align: right;
  background: transparent;
  border-top: 1px solid #fff;
  border-radius: 0 0 10px 10px;
}
.ant-modal-footer button + button {
  margin-bottom: 0;
  margin-left: 8px;
}
.ant-modal.zoom-enter,
.ant-modal.zoom-appear {
  transform: none;
  opacity: 0;
  animation-duration: 0.3s;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.ant-modal-mask {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  height: 100%;
  background-color: rgba(34, 34, 34, 0.45);
  filter: alpha(opacity=50);
}
.ant-modal-mask-hidden {
  display: none;
}
.ant-modal-open {
  overflow: hidden;
}
.ant-modal-centered {
  text-align: center;
}
.ant-modal-centered::before {
  display: inline-block;
  width: 0;
  height: 100%;
  vertical-align: middle;
  content: "";
}
.ant-modal-centered .ant-modal {
  top: 0;
  display: inline-block;
  text-align: left;
  vertical-align: middle;
}
@media (max-width: 767px) {
  .ant-modal {
    max-width: calc(100vw - 16px);
    margin: 8px auto;
  }
  .ant-modal-centered .ant-modal {
    flex: 1 1;
  }
}
.ant-modal-confirm .ant-modal-header {
  display: none;
}
.ant-modal-confirm .ant-modal-body {
  padding: 32px 32px 24px;
}
.ant-modal-confirm-body-wrapper {
  zoom: 1;
}
.ant-modal-confirm-body-wrapper::before,
.ant-modal-confirm-body-wrapper::after {
  display: table;
  content: '';
}
.ant-modal-confirm-body-wrapper::after {
  clear: both;
}
.ant-modal-confirm-body-wrapper::before,
.ant-modal-confirm-body-wrapper::after {
  display: table;
  content: '';
}
.ant-modal-confirm-body-wrapper::after {
  clear: both;
}
.ant-modal-confirm-body .ant-modal-confirm-title {
  display: block;
  overflow: hidden;
  color: rgba(255, 255, 255, 0.85);
  line-height: 1.4;
  font-weight: bold;
  font-size: 20px;
  text-align: center;
}
.ant-modal-confirm-body .ant-modal-confirm-content {
  margin-top: 32px;
  padding-bottom: 10px;
  text-align: center;
  color: rgba(34, 34, 34, 0.8);
  font-size: 14px;
}
.ant-modal-confirm-body > .anticon {
  float: left;
  margin-right: 16px;
  font-size: 22px;
}
.ant-modal-confirm .ant-modal-confirm-btns {
  margin-top: 24px;
}
.ant-modal-confirm .ant-modal-confirm-btns .ant-btn {
  width: 47%;
  height: 40px;
}
.ant-modal-confirm .ant-modal-confirm-btns button + button {
  margin-bottom: 0;
  margin-left: 20px;
}
.ant-modal-confirm-error .ant-modal-confirm-body > .anticon {
  color: #ff4d4f;
}
.ant-modal-confirm-warning .ant-modal-confirm-body > .anticon,
.ant-modal-confirm-confirm .ant-modal-confirm-body > .anticon {
  color: #faad14;
}
.ant-modal-confirm-info .ant-btn {
  width: 100% !important;
}
.ant-modal-confirm-info .ant-modal-confirm-body > .anticon {
  color: #1890ff;
}
.ant-modal-confirm-success .ant-modal-confirm-body > .anticon {
  color: #52c41a;
}
.ant-notification {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(34, 34, 34, 0.8);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: "tnum";
  position: fixed;
  z-index: 1010;
  width: 384px;
  max-width: calc(100vw - 32px);
  margin-right: 24px;
}
.ant-notification-topLeft,
.ant-notification-bottomLeft {
  margin-right: 0;
  margin-left: 24px;
}
.ant-notification-topLeft .ant-notification-fade-enter.ant-notification-fade-enter-active,
.ant-notification-bottomLeft .ant-notification-fade-enter.ant-notification-fade-enter-active,
.ant-notification-topLeft .ant-notification-fade-appear.ant-notification-fade-appear-active,
.ant-notification-bottomLeft .ant-notification-fade-appear.ant-notification-fade-appear-active {
  animation-name: NotificationLeftFadeIn;
}
.ant-notification-close-icon {
  font-size: 14px;
  cursor: pointer;
}
.ant-notification-notice {
  position: relative;
  margin-bottom: 16px;
  padding: 16px 24px;
  overflow: hidden;
  line-height: 1.5;
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 0 1px 1px rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.08), 0 2px 8px 2px rgba(0, 0, 0, 0.05);
}
.ant-notification-notice-message {
  display: inline-block;
  margin-bottom: 8px;
  color: rgba(255, 255, 255, 0.85);
  font-size: 14px;
  line-height: 24px;
}
.ant-notification-notice-message-single-line-auto-margin {
  display: block;
  width: calc(384px - 24px * 2 - 24px - 48px - 100%);
  max-width: 4px;
  background-color: transparent;
  pointer-events: none;
}
.ant-notification-notice-message-single-line-auto-margin::before {
  display: block;
  content: '';
}
.ant-notification-notice-description {
  font-size: 14px;
}
.ant-notification-notice-closable .ant-notification-notice-message {
  padding-right: 24px;
}
.ant-notification-notice-with-icon .ant-notification-notice-message {
  margin-bottom: 4px;
  margin-left: 48px;
  font-size: 14px;
}
.ant-notification-notice-with-icon .ant-notification-notice-description {
  margin-left: 48px;
  font-size: 14px;
}
.ant-notification-notice-icon {
  position: absolute;
  margin-left: 4px;
  font-size: 24px;
  line-height: 24px;
}
.anticon.ant-notification-notice-icon-success {
  color: #52c41a;
}
.anticon.ant-notification-notice-icon-info {
  color: #1890ff;
}
.anticon.ant-notification-notice-icon-warning {
  color: #faad14;
}
.anticon.ant-notification-notice-icon-error {
  color: #ff4d4f;
}
.ant-notification-notice-close {
  position: absolute;
  top: 16px;
  right: 22px;
  color: rgba(34, 34, 34, 0.45);
  outline: none;
}
.ant-notification-notice-close:hover {
  color: rgba(10, 10, 10, 0.67);
}
.ant-notification-notice-btn {
  float: right;
  margin-top: 16px;
}
.ant-notification .notification-fade-effect {
  animation-duration: 0.24s;
  animation-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
  animation-fill-mode: both;
}
.ant-notification-fade-enter,
.ant-notification-fade-appear {
  opacity: 0;
  animation-duration: 0.24s;
  animation-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
  animation-fill-mode: both;
  animation-play-state: paused;
}
.ant-notification-fade-leave {
  animation-duration: 0.24s;
  animation-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
  animation-fill-mode: both;
  animation-duration: 0.2s;
  animation-play-state: paused;
}
.ant-notification-fade-enter.ant-notification-fade-enter-active,
.ant-notification-fade-appear.ant-notification-fade-appear-active {
  animation-name: NotificationFadeIn;
  animation-play-state: running;
}
.ant-notification-fade-leave.ant-notification-fade-leave-active {
  animation-name: NotificationFadeOut;
  animation-play-state: running;
}
@keyframes NotificationFadeIn {
  0% {
    left: 384px;
    opacity: 0;
  }
  100% {
    left: 0;
    opacity: 1;
  }
}
@keyframes NotificationLeftFadeIn {
  0% {
    right: 384px;
    opacity: 0;
  }
  100% {
    right: 0;
    opacity: 1;
  }
}
@keyframes NotificationFadeOut {
  0% {
    max-height: 150px;
    margin-bottom: 16px;
    padding-top: 16px 24px;
    padding-bottom: 16px 24px;
    opacity: 1;
  }
  100% {
    max-height: 0;
    margin-bottom: 0;
    padding-top: 0;
    padding-bottom: 0;
    opacity: 0;
  }
}
.ant-page-header {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(34, 34, 34, 0.8);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: "tnum";
  position: relative;
  padding: 16px 24px;
  background-color: #fff;
}
.ant-page-header-ghost {
  background-color: inherit;
}
.ant-page-header.has-breadcrumb {
  padding-top: 12px;
}
.ant-page-header.has-footer {
  padding-bottom: 0;
}
.ant-page-header-back {
  float: left;
  margin: 8px 0;
  margin-right: 16px;
  font-size: 16px;
  line-height: 1;
}
.ant-page-header-back-button {
  color: #1c8eff;
  text-decoration: none;
  outline: none;
  transition: color 0.3s;
  color: #000;
  cursor: pointer;
}
.ant-page-header-back-button:focus,
.ant-page-header-back-button:hover {
  color: #45a8ff;
}
.ant-page-header-back-button:active {
  color: #0d6cd9;
}
.ant-page-header .ant-divider-vertical {
  height: 14px;
  margin: 0 12px;
  vertical-align: middle;
}
.ant-breadcrumb + .ant-page-header-heading {
  margin-top: 8px;
}
.ant-page-header-heading {
  width: 100%;
  overflow: hidden;
}
.ant-page-header-heading-title {
  display: block;
  float: left;
  margin-bottom: 0;
  padding-right: 12px;
  color: rgba(255, 255, 255, 0.85);
  font-weight: 600;
  font-size: 20px;
  line-height: 32px;
}
.ant-page-header-heading .ant-avatar {
  float: left;
  margin-right: 12px;
}
.ant-page-header-heading-sub-title {
  float: left;
  margin: 5px 0;
  margin-right: 12px;
  color: rgba(34, 34, 34, 0.45);
  font-size: 14px;
  line-height: 22px;
}
.ant-page-header-heading-tags {
  float: left;
  margin: 4px 0;
}
.ant-page-header-heading-extra {
  float: right;
}
.ant-page-header-heading-extra > * {
  margin-left: 8px;
}
.ant-page-header-heading-extra > *:first-child {
  margin-left: 0;
}
.ant-page-header-content {
  padding-top: 12px;
  overflow: hidden;
}
.ant-page-header-footer {
  margin-top: 16px;
}
.ant-page-header-footer .ant-tabs-bar {
  margin-bottom: 1px;
  border-bottom: 0;
}
.ant-page-header-footer .ant-tabs-bar .ant-tabs-nav .ant-tabs-tab {
  padding: 8px;
  font-size: 16px;
}
@media (max-width: 576px) {
  .ant-page-header-heading-extra {
    display: block;
    float: unset;
    width: 100%;
    padding-top: 12px;
    overflow: hidden;
  }
}
.ant-pagination {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(34, 34, 34, 0.8);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: "tnum";
}
.ant-pagination ul,
.ant-pagination ol {
  margin: 0;
  padding: 0;
  list-style: none;
}
.ant-pagination::after {
  display: block;
  clear: both;
  height: 0;
  overflow: hidden;
  visibility: hidden;
  content: ' ';
}
.ant-pagination-total-text {
  display: inline-block;
  height: 32px;
  margin-right: 8px;
  line-height: 30px;
  vertical-align: middle;
}
.ant-pagination-item {
  display: inline-block;
  min-width: 32px;
  height: 32px;
  margin-right: 8px;
  font-family: Arial;
  line-height: 30px;
  text-align: center;
  vertical-align: middle;
  list-style: none;
  background-color: #fff;
  border: 1px solid #bfbfbf;
  border-radius: 10px;
  outline: 0;
  cursor: pointer;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.ant-pagination-item a {
  display: block;
  padding: 0 6px;
  color: rgba(34, 34, 34, 0.8);
  transition: none;
}
.ant-pagination-item a:hover {
  text-decoration: none;
}
.ant-pagination-item:focus,
.ant-pagination-item:hover {
  border-color: #00a6ff;
  transition: all 0.3s;
}
.ant-pagination-item:focus a,
.ant-pagination-item:hover a {
  color: #00a6ff;
}
.ant-pagination-item-active {
  font-weight: 500;
  background: #fff;
  border-color: #00a6ff;
}
.ant-pagination-item-active a {
  color: #00a6ff;
}
.ant-pagination-item-active:focus,
.ant-pagination-item-active:hover {
  border-color: #29bbff;
}
.ant-pagination-item-active:focus a,
.ant-pagination-item-active:hover a {
  color: #29bbff;
}
.ant-pagination-jump-prev,
.ant-pagination-jump-next {
  outline: 0;
}
.ant-pagination-jump-prev .ant-pagination-item-container,
.ant-pagination-jump-next .ant-pagination-item-container {
  position: relative;
}
.ant-pagination-jump-prev .ant-pagination-item-container .ant-pagination-item-link-icon,
.ant-pagination-jump-next .ant-pagination-item-container .ant-pagination-item-link-icon {
  display: inline-block;
  font-size: 12px;
  font-size: 12px \9;
  transform: scale(1) rotate(0deg);
  color: #00a6ff;
  letter-spacing: -1px;
  opacity: 0;
  transition: all 0.2s;
}
:root .ant-pagination-jump-prev .ant-pagination-item-container .ant-pagination-item-link-icon,
:root .ant-pagination-jump-next .ant-pagination-item-container .ant-pagination-item-link-icon {
  font-size: 12px;
}
.ant-pagination-jump-prev .ant-pagination-item-container .ant-pagination-item-link-icon-svg,
.ant-pagination-jump-next .ant-pagination-item-container .ant-pagination-item-link-icon-svg {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
}
.ant-pagination-jump-prev .ant-pagination-item-container .ant-pagination-item-ellipsis,
.ant-pagination-jump-next .ant-pagination-item-container .ant-pagination-item-ellipsis {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: block;
  margin: auto;
  color: #fff;
  letter-spacing: 2px;
  text-align: center;
  text-indent: 0.13em;
  opacity: 1;
  transition: all 0.2s;
}
.ant-pagination-jump-prev:focus .ant-pagination-item-link-icon,
.ant-pagination-jump-next:focus .ant-pagination-item-link-icon,
.ant-pagination-jump-prev:hover .ant-pagination-item-link-icon,
.ant-pagination-jump-next:hover .ant-pagination-item-link-icon {
  opacity: 1;
}
.ant-pagination-jump-prev:focus .ant-pagination-item-ellipsis,
.ant-pagination-jump-next:focus .ant-pagination-item-ellipsis,
.ant-pagination-jump-prev:hover .ant-pagination-item-ellipsis,
.ant-pagination-jump-next:hover .ant-pagination-item-ellipsis {
  opacity: 0;
}
.ant-pagination-prev,
.ant-pagination-jump-prev,
.ant-pagination-jump-next {
  margin-right: 8px;
}
.ant-pagination-prev,
.ant-pagination-next,
.ant-pagination-jump-prev,
.ant-pagination-jump-next {
  display: inline-block;
  min-width: 32px;
  height: 32px;
  color: rgba(34, 34, 34, 0.8);
  font-family: Arial;
  line-height: 32px;
  text-align: center;
  vertical-align: middle;
  list-style: none;
  border-radius: 10px;
  cursor: pointer;
  transition: all 0.3s;
}
.ant-pagination-prev,
.ant-pagination-next {
  outline: 0;
}
.ant-pagination-prev a,
.ant-pagination-next a {
  color: rgba(34, 34, 34, 0.8);
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.ant-pagination-prev:hover a,
.ant-pagination-next:hover a {
  border-color: #29bbff;
}
.ant-pagination-prev .ant-pagination-item-link,
.ant-pagination-next .ant-pagination-item-link {
  display: block;
  height: 100%;
  font-size: 12px;
  text-align: center;
  background-color: #fff;
  border: 1px solid #bfbfbf;
  border-radius: 10px;
  outline: none;
  transition: all 0.3s;
}
.ant-pagination-prev:focus .ant-pagination-item-link,
.ant-pagination-next:focus .ant-pagination-item-link,
.ant-pagination-prev:hover .ant-pagination-item-link,
.ant-pagination-next:hover .ant-pagination-item-link {
  color: #00a6ff;
  border-color: #00a6ff;
}
.ant-pagination-disabled,
.ant-pagination-disabled:hover,
.ant-pagination-disabled:focus {
  cursor: not-allowed;
}
.ant-pagination-disabled a,
.ant-pagination-disabled:hover a,
.ant-pagination-disabled:focus a,
.ant-pagination-disabled .ant-pagination-item-link,
.ant-pagination-disabled:hover .ant-pagination-item-link,
.ant-pagination-disabled:focus .ant-pagination-item-link {
  color: #fff;
  border-color: #bfbfbf;
  cursor: not-allowed;
}
.ant-pagination-slash {
  margin: 0 10px 0 5px;
}
.ant-pagination-options {
  display: inline-block;
  margin-left: 16px;
  vertical-align: middle;
}
.ant-pagination-options-size-changer.ant-select {
  display: inline-block;
  width: auto;
  margin-right: 8px;
}
.ant-pagination-options-quick-jumper {
  display: inline-block;
  height: 32px;
  line-height: 32px;
  vertical-align: top;
}
.ant-pagination-options-quick-jumper input {
  position: relative;
  display: inline-block;
  width: 100%;
  height: 32px;
  padding: 4px 11px;
  color: rgba(34, 34, 34, 0.8);
  font-size: 14px;
  line-height: 1.5715;
  background-color: #fff;
  background-image: none;
  border: 1px solid #bfbfbf;
  border-radius: 10px;
  transition: all 0.3s;
  width: 50px;
  margin: 0 8px;
}
.ant-pagination-options-quick-jumper input::-moz-placeholder {
  color: #bfbfbf;
  opacity: 1;
}
.ant-pagination-options-quick-jumper input:-ms-input-placeholder {
  color: #bfbfbf;
}
.ant-pagination-options-quick-jumper input::-webkit-input-placeholder {
  color: #bfbfbf;
}
.ant-pagination-options-quick-jumper input:-moz-placeholder-shown {
  text-overflow: ellipsis;
}
.ant-pagination-options-quick-jumper input:-ms-input-placeholder {
  text-overflow: ellipsis;
}
.ant-pagination-options-quick-jumper input:placeholder-shown {
  text-overflow: ellipsis;
}
.ant-pagination-options-quick-jumper input:hover {
  border-color: #333;
  border-right-width: 1px !important;
}
.ant-pagination-options-quick-jumper input:focus {
  border-color: #666060;
  border-right-width: 1px !important;
  outline: 0;
  box-shadow: 0 0 0 1px rgba(89, 89, 89, 0.2);
}
.ant-pagination-options-quick-jumper input-disabled {
  color: #fff;
  background-color: #cccccc;
  cursor: not-allowed;
  opacity: 1;
}
.ant-pagination-options-quick-jumper input-disabled:hover {
  border-color: #bfbfbf;
  border-right-width: 1px !important;
}
.ant-pagination-options-quick-jumper input[disabled] {
  color: #fff;
  background-color: #cccccc;
  cursor: not-allowed;
  opacity: 1;
}
.ant-pagination-options-quick-jumper input[disabled]:hover {
  border-color: #bfbfbf;
  border-right-width: 1px !important;
}
textarea.ant-pagination-options-quick-jumper input {
  max-width: 100%;
  height: auto;
  min-height: 32px;
  line-height: 1.5715;
  vertical-align: bottom;
  transition: all 0.3s, height 0s;
}
.ant-pagination-options-quick-jumper input-lg {
  height: 45px;
  padding: 10.5px 11px;
  font-size: 14px;
}
.ant-pagination-options-quick-jumper input-sm {
  height: 24px;
  padding: 0px 7px;
}
.ant-pagination-simple .ant-pagination-prev,
.ant-pagination-simple .ant-pagination-next {
  height: 24px;
  line-height: 24px;
  vertical-align: top;
}
.ant-pagination-simple .ant-pagination-prev .ant-pagination-item-link,
.ant-pagination-simple .ant-pagination-next .ant-pagination-item-link {
  height: 24px;
  border: 0;
}
.ant-pagination-simple .ant-pagination-prev .ant-pagination-item-link::after,
.ant-pagination-simple .ant-pagination-next .ant-pagination-item-link::after {
  height: 24px;
  line-height: 24px;
}
.ant-pagination-simple .ant-pagination-simple-pager {
  display: inline-block;
  height: 24px;
  margin-right: 8px;
}
.ant-pagination-simple .ant-pagination-simple-pager input {
  box-sizing: border-box;
  height: 100%;
  margin-right: 8px;
  padding: 0 6px;
  text-align: center;
  background-color: #fff;
  border: 1px solid #bfbfbf;
  border-radius: 10px;
  outline: none;
  transition: border-color 0.3s;
}
.ant-pagination-simple .ant-pagination-simple-pager input:hover {
  border-color: #00a6ff;
}
.ant-pagination.mini .ant-pagination-total-text,
.ant-pagination.mini .ant-pagination-simple-pager {
  height: 24px;
  line-height: 24px;
}
.ant-pagination.mini .ant-pagination-item {
  min-width: 24px;
  height: 24px;
  margin: 0;
  line-height: 22px;
}
.ant-pagination.mini .ant-pagination-item:not(.ant-pagination-item-active) {
  background: transparent;
  border-color: transparent;
}
.ant-pagination.mini .ant-pagination-prev,
.ant-pagination.mini .ant-pagination-next {
  min-width: 24px;
  height: 24px;
  margin: 0;
  line-height: 24px;
}
.ant-pagination.mini .ant-pagination-prev .ant-pagination-item-link,
.ant-pagination.mini .ant-pagination-next .ant-pagination-item-link {
  background: transparent;
  border-color: transparent;
}
.ant-pagination.mini .ant-pagination-prev .ant-pagination-item-link::after,
.ant-pagination.mini .ant-pagination-next .ant-pagination-item-link::after {
  height: 24px;
  line-height: 24px;
}
.ant-pagination.mini .ant-pagination-jump-prev,
.ant-pagination.mini .ant-pagination-jump-next {
  height: 24px;
  margin-right: 0;
  line-height: 24px;
}
.ant-pagination.mini .ant-pagination-options {
  margin-left: 2px;
}
.ant-pagination.mini .ant-pagination-options-quick-jumper {
  height: 24px;
  line-height: 24px;
}
.ant-pagination.mini .ant-pagination-options-quick-jumper input {
  height: 24px;
  padding: 0px 7px;
  width: 44px;
}
.ant-pagination.ant-pagination-disabled {
  cursor: not-allowed;
}
.ant-pagination.ant-pagination-disabled .ant-pagination-item {
  background: #cccccc;
  border-color: #bfbfbf;
  cursor: not-allowed;
}
.ant-pagination.ant-pagination-disabled .ant-pagination-item a {
  color: #fff;
  background: transparent;
  border: none;
  cursor: not-allowed;
}
.ant-pagination.ant-pagination-disabled .ant-pagination-item-active {
  background: #b3b3b3;
  border-color: transparent;
}
.ant-pagination.ant-pagination-disabled .ant-pagination-item-active a {
  color: #fff;
}
.ant-pagination.ant-pagination-disabled .ant-pagination-item-link,
.ant-pagination.ant-pagination-disabled .ant-pagination-item-link:hover,
.ant-pagination.ant-pagination-disabled .ant-pagination-item-link:focus {
  color: rgba(34, 34, 34, 0.45);
  background: #cccccc;
  border-color: #bfbfbf;
  cursor: not-allowed;
}
.ant-pagination.ant-pagination-disabled .ant-pagination-jump-prev:focus .ant-pagination-item-link-icon,
.ant-pagination.ant-pagination-disabled .ant-pagination-jump-next:focus .ant-pagination-item-link-icon,
.ant-pagination.ant-pagination-disabled .ant-pagination-jump-prev:hover .ant-pagination-item-link-icon,
.ant-pagination.ant-pagination-disabled .ant-pagination-jump-next:hover .ant-pagination-item-link-icon {
  opacity: 0;
}
.ant-pagination.ant-pagination-disabled .ant-pagination-jump-prev:focus .ant-pagination-item-ellipsis,
.ant-pagination.ant-pagination-disabled .ant-pagination-jump-next:focus .ant-pagination-item-ellipsis,
.ant-pagination.ant-pagination-disabled .ant-pagination-jump-prev:hover .ant-pagination-item-ellipsis,
.ant-pagination.ant-pagination-disabled .ant-pagination-jump-next:hover .ant-pagination-item-ellipsis {
  opacity: 1;
}
@media only screen and (max-width: 992px) {
  .ant-pagination-item-after-jump-prev,
  .ant-pagination-item-before-jump-next {
    display: none;
  }
}
@media only screen and (max-width: 576px) {
  .ant-pagination-options {
    display: none;
  }
}
.ant-popover {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(34, 34, 34, 0.8);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: "tnum";
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1030;
  font-weight: normal;
  white-space: normal;
  text-align: left;
  cursor: auto;
  -webkit-user-select: text;
     -moz-user-select: text;
      -ms-user-select: text;
          user-select: text;
}
.ant-popover::after {
  position: absolute;
  background: rgba(255, 255, 255, 0.01);
  content: '';
}
.ant-popover-hidden {
  display: none;
}
.ant-popover-placement-top,
.ant-popover-placement-topLeft,
.ant-popover-placement-topRight {
  padding-bottom: 6px;
}
.ant-popover-placement-right,
.ant-popover-placement-rightTop,
.ant-popover-placement-rightBottom {
  padding-left: 6px;
}
.ant-popover-placement-bottom,
.ant-popover-placement-bottomLeft,
.ant-popover-placement-bottomRight {
  padding-top: 6px;
}
.ant-popover-placement-left,
.ant-popover-placement-leftTop,
.ant-popover-placement-leftBottom {
  padding-right: 6px;
}
.ant-popover-inner {
  background-color: #fff;
  background-clip: padding-box;
  border-radius: 10px;
  box-shadow: 0 0 1px 1px rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.08), 0 2px 8px 2px rgba(0, 0, 0, 0.05);
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.15) \9;
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .ant-popover {
    /* IE10+ */
  }
  .ant-popover-inner {
    box-shadow: 0 0 1px 1px rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.08), 0 2px 8px 2px rgba(0, 0, 0, 0.05);
  }
}
.ant-popover-title {
  min-width: 177px;
  min-height: 32px;
  margin: 0;
  padding: 5px 16px 4px;
  color: rgba(255, 255, 255, 0.85);
  font-weight: 500;
  border-bottom: 1px solid #f0f0f0;
}
.ant-popover-inner-content {
  padding: 12px 16px;
  color: rgba(34, 34, 34, 0.8);
}
.ant-popover-message {
  position: relative;
  padding: 4px 0 12px;
  color: rgba(34, 34, 34, 0.8);
  font-size: 14px;
}
.ant-popover-message > .anticon {
  position: absolute;
  top: 8px;
  color: #faad14;
  font-size: 14px;
}
.ant-popover-message-title {
  padding-left: 22px;
}
.ant-popover-buttons {
  margin-bottom: 4px;
  text-align: right;
}
.ant-popover-buttons button {
  margin-left: 8px;
}
.ant-popover-arrow {
  position: absolute;
  display: block;
  width: 8.48528137px;
  height: 8.48528137px;
  background: transparent;
  border-style: solid;
  border-width: 4.24264069px;
  transform: rotate(45deg);
}
.ant-popover-placement-top > .ant-popover-content > .ant-popover-arrow,
.ant-popover-placement-topLeft > .ant-popover-content > .ant-popover-arrow,
.ant-popover-placement-topRight > .ant-popover-content > .ant-popover-arrow {
  bottom: 2.2px;
  border-top-color: transparent;
  border-right-color: #fff;
  border-bottom-color: #fff;
  border-left-color: transparent;
  box-shadow: 3px 3px 7px rgba(34, 34, 34, 0.07);
}
.ant-popover-placement-top > .ant-popover-content > .ant-popover-arrow {
  left: 50%;
  transform: translateX(-50%) rotate(45deg);
}
.ant-popover-placement-topLeft > .ant-popover-content > .ant-popover-arrow {
  left: 16px;
}
.ant-popover-placement-topRight > .ant-popover-content > .ant-popover-arrow {
  right: 16px;
}
.ant-popover-placement-right > .ant-popover-content > .ant-popover-arrow,
.ant-popover-placement-rightTop > .ant-popover-content > .ant-popover-arrow,
.ant-popover-placement-rightBottom > .ant-popover-content > .ant-popover-arrow {
  left: 2px;
  border-top-color: transparent;
  border-right-color: transparent;
  border-bottom-color: #fff;
  border-left-color: #fff;
  box-shadow: -3px 3px 7px rgba(34, 34, 34, 0.07);
}
.ant-popover-placement-right > .ant-popover-content > .ant-popover-arrow {
  top: 50%;
  transform: translateY(-50%) rotate(45deg);
}
.ant-popover-placement-rightTop > .ant-popover-content > .ant-popover-arrow {
  top: 12px;
}
.ant-popover-placement-rightBottom > .ant-popover-content > .ant-popover-arrow {
  bottom: 12px;
}
.ant-popover-placement-bottom > .ant-popover-content > .ant-popover-arrow,
.ant-popover-placement-bottomLeft > .ant-popover-content > .ant-popover-arrow,
.ant-popover-placement-bottomRight > .ant-popover-content > .ant-popover-arrow {
  top: 2px;
  border-top-color: #fff;
  border-right-color: transparent;
  border-bottom-color: transparent;
  border-left-color: #fff;
  box-shadow: -2px -2px 5px rgba(34, 34, 34, 0.06);
}
.ant-popover-placement-bottom > .ant-popover-content > .ant-popover-arrow {
  left: 50%;
  transform: translateX(-50%) rotate(45deg);
}
.ant-popover-placement-bottomLeft > .ant-popover-content > .ant-popover-arrow {
  left: 16px;
}
.ant-popover-placement-bottomRight > .ant-popover-content > .ant-popover-arrow {
  right: 16px;
}
.ant-popover-placement-left > .ant-popover-content > .ant-popover-arrow,
.ant-popover-placement-leftTop > .ant-popover-content > .ant-popover-arrow,
.ant-popover-placement-leftBottom > .ant-popover-content > .ant-popover-arrow {
  right: 2px;
  border-top-color: #fff;
  border-right-color: #fff;
  border-bottom-color: transparent;
  border-left-color: transparent;
  box-shadow: 3px -3px 7px rgba(34, 34, 34, 0.07);
}
.ant-popover-placement-left > .ant-popover-content > .ant-popover-arrow {
  top: 50%;
  transform: translateY(-50%) rotate(45deg);
}
.ant-popover-placement-leftTop > .ant-popover-content > .ant-popover-arrow {
  top: 12px;
}
.ant-popover-placement-leftBottom > .ant-popover-content > .ant-popover-arrow {
  bottom: 12px;
}
.ant-progress {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(34, 34, 34, 0.8);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: "tnum";
  display: inline-block;
}
.ant-progress-line {
  position: relative;
  width: 100%;
  font-size: 14px;
}
.ant-progress-small.ant-progress-line,
.ant-progress-small.ant-progress-line .ant-progress-text .anticon {
  font-size: 12px;
}
.ant-progress-outer {
  display: inline-block;
  width: 100%;
  margin-right: 0;
  padding-right: 0;
}
.ant-progress-show-info .ant-progress-outer {
  margin-right: calc(-2em - 8px);
  padding-right: calc(2em + 8px);
}
.ant-progress-inner {
  position: relative;
  display: inline-block;
  width: 100%;
  overflow: hidden;
  vertical-align: middle;
  background-color: #cccccc;
  border-radius: 100px;
}
.ant-progress-circle-trail {
  stroke: #cccccc;
}
.ant-progress-circle-path {
  animation: ant-progress-appear 0.3s;
}
.ant-progress-inner:not(.ant-progress-circle-gradient) .ant-progress-circle-path {
  stroke: #1890ff;
}
.ant-progress-success-bg,
.ant-progress-bg {
  position: relative;
  background-color: #1890ff;
  border-radius: 100px;
  transition: all 0.4s cubic-bezier(0.08, 0.82, 0.17, 1) 0s;
}
.ant-progress-success-bg {
  position: absolute;
  top: 0;
  left: 0;
  background-color: #52c41a;
}
.ant-progress-text {
  display: inline-block;
  width: 2em;
  margin-left: 8px;
  color: rgba(34, 34, 34, 0.45);
  font-size: 1em;
  line-height: 1;
  white-space: nowrap;
  text-align: left;
  vertical-align: middle;
  word-break: normal;
}
.ant-progress-text .anticon {
  font-size: 14px;
}
.ant-progress-status-active .ant-progress-bg::before {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: #fff;
  border-radius: 10px;
  opacity: 0;
  animation: ant-progress-active 2.4s cubic-bezier(0.23, 1, 0.32, 1) infinite;
  content: '';
}
.ant-progress-status-exception .ant-progress-bg {
  background-color: #ff4d4f;
}
.ant-progress-status-exception .ant-progress-text {
  color: #ff4d4f;
}
.ant-progress-status-exception .ant-progress-inner:not(.ant-progress-circle-gradient) .ant-progress-circle-path {
  stroke: #ff4d4f;
}
.ant-progress-status-success .ant-progress-bg {
  background-color: #52c41a;
}
.ant-progress-status-success .ant-progress-text {
  color: #52c41a;
}
.ant-progress-status-success .ant-progress-inner:not(.ant-progress-circle-gradient) .ant-progress-circle-path {
  stroke: #52c41a;
}
.ant-progress-circle .ant-progress-inner {
  position: relative;
  line-height: 1;
  background-color: transparent;
}
.ant-progress-circle .ant-progress-text {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  margin: 0;
  padding: 0;
  color: rgba(34, 34, 34, 0.8);
  line-height: 1;
  white-space: normal;
  text-align: center;
  transform: translate(-50%, -50%);
}
.ant-progress-circle .ant-progress-text .anticon {
  font-size: 1.16666667em;
}
.ant-progress-circle.ant-progress-status-exception .ant-progress-text {
  color: #ff4d4f;
}
.ant-progress-circle.ant-progress-status-success .ant-progress-text {
  color: #52c41a;
}
@keyframes ant-progress-active {
  0% {
    width: 0;
    opacity: 0.1;
  }
  20% {
    width: 0;
    opacity: 0.5;
  }
  100% {
    width: 100%;
    opacity: 0;
  }
}
.ant-radio-group {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(34, 34, 34, 0.8);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: "tnum";
  display: inline-block;
}
.ant-radio-wrapper {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(34, 34, 34, 0.8);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: "tnum";
  position: relative;
  display: inline-block;
  margin-right: 8px;
  white-space: nowrap;
  cursor: pointer;
}
.ant-radio {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(34, 34, 34, 0.8);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: "tnum";
  position: relative;
  display: inline-block;
  line-height: 1;
  white-space: nowrap;
  vertical-align: sub;
  outline: none;
  cursor: pointer;
}
.ant-radio-wrapper:hover .ant-radio,
.ant-radio:hover .ant-radio-inner,
.ant-radio-input:focus + .ant-radio-inner {
  border-color: #00a6ff;
}
.ant-radio-input:focus + .ant-radio-inner {
  box-shadow: 0 0 0 3px rgba(0, 166, 255, 0.08);
}
.ant-radio-checked::after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 1px solid #00a6ff;
  border-radius: 50%;
  visibility: hidden;
  animation: antRadioEffect 0.36s ease-in-out;
  animation-fill-mode: both;
  content: '';
}
.ant-radio:hover::after,
.ant-radio-wrapper:hover .ant-radio::after {
  visibility: visible;
}
.ant-radio-inner {
  position: relative;
  top: 0;
  left: 0;
  display: block;
  width: 16px;
  height: 16px;
  background-color: #fff;
  border-color: #bfbfbf;
  border-style: solid;
  border-width: 1px;
  border-radius: 100px;
  transition: all 0.3s;
}
.ant-radio-inner::after {
  position: absolute;
  top: 3px;
  left: 3px;
  display: table;
  width: 8px;
  height: 8px;
  background-color: #00a6ff;
  border-top: 0;
  border-left: 0;
  border-radius: 8px;
  transform: scale(0);
  opacity: 0;
  transition: all 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
  content: ' ';
}
.ant-radio-input {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  cursor: pointer;
  opacity: 0;
}
.ant-radio-checked .ant-radio-inner {
  border-color: #00a6ff;
}
.ant-radio-checked .ant-radio-inner::after {
  transform: scale(1);
  opacity: 1;
  transition: all 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
}
.ant-radio-disabled .ant-radio-inner {
  background-color: #cccccc;
  border-color: #bfbfbf !important;
  cursor: not-allowed;
}
.ant-radio-disabled .ant-radio-inner::after {
  background-color: rgba(34, 34, 34, 0.2);
}
.ant-radio-disabled .ant-radio-input {
  cursor: not-allowed;
}
.ant-radio-disabled + span {
  color: #fff;
  cursor: not-allowed;
}
span.ant-radio + * {
  padding-right: 8px;
  padding-left: 8px;
}
.ant-radio-button-wrapper {
  position: relative;
  display: inline-block;
  height: 32px;
  margin: 0;
  padding: 0 15px;
  color: #666;
  line-height: 30px;
  background: #fff;
  border: 1px solid #bfbfbf;
  border-top-width: 1.02px;
  border-left: 0;
  cursor: pointer;
  transition: color 0.3s, background 0.3s, border-color 0.3s;
}
.ant-radio-button-wrapper a {
  color: #666;
}
.ant-radio-button-wrapper > .ant-radio-button {
  display: block;
  width: 0;
  height: 0;
  margin-left: 0;
}
.ant-radio-group-large .ant-radio-button-wrapper {
  height: 45px;
  font-size: 14px;
  line-height: 43px;
}
.ant-radio-group-small .ant-radio-button-wrapper {
  height: 24px;
  padding: 0 7px;
  line-height: 22px;
}
.ant-radio-button-wrapper:not(:first-child)::before {
  position: absolute;
  top: 0;
  left: -1px;
  display: block;
  width: 1px;
  height: 100%;
  background-color: #bfbfbf;
  content: '';
}
.ant-radio-button-wrapper:first-child {
  border-left: 1px solid #bfbfbf;
  border-radius: 10px 0 0 10px;
}
.ant-radio-button-wrapper:last-child {
  border-radius: 0 10px 10px 0;
}
.ant-radio-button-wrapper:first-child:last-child {
  border-radius: 10px;
}
.ant-radio-button-wrapper:hover {
  position: relative;
  color: #00a6ff;
}
.ant-radio-button-wrapper:focus-within {
  outline: 3px solid rgba(0, 166, 255, 0.06);
}
.ant-radio-button-wrapper .ant-radio-inner,
.ant-radio-button-wrapper input[type='checkbox'],
.ant-radio-button-wrapper input[type='radio'] {
  width: 0;
  height: 0;
  opacity: 0;
  pointer-events: none;
}
.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  z-index: 1;
  color: #00a6ff;
  background: #fff;
  border-color: #00a6ff;
  box-shadow: -1px 0 0 0 #00a6ff;
}
.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before {
  background-color: #00a6ff !important;
  opacity: 0.1;
}
.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):first-child {
  border-color: #00a6ff;
  box-shadow: none !important;
}
.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover {
  color: #29bbff;
  border-color: #29bbff;
  box-shadow: -1px 0 0 0 #29bbff;
}
.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):active {
  color: #0086d9;
  border-color: #0086d9;
  box-shadow: -1px 0 0 0 #0086d9;
}
.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):focus-within {
  outline: 3px solid rgba(0, 166, 255, 0.06);
}
.ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  color: #fff;
  background: #00a6ff;
  border-color: #00a6ff;
}
.ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover {
  color: #fff;
  background: #29bbff;
  border-color: #29bbff;
}
.ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):active {
  color: #fff;
  background: #0086d9;
  border-color: #0086d9;
}
.ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):focus-within {
  outline: 3px solid rgba(0, 166, 255, 0.06);
}
.ant-radio-button-wrapper-disabled {
  color: #fff;
  background-color: #cccccc;
  border-color: #bfbfbf;
  cursor: not-allowed;
}
.ant-radio-button-wrapper-disabled:first-child,
.ant-radio-button-wrapper-disabled:hover {
  color: #fff;
  background-color: #cccccc;
  border-color: #bfbfbf;
}
.ant-radio-button-wrapper-disabled:first-child {
  border-left-color: #bfbfbf;
}
.ant-radio-button-wrapper-disabled.ant-radio-button-wrapper-checked {
  color: #fff;
  background-color: #e9e9e9;
  border-color: #bfbfbf;
  box-shadow: none;
}
@keyframes antRadioEffect {
  0% {
    transform: scale(1);
    opacity: 0.5;
  }
  100% {
    transform: scale(1.6);
    opacity: 0;
  }
}
@supports (-moz-appearance: meterbar) and (background-blend-mode: difference, normal) {
  .ant-radio {
    vertical-align: text-bottom;
  }
}
.ant-rate {
  box-sizing: border-box;
  color: rgba(34, 34, 34, 0.8);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  font-feature-settings: "tnum";
  display: inline-block;
  margin: 0;
  padding: 0;
  color: #fadb14;
  font-size: 20px;
  line-height: unset;
  list-style: none;
  outline: none;
}
.ant-rate-disabled .ant-rate-star {
  cursor: default;
}
.ant-rate-disabled .ant-rate-star:hover {
  transform: scale(1);
}
.ant-rate-star {
  position: relative;
  display: inline-block;
  margin: 0;
  padding: 0;
  color: inherit;
  cursor: pointer;
  transition: all 0.3s;
}
.ant-rate-star:not(:last-child) {
  margin-right: 8px;
}
.ant-rate-star > div:focus {
  outline: 0;
}
.ant-rate-star > div:hover,
.ant-rate-star > div:focus {
  transform: scale(1.1);
}
.ant-rate-star-first,
.ant-rate-star-second {
  color: #f0f0f0;
  transition: all 0.3s;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.ant-rate-star-first .anticon,
.ant-rate-star-second .anticon {
  vertical-align: middle;
}
.ant-rate-star-first {
  position: absolute;
  top: 0;
  left: 0;
  width: 50%;
  height: 100%;
  overflow: hidden;
  opacity: 0;
}
.ant-rate-star-half .ant-rate-star-first,
.ant-rate-star-half .ant-rate-star-second {
  opacity: 1;
}
.ant-rate-star-half .ant-rate-star-first,
.ant-rate-star-full .ant-rate-star-second {
  color: inherit;
}
.ant-rate-text {
  display: inline-block;
  margin-left: 8px;
  font-size: 14px;
}
.ant-result {
  padding: 48px 32px;
}
.ant-result-success .ant-result-icon > .anticon {
  color: #52c41a;
}
.ant-result-error .ant-result-icon > .anticon {
  color: #ff4d4f;
}
.ant-result-info .ant-result-icon > .anticon {
  color: #1890ff;
}
.ant-result-warning .ant-result-icon > .anticon {
  color: #faad14;
}
.ant-result-image {
  width: 250px;
  height: 295px;
  margin: auto;
}
.ant-result-icon {
  margin-bottom: 24px;
  text-align: center;
}
.ant-result-icon > .anticon {
  font-size: 72px;
}
.ant-result-title {
  color: rgba(255, 255, 255, 0.85);
  font-size: 24px;
  line-height: 1.8;
  text-align: center;
}
.ant-result-subtitle {
  color: rgba(34, 34, 34, 0.45);
  font-size: 14px;
  line-height: 1.6;
  text-align: center;
}
.ant-result-extra {
  margin-top: 32px;
  text-align: center;
}
.ant-result-extra > * {
  margin-right: 8px;
}
.ant-result-extra > *:last-child {
  margin-right: 0;
}
.ant-result-content {
  margin-top: 24px;
  padding: 24px 40px;
  background-color: #fafafa;
}
.ant-select {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(34, 34, 34, 0.8);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: "tnum";
  position: relative;
  display: inline-block;
  outline: 0;
}
.ant-select ul,
.ant-select ol {
  margin: 0;
  padding: 0;
  list-style: none;
}
.ant-select > ul > li > a {
  padding: 0;
  background-color: #fff;
}
.ant-select-arrow {
  display: inline-block;
  color: inherit;
  font-style: normal;
  line-height: 0;
  text-align: center;
  text-transform: none;
  vertical-align: -0.125em;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: absolute;
  top: 50%;
  right: 11px;
  margin-top: -6px;
  color: #fff;
  font-size: 12px;
  line-height: 1;
  transform-origin: 50% 50%;
}
.ant-select-arrow > * {
  line-height: 1;
}
.ant-select-arrow svg {
  display: inline-block;
}
.ant-select-arrow::before {
  display: none;
}
.ant-select-arrow .ant-select-arrow-icon {
  display: block;
}
.ant-select-arrow .ant-select-arrow-icon svg {
  transition: transform 0.3s;
}
.ant-select-selection {
  display: block;
  box-sizing: border-box;
  background-color: #fff;
  border: 1px solid #bfbfbf;
  border-top-width: 1.02px;
  border-radius: 10px;
  outline: none;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.ant-select-selection:hover {
  border-color: #333;
  border-right-width: 1px !important;
}
.ant-select-focused .ant-select-selection,
.ant-select-selection:focus,
.ant-select-selection:active {
  border-color: #666060;
  border-right-width: 1px !important;
  outline: 0;
  box-shadow: 0 0 0 1px rgba(89, 89, 89, 0.2);
}
.ant-select-selection__clear {
  position: absolute;
  top: 50%;
  right: 11px;
  z-index: 1;
  display: inline-block;
  width: 12px;
  height: 12px;
  margin-top: -6px;
  color: #fff;
  font-size: 12px;
  font-style: normal;
  line-height: 12px;
  text-align: center;
  text-transform: none;
  background: #fff;
  cursor: pointer;
  opacity: 0;
  transition: color 0.3s ease, opacity 0.15s ease;
  text-rendering: auto;
}
.ant-select-selection__clear::before {
  display: block;
}
.ant-select-selection__clear:hover {
  color: rgba(34, 34, 34, 0.45);
}
.ant-select-selection:hover .ant-select-selection__clear {
  opacity: 1;
}
.ant-select-selection-selected-value {
  float: left;
  max-width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.ant-select-no-arrow .ant-select-selection-selected-value {
  padding-right: 0;
}
.ant-select-disabled {
  color: #fff;
}
.ant-select-disabled .ant-select-selection {
  background: #cccccc;
  cursor: not-allowed;
}
.ant-select-disabled .ant-select-selection:hover,
.ant-select-disabled .ant-select-selection:focus,
.ant-select-disabled .ant-select-selection:active {
  border-color: #bfbfbf;
  box-shadow: none;
}
.ant-select-disabled .ant-select-selection__clear {
  display: none;
  visibility: hidden;
  pointer-events: none;
}
.ant-select-disabled .ant-select-selection--multiple .ant-select-selection__choice {
  padding-right: 10px;
  color: rgba(34, 34, 34, 0.33);
  background: #cccccc;
}
.ant-select-disabled .ant-select-selection--multiple .ant-select-selection__choice__remove {
  display: none;
}
.ant-select-selection--single {
  position: relative;
  height: 32px;
  cursor: pointer;
}
.ant-select-selection--single .ant-select-selection__rendered {
  margin-right: 24px;
}
.ant-select-no-arrow .ant-select-selection__rendered {
  margin-right: 11px;
}
.ant-select-selection__rendered {
  position: relative;
  display: block;
  margin-right: 11px;
  margin-left: 11px;
  line-height: 30px;
}
.ant-select-selection__rendered::after {
  display: inline-block;
  width: 0;
  visibility: hidden;
  content: '.';
  pointer-events: none;
}
.ant-select-lg {
  font-size: 14px;
}
.ant-select-lg .ant-select-selection--single {
  height: 45px;
}
.ant-select-lg .ant-select-selection__rendered {
  line-height: 43px;
}
.ant-select-lg .ant-select-selection--multiple {
  min-height: 45px;
}
.ant-select-lg .ant-select-selection--multiple .ant-select-selection__rendered li {
  height: 37px;
  line-height: 37px;
}
.ant-select-lg .ant-select-selection--multiple .ant-select-selection__clear,
.ant-select-lg .ant-select-selection--multiple .ant-select-arrow {
  top: 22.5px;
}
.ant-select-sm .ant-select-selection--single {
  height: 24px;
}
.ant-select-sm .ant-select-selection__rendered {
  margin-left: 7px;
  line-height: 22px;
}
.ant-select-sm .ant-select-selection--multiple {
  min-height: 24px;
}
.ant-select-sm .ant-select-selection--multiple .ant-select-selection__rendered li {
  height: 16px;
  line-height: 14px;
}
.ant-select-sm .ant-select-selection--multiple .ant-select-selection__clear,
.ant-select-sm .ant-select-selection--multiple .ant-select-arrow {
  top: 12px;
}
.ant-select-sm .ant-select-selection__clear,
.ant-select-sm .ant-select-arrow {
  right: 8px;
}
.ant-select-disabled .ant-select-selection__choice__remove {
  color: #fff;
  cursor: default;
}
.ant-select-disabled .ant-select-selection__choice__remove:hover {
  color: #fff;
}
.ant-select-search__field__wrap {
  position: relative;
  display: inline-block;
}
.ant-select-selection__placeholder,
.ant-select-search__field__placeholder {
  position: absolute;
  top: 50%;
  right: 9px;
  left: 0;
  max-width: 100%;
  height: 20px;
  margin-top: -10px;
  overflow: hidden;
  color: #bfbfbf;
  line-height: 20px;
  white-space: nowrap;
  text-align: left;
  text-overflow: ellipsis;
}
.ant-select-search__field__placeholder {
  left: 12px;
}
.ant-select-search__field__mirror {
  position: absolute;
  top: 0;
  left: 0;
  white-space: pre;
  opacity: 0;
  pointer-events: none;
}
.ant-select-search--inline {
  position: absolute;
  width: 100%;
  height: 100%;
}
.ant-select-search--inline .ant-select-search__field__wrap {
  width: 100%;
  height: 100%;
}
.ant-select-search--inline .ant-select-search__field {
  width: 100%;
  height: 100%;
  font-size: 100%;
  line-height: 1;
  background: transparent;
  border-width: 0;
  border-radius: 10px;
  outline: 0;
}
.ant-select-search--inline > i {
  float: right;
}
.ant-select-selection--multiple {
  min-height: 32px;
  padding-bottom: 3px;
  cursor: text;
  zoom: 1;
}
.ant-select-selection--multiple::before,
.ant-select-selection--multiple::after {
  display: table;
  content: '';
}
.ant-select-selection--multiple::after {
  clear: both;
}
.ant-select-selection--multiple::before,
.ant-select-selection--multiple::after {
  display: table;
  content: '';
}
.ant-select-selection--multiple::after {
  clear: both;
}
.ant-select-selection--multiple .ant-select-search--inline {
  position: static;
  float: left;
  width: auto;
  max-width: 100%;
  padding: 0;
}
.ant-select-selection--multiple .ant-select-search--inline .ant-select-search__field {
  width: 0.75em;
  max-width: 100%;
  padding: 1px;
}
.ant-select-selection--multiple .ant-select-selection__rendered {
  height: auto;
  margin-bottom: -3px;
  margin-left: 5px;
}
.ant-select-selection--multiple .ant-select-selection__placeholder {
  margin-left: 6px;
}
.ant-select-selection--multiple > ul > li,
.ant-select-selection--multiple .ant-select-selection__rendered > ul > li {
  height: 24px;
  margin-top: 3px;
  line-height: 22px;
}
.ant-select-selection--multiple .ant-select-selection__choice {
  position: relative;
  float: left;
  max-width: 99%;
  margin-right: 4px;
  padding: 0 20px 0 10px;
  overflow: hidden;
  color: rgba(34, 34, 34, 0.8);
  background-color: #fafafa;
  border: 1px solid #f0f0f0;
  border-radius: 10px;
  cursor: default;
  transition: padding 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.ant-select-selection--multiple .ant-select-selection__choice__disabled {
  padding: 0 10px;
}
.ant-select-selection--multiple .ant-select-selection__choice__content {
  display: inline-block;
  max-width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  transition: margin 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.ant-select-selection--multiple .ant-select-selection__choice__remove {
  color: inherit;
  font-style: normal;
  line-height: 0;
  text-align: center;
  text-transform: none;
  vertical-align: -0.125em;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: absolute;
  right: 4px;
  color: rgba(34, 34, 34, 0.45);
  font-weight: bold;
  line-height: inherit;
  cursor: pointer;
  transition: all 0.3s;
  display: inline-block;
  font-size: 12px;
  font-size: 10px \9;
  transform: scale(0.83333333) rotate(0deg);
}
.ant-select-selection--multiple .ant-select-selection__choice__remove > * {
  line-height: 1;
}
.ant-select-selection--multiple .ant-select-selection__choice__remove svg {
  display: inline-block;
}
.ant-select-selection--multiple .ant-select-selection__choice__remove::before {
  display: none;
}
.ant-select-selection--multiple .ant-select-selection__choice__remove .ant-select-selection--multiple .ant-select-selection__choice__remove-icon {
  display: block;
}
:root .ant-select-selection--multiple .ant-select-selection__choice__remove {
  font-size: 12px;
}
.ant-select-selection--multiple .ant-select-selection__choice__remove:hover {
  color: rgba(34, 34, 34, 0.75);
}
.ant-select-selection--multiple .ant-select-selection__clear,
.ant-select-selection--multiple .ant-select-arrow {
  top: 16px;
}
.ant-select-allow-clear .ant-select-selection--multiple .ant-select-selection__rendered,
.ant-select-show-arrow .ant-select-selection--multiple .ant-select-selection__rendered {
  margin-right: 20px;
}
.ant-select-open .ant-select-arrow-icon svg {
  transform: rotate(180deg);
}
.ant-select-open .ant-select-selection {
  border-color: #666060;
  border-right-width: 1px !important;
  outline: 0;
  box-shadow: 0 0 0 1px rgba(89, 89, 89, 0.2);
}
.ant-select-combobox .ant-select-arrow {
  display: none;
}
.ant-select-combobox .ant-select-search--inline {
  float: none;
  width: 100%;
  height: 100%;
}
.ant-select-combobox .ant-select-search__field__wrap {
  width: 100%;
  height: 100%;
}
.ant-select-combobox .ant-select-search__field {
  position: relative;
  z-index: 1;
  width: 100%;
  height: 100%;
  box-shadow: none;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), height 0s;
}
.ant-select-combobox.ant-select-allow-clear .ant-select-selection:hover .ant-select-selection__rendered,
.ant-select-combobox.ant-select-show-arrow .ant-select-selection:hover .ant-select-selection__rendered {
  margin-right: 20px;
}
.ant-select-dropdown {
  margin: 0;
  padding: 0;
  color: rgba(34, 34, 34, 0.8);
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: "tnum", ;
  position: absolute;
  top: -9999px;
  left: -9999px;
  z-index: 9999;
  box-sizing: border-box;
  font-size: 14px;
  font-variant: initial;
  background-color: #fff;
  border-radius: 10px;
  outline: none;
  box-shadow: 0 0 1px 1px rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.08), 0 2px 8px 2px rgba(0, 0, 0, 0.05);
}
.ant-select-dropdown.slide-up-enter.slide-up-enter-active.ant-select-dropdown-placement-bottomLeft,
.ant-select-dropdown.slide-up-appear.slide-up-appear-active.ant-select-dropdown-placement-bottomLeft {
  animation-name: antSlideUpIn;
}
.ant-select-dropdown.slide-up-enter.slide-up-enter-active.ant-select-dropdown-placement-topLeft,
.ant-select-dropdown.slide-up-appear.slide-up-appear-active.ant-select-dropdown-placement-topLeft {
  animation-name: antSlideDownIn;
}
.ant-select-dropdown.slide-up-leave.slide-up-leave-active.ant-select-dropdown-placement-bottomLeft {
  animation-name: antSlideUpOut;
}
.ant-select-dropdown.slide-up-leave.slide-up-leave-active.ant-select-dropdown-placement-topLeft {
  animation-name: antSlideDownOut;
}
.ant-select-dropdown-hidden {
  display: none;
}
.ant-select-dropdown-menu {
  max-height: 250px;
  margin-bottom: 0;
  padding: 4px 0;
  padding-left: 0;
  overflow: auto;
  list-style: none;
  outline: none;
}
.ant-select-dropdown-menu-item-group-list {
  margin: 0;
  padding: 0;
}
.ant-select-dropdown-menu-item-group-list > .ant-select-dropdown-menu-item {
  padding-left: 20px;
}
.ant-select-dropdown-menu-item-group-title {
  height: 32px;
  padding: 0 12px;
  color: rgba(34, 34, 34, 0.45);
  font-size: 12px;
  line-height: 32px;
}
.ant-select-dropdown-menu-item-group-list .ant-select-dropdown-menu-item:first-child:not(:last-child),
.ant-select-dropdown-menu-item-group:not(:last-child) .ant-select-dropdown-menu-item-group-list .ant-select-dropdown-menu-item:last-child {
  border-radius: 0;
}
.ant-select-dropdown-menu-item {
  position: relative;
  display: block;
  padding: 5px 12px;
  overflow: hidden;
  color: rgba(34, 34, 34, 0.8);
  font-weight: normal;
  font-size: 14px;
  line-height: 32px;
  white-space: nowrap;
  text-overflow: ellipsis;
  cursor: pointer;
  transition: background 0.3s ease;
}
.ant-select-dropdown-menu-item:hover:not(.ant-select-dropdown-menu-item-disabled) {
  background-color: #f5f5f5;
}
.ant-select-dropdown-menu-item-selected {
  color: rgba(34, 34, 34, 0.8);
  font-weight: 600;
  background-color: #a3ebff;
}
.ant-select-dropdown-menu-item-disabled {
  color: #fff;
  cursor: not-allowed;
}
.ant-select-dropdown-menu-item-disabled:hover {
  color: #fff;
  cursor: not-allowed;
}
.ant-select-dropdown-menu-item-active:not(.ant-select-dropdown-menu-item-disabled) {
  background-color: #a3ebff;
}
.ant-select-dropdown-menu-item-divider {
  height: 1px;
  margin: 1px 0;
  overflow: hidden;
  line-height: 0;
  background-color: #f0f0f0;
}
.ant-select-dropdown.ant-select-dropdown--multiple .ant-select-dropdown-menu-item {
  padding-right: 32px;
}
.ant-select-dropdown.ant-select-dropdown--multiple .ant-select-dropdown-menu-item .ant-select-selected-icon {
  position: absolute;
  top: 50%;
  right: 12px;
  color: transparent;
  font-weight: bold;
  font-size: 12px;
  text-shadow: 0 0.1px 0, 0.1px 0 0, 0 -0.1px 0, -0.1px 0;
  transform: translateY(-50%);
  transition: all 0.2s;
}
.ant-select-dropdown.ant-select-dropdown--multiple .ant-select-dropdown-menu-item:hover .ant-select-selected-icon {
  color: rgba(34, 34, 34, 0.87);
}
.ant-select-dropdown.ant-select-dropdown--multiple .ant-select-dropdown-menu-item-disabled .ant-select-selected-icon {
  display: none;
}
.ant-select-dropdown.ant-select-dropdown--multiple .ant-select-dropdown-menu-item-selected .ant-select-selected-icon,
.ant-select-dropdown.ant-select-dropdown--multiple .ant-select-dropdown-menu-item-selected:hover .ant-select-selected-icon {
  display: inline-block;
  color: #00a6ff;
}
.ant-select-dropdown--empty.ant-select-dropdown--multiple .ant-select-dropdown-menu-item {
  padding-right: 12px;
}
.ant-select-dropdown-container-open .ant-select-dropdown,
.ant-select-dropdown-open .ant-select-dropdown {
  display: block;
}
.ant-skeleton {
  display: table;
  width: 100%;
}
.ant-skeleton-header {
  display: table-cell;
  padding-right: 16px;
  vertical-align: top;
}
.ant-skeleton-header .ant-skeleton-avatar {
  display: inline-block;
  vertical-align: top;
  background: #f2f2f2;
  width: 32px;
  height: 32px;
  line-height: 32px;
}
.ant-skeleton-header .ant-skeleton-avatar.ant-skeleton-avatar-circle {
  border-radius: 50%;
}
.ant-skeleton-header .ant-skeleton-avatar-lg {
  width: 40px;
  height: 40px;
  line-height: 40px;
}
.ant-skeleton-header .ant-skeleton-avatar-lg.ant-skeleton-avatar-circle {
  border-radius: 50%;
}
.ant-skeleton-header .ant-skeleton-avatar-sm {
  width: 24px;
  height: 24px;
  line-height: 24px;
}
.ant-skeleton-header .ant-skeleton-avatar-sm.ant-skeleton-avatar-circle {
  border-radius: 50%;
}
.ant-skeleton-content {
  display: table-cell;
  width: 100%;
  vertical-align: top;
}
.ant-skeleton-content .ant-skeleton-title {
  width: 100%;
  height: 16px;
  margin-top: 16px;
  background: #f2f2f2;
}
.ant-skeleton-content .ant-skeleton-title + .ant-skeleton-paragraph {
  margin-top: 24px;
}
.ant-skeleton-content .ant-skeleton-paragraph {
  padding: 0;
}
.ant-skeleton-content .ant-skeleton-paragraph > li {
  width: 100%;
  height: 16px;
  list-style: none;
  background: #f2f2f2;
}
.ant-skeleton-content .ant-skeleton-paragraph > li:last-child:not(:first-child):not(:nth-child(2)) {
  width: 61%;
}
.ant-skeleton-content .ant-skeleton-paragraph > li + li {
  margin-top: 16px;
}
.ant-skeleton-with-avatar .ant-skeleton-content .ant-skeleton-title {
  margin-top: 12px;
}
.ant-skeleton-with-avatar .ant-skeleton-content .ant-skeleton-title + .ant-skeleton-paragraph {
  margin-top: 28px;
}
.ant-skeleton.ant-skeleton-active .ant-skeleton-content .ant-skeleton-title,
.ant-skeleton.ant-skeleton-active .ant-skeleton-content .ant-skeleton-paragraph > li {
  background: linear-gradient(90deg, #f2f2f2 25%, #e6e6e6 37%, #f2f2f2 63%);
  background-size: 400% 100%;
  animation: ant-skeleton-loading 1.4s ease infinite;
}
.ant-skeleton.ant-skeleton-active .ant-skeleton-avatar {
  background: linear-gradient(90deg, #f2f2f2 25%, #e6e6e6 37%, #f2f2f2 63%);
  background-size: 400% 100%;
  animation: ant-skeleton-loading 1.4s ease infinite;
}
@keyframes ant-skeleton-loading {
  0% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0 50%;
  }
}
.ant-slider {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(34, 34, 34, 0.8);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: "tnum";
  position: relative;
  height: 12px;
  margin: 10px 6px 10px;
  padding: 4px 0;
  cursor: pointer;
  touch-action: none;
}
.ant-slider-vertical {
  width: 12px;
  height: 100%;
  margin: 6px 10px;
  padding: 0 4px;
}
.ant-slider-vertical .ant-slider-rail {
  width: 4px;
  height: 100%;
}
.ant-slider-vertical .ant-slider-track {
  width: 4px;
}
.ant-slider-vertical .ant-slider-handle {
  margin-bottom: -7px;
  margin-left: -5px;
}
.ant-slider-vertical .ant-slider-mark {
  top: 0;
  left: 12px;
  width: 18px;
  height: 100%;
}
.ant-slider-vertical .ant-slider-mark-text {
  left: 4px;
  white-space: nowrap;
}
.ant-slider-vertical .ant-slider-step {
  width: 4px;
  height: 100%;
}
.ant-slider-vertical .ant-slider-dot {
  top: auto;
  left: 2px;
  margin-bottom: -4px;
}
.ant-slider-tooltip .ant-tooltip-inner {
  min-width: unset;
}
.ant-slider-with-marks {
  margin-bottom: 28px;
}
.ant-slider-rail {
  position: absolute;
  width: 100%;
  height: 4px;
  background-color: #cccccc;
  border-radius: 10px;
  transition: background-color 0.3s;
}
.ant-slider-track {
  position: absolute;
  height: 4px;
  background-color: #7adeff;
  border-radius: 10px;
  transition: background-color 0.3s;
}
.ant-slider-handle {
  position: absolute;
  width: 14px;
  height: 14px;
  margin-top: -5px;
  background-color: #fff;
  border: solid 2px #7adeff;
  border-radius: 50%;
  box-shadow: 0;
  cursor: pointer;
  transition: border-color 0.3s, box-shadow 0.6s, transform 0.3s cubic-bezier(0.18, 0.89, 0.32, 1.28);
}
.ant-slider-handle:focus {
  border-color: #33b8ff;
  outline: none;
  box-shadow: 0 0 0 5px rgba(0, 166, 255, 0.2);
}
.ant-slider-handle.ant-tooltip-open {
  border-color: #00a6ff;
}
.ant-slider:hover .ant-slider-rail {
  background-color: #e1e1e1;
}
.ant-slider:hover .ant-slider-track {
  background-color: #52ceff;
}
.ant-slider:hover .ant-slider-handle:not(.ant-tooltip-open) {
  border-color: #52ceff;
}
.ant-slider-mark {
  position: absolute;
  top: 14px;
  left: 0;
  width: 100%;
  font-size: 14px;
}
.ant-slider-mark-text {
  position: absolute;
  display: inline-block;
  color: rgba(34, 34, 34, 0.45);
  text-align: center;
  word-break: keep-all;
  cursor: pointer;
}
.ant-slider-mark-text-active {
  color: rgba(34, 34, 34, 0.8);
}
.ant-slider-step {
  position: absolute;
  width: 100%;
  height: 4px;
  background: transparent;
}
.ant-slider-dot {
  position: absolute;
  top: -2px;
  width: 8px;
  height: 8px;
  margin-left: -4px;
  background-color: #fff;
  border: 2px solid #f0f0f0;
  border-radius: 50%;
  cursor: pointer;
}
.ant-slider-dot:first-child {
  margin-left: -4px;
}
.ant-slider-dot:last-child {
  margin-left: -4px;
}
.ant-slider-dot-active {
  border-color: #80d3ff;
}
.ant-slider-disabled {
  cursor: not-allowed;
}
.ant-slider-disabled .ant-slider-track {
  background-color: #fff !important;
}
.ant-slider-disabled .ant-slider-handle,
.ant-slider-disabled .ant-slider-dot {
  background-color: #fff;
  border-color: #fff !important;
  box-shadow: none;
  cursor: not-allowed;
}
.ant-slider-disabled .ant-slider-mark-text,
.ant-slider-disabled .ant-slider-dot {
  cursor: not-allowed !important;
}
.ant-spin {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(34, 34, 34, 0.8);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: "tnum";
  position: absolute;
  display: none;
  color: #00a6ff;
  text-align: center;
  vertical-align: middle;
  opacity: 0;
  transition: transform 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
}
.ant-spin-spinning {
  position: static;
  display: inline-block;
  opacity: 1;
}
.ant-spin-nested-loading {
  position: relative;
}
.ant-spin-nested-loading > div > .ant-spin {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 4;
  display: block;
  width: 100%;
  height: 100%;
  max-height: 400px;
}
.ant-spin-nested-loading > div > .ant-spin .ant-spin-dot {
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -10px;
}
.ant-spin-nested-loading > div > .ant-spin .ant-spin-text {
  position: absolute;
  top: 50%;
  width: 100%;
  padding-top: 5px;
  text-shadow: 0 1px 2px #fff;
}
.ant-spin-nested-loading > div > .ant-spin.ant-spin-show-text .ant-spin-dot {
  margin-top: -20px;
}
.ant-spin-nested-loading > div > .ant-spin-sm .ant-spin-dot {
  margin: -7px;
}
.ant-spin-nested-loading > div > .ant-spin-sm .ant-spin-text {
  padding-top: 2px;
}
.ant-spin-nested-loading > div > .ant-spin-sm.ant-spin-show-text .ant-spin-dot {
  margin-top: -17px;
}
.ant-spin-nested-loading > div > .ant-spin-lg .ant-spin-dot {
  margin: -16px;
}
.ant-spin-nested-loading > div > .ant-spin-lg .ant-spin-text {
  padding-top: 11px;
}
.ant-spin-nested-loading > div > .ant-spin-lg.ant-spin-show-text .ant-spin-dot {
  margin-top: -26px;
}
.ant-spin-container {
  position: relative;
  transition: opacity 0.3s;
}
.ant-spin-container::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 10;
  display: none \9;
  width: 100%;
  height: 100%;
  background: #fff;
  opacity: 0;
  transition: all 0.3s;
  content: '';
  pointer-events: none;
}
.ant-spin-blur {
  clear: both;
  overflow: hidden;
  opacity: 0.5;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  pointer-events: none;
}
.ant-spin-blur::after {
  opacity: 0.4;
  pointer-events: auto;
}
.ant-spin-tip {
  color: rgba(34, 34, 34, 0.45);
}
.ant-spin-dot {
  position: relative;
  display: inline-block;
  font-size: 20px;
  width: 1em;
  height: 1em;
}
.ant-spin-dot-item {
  position: absolute;
  display: block;
  width: 9px;
  height: 9px;
  background-color: #00a6ff;
  border-radius: 100%;
  transform: scale(0.75);
  transform-origin: 50% 50%;
  opacity: 0.3;
  animation: antSpinMove 1s infinite linear alternate;
}
.ant-spin-dot-item:nth-child(1) {
  top: 0;
  left: 0;
}
.ant-spin-dot-item:nth-child(2) {
  top: 0;
  right: 0;
  animation-delay: 0.4s;
}
.ant-spin-dot-item:nth-child(3) {
  right: 0;
  bottom: 0;
  animation-delay: 0.8s;
}
.ant-spin-dot-item:nth-child(4) {
  bottom: 0;
  left: 0;
  animation-delay: 1.2s;
}
.ant-spin-dot-spin {
  transform: rotate(45deg);
  animation: antRotate 1.2s infinite linear;
}
.ant-spin-sm .ant-spin-dot {
  font-size: 14px;
}
.ant-spin-sm .ant-spin-dot i {
  width: 6px;
  height: 6px;
}
.ant-spin-lg .ant-spin-dot {
  font-size: 32px;
}
.ant-spin-lg .ant-spin-dot i {
  width: 14px;
  height: 14px;
}
.ant-spin.ant-spin-show-text .ant-spin-text {
  display: block;
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  /* IE10+ */
  .ant-spin-blur {
    background: #fff;
    opacity: 0.5;
  }
}
@keyframes antSpinMove {
  to {
    opacity: 1;
  }
}
@keyframes antRotate {
  to {
    transform: rotate(405deg);
  }
}
.ant-statistic {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(34, 34, 34, 0.8);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: "tnum";
}
.ant-statistic-title {
  margin-bottom: 4px;
  color: rgba(34, 34, 34, 0.45);
  font-size: 14px;
}
.ant-statistic-content {
  color: rgba(255, 255, 255, 0.85);
  font-size: 24px;
  font-family: Roboto, Arial, San-serif;
}
.ant-statistic-content-value-decimal {
  font-size: 16px;
}
.ant-statistic-content-prefix,
.ant-statistic-content-suffix {
  display: inline-block;
}
.ant-statistic-content-prefix {
  margin-right: 4px;
}
.ant-statistic-content-suffix {
  margin-left: 4px;
  font-size: 16px;
}
.ant-steps {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(34, 34, 34, 0.8);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: "tnum";
  display: flex;
  width: 100%;
  font-size: 0;
}
.ant-steps-item {
  position: relative;
  display: inline-block;
  flex: 1 1;
  overflow: hidden;
  vertical-align: top;
}
.ant-steps-item-container {
  outline: none;
}
.ant-steps-item:last-child {
  flex: none;
}
.ant-steps-item:last-child > .ant-steps-item-container > .ant-steps-item-tail,
.ant-steps-item:last-child > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title::after {
  display: none;
}
.ant-steps-item-icon,
.ant-steps-item-content {
  display: inline-block;
  vertical-align: top;
}
.ant-steps-item-icon {
  width: 32px;
  height: 32px;
  margin-right: 8px;
  font-size: 14px;
  font-family: Roboto, Arial, San-serif;
  line-height: 32px;
  text-align: center;
  border: 1px solid #fff;
  border-radius: 32px;
  transition: background-color 0.3s, border-color 0.3s;
}
.ant-steps-item-icon > .ant-steps-icon {
  position: relative;
  top: -1px;
  color: #00a6ff;
  line-height: 1;
}
.ant-steps-item-tail {
  position: absolute;
  top: 12px;
  left: 0;
  width: 100%;
  padding: 0 10px;
}
.ant-steps-item-tail::after {
  display: inline-block;
  width: 100%;
  height: 1px;
  background: #f0f0f0;
  border-radius: 1px;
  transition: background 0.3s;
  content: '';
}
.ant-steps-item-title {
  position: relative;
  display: inline-block;
  padding-right: 16px;
  color: rgba(34, 34, 34, 0.8);
  font-size: 14px;
  line-height: 32px;
}
.ant-steps-item-title::after {
  position: absolute;
  top: 16px;
  left: 100%;
  display: block;
  width: 9999px;
  height: 1px;
  background: #f0f0f0;
  content: '';
}
.ant-steps-item-subtitle {
  display: inline;
  margin-left: 8px;
  color: rgba(34, 34, 34, 0.45);
  font-weight: normal;
  font-size: 14px;
}
.ant-steps-item-description {
  color: rgba(34, 34, 34, 0.45);
  font-size: 14px;
}
.ant-steps-item-wait .ant-steps-item-icon {
  background-color: #fff;
  border-color: #fff;
}
.ant-steps-item-wait .ant-steps-item-icon > .ant-steps-icon {
  color: #fff;
}
.ant-steps-item-wait .ant-steps-item-icon > .ant-steps-icon .ant-steps-icon-dot {
  background: #fff;
}
.ant-steps-item-wait > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title {
  color: rgba(34, 34, 34, 0.45);
}
.ant-steps-item-wait > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title::after {
  background-color: #f0f0f0;
}
.ant-steps-item-wait > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-description {
  color: rgba(34, 34, 34, 0.45);
}
.ant-steps-item-wait > .ant-steps-item-container > .ant-steps-item-tail::after {
  background-color: #f0f0f0;
}
.ant-steps-item-process .ant-steps-item-icon {
  background-color: #fff;
  border-color: #00a6ff;
}
.ant-steps-item-process .ant-steps-item-icon > .ant-steps-icon {
  color: #00a6ff;
}
.ant-steps-item-process .ant-steps-item-icon > .ant-steps-icon .ant-steps-icon-dot {
  background: #00a6ff;
}
.ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title {
  color: rgba(255, 255, 255, 0.85);
}
.ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title::after {
  background-color: #f0f0f0;
}
.ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-description {
  color: rgba(34, 34, 34, 0.8);
}
.ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-tail::after {
  background-color: #f0f0f0;
}
.ant-steps-item-process .ant-steps-item-icon {
  background: #00a6ff;
}
.ant-steps-item-process .ant-steps-item-icon > .ant-steps-icon {
  color: #fff;
}
.ant-steps-item-process .ant-steps-item-title {
  font-weight: 500;
}
.ant-steps-item-finish .ant-steps-item-icon {
  background-color: #fff;
  border-color: #00a6ff;
}
.ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon {
  color: #00a6ff;
}
.ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon .ant-steps-icon-dot {
  background: #00a6ff;
}
.ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title {
  color: rgba(34, 34, 34, 0.8);
}
.ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title::after {
  background-color: #00a6ff;
}
.ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-description {
  color: rgba(34, 34, 34, 0.45);
}
.ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-tail::after {
  background-color: #00a6ff;
}
.ant-steps-item-error .ant-steps-item-icon {
  background-color: #fff;
  border-color: #ff4d4f;
}
.ant-steps-item-error .ant-steps-item-icon > .ant-steps-icon {
  color: #ff4d4f;
}
.ant-steps-item-error .ant-steps-item-icon > .ant-steps-icon .ant-steps-icon-dot {
  background: #ff4d4f;
}
.ant-steps-item-error > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title {
  color: #ff4d4f;
}
.ant-steps-item-error > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title::after {
  background-color: #f0f0f0;
}
.ant-steps-item-error > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-description {
  color: #ff4d4f;
}
.ant-steps-item-error > .ant-steps-item-container > .ant-steps-item-tail::after {
  background-color: #f0f0f0;
}
.ant-steps-item.ant-steps-next-error .ant-steps-item-title::after {
  background: #ff4d4f;
}
.ant-steps .ant-steps-item:not(.ant-steps-item-active) > .ant-steps-item-container[role='button'] {
  cursor: pointer;
}
.ant-steps .ant-steps-item:not(.ant-steps-item-active) > .ant-steps-item-container[role='button'] .ant-steps-item-title,
.ant-steps .ant-steps-item:not(.ant-steps-item-active) > .ant-steps-item-container[role='button'] .ant-steps-item-description,
.ant-steps .ant-steps-item:not(.ant-steps-item-active) > .ant-steps-item-container[role='button'] .ant-steps-item-icon .ant-steps-icon {
  transition: color 0.3s;
}
.ant-steps .ant-steps-item:not(.ant-steps-item-active) > .ant-steps-item-container[role='button']:hover .ant-steps-item-title,
.ant-steps .ant-steps-item:not(.ant-steps-item-active) > .ant-steps-item-container[role='button']:hover .ant-steps-item-subtitle,
.ant-steps .ant-steps-item:not(.ant-steps-item-active) > .ant-steps-item-container[role='button']:hover .ant-steps-item-description {
  color: #00a6ff;
}
.ant-steps .ant-steps-item:not(.ant-steps-item-active):not(.ant-steps-item-process) > .ant-steps-item-container[role='button']:hover .ant-steps-item-icon {
  border-color: #00a6ff;
}
.ant-steps .ant-steps-item:not(.ant-steps-item-active):not(.ant-steps-item-process) > .ant-steps-item-container[role='button']:hover .ant-steps-item-icon .ant-steps-icon {
  color: #00a6ff;
}
.ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item {
  margin-right: 16px;
  white-space: nowrap;
}
.ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item:last-child {
  margin-right: 0;
}
.ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item:last-child .ant-steps-item-title {
  padding-right: 0;
}
.ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item-tail {
  display: none;
}
.ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item-description {
  max-width: 140px;
  white-space: normal;
}
.ant-steps-item-custom .ant-steps-item-icon {
  height: auto;
  background: none;
  border: 0;
}
.ant-steps-item-custom .ant-steps-item-icon > .ant-steps-icon {
  top: 0;
  left: 0.5px;
  width: 32px;
  height: 32px;
  font-size: 24px;
  line-height: 32px;
}
.ant-steps-item-custom.ant-steps-item-process .ant-steps-item-icon > .ant-steps-icon {
  color: #00a6ff;
}
.ant-steps:not(.ant-steps-vertical) .ant-steps-item-custom .ant-steps-item-icon {
  width: auto;
}
.ant-steps-small.ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item {
  margin-right: 12px;
}
.ant-steps-small.ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item:last-child {
  margin-right: 0;
}
.ant-steps-small .ant-steps-item-icon {
  width: 24px;
  height: 24px;
  font-size: 12px;
  line-height: 24px;
  text-align: center;
  border-radius: 24px;
}
.ant-steps-small .ant-steps-item-title {
  padding-right: 12px;
  font-size: 14px;
  line-height: 24px;
}
.ant-steps-small .ant-steps-item-title::after {
  top: 12px;
}
.ant-steps-small .ant-steps-item-description {
  color: rgba(34, 34, 34, 0.45);
  font-size: 14px;
}
.ant-steps-small .ant-steps-item-tail {
  top: 8px;
}
.ant-steps-small .ant-steps-item-custom .ant-steps-item-icon {
  width: inherit;
  height: inherit;
  line-height: inherit;
  background: none;
  border: 0;
  border-radius: 0;
}
.ant-steps-small .ant-steps-item-custom .ant-steps-item-icon > .ant-steps-icon {
  font-size: 24px;
  line-height: 24px;
  transform: none;
}
.ant-steps-vertical {
  display: block;
}
.ant-steps-vertical .ant-steps-item {
  display: block;
  overflow: visible;
}
.ant-steps-vertical .ant-steps-item-icon {
  float: left;
  margin-right: 16px;
}
.ant-steps-vertical .ant-steps-item-content {
  display: block;
  min-height: 48px;
  overflow: hidden;
}
.ant-steps-vertical .ant-steps-item-title {
  line-height: 32px;
}
.ant-steps-vertical .ant-steps-item-description {
  padding-bottom: 12px;
}
.ant-steps-vertical > .ant-steps-item > .ant-steps-item-container > .ant-steps-item-tail {
  position: absolute;
  top: 0;
  left: 16px;
  width: 1px;
  height: 100%;
  padding: 38px 0 6px;
}
.ant-steps-vertical > .ant-steps-item > .ant-steps-item-container > .ant-steps-item-tail::after {
  width: 1px;
  height: 100%;
}
.ant-steps-vertical > .ant-steps-item:not(:last-child) > .ant-steps-item-container > .ant-steps-item-tail {
  display: block;
}
.ant-steps-vertical > .ant-steps-item > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title::after {
  display: none;
}
.ant-steps-vertical.ant-steps-small .ant-steps-item-container .ant-steps-item-tail {
  position: absolute;
  top: 0;
  left: 12px;
  padding: 30px 0 6px;
}
.ant-steps-vertical.ant-steps-small .ant-steps-item-container .ant-steps-item-title {
  line-height: 24px;
}
@media (max-width: 480px) {
  .ant-steps-horizontal.ant-steps-label-horizontal {
    display: block;
  }
  .ant-steps-horizontal.ant-steps-label-horizontal .ant-steps-item {
    display: block;
    overflow: visible;
  }
  .ant-steps-horizontal.ant-steps-label-horizontal .ant-steps-item-icon {
    float: left;
    margin-right: 16px;
  }
  .ant-steps-horizontal.ant-steps-label-horizontal .ant-steps-item-content {
    display: block;
    min-height: 48px;
    overflow: hidden;
  }
  .ant-steps-horizontal.ant-steps-label-horizontal .ant-steps-item-title {
    line-height: 32px;
  }
  .ant-steps-horizontal.ant-steps-label-horizontal .ant-steps-item-description {
    padding-bottom: 12px;
  }
  .ant-steps-horizontal.ant-steps-label-horizontal > .ant-steps-item > .ant-steps-item-container > .ant-steps-item-tail {
    position: absolute;
    top: 0;
    left: 16px;
    width: 1px;
    height: 100%;
    padding: 38px 0 6px;
  }
  .ant-steps-horizontal.ant-steps-label-horizontal > .ant-steps-item > .ant-steps-item-container > .ant-steps-item-tail::after {
    width: 1px;
    height: 100%;
  }
  .ant-steps-horizontal.ant-steps-label-horizontal > .ant-steps-item:not(:last-child) > .ant-steps-item-container > .ant-steps-item-tail {
    display: block;
  }
  .ant-steps-horizontal.ant-steps-label-horizontal > .ant-steps-item > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title::after {
    display: none;
  }
  .ant-steps-horizontal.ant-steps-label-horizontal.ant-steps-small .ant-steps-item-container .ant-steps-item-tail {
    position: absolute;
    top: 0;
    left: 12px;
    padding: 30px 0 6px;
  }
  .ant-steps-horizontal.ant-steps-label-horizontal.ant-steps-small .ant-steps-item-container .ant-steps-item-title {
    line-height: 24px;
  }
}
.ant-steps-label-vertical .ant-steps-item {
  overflow: visible;
}
.ant-steps-label-vertical .ant-steps-item-tail {
  margin-left: 58px;
  padding: 3.5px 24px;
}
.ant-steps-label-vertical .ant-steps-item-content {
  display: block;
  width: 116px;
  margin-top: 8px;
  text-align: center;
}
.ant-steps-label-vertical .ant-steps-item-icon {
  display: inline-block;
  margin-left: 42px;
}
.ant-steps-label-vertical .ant-steps-item-title {
  padding-right: 0;
}
.ant-steps-label-vertical .ant-steps-item-title::after {
  display: none;
}
.ant-steps-label-vertical .ant-steps-item-subtitle {
  display: block;
  margin-bottom: 4px;
  margin-left: 0;
  line-height: 1.5715;
}
.ant-steps-label-vertical.ant-steps-small:not(.ant-steps-dot) .ant-steps-item-icon {
  margin-left: 46px;
}
.ant-steps-dot .ant-steps-item-title,
.ant-steps-dot.ant-steps-small .ant-steps-item-title {
  line-height: 1.5715;
}
.ant-steps-dot .ant-steps-item-tail,
.ant-steps-dot.ant-steps-small .ant-steps-item-tail {
  top: 2px;
  width: 100%;
  margin: 0 0 0 70px;
  padding: 0;
}
.ant-steps-dot .ant-steps-item-tail::after,
.ant-steps-dot.ant-steps-small .ant-steps-item-tail::after {
  width: calc(100% - 20px);
  height: 3px;
  margin-left: 12px;
}
.ant-steps-dot .ant-steps-item:first-child .ant-steps-icon-dot,
.ant-steps-dot.ant-steps-small .ant-steps-item:first-child .ant-steps-icon-dot {
  left: 2px;
}
.ant-steps-dot .ant-steps-item-icon,
.ant-steps-dot.ant-steps-small .ant-steps-item-icon {
  width: 8px;
  height: 8px;
  margin-left: 67px;
  padding-right: 0;
  line-height: 8px;
  background: transparent;
  border: 0;
}
.ant-steps-dot .ant-steps-item-icon .ant-steps-icon-dot,
.ant-steps-dot.ant-steps-small .ant-steps-item-icon .ant-steps-icon-dot {
  position: relative;
  float: left;
  width: 100%;
  height: 100%;
  border-radius: 100px;
  transition: all 0.3s;
  /* expand hover area */
}
.ant-steps-dot .ant-steps-item-icon .ant-steps-icon-dot::after,
.ant-steps-dot.ant-steps-small .ant-steps-item-icon .ant-steps-icon-dot::after {
  position: absolute;
  top: -12px;
  left: -26px;
  width: 60px;
  height: 32px;
  background: rgba(34, 34, 34, 0.001);
  content: '';
}
.ant-steps-dot .ant-steps-item-content,
.ant-steps-dot.ant-steps-small .ant-steps-item-content {
  width: 140px;
}
.ant-steps-dot .ant-steps-item-process .ant-steps-item-icon,
.ant-steps-dot.ant-steps-small .ant-steps-item-process .ant-steps-item-icon {
  width: 10px;
  height: 10px;
  line-height: 10px;
}
.ant-steps-dot .ant-steps-item-process .ant-steps-item-icon .ant-steps-icon-dot,
.ant-steps-dot.ant-steps-small .ant-steps-item-process .ant-steps-item-icon .ant-steps-icon-dot {
  top: -1px;
}
.ant-steps-vertical.ant-steps-dot .ant-steps-item-icon {
  margin-top: 8px;
  margin-left: 0;
}
.ant-steps-vertical.ant-steps-dot .ant-steps-item > .ant-steps-item-container > .ant-steps-item-tail {
  top: 2px;
  left: -9px;
  margin: 0;
  padding: 22px 0 4px;
}
.ant-steps-vertical.ant-steps-dot .ant-steps-item:first-child .ant-steps-icon-dot {
  left: 0;
}
.ant-steps-vertical.ant-steps-dot .ant-steps-item-process .ant-steps-icon-dot {
  left: -2px;
}
.ant-steps-navigation {
  padding-top: 12px;
}
.ant-steps-navigation.ant-steps-small .ant-steps-item-container {
  margin-left: -12px;
}
.ant-steps-navigation .ant-steps-item {
  overflow: visible;
  text-align: center;
}
.ant-steps-navigation .ant-steps-item-container {
  display: inline-block;
  height: 100%;
  margin-left: -16px;
  padding-bottom: 12px;
  text-align: left;
  transition: opacity 0.3s;
}
.ant-steps-navigation .ant-steps-item-container .ant-steps-item-content {
  max-width: auto;
}
.ant-steps-navigation .ant-steps-item-container .ant-steps-item-title {
  max-width: 100%;
  padding-right: 0;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.ant-steps-navigation .ant-steps-item-container .ant-steps-item-title::after {
  display: none;
}
.ant-steps-navigation .ant-steps-item:not(.ant-steps-item-active) .ant-steps-item-container[role='button'] {
  cursor: pointer;
}
.ant-steps-navigation .ant-steps-item:not(.ant-steps-item-active) .ant-steps-item-container[role='button']:hover {
  opacity: 0.85;
}
.ant-steps-navigation .ant-steps-item:last-child {
  flex: 1 1;
}
.ant-steps-navigation .ant-steps-item:last-child::after {
  display: none;
}
.ant-steps-navigation .ant-steps-item::after {
  position: absolute;
  top: 50%;
  left: 100%;
  display: inline-block;
  width: 12px;
  height: 12px;
  margin-top: -14px;
  margin-left: -2px;
  border: 1px solid rgba(34, 34, 34, 0.25);
  border-bottom: none;
  border-left: none;
  transform: rotate(45deg);
  content: '';
}
.ant-steps-navigation .ant-steps-item::before {
  position: absolute;
  bottom: 0;
  left: 50%;
  display: inline-block;
  width: 0;
  height: 3px;
  background-color: #00a6ff;
  transition: width 0.3s, left 0.3s;
  transition-timing-function: ease-out;
  content: '';
}
.ant-steps-navigation .ant-steps-item.ant-steps-item-active::before {
  left: 0;
  width: 100%;
}
@media (max-width: 480px) {
  .ant-steps-navigation > .ant-steps-item {
    margin-right: 0 !important;
  }
  .ant-steps-navigation > .ant-steps-item::before {
    display: none;
  }
  .ant-steps-navigation > .ant-steps-item.ant-steps-item-active::before {
    top: 0;
    right: 0;
    left: unset;
    display: block;
    width: 3px;
    height: calc(100% - 24px);
  }
  .ant-steps-navigation > .ant-steps-item::after {
    position: relative;
    top: -2px;
    left: 50%;
    display: block;
    width: 8px;
    height: 8px;
    margin-bottom: 8px;
    text-align: center;
    transform: rotate(135deg);
  }
  .ant-steps-navigation > .ant-steps-item > .ant-steps-item-container > .ant-steps-item-tail {
    visibility: hidden;
  }
}
.ant-steps-flex-not-supported.ant-steps-horizontal.ant-steps-label-horizontal .ant-steps-item {
  margin-left: -16px;
  padding-left: 16px;
  background: #fff;
}
.ant-steps-flex-not-supported.ant-steps-horizontal.ant-steps-label-horizontal.ant-steps-small .ant-steps-item {
  margin-left: -12px;
  padding-left: 12px;
}
.ant-steps-flex-not-supported.ant-steps-dot .ant-steps-item:last-child {
  overflow: hidden;
}
.ant-steps-flex-not-supported.ant-steps-dot .ant-steps-item:last-child .ant-steps-icon-dot::after {
  right: -200px;
  width: 200px;
}
.ant-steps-flex-not-supported.ant-steps-dot .ant-steps-item .ant-steps-icon-dot::before,
.ant-steps-flex-not-supported.ant-steps-dot .ant-steps-item .ant-steps-icon-dot::after {
  position: absolute;
  top: 0;
  left: -10px;
  width: 10px;
  height: 8px;
  background: #fff;
  content: '';
}
.ant-steps-flex-not-supported.ant-steps-dot .ant-steps-item .ant-steps-icon-dot::after {
  right: -10px;
  left: auto;
}
.ant-steps-flex-not-supported.ant-steps-dot .ant-steps-item-wait .ant-steps-item-icon > .ant-steps-icon .ant-steps-icon-dot {
  background: #ccc;
}
.ant-switch {
  margin: 0;
  padding: 0;
  color: rgba(34, 34, 34, 0.8);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: "tnum";
  position: relative;
  display: inline-block;
  box-sizing: border-box;
  min-width: 44px;
  height: 22px;
  line-height: 20px;
  vertical-align: middle;
  background-color: #fff;
  border: 1px solid transparent;
  border-radius: 100px;
  cursor: pointer;
  transition: all 0.36s;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.ant-switch-inner {
  display: block;
  margin-right: 6px;
  margin-left: 24px;
  color: #fff;
  font-size: 12px;
}
.ant-switch-loading-icon,
.ant-switch::after {
  position: absolute;
  top: 1px;
  left: 1px;
  width: 18px;
  height: 18px;
  background-color: #fff;
  border-radius: 18px;
  cursor: pointer;
  transition: all 0.36s cubic-bezier(0.78, 0.14, 0.15, 0.86);
  content: ' ';
}
.ant-switch::after {
  box-shadow: 0 2px 4px 0 rgba(0, 35, 11, 0.2);
}
.ant-switch:not(.ant-switch-disabled):active::before,
.ant-switch:not(.ant-switch-disabled):active::after {
  width: 24px;
}
.ant-switch-loading-icon {
  z-index: 1;
  display: none;
  font-size: 12px;
  background: transparent;
}
.ant-switch-loading-icon svg {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
}
.ant-switch-loading .ant-switch-loading-icon {
  display: inline-block;
  color: rgba(34, 34, 34, 0.8);
}
.ant-switch-checked.ant-switch-loading .ant-switch-loading-icon {
  color: #00a6ff;
}
.ant-switch:focus {
  outline: 0;
  box-shadow: 0 0 0 2px rgba(0, 166, 255, 0.2);
}
.ant-switch:focus:hover {
  box-shadow: none;
}
.ant-switch-small {
  min-width: 28px;
  height: 16px;
  line-height: 14px;
}
.ant-switch-small .ant-switch-inner {
  margin-right: 3px;
  margin-left: 18px;
  font-size: 12px;
}
.ant-switch-small::after {
  width: 12px;
  height: 12px;
}
.ant-switch-small:active::before,
.ant-switch-small:active::after {
  width: 16px;
}
.ant-switch-small .ant-switch-loading-icon {
  width: 12px;
  height: 12px;
}
.ant-switch-small.ant-switch-checked .ant-switch-inner {
  margin-right: 18px;
  margin-left: 3px;
}
.ant-switch-small.ant-switch-checked .ant-switch-loading-icon {
  left: 100%;
  margin-left: -13px;
}
.ant-switch-small.ant-switch-loading .ant-switch-loading-icon {
  font-weight: bold;
  transform: scale(0.66667);
}
.ant-switch-checked {
  background-color: #00a6ff;
}
.ant-switch-checked .ant-switch-inner {
  margin-right: 24px;
  margin-left: 6px;
}
.ant-switch-checked::after {
  left: 100%;
  margin-left: -1px;
  transform: translateX(-100%);
}
.ant-switch-checked .ant-switch-loading-icon {
  left: 100%;
  margin-left: -19px;
}
.ant-switch-loading,
.ant-switch-disabled {
  cursor: not-allowed;
  opacity: 0.4;
}
.ant-switch-loading *,
.ant-switch-disabled * {
  cursor: not-allowed;
}
.ant-switch-loading::before,
.ant-switch-disabled::before,
.ant-switch-loading::after,
.ant-switch-disabled::after {
  cursor: not-allowed;
}
@keyframes AntSwitchSmallLoadingCircle {
  0% {
    transform: rotate(0deg) scale(0.66667);
    transform-origin: 50% 50%;
  }
  100% {
    transform: rotate(360deg) scale(0.66667);
    transform-origin: 50% 50%;
  }
}
.ant-table-wrapper {
  zoom: 1;
}
.ant-table-wrapper::before,
.ant-table-wrapper::after {
  display: table;
  content: '';
}
.ant-table-wrapper::after {
  clear: both;
}
.ant-table-wrapper::before,
.ant-table-wrapper::after {
  display: table;
  content: '';
}
.ant-table-wrapper::after {
  clear: both;
}
.ant-table {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(34, 34, 34, 0.8);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: "tnum";
  position: relative;
  clear: both;
}
.ant-table-body {
  transition: opacity 0.3s;
}
.ant-table-empty .ant-table-body {
  overflow-x: auto !important;
  overflow-y: hidden !important;
}
.ant-table table {
  width: 100%;
  text-align: left;
  border-radius: 10px 10px 0 0;
  border-collapse: separate;
  border-spacing: 0;
}
.ant-table-layout-fixed table {
  table-layout: fixed;
}
.ant-table-thead > tr > th {
  color: rgba(255, 255, 255, 0.85);
  font-weight: 500;
  text-align: left;
  background: #fafafa;
  border-bottom: 1px solid #f0f0f0;
  transition: background 0.3s ease;
}
.ant-table-thead > tr > th[colspan]:not([colspan='1']) {
  text-align: center;
}
.ant-table-thead > tr > th .anticon-filter,
.ant-table-thead > tr > th .ant-table-filter-icon {
  position: absolute;
  top: 0;
  right: 0;
  width: 28px;
  height: 100%;
  color: #bfbfbf;
  font-size: 12px;
  text-align: center;
  cursor: pointer;
  transition: all 0.3s;
}
.ant-table-thead > tr > th .anticon-filter > svg,
.ant-table-thead > tr > th .ant-table-filter-icon > svg {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -5px;
  margin-left: -6px;
}
.ant-table-thead > tr > th .ant-table-filter-selected.anticon {
  color: #00a6ff;
}
.ant-table-thead > tr > th .ant-table-column-sorter {
  display: table-cell;
  vertical-align: middle;
}
.ant-table-thead > tr > th .ant-table-column-sorter .ant-table-column-sorter-inner {
  height: 1em;
  margin-top: 0.35em;
  margin-left: 0.57142857em;
  color: #bfbfbf;
  line-height: 1em;
  text-align: center;
  transition: all 0.3s;
}
.ant-table-thead > tr > th .ant-table-column-sorter .ant-table-column-sorter-inner .ant-table-column-sorter-up,
.ant-table-thead > tr > th .ant-table-column-sorter .ant-table-column-sorter-inner .ant-table-column-sorter-down {
  display: inline-block;
  font-size: 12px;
  font-size: 11px \9;
  transform: scale(0.91666667) rotate(0deg);
  display: block;
  height: 1em;
  line-height: 1em;
  transition: all 0.3s;
}
:root .ant-table-thead > tr > th .ant-table-column-sorter .ant-table-column-sorter-inner .ant-table-column-sorter-up,
:root .ant-table-thead > tr > th .ant-table-column-sorter .ant-table-column-sorter-inner .ant-table-column-sorter-down {
  font-size: 12px;
}
.ant-table-thead > tr > th .ant-table-column-sorter .ant-table-column-sorter-inner .ant-table-column-sorter-up.on,
.ant-table-thead > tr > th .ant-table-column-sorter .ant-table-column-sorter-inner .ant-table-column-sorter-down.on {
  color: #00a6ff;
}
.ant-table-thead > tr > th .ant-table-column-sorter .ant-table-column-sorter-inner-full {
  margin-top: -0.15em;
}
.ant-table-thead > tr > th .ant-table-column-sorter .ant-table-column-sorter-inner-full .ant-table-column-sorter-up,
.ant-table-thead > tr > th .ant-table-column-sorter .ant-table-column-sorter-inner-full .ant-table-column-sorter-down {
  height: 0.5em;
  line-height: 0.5em;
}
.ant-table-thead > tr > th .ant-table-column-sorter .ant-table-column-sorter-inner-full .ant-table-column-sorter-down {
  margin-top: 0.125em;
}
.ant-table-thead > tr > th.ant-table-column-has-actions {
  position: relative;
  background-clip: padding-box;
  /* stylelint-disable-next-line */
  -webkit-background-clip: border-box;
}
.ant-table-thead > tr > th.ant-table-column-has-actions.ant-table-column-has-filters {
  padding-right: 30px !important;
}
.ant-table-thead > tr > th.ant-table-column-has-actions.ant-table-column-has-filters .anticon-filter.ant-table-filter-open,
.ant-table-thead > tr > th.ant-table-column-has-actions.ant-table-column-has-filters .ant-table-filter-icon.ant-table-filter-open {
  color: rgba(34, 34, 34, 0.45);
  background: #e5e5e5;
}
.ant-table-thead > tr > th.ant-table-column-has-actions.ant-table-column-has-filters:hover .anticon-filter:hover,
.ant-table-thead > tr > th.ant-table-column-has-actions.ant-table-column-has-filters:hover .ant-table-filter-icon:hover {
  color: rgba(34, 34, 34, 0.45);
  background: #e5e5e5;
}
.ant-table-thead > tr > th.ant-table-column-has-actions.ant-table-column-has-filters:hover .anticon-filter:active,
.ant-table-thead > tr > th.ant-table-column-has-actions.ant-table-column-has-filters:hover .ant-table-filter-icon:active {
  color: rgba(34, 34, 34, 0.8);
}
.ant-table-thead > tr > th.ant-table-column-has-actions.ant-table-column-has-sorters {
  cursor: pointer;
}
.ant-table-thead > tr > th.ant-table-column-has-actions.ant-table-column-has-sorters:hover {
  background: #f2f2f2;
}
.ant-table-thead > tr > th.ant-table-column-has-actions.ant-table-column-has-sorters:hover .anticon-filter,
.ant-table-thead > tr > th.ant-table-column-has-actions.ant-table-column-has-sorters:hover .ant-table-filter-icon {
  background: #f2f2f2;
}
.ant-table-thead > tr > th.ant-table-column-has-actions.ant-table-column-has-sorters:active .ant-table-column-sorter-up:not(.on),
.ant-table-thead > tr > th.ant-table-column-has-actions.ant-table-column-has-sorters:active .ant-table-column-sorter-down:not(.on) {
  color: rgba(34, 34, 34, 0.45);
}
.ant-table-thead > tr > th .ant-table-header-column {
  display: inline-block;
  max-width: 100%;
  vertical-align: top;
}
.ant-table-thead > tr > th .ant-table-header-column .ant-table-column-sorters {
  display: table;
}
.ant-table-thead > tr > th .ant-table-header-column .ant-table-column-sorters > .ant-table-column-title {
  display: table-cell;
  vertical-align: middle;
}
.ant-table-thead > tr > th .ant-table-header-column .ant-table-column-sorters > *:not(.ant-table-column-sorter) {
  position: relative;
}
.ant-table-thead > tr > th .ant-table-header-column .ant-table-column-sorters::before {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  transition: all 0.3s;
  content: '';
}
.ant-table-thead > tr > th .ant-table-header-column .ant-table-column-sorters:hover::before {
  background: rgba(0, 0, 0, 0.04);
}
.ant-table-thead > tr > th.ant-table-column-has-sorters {
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.ant-table-thead > tr:first-child > th:first-child {
  border-top-left-radius: 10px;
}
.ant-table-thead > tr:first-child > th:last-child {
  border-top-right-radius: 10px;
}
.ant-table-thead > tr:not(:last-child) > th[colspan] {
  border-bottom: 0;
}
.ant-table-tbody > tr > td {
  border-bottom: 1px solid #f0f0f0;
  transition: all 0.3s, border 0s;
}
.ant-table-thead > tr,
.ant-table-tbody > tr {
  transition: all 0.3s, height 0s;
}
.ant-table-thead > tr.ant-table-row-hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) > td,
.ant-table-tbody > tr.ant-table-row-hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) > td,
.ant-table-thead > tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) > td,
.ant-table-tbody > tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) > td {
  background: #f5f5f5;
}
.ant-table-thead > tr.ant-table-row-selected > td.ant-table-column-sort,
.ant-table-tbody > tr.ant-table-row-selected > td.ant-table-column-sort {
  background: #e6faff;
}
.ant-table-thead > tr:hover.ant-table-row-selected > td,
.ant-table-tbody > tr:hover.ant-table-row-selected > td {
  background: #e6faff;
}
.ant-table-thead > tr:hover.ant-table-row-selected > td.ant-table-column-sort,
.ant-table-tbody > tr:hover.ant-table-row-selected > td.ant-table-column-sort {
  background: #e6faff;
}
.ant-table-thead > tr:hover {
  background: none;
}
.ant-table-footer {
  position: relative;
  padding: 16px 16px;
  color: rgba(255, 255, 255, 0.85);
  background: #fafafa;
  border-top: 1px solid #f0f0f0;
  border-radius: 0 0 10px 10px;
}
.ant-table-footer::before {
  position: absolute;
  top: -1px;
  left: 0;
  width: 100%;
  height: 1px;
  background: #fafafa;
  content: '';
}
.ant-table.ant-table-bordered .ant-table-footer {
  border: 1px solid #f0f0f0;
}
.ant-table-title {
  position: relative;
  top: 1px;
  padding: 16px 0;
  border-radius: 10px 10px 0 0;
}
.ant-table.ant-table-bordered .ant-table-title {
  padding-right: 16px;
  padding-left: 16px;
  border: 1px solid #f0f0f0;
}
.ant-table-title + .ant-table-content {
  position: relative;
  border-radius: 10px 10px 0 0;
}
.ant-table-bordered .ant-table-title + .ant-table-content,
.ant-table-bordered .ant-table-title + .ant-table-content table,
.ant-table-bordered .ant-table-title + .ant-table-content .ant-table-thead > tr:first-child > th {
  border-radius: 0;
}
.ant-table-without-column-header .ant-table-title + .ant-table-content,
.ant-table-without-column-header table {
  border-radius: 0;
}
.ant-table-without-column-header.ant-table-bordered.ant-table-empty .ant-table-placeholder {
  border-top: 1px solid #f0f0f0;
  border-radius: 10px;
}
.ant-table-tbody > tr.ant-table-row-selected td {
  color: inherit;
  background: #e6faff;
}
.ant-table-thead > tr > th.ant-table-column-sort {
  background: #cccccc;
}
.ant-table-tbody > tr > td.ant-table-column-sort {
  background: rgba(0, 0, 0, 0.01);
}
.ant-table-thead > tr > th,
.ant-table-tbody > tr > td {
  padding: 16px 16px;
  overflow-wrap: break-word;
}
.ant-table-expand-icon-th,
.ant-table-row-expand-icon-cell {
  width: 50px;
  min-width: 50px;
  text-align: center;
}
.ant-table-header {
  overflow: hidden;
  background: #fafafa;
}
.ant-table-header table {
  border-radius: 10px 10px 0 0;
}
.ant-table-loading {
  position: relative;
}
.ant-table-loading .ant-table-body {
  background: #fff;
  opacity: 0.5;
}
.ant-table-loading .ant-table-spin-holder {
  position: absolute;
  top: 50%;
  left: 50%;
  height: 20px;
  margin-left: -30px;
  line-height: 20px;
}
.ant-table-loading .ant-table-with-pagination {
  margin-top: -20px;
}
.ant-table-loading .ant-table-without-pagination {
  margin-top: 10px;
}
.ant-table-bordered .ant-table-header > table,
.ant-table-bordered .ant-table-body > table,
.ant-table-bordered .ant-table-fixed-left table,
.ant-table-bordered .ant-table-fixed-right table {
  border: 1px solid #f0f0f0;
  border-right: 0;
  border-bottom: 0;
}
.ant-table-bordered.ant-table-empty .ant-table-placeholder {
  border-right: 1px solid #f0f0f0;
  border-left: 1px solid #f0f0f0;
}
.ant-table-bordered.ant-table-fixed-header .ant-table-header > table {
  border-bottom: 0;
}
.ant-table-bordered.ant-table-fixed-header .ant-table-body > table {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.ant-table-bordered.ant-table-fixed-header .ant-table-header + .ant-table-body > table,
.ant-table-bordered.ant-table-fixed-header .ant-table-body-inner > table {
  border-top: 0;
}
.ant-table-bordered .ant-table-thead > tr:not(:last-child) > th {
  border-bottom: 1px solid #f0f0f0;
}
.ant-table-bordered .ant-table-thead > tr > th,
.ant-table-bordered .ant-table-tbody > tr > td {
  border-right: 1px solid #f0f0f0;
}
.ant-table-placeholder {
  position: relative;
  z-index: 1;
  margin-top: -1px;
  padding: 16px 16px;
  color: #fff;
  font-size: 14px;
  text-align: center;
  background: #fff;
  border-top: 1px solid #f0f0f0;
  border-bottom: 1px solid #f0f0f0;
  border-radius: 0 0 10px 10px;
}
.ant-table-pagination.ant-pagination {
  float: right;
  margin: 16px 0;
}
.ant-table-filter-dropdown {
  position: relative;
  min-width: 96px;
  margin-left: -8px;
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 0 1px 1px rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.08), 0 2px 8px 2px rgba(0, 0, 0, 0.05);
}
.ant-table-filter-dropdown .ant-dropdown-menu {
  max-height: calc(100vh - 130px);
  overflow-x: hidden;
  border: 0;
  border-radius: 10px 10px 0 0;
  box-shadow: none;
}
.ant-table-filter-dropdown .ant-dropdown-menu-item > label + span {
  padding-right: 0;
}
.ant-table-filter-dropdown .ant-dropdown-menu-sub {
  border-radius: 10px;
  box-shadow: 0 0 1px 1px rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.08), 0 2px 8px 2px rgba(0, 0, 0, 0.05);
}
.ant-table-filter-dropdown .ant-dropdown-menu .ant-dropdown-submenu-contain-selected .ant-dropdown-menu-submenu-title::after {
  color: #00a6ff;
  font-weight: bold;
  text-shadow: 0 0 2px #a3ebff;
}
.ant-table-filter-dropdown .ant-dropdown-menu-item {
  overflow: hidden;
}
.ant-table-filter-dropdown > .ant-dropdown-menu > .ant-dropdown-menu-item:last-child,
.ant-table-filter-dropdown > .ant-dropdown-menu > .ant-dropdown-menu-submenu:last-child .ant-dropdown-menu-submenu-title {
  border-radius: 0;
}
.ant-table-filter-dropdown-btns {
  padding: 7px 8px;
  overflow: hidden;
  border-top: 1px solid #f0f0f0;
}
.ant-table-filter-dropdown-link {
  color: #1c8eff;
}
.ant-table-filter-dropdown-link:hover {
  color: #45a8ff;
}
.ant-table-filter-dropdown-link:active {
  color: #0d6cd9;
}
.ant-table-filter-dropdown-link.confirm {
  float: left;
}
.ant-table-filter-dropdown-link.clear {
  float: right;
}
.ant-table-selection {
  white-space: nowrap;
}
.ant-table-selection-select-all-custom {
  margin-right: 4px !important;
}
.ant-table-selection .anticon-down {
  color: #bfbfbf;
  transition: all 0.3s;
}
.ant-table-selection-menu {
  min-width: 96px;
  margin-top: 5px;
  margin-left: -30px;
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 0 1px 1px rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.08), 0 2px 8px 2px rgba(0, 0, 0, 0.05);
}
.ant-table-selection-menu .ant-action-down {
  color: #bfbfbf;
}
.ant-table-selection-down {
  display: inline-block;
  padding: 0;
  line-height: 1;
  cursor: pointer;
}
.ant-table-selection-down:hover .anticon-down {
  color: rgba(34, 34, 34, 0.6);
}
.ant-table-row-expand-icon {
  color: #1c8eff;
  text-decoration: none;
  cursor: pointer;
  transition: color 0.3s;
  display: inline-block;
  width: 17px;
  height: 17px;
  color: inherit;
  line-height: 13px;
  text-align: center;
  background: #fff;
  border: 1px solid #f0f0f0;
  border-radius: 10px;
  outline: none;
  transition: all 0.3s;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.ant-table-row-expand-icon:focus,
.ant-table-row-expand-icon:hover {
  color: #45a8ff;
}
.ant-table-row-expand-icon:active {
  color: #0d6cd9;
}
.ant-table-row-expand-icon:focus,
.ant-table-row-expand-icon:hover,
.ant-table-row-expand-icon:active {
  border-color: currentColor;
}
.ant-table-row-expanded::after {
  content: '-';
}
.ant-table-row-collapsed::after {
  content: '+';
}
.ant-table-row-spaced {
  visibility: hidden;
}
.ant-table-row-spaced::after {
  content: '.';
}
.ant-table-row-cell-ellipsis,
.ant-table-row-cell-ellipsis .ant-table-column-title {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.ant-table-row-cell-ellipsis .ant-table-column-title {
  display: block;
}
.ant-table-row-cell-break-word {
  word-wrap: break-word;
  word-break: break-word;
}
tr.ant-table-expanded-row,
tr.ant-table-expanded-row:hover {
  background: #fbfbfb;
}
tr.ant-table-expanded-row td > .ant-table-wrapper {
  margin: -16px -16px -17px;
}
.ant-table .ant-table-row-indent + .ant-table-row-expand-icon {
  margin-right: 8px;
}
.ant-table-scroll {
  overflow: auto;
  overflow-x: hidden;
}
.ant-table-scroll table {
  min-width: 100%;
}
.ant-table-scroll table .ant-table-fixed-columns-in-body:not([colspan]) {
  color: transparent;
}
.ant-table-scroll table .ant-table-fixed-columns-in-body:not([colspan]) > * {
  visibility: hidden;
}
.ant-table-body-inner {
  height: 100%;
}
.ant-table-fixed-header > .ant-table-content > .ant-table-scroll > .ant-table-body {
  position: relative;
  background: #fff;
}
.ant-table-fixed-header .ant-table-body-inner {
  overflow: scroll;
}
.ant-table-fixed-header .ant-table-scroll .ant-table-header {
  margin-bottom: -20px;
  padding-bottom: 20px;
  overflow: scroll;
  opacity: 0.9999;
}
.ant-table-fixed-header .ant-table-scroll .ant-table-header::-webkit-scrollbar {
  border: 1px solid #f0f0f0;
  border-width: 0 0 1px 0;
}
.ant-table-hide-scrollbar {
  scrollbar-color: transparent transparent;
  min-width: unset;
}
.ant-table-hide-scrollbar::-webkit-scrollbar {
  min-width: inherit;
  background-color: transparent;
}
.ant-table-bordered.ant-table-fixed-header .ant-table-scroll .ant-table-header::-webkit-scrollbar {
  border: 1px solid #f0f0f0;
  border-width: 1px 1px 1px 0;
}
.ant-table-bordered.ant-table-fixed-header .ant-table-scroll .ant-table-header.ant-table-hide-scrollbar .ant-table-thead > tr:only-child > th:last-child {
  border-right-color: transparent;
}
.ant-table-fixed-left,
.ant-table-fixed-right {
  position: absolute;
  top: 0;
  z-index: auto;
  overflow: hidden;
  border-radius: 0;
  transition: box-shadow 0.3s ease;
}
.ant-table-fixed-left table,
.ant-table-fixed-right table {
  width: auto;
  background: #fff;
}
.ant-table-fixed-header .ant-table-fixed-left .ant-table-body-outer .ant-table-fixed,
.ant-table-fixed-header .ant-table-fixed-right .ant-table-body-outer .ant-table-fixed {
  border-radius: 0;
}
.ant-table-fixed-left {
  left: 0;
  box-shadow: 6px 0 6px -4px rgba(0, 0, 0, 0.15);
}
.ant-table-fixed-left .ant-table-header {
  overflow-y: hidden;
}
.ant-table-fixed-left .ant-table-body-inner {
  margin-right: -20px;
  padding-right: 20px;
}
.ant-table-fixed-header .ant-table-fixed-left .ant-table-body-inner {
  padding-right: 0;
}
.ant-table-fixed-left,
.ant-table-fixed-left table {
  border-radius: 10px 0 0 0;
}
.ant-table-fixed-left .ant-table-thead > tr > th:last-child {
  border-top-right-radius: 0;
}
.ant-table-fixed-right {
  right: 0;
  box-shadow: -6px 0 6px -4px rgba(0, 0, 0, 0.15);
}
.ant-table-fixed-right,
.ant-table-fixed-right table {
  border-radius: 0 10px 0 0;
}
.ant-table-fixed-right .ant-table-expanded-row {
  color: transparent;
  pointer-events: none;
}
.ant-table-fixed-right .ant-table-thead > tr > th:first-child {
  border-top-left-radius: 0;
}
.ant-table.ant-table-scroll-position-left .ant-table-fixed-left {
  box-shadow: none;
}
.ant-table.ant-table-scroll-position-right .ant-table-fixed-right {
  box-shadow: none;
}
.ant-table colgroup > col.ant-table-selection-col {
  width: 60px;
}
.ant-table-thead > tr > th.ant-table-selection-column-custom .ant-table-selection {
  margin-right: -15px;
}
.ant-table-thead > tr > th.ant-table-selection-column,
.ant-table-tbody > tr > td.ant-table-selection-column {
  text-align: center;
}
.ant-table-thead > tr > th.ant-table-selection-column .ant-radio-wrapper,
.ant-table-tbody > tr > td.ant-table-selection-column .ant-radio-wrapper {
  margin-right: 0;
}
.ant-table-row[class*='ant-table-row-level-0'] .ant-table-selection-column > span {
  display: inline-block;
}
.ant-table-filter-dropdown .ant-checkbox-wrapper + span,
.ant-table-filter-dropdown-submenu .ant-checkbox-wrapper + span {
  padding-left: 8px;
}
/**
* Another fix of Firefox:
*/
@supports (-moz-appearance: meterbar) {
  .ant-table-thead > tr > th.ant-table-column-has-actions {
    background-clip: padding-box;
  }
}
.ant-table-middle > .ant-table-title,
.ant-table-middle > .ant-table-content > .ant-table-footer {
  padding: 12px 8px;
}
.ant-table-middle > .ant-table-content > .ant-table-header > table > .ant-table-thead > tr > th,
.ant-table-middle > .ant-table-content > .ant-table-body > table > .ant-table-thead > tr > th,
.ant-table-middle > .ant-table-content > .ant-table-scroll > .ant-table-header > table > .ant-table-thead > tr > th,
.ant-table-middle > .ant-table-content > .ant-table-scroll > .ant-table-body > table > .ant-table-thead > tr > th,
.ant-table-middle > .ant-table-content > .ant-table-fixed-left > .ant-table-header > table > .ant-table-thead > tr > th,
.ant-table-middle > .ant-table-content > .ant-table-fixed-right > .ant-table-header > table > .ant-table-thead > tr > th,
.ant-table-middle > .ant-table-content > .ant-table-fixed-left > .ant-table-body-outer > .ant-table-body-inner > table > .ant-table-thead > tr > th,
.ant-table-middle > .ant-table-content > .ant-table-fixed-right > .ant-table-body-outer > .ant-table-body-inner > table > .ant-table-thead > tr > th,
.ant-table-middle > .ant-table-content > .ant-table-header > table > .ant-table-tbody > tr > td,
.ant-table-middle > .ant-table-content > .ant-table-body > table > .ant-table-tbody > tr > td,
.ant-table-middle > .ant-table-content > .ant-table-scroll > .ant-table-header > table > .ant-table-tbody > tr > td,
.ant-table-middle > .ant-table-content > .ant-table-scroll > .ant-table-body > table > .ant-table-tbody > tr > td,
.ant-table-middle > .ant-table-content > .ant-table-fixed-left > .ant-table-header > table > .ant-table-tbody > tr > td,
.ant-table-middle > .ant-table-content > .ant-table-fixed-right > .ant-table-header > table > .ant-table-tbody > tr > td,
.ant-table-middle > .ant-table-content > .ant-table-fixed-left > .ant-table-body-outer > .ant-table-body-inner > table > .ant-table-tbody > tr > td,
.ant-table-middle > .ant-table-content > .ant-table-fixed-right > .ant-table-body-outer > .ant-table-body-inner > table > .ant-table-tbody > tr > td {
  padding: 12px 8px;
}
.ant-table-middle tr.ant-table-expanded-row td > .ant-table-wrapper {
  margin: -12px -8px -13px;
}
.ant-table-small {
  border: 1px solid #f0f0f0;
  border-radius: 10px;
}
.ant-table-small > .ant-table-title,
.ant-table-small > .ant-table-content > .ant-table-footer {
  padding: 8px 8px;
}
.ant-table-small > .ant-table-title {
  top: 0;
  border-bottom: 1px solid #f0f0f0;
}
.ant-table-small > .ant-table-content > .ant-table-footer {
  background-color: transparent;
  border-top: 1px solid #f0f0f0;
}
.ant-table-small > .ant-table-content > .ant-table-footer::before {
  background-color: transparent;
}
.ant-table-small > .ant-table-content > .ant-table-body {
  margin: 0 8px;
}
.ant-table-small > .ant-table-content > .ant-table-header > table,
.ant-table-small > .ant-table-content > .ant-table-body > table,
.ant-table-small > .ant-table-content > .ant-table-scroll > .ant-table-header > table,
.ant-table-small > .ant-table-content > .ant-table-scroll > .ant-table-body > table,
.ant-table-small > .ant-table-content > .ant-table-fixed-left > .ant-table-header > table,
.ant-table-small > .ant-table-content > .ant-table-fixed-right > .ant-table-header > table,
.ant-table-small > .ant-table-content > .ant-table-fixed-left > .ant-table-body-outer > .ant-table-body-inner > table,
.ant-table-small > .ant-table-content > .ant-table-fixed-right > .ant-table-body-outer > .ant-table-body-inner > table {
  border: 0;
}
.ant-table-small > .ant-table-content > .ant-table-header > table > .ant-table-thead > tr > th,
.ant-table-small > .ant-table-content > .ant-table-body > table > .ant-table-thead > tr > th,
.ant-table-small > .ant-table-content > .ant-table-scroll > .ant-table-header > table > .ant-table-thead > tr > th,
.ant-table-small > .ant-table-content > .ant-table-scroll > .ant-table-body > table > .ant-table-thead > tr > th,
.ant-table-small > .ant-table-content > .ant-table-fixed-left > .ant-table-header > table > .ant-table-thead > tr > th,
.ant-table-small > .ant-table-content > .ant-table-fixed-right > .ant-table-header > table > .ant-table-thead > tr > th,
.ant-table-small > .ant-table-content > .ant-table-fixed-left > .ant-table-body-outer > .ant-table-body-inner > table > .ant-table-thead > tr > th,
.ant-table-small > .ant-table-content > .ant-table-fixed-right > .ant-table-body-outer > .ant-table-body-inner > table > .ant-table-thead > tr > th,
.ant-table-small > .ant-table-content > .ant-table-header > table > .ant-table-tbody > tr > td,
.ant-table-small > .ant-table-content > .ant-table-body > table > .ant-table-tbody > tr > td,
.ant-table-small > .ant-table-content > .ant-table-scroll > .ant-table-header > table > .ant-table-tbody > tr > td,
.ant-table-small > .ant-table-content > .ant-table-scroll > .ant-table-body > table > .ant-table-tbody > tr > td,
.ant-table-small > .ant-table-content > .ant-table-fixed-left > .ant-table-header > table > .ant-table-tbody > tr > td,
.ant-table-small > .ant-table-content > .ant-table-fixed-right > .ant-table-header > table > .ant-table-tbody > tr > td,
.ant-table-small > .ant-table-content > .ant-table-fixed-left > .ant-table-body-outer > .ant-table-body-inner > table > .ant-table-tbody > tr > td,
.ant-table-small > .ant-table-content > .ant-table-fixed-right > .ant-table-body-outer > .ant-table-body-inner > table > .ant-table-tbody > tr > td {
  padding: 8px 8px;
}
.ant-table-small > .ant-table-content > .ant-table-header > table > .ant-table-thead > tr > th,
.ant-table-small > .ant-table-content > .ant-table-body > table > .ant-table-thead > tr > th,
.ant-table-small > .ant-table-content > .ant-table-scroll > .ant-table-header > table > .ant-table-thead > tr > th,
.ant-table-small > .ant-table-content > .ant-table-scroll > .ant-table-body > table > .ant-table-thead > tr > th,
.ant-table-small > .ant-table-content > .ant-table-fixed-left > .ant-table-header > table > .ant-table-thead > tr > th,
.ant-table-small > .ant-table-content > .ant-table-fixed-right > .ant-table-header > table > .ant-table-thead > tr > th,
.ant-table-small > .ant-table-content > .ant-table-fixed-left > .ant-table-body-outer > .ant-table-body-inner > table > .ant-table-thead > tr > th,
.ant-table-small > .ant-table-content > .ant-table-fixed-right > .ant-table-body-outer > .ant-table-body-inner > table > .ant-table-thead > tr > th {
  background-color: transparent;
}
.ant-table-small > .ant-table-content > .ant-table-header > table > .ant-table-thead > tr,
.ant-table-small > .ant-table-content > .ant-table-body > table > .ant-table-thead > tr,
.ant-table-small > .ant-table-content > .ant-table-scroll > .ant-table-header > table > .ant-table-thead > tr,
.ant-table-small > .ant-table-content > .ant-table-scroll > .ant-table-body > table > .ant-table-thead > tr,
.ant-table-small > .ant-table-content > .ant-table-fixed-left > .ant-table-header > table > .ant-table-thead > tr,
.ant-table-small > .ant-table-content > .ant-table-fixed-right > .ant-table-header > table > .ant-table-thead > tr,
.ant-table-small > .ant-table-content > .ant-table-fixed-left > .ant-table-body-outer > .ant-table-body-inner > table > .ant-table-thead > tr,
.ant-table-small > .ant-table-content > .ant-table-fixed-right > .ant-table-body-outer > .ant-table-body-inner > table > .ant-table-thead > tr {
  border-bottom: 1px solid #f0f0f0;
}
.ant-table-small > .ant-table-content > .ant-table-header > table > .ant-table-thead > tr > th.ant-table-column-sort,
.ant-table-small > .ant-table-content > .ant-table-body > table > .ant-table-thead > tr > th.ant-table-column-sort,
.ant-table-small > .ant-table-content > .ant-table-scroll > .ant-table-header > table > .ant-table-thead > tr > th.ant-table-column-sort,
.ant-table-small > .ant-table-content > .ant-table-scroll > .ant-table-body > table > .ant-table-thead > tr > th.ant-table-column-sort,
.ant-table-small > .ant-table-content > .ant-table-fixed-left > .ant-table-header > table > .ant-table-thead > tr > th.ant-table-column-sort,
.ant-table-small > .ant-table-content > .ant-table-fixed-right > .ant-table-header > table > .ant-table-thead > tr > th.ant-table-column-sort,
.ant-table-small > .ant-table-content > .ant-table-fixed-left > .ant-table-body-outer > .ant-table-body-inner > table > .ant-table-thead > tr > th.ant-table-column-sort,
.ant-table-small > .ant-table-content > .ant-table-fixed-right > .ant-table-body-outer > .ant-table-body-inner > table > .ant-table-thead > tr > th.ant-table-column-sort {
  background-color: rgba(0, 0, 0, 0.01);
}
.ant-table-small > .ant-table-content > .ant-table-scroll > .ant-table-header > table,
.ant-table-small > .ant-table-content > .ant-table-scroll > .ant-table-body > table,
.ant-table-small > .ant-table-content > .ant-table-fixed-left > .ant-table-header > table,
.ant-table-small > .ant-table-content > .ant-table-fixed-right > .ant-table-header > table,
.ant-table-small > .ant-table-content > .ant-table-fixed-left > .ant-table-body-outer > .ant-table-body-inner > table,
.ant-table-small > .ant-table-content > .ant-table-fixed-right > .ant-table-body-outer > .ant-table-body-inner > table {
  padding: 0;
}
.ant-table-small > .ant-table-content .ant-table-header {
  background-color: transparent;
  border-radius: 10px 10px 0 0;
}
.ant-table-small > .ant-table-content .ant-table-placeholder,
.ant-table-small > .ant-table-content .ant-table-row:last-child td {
  border-bottom: 0;
}
.ant-table-small.ant-table-bordered {
  border-right: 0;
}
.ant-table-small.ant-table-bordered .ant-table-title {
  border: 0;
  border-right: 1px solid #f0f0f0;
  border-bottom: 1px solid #f0f0f0;
}
.ant-table-small.ant-table-bordered .ant-table-content {
  border-right: 1px solid #f0f0f0;
}
.ant-table-small.ant-table-bordered .ant-table-footer {
  border: 0;
  border-top: 1px solid #f0f0f0;
}
.ant-table-small.ant-table-bordered .ant-table-footer::before {
  display: none;
}
.ant-table-small.ant-table-bordered .ant-table-placeholder {
  border-right: 0;
  border-bottom: 0;
  border-left: 0;
}
.ant-table-small.ant-table-bordered .ant-table-thead > tr > th.ant-table-row-cell-last,
.ant-table-small.ant-table-bordered .ant-table-tbody > tr > td:last-child {
  border-right: none;
}
.ant-table-small.ant-table-bordered .ant-table-fixed-left .ant-table-thead > tr > th:last-child,
.ant-table-small.ant-table-bordered .ant-table-fixed-left .ant-table-tbody > tr > td:last-child {
  border-right: 1px solid #f0f0f0;
}
.ant-table-small.ant-table-bordered .ant-table-fixed-right {
  border-right: 1px solid #f0f0f0;
  border-left: 1px solid #f0f0f0;
}
.ant-table-small tr.ant-table-expanded-row td > .ant-table-wrapper {
  margin: -8px -8px -9px;
}
.ant-table-small.ant-table-fixed-header > .ant-table-content > .ant-table-scroll > .ant-table-body {
  border-radius: 0 0 10px 10px;
}
.ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-nav-container {
  height: 40px;
}
.ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-ink-bar {
  visibility: hidden;
}
.ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab {
  height: 40px;
  margin: 0;
  margin-right: 2px;
  padding: 0 16px;
  line-height: 38px;
  background: #fafafa;
  border: 1px solid #f0f0f0;
  border-radius: 10px 10px 0 0;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab-active {
  height: 40px;
  color: #00a6ff;
  background: #fff;
  border-color: #f0f0f0;
  border-bottom: 1px solid #fff;
}
.ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab-active::before {
  border-top: 2px solid transparent;
}
.ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab-disabled {
  color: #00a6ff;
  color: #fff;
}
.ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab-inactive {
  padding: 0;
}
.ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-nav-wrap {
  margin-bottom: 0;
}
.ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab .ant-tabs-close-x {
  width: 16px;
  height: 16px;
  height: 14px;
  margin-right: -5px;
  margin-left: 3px;
  overflow: hidden;
  color: rgba(34, 34, 34, 0.45);
  font-size: 12px;
  vertical-align: middle;
  transition: all 0.3s;
}
.ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab .ant-tabs-close-x:hover {
  color: rgba(255, 255, 255, 0.85);
}
.ant-tabs.ant-tabs-card .ant-tabs-card-content > .ant-tabs-tabpane,
.ant-tabs.ant-tabs-editable-card .ant-tabs-card-content > .ant-tabs-tabpane {
  transition: none !important;
}
.ant-tabs.ant-tabs-card .ant-tabs-card-content > .ant-tabs-tabpane-inactive,
.ant-tabs.ant-tabs-editable-card .ant-tabs-card-content > .ant-tabs-tabpane-inactive {
  overflow: hidden;
}
.ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab:hover .anticon-close {
  opacity: 1;
}
.ant-tabs-extra-content {
  line-height: 46.001px;
}
.ant-tabs-extra-content .ant-tabs-new-tab {
  position: relative;
  width: 20px;
  height: 20px;
  color: rgba(34, 34, 34, 0.8);
  font-size: 12px;
  line-height: 20px;
  text-align: center;
  border: 1px solid #f0f0f0;
  border-radius: 10px;
  cursor: pointer;
  transition: all 0.3s;
}
.ant-tabs-extra-content .ant-tabs-new-tab:hover {
  color: #00a6ff;
  border-color: #00a6ff;
}
.ant-tabs-extra-content .ant-tabs-new-tab svg {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
}
.ant-tabs.ant-tabs-large .ant-tabs-extra-content {
  line-height: 54.001px;
}
.ant-tabs.ant-tabs-small .ant-tabs-extra-content {
  line-height: 38.001px;
}
.ant-tabs.ant-tabs-card .ant-tabs-extra-content {
  line-height: 40px;
}
.ant-tabs-vertical.ant-tabs-card .ant-tabs-card-bar.ant-tabs-left-bar .ant-tabs-nav-container,
.ant-tabs-vertical.ant-tabs-card .ant-tabs-card-bar.ant-tabs-right-bar .ant-tabs-nav-container {
  height: 100%;
}
.ant-tabs-vertical.ant-tabs-card .ant-tabs-card-bar.ant-tabs-left-bar .ant-tabs-tab,
.ant-tabs-vertical.ant-tabs-card .ant-tabs-card-bar.ant-tabs-right-bar .ant-tabs-tab {
  margin-bottom: 8px;
  border-bottom: 1px solid #f0f0f0;
}
.ant-tabs-vertical.ant-tabs-card .ant-tabs-card-bar.ant-tabs-left-bar .ant-tabs-tab-active,
.ant-tabs-vertical.ant-tabs-card .ant-tabs-card-bar.ant-tabs-right-bar .ant-tabs-tab-active {
  padding-bottom: 4px;
}
.ant-tabs-vertical.ant-tabs-card .ant-tabs-card-bar.ant-tabs-left-bar .ant-tabs-tab:last-child,
.ant-tabs-vertical.ant-tabs-card .ant-tabs-card-bar.ant-tabs-right-bar .ant-tabs-tab:last-child {
  margin-bottom: 8px;
}
.ant-tabs-vertical.ant-tabs-card .ant-tabs-card-bar.ant-tabs-left-bar .ant-tabs-new-tab,
.ant-tabs-vertical.ant-tabs-card .ant-tabs-card-bar.ant-tabs-right-bar .ant-tabs-new-tab {
  width: 90%;
}
.ant-tabs-vertical.ant-tabs-card.ant-tabs-left .ant-tabs-card-bar.ant-tabs-left-bar .ant-tabs-nav-wrap {
  margin-right: 0;
}
.ant-tabs-vertical.ant-tabs-card.ant-tabs-left .ant-tabs-card-bar.ant-tabs-left-bar .ant-tabs-tab {
  margin-right: 1px;
  border-right: 0;
  border-radius: 10px 0 0 10px;
}
.ant-tabs-vertical.ant-tabs-card.ant-tabs-left .ant-tabs-card-bar.ant-tabs-left-bar .ant-tabs-tab-active {
  margin-right: -1px;
  padding-right: 18px;
}
.ant-tabs-vertical.ant-tabs-card.ant-tabs-right .ant-tabs-card-bar.ant-tabs-right-bar .ant-tabs-nav-wrap {
  margin-left: 0;
}
.ant-tabs-vertical.ant-tabs-card.ant-tabs-right .ant-tabs-card-bar.ant-tabs-right-bar .ant-tabs-tab {
  margin-left: 1px;
  border-left: 0;
  border-radius: 0 10px 10px 0;
}
.ant-tabs-vertical.ant-tabs-card.ant-tabs-right .ant-tabs-card-bar.ant-tabs-right-bar .ant-tabs-tab-active {
  margin-left: -1px;
  padding-left: 18px;
}
.ant-tabs .ant-tabs-card-bar.ant-tabs-bottom-bar .ant-tabs-tab {
  height: auto;
  border-top: 0;
  border-bottom: 1px solid #f0f0f0;
  border-radius: 0 0 10px 10px;
}
.ant-tabs .ant-tabs-card-bar.ant-tabs-bottom-bar .ant-tabs-tab-active {
  padding-top: 1px;
  padding-bottom: 0;
  color: #00a6ff;
}
.ant-tabs {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(34, 34, 34, 0.8);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: "tnum";
  position: relative;
  overflow: hidden;
  zoom: 1;
}
.ant-tabs::before,
.ant-tabs::after {
  display: table;
  content: '';
}
.ant-tabs::after {
  clear: both;
}
.ant-tabs::before,
.ant-tabs::after {
  display: table;
  content: '';
}
.ant-tabs::after {
  clear: both;
}
.ant-tabs-ink-bar {
  position: absolute;
  bottom: 1px;
  left: 0;
  z-index: 1;
  box-sizing: border-box;
  width: 0;
  height: 2px;
  background-color: #00a6ff;
  transform-origin: 0 0;
}
.ant-tabs-bar {
  margin: 0 0 16px 0;
  outline: none;
  transition: padding 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.ant-tabs-nav-container {
  position: relative;
  box-sizing: border-box;
  margin-bottom: -1px;
  overflow: hidden;
  font-size: 14px;
  line-height: 1.5715;
  white-space: nowrap;
  transition: padding 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  zoom: 1;
}
.ant-tabs-nav-container::before,
.ant-tabs-nav-container::after {
  display: table;
  content: '';
}
.ant-tabs-nav-container::after {
  clear: both;
}
.ant-tabs-nav-container::before,
.ant-tabs-nav-container::after {
  display: table;
  content: '';
}
.ant-tabs-nav-container::after {
  clear: both;
}
.ant-tabs-nav-container-scrolling {
  padding-right: 32px;
  padding-left: 32px;
}
.ant-tabs-bottom .ant-tabs-bottom-bar {
  margin-top: 16px;
  margin-bottom: 0;
  border-top: 1px solid #f0f0f0;
  border-bottom: none;
}
.ant-tabs-bottom .ant-tabs-bottom-bar .ant-tabs-ink-bar {
  top: 1px;
  bottom: auto;
}
.ant-tabs-bottom .ant-tabs-bottom-bar .ant-tabs-nav-container {
  margin-top: -1px;
  margin-bottom: 0;
}
.ant-tabs-tab-prev,
.ant-tabs-tab-next {
  position: absolute;
  z-index: 2;
  width: 0;
  height: 100%;
  color: rgba(34, 34, 34, 0.45);
  text-align: center;
  background-color: transparent;
  border: 0;
  cursor: pointer;
  opacity: 0;
  transition: width 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), opacity 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  pointer-events: none;
}
.ant-tabs-tab-prev.ant-tabs-tab-arrow-show,
.ant-tabs-tab-next.ant-tabs-tab-arrow-show {
  width: 32px;
  height: 100%;
  opacity: 1;
  pointer-events: auto;
}
.ant-tabs-tab-prev:hover,
.ant-tabs-tab-next:hover {
  color: rgba(34, 34, 34, 0.8);
}
.ant-tabs-tab-prev-icon,
.ant-tabs-tab-next-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  font-weight: bold;
  font-style: normal;
  font-feature-settings: normal;
  font-variant: normal;
  line-height: inherit;
  text-align: center;
  text-transform: none;
  transform: translate(-50%, -50%);
}
.ant-tabs-tab-prev-icon-target,
.ant-tabs-tab-next-icon-target {
  display: block;
  display: inline-block;
  font-size: 12px;
  font-size: 10px \9;
  transform: scale(0.83333333) rotate(0deg);
}
:root .ant-tabs-tab-prev-icon-target,
:root .ant-tabs-tab-next-icon-target {
  font-size: 12px;
}
.ant-tabs-tab-btn-disabled {
  cursor: not-allowed;
}
.ant-tabs-tab-btn-disabled,
.ant-tabs-tab-btn-disabled:hover {
  color: #fff;
}
.ant-tabs-tab-next {
  right: 2px;
}
.ant-tabs-tab-prev {
  left: 0;
}
:root .ant-tabs-tab-prev {
  filter: none;
}
.ant-tabs-nav-wrap {
  margin-bottom: -1px;
  overflow: hidden;
}
.ant-tabs-nav-scroll {
  overflow: hidden;
  white-space: nowrap;
}
.ant-tabs-nav {
  position: relative;
  display: inline-block;
  box-sizing: border-box;
  margin: 0;
  padding-left: 0;
  list-style: none;
  transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.ant-tabs-nav::before,
.ant-tabs-nav::after {
  display: table;
  content: " ";
}
.ant-tabs-nav::after {
  clear: both;
}
.ant-tabs-nav .ant-tabs-tab {
  position: relative;
  display: inline-block;
  box-sizing: border-box;
  height: 100%;
  margin: 0 32px 0 0;
  padding: 12px 16px;
  text-decoration: none;
  cursor: pointer;
  transition: color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.ant-tabs-nav .ant-tabs-tab::before {
  position: absolute;
  top: -1px;
  left: 0;
  width: 100%;
  border-top: 2px solid transparent;
  border-radius: 10px 10px 0 0;
  transition: all 0.3s;
  content: "";
  pointer-events: none;
}
.ant-tabs-nav .ant-tabs-tab:last-child {
  margin-right: 0;
}
.ant-tabs-nav .ant-tabs-tab:hover {
  color: #29bbff;
}
.ant-tabs-nav .ant-tabs-tab:active {
  color: #0086d9;
}
.ant-tabs-nav .ant-tabs-tab .anticon {
  margin-right: 8px;
}
.ant-tabs-nav .ant-tabs-tab-active {
  color: #00a6ff;
  font-weight: 500;
}
.ant-tabs-nav .ant-tabs-tab-disabled,
.ant-tabs-nav .ant-tabs-tab-disabled:hover {
  color: #fff;
  cursor: not-allowed;
}
.ant-tabs .ant-tabs-large-bar .ant-tabs-nav-container {
  font-size: 14px;
}
.ant-tabs .ant-tabs-large-bar .ant-tabs-tab {
  padding: 16px;
}
.ant-tabs .ant-tabs-small-bar .ant-tabs-nav-container {
  font-size: 14px;
}
.ant-tabs .ant-tabs-small-bar .ant-tabs-tab {
  padding: 8px 16px;
}
.ant-tabs-content::before {
  display: block;
  overflow: hidden;
  content: "";
}
.ant-tabs .ant-tabs-top-content,
.ant-tabs .ant-tabs-bottom-content {
  width: 100%;
}
.ant-tabs .ant-tabs-top-content > .ant-tabs-tabpane,
.ant-tabs .ant-tabs-bottom-content > .ant-tabs-tabpane {
  flex-shrink: 0;
  width: 100%;
  opacity: 1;
  transition: opacity 0.45s;
}
.ant-tabs .ant-tabs-top-content > .ant-tabs-tabpane-inactive,
.ant-tabs .ant-tabs-bottom-content > .ant-tabs-tabpane-inactive {
  height: 0;
  padding: 0 !important;
  overflow: hidden;
  opacity: 0;
  pointer-events: none;
}
.ant-tabs .ant-tabs-top-content > .ant-tabs-tabpane-inactive input,
.ant-tabs .ant-tabs-bottom-content > .ant-tabs-tabpane-inactive input {
  visibility: hidden;
}
.ant-tabs .ant-tabs-top-content.ant-tabs-content-animated,
.ant-tabs .ant-tabs-bottom-content.ant-tabs-content-animated {
  display: flex;
  flex-direction: row;
  transition: margin-left 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  will-change: margin-left;
}
.ant-tabs .ant-tabs-left-bar,
.ant-tabs .ant-tabs-right-bar {
  height: 100%;
  border-bottom: 0;
}
.ant-tabs .ant-tabs-left-bar .ant-tabs-tab-arrow-show,
.ant-tabs .ant-tabs-right-bar .ant-tabs-tab-arrow-show {
  width: 100%;
  height: 32px;
}
.ant-tabs .ant-tabs-left-bar .ant-tabs-tab,
.ant-tabs .ant-tabs-right-bar .ant-tabs-tab {
  display: block;
  float: none;
  margin: 0 0 16px 0;
  padding: 8px 24px;
}
.ant-tabs .ant-tabs-left-bar .ant-tabs-tab:last-child,
.ant-tabs .ant-tabs-right-bar .ant-tabs-tab:last-child {
  margin-bottom: 0;
}
.ant-tabs .ant-tabs-left-bar .ant-tabs-extra-content,
.ant-tabs .ant-tabs-right-bar .ant-tabs-extra-content {
  text-align: center;
}
.ant-tabs .ant-tabs-left-bar .ant-tabs-nav-scroll,
.ant-tabs .ant-tabs-right-bar .ant-tabs-nav-scroll {
  width: auto;
}
.ant-tabs .ant-tabs-left-bar .ant-tabs-nav-container,
.ant-tabs .ant-tabs-right-bar .ant-tabs-nav-container,
.ant-tabs .ant-tabs-left-bar .ant-tabs-nav-wrap,
.ant-tabs .ant-tabs-right-bar .ant-tabs-nav-wrap {
  height: 100%;
}
.ant-tabs .ant-tabs-left-bar .ant-tabs-nav-container,
.ant-tabs .ant-tabs-right-bar .ant-tabs-nav-container {
  margin-bottom: 0;
}
.ant-tabs .ant-tabs-left-bar .ant-tabs-nav-container.ant-tabs-nav-container-scrolling,
.ant-tabs .ant-tabs-right-bar .ant-tabs-nav-container.ant-tabs-nav-container-scrolling {
  padding: 32px 0;
}
.ant-tabs .ant-tabs-left-bar .ant-tabs-nav-wrap,
.ant-tabs .ant-tabs-right-bar .ant-tabs-nav-wrap {
  margin-bottom: 0;
}
.ant-tabs .ant-tabs-left-bar .ant-tabs-nav,
.ant-tabs .ant-tabs-right-bar .ant-tabs-nav {
  width: 100%;
}
.ant-tabs .ant-tabs-left-bar .ant-tabs-ink-bar,
.ant-tabs .ant-tabs-right-bar .ant-tabs-ink-bar {
  top: 0;
  bottom: auto;
  left: auto;
  width: 2px;
  height: 0;
}
.ant-tabs .ant-tabs-left-bar .ant-tabs-tab-next,
.ant-tabs .ant-tabs-right-bar .ant-tabs-tab-next {
  right: 0;
  bottom: 0;
  width: 100%;
  height: 32px;
}
.ant-tabs .ant-tabs-left-bar .ant-tabs-tab-prev,
.ant-tabs .ant-tabs-right-bar .ant-tabs-tab-prev {
  top: 0;
  width: 100%;
  height: 32px;
}
.ant-tabs .ant-tabs-left-content,
.ant-tabs .ant-tabs-right-content {
  width: auto;
  margin-top: 0 !important;
  overflow: hidden;
}
.ant-tabs .ant-tabs-left-bar {
  float: left;
  margin-right: -1px;
  margin-bottom: 0;
  border-right: 1px solid #f0f0f0;
}
.ant-tabs .ant-tabs-left-bar .ant-tabs-tab {
  text-align: right;
}
.ant-tabs .ant-tabs-left-bar .ant-tabs-nav-container {
  margin-right: -1px;
}
.ant-tabs .ant-tabs-left-bar .ant-tabs-nav-wrap {
  margin-right: -1px;
}
.ant-tabs .ant-tabs-left-bar .ant-tabs-ink-bar {
  right: 1px;
}
.ant-tabs .ant-tabs-left-content {
  padding-left: 24px;
  border-left: 1px solid #f0f0f0;
}
.ant-tabs .ant-tabs-right-bar {
  float: right;
  margin-bottom: 0;
  margin-left: -1px;
  border-left: 1px solid #f0f0f0;
}
.ant-tabs .ant-tabs-right-bar .ant-tabs-nav-container {
  margin-left: -1px;
}
.ant-tabs .ant-tabs-right-bar .ant-tabs-nav-wrap {
  margin-left: -1px;
}
.ant-tabs .ant-tabs-right-bar .ant-tabs-ink-bar {
  left: 1px;
}
.ant-tabs .ant-tabs-right-content {
  padding-right: 24px;
  border-right: 1px solid #f0f0f0;
}
.ant-tabs-top .ant-tabs-ink-bar-animated,
.ant-tabs-bottom .ant-tabs-ink-bar-animated {
  transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), width 0.2s cubic-bezier(0.645, 0.045, 0.355, 1), left 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.ant-tabs-left .ant-tabs-ink-bar-animated,
.ant-tabs-right .ant-tabs-ink-bar-animated {
  transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), height 0.2s cubic-bezier(0.645, 0.045, 0.355, 1), top 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.no-flex > .ant-tabs-content > .ant-tabs-content-animated,
.ant-tabs-no-animation > .ant-tabs-content > .ant-tabs-content-animated {
  margin-left: 0 !important;
  transform: none !important;
}
.no-flex > .ant-tabs-content > .ant-tabs-tabpane-inactive,
.ant-tabs-no-animation > .ant-tabs-content > .ant-tabs-tabpane-inactive {
  height: 0;
  padding: 0 !important;
  overflow: hidden;
  opacity: 0;
  pointer-events: none;
}
.no-flex > .ant-tabs-content > .ant-tabs-tabpane-inactive input,
.ant-tabs-no-animation > .ant-tabs-content > .ant-tabs-tabpane-inactive input {
  visibility: hidden;
}
.ant-tabs-left-content > .ant-tabs-content-animated,
.ant-tabs-right-content > .ant-tabs-content-animated {
  margin-left: 0 !important;
  transform: none !important;
}
.ant-tabs-left-content > .ant-tabs-tabpane-inactive,
.ant-tabs-right-content > .ant-tabs-tabpane-inactive {
  height: 0;
  padding: 0 !important;
  overflow: hidden;
  opacity: 0;
  pointer-events: none;
}
.ant-tabs-left-content > .ant-tabs-tabpane-inactive input,
.ant-tabs-right-content > .ant-tabs-tabpane-inactive input {
  visibility: hidden;
}
.ant-tag {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(34, 34, 34, 0.8);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: "tnum";
  display: inline-block;
  height: auto;
  margin-right: 8px;
  padding: 0 7px;
  font-size: 12px;
  line-height: 20px;
  white-space: nowrap;
  background: #fafafa;
  border: 1px solid #bfbfbf;
  border-radius: 10px;
  cursor: default;
  opacity: 1;
  transition: all 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
}
.ant-tag:hover {
  opacity: 0.85;
}
.ant-tag,
.ant-tag a,
.ant-tag a:hover {
  color: rgba(34, 34, 34, 0.8);
}
.ant-tag > a:first-child:last-child {
  display: inline-block;
  margin: 0 -8px;
  padding: 0 8px;
}
.ant-tag .anticon-close {
  display: inline-block;
  font-size: 12px;
  font-size: 10px \9;
  transform: scale(0.83333333) rotate(0deg);
  margin-left: 3px;
  color: rgba(34, 34, 34, 0.45);
  font-weight: bold;
  cursor: pointer;
  transition: all 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
}
:root .ant-tag .anticon-close {
  font-size: 12px;
}
.ant-tag .anticon-close:hover {
  color: rgba(255, 255, 255, 0.85);
}
.ant-tag-has-color {
  border-color: transparent;
}
.ant-tag-has-color,
.ant-tag-has-color a,
.ant-tag-has-color a:hover,
.ant-tag-has-color .anticon-close,
.ant-tag-has-color .anticon-close:hover {
  color: #fff;
}
.ant-tag-checkable {
  background-color: transparent;
  border-color: transparent;
}
.ant-tag-checkable:not(.ant-tag-checkable-checked):hover {
  color: #00a6ff;
}
.ant-tag-checkable:active,
.ant-tag-checkable-checked {
  color: #fff;
}
.ant-tag-checkable-checked {
  background-color: #00a6ff;
}
.ant-tag-checkable:active {
  background-color: #0086d9;
}
.ant-tag-hidden {
  display: none;
}
.ant-tag-pink {
  color: #eb2f96;
  background: #fff0f6;
  border-color: #ffadd2;
}
.ant-tag-pink-inverse {
  color: #fff;
  background: #eb2f96;
  border-color: #eb2f96;
}
.ant-tag-magenta {
  color: #eb2f96;
  background: #fff0f6;
  border-color: #ffadd2;
}
.ant-tag-magenta-inverse {
  color: #fff;
  background: #eb2f96;
  border-color: #eb2f96;
}
.ant-tag-red {
  color: #f5222d;
  background: #fff1f0;
  border-color: #ffa39e;
}
.ant-tag-red-inverse {
  color: #fff;
  background: #f5222d;
  border-color: #f5222d;
}
.ant-tag-volcano {
  color: #fa541c;
  background: #fff2e8;
  border-color: #ffbb96;
}
.ant-tag-volcano-inverse {
  color: #fff;
  background: #fa541c;
  border-color: #fa541c;
}
.ant-tag-orange {
  color: #fa8c16;
  background: #fff7e6;
  border-color: #ffd591;
}
.ant-tag-orange-inverse {
  color: #fff;
  background: #fa8c16;
  border-color: #fa8c16;
}
.ant-tag-yellow {
  color: #fadb14;
  background: #feffe6;
  border-color: #fffb8f;
}
.ant-tag-yellow-inverse {
  color: #fff;
  background: #fadb14;
  border-color: #fadb14;
}
.ant-tag-gold {
  color: #faad14;
  background: #fffbe6;
  border-color: #ffe58f;
}
.ant-tag-gold-inverse {
  color: #fff;
  background: #faad14;
  border-color: #faad14;
}
.ant-tag-cyan {
  color: #13c2c2;
  background: #e6fffb;
  border-color: #87e8de;
}
.ant-tag-cyan-inverse {
  color: #fff;
  background: #13c2c2;
  border-color: #13c2c2;
}
.ant-tag-lime {
  color: #a0d911;
  background: #fcffe6;
  border-color: #eaff8f;
}
.ant-tag-lime-inverse {
  color: #fff;
  background: #a0d911;
  border-color: #a0d911;
}
.ant-tag-green {
  color: #52c41a;
  background: #f6ffed;
  border-color: #b7eb8f;
}
.ant-tag-green-inverse {
  color: #fff;
  background: #52c41a;
  border-color: #52c41a;
}
.ant-tag-blue {
  color: #1890ff;
  background: #e6f7ff;
  border-color: #91d5ff;
}
.ant-tag-blue-inverse {
  color: #fff;
  background: #1890ff;
  border-color: #1890ff;
}
.ant-tag-geekblue {
  color: #2f54eb;
  background: #f0f5ff;
  border-color: #adc6ff;
}
.ant-tag-geekblue-inverse {
  color: #fff;
  background: #2f54eb;
  border-color: #2f54eb;
}
.ant-tag-purple {
  color: #722ed1;
  background: #f9f0ff;
  border-color: #d3adf7;
}
.ant-tag-purple-inverse {
  color: #fff;
  background: #722ed1;
  border-color: #722ed1;
}
.ant-time-picker-panel {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(34, 34, 34, 0.8);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: "tnum";
  position: absolute;
  z-index: 9999;
  font-family: Roboto, Arial, San-serif;
}
.ant-time-picker-panel-inner {
  position: relative;
  left: -2px;
  font-size: 14px;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border-radius: 10px;
  outline: none;
  box-shadow: 0 0 1px 1px rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.08), 0 2px 8px 2px rgba(0, 0, 0, 0.05);
}
.ant-time-picker-panel-input {
  width: 100%;
  max-width: 154px;
  margin: 0;
  padding: 0;
  line-height: normal;
  border: 0;
  outline: 0;
  cursor: auto;
}
.ant-time-picker-panel-input::-moz-placeholder {
  color: #bfbfbf;
  opacity: 1;
}
.ant-time-picker-panel-input:-ms-input-placeholder {
  color: #bfbfbf;
}
.ant-time-picker-panel-input::-webkit-input-placeholder {
  color: #bfbfbf;
}
.ant-time-picker-panel-input:-moz-placeholder-shown {
  text-overflow: ellipsis;
}
.ant-time-picker-panel-input:-ms-input-placeholder {
  text-overflow: ellipsis;
}
.ant-time-picker-panel-input:placeholder-shown {
  text-overflow: ellipsis;
}
.ant-time-picker-panel-input-wrap {
  position: relative;
  padding: 7px 2px 7px 12px;
  border-bottom: 1px solid #f0f0f0;
}
.ant-time-picker-panel-input-invalid {
  border-color: #ff4d4f;
}
.ant-time-picker-panel-narrow .ant-time-picker-panel-input-wrap {
  max-width: 112px;
}
.ant-time-picker-panel-select {
  position: relative;
  float: left;
  width: 56px;
  max-height: 192px;
  overflow: hidden;
  font-size: 14px;
  border-left: 1px solid #f0f0f0;
}
.ant-time-picker-panel-select:hover {
  overflow-y: auto;
}
.ant-time-picker-panel-select:first-child {
  margin-left: 0;
  border-left: 0;
}
.ant-time-picker-panel-select:last-child {
  border-right: 0;
}
.ant-time-picker-panel-select:only-child {
  width: 100%;
}
.ant-time-picker-panel-select ul {
  width: 56px;
  margin: 0;
  padding: 0 0 160px;
  list-style: none;
}
.ant-time-picker-panel-select li {
  width: 100%;
  height: 32px;
  margin: 0;
  padding: 0 0 0 12px;
  line-height: 32px;
  text-align: left;
  list-style: none;
  cursor: pointer;
  transition: all 0.3s;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.ant-time-picker-panel-select li:focus {
  color: #00a6ff;
  font-weight: 600;
  outline: none;
}
.ant-time-picker-panel-select li:hover {
  background: #f5f5f5;
}
li.ant-time-picker-panel-select-option-selected {
  font-weight: 600;
  background: #cccccc;
}
li.ant-time-picker-panel-select-option-selected:hover {
  background: #cccccc;
}
li.ant-time-picker-panel-select-option-disabled {
  color: #fff;
}
li.ant-time-picker-panel-select-option-disabled:hover {
  background: transparent;
  cursor: not-allowed;
}
li.ant-time-picker-panel-select-option-disabled:focus {
  color: #fff;
  font-weight: inherit;
}
.ant-time-picker-panel-combobox {
  zoom: 1;
}
.ant-time-picker-panel-combobox::before,
.ant-time-picker-panel-combobox::after {
  display: table;
  content: '';
}
.ant-time-picker-panel-combobox::after {
  clear: both;
}
.ant-time-picker-panel-combobox::before,
.ant-time-picker-panel-combobox::after {
  display: table;
  content: '';
}
.ant-time-picker-panel-combobox::after {
  clear: both;
}
.ant-time-picker-panel-addon {
  padding: 8px;
  border-top: 1px solid #f0f0f0;
}
.ant-time-picker-panel.slide-up-enter.slide-up-enter-active.ant-time-picker-panel-placement-topLeft,
.ant-time-picker-panel.slide-up-enter.slide-up-enter-active.ant-time-picker-panel-placement-topRight,
.ant-time-picker-panel.slide-up-appear.slide-up-appear-active.ant-time-picker-panel-placement-topLeft,
.ant-time-picker-panel.slide-up-appear.slide-up-appear-active.ant-time-picker-panel-placement-topRight {
  animation-name: antSlideDownIn;
}
.ant-time-picker-panel.slide-up-enter.slide-up-enter-active.ant-time-picker-panel-placement-bottomLeft,
.ant-time-picker-panel.slide-up-enter.slide-up-enter-active.ant-time-picker-panel-placement-bottomRight,
.ant-time-picker-panel.slide-up-appear.slide-up-appear-active.ant-time-picker-panel-placement-bottomLeft,
.ant-time-picker-panel.slide-up-appear.slide-up-appear-active.ant-time-picker-panel-placement-bottomRight {
  animation-name: antSlideUpIn;
}
.ant-time-picker-panel.slide-up-leave.slide-up-leave-active.ant-time-picker-panel-placement-topLeft,
.ant-time-picker-panel.slide-up-leave.slide-up-leave-active.ant-time-picker-panel-placement-topRight {
  animation-name: antSlideDownOut;
}
.ant-time-picker-panel.slide-up-leave.slide-up-leave-active.ant-time-picker-panel-placement-bottomLeft,
.ant-time-picker-panel.slide-up-leave.slide-up-leave-active.ant-time-picker-panel-placement-bottomRight {
  animation-name: antSlideUpOut;
}
.ant-time-picker {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(34, 34, 34, 0.8);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: "tnum";
  position: relative;
  display: inline-block;
  width: 128px;
  outline: none;
  cursor: text;
  transition: opacity 0.3s;
}
.ant-time-picker-input {
  position: relative;
  display: inline-block;
  width: 100%;
  height: 32px;
  padding: 4px 11px;
  color: rgba(34, 34, 34, 0.8);
  font-size: 14px;
  line-height: 1.5715;
  background-color: #fff;
  background-image: none;
  border: 1px solid #bfbfbf;
  border-radius: 10px;
  transition: all 0.3s;
}
.ant-time-picker-input::-moz-placeholder {
  color: #bfbfbf;
  opacity: 1;
}
.ant-time-picker-input:-ms-input-placeholder {
  color: #bfbfbf;
}
.ant-time-picker-input::-webkit-input-placeholder {
  color: #bfbfbf;
}
.ant-time-picker-input:-moz-placeholder-shown {
  text-overflow: ellipsis;
}
.ant-time-picker-input:-ms-input-placeholder {
  text-overflow: ellipsis;
}
.ant-time-picker-input:placeholder-shown {
  text-overflow: ellipsis;
}
.ant-time-picker-input:hover {
  border-color: #333;
  border-right-width: 1px !important;
}
.ant-time-picker-input:focus {
  border-color: #666060;
  border-right-width: 1px !important;
  outline: 0;
  box-shadow: 0 0 0 1px rgba(89, 89, 89, 0.2);
}
.ant-time-picker-input-disabled {
  color: #fff;
  background-color: #cccccc;
  cursor: not-allowed;
  opacity: 1;
}
.ant-time-picker-input-disabled:hover {
  border-color: #bfbfbf;
  border-right-width: 1px !important;
}
.ant-time-picker-input[disabled] {
  color: #fff;
  background-color: #cccccc;
  cursor: not-allowed;
  opacity: 1;
}
.ant-time-picker-input[disabled]:hover {
  border-color: #bfbfbf;
  border-right-width: 1px !important;
}
textarea.ant-time-picker-input {
  max-width: 100%;
  height: auto;
  min-height: 32px;
  line-height: 1.5715;
  vertical-align: bottom;
  transition: all 0.3s, height 0s;
}
.ant-time-picker-input-lg {
  height: 45px;
  padding: 10.5px 11px;
  font-size: 14px;
}
.ant-time-picker-input-sm {
  height: 24px;
  padding: 0px 7px;
}
.ant-time-picker-input[disabled] {
  color: #fff;
  background-color: #cccccc;
  cursor: not-allowed;
  opacity: 1;
}
.ant-time-picker-input[disabled]:hover {
  border-color: #bfbfbf;
  border-right-width: 1px !important;
}
.ant-time-picker-open {
  opacity: 0;
}
.ant-time-picker-icon,
.ant-time-picker-clear {
  position: absolute;
  top: 50%;
  right: 11px;
  z-index: 1;
  width: 14px;
  height: 14px;
  margin-top: -7px;
  color: #fff;
  line-height: 14px;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.ant-time-picker-icon .ant-time-picker-clock-icon,
.ant-time-picker-clear .ant-time-picker-clock-icon {
  display: block;
  color: #fff;
  line-height: 1;
}
.ant-time-picker-clear {
  z-index: 2;
  background: #fff;
  opacity: 0;
  pointer-events: none;
}
.ant-time-picker-clear:hover {
  color: rgba(34, 34, 34, 0.45);
}
.ant-time-picker:hover .ant-time-picker-clear {
  opacity: 1;
  pointer-events: auto;
}
.ant-time-picker-large .ant-time-picker-input {
  height: 45px;
  padding: 10.5px 11px;
  font-size: 14px;
}
.ant-time-picker-small .ant-time-picker-input {
  height: 24px;
  padding: 0px 7px;
}
.ant-time-picker-small .ant-time-picker-icon,
.ant-time-picker-small .ant-time-picker-clear {
  right: 7px;
}
@media not all and (-webkit-min-device-pixel-ratio: 0), not all and (min-resolution: 0.001dpcm) {
  @supports (-webkit-appearance: none) and (stroke-color: transparent) {
    .ant-input {
      line-height: 1.5715;
    }
  }
}
.ant-timeline {
  box-sizing: border-box;
  color: rgba(34, 34, 34, 0.8);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  font-feature-settings: "tnum";
  margin: 0;
  padding: 0;
  list-style: none;
}
.ant-timeline-item {
  position: relative;
  margin: 0;
  padding: 0 0 20px;
  font-size: 14px;
  list-style: none;
}
.ant-timeline-item-tail {
  position: absolute;
  top: 10px;
  left: 4px;
  height: calc(100% - 10px);
  border-left: 2px solid #f0f0f0;
}
.ant-timeline-item-pending .ant-timeline-item-head {
  font-size: 12px;
  background-color: transparent;
}
.ant-timeline-item-pending .ant-timeline-item-tail {
  display: none;
}
.ant-timeline-item-head {
  position: absolute;
  width: 10px;
  height: 10px;
  background-color: #fff;
  border: 2px solid transparent;
  border-radius: 100px;
}
.ant-timeline-item-head-blue {
  color: #00a6ff;
  border-color: #00a6ff;
}
.ant-timeline-item-head-red {
  color: #ff4d4f;
  border-color: #ff4d4f;
}
.ant-timeline-item-head-green {
  color: #52c41a;
  border-color: #52c41a;
}
.ant-timeline-item-head-gray {
  color: #fff;
  border-color: #fff;
}
.ant-timeline-item-head-custom {
  position: absolute;
  top: 5.5px;
  left: 5px;
  width: auto;
  height: auto;
  margin-top: 0;
  padding: 3px 1px;
  line-height: 1;
  text-align: center;
  border: 0;
  border-radius: 0;
  transform: translate(-50%, -50%);
}
.ant-timeline-item-content {
  position: relative;
  top: -7.001px;
  margin: 0 0 0 18px;
  word-break: break-word;
}
.ant-timeline-item-last > .ant-timeline-item-tail {
  display: none;
}
.ant-timeline-item-last > .ant-timeline-item-content {
  min-height: 48px;
}
.ant-timeline.ant-timeline-alternate .ant-timeline-item-tail,
.ant-timeline.ant-timeline-right .ant-timeline-item-tail,
.ant-timeline.ant-timeline-alternate .ant-timeline-item-head,
.ant-timeline.ant-timeline-right .ant-timeline-item-head,
.ant-timeline.ant-timeline-alternate .ant-timeline-item-head-custom,
.ant-timeline.ant-timeline-right .ant-timeline-item-head-custom {
  left: 50%;
}
.ant-timeline.ant-timeline-alternate .ant-timeline-item-head,
.ant-timeline.ant-timeline-right .ant-timeline-item-head {
  margin-left: -4px;
}
.ant-timeline.ant-timeline-alternate .ant-timeline-item-head-custom,
.ant-timeline.ant-timeline-right .ant-timeline-item-head-custom {
  margin-left: 1px;
}
.ant-timeline.ant-timeline-alternate .ant-timeline-item-left .ant-timeline-item-content,
.ant-timeline.ant-timeline-right .ant-timeline-item-left .ant-timeline-item-content {
  left: calc(50% - 4px);
  width: calc(50% - 14px);
  text-align: left;
}
.ant-timeline.ant-timeline-alternate .ant-timeline-item-right .ant-timeline-item-content,
.ant-timeline.ant-timeline-right .ant-timeline-item-right .ant-timeline-item-content {
  width: calc(50% - 12px);
  margin: 0;
  text-align: right;
}
.ant-timeline.ant-timeline-right .ant-timeline-item-right .ant-timeline-item-tail,
.ant-timeline.ant-timeline-right .ant-timeline-item-right .ant-timeline-item-head,
.ant-timeline.ant-timeline-right .ant-timeline-item-right .ant-timeline-item-head-custom {
  left: calc(100% - 4px - 2px);
}
.ant-timeline.ant-timeline-right .ant-timeline-item-right .ant-timeline-item-content {
  width: calc(100% - 18px);
}
.ant-timeline.ant-timeline-pending .ant-timeline-item-last .ant-timeline-item-tail {
  display: block;
  height: calc(100% - 14px);
  border-left: 2px dotted #f0f0f0;
}
.ant-timeline.ant-timeline-reverse .ant-timeline-item-last .ant-timeline-item-tail {
  display: none;
}
.ant-timeline.ant-timeline-reverse .ant-timeline-item-pending .ant-timeline-item-tail {
  top: 15px;
  display: block;
  height: calc(100% - 15px);
  border-left: 2px dotted #f0f0f0;
}
.ant-timeline.ant-timeline-reverse .ant-timeline-item-pending .ant-timeline-item-content {
  min-height: 48px;
}
.ant-tooltip {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(34, 34, 34, 0.8);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: "tnum";
  position: absolute;
  z-index: 1060;
  display: block;
  max-width: 250px;
  visibility: visible;
}
.ant-tooltip-hidden {
  display: none;
}
.ant-tooltip-placement-top,
.ant-tooltip-placement-topLeft,
.ant-tooltip-placement-topRight {
  padding-bottom: 8px;
}
.ant-tooltip-placement-right,
.ant-tooltip-placement-rightTop,
.ant-tooltip-placement-rightBottom {
  padding-left: 8px;
}
.ant-tooltip-placement-bottom,
.ant-tooltip-placement-bottomLeft,
.ant-tooltip-placement-bottomRight {
  padding-top: 8px;
}
.ant-tooltip-placement-left,
.ant-tooltip-placement-leftTop,
.ant-tooltip-placement-leftBottom {
  padding-right: 8px;
}
.ant-tooltip-inner {
  min-width: 30px;
  min-height: 32px;
  padding: 6px 8px;
  color: #fff;
  text-align: left;
  text-decoration: none;
  word-wrap: break-word;
  background-color: rgba(0, 0, 0, 0.75);
  border-radius: 10px;
  box-shadow: 0 0 1px 1px rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.08), 0 2px 8px 2px rgba(0, 0, 0, 0.05);
}
.ant-tooltip-arrow {
  position: absolute;
  display: block;
  width: 13.07106781px;
  height: 13.07106781px;
  overflow: hidden;
  background: transparent;
  pointer-events: none;
}
.ant-tooltip-arrow::before {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: block;
  width: 5px;
  height: 5px;
  margin: auto;
  background-color: rgba(0, 0, 0, 0.75);
  content: '';
  pointer-events: auto;
}
.ant-tooltip-placement-top .ant-tooltip-arrow,
.ant-tooltip-placement-topLeft .ant-tooltip-arrow,
.ant-tooltip-placement-topRight .ant-tooltip-arrow {
  bottom: -5.07106781px;
}
.ant-tooltip-placement-top .ant-tooltip-arrow::before,
.ant-tooltip-placement-topLeft .ant-tooltip-arrow::before,
.ant-tooltip-placement-topRight .ant-tooltip-arrow::before {
  box-shadow: 3px 3px 7px rgba(34, 34, 34, 0.07);
  transform: translateY(-6.53553391px) rotate(45deg);
}
.ant-tooltip-placement-top .ant-tooltip-arrow {
  left: 50%;
  transform: translateX(-50%);
}
.ant-tooltip-placement-topLeft .ant-tooltip-arrow {
  left: 13px;
}
.ant-tooltip-placement-topRight .ant-tooltip-arrow {
  right: 13px;
}
.ant-tooltip-placement-right .ant-tooltip-arrow,
.ant-tooltip-placement-rightTop .ant-tooltip-arrow,
.ant-tooltip-placement-rightBottom .ant-tooltip-arrow {
  left: -5.07106781px;
}
.ant-tooltip-placement-right .ant-tooltip-arrow::before,
.ant-tooltip-placement-rightTop .ant-tooltip-arrow::before,
.ant-tooltip-placement-rightBottom .ant-tooltip-arrow::before {
  box-shadow: -3px 3px 7px rgba(34, 34, 34, 0.07);
  transform: translateX(6.53553391px) rotate(45deg);
}
.ant-tooltip-placement-right .ant-tooltip-arrow {
  top: 50%;
  transform: translateY(-50%);
}
.ant-tooltip-placement-rightTop .ant-tooltip-arrow {
  top: 5px;
}
.ant-tooltip-placement-rightBottom .ant-tooltip-arrow {
  bottom: 5px;
}
.ant-tooltip-placement-left .ant-tooltip-arrow,
.ant-tooltip-placement-leftTop .ant-tooltip-arrow,
.ant-tooltip-placement-leftBottom .ant-tooltip-arrow {
  right: -5.07106781px;
}
.ant-tooltip-placement-left .ant-tooltip-arrow::before,
.ant-tooltip-placement-leftTop .ant-tooltip-arrow::before,
.ant-tooltip-placement-leftBottom .ant-tooltip-arrow::before {
  box-shadow: 3px -3px 7px rgba(34, 34, 34, 0.07);
  transform: translateX(-6.53553391px) rotate(45deg);
}
.ant-tooltip-placement-left .ant-tooltip-arrow {
  top: 50%;
  transform: translateY(-50%);
}
.ant-tooltip-placement-leftTop .ant-tooltip-arrow {
  top: 5px;
}
.ant-tooltip-placement-leftBottom .ant-tooltip-arrow {
  bottom: 5px;
}
.ant-tooltip-placement-bottom .ant-tooltip-arrow,
.ant-tooltip-placement-bottomLeft .ant-tooltip-arrow,
.ant-tooltip-placement-bottomRight .ant-tooltip-arrow {
  top: -5.07106781px;
}
.ant-tooltip-placement-bottom .ant-tooltip-arrow::before,
.ant-tooltip-placement-bottomLeft .ant-tooltip-arrow::before,
.ant-tooltip-placement-bottomRight .ant-tooltip-arrow::before {
  box-shadow: -3px -3px 7px rgba(34, 34, 34, 0.07);
  transform: translateY(6.53553391px) rotate(45deg);
}
.ant-tooltip-placement-bottom .ant-tooltip-arrow {
  left: 50%;
  transform: translateX(-50%);
}
.ant-tooltip-placement-bottomLeft .ant-tooltip-arrow {
  left: 13px;
}
.ant-tooltip-placement-bottomRight .ant-tooltip-arrow {
  right: 13px;
}
.ant-transfer-customize-list {
  display: flex;
}
.ant-transfer-customize-list .ant-transfer-operation {
  flex: none;
  align-self: center;
}
.ant-transfer-customize-list .ant-transfer-list {
  flex: auto;
  width: auto;
  height: auto;
  min-height: 200px;
}
.ant-transfer-customize-list .ant-transfer-list-body-with-search {
  padding-top: 0;
}
.ant-transfer-customize-list .ant-transfer-list-body-search-wrapper {
  position: relative;
  padding-bottom: 0;
}
.ant-transfer-customize-list .ant-transfer-list-body-customize-wrapper {
  padding: 12px;
}
.ant-transfer-customize-list .ant-table-wrapper .ant-table-small {
  border: 0;
  border-radius: 0;
}
.ant-transfer-customize-list .ant-table-wrapper .ant-table-small > .ant-table-content > .ant-table-body > table > .ant-table-thead > tr > th {
  background: #fafafa;
}
.ant-transfer-customize-list .ant-table-wrapper .ant-table-small > .ant-table-content .ant-table-row:last-child td {
  border-bottom: 1px solid #f0f0f0;
}
.ant-transfer-customize-list .ant-table-wrapper .ant-table-small .ant-table-body {
  margin: 0;
}
.ant-transfer-customize-list .ant-table-wrapper .ant-table-pagination.ant-pagination {
  margin: 16px 0 4px;
}
.ant-transfer {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(34, 34, 34, 0.8);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: "tnum";
  position: relative;
}
.ant-transfer-disabled .ant-transfer-list {
  background: #cccccc;
}
.ant-transfer-list {
  position: relative;
  display: inline-block;
  width: 180px;
  height: 200px;
  padding-top: 40px;
  vertical-align: middle;
  border: 1px solid #bfbfbf;
  border-radius: 10px;
}
.ant-transfer-list-with-footer {
  padding-bottom: 34px;
}
.ant-transfer-list-search {
  padding: 0 24px 0 8px;
}
.ant-transfer-list-search-action {
  position: absolute;
  top: 12px;
  right: 12px;
  bottom: 12px;
  width: 28px;
  color: #fff;
  line-height: 32px;
  text-align: center;
}
.ant-transfer-list-search-action .anticon {
  color: #fff;
  transition: all 0.3s;
}
.ant-transfer-list-search-action .anticon:hover {
  color: rgba(34, 34, 34, 0.45);
}
span.ant-transfer-list-search-action {
  pointer-events: none;
}
.ant-transfer-list-header {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  padding: 7.4995px 12px 8.4995px;
  overflow: hidden;
  color: rgba(34, 34, 34, 0.8);
  background: #fff;
  border-bottom: 1px solid #f0f0f0;
  border-radius: 10px 10px 0 0;
}
.ant-transfer-list-header-title {
  position: absolute;
  right: 12px;
}
.ant-transfer-list-header .ant-checkbox-wrapper + span {
  padding-left: 8px;
}
.ant-transfer-list-body {
  position: relative;
  height: 100%;
  font-size: 14px;
}
.ant-transfer-list-body-search-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  padding: 12px;
}
.ant-transfer-list-body-with-search {
  padding-top: 56px;
}
.ant-transfer-list-content {
  height: 100%;
  margin: 0;
  padding: 0;
  overflow: auto;
  list-style: none;
}
.ant-transfer-list-content > .LazyLoad {
  animation: transferHighlightIn 1s;
}
.ant-transfer-list-content-item {
  min-height: 32px;
  padding: 6px 12px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  transition: all 0.3s;
}
.ant-transfer-list-content-item > span {
  padding-right: 0;
}
.ant-transfer-list-content-item-text {
  padding-left: 8px;
}
.ant-transfer-list-content-item:not(.ant-transfer-list-content-item-disabled):hover {
  background-color: #f5f5f5;
  cursor: pointer;
}
.ant-transfer-list-content-item-disabled {
  color: #fff;
  cursor: not-allowed;
}
.ant-transfer-list-body-not-found {
  position: absolute;
  top: 50%;
  width: 100%;
  padding-top: 0;
  color: #fff;
  text-align: center;
  transform: translateY(-50%);
}
.ant-transfer-list-body-with-search .ant-transfer-list-body-not-found {
  margin-top: 16px;
}
.ant-transfer-list-footer {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  border-top: 1px solid #f0f0f0;
  border-radius: 0 0 10px 10px;
}
.ant-transfer-operation {
  display: inline-block;
  margin: 0 8px;
  overflow: hidden;
  vertical-align: middle;
}
.ant-transfer-operation .ant-btn {
  display: block;
}
.ant-transfer-operation .ant-btn:first-child {
  margin-bottom: 4px;
}
.ant-transfer-operation .ant-btn .anticon {
  font-size: 12px;
}
@keyframes transferHighlightIn {
  0% {
    background: #a3ebff;
  }
  100% {
    background: transparent;
  }
}
.ant-tree.ant-tree-directory {
  position: relative;
}
.ant-tree.ant-tree-directory > li span.ant-tree-switcher,
.ant-tree.ant-tree-directory .ant-tree-child-tree > li span.ant-tree-switcher {
  position: relative;
  z-index: 1;
}
.ant-tree.ant-tree-directory > li span.ant-tree-switcher.ant-tree-switcher-noop,
.ant-tree.ant-tree-directory .ant-tree-child-tree > li span.ant-tree-switcher.ant-tree-switcher-noop {
  pointer-events: none;
}
.ant-tree.ant-tree-directory > li span.ant-tree-checkbox,
.ant-tree.ant-tree-directory .ant-tree-child-tree > li span.ant-tree-checkbox {
  position: relative;
  z-index: 1;
}
.ant-tree.ant-tree-directory > li span.ant-tree-node-content-wrapper,
.ant-tree.ant-tree-directory .ant-tree-child-tree > li span.ant-tree-node-content-wrapper {
  border-radius: 0;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.ant-tree.ant-tree-directory > li span.ant-tree-node-content-wrapper:hover,
.ant-tree.ant-tree-directory .ant-tree-child-tree > li span.ant-tree-node-content-wrapper:hover {
  background: transparent;
}
.ant-tree.ant-tree-directory > li span.ant-tree-node-content-wrapper:hover::before,
.ant-tree.ant-tree-directory .ant-tree-child-tree > li span.ant-tree-node-content-wrapper:hover::before {
  background: #f5f5f5;
}
.ant-tree.ant-tree-directory > li span.ant-tree-node-content-wrapper.ant-tree-node-selected,
.ant-tree.ant-tree-directory .ant-tree-child-tree > li span.ant-tree-node-content-wrapper.ant-tree-node-selected {
  color: #fff;
  background: transparent;
}
.ant-tree.ant-tree-directory > li span.ant-tree-node-content-wrapper::before,
.ant-tree.ant-tree-directory .ant-tree-child-tree > li span.ant-tree-node-content-wrapper::before {
  position: absolute;
  right: 0;
  left: 0;
  height: 24px;
  transition: all 0.3s;
  content: '';
}
.ant-tree.ant-tree-directory > li span.ant-tree-node-content-wrapper > span,
.ant-tree.ant-tree-directory .ant-tree-child-tree > li span.ant-tree-node-content-wrapper > span {
  position: relative;
  z-index: 1;
}
.ant-tree.ant-tree-directory > li.ant-tree-treenode-selected > span.ant-tree-switcher,
.ant-tree.ant-tree-directory .ant-tree-child-tree > li.ant-tree-treenode-selected > span.ant-tree-switcher {
  color: #fff;
}
.ant-tree.ant-tree-directory > li.ant-tree-treenode-selected > span.ant-tree-checkbox .ant-tree-checkbox-inner,
.ant-tree.ant-tree-directory .ant-tree-child-tree > li.ant-tree-treenode-selected > span.ant-tree-checkbox .ant-tree-checkbox-inner {
  border-color: #00a6ff;
}
.ant-tree.ant-tree-directory > li.ant-tree-treenode-selected > span.ant-tree-checkbox.ant-tree-checkbox-checked::after,
.ant-tree.ant-tree-directory .ant-tree-child-tree > li.ant-tree-treenode-selected > span.ant-tree-checkbox.ant-tree-checkbox-checked::after {
  border-color: #fff;
}
.ant-tree.ant-tree-directory > li.ant-tree-treenode-selected > span.ant-tree-checkbox.ant-tree-checkbox-checked .ant-tree-checkbox-inner,
.ant-tree.ant-tree-directory .ant-tree-child-tree > li.ant-tree-treenode-selected > span.ant-tree-checkbox.ant-tree-checkbox-checked .ant-tree-checkbox-inner {
  background: #fff;
}
.ant-tree.ant-tree-directory > li.ant-tree-treenode-selected > span.ant-tree-checkbox.ant-tree-checkbox-checked .ant-tree-checkbox-inner::after,
.ant-tree.ant-tree-directory .ant-tree-child-tree > li.ant-tree-treenode-selected > span.ant-tree-checkbox.ant-tree-checkbox-checked .ant-tree-checkbox-inner::after {
  border-color: #00a6ff;
}
.ant-tree.ant-tree-directory > li.ant-tree-treenode-selected > span.ant-tree-node-content-wrapper::before,
.ant-tree.ant-tree-directory .ant-tree-child-tree > li.ant-tree-treenode-selected > span.ant-tree-node-content-wrapper::before {
  background: #00a6ff;
}
.ant-tree-checkbox {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(34, 34, 34, 0.8);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: "tnum";
  position: relative;
  top: -0.09em;
  display: inline-block;
  line-height: 1;
  white-space: nowrap;
  vertical-align: middle;
  outline: none;
  cursor: pointer;
}
.ant-tree-checkbox-wrapper:hover .ant-tree-checkbox-inner,
.ant-tree-checkbox:hover .ant-tree-checkbox-inner,
.ant-tree-checkbox-input:focus + .ant-tree-checkbox-inner {
  border-color: #00a6ff;
}
.ant-tree-checkbox-checked::after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 1px solid #00a6ff;
  border-radius: 10px;
  visibility: hidden;
  animation: antCheckboxEffect 0.36s ease-in-out;
  animation-fill-mode: backwards;
  content: '';
}
.ant-tree-checkbox:hover::after,
.ant-tree-checkbox-wrapper:hover .ant-tree-checkbox::after {
  visibility: visible;
}
.ant-tree-checkbox-inner {
  position: relative;
  top: 0;
  left: 0;
  display: block;
  width: 16px;
  height: 16px;
  background-color: #fff;
  border: 1px solid #bfbfbf;
  border-radius: 10px;
  border-collapse: separate;
  transition: all 0.3s;
}
.ant-tree-checkbox-inner::after {
  position: absolute;
  top: 50%;
  left: 22%;
  display: table;
  width: 5.71428571px;
  height: 9.14285714px;
  border: 2px solid #fff;
  border-top: 0;
  border-left: 0;
  transform: rotate(45deg) scale(0) translate(-50%, -50%);
  opacity: 0;
  transition: all 0.1s cubic-bezier(0.71, -0.46, 0.88, 0.6), opacity 0.1s;
  content: ' ';
}
.ant-tree-checkbox-input {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  cursor: pointer;
  opacity: 0;
}
.ant-tree-checkbox-checked .ant-tree-checkbox-inner::after {
  position: absolute;
  display: table;
  border: 2px solid #fff;
  border-top: 0;
  border-left: 0;
  transform: rotate(45deg) scale(1) translate(-50%, -50%);
  opacity: 1;
  transition: all 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46) 0.1s;
  content: ' ';
}
.ant-tree-checkbox-checked .ant-tree-checkbox-inner {
  background-color: #00a6ff;
  border-color: #00a6ff;
}
.ant-tree-checkbox-disabled {
  cursor: not-allowed;
}
.ant-tree-checkbox-disabled.ant-tree-checkbox-checked .ant-tree-checkbox-inner::after {
  border-color: #fff;
  animation-name: none;
}
.ant-tree-checkbox-disabled .ant-tree-checkbox-input {
  cursor: not-allowed;
}
.ant-tree-checkbox-disabled .ant-tree-checkbox-inner {
  background-color: #cccccc;
  border-color: #bfbfbf !important;
}
.ant-tree-checkbox-disabled .ant-tree-checkbox-inner::after {
  border-color: #cccccc;
  border-collapse: separate;
  animation-name: none;
}
.ant-tree-checkbox-disabled + span {
  color: #fff;
  cursor: not-allowed;
}
.ant-tree-checkbox-disabled:hover::after,
.ant-tree-checkbox-wrapper:hover .ant-tree-checkbox-disabled::after {
  visibility: hidden;
}
.ant-tree-checkbox-wrapper {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(34, 34, 34, 0.8);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: "tnum";
  display: inline-block;
  line-height: unset;
  cursor: pointer;
}
.ant-tree-checkbox-wrapper.ant-tree-checkbox-wrapper-disabled {
  cursor: not-allowed;
}
.ant-tree-checkbox-wrapper + .ant-tree-checkbox-wrapper {
  margin-left: 8px;
}
.ant-tree-checkbox + span {
  padding-right: 8px;
  padding-left: 8px;
}
.ant-tree-checkbox-group {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(34, 34, 34, 0.8);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: "tnum";
  display: inline-block;
}
.ant-tree-checkbox-group-item {
  display: inline-block;
  margin-right: 8px;
}
.ant-tree-checkbox-group-item:last-child {
  margin-right: 0;
}
.ant-tree-checkbox-group-item + .ant-tree-checkbox-group-item {
  margin-left: 0;
}
.ant-tree-checkbox-indeterminate .ant-tree-checkbox-inner {
  background-color: #fff;
  border-color: #bfbfbf;
}
.ant-tree-checkbox-indeterminate .ant-tree-checkbox-inner::after {
  top: 50%;
  left: 50%;
  width: 8px;
  height: 8px;
  background-color: #00a6ff;
  border: 0;
  transform: translate(-50%, -50%) scale(1);
  opacity: 1;
  content: ' ';
}
.ant-tree-checkbox-indeterminate.ant-tree-checkbox-disabled .ant-tree-checkbox-inner::after {
  background-color: #fff;
  border-color: #fff;
}
.ant-tree {
  /* see https://github.com/ant-design/ant-design/issues/16259 */
  box-sizing: border-box;
  color: rgba(34, 34, 34, 0.8);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: "tnum";
  margin: 0;
  padding: 0;
}
.ant-tree-checkbox-checked::after {
  position: absolute;
  top: 16.67%;
  left: 0;
  width: 100%;
  height: 66.67%;
}
.ant-tree ol,
.ant-tree ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
.ant-tree li {
  margin: 0;
  padding: 4px 0;
  white-space: nowrap;
  list-style: none;
  outline: 0;
}
.ant-tree li span[draggable],
.ant-tree li span[draggable='true'] {
  line-height: 20px;
  border-top: 2px transparent solid;
  border-bottom: 2px transparent solid;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  /* Required to make elements draggable in old WebKit */
  -khtml-user-drag: element;
  -webkit-user-drag: element;
}
.ant-tree li.drag-over > span[draggable] {
  color: white;
  background-color: #00a6ff;
  opacity: 0.8;
}
.ant-tree li.drag-over-gap-top > span[draggable] {
  border-top-color: #00a6ff;
}
.ant-tree li.drag-over-gap-bottom > span[draggable] {
  border-bottom-color: #00a6ff;
}
.ant-tree li.filter-node > span {
  color: #ff4d4f !important;
  font-weight: 500 !important;
}
.ant-tree li.ant-tree-treenode-loading span.ant-tree-switcher.ant-tree-switcher_open .ant-tree-switcher-loading-icon,
.ant-tree li.ant-tree-treenode-loading span.ant-tree-switcher.ant-tree-switcher_close .ant-tree-switcher-loading-icon {
  position: absolute;
  left: 0;
  display: inline-block;
  width: 24px;
  height: 24px;
  color: #00a6ff;
  font-size: 14px;
  transform: none;
}
.ant-tree li.ant-tree-treenode-loading span.ant-tree-switcher.ant-tree-switcher_open .ant-tree-switcher-loading-icon svg,
.ant-tree li.ant-tree-treenode-loading span.ant-tree-switcher.ant-tree-switcher_close .ant-tree-switcher-loading-icon svg {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
}
:root .ant-tree li.ant-tree-treenode-loading span.ant-tree-switcher.ant-tree-switcher_open::after,
:root .ant-tree li.ant-tree-treenode-loading span.ant-tree-switcher.ant-tree-switcher_close::after {
  opacity: 0;
}
.ant-tree li ul {
  margin: 0;
  padding: 0 0 0 18px;
}
.ant-tree li .ant-tree-node-content-wrapper {
  display: inline-block;
  height: 24px;
  margin: 0;
  padding: 0 5px;
  color: rgba(34, 34, 34, 0.8);
  line-height: 24px;
  text-decoration: none;
  vertical-align: top;
  border-radius: 10px;
  cursor: pointer;
  transition: all 0.3s;
}
.ant-tree li .ant-tree-node-content-wrapper:hover {
  background-color: #f5f5f5;
}
.ant-tree li .ant-tree-node-content-wrapper.ant-tree-node-selected {
  background-color: #a3ebff;
}
.ant-tree li span.ant-tree-checkbox {
  top: auto;
  top: initial;
  height: 24px;
  margin: 0 4px 0 2px;
  padding: 4px 0;
}
.ant-tree li span.ant-tree-switcher,
.ant-tree li span.ant-tree-iconEle {
  display: inline-block;
  width: 24px;
  height: 24px;
  margin: 0;
  line-height: 24px;
  text-align: center;
  vertical-align: top;
  border: 0 none;
  outline: none;
  cursor: pointer;
}
.ant-tree li span.ant-tree-iconEle:empty {
  display: none;
}
.ant-tree li span.ant-tree-switcher {
  position: relative;
}
.ant-tree li span.ant-tree-switcher.ant-tree-switcher-noop {
  cursor: default;
}
.ant-tree li span.ant-tree-switcher.ant-tree-switcher_open .ant-tree-switcher-icon,
.ant-tree li span.ant-tree-switcher.ant-tree-switcher_open .ant-select-switcher-icon {
  font-size: 12px;
  font-size: 10px \9;
  transform: scale(0.83333333) rotate(0deg);
  display: inline-block;
  font-weight: bold;
}
:root .ant-tree li span.ant-tree-switcher.ant-tree-switcher_open .ant-tree-switcher-icon,
:root .ant-tree li span.ant-tree-switcher.ant-tree-switcher_open .ant-select-switcher-icon {
  font-size: 12px;
}
.ant-tree li span.ant-tree-switcher.ant-tree-switcher_open .ant-tree-switcher-icon svg,
.ant-tree li span.ant-tree-switcher.ant-tree-switcher_open .ant-select-switcher-icon svg {
  transition: transform 0.3s;
}
.ant-tree li span.ant-tree-switcher.ant-tree-switcher_close .ant-tree-switcher-icon,
.ant-tree li span.ant-tree-switcher.ant-tree-switcher_close .ant-select-switcher-icon {
  font-size: 12px;
  font-size: 10px \9;
  transform: scale(0.83333333) rotate(0deg);
  display: inline-block;
  font-weight: bold;
}
:root .ant-tree li span.ant-tree-switcher.ant-tree-switcher_close .ant-tree-switcher-icon,
:root .ant-tree li span.ant-tree-switcher.ant-tree-switcher_close .ant-select-switcher-icon {
  font-size: 12px;
}
.ant-tree li span.ant-tree-switcher.ant-tree-switcher_close .ant-tree-switcher-icon svg,
.ant-tree li span.ant-tree-switcher.ant-tree-switcher_close .ant-select-switcher-icon svg {
  transition: transform 0.3s;
}
.ant-tree li span.ant-tree-switcher.ant-tree-switcher_close .ant-tree-switcher-icon svg {
  transform: rotate(-90deg);
}
.ant-tree li:last-child > span.ant-tree-switcher::before,
.ant-tree li:last-child > span.ant-tree-iconEle::before {
  display: none;
}
.ant-tree > li:first-child {
  padding-top: 7px;
}
.ant-tree > li:last-child {
  padding-bottom: 7px;
}
.ant-tree-child-tree > li:first-child {
  padding-top: 8px;
}
.ant-tree-child-tree > li:last-child {
  padding-bottom: 0;
}
li.ant-tree-treenode-disabled > span:not(.ant-tree-switcher),
li.ant-tree-treenode-disabled > .ant-tree-node-content-wrapper,
li.ant-tree-treenode-disabled > .ant-tree-node-content-wrapper span {
  color: #fff;
  cursor: not-allowed;
}
li.ant-tree-treenode-disabled > .ant-tree-node-content-wrapper:hover {
  background: transparent;
}
.ant-tree-icon__open {
  margin-right: 2px;
  vertical-align: top;
}
.ant-tree-icon__close {
  margin-right: 2px;
  vertical-align: top;
}
.ant-tree.ant-tree-show-line li {
  position: relative;
}
.ant-tree.ant-tree-show-line li span.ant-tree-switcher {
  color: rgba(34, 34, 34, 0.45);
  background: #fff;
}
.ant-tree.ant-tree-show-line li span.ant-tree-switcher.ant-tree-switcher-noop .ant-tree-switcher-icon,
.ant-tree.ant-tree-show-line li span.ant-tree-switcher.ant-tree-switcher-noop .ant-select-switcher-icon {
  display: inline-block;
  font-weight: normal;
  font-size: 12px;
}
.ant-tree.ant-tree-show-line li span.ant-tree-switcher.ant-tree-switcher-noop .ant-tree-switcher-icon svg,
.ant-tree.ant-tree-show-line li span.ant-tree-switcher.ant-tree-switcher-noop .ant-select-switcher-icon svg {
  transition: transform 0.3s;
}
.ant-tree.ant-tree-show-line li span.ant-tree-switcher.ant-tree-switcher_open .ant-tree-switcher-icon,
.ant-tree.ant-tree-show-line li span.ant-tree-switcher.ant-tree-switcher_open .ant-select-switcher-icon {
  display: inline-block;
  font-weight: normal;
  font-size: 12px;
}
.ant-tree.ant-tree-show-line li span.ant-tree-switcher.ant-tree-switcher_open .ant-tree-switcher-icon svg,
.ant-tree.ant-tree-show-line li span.ant-tree-switcher.ant-tree-switcher_open .ant-select-switcher-icon svg {
  transition: transform 0.3s;
}
.ant-tree.ant-tree-show-line li span.ant-tree-switcher.ant-tree-switcher_close .ant-tree-switcher-icon,
.ant-tree.ant-tree-show-line li span.ant-tree-switcher.ant-tree-switcher_close .ant-select-switcher-icon {
  display: inline-block;
  font-weight: normal;
  font-size: 12px;
}
.ant-tree.ant-tree-show-line li span.ant-tree-switcher.ant-tree-switcher_close .ant-tree-switcher-icon svg,
.ant-tree.ant-tree-show-line li span.ant-tree-switcher.ant-tree-switcher_close .ant-select-switcher-icon svg {
  transition: transform 0.3s;
}
.ant-tree.ant-tree-show-line li:not(:last-child)::before {
  position: absolute;
  left: 12px;
  width: 1px;
  height: 100%;
  height: calc(100% - 22px);
  margin: 22px 0 0;
  border-left: 1px solid #bfbfbf;
  content: ' ';
}
.ant-tree.ant-tree-icon-hide .ant-tree-treenode-loading .ant-tree-iconEle {
  display: none;
}
.ant-tree.ant-tree-block-node li .ant-tree-node-content-wrapper {
  width: calc(100% - 24px);
}
.ant-tree.ant-tree-block-node li span.ant-tree-checkbox + .ant-tree-node-content-wrapper {
  width: calc(100% - 46px);
}
.ant-select-tree-checkbox {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(34, 34, 34, 0.8);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: "tnum";
  position: relative;
  top: -0.09em;
  display: inline-block;
  line-height: 1;
  white-space: nowrap;
  vertical-align: middle;
  outline: none;
  cursor: pointer;
}
.ant-select-tree-checkbox-wrapper:hover .ant-select-tree-checkbox-inner,
.ant-select-tree-checkbox:hover .ant-select-tree-checkbox-inner,
.ant-select-tree-checkbox-input:focus + .ant-select-tree-checkbox-inner {
  border-color: #00a6ff;
}
.ant-select-tree-checkbox-checked::after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 1px solid #00a6ff;
  border-radius: 10px;
  visibility: hidden;
  animation: antCheckboxEffect 0.36s ease-in-out;
  animation-fill-mode: backwards;
  content: '';
}
.ant-select-tree-checkbox:hover::after,
.ant-select-tree-checkbox-wrapper:hover .ant-select-tree-checkbox::after {
  visibility: visible;
}
.ant-select-tree-checkbox-inner {
  position: relative;
  top: 0;
  left: 0;
  display: block;
  width: 16px;
  height: 16px;
  background-color: #fff;
  border: 1px solid #bfbfbf;
  border-radius: 10px;
  border-collapse: separate;
  transition: all 0.3s;
}
.ant-select-tree-checkbox-inner::after {
  position: absolute;
  top: 50%;
  left: 22%;
  display: table;
  width: 5.71428571px;
  height: 9.14285714px;
  border: 2px solid #fff;
  border-top: 0;
  border-left: 0;
  transform: rotate(45deg) scale(0) translate(-50%, -50%);
  opacity: 0;
  transition: all 0.1s cubic-bezier(0.71, -0.46, 0.88, 0.6), opacity 0.1s;
  content: ' ';
}
.ant-select-tree-checkbox-input {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  cursor: pointer;
  opacity: 0;
}
.ant-select-tree-checkbox-checked .ant-select-tree-checkbox-inner::after {
  position: absolute;
  display: table;
  border: 2px solid #fff;
  border-top: 0;
  border-left: 0;
  transform: rotate(45deg) scale(1) translate(-50%, -50%);
  opacity: 1;
  transition: all 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46) 0.1s;
  content: ' ';
}
.ant-select-tree-checkbox-checked .ant-select-tree-checkbox-inner {
  background-color: #00a6ff;
  border-color: #00a6ff;
}
.ant-select-tree-checkbox-disabled {
  cursor: not-allowed;
}
.ant-select-tree-checkbox-disabled.ant-select-tree-checkbox-checked .ant-select-tree-checkbox-inner::after {
  border-color: #fff;
  animation-name: none;
}
.ant-select-tree-checkbox-disabled .ant-select-tree-checkbox-input {
  cursor: not-allowed;
}
.ant-select-tree-checkbox-disabled .ant-select-tree-checkbox-inner {
  background-color: #cccccc;
  border-color: #bfbfbf !important;
}
.ant-select-tree-checkbox-disabled .ant-select-tree-checkbox-inner::after {
  border-color: #cccccc;
  border-collapse: separate;
  animation-name: none;
}
.ant-select-tree-checkbox-disabled + span {
  color: #fff;
  cursor: not-allowed;
}
.ant-select-tree-checkbox-disabled:hover::after,
.ant-select-tree-checkbox-wrapper:hover .ant-select-tree-checkbox-disabled::after {
  visibility: hidden;
}
.ant-select-tree-checkbox-wrapper {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(34, 34, 34, 0.8);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: "tnum";
  display: inline-block;
  line-height: unset;
  cursor: pointer;
}
.ant-select-tree-checkbox-wrapper.ant-select-tree-checkbox-wrapper-disabled {
  cursor: not-allowed;
}
.ant-select-tree-checkbox-wrapper + .ant-select-tree-checkbox-wrapper {
  margin-left: 8px;
}
.ant-select-tree-checkbox + span {
  padding-right: 8px;
  padding-left: 8px;
}
.ant-select-tree-checkbox-group {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(34, 34, 34, 0.8);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: "tnum";
  display: inline-block;
}
.ant-select-tree-checkbox-group-item {
  display: inline-block;
  margin-right: 8px;
}
.ant-select-tree-checkbox-group-item:last-child {
  margin-right: 0;
}
.ant-select-tree-checkbox-group-item + .ant-select-tree-checkbox-group-item {
  margin-left: 0;
}
.ant-select-tree-checkbox-indeterminate .ant-select-tree-checkbox-inner {
  background-color: #fff;
  border-color: #bfbfbf;
}
.ant-select-tree-checkbox-indeterminate .ant-select-tree-checkbox-inner::after {
  top: 50%;
  left: 50%;
  width: 8px;
  height: 8px;
  background-color: #00a6ff;
  border: 0;
  transform: translate(-50%, -50%) scale(1);
  opacity: 1;
  content: ' ';
}
.ant-select-tree-checkbox-indeterminate.ant-select-tree-checkbox-disabled .ant-select-tree-checkbox-inner::after {
  background-color: #fff;
  border-color: #fff;
}
.ant-select-tree {
  box-sizing: border-box;
  padding: 0;
  color: rgba(34, 34, 34, 0.8);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: "tnum";
  margin: 0;
  margin-top: -4px;
  padding: 0 4px;
}
.ant-select-tree li {
  margin: 8px 0;
  padding: 0;
  white-space: nowrap;
  list-style: none;
  outline: 0;
}
.ant-select-tree li.filter-node > span {
  font-weight: 500;
}
.ant-select-tree li ul {
  margin: 0;
  padding: 0 0 0 18px;
}
.ant-select-tree li .ant-select-tree-node-content-wrapper {
  display: inline-block;
  width: calc(100% - 24px);
  margin: 0;
  padding: 3px 5px;
  color: rgba(34, 34, 34, 0.8);
  text-decoration: none;
  border-radius: 10px;
  cursor: pointer;
  transition: all 0.3s;
}
.ant-select-tree li .ant-select-tree-node-content-wrapper:hover {
  background-color: #f5f5f5;
}
.ant-select-tree li .ant-select-tree-node-content-wrapper.ant-select-tree-node-selected {
  background-color: #a3ebff;
}
.ant-select-tree li span.ant-select-tree-checkbox {
  margin: 0 4px 0 0;
}
.ant-select-tree li span.ant-select-tree-checkbox + .ant-select-tree-node-content-wrapper {
  width: calc(100% - 46px);
}
.ant-select-tree li span.ant-select-tree-switcher,
.ant-select-tree li span.ant-select-tree-iconEle {
  display: inline-block;
  width: 24px;
  height: 24px;
  margin: 0;
  line-height: 22px;
  text-align: center;
  vertical-align: middle;
  border: 0 none;
  outline: none;
  cursor: pointer;
}
.ant-select-tree li span.ant-select-icon_loading .ant-select-switcher-loading-icon {
  position: absolute;
  left: 0;
  display: inline-block;
  color: #00a6ff;
  font-size: 14px;
  transform: none;
}
.ant-select-tree li span.ant-select-icon_loading .ant-select-switcher-loading-icon svg {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
}
.ant-select-tree li span.ant-select-tree-switcher {
  position: relative;
}
.ant-select-tree li span.ant-select-tree-switcher.ant-select-tree-switcher-noop {
  cursor: auto;
}
.ant-select-tree li span.ant-select-tree-switcher.ant-select-tree-switcher_open .ant-tree-switcher-icon,
.ant-select-tree li span.ant-select-tree-switcher.ant-select-tree-switcher_open .ant-select-switcher-icon {
  font-size: 12px;
  font-size: 10px \9;
  transform: scale(0.83333333) rotate(0deg);
  display: inline-block;
  font-weight: bold;
}
:root .ant-select-tree li span.ant-select-tree-switcher.ant-select-tree-switcher_open .ant-tree-switcher-icon,
:root .ant-select-tree li span.ant-select-tree-switcher.ant-select-tree-switcher_open .ant-select-switcher-icon {
  font-size: 12px;
}
.ant-select-tree li span.ant-select-tree-switcher.ant-select-tree-switcher_open .ant-tree-switcher-icon svg,
.ant-select-tree li span.ant-select-tree-switcher.ant-select-tree-switcher_open .ant-select-switcher-icon svg {
  transition: transform 0.3s;
}
.ant-select-tree li span.ant-select-tree-switcher.ant-select-tree-switcher_close .ant-tree-switcher-icon,
.ant-select-tree li span.ant-select-tree-switcher.ant-select-tree-switcher_close .ant-select-switcher-icon {
  font-size: 12px;
  font-size: 10px \9;
  transform: scale(0.83333333) rotate(0deg);
  display: inline-block;
  font-weight: bold;
}
:root .ant-select-tree li span.ant-select-tree-switcher.ant-select-tree-switcher_close .ant-tree-switcher-icon,
:root .ant-select-tree li span.ant-select-tree-switcher.ant-select-tree-switcher_close .ant-select-switcher-icon {
  font-size: 12px;
}
.ant-select-tree li span.ant-select-tree-switcher.ant-select-tree-switcher_close .ant-tree-switcher-icon svg,
.ant-select-tree li span.ant-select-tree-switcher.ant-select-tree-switcher_close .ant-select-switcher-icon svg {
  transition: transform 0.3s;
}
.ant-select-tree li span.ant-select-tree-switcher.ant-select-tree-switcher_close .ant-select-switcher-icon svg {
  transform: rotate(-90deg);
}
.ant-select-tree li span.ant-select-tree-switcher.ant-select-tree-switcher_open .ant-select-switcher-loading-icon,
.ant-select-tree li span.ant-select-tree-switcher.ant-select-tree-switcher_close .ant-select-switcher-loading-icon {
  position: absolute;
  left: 0;
  display: inline-block;
  width: 24px;
  height: 24px;
  color: #00a6ff;
  font-size: 14px;
  transform: none;
}
.ant-select-tree li span.ant-select-tree-switcher.ant-select-tree-switcher_open .ant-select-switcher-loading-icon svg,
.ant-select-tree li span.ant-select-tree-switcher.ant-select-tree-switcher_close .ant-select-switcher-loading-icon svg {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
}
.ant-select-tree .ant-select-tree-treenode-loading .ant-select-tree-iconEle {
  display: none;
}
.ant-select-tree-child-tree {
  display: none;
}
.ant-select-tree-child-tree-open {
  display: block;
}
li.ant-select-tree-treenode-disabled > span:not(.ant-select-tree-switcher),
li.ant-select-tree-treenode-disabled > .ant-select-tree-node-content-wrapper,
li.ant-select-tree-treenode-disabled > .ant-select-tree-node-content-wrapper span {
  color: #fff;
  cursor: not-allowed;
}
li.ant-select-tree-treenode-disabled > .ant-select-tree-node-content-wrapper:hover {
  background: transparent;
}
.ant-select-tree-icon__open {
  margin-right: 2px;
  vertical-align: top;
}
.ant-select-tree-icon__close {
  margin-right: 2px;
  vertical-align: top;
}
.ant-select-tree-dropdown {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(34, 34, 34, 0.8);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: "tnum";
}
.ant-select-tree-dropdown .ant-select-dropdown-search {
  position: sticky;
  top: 0;
  z-index: 1;
  display: block;
  padding: 4px;
  background: #fff;
}
.ant-select-tree-dropdown .ant-select-dropdown-search .ant-select-search__field__wrap {
  width: 100%;
}
.ant-select-tree-dropdown .ant-select-dropdown-search .ant-select-search__field {
  box-sizing: border-box;
  width: 100%;
  padding: 4px 7px;
  border: 1px solid #bfbfbf;
  border-radius: 4px;
  outline: none;
}
.ant-select-tree-dropdown .ant-select-dropdown-search.ant-select-search--hide {
  display: none;
}
.ant-select-tree-dropdown .ant-select-not-found {
  display: block;
  padding: 7px 16px;
  color: #fff;
  cursor: not-allowed;
}
.ant-typography {
  color: rgba(34, 34, 34, 0.8);
}
.ant-typography.ant-typography-secondary {
  color: rgba(34, 34, 34, 0.45);
}
.ant-typography.ant-typography-warning {
  color: #faad14;
}
.ant-typography.ant-typography-danger {
  color: #ff4d4f;
}
.ant-typography.ant-typography-disabled {
  color: #fff;
  cursor: not-allowed;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
div.ant-typography,
.ant-typography p {
  margin-bottom: 1em;
}
h1.ant-typography,
.ant-typography h1 {
  margin-bottom: 0.5em;
  color: rgba(255, 255, 255, 0.85);
  font-weight: 600;
  font-size: 38px;
  line-height: 1.23;
}
h2.ant-typography,
.ant-typography h2 {
  margin-bottom: 0.5em;
  color: rgba(255, 255, 255, 0.85);
  font-weight: 600;
  font-size: 30px;
  line-height: 1.35;
}
h3.ant-typography,
.ant-typography h3 {
  margin-bottom: 0.5em;
  color: rgba(255, 255, 255, 0.85);
  font-weight: 600;
  font-size: 24px;
  line-height: 1.35;
}
h4.ant-typography,
.ant-typography h4 {
  margin-bottom: 0.5em;
  color: rgba(255, 255, 255, 0.85);
  font-weight: 600;
  font-size: 20px;
  line-height: 1.4;
}
.ant-typography + h1.ant-typography,
.ant-typography + h2.ant-typography,
.ant-typography + h3.ant-typography,
.ant-typography + h4.ant-typography {
  margin-top: 1.2em;
}
.ant-typography div + h1,
.ant-typography ul + h1,
.ant-typography li + h1,
.ant-typography p + h1,
.ant-typography h1 + h1,
.ant-typography h2 + h1,
.ant-typography h3 + h1,
.ant-typography h4 + h1,
.ant-typography div + h2,
.ant-typography ul + h2,
.ant-typography li + h2,
.ant-typography p + h2,
.ant-typography h1 + h2,
.ant-typography h2 + h2,
.ant-typography h3 + h2,
.ant-typography h4 + h2,
.ant-typography div + h3,
.ant-typography ul + h3,
.ant-typography li + h3,
.ant-typography p + h3,
.ant-typography h1 + h3,
.ant-typography h2 + h3,
.ant-typography h3 + h3,
.ant-typography h4 + h3,
.ant-typography div + h4,
.ant-typography ul + h4,
.ant-typography li + h4,
.ant-typography p + h4,
.ant-typography h1 + h4,
.ant-typography h2 + h4,
.ant-typography h3 + h4,
.ant-typography h4 + h4 {
  margin-top: 1.2em;
}
span.ant-typography-ellipsis {
  display: inline-block;
}
.ant-typography a {
  color: #1c8eff;
  text-decoration: none;
  outline: none;
  cursor: pointer;
  transition: color 0.3s;
}
.ant-typography a:focus,
.ant-typography a:hover {
  color: #45a8ff;
}
.ant-typography a:active {
  color: #0d6cd9;
}
.ant-typography a:active,
.ant-typography a:hover {
  text-decoration: none;
}
.ant-typography a[disabled] {
  color: #fff;
  cursor: not-allowed;
  pointer-events: none;
}
.ant-typography code {
  margin: 0 0.2em;
  padding: 0.2em 0.4em 0.1em;
  font-size: 85%;
  background: rgba(0, 0, 0, 0.06);
  border: 1px solid rgba(0, 0, 0, 0.06);
  border-radius: 3px;
}
.ant-typography mark {
  padding: 0;
  background-color: #ffe58f;
}
.ant-typography u,
.ant-typography ins {
  text-decoration: underline;
  -webkit-text-decoration-skip: ink;
          text-decoration-skip-ink: auto;
}
.ant-typography s,
.ant-typography del {
  text-decoration: line-through;
}
.ant-typography strong {
  font-weight: 600;
}
.ant-typography-expand,
.ant-typography-edit,
.ant-typography-copy {
  color: #1c8eff;
  text-decoration: none;
  outline: none;
  cursor: pointer;
  transition: color 0.3s;
  margin-left: 8px;
}
.ant-typography-expand:focus,
.ant-typography-edit:focus,
.ant-typography-copy:focus,
.ant-typography-expand:hover,
.ant-typography-edit:hover,
.ant-typography-copy:hover {
  color: #45a8ff;
}
.ant-typography-expand:active,
.ant-typography-edit:active,
.ant-typography-copy:active {
  color: #0d6cd9;
}
.ant-typography-copy-success,
.ant-typography-copy-success:hover,
.ant-typography-copy-success:focus {
  color: #52c41a;
}
.ant-typography-edit-content {
  position: relative;
}
div.ant-typography-edit-content {
  left: -12px;
  margin-top: -5px;
  margin-bottom: calc(1em - 4px - 2px);
}
.ant-typography-edit-content-confirm {
  position: absolute;
  right: 10px;
  bottom: 8px;
  color: rgba(34, 34, 34, 0.45);
  pointer-events: none;
}
.ant-typography-edit-content textarea {
  -moz-transition: none;
}
.ant-typography ul,
.ant-typography ol {
  margin: 0 0 1em 0;
  padding: 0;
}
.ant-typography ul li,
.ant-typography ol li {
  margin: 0 0 0 20px;
  padding: 0 0 0 4px;
}
.ant-typography ul li {
  list-style-type: circle;
}
.ant-typography ul li li {
  list-style-type: disc;
}
.ant-typography ol li {
  list-style-type: decimal;
}
.ant-typography-ellipsis-single-line {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.ant-typography-ellipsis-multiple-line {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  /*! autoprefixer: ignore next */
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.ant-upload {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(34, 34, 34, 0.8);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: "tnum";
  outline: 0;
}
.ant-upload p {
  margin: 0;
}
.ant-upload-btn {
  display: block;
  width: 100%;
  outline: none;
}
.ant-upload input[type='file'] {
  cursor: pointer;
}
.ant-upload.ant-upload-select {
  display: inline-block;
}
.ant-upload.ant-upload-disabled {
  cursor: not-allowed;
}
.ant-upload.ant-upload-select-picture-card {
  display: table;
  float: left;
  width: 104px;
  height: 104px;
  margin-right: 8px;
  margin-bottom: 8px;
  text-align: center;
  vertical-align: top;
  background-color: #fafafa;
  border: 1px dashed #bfbfbf;
  border-radius: 10px;
  cursor: pointer;
  transition: border-color 0.3s ease;
}
.ant-upload.ant-upload-select-picture-card > .ant-upload {
  display: table-cell;
  width: 100%;
  height: 100%;
  padding: 8px;
  text-align: center;
  vertical-align: middle;
}
.ant-upload.ant-upload-select-picture-card:hover {
  border-color: #00a6ff;
}
.ant-upload.ant-upload-drag {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  background: #fafafa;
  border: 1px dashed #bfbfbf;
  border-radius: 10px;
  cursor: pointer;
  transition: border-color 0.3s;
}
.ant-upload.ant-upload-drag .ant-upload {
  padding: 16px 0;
}
.ant-upload.ant-upload-drag.ant-upload-drag-hover:not(.ant-upload-disabled) {
  border-color: #0086d9;
}
.ant-upload.ant-upload-drag.ant-upload-disabled {
  cursor: not-allowed;
}
.ant-upload.ant-upload-drag .ant-upload-btn {
  display: table;
  height: 100%;
}
.ant-upload.ant-upload-drag .ant-upload-drag-container {
  display: table-cell;
  vertical-align: middle;
}
.ant-upload.ant-upload-drag:not(.ant-upload-disabled):hover {
  border-color: #29bbff;
}
.ant-upload.ant-upload-drag p.ant-upload-drag-icon {
  margin-bottom: 20px;
}
.ant-upload.ant-upload-drag p.ant-upload-drag-icon .anticon {
  color: #29bbff;
  font-size: 48px;
}
.ant-upload.ant-upload-drag p.ant-upload-text {
  margin: 0 0 4px;
  color: rgba(255, 255, 255, 0.85);
  font-size: 14px;
}
.ant-upload.ant-upload-drag p.ant-upload-hint {
  color: rgba(34, 34, 34, 0.45);
  font-size: 14px;
}
.ant-upload.ant-upload-drag .anticon-plus {
  color: #fff;
  font-size: 30px;
  transition: all 0.3s;
}
.ant-upload.ant-upload-drag .anticon-plus:hover {
  color: rgba(34, 34, 34, 0.45);
}
.ant-upload.ant-upload-drag:hover .anticon-plus {
  color: rgba(34, 34, 34, 0.45);
}
.ant-upload-picture-card-wrapper {
  zoom: 1;
  display: inline-block;
  width: 100%;
}
.ant-upload-picture-card-wrapper::before,
.ant-upload-picture-card-wrapper::after {
  display: table;
  content: '';
}
.ant-upload-picture-card-wrapper::after {
  clear: both;
}
.ant-upload-picture-card-wrapper::before,
.ant-upload-picture-card-wrapper::after {
  display: table;
  content: '';
}
.ant-upload-picture-card-wrapper::after {
  clear: both;
}
.ant-upload-list {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(34, 34, 34, 0.8);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: "tnum";
  zoom: 1;
}
.ant-upload-list::before,
.ant-upload-list::after {
  display: table;
  content: '';
}
.ant-upload-list::after {
  clear: both;
}
.ant-upload-list::before,
.ant-upload-list::after {
  display: table;
  content: '';
}
.ant-upload-list::after {
  clear: both;
}
.ant-upload-list-item-list-type-text:hover .ant-upload-list-item-name-icon-count-1 {
  padding-right: 14px;
}
.ant-upload-list-item-list-type-text:hover .ant-upload-list-item-name-icon-count-2 {
  padding-right: 28px;
}
.ant-upload-list-item {
  position: relative;
  height: 22px;
  margin-top: 8px;
  font-size: 14px;
}
.ant-upload-list-item-name {
  display: inline-block;
  width: 100%;
  padding-left: 22px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.ant-upload-list-item-name-icon-count-1 {
  padding-right: 14px;
}
.ant-upload-list-item-card-actions {
  position: absolute;
  right: 0;
  opacity: 0;
}
.ant-upload-list-item-card-actions.picture {
  top: 25px;
  line-height: 1;
  opacity: 1;
}
.ant-upload-list-item-card-actions .anticon {
  padding-right: 6px;
  color: rgba(0, 0, 0, 0.45);
}
.ant-upload-list-item-info {
  height: 100%;
  padding: 0 12px 0 4px;
  transition: background-color 0.3s;
}
.ant-upload-list-item-info > span {
  display: block;
  width: 100%;
  height: 100%;
}
.ant-upload-list-item-info .anticon-loading,
.ant-upload-list-item-info .anticon-paper-clip {
  position: absolute;
  top: 5px;
  color: rgba(34, 34, 34, 0.45);
  font-size: 14px;
}
.ant-upload-list-item .anticon-close {
  display: inline-block;
  font-size: 12px;
  font-size: 10px \9;
  transform: scale(0.83333333) rotate(0deg);
  position: absolute;
  top: 6px;
  right: 4px;
  color: rgba(34, 34, 34, 0.45);
  line-height: 0;
  cursor: pointer;
  opacity: 0;
  transition: all 0.3s;
}
:root .ant-upload-list-item .anticon-close {
  font-size: 12px;
}
.ant-upload-list-item .anticon-close:hover {
  color: rgba(34, 34, 34, 0.8);
}
.ant-upload-list-item:hover .ant-upload-list-item-info {
  background-color: #f5f5f5;
}
.ant-upload-list-item:hover .anticon-close {
  opacity: 1;
}
.ant-upload-list-item:hover .ant-upload-list-item-card-actions {
  opacity: 1;
}
.ant-upload-list-item-error,
.ant-upload-list-item-error .anticon-paper-clip,
.ant-upload-list-item-error .ant-upload-list-item-name {
  color: #ff4d4f;
}
.ant-upload-list-item-error .ant-upload-list-item-card-actions {
  opacity: 1;
}
.ant-upload-list-item-error .ant-upload-list-item-card-actions .anticon {
  color: #ff4d4f;
}
.ant-upload-list-item-progress {
  position: absolute;
  bottom: -12px;
  width: 100%;
  padding-left: 26px;
  font-size: 14px;
  line-height: 0;
}
.ant-upload-list-picture .ant-upload-list-item,
.ant-upload-list-picture-card .ant-upload-list-item {
  position: relative;
  height: 66px;
  padding: 8px;
  border: 1px solid #bfbfbf;
  border-radius: 10px;
}
.ant-upload-list-picture .ant-upload-list-item:hover,
.ant-upload-list-picture-card .ant-upload-list-item:hover {
  background: transparent;
}
.ant-upload-list-picture .ant-upload-list-item-error,
.ant-upload-list-picture-card .ant-upload-list-item-error {
  border-color: #ff4d4f;
}
.ant-upload-list-picture .ant-upload-list-item-info,
.ant-upload-list-picture-card .ant-upload-list-item-info {
  padding: 0;
}
.ant-upload-list-picture .ant-upload-list-item:hover .ant-upload-list-item-info,
.ant-upload-list-picture-card .ant-upload-list-item:hover .ant-upload-list-item-info {
  background: transparent;
}
.ant-upload-list-picture .ant-upload-list-item-uploading,
.ant-upload-list-picture-card .ant-upload-list-item-uploading {
  border-style: dashed;
}
.ant-upload-list-picture .ant-upload-list-item-thumbnail,
.ant-upload-list-picture-card .ant-upload-list-item-thumbnail {
  position: absolute;
  top: 8px;
  left: 8px;
  width: 48px;
  height: 48px;
  font-size: 26px;
  line-height: 54px;
  text-align: center;
  opacity: 0.8;
}
.ant-upload-list-picture .ant-upload-list-item-icon,
.ant-upload-list-picture-card .ant-upload-list-item-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  font-size: 26px;
  transform: translate(-50%, -50%);
}
.ant-upload-list-picture .ant-upload-list-item-image,
.ant-upload-list-picture-card .ant-upload-list-item-image {
  max-width: 100%;
}
.ant-upload-list-picture .ant-upload-list-item-thumbnail img,
.ant-upload-list-picture-card .ant-upload-list-item-thumbnail img {
  display: block;
  width: 48px;
  height: 48px;
  overflow: hidden;
}
.ant-upload-list-picture .ant-upload-list-item-name,
.ant-upload-list-picture-card .ant-upload-list-item-name {
  display: inline-block;
  box-sizing: border-box;
  max-width: 100%;
  margin: 0 0 0 8px;
  padding-right: 8px;
  padding-left: 48px;
  overflow: hidden;
  line-height: 44px;
  white-space: nowrap;
  text-overflow: ellipsis;
  transition: all 0.3s;
}
.ant-upload-list-picture .ant-upload-list-item-name-icon-count-1,
.ant-upload-list-picture-card .ant-upload-list-item-name-icon-count-1 {
  padding-right: 18px;
}
.ant-upload-list-picture .ant-upload-list-item-name-icon-count-2,
.ant-upload-list-picture-card .ant-upload-list-item-name-icon-count-2 {
  padding-right: 36px;
}
.ant-upload-list-picture .ant-upload-list-item-uploading .ant-upload-list-item-name,
.ant-upload-list-picture-card .ant-upload-list-item-uploading .ant-upload-list-item-name {
  line-height: 28px;
}
.ant-upload-list-picture .ant-upload-list-item-progress,
.ant-upload-list-picture-card .ant-upload-list-item-progress {
  bottom: 14px;
  width: calc(100% - 24px);
  margin-top: 0;
  padding-left: 56px;
}
.ant-upload-list-picture .anticon-close,
.ant-upload-list-picture-card .anticon-close {
  position: absolute;
  top: 8px;
  right: 8px;
  line-height: 1;
  opacity: 1;
}
.ant-upload-list-picture-card.ant-upload-list::after {
  display: none;
}
.ant-upload-list-picture-card-container {
  float: left;
  width: 104px;
  height: 104px;
  margin: 0 8px 8px 0;
}
.ant-upload-list-picture-card .ant-upload-list-item {
  float: left;
  width: 104px;
  height: 104px;
  margin: 0 8px 8px 0;
}
.ant-upload-list-picture-card .ant-upload-list-item-info {
  position: relative;
  height: 100%;
  overflow: hidden;
}
.ant-upload-list-picture-card .ant-upload-list-item-info::before {
  position: absolute;
  z-index: 1;
  width: 100%;
  height: 100%;
  background-color: rgba(34, 34, 34, 0.5);
  opacity: 0;
  transition: all 0.3s;
  content: ' ';
}
.ant-upload-list-picture-card .ant-upload-list-item:hover .ant-upload-list-item-info::before {
  opacity: 1;
}
.ant-upload-list-picture-card .ant-upload-list-item-actions {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 10;
  white-space: nowrap;
  transform: translate(-50%, -50%);
  opacity: 0;
  transition: all 0.3s;
}
.ant-upload-list-picture-card .ant-upload-list-item-actions .anticon-eye-o,
.ant-upload-list-picture-card .ant-upload-list-item-actions .anticon-download,
.ant-upload-list-picture-card .ant-upload-list-item-actions .anticon-delete {
  z-index: 10;
  width: 16px;
  margin: 0 4px;
  color: rgba(255, 255, 255, 0.85);
  font-size: 16px;
  cursor: pointer;
  transition: all 0.3s;
}
.ant-upload-list-picture-card .ant-upload-list-item-actions .anticon-eye-o:hover,
.ant-upload-list-picture-card .ant-upload-list-item-actions .anticon-download:hover,
.ant-upload-list-picture-card .ant-upload-list-item-actions .anticon-delete:hover {
  color: #fff;
}
.ant-upload-list-picture-card .ant-upload-list-item-info:hover + .ant-upload-list-item-actions,
.ant-upload-list-picture-card .ant-upload-list-item-actions:hover {
  opacity: 1;
}
.ant-upload-list-picture-card .ant-upload-list-item-thumbnail,
.ant-upload-list-picture-card .ant-upload-list-item-thumbnail img {
  position: static;
  display: block;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
.ant-upload-list-picture-card .ant-upload-list-item-name {
  display: none;
  margin: 8px 0 0;
  padding: 0;
  line-height: 1.5715;
  text-align: center;
}
.ant-upload-list-picture-card .anticon-picture + .ant-upload-list-item-name {
  position: absolute;
  bottom: 10px;
  display: block;
}
.ant-upload-list-picture-card .ant-upload-list-item-uploading.ant-upload-list-item {
  background-color: #fafafa;
}
.ant-upload-list-picture-card .ant-upload-list-item-uploading .ant-upload-list-item-info {
  height: auto;
}
.ant-upload-list-picture-card .ant-upload-list-item-uploading .ant-upload-list-item-info::before,
.ant-upload-list-picture-card .ant-upload-list-item-uploading .ant-upload-list-item-info .anticon-eye-o,
.ant-upload-list-picture-card .ant-upload-list-item-uploading .ant-upload-list-item-info .anticon-delete {
  display: none;
}
.ant-upload-list-picture-card .ant-upload-list-item-uploading-text {
  margin-top: 18px;
  color: rgba(34, 34, 34, 0.45);
}
.ant-upload-list-picture-card .ant-upload-list-item-progress {
  bottom: 32px;
  padding-left: 0;
}
.ant-upload-list .ant-upload-success-icon {
  color: #52c41a;
  font-weight: bold;
}
.ant-upload-list .ant-upload-animate-enter,
.ant-upload-list .ant-upload-animate-leave,
.ant-upload-list .ant-upload-animate-inline-enter,
.ant-upload-list .ant-upload-animate-inline-leave {
  animation-duration: 0.3s;
  animation-fill-mode: cubic-bezier(0.78, 0.14, 0.15, 0.86);
}
.ant-upload-list .ant-upload-animate-enter {
  animation-name: uploadAnimateIn;
}
.ant-upload-list .ant-upload-animate-leave {
  animation-name: uploadAnimateOut;
}
.ant-upload-list .ant-upload-animate-inline-enter {
  animation-name: uploadAnimateInlineIn;
}
.ant-upload-list .ant-upload-animate-inline-leave {
  animation-name: uploadAnimateInlineOut;
}
@keyframes uploadAnimateIn {
  from {
    height: 0;
    margin: 0;
    padding: 0;
    opacity: 0;
  }
}
@keyframes uploadAnimateOut {
  to {
    height: 0;
    margin: 0;
    padding: 0;
    opacity: 0;
  }
}
@keyframes uploadAnimateInlineIn {
  from {
    width: 0;
    height: 0;
    margin: 0;
    padding: 0;
    opacity: 0;
  }
}
@keyframes uploadAnimateInlineOut {
  to {
    width: 0;
    height: 0;
    margin: 0;
    padding: 0;
    opacity: 0;
  }
}
.btnYellow {
  background: transparent linear-gradient(123deg, #ffb858 0%, #ff8b62 100%) 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  padding: 5px;
  color: white;
  border-radius: 5px;
  text-align: center;
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.btnBlue {
  background: transparent linear-gradient(123deg, #2ce3fc 0%, #32c7f7 100%) 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #0202021a;
  padding: 5px;
  color: white;
  border-radius: 5px;
  text-align: center;
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 5px;
}
.btnPurple {
  background: transparent linear-gradient(117deg, #fc96ee 0%, #d274ff 100%) 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #0202021a;
  padding: 5px;
  color: white;
  border-radius: 5px;
  text-align: center;
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 5px;
}
.red {
  color: #f92d2d;
}
.TextLightYellow {
  background: #fff5bf 0% 0% no-repeat padding-box;
  border-radius: 8px;
  padding: 10px;
  color: #83630b;
}
.TextLightRed {
  background: #fee5e5 0% 0% no-repeat padding-box;
  border-radius: 8px;
  padding: 10px;
  color: #eb2121;
  margin-bottom: 40px;
  width: 240px;
  height: auto !important;
}
.TextLightGrey {
  background-color: #e0e0e0;
  color: #666666;
}
.TextLightGreyInfo {
  background-color: #e0e0e0;
  color: #666666;
  padding: 10px;
  border-radius: 8px;
  margin-bottom: 10px;
}
.TextLight-Off-white {
  background: #F7F7FC;
  color: #666666;
  line-height: 20px;
  padding: 0 10px;
  border-radius: 8px;
  margin: 10px auto;
}
.P10 {
  padding: 10px;
  border-radius: 8px;
}
.has-error .ant-form-explain,
.has-error .ant-form-split {
  color: #ff4d4f;
  background-color: #fee0e0;
  padding: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
  border-radius: 8px;
}
.ant-popover {
  z-index: 998;
}
.showInfoModal {
  width: 150px!important;
}
.showInfoModal .ant-modal-body {
  padding: 0;
  background-color: #000000cc;
  border-radius: 6px;
}
.showInfoModal .ant-modal-body .ant-modal-confirm .ant-modal-body {
  padding: 0;
}
.showInfoModal .ant-modal-body .ant-modal-confirm-body .ant-modal-confirm-content {
  padding: 0;
  color: #222;
  margin-top: 0;
}
.showInfoModal .ant-btn-primary,
.showInfoModal .ant-modal-close-x {
  display: none;
}
.showInfoModal .ant-modal-confirm-btns {
  display: none;
}
.showInfoModal.opacity .ant-modal-content {
  background-color: transparent;
}
.showInfoModal.opacity .ant-modal-body {
  background-color: white;
  padding: 20px 10px;
}
.showInfoModal._initail {
  width: 400px !important;
}
.showInfoModal.depositLoading {
  width: 250px !important;
}
.showInfoModal.depositLoading .ant-modal-body {
  padding: 0 !important;
}
.showInfoModal.depositLoading .ant-modal-body .loadimg {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 25px;
}
.showInfoModal.depositLoading .ant-modal-body .loadimg p {
  font-size: 16px;
  color: #fff;
  text-align: center;
}
.showInfoModal.hidden-btn .ant-modal-confirm-btns {
  margin-top: 20px;
}
.showInfoModal.authentication-succeeded {
  width: 170px!important;
}
.showInfoModal.authentication-succeeded .ant-modal-body {
  padding: 25px 5px;
  text-align: center;
  font-weight: bold;
}
.tipsMsg {
  background: #FFF5BF;
  border-radius: 8px;
  padding: 12px;
  width: 100%;
  margin-bottom: 10px;
}
.tipsMsg .tipsMsgItem {
  font-size: 12px;
  color: #83630B;
  line-height: 16px;
  white-space: pre-line;
  text-align: left;
}
.error-modal-Smartico .ant-modal-confirm-btns {
  width: 100%;
}
.error-modal-Smartico .ant-modal-confirm-btns .ant-btn-primary {
  width: 100%;
}
.error-modal-Smartico .ant-modal-confirm-content {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}
.verify-modal .ant-modal-content {
  border-radius: 20px;
}
.verify-modal .ant-modal-header {
  border-radius: 20px 20px 0 0;
}
.verify-modal .ant-modal-header .ant-modal-title {
  font-size: 20px;
}
.verify-modal .ant-modal-body .ant-form-item-required::before {
  content: "";
}
.verify-modal .ant-modal-body .StepsBox {
  width: 168px;
}
.verify-modal .ant-modal-body .StepsBox .ant-steps-item {
  margin-right: 0px !important;
}
.verify-modal .ant-modal-body .StepsBox .ant-steps-item .ant-steps-item-container .ant-steps-item-icon {
  margin-right: 0px;
}
.verify-modal .ant-modal-body .StepsBox .ant-steps-item .ant-steps-item-container .ant-steps-item-title::after {
  height: 2px;
  left: 0px;
}
.verify-modal .ant-modal-body .StepsBox .ant-steps-item-process .ant-steps-item-container .ant-steps-item-icon {
  background-color: #00a6ff;
}
.verify-modal .ant-modal-body .StepsBox .ant-steps-item-process .ant-steps-item-container .ant-steps-item-icon .ant-steps-icon {
  color: #ffffff;
}
.verify-modal .ant-modal-body .StepsBox .ant-steps-item-process .ant-steps-item-container .ant-steps-item-title::after {
  background-color: #d4d4d4;
}
.verify-modal .ant-modal-body label > b {
  padding-left: 0.6rem;
  font-size: 14px;
}
.verify-modal .ant-modal-body .verify-remaining {
  font-size: 12px;
  margin-bottom: 15px;
}
.verify-modal .ant-modal-body .TextLightGrey {
  margin: -8px 0px 16px;
}
.verify-modal .ant-modal-body .OTP-input.has-error input {
  border-color: #eb2121;
}
.verify-modal .ant-modal-body .OTP-input input {
  font-size: 12px;
  padding: 10.5px 7px;
}
.verify-modal .ant-modal-body .OTP-input .ant-input-group-addon:last-child {
  width: 100px;
  padding: 0px;
}
.verify-modal .ant-modal-body .OTP-input .ant-input-group-addon:last-child > div {
  width: 100%;
  padding: 5px 0px;
  line-height: 33px;
}
.verify-modal .ant-modal-body .OTP-input .ant-input-group-addon:last-child .resend-disabled {
  color: #fff;
  font-size: 12px;
  line-height: 17px;
}
.verify-modal .ant-modal-body button.changeVerify {
  color: #cccccc;
  background-color: #fff;
  border-color: #cccccc;
}
.verify-modal .ant-modal-body button.changeVerify.abled {
  color: #00a5fd;
  background-color: #fff;
  border-color: #00a5fd;
}
.verify-modal .ant-modal-body div.changeVerify {
  text-align: center;
}
.verify-modal .ant-modal-body div.changeVerify > span {
  color: #00a6ff;
  cursor: pointer;
  border-bottom: 1px solid #00a6ff;
}
.verify-modal .ant-modal-body .realname-image {
  margin: 11px 0 5px;
  width: 100%;
  text-align: center;
}
.verify-modal .ant-modal-body .verify-realname {
  color: #222222;
}
.verify-modal .ant-modal-body .verify-realname label {
  color: #222222;
}
.verify-modal .ant-modal-body .verify-realname input {
  color: #222222;
}
.verify-modal .ant-modal-body .verify-realname button {
  height: 44px;
}
.verify-modal .ant-modal-body .verify-realname input::-moz-placeholder {
  color: #999999;
}
.verify-modal .ant-modal-body .verify-realname input:-ms-input-placeholder {
  color: #999999;
}
.verify-modal .ant-modal-body .verify-realname input::placeholder {
  color: #999999;
}
.verify-modal .ant-modal-body .verify-realname p {
  margin-bottom: 16px;
}
.verify-modal .ant-modal-body .verify-realname .verify-realname-box p {
  font-size: 12px;
}
.verify-modal .ant-modal-body .verify-realname .verify-realname-box .verify-realname-input {
  margin-bottom: 16px;
}
.verify-modal .ant-modal-body .verify-realname .verify-realname-box .verify-realname-input .ant-form-explain {
  margin-bottom: 0px;
}
.verify-modal .ant-modal-body .verify-realname .verify-realname-btns {
  display: flex;
  gap: 10px;
}
.verify-modal .ant-modal-body .verify-realname .verify-realname-btns button {
  width: 100%;
}
.ui-modal-select-box .select-title {
  display: block;
  margin: 0 0 5px 0;
}
.ui-modal-select-box .selection-container {
  position: relative;
}
.ui-modal-select-box .selection-container .current-select-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #e0e0e0;
  padding: 15px;
  border-radius: 8px;
  cursor: pointer;
}
.ui-modal-select-box .selection-container .current-select-box p {
  margin: 0;
  color: #999999;
}
.ui-modal-select-box .selection-container .current-select-box .selected-item-title {
  color: #222222;
}
.ui-modal-select-box .selection-container .current-select-box svg {
  cursor: pointer;
}
.ui-modal-select-box .selection-container .selection-menu {
  box-shadow: 0px 8px 20px #00000029;
  border-radius: 8px;
  padding: 16px 0;
  position: absolute;
  top: 0%;
  left: 0%;
  width: 100%;
  background-color: #fff;
  z-index: 100;
}
.ui-modal-select-box .selection-container .selection-menu header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #00a6ff;
  border-bottom: 1px solid #0000001c;
  padding: 0 19px 15px 19px;
}
.ui-modal-select-box .selection-container .selection-menu header p {
  margin: 0;
  font-weight: bold;
  font-size: 14px;
  line-height: 19px;
  color: #222222;
}
.ui-modal-select-box .selection-container .selection-menu header button {
  border: none;
  background-color: transparent;
  padding: 0;
  cursor: pointer;
}
.ui-modal-select-box .selection-container .selection-menu .options-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: scroll;
  max-height: 255px;
  padding: 0 10px 0 19px;
  margin-right: 2px;
}
.ui-modal-select-box .selection-container .selection-menu .options-box::-webkit-scrollbar-track {
  background-color: transparent;
  margin-top: 2px;
}
.ui-modal-select-box .selection-container .selection-menu .options-box::-webkit-scrollbar-thumb {
  background-color: #cccccc;
  border-radius: 6px;
}
.ui-modal-select-box .selection-container .selection-menu .options-box .option-item {
  width: 100%;
  padding: 10px 0;
  border-bottom: 1px solid #0000001c;
  color: #999999;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
}
.ui-modal-select-box .selection-container .selection-menu .options-box .option-item p {
  margin: 0;
}
.ui-modal-select-box .selection-container .selection-menu .options-box .option-item .check-box {
  color: #00a6ff;
}
.ui-modal-select-box .selection-container .selection-menu .options-box .clicked-item p,
.ui-modal-select-box .selection-container .selection-menu .options-box .confirmed-item p {
  color: #000;
}
.ui-modal-select-box .ant-select-lg {
  width: 100%;
}
.BoxOther {
  padding: 0 40px;
  margin: 80px auto;
  height: 460px;
  width: 960px;
  border-radius: 20px;
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.BoxOther .Box_Content {
  width: 360px;
  background-color: white;
  height: auto;
  border-radius: 20px;
  overflow: hidden;
  color: white;
  padding-bottom: 20px;
}
.BoxOther .Box_Content h3 {
  color: white;
  background-color: #00a5fd;
  text-align: center;
  padding: 10px;
  margin-bottom: 0;
}
.BoxOther .Box_Content .ant-form {
  padding: 0px 20px;
}
.BoxOther .Box_Content .ant-form button {
  width: 100%;
}
.BoxOther .Box_Content .ant-form .ant-form-item-label {
  width: 100%;
  text-align: left;
}
.BoxOther .ErrorShow {
  background-color: #fee5e5;
  color: #eb2121;
  padding: 10px;
  margin: 10px 10px 0px 10px;
  border-radius: 8px;
  text-align: center;
}
.sf {
  background-image: url("/vn/img/other/sf.jpg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
#maintain {
  background-image: url("/vn/img/other/Bg.jpg");
  background-repeat: no-repeat;
  background-size: cover;
}
.UploadFilesUserCenter h2 {
  font-weight: bold;
  font-size: 24px;
  margin-bottom: 0;
}
.UploadFilesUserCenter .noNeedFiles .Top {
  padding-bottom: 15px;
  border-bottom: 1px solid #cccccc;
}
.UploadFilesUserCenter .Top {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  padding: 10px 0;
}
.UploadFilesUserCenter .Top .h2 {
  flex: 0 0 12%;
  margin-bottom: 0;
  color: #222222;
}
.UploadFilesUserCenter .Top .txt {
  flex: 0 0 70%;
  color: #999999;
}
.UploadFilesUserCenter .Top .howtxt {
  color: #00a6ff;
  cursor: pointer;
}
.Uploadfiles .Title .name {
  color: #222222;
  font-weight: bold;
  font-size: 16px;
}
.Uploadfiles .StatusBox {
  width: 500px;
  height: 296px;
  background-color: #f6f6f6;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  margin-top: 20px;
  margin-bottom: 20px;
}
.Uploadfiles .StatusBox h2 {
  font: normal normal 600 18px/28px Roboto;
  color: #666;
  padding: 10px;
}
.Uploadfiles .StatusBox Button {
  margin-top: 10px;
  width: 240px;
}
.Uploadfiles .UploadFilesSet {
  padding-top: 25px;
  padding-bottom: 25px;
  border-top: 1px solid #cccccc;
}
.Uploadfiles .UploadFilesSet .Title {
  display: flex;
  justify-content: space-between;
  width: 490px;
}
.Uploadfiles .UploadFilesSet .Title .name {
  color: #222222;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: bold;
  font-size: 16px;
}
.Uploadfiles .UploadFilesSet .Title .name img {
  margin-right: 5px;
  width: 20px;
}
.Uploadfiles .UploadFilesSet .Title .eg {
  color: #00a6ff;
  cursor: pointer;
}
.Uploadfiles .UploadFilesSet .UploadNote {
  padding-top: 15px;
  color: #666;
}
.Uploadfiles .UploadFilesSet .tryTime {
  width: 240px;
  text-align: center;
  padding-bottom: 5px;
}
.Uploadfiles .UploadFilesSet .Submit {
  width: 240px;
}
.Uploadfiles .UploadFilesSet .Content {
  display: flex;
  padding-top: 15px;
  justify-content: space-between;
  width: 505px;
}
.Uploadfiles .UploadFilesSet .Content label {
  color: #222;
}
.Uploadfiles .UploadFilesSet .Content .SelectFile {
  width: 240px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px dashed #cccccc;
  border-radius: 10px;
  margin: 20px 0;
  color: #00a6ff;
  cursor: pointer;
}
.Uploadfiles .UploadFilesSet .Content .SelectFile img {
  margin-right: 10px;
}
.Uploadfiles .UploadFilesSet .Content .image-item {
  background-color: #f5f5f5;
  border-radius: 8px;
  width: 240px;
  margin: 20px 0px;
  cursor: pointer;
  padding: 15px 10px;
}
.Uploadfiles .UploadFilesSet .Content .image-item .name {
  word-break: break-all;
}
.upload-lesson-wrap {
  position: relative;
  width: 100%;
  height: 400px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}
.upload-lesson-wrap.upload-ls-1 {
  background-image: url("/vn/img/upload/1.png");
}
.upload-lesson-wrap.upload-ls-2 {
  background-image: url("/vn/img/upload/2.png");
}
.upload-lesson-wrap.upload-ls-3 {
  background-image: url("/vn/img/upload/3.png");
}
.upload-lesson-wrap.upload-ls-4 {
  background-image: url("/vn/img/upload/4.png");
}
.upload-lesson-wrap h5 {
  font: normal normal bold 14px/24px Roboto;
}
.EgBox {
  display: flex;
  justify-content: center;
  flex: 1 1;
}
.EgBox div {
  flex: 0.5 1;
}
.EgBox img {
  width: 60%;
  height: auto;
  display: block;
  margin: auto;
}
.EgBox .textC_1 {
  color: red;
}
.uploadFiles-removeModal .ant-modal-confirm-btns .ant-btn:first-child {
  border-color: #00a6ff;
  color: #00a6ff;
}
.uploadFiles-removeModal .ant-modal-confirm-btns .ant-btn:first-child:hover,
.uploadFiles-removeModal .ant-modal-confirm-btns .ant-btn:first-child:focus {
  color: #29bbff;
  border-color: #29bbff;
}
#upload_wrapper .slide-arrow {
  width: 32px;
  height: 64px;
  position: absolute;
  top: 250px;
  outline: none;
  border-width: 0;
  border-radius: 4px;
  cursor: pointer;
  background-color: #00000040;
  transition: background-color 0.3s;
}
#upload_wrapper .slide-arrow:hover {
  background-color: #00000080;
}
#upload_wrapper .slide-prev {
  left: 36px;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url("/vn/img/upload/icon-left.svg");
}
#upload_wrapper .slide-next {
  right: 36px;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url("/vn/img/upload/icon-right.svg");
}
#upload_wrapper .ant-carousel .custom-Carousel .slick-dots li button {
  background: #00000040;
  border-radius: 50%;
  height: 8px;
  width: 8px;
}
#upload_wrapper .ant-carousel .custom-Carousel .slick-dots li.slick-active button {
  background: #00a6ff;
  border-radius: 8px;
  height: 8px;
  width: 20px;
}
#upload_wrapper .setText {
  text-align: left;
  margin-bottom: 20px;
}
.account-wrap.address-management .address-wrap {
  width: 100%;
  display: flex;
  gap: 10px;
}
.account-wrap.address-management .address-wrap .address-item-list {
  display: flex;
  gap: 10px;
}
.account-wrap.address-management .address-wrap .address-item-list .address-item {
  width: 260px;
  height: 130px;
  color: #666;
  margin-bottom: 18px;
  border-radius: 10px;
  padding: 10px;
  background: #fff;
  border: 2px solid #e3e3e8;
  font-size: 12px;
  cursor: pointer;
}
.account-wrap.address-management .address-wrap .address-item-list .address-item .info-name-edit {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 10px;
}
.account-wrap.address-management .address-wrap .address-item-list .address-item .info-name-edit .info-name {
  width: 55%;
  font-weight: bold;
  color: #000;
  font-size: 16px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.account-wrap.address-management .address-wrap .address-item-list .address-item .info-name-edit .info-edit {
  width: 45%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.account-wrap.address-management .address-wrap .address-item-list .address-item .info-name-edit .info-edit .default {
  color: #0CCC3C;
  margin-right: 5px;
  display: inline-block;
  background: url("/vn/img/icon/greenTick.svg") 0 center no-repeat;
  width: 70px;
  text-align: right;
}
.account-wrap.address-management .address-wrap .address-item-list .address-item .info-name-edit .info-edit .edit {
  opacity: 1;
}
.account-wrap.address-management .address-wrap .address-item-list .address-item .info-phone {
  margin-bottom: 10px;
}
.account-wrap.address-management .address-wrap .address-item-list .address-item .info-address {
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-all;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}
.footer-seo-wrap {
  background-color: #00a6ff;
  color: #fff;
  overflow: hidden;
}
.footer-seo-wrap .common-distance {
  padding: 0;
}
.footer-seo-wrap h1,
.footer-seo-wrap h2,
.footer-seo-wrap h3 {
  color: #fff;
  padding-top: 0.5em;
}
.footer-seo-wrap h1 {
  text-align: center;
}
.footer-seo-wrap .seo-container {
  padding: 50px 0;
  position: relative;
}
.footer-seo-wrap .seo-container .button-more {
  width: 100%;
  position: absolute;
  left: 0;
  top: 145px;
  background: linear-gradient(0deg, rgba(0, 166, 255, 0.878) 37.3%, rgba(0, 166, 255, 0) 100%);
  height: 145px;
}
.footer-seo-wrap .seo-container .button-more .show-more-arrow {
  position: absolute;
  width: 94px;
  left: calc(50% - 47px);
  top: 50px;
  display: inline-block;
  border-radius: 18px;
  background-color: rgba(0, 0, 0, 0.2);
  text-align: center;
  line-height: 36px;
  cursor: pointer;
  transition-duration: 2s;
}
.footer-seo-wrap .seo-container .button-more .show-more-arrow:hover {
  transform: scale(1.1);
  transition-duration: 1.5s;
}
.footer-seo-wrap .seo-container .button-up {
  position: absolute;
  left: calc(50% - 18px);
  bottom: 15px;
  cursor: pointer;
  font-size: 18px;
}
.footer-seo-wrap .seo-container .button-up .anticon-up {
  width: 36px;
  height: 36px;
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 18px;
  text-align: center;
  line-height: 36px;
  transition-duration: 2s;
}
.footer-seo-wrap .seo-container .button-up .anticon-up:hover {
  transform: scale(1.2);
  transition-duration: 1.5s;
}
.footer-seo-wrap .seo-container ol {
  padding-left: 25px;
}
.footer-seo-wrap .seo-container ol li {
  list-style: disc;
}
.DepositCenter,
.customModalMask {
  position: relative;
  width: 100%;
}
.DepositCenter .methodsList .deposit-item .deposit-sprite,
.customModalMask .methodsList .deposit-item .deposit-sprite {
  width: 40px;
  height: 40px;
}
.DepositCenter .methodsList .deposit-item .deposit-sprite.LB,
.customModalMask .methodsList .deposit-item .deposit-sprite.LB {
  background: url("/vn/img/central-payment/methods/LB.svg") center center no-repeat;
}
.DepositCenter .methodsList .deposit-item .deposit-sprite.BQR,
.customModalMask .methodsList .deposit-item .deposit-sprite.BQR {
  background: url("/vn/img/central-payment/methods/BQR.svg") center center no-repeat;
}
.DepositCenter .methodsList .deposit-item .deposit-sprite.CC,
.customModalMask .methodsList .deposit-item .deposit-sprite.CC {
  background: url("/vn/img/central-payment/methods/CC.svg") center center no-repeat;
}
.DepositCenter .methodsList .deposit-item .deposit-sprite.FP,
.customModalMask .methodsList .deposit-item .deposit-sprite.FP {
  background: url("/vn/img/central-payment/methods/FP.svg") center center no-repeat;
}
.DepositCenter .methodsList .deposit-item .deposit-sprite.LBQR,
.customModalMask .methodsList .deposit-item .deposit-sprite.LBQR {
  background: url("/vn/img/central-payment/methods/LBQR.svg") center center no-repeat;
}
.DepositCenter .methodsList .deposit-item .deposit-sprite.MMLB,
.customModalMask .methodsList .deposit-item .deposit-sprite.MMLB {
  background: url("/vn/img/central-payment/methods/LB.svg") center center no-repeat;
}
.DepositCenter .methodsList .deposit-item .deposit-sprite.MMO,
.customModalMask .methodsList .deposit-item .deposit-sprite.MMO {
  background: url("/vn/img/central-payment/methods/MMO.svg") center center no-repeat;
}
.DepositCenter .methodsList .deposit-item .deposit-sprite.PHC,
.customModalMask .methodsList .deposit-item .deposit-sprite.PHC {
  background: url("/vn/img/central-payment/methods/PHC.svg") center center no-repeat;
}
.DepositCenter .methodsList .deposit-item .deposit-sprite.QD,
.customModalMask .methodsList .deposit-item .deposit-sprite.QD {
  background: url("/vn/img/central-payment/methods/QD.svg") center center no-repeat;
}
.DepositCenter .methodsList .deposit-item .deposit-sprite.VP,
.customModalMask .methodsList .deposit-item .deposit-sprite.VP {
  background: url("/vn/img/central-payment/methods/VP.svg") center center no-repeat;
}
.DepositCenter .methodsList .deposit-item .deposit-sprite.ZLP,
.customModalMask .methodsList .deposit-item .deposit-sprite.ZLP {
  background: url("/vn/img/central-payment/methods/ZLP.svg") center center no-repeat;
}
.DepositCenter .methodsList .deposit-item .deposit-sprite.CTC,
.customModalMask .methodsList .deposit-item .deposit-sprite.CTC {
  background: url("/vn/img/central-payment/methods/CTC.svg") center center no-repeat;
}
.DepositCenter .PHC-MOMO,
.customModalMask .PHC-MOMO {
  background: url("/vn/img/central-payment/bankIcon/PHC/MMO.png") center no-repeat;
  background-size: contain;
  width: 24px;
  height: 24px;
  flex: 0.8 1;
}
.DepositCenter .PHC-MOBI,
.customModalMask .PHC-MOBI {
  background: url("/vn/img/central-payment/bankIcon/PHC/VMS.png") center no-repeat;
  background-size: contain;
  width: 24px;
  height: 24px;
  flex: 0.8 1;
}
.DepositCenter .PHC-VIETNA,
.customModalMask .PHC-VIETNA {
  background: url("/vn/img/central-payment/bankIcon/PHC/VNM.png") center no-repeat;
  background-size: contain;
  width: 24px;
  height: 24px;
  flex: 0.8 1;
}
.DepositCenter .PHC-VINA,
.customModalMask .PHC-VINA {
  background: url("/vn/img/central-payment/bankIcon/PHC/VNP.png") center no-repeat;
  background-size: contain;
  width: 24px;
  height: 24px;
  flex: 0.8 1;
}
.DepositCenter .PHC-VIETTEL,
.customModalMask .PHC-VIETTEL {
  background: url("/vn/img/central-payment/bankIcon/PHC/VTT.png") center no-repeat;
  background-size: contain;
  width: 24px;
  height: 24px;
  flex: 0.8 1;
}
.DepositCenter .PHC-ZING,
.customModalMask .PHC-ZING {
  background: url("/vn/img/central-payment/bankIcon/PHC/ZING.png") center no-repeat;
  background-size: contain;
  width: 30px;
  height: 30px;
  flex: 0.8 1;
}
.DepositCenter .depositBankIcon,
.customModalMask .depositBankIcon {
  background: url("/vn/img/central-payment/bankIcon/icon.svg") center center no-repeat;
  width: 20px;
  height: 20px;
}
.DepositCenter .bankNone,
.customModalMask .bankNone {
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: column;
  background-color: #fff;
  border-radius: 10px;
}
.DepositCenter .bankNone > span,
.customModalMask .bankNone > span {
  background: url("/vn/img/central-payment/bankNone.svg") center center no-repeat;
  width: 130px;
  height: 97px;
  margin: 20px auto;
  background-size: contain;
}
.DepositCenter .bankNone .PHC-bankNone,
.customModalMask .bankNone .PHC-bankNone {
  background: url("/vn/img/central-payment/PHC_bankNone.svg") center center no-repeat;
  width: 130px;
  height: 97px;
  margin: 20px auto;
  background-size: contain;
}
.DepositCenter .bankNone p,
.customModalMask .bankNone p {
  color: #666;
  font-size: 14px;
  line-height: 16px;
  padding: 0 30px 20px;
  text-align: center;
}
.DepositCenter .bankNone p span,
.customModalMask .bankNone p span {
  color: #00A6FF;
  text-decoration: underline;
  cursor: pointer;
}
.DepositCenter .ant-time-picker-input,
.customModalMask .ant-time-picker-input,
.DepositCenter .ant-calendar-picker-input .ant-input,
.customModalMask .ant-calendar-picker-input .ant-input {
  border-width: 0px;
  background-color: transparent;
}
.DepositCenter input,
.customModalMask input {
  padding: 0px;
}
.DepositCenter .banners,
.customModalMask .banners {
  background: url("/vn/img/central-payment/depositBanner.png") center center no-repeat;
  width: 100%;
  height: 50px;
  background-size: cover;
  margin-bottom: 10px;
  margin-top: 8px;
}
.DepositCenter .DepositHeader,
.customModalMask .DepositHeader {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  background-color: #00A6FF;
  height: 48px;
}
.DepositCenter .leftTtransparent,
.customModalMask .leftTtransparent {
  width: 40px;
  height: 40px;
}
.DepositCenter .backIcon,
.customModalMask .backIcon {
  background: url("/vn/img/central-payment/backIcon.svg") center center no-repeat;
  width: 40px;
  height: 40px;
}
.DepositCenter .csIcon,
.customModalMask .csIcon {
  background: url("/vn/img/central-payment/cs.svg") center center no-repeat;
  width: 40px;
  height: 40px;
}
.DepositCenter .headerTitle,
.customModalMask .headerTitle {
  color: #fff;
  font-size: 16px;
  font-weight: bold;
}
.DepositCenter .methodsList,
.customModalMask .methodsList {
  background: #fff;
  border-radius: 8px;
  width: 100%;
}
.DepositCenter .marginL,
.customModalMask .marginL {
  margin-left: 1.2%;
}
.DepositCenter .methodsList .active,
.customModalMask .methodsList .active {
  display: flex;
  flex: 0 0 19%;
  flex-direction: row;
  height: 40px;
  justify-content: center;
  align-items: center;
  background: #F0F0F2;
  background-color: #f0f0f2;
  font-weight: 700;
}
.DepositCenter .methodsActive,
.customModalMask .methodsActive {
  border-radius: 8px;
  flex: 1 1;
}
.DepositCenter .methodsActive input .ant-select-selection,
.customModalMask .methodsActive input .ant-select-selection {
  border: 0;
  border-radius: 8px;
}
.DepositCenter .methodsActive .bankSelect-warp .Item-bankName.inactive .ant-form-item-control,
.customModalMask .methodsActive .bankSelect-warp .Item-bankName.inactive .ant-form-item-control {
  box-shadow: 0;
  width: 100%;
}
.DepositCenter .methodsActive .bankSelect-warp .ant-form-item-label > label::after,
.customModalMask .methodsActive .bankSelect-warp .ant-form-item-label > label::after {
  content: none;
}
.DepositCenter .methodsActive .bankSelect-warp .Item-bankName.active .ant-form-item-control,
.customModalMask .methodsActive .bankSelect-warp .Item-bankName.active .ant-form-item-control {
  box-shadow: 0 0 1px 1px #0000001a, 0 1px 2px 0 #00000014, 0 2px 8px 2px #0000000d;
  border-bottom: none;
  border-radius: 8px 8px 0 0;
}
.DepositCenter .methodsActive .bankSelect-warp .bankNamewrap .ant-input:not(:first-child),
.customModalMask .methodsActive .bankSelect-warp .bankNamewrap .ant-input:not(:first-child) {
  padding-left: 13px;
  border: none;
}
.DepositCenter .methodsActive .bankSelect-warp .bankNamewrap.have .ant-input:not(:first-child),
.customModalMask .methodsActive .bankSelect-warp .bankNamewrap.have .ant-input:not(:first-child) {
  padding-left: 38px;
}
.DepositCenter .methodsActive .bankSelect-warp .bankNamewrap.active .ant-input,
.customModalMask .methodsActive .bankSelect-warp .bankNamewrap.active .ant-input {
  border-radius: 8px 8px 0 0;
}
.DepositCenter .methodsActive .bankSelect-warp .bankNamewrap.active .ant-input:focus,
.customModalMask .methodsActive .bankSelect-warp .bankNamewrap.active .ant-input:focus {
  box-shadow: none;
}
.DepositCenter .methodsActive .bankSelect-warp .bankNameSelect .ant-select-selection,
.customModalMask .methodsActive .bankSelect-warp .bankNameSelect .ant-select-selection {
  box-shadow: none;
  border-radius: 4px;
  height: 30px;
  width: 94%;
  margin: 0 3%;
  background-color: #F3F5F9;
}
.DepositCenter .methodsActive .bankSelect-warp .bankNameSelect .ant-select-selection .searchwrap,
.customModalMask .methodsActive .bankSelect-warp .bankNameSelect .ant-select-selection .searchwrap {
  width: 100%;
  height: 20px;
  line-height: 20px;
  background: none;
  display: flex;
  align-items: center;
}
.DepositCenter .methodsActive .bankSelect-warp .bankNameSelect .ant-select-selection .ant-select-selection__rendered,
.customModalMask .methodsActive .bankSelect-warp .bankNameSelect .ant-select-selection .ant-select-selection__rendered {
  line-height: 35px;
}
.DepositCenter .methodsActive .bankSelect-warp .not-found-content,
.customModalMask .methodsActive .bankSelect-warp .not-found-content {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.DepositCenter .methodsActive .bankSelect-warp .not-found-content .nofoundimg,
.customModalMask .methodsActive .bankSelect-warp .not-found-content .nofoundimg {
  width: 98px;
  height: 98px;
  background-image: url("/vn/img/central-payment/img-no-record.svg");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}
.DepositCenter .methodsActive .bankSelect-warp .not-found-content p,
.customModalMask .methodsActive .bankSelect-warp .not-found-content p {
  width: 100%;
  color: #939393;
  text-align: center;
}
.DepositCenter .methodsActive .drop-area,
.customModalMask .methodsActive .drop-area {
  width: 100%;
  background-color: #fff;
  border-radius: 10px;
}
.DepositCenter .methodsActive .drop-area .ant-select-selection,
.customModalMask .methodsActive .drop-area .ant-select-selection {
  background-color: #fff;
  border: 0;
}
.DepositCenter .methodsActive .drop-area .ant-select-dropdown,
.customModalMask .methodsActive .drop-area .ant-select-dropdown {
  box-shadow: none;
}
.DepositCenter .methodsActive .drop-area .ant-select-dropdown-menu .ant-select-dropdown-menu-item,
.customModalMask .methodsActive .drop-area .ant-select-dropdown-menu .ant-select-dropdown-menu-item {
  text-align: left;
}
.DepositCenter .methodsActive .drop-area .ant-select .ant-select-selection-selected-value,
.customModalMask .methodsActive .drop-area .ant-select .ant-select-selection-selected-value,
.DepositCenter .methodsActive .drop-area .ant-select-dropdown-menu .ant-select-dropdown-menu-item,
.customModalMask .methodsActive .drop-area .ant-select-dropdown-menu .ant-select-dropdown-menu-item {
  display: flex !important;
  align-items: center;
}
.DepositCenter .methodsActive .drop-area .ant-select .ant-select-selection-selected-value span:last-child,
.customModalMask .methodsActive .drop-area .ant-select .ant-select-selection-selected-value span:last-child,
.DepositCenter .methodsActive .drop-area .ant-select-dropdown-menu .ant-select-dropdown-menu-item span:last-child,
.customModalMask .methodsActive .drop-area .ant-select-dropdown-menu .ant-select-dropdown-menu-item span:last-child {
  margin-left: 5px;
}
.DepositCenter .methodsActive .drop-area .ant-select .ant-select-selection-selected-value .bankname,
.customModalMask .methodsActive .drop-area .ant-select .ant-select-selection-selected-value .bankname,
.DepositCenter .methodsActive .drop-area .ant-select-dropdown-menu .ant-select-dropdown-menu-item .bankname,
.customModalMask .methodsActive .drop-area .ant-select-dropdown-menu .ant-select-dropdown-menu-item .bankname {
  width: 90%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.DepositCenter .methodsActive .drop-area .ant-select-dropdown-menu-item-selected,
.customModalMask .methodsActive .drop-area .ant-select-dropdown-menu-item-selected {
  position: relative;
}
.DepositCenter .methodsActive .drop-area .ant-select-dropdown-menu-item-selected::after,
.customModalMask .methodsActive .drop-area .ant-select-dropdown-menu-item-selected::after {
  content: "";
  position: absolute;
  background-image: url("/vn/img/central-payment/miniTick.svg");
  background-position: center center;
  background-repeat: no-repeat;
  width: 14px;
  height: 14px;
  right: 15px;
}
.DepositCenter .methodsActive .drop-area .addBank,
.customModalMask .methodsActive .drop-area .addBank {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  color: #00a6ff;
}
.DepositCenter .methodsActive .openOldAccount,
.customModalMask .methodsActive .openOldAccount {
  font-weight: bold;
  cursor: pointer;
}
.DepositCenter .methodsActive .oldBankModal .DatePickerolumn > div,
.customModalMask .methodsActive .oldBankModal .DatePickerolumn > div {
  width: 48%;
}
.DepositCenter .methodsActive .depositDatePicker .ant-calendar-picker-input.ant-input,
.customModalMask .methodsActive .depositDatePicker .ant-calendar-picker-input.ant-input,
.DepositCenter .methodsActive .depositDatePicker .ant-time-picker-large .ant-time-picker-input,
.customModalMask .methodsActive .depositDatePicker .ant-time-picker-large .ant-time-picker-input {
  border: none;
  height: 43px;
  padding: 1px 10px;
  background-color: #fff;
}
.DepositCenter .methodsActive .depositDatePicker .deposit-date-picker-container .ant-calendar-input-wrap,
.customModalMask .methodsActive .depositDatePicker .deposit-date-picker-container .ant-calendar-input-wrap {
  display: none;
}
.DepositCenter .methodsActive .depositDatePicker .deposit-date-picker-container .ant-calendar-my-select a,
.customModalMask .methodsActive .depositDatePicker .deposit-date-picker-container .ant-calendar-my-select a {
  color: black;
}
.DepositCenter .methodsActive .depositDatePicker .deposit-time-picker-container .ant-time-picker-panel-input-wrap,
.customModalMask .methodsActive .depositDatePicker .deposit-time-picker-container .ant-time-picker-panel-input-wrap {
  display: none;
}
.DepositCenter .methodsActive .ant-form-item,
.customModalMask .methodsActive .ant-form-item {
  margin-bottom: 0;
}
.DepositCenter .methodsActive .UserBankSelect .ant-form-item-label > label::after,
.customModalMask .methodsActive .UserBankSelect .ant-form-item-label > label::after {
  content: none;
}
.DepositCenter .methodsActive .blueTip,
.customModalMask .methodsActive .blueTip {
  color: #00A6FF;
}
.DepositCenter .methodsActive .vnMoneyTip,
.customModalMask .methodsActive .vnMoneyTip {
  margin-top: 8px;
  padding: 8px;
  border-radius: 8px;
  background-color: #e0e0e0;
  color: #666;
}
.DepositCenter .methodsActive .bankSelect-warp .ant-form-item-label .ant-col > label::after,
.customModalMask .methodsActive .bankSelect-warp .ant-form-item-label .ant-col > label::after {
  content: none;
}
.DepositCenter .inputTitle,
.customModalMask .inputTitle {
  font-size: 14px;
  color: #222;
  padding-top: 15px;
  padding-bottom: 5px;
}
.DepositCenter .input-item-wrap input,
.customModalMask .input-item-wrap input {
  border-radius: 8px;
  border: 0;
  width: 100%;
  height: 45px;
  padding: 12px;
  box-sizing: border-box;
  color: #000;
  font-size: 14px;
}
.DepositCenter .input-item-wrap input:focus,
.customModalMask .input-item-wrap input:focus {
  border-color: #00A6FF;
  border-right-width: 1px;
  outline: 0;
  box-shadow: 0 0 0 0.05333rem rgba(24, 144, 255, 0.2);
}
.DepositCenter .inputErr input,
.customModalMask .inputErr input {
  border: 1px solid #F11818;
}
.DepositCenter .inputErr input:focus,
.customModalMask .inputErr input:focus {
  border: 1px solid #F11818;
}
.DepositCenter .input-item-wrap input::-moz-placeholder, .customModalMask .input-item-wrap input::-moz-placeholder {
  color: #ccc;
}
.DepositCenter .input-item-wrap input:-ms-input-placeholder, .customModalMask .input-item-wrap input:-ms-input-placeholder {
  color: #ccc;
}
.DepositCenter .input-item-wrap input::placeholder,
.customModalMask .input-item-wrap input::placeholder {
  color: #ccc;
}
.DepositCenter .input-item-wrap,
.customModalMask .input-item-wrap {
  position: relative;
}
.DepositCenter .input-item-wrap .eyeIcon,
.customModalMask .input-item-wrap .eyeIcon {
  position: absolute;
  right: 10px;
  top: 13px;
  width: 20px;
  height: 20px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  cursor: pointer;
}
.DepositCenter .input-item-wrap .eyeIcon.visibleIcon,
.customModalMask .input-item-wrap .eyeIcon.visibleIcon {
  background-image: url("/vn/img/central-payment/visible-eye.svg");
}
.DepositCenter .input-item-wrap .eyeIcon.invisibleIcon,
.customModalMask .input-item-wrap .eyeIcon.invisibleIcon {
  background-image: url("/vn/img/central-payment/invisible-eye.svg");
}
.DepositCenter .fun88-bank .input-item-wrap input,
.customModalMask .fun88-bank .input-item-wrap input {
  border: 1px solid #E0E0E0;
}
.DepositCenter .fun88-bank .inputErr input,
.customModalMask .fun88-bank .inputErr input {
  border: 1px solid #F11818;
}
.DepositCenter .fun88-bank .tipsGray,
.customModalMask .fun88-bank .tipsGray {
  margin-top: 5px;
  background-color: #f5f5f5;
  border-radius: 6px;
  padding: 10px;
}
.DepositCenter .inputErrMsg,
.customModalMask .inputErrMsg {
  background-color: #FEE5E5;
  color: #F11818;
  border-radius: 8px;
  font-size: 14px;
  padding: 12px;
  margin-top: 5px;
}
.DepositCenter .inputView,
.customModalMask .inputView {
  height: 45px;
  border-radius: 5px;
  border: 1px solid #CCC;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  padding: 12px;
}
.DepositCenter .inputViewIcon,
.customModalMask .inputViewIcon {
  background: url("/vn/img/central-payment/bankIcon/icon.svg") center center no-repeat;
  width: 20px;
  height: 20px;
}
.DepositCenter .inputNameIcon,
.customModalMask .inputNameIcon {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
  width: 80%;
}
.DepositCenter .inputViewName,
.customModalMask .inputViewName {
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #000;
  font-size: 14px;
  padding-left: 5px;
}
.DepositCenter .ant-select-enabled,
.customModalMask .ant-select-enabled {
  width: 100%;
}
.DepositCenter .drawerIcon,
.customModalMask .drawerIcon {
  background: url("/vn/img/central-payment/drawerIcon.svg") center center no-repeat;
  width: 20px;
  height: 20px;
}
.DepositCenter .liveChatBtn,
.customModalMask .liveChatBtn {
  border-radius: 8px;
  background: #fff;
  width: 100%;
  height: 45px;
  line-height: 45px;
  text-align: center;
  color: #00A6FF;
  border: 1.5px solid;
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;
  background-color: transparent;
}
.DepositCenter .openOldAccount,
.customModalMask .openOldAccount {
  color: #00A6FF;
  padding-top: 16px;
  text-decoration: underline;
  display: inline-block;
}
.DepositCenter .submitBorderActive,
.customModalMask .submitBorderActive {
  border: 1px solid #00A6FF;
  color: #00A6FF;
  border-radius: 8px;
  margin-bottom: 15px;
  line-height: 43px;
  height: 43px;
  font-size: 14px;
  text-align: center;
  font-weight: bold;
  cursor: pointer;
}
.DepositCenter .submitBtnActive,
.customModalMask .submitBtnActive {
  background: #00A6FF;
  border-radius: 8px;
  margin-top: 30px;
  margin-bottom: 15px;
  line-height: 45px;
  height: 45px;
  font-size: 14px;
  color: #fff;
  text-align: center;
  font-weight: bold;
  cursor: pointer;
}
.DepositCenter .submitBtn,
.customModalMask .submitBtn {
  background: #ccc;
  border-radius: 8px;
  margin-top: 30px;
  margin-bottom: 15px;
  line-height: 45px;
  height: 45px;
  font-size: 14px;
  color: #fff;
  text-align: center;
  font-weight: bold;
  cursor: not-allowed;
}
.DepositCenter .depositBonus,
.customModalMask .depositBonus {
  width: 100%;
  margin: 5px auto 0;
}
.DepositCenter .depositBonus p,
.customModalMask .depositBonus p {
  color: #666;
  margin-bottom: 5px;
}
.DepositCenter .depositBonus div,
.customModalMask .depositBonus div {
  width: 100%;
  padding: 10px 16px;
  border: 1px solid #CCCCCC;
  border-radius: 8px;
  color: #999999;
  background: #E8E8E8;
  font-size: 14px;
  font-weight: 400;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.DepositCenter .amount,
.customModalMask .amount {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  width: 100%;
  line-height: 50px;
}
.DepositCenter .amount .amountTitle,
.customModalMask .amount .amountTitle {
  font-size: 14px;
  color: #222;
}
.DepositCenter .amount .amountValue,
.customModalMask .amount .amountValue {
  font-size: 18px;
  font-weight: bold;
  color: #000;
}
.DepositCenter h4,
.customModalMask h4 {
  line-height: 50px;
  margin-bottom: 0;
}
.DepositCenter .paymentData,
.customModalMask .paymentData {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  width: 100%;
  margin-bottom: 16px;
  padding-right: 1.2rem;
}
.DepositCenter .paymentData .paymentTitle,
.customModalMask .paymentData .paymentTitle {
  color: #222;
  font-size: 14px;
}
.DepositCenter .paymentData .redTips,
.customModalMask .paymentData .redTips {
  padding: 3px 2px;
  font-size: 12px;
  font-weight: bold;
  color: #fff;
  border-radius: 4px;
  background-color: #f00;
  margin-left: 10px;
}
.DepositCenter .paymentData .paymentValue,
.customModalMask .paymentData .paymentValue {
  color: #000;
  font-size: 14px;
}
.DepositCenter .paymentData .paymentItem,
.customModalMask .paymentData .paymentItem {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-direction: row;
}
.DepositCenter .paymentData .copyIcon,
.customModalMask .paymentData .copyIcon {
  background: url("/vn/img/central-payment/copyIcon.svg") center center no-repeat;
  width: 20px;
  height: 20px;
  margin-left: 3px;
  cursor: pointer;
}
.DepositCenter .paymentData .copyActive,
.customModalMask .paymentData .copyActive {
  background: url("/vn/img/central-payment/checkIcon.svg") center center no-repeat;
  width: 20px;
  height: 20px;
  margin-left: 3px;
  cursor: pointer;
}
.DepositCenter .paymentData .copyActives,
.customModalMask .paymentData .copyActives {
  width: 20px;
  height: 20px;
}
.DepositCenter .CTC_copy,
.customModalMask .CTC_copy {
  justify-content: flex-start;
  width: 100%;
  background-color: #f5f5f5;
  border-radius: 8px;
  height: 40px;
}
.DepositCenter .CTC_copy .CTC_copyItem,
.customModalMask .CTC_copy .CTC_copyItem {
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  justify-content: space-between;
  padding: 0 10px 0 10px;
}
.DepositCenter .CTC_copy .CTC_copyItem span,
.customModalMask .CTC_copy .CTC_copyItem span {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.DepositCenter .tipsMsg,
.customModalMask .tipsMsg {
  background: #FFF5BF;
  border-radius: 8px;
  padding: 12px;
  width: 100%;
  margin-bottom: 10px;
}
.DepositCenter .tipsMsg .tipsMsgItem,
.customModalMask .tipsMsg .tipsMsgItem {
  font-size: 12px;
  color: #83630B;
  line-height: 16px;
  white-space: pre-line;
  text-align: left;
}
.DepositCenter .tipsGray,
.customModalMask .tipsGray {
  border-radius: 8px;
  padding: 9px 0;
  width: 100%;
  color: #666;
  font-size: 12px;
  line-height: 16px;
}
.DepositCenter .tipsGray span,
.customModalMask .tipsGray span {
  color: #00A6FF;
}
.DepositCenter .tipsBlue,
.customModalMask .tipsBlue {
  background: #e6f6ff;
  border-radius: 8px;
  padding: 12px;
  width: 100%;
  color: #00A6FF;
  font-size: 12px;
  line-height: 16px;
}
.DepositCenter .borders,
.customModalMask .borders {
  width: 100%;
  height: 1px;
  background: #EFEFF4;
}
.DepositCenter .methodCodeList,
.customModalMask .methodCodeList {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
}
.DepositCenter .methodsListActive,
.customModalMask .methodsListActive {
  border: 1px solid #00A6FF;
  border-radius: 5px;
  width: 49%;
  margin-top: 6px;
  font-size: 14px;
  color: #00A6FF;
  line-height: 40px;
  height: 40px;
  text-align: center;
}
.DepositCenter .methodsCodeList,
.customModalMask .methodsCodeList {
  border: 1px solid #666;
  border-radius: 5px;
  width: 49%;
  margin-top: 6px;
  font-size: 14px;
  color: #666;
  line-height: 40px;
  height: 40px;
  text-align: center;
}
.DepositCenter .BQR_Trips,
.customModalMask .BQR_Trips {
  padding: 12px;
  border-radius: 8px;
  background: #FFF5BF;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  width: 100%;
  margin-top: 20px;
}
.DepositCenter .BQR_Trips span,
.customModalMask .BQR_Trips span {
  font-size: 12px;
  color: #83630B;
  line-height: 16px;
}
.DepositCenter .BQR_Trips .BQR_TripsBank,
.customModalMask .BQR_Trips .BQR_TripsBank {
  border: 1px solid #C5B979;
  border-radius: 3px;
  padding-left: 8px;
  padding-right: 8px;
  height: 52px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  margin-right: 8px;
}
.DepositCenter .BQR_Trips .BQR_TripsBank1,
.customModalMask .BQR_Trips .BQR_TripsBank1 {
  background: url("/vn/img/central-payment/methods/LB.svg") center center no-repeat;
  width: 20px;
  height: 20px;
  margin-right: 3px;
  background-size: contain;
}
.DepositCenter .BQR_Trips .BQR_TripsBank2,
.customModalMask .BQR_Trips .BQR_TripsBank2 {
  background: url("/vn/img/central-payment/methods/MMO.svg") center center no-repeat;
  width: 20px;
  height: 20px;
  margin-right: 3px;
  background-size: contain;
}
.DepositCenter .BQR_Trips .BQR_TripsBank3,
.customModalMask .BQR_Trips .BQR_TripsBank3 {
  background: url("/vn/img/central-payment/methods/pay.png") center center no-repeat;
  width: 20px;
  height: 20px;
  background-size: contain;
}
.DepositCenter .CTCTitleIcon,
.customModalMask .CTCTitleIcon {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  color: #222;
  font-size: 14px;
  padding-top: 20px;
  padding-bottom: 20px;
  font-weight: 600;
}
.DepositCenter .CTCTitleIcon span,
.customModalMask .CTCTitleIcon span {
  padding-top: 10px;
}
.DepositCenter .exchangeTitle,
.customModalMask .exchangeTitle {
  color: #222;
  font-size: 14px;
}
.DepositCenter .exchangeValue,
.customModalMask .exchangeValue {
  color: #000;
  font-size: 14px;
  font-weight: bold;
}
.DepositCenter .exchangeRate,
.customModalMask .exchangeRate {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  padding-top: 20px;
  width: 100%;
  margin-bottom: 16px;
}
.DepositCenter .methodCodeCTC,
.customModalMask .methodCodeCTC {
  width: 48%;
  background-color: #fff;
  border: 1px solid #E0E0E0;
  height: 46px;
  border-radius: 8px;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 6px 10px;
  margin-bottom: 10px;
}
.DepositCenter .methodCodeCTC .select-box,
.customModalMask .methodCodeCTC .select-box {
  width: 16px;
  height: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.DepositCenter .methodCodeCTC .CTCmethodName,
.customModalMask .methodCodeCTC .CTCmethodName {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  line-height: 46px;
}
.DepositCenter .methodCodeCTC h5,
.customModalMask .methodCodeCTC h5 {
  font-size: 12px;
  color: #2C2C2C;
  padding-left: 12px;
  margin-bottom: 0;
}
.DepositCenter .methodCodeCTC p,
.customModalMask .methodCodeCTC p {
  font-size: 12px;
  color: #999;
}
.DepositCenter .CTCList,
.customModalMask .CTCList {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  font-weight: bold;
  gap: 15px;
  color: #222;
  cursor: pointer;
}
.DepositCenter .CTCList .CTCListItemActive,
.customModalMask .CTCList .CTCListItemActive {
  border-radius: 10px;
  width: 48%;
  border: 2px solid #00A6FF;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 10px 15px;
  font-size: 14px;
  text-align: center;
  background-color: #fff;
}
.DepositCenter .CTCList .CTCListItem,
.customModalMask .CTCList .CTCListItem {
  border-radius: 10px;
  width: 48%;
  border: 2px solid #EFEFF4;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 10px 15px;
  font-size: 14px;
  text-align: center;
  background-color: #fff;
}
.DepositCenter .CTCList p,
.customModalMask .CTCList p {
  margin-bottom: 0px;
}
.DepositCenter .CTCList .usdt-trc20,
.customModalMask .CTCList .usdt-trc20 {
  position: relative;
}
.DepositCenter .CTCList .usdt-trc20::after,
.customModalMask .CTCList .usdt-trc20::after {
  content: "Miễn Phí";
  position: absolute;
  top: 0;
  right: 0;
  line-height: 18px;
  background-color: #EB2121;
  color: #fff;
  font-size: 10px;
  padding: 0 8px;
  border-radius: 0 7px 0 7px;
  text-align: center;
  font-weight: normal;
}
.DepositCenter .CIC-help,
.customModalMask .CIC-help {
  width: 100%;
  text-align: right;
  color: #00A6FF;
  padding-bottom: 15px;
  cursor: pointer;
  font-weight: bold;
}
.DepositCenter .CTC-tips,
.customModalMask .CTC-tips {
  background: #E0E0E0;
  color: #666;
  border-radius: 8px;
  padding: 10px;
  margin-top: 10px;
  font-size: 12px;
}
.DepositCenter .noteDep .notDepTitle,
.customModalMask .noteDep .notDepTitle {
  font-weight: bold;
}
.DepositCenter .noteDep .notDepContent,
.customModalMask .noteDep .notDepContent {
  color: #666666;
  font-size: 14px;
  font-weight: 400;
}
.DepositCenter .promptMsg,
.customModalMask .promptMsg {
  color: #666;
  font-size: 14px;
  padding: 0 30px;
}
.DepositCenter .QD-qrCode,
.customModalMask .QD-qrCode {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  margin-top: 15px;
}
.DepositCenter .QD-qrCode .QD-qrCode-border,
.customModalMask .QD-qrCode .QD-qrCode-border {
  border: 1px solid #EFEFF4;
  padding: 5px;
  border-radius: 8px;
}
.DepositCenter .QD-qrCode .QD-qrCode-border img,
.customModalMask .QD-qrCode .QD-qrCode-border img {
  width: 150px;
}
.DepositCenter .QD-qrCode .DQ-download,
.customModalMask .QD-qrCode .DQ-download {
  background: #00A6FF;
  border-radius: 5px;
  padding: 4px;
  padding-left: 6px;
  padding-right: 6px;
  margin-top: 16px;
  margin-bottom: 20px;
  color: #fff;
  font-size: 14px;
  cursor: pointer;
}
.DepositCenter .uploadFile .upload,
.customModalMask .uploadFile .upload {
  font-size: 14px;
  color: #666;
}
.DepositCenter .uploadFile .upload .uploadSlip,
.customModalMask .uploadFile .upload .uploadSlip {
  padding: 4px;
  background: #FFE273;
  color: #000;
  font-size: 12px;
  margin-left: 4px;
  border-radius: 4px;
  font-weight: bold;
}
.DepositCenter .uploadFile .fileList,
.customModalMask .uploadFile .fileList {
  margin-top: 10px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
}
.DepositCenter .uploadFile .fileItem,
.customModalMask .uploadFile .fileItem {
  background: #EFEFF4;
  font-size: 30px;
  color: #00A6FF;
  width: 80px;
  height: 80px;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-right: 10px;
  position: relative;
  border: 2px dashed #00A6FF;
  box-sizing: border-box;
  cursor: pointer;
}
.DepositCenter .uploadFile .fileItem img,
.customModalMask .uploadFile .fileItem img {
  width: 80px;
  height: 80px;
}
.DepositCenter .uploadFile .fileItem.hasfiles,
.customModalMask .uploadFile .fileItem.hasfiles {
  border: none;
}
.DepositCenter .uploadFile .uploadTrips,
.customModalMask .uploadFile .uploadTrips {
  font-size: 14px;
  color: #999;
  padding-bottom: 10px;
  line-height: 18px;
  padding-top: 16px;
}
.DepositCenter .uploadFile .removeFile,
.customModalMask .uploadFile .removeFile {
  background: url("/vn/img/central-payment/fildIcon.svg") center center no-repeat;
  width: 20px;
  height: 20px;
  position: absolute;
  right: -5px;
  top: -5px;
}
.DepositCenter .uploadFile .uploadUp,
.customModalMask .uploadFile .uploadUp {
  width: 100%;
  line-height: 43px;
  border-radius: 8px;
  background: #F5F5F5;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 10px;
  cursor: pointer;
  color: #00a6ff;
}
.DepositCenter .uploadFile .uploadUp .add,
.customModalMask .uploadFile .uploadUp .add {
  width: 16px;
  line-height: 16px;
  text-align: center;
  background-color: #00a6ff;
  color: #fff;
  border-radius: 8px;
  font-size: 16px;
  margin-right: 10px;
}
.DepositCenter .uploadFile .uploaded,
.customModalMask .uploadFile .uploaded {
  width: 100%;
  line-height: 43px;
  border-radius: 8px;
  background: #F5F5F5;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 10px;
}
.DepositCenter .uploadFile .uploaded .removeIcon,
.customModalMask .uploadFile .uploaded .removeIcon {
  cursor: pointer;
  display: block;
  width: 16px;
  height: 16px;
  background-image: url("/vn/img/central-payment/removeIcon.svg");
}
.DepositCenter .uploadFile .uploadfileRemark,
.customModalMask .uploadFile .uploadfileRemark {
  width: 100%;
  line-height: 43px;
  border-radius: 8px;
  background: #F5F5F5;
  display: flex;
  align-items: center;
  padding: 0 10px;
  margin: 10px 0;
}
.DepositCenter .uploadFile .uploadfileRemark span,
.customModalMask .uploadFile .uploadfileRemark span {
  color: #00a6ff;
  margin-left: 2px;
  cursor: pointer;
}
@keyframes scaleAnimation {
  0% {
    transform: scale(0.6);
  }
  100% {
    transform: scale(1);
  }
}
.DepositCenter .openPayModal,
.customModalMask .openPayModal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;
  animation: scaleAnimation 0.2s ease-in-out;
}
.DepositCenter .openPayModal .openPayModalCenter,
.customModalMask .openPayModal .openPayModalCenter {
  width: 400px;
  border-radius: 20px;
  background: #fff;
  overflow: hidden;
}
.DepositCenter .openPayModal .openPayModalTitle,
.customModalMask .openPayModal .openPayModalTitle {
  background: #00A6FF;
  color: #fff;
  font-size: 20px;
  text-align: center;
  line-height: 60px;
}
.DepositCenter .openPayModal .openPayModalBody,
.customModalMask .openPayModal .openPayModalBody {
  color: #000;
  font-size: 14px;
  text-align: left;
  line-height: 20px;
  padding: 24px 30px;
}
.DepositCenter .openPayModal .openPayModalBtn,
.customModalMask .openPayModal .openPayModalBtn {
  width: 180px;
  color: #fff;
  line-height: 40px;
  font-size: 16px;
  font-weight: bold;
  border-radius: 8px;
  background: #00A6FF;
  margin: auto;
  text-align: center;
  cursor: pointer;
  margin-bottom: 24px;
}
.DepositCenter .USDT-TRC20Icon,
.customModalMask .USDT-TRC20Icon {
  background: url("/vn/img/central-payment/USDT-TRC20.svg") center center no-repeat;
  width: 98px;
  height: 30px;
}
.DepositCenter .USDT-ERC20Icon,
.customModalMask .USDT-ERC20Icon {
  background: url("/vn/img/central-payment/USDT-ERC20.svg") center center no-repeat;
  width: 98px;
  height: 30px;
}
.DepositCenter .BTCIcon,
.customModalMask .BTCIcon {
  background: url("/vn/img/central-payment/USDT-ERC20.svg") center center no-repeat;
  width: 98px;
  height: 30px;
}
.DepositCenter .ETHIcon,
.customModalMask .ETHIcon {
  background: url("/vn/img/central-payment/USDT-ERC20.svg") center center no-repeat;
  width: 98px;
  height: 30px;
}
.DepositCenter .CTCqrcode,
.customModalMask .CTCqrcode {
  width: 100%;
  text-align: center;
}
.DepositCenter .CTCqrcode span,
.customModalMask .CTCqrcode span {
  color: #222;
  font-size: 14px;
  padding-top: 10px;
  width: 100%;
  display: inline-block;
  text-align: left;
}
.DepositCenter .CTCqrcode img,
.customModalMask .CTCqrcode img {
  width: 160px;
  height: 160px;
}
.DepositCenter .PHCbankList,
.customModalMask .PHCbankList {
  width: 100%;
  height: 50px;
}
.DepositCenter .PHCbankItemActive,
.customModalMask .PHCbankItemActive {
  border: 1px solid #25A9E1 !important;
  background: #D6F2FF !important;
}
.DepositCenter .PHCbankItem,
.customModalMask .PHCbankItem {
  background: white;
  float: left;
  border: 1px solid #E3E3E8;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 15%;
  height: 44px;
  border-radius: 5px;
  margin-right: 2%;
  cursor: pointer;
}
.DepositCenter .PHCbankItem:last-child,
.customModalMask .PHCbankItem:last-child {
  margin-right: 0;
}
.DepositCenter .PHC-charges,
.customModalMask .PHC-charges {
  border-radius: 8px;
  background: #FFF5BF;
  padding: 12px;
  padding-top: 8px;
  padding-bottom: 8px;
  margin-top: 6px;
  color: #222;
  font-size: 12px;
}
.DepositCenter .PHC-amounts,
.customModalMask .PHC-amounts {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  flex-wrap: wrap;
}
.DepositCenter .PHC-amounts .PHC-amountList,
.customModalMask .PHC-amounts .PHC-amountList {
  width: 32%;
  height: 46px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
  border: 1px solid #EFEFF4;
  border-radius: 8px;
  padding: 6px;
  padding-top: 13px;
  padding-bottom: 13px;
  margin-bottom: 8px;
  color: #222;
  font-size: 12px;
  background-color: #fff;
  cursor: pointer;
}
.DepositCenter .PHC-amounts .PHC-amountList p,
.customModalMask .PHC-amounts .PHC-amountList p {
  font-size: 12px;
  margin: 0px 0px 0px 5px;
}
.DepositCenter .phc-chargesMoney .phc-amount-conversion,
.customModalMask .phc-chargesMoney .phc-amount-conversion {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.DepositCenter .phc-chargesMoney .phc-amount-conversion span:last-child,
.customModalMask .phc-chargesMoney .phc-amount-conversion span:last-child {
  font-weight: bold;
}
.DepositCenter .phc-chargesMoney .chargesMoney,
.customModalMask .phc-chargesMoney .chargesMoney {
  text-align: right;
  color: #00a6ff;
  font-weight: bold;
}
.DepositCenter .depositDatePicker,
.customModalMask .depositDatePicker {
  border-radius: 8px;
  height: 45px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  width: 100%;
}
.DepositCenter .DatePickerolumn,
.customModalMask .DatePickerolumn {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  width: 100%;
}
.DepositCenter .DatePickerolumn .deposit-date-picker-container .ant-calendar-header .ant-calendar-month-select,
.customModalMask .DatePickerolumn .deposit-date-picker-container .ant-calendar-header .ant-calendar-month-select,
.DepositCenter .DatePickerolumn .deposit-date-picker-container .ant-calendar-header .ant-calendar-year-select,
.customModalMask .DatePickerolumn .deposit-date-picker-container .ant-calendar-header .ant-calendar-year-select,
.DepositCenter .DatePickerolumn .deposit-date-picker-container thead .ant-calendar-column-header .ant-calendar-column-header-inner,
.customModalMask .DatePickerolumn .deposit-date-picker-container thead .ant-calendar-column-header .ant-calendar-column-header-inner {
  color: #000;
  font-weight: bold;
}
.noteDep {
  margin-top: 1rem;
  color: #222;
  font-weight: bold;
}
.noteDep ol {
  padding-inline-start: 20px;
}
.noteDep ol li {
  color: #999;
  font-weight: 400;
  line-height: 1.5;
  list-style: auto;
}
.noteDep ol li:first-child {
  margin-top: 15px;
}
.activeSelect {
  width: 12px;
  height: 12px;
  border-radius: 12px;
  background-color: #00A6FF;
  border: 2px solid #fff;
  outline: 2px solid #00A6FF;
}
.borderSelect {
  border: 2px solid #ccc;
  width: 16px;
  height: 16px;
  border-radius: 20px;
}
.oldBankModal .oldBankHeader {
  padding-top: 12px;
  padding-bottom: 12px;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.oldBankModal .oldBankHeader span {
  float: left;
  width: 20px;
  height: 20px;
  background-image: url("/vn/img/central-payment/backIcon.svg");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  cursor: pointer;
}
.oldBankModal .oldBankHeader h3 {
  color: #000;
  width: 100%;
  text-align: center;
  margin-bottom: 0;
}
.oldBankModal .oldBankModalCenter {
  overflow-x: hidden;
  height: 100%;
  width: 100%;
}
.oldBankModal .oldBankModalCenter .viewArea {
  text-align: start;
}
.oldBankModal .oldBankModalCenter .viewArea > .inputTitle:first-child {
  padding-top: 1px;
}
.oldBankModal .oldBankModalCenter .viewArea .tipsGray {
  margin-top: 7px;
}
.oldBankModal .oldBankModalCenter .viewArea .uploadFile .uploadedBox {
  margin-bottom: 30px;
}
.oldBankModal .oldBankModalCenter .viewArea .uploadFile .uploadBtn {
  margin-bottom: 15px;
}
.oldBankModal .oldBankModalCenter .viewArea .date-picker-container .dateIcon {
  background: url("/vn/img/central-payment/dateIcon.svg") left center no-repeat;
  width: 16px;
  height: 16px;
  background-size: cover;
  cursor: pointer;
  margin-right: -4px;
}
.oldBankModal .oldBankModalCenter .viewArea .time-picker-container .timeIcon {
  background: url("/vn/img/central-payment/timeIcon.svg") center center no-repeat;
  width: 16px;
  height: 16px;
  background-size: cover;
  cursor: pointer;
}
.oldBankModal .oldBankModalCenter .submitBtn {
  margin-top: 0;
}
.uploadModal .customModalBody {
  text-align: start !important;
}
.uploadModal .intro {
  color: #666;
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 22px;
}
.uploadModal .inputTitle {
  padding-top: 0;
}
.uploadModal .upload_title {
  font-weight: bold;
  font-size: 14px;
}
.uploadModal .upload_box {
  background-color: #f5f5f5;
  height: 40px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 0 0 0 16px;
  border-radius: 8px;
  overflow: hidden;
}
.uploadModal .upload_box .fileName {
  margin-right: auto;
  flex-basis: 72%;
  overflow: hidden;
  text-overflow: ellipsis;
}
.uploadModal .upload_box button {
  color: #fff;
  width: 96px;
  height: 40px;
  padding: 0;
  font-size: 14px;
  font-weight: 400;
  background-color: #00a6ff;
  border: 0;
  cursor: pointer;
}
.uploadModal .uploadError {
  color: #F11818;
  font-size: 14px;
  font-weight: 500;
  line-height: 22px;
  /* 157.143% */
}
.uploadMethodDrawer .drawer-custom-content-wrapper {
  box-shadow: none !important;
}
.uploadMethodDrawer .drawer-custom-content-wrapper .drawer-custom-content {
  background-color: transparent !important;
  padding: 8px;
}
.uploadMethodDrawer .drawer-custom-content-wrapper .drawer-custom-content .uploadMethods {
  background-color: rgba(249, 249, 249, 0.9);
  margin-bottom: 12px;
  border-radius: 15px;
  overflow: hidden;
}
.uploadMethodDrawer .drawer-custom-content-wrapper .drawer-custom-content .uploadMethods li {
  padding: 17px 18px 15.5px 19px;
  color: #007AFF;
  font-size: 20px;
  font-weight: 400;
  line-height: normal;
  border-bottom: 1px solid #C6C6C8;
  display: flex;
  justify-content: space-between;
}
.uploadMethodDrawer .drawer-custom-content-wrapper .drawer-custom-content .uploadMethods li .cameraIcon {
  width: 25px;
  height: 25px;
  background-image: url("/vn/img/central-payment/cameraIcon.svg");
}
.uploadMethodDrawer .drawer-custom-content-wrapper .drawer-custom-content .uploadMethods li .galleryIcon {
  width: 25px;
  height: 25px;
  background-image: url("/vn/img/central-payment/galleryIcon.svg");
}
.uploadMethodDrawer .drawer-custom-content-wrapper .drawer-custom-content .cancelBtn {
  background-color: #FFF;
  color: #007AFF;
  font-size: 20px;
  font-weight: 700;
  line-height: normal;
  height: 57px;
  margin-bottom: 24px;
}
.nextModal .customModalBody {
  background-color: #EFEFF4;
}
.nextModal .nextModalCenter {
  overflow-x: hidden;
  height: 100%;
  width: 100%;
}
.nextModal .nextModalCenter .depositDatePicker {
  display: inline-block;
  border: 1px solid #E0E0E0;
}
.nextModal .nextModalCenter .depositDatePicker .ant-input-lg {
  height: 43px;
}
.nextModal .nextModalCenter .depositDatePicker .date-picker-container .dateIcon {
  background: url("/vn/img/central-payment/dateIcon.svg") left center no-repeat;
  width: 16px;
  height: 16px;
  background-size: cover;
  cursor: pointer;
  margin-right: -4px;
}
.nextModal .nextModalCenter .depositDatePicker .time-picker-container .timeIcon {
  background: url("/vn/img/central-payment/timeIcon.svg") center center no-repeat;
  width: 16px;
  height: 16px;
  background-size: cover;
  cursor: pointer;
}
.nextModal .viewArea {
  padding: 8px 16px;
  background: #fff;
  margin-bottom: 15px;
}
.nextModal .viewArea .amount,
.nextModal .viewArea .tipsGray,
.nextModal .viewArea .remarks {
  border-top: 1px solid #F3F3F3;
}
.nextModal .viewArea.depositInfo {
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.nextModal .viewArea.depositInfo .paymentData .depositBankIcon {
  display: none;
}
.nextModal .viewArea.depositOptions {
  padding: 0 16px 32px;
}
.nextModal .viewArea.depositOptions .uploadFile {
  margin-bottom: 10px;
}
.nextModal .viewArea .redtip {
  color: #f00;
  font-weight: 600;
  font-size: 12px;
}
.nextModal .viewArea .blueTip {
  color: #00A6FF;
}
.nextModal .viewArea .blueTip.before-ctc p {
  position: relative;
  padding-left: 15px;
}
.nextModal .viewArea .blueTip.before-ctc p::before {
  content: "";
  position: absolute;
  left: 0;
  top: 8px;
  width: 6px;
  height: 6px;
  border-radius: 3px;
  background-color: #00A6FF;
}
.nextModal .viewArea .inextTitle {
  font-size: 14px;
  font-weight: 600;
  line-height: 18px;
  color: #222;
}
.nextModal .submitBtnActive {
  margin-top: 0;
}
.nextModal .remarks p {
  color: #666;
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
  margin-left: 10px;
  margin-bottom: 7px;
}
.nextModal .remarks ol {
  margin-left: 0;
  padding-inline-start: 0;
}
.nextModal .remarks ol li {
  list-style-type: decimal;
  color: #999;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  margin-left: 22px;
}
.sucessModal .sucessModalCenter {
  overflow-x: hidden;
  height: 100%;
  width: 100%;
  padding: 8px;
}
.sucessModal .sucessModalCenter .viewArea {
  padding: 8px 16px 5px;
  background: #fff;
  margin-bottom: 16px;
}
.sucessModal .sucessModalCenter .viewArea .successIcon {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 17px;
}
.sucessModal .sucessModalCenter .viewArea .successIcon span {
  background: url("/vn/img/central-payment/successIcon.svg") center center no-repeat;
  width: 50px;
  height: 50px;
}
.sucessModal .sucessModalCenter .viewArea .successTitle {
  text-align: center;
  width: 100%;
  color: #000;
  font-size: 17px;
  font-weight: 500;
  line-height: normal;
}
.sucessModal .sucessModalCenter .viewArea .amount {
  line-height: 50px;
  border-top: 1px solid #E0E0E0;
  border-bottom: 1px solid #E0E0E0;
}
.sucessModal .sucessModalCenter .viewArea .amount .amountTitle {
  color: #222;
  font-size: 12px;
  font-weight: 600;
}
.sucessModal .sucessModalCenter .viewArea .amount .amountValue {
  color: #222;
  font-size: 14px;
  font-weight: 600;
}
.sucessModal .sucessModalCenter .viewArea .paymentData {
  line-height: 50px;
}
.sucessModal .sucessModalCenter .viewArea .paymentData .paymentTitle {
  color: #222;
  font-weight: 600;
  font-size: 12px;
}
.sucessModal .sucessModalCenter .viewArea .paymentData .paymentValue {
  margin-bottom: 0;
}
.sucessModal .sucessModalCenter .viewArea .paymentData .paymentValue.transactionId {
  color: #222;
  font-size: 14px;
  font-weight: 400;
}
.sucessModal .sucessModalCenter .viewArea .paymentData .copyIcon {
  margin-left: 5px;
}
.sucessModal .sucessModalCenter .viewArea .steps {
  margin: 10px 0;
}
.sucessModal .sucessModalCenter .viewArea .steps .roundsItem {
  font-size: 13px;
  color: #999;
  padding-left: 12px;
  font-weight: 400;
  line-height: normal;
}
.sucessModal .sucessModalCenter .viewArea .steps .rounds {
  border: 2px solid #DCDCE0;
  width: 10px;
  height: 10px;
  border-radius: 10px;
  margin-left: 3px;
}
.sucessModal .sucessModalCenter .viewArea .steps .borderItem {
  color: #999;
  font-size: 11px;
  padding-left: 15px;
  padding-bottom: 15px;
  font-weight: 400;
  line-height: normal;
}
.sucessModal .sucessModalCenter .viewArea .steps .borders {
  height: 40px;
  width: 2px;
  background: #DCDCE0;
  margin-top: 5px;
  margin-bottom: 1px;
  margin-left: 7px;
}
.sucessModal .sucessModalCenter .viewArea .steps .stepA {
  color: #00A6FF;
  padding-left: 8px;
  font-size: 14px;
  font-weight: 500;
  line-height: normal;
}
.sucessModal .sucessModalCenter .viewArea .steps .stepIcon {
  background: url("/vn/img/central-payment/checkIcon.svg") center center no-repeat;
  width: 15px;
  height: 15px;
}
.sucessModal .sucessModalCenter .viewArea .steps .stepsList {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
}
.sucessModal .sucessModalCenter .offerDetails {
  background: #fff;
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}
.sucessModal .sucessModalCenter .offerDetails .offerTitle {
  color: #222222;
  font-size: 16px;
  line-height: 20px;
  font-weight: 700;
  padding: 15px;
  border-bottom: 1px solid #F3F3F3;
}
.sucessModal .sucessModalCenter .offerDetails .offerItem {
  width: 100%;
  display: flex;
  justify-content: space-between;
  color: #222222;
  font-size: 12px;
  line-height: 16px;
  font-weight: 700;
  padding: 15px;
}
.sucessModal .sucessModalCenter .offerDetails .offerItem:last-child {
  border-top: 1px solid #F3F3F3;
}
.sucessModal .sucessModalCenter .successTrips {
  color: #999;
  font-size: 14px;
  text-align: left;
  font-weight: 400;
  line-height: 18px;
  /* 158.333% */
}
.sucessModal .sucessModalCenter .successTrips span {
  color: #00A6FF;
  cursor: pointer;
}
.sucessModal .sucessModalCenter .submitBtnActive {
  height: 40px;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
}
.sucessModal .sucessModalCenter .submitBorderActive {
  height: 40px;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
}
.qrCodeBanksModal .customModalBody {
  padding: 20px 16px 25px !important;
  text-align: start !important;
}
.qrCodeBanksModal .customModalBody .allowQRCodeBankList {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-row-gap: 15px;
  row-gap: 15px;
}
.qrCodeBanksModal .customModalBody .allowQRCodeBankList .bankItem {
  display: flex;
  gap: 21px;
  align-items: center;
}
.qrCodeBanksModal .customModalBody .allowQRCodeBankList .bankItem .bankIcon-default {
  border-radius: 50%;
  width: 24px;
  height: 24px;
  background-size: contain;
}
.qrCodeBanksModal .customModalBody .allowQRCodeBankList .bankItem label {
  width: 90px;
  color: #333;
  font-size: 13px;
  font-weight: 400;
}
.depositModalMask {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;
}
.depositModalMask .modalCotent {
  width: 400px;
  border-radius: 15px;
  background: #fff;
  overflow: hidden;
}
.depositModalMask .modalCotent .modalTitle {
  background: #00A6FF;
  height: 60px;
  border-top-right-radius: 15px;
  border-top-left-radius: 15px;
  color: #fff;
  font-size: 20px;
  text-align: center;
  line-height: 60px;
}
.depositModalMask .modalCotent .modalBody {
  padding: 28px;
  min-height: 50px;
  color: #000;
  font-size: 14px;
  text-align: center;
}
.depositModalMask .modalCotent .modalButtonBox {
  display: flex;
  gap: 20px;
  padding: 0 28px 28px;
  cursor: pointer;
}
.depositModalMask .modalCotent .okBtn {
  background: #00A6FF;
  color: #fff;
  width: 100%;
  line-height: 38px;
  border-radius: 8px;
  text-align: center;
}
.depositModalMask .modalCotent .okBtn.disabled {
  background: #ccc;
  color: #fff;
  text-align: center;
  cursor: not-allowed;
}
.depositModalMask .modalCotent .cancelBtn {
  border: 1px solid #00A6FF;
  color: #00A6FF;
  width: 100%;
  line-height: 37px;
  border-radius: 8px;
  text-align: center;
}
.depositModalMask .modalCotent.fullscreen {
  width: 100vw;
  height: 100vh;
  border-radius: 0;
}
.depositModalMask .modalCotent.fullscreen .modalTitle {
  border-radius: 0;
  height: 52px;
}
.depositModalMask .modalCotent.fullscreen .modalBody {
  padding: 0;
  height: calc(100vh - 52px);
  text-align: start;
  background: #EFEFF4;
}
.modalTips {
  z-index: 999999;
}
.modalTips .intro {
  color: #000;
  font-size: 14px;
  line-height: normal;
  margin-bottom: 8px;
}
.modalTips .intro_left {
  text-align: left;
}
.modalTips .introBlue {
  color: #00A5FD;
}
.modalTips .left {
  text-align: left;
}
.modalTips .titleIcon {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
}
.modalTips .titleIcon span {
  font-size: 14px;
  color: #202939;
  margin-bottom: 15px;
  font-weight: bold;
}
.modalTips .titleIcon .imgIcon {
  width: 58px;
  height: 58px;
  margin-top: 12px;
  margin-bottom: 15px;
}
.modalTips .titleIcon .errorIcon {
  background: url("/vn/img/central-payment/error.svg") center center no-repeat;
}
.modalTips .titleIcon .warningIcon {
  background: url("/vn/img/central-payment/warning.svg") center center no-repeat;
}
.modalTips .titleIcon .CTCimgIcon {
  width: 150px;
  height: 40px;
  margin: 0 auto;
}
.modalTips .titleIcon .USDT-ERC20-icon {
  background: url("/vn/img/central-payment/USDT-ERC20.svg") center center no-repeat;
}
.modalTips .titleIcon .USDT-TRC20-icon {
  background: url("/vn/img/central-payment/USDT-TRC20.svg") center center no-repeat;
}
.modalTips.USDT-TRC20-modal .customModalBody {
  position: relative;
}
.modalTips.USDT-TRC20-modal .customModalBody::after {
  content: "Miễn Phí";
  position: absolute;
  top: 0;
  right: 0;
  line-height: 18px;
  background-color: #EB2121;
  color: #fff;
  font-size: 10px;
  padding: 0 8px;
  border-radius: 0 0px 0 7px;
  text-align: center;
  font-weight: normal;
}
.CTC-help-Modal .customModalCotent {
  width: 600px;
}
.CTC-help-Modal .customModalCotent .okBtn {
  width: 58%;
  margin: auto;
}
.tutorialModal .customModalCotent {
  width: 600px;
}
.tutorialModal .rc-tabs.usdtWithdrawLesson .rc-tabs-nav-list {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.tutorialModal .rc-tabs.usdtWithdrawLesson .rc-tabs-nav-list .rc-tabs-tab {
  text-align: center;
  line-height: 26px;
  cursor: pointer;
}
.tutorialModal .rc-tabs.usdtWithdrawLesson .rc-tabs-nav-list .rc-tabs-tab.rc-tabs-tab-active {
  border-bottom: 2px solid #00A5FD;
  font-weight: bold;
}
.tutorialModal .rc-tabs.usdtWithdrawLesson .rc-tabs-nav-list .rc-tabs-ink-bar {
  display: none;
}
.tutorialModal .rc-tabs.usdtWithdrawLesson .swiper {
  width: 100%;
  height: 500px;
  overflow: hidden;
  position: relative;
}
.tutorialModal .rc-tabs.usdtWithdrawLesson .swiper .swiper-button-prev {
  width: 32px;
  height: 64px;
  background: url("/vn/img/withdrawlesson/icon-left.svg") center no-repeat;
  background-size: contain;
  position: absolute;
  left: 0;
  top: 130px;
  border-radius: 6px;
}
.tutorialModal .rc-tabs.usdtWithdrawLesson .swiper .swiper-button-prev:hover {
  background-color: rgba(0, 0, 0, 0.3);
  cursor: pointer;
}
.tutorialModal .rc-tabs.usdtWithdrawLesson .swiper .swiper-button-next {
  width: 32px;
  height: 64px;
  background: url("/vn/img/withdrawlesson/icon-right.svg") center no-repeat;
  background-size: contain;
  position: absolute;
  right: 0;
  top: 130px;
  border-radius: 6px;
}
.tutorialModal .rc-tabs.usdtWithdrawLesson .swiper .swiper-button-next:hover {
  background-color: rgba(0, 0, 0, 0.3);
  cursor: pointer;
}
.tutorialModal .rc-tabs.usdtWithdrawLesson .swiper .swiper-pagination {
  width: 100%;
  height: 4px;
  position: absolute;
  top: 350px;
  display: flex;
  justify-content: center;
}
.tutorialModal .rc-tabs.usdtWithdrawLesson .swiper .swiper-pagination .swiper-pagination-bullet {
  width: 4px;
  height: 4px;
  border-radius: 4px;
  background-color: #999;
  margin: 0 4px;
  display: inline-block;
}
.tutorialModal .rc-tabs.usdtWithdrawLesson .swiper .swiper-pagination .swiper-pagination-bullet.swiper-pagination-bullet-active {
  width: 12px;
  background-color: #00A6FF;
}
.tutorialModal .rc-tabs.usdtWithdrawLesson .swiper .swiper-wrapper {
  width: 100%;
  display: flex;
  flex-direction: row;
}
.tutorialModal .rc-tabs.usdtWithdrawLesson .swiper .swiper-wrapper .swiper-slid {
  display: none;
}
.tutorialModal .rc-tabs.usdtWithdrawLesson .swiper .swiper-wrapper .swiper-slide-active {
  display: block;
}
.tutorialModal .rc-tabs.usdtWithdrawLesson .swiper .tutorialCard {
  width: 544px;
}
.tutorialModal .rc-tabs.usdtWithdrawLesson .swiper .tutorialCard .tutorial_img {
  position: relative;
  width: 100%;
  height: 323px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.tutorialModal .rc-tabs.usdtWithdrawLesson .swiper .tutorialCard .tutorial_img.tutorialPic_Withdrawal_1 {
  background-image: url("/vn/img/withdrawlesson/tutorial1.png");
}
.tutorialModal .rc-tabs.usdtWithdrawLesson .swiper .tutorialCard .tutorial_img.tutorialPic_Withdrawal_2 {
  background-image: url("/vn/img/withdrawlesson/tutorial2.png");
}
.tutorialModal .rc-tabs.usdtWithdrawLesson .swiper .tutorialCard .tutorial_img.tutorialPic_Withdrawal_3 {
  background-image: url("/vn/img/withdrawlesson/tutorial3.png");
}
.tutorialModal .rc-tabs.usdtWithdrawLesson .swiper .tutorialCard .tutorial_img.tutorialPic_Withdrawal_4 {
  background-image: url("/vn/img/withdrawlesson/tutorial4.png");
}
.tutorialModal .rc-tabs.usdtWithdrawLesson .swiper .tutorialCard .tutorial_img.tutorialPic_Withdrawal_AddWallet_1 {
  background-image: url("/vn/img/withdrawlesson/add-tutorial1.png");
}
.tutorialModal .rc-tabs.usdtWithdrawLesson .swiper .tutorialCard .tutorial_img.tutorialPic_Withdrawal_AddWallet_2 {
  background-image: url("/vn/img/withdrawlesson/add-tutorial2.png");
}
.tutorialModal .rc-tabs.usdtWithdrawLesson .swiper .tutorialCard .tutorial_img.tutorialPic_Withdrawal_AddWallet_3 {
  background-image: url("/vn/img/withdrawlesson/add-tutorial3.png");
}
.tutorialModal .rc-tabs.usdtWithdrawLesson .swiper .tutorialCard .tutorial_img.tutorialPic_Withdrawal_AddWallet_4 {
  background-image: url("/vn/img/withdrawlesson/add-tutorial4.png");
}
.tutorialModal .rc-tabs.usdtWithdrawLesson .swiper .tutorialCard .tutorial_img.tutorialPic_Withdrawal_AddWallet_5 {
  background-image: url("/vn/img/withdrawlesson/add-tutorial5.png");
}
.tutorialModal .rc-tabs.usdtWithdrawLesson .swiper .tutorialCard h3 {
  text-align: left;
  line-height: 30px;
  padding-top: 35px;
}
.tutorialModal .rc-tabs.usdtWithdrawLesson .swiper .tutorialCard .contentWithTitle {
  text-align: left;
}
.tutorialModal .rc-tabs.usdtWithdrawLesson .swiper .tutorialCard .iKnow {
  width: 334px;
  line-height: 40px;
  background-color: #00A6FF;
  border-radius: 6px;
  margin: 15px auto;
  color: #fff;
  cursor: pointer;
  border: 0;
}
.tutorialModal .rc-tabs.usdtWithdrawLesson .swiper.withdrawCurrentIndex0 .swiper-button-prev,
.tutorialModal .rc-tabs.usdtWithdrawLesson .swiper.addUsdtWalltCurrentIndex0 .swiper-button-prev {
  display: none;
}
.tutorialModal .rc-tabs.usdtWithdrawLesson .swiper.withdrawCurrentIndex3 .swiper-button-next,
.tutorialModal .rc-tabs.usdtWithdrawLesson .swiper.addUsdtWalltCurrentIndex4 .swiper-button-next {
  display: none;
}
.bankIcon-default {
  background: url("/vn/img/bank/generic.png") center center no-repeat;
  width: 20px;
  height: 20px;
  background-size: contain !important;
  display: inline-block;
  margin-right: 5px;
}
.bankIcon-default.bankIcon-ABBANK {
  background: url("/vn/img/bank/ABBANK.png") center center no-repeat;
}
.bankIcon-default.bankIcon-AGRIBANK {
  background: url("/vn/img/bank/AGRIBANK.png") center center no-repeat;
}
.bankIcon-default.bankIcon-ANBINHBANK {
  background: url("/vn/img/bank/ANBINHBANK.png") center center no-repeat;
}
.bankIcon-default.bankIcon-ASIACOMMERCIALBANK {
  background: url("/vn/img/bank/ASIACOMMERCIALBANK.png") center center no-repeat;
}
.bankIcon-default.bankIcon-BACABANK {
  background: url("/vn/img/bank/BACABANK.png") center center no-repeat;
}
.bankIcon-default.bankIcon-BANKFORINVESTMENTANDDEVELOPMENTOFVIETNAM {
  background: url("/vn/img/bank/BANKFORINVESTMENTANDDEVELOPMENTOFVIETNAM.png") center center no-repeat;
}
.bankIcon-default.bankIcon-BAOVIETBANK {
  background: url("/vn/img/bank/BAOVIETBANK.png") center center no-repeat;
}
.bankIcon-default.bankIcon-BIDV {
  background: url("/vn/img/bank/BIDV.png") center center no-repeat;
}
.bankIcon-default.bankIcon-ONTRUCTIONBANKCB {
  background: url("/vn/img/bank/ONTRUCTIONBANKCB.png") center center no-repeat;
}
.bankIcon-default.bankIcon-DAIABANK {
  background: url("/vn/img/bank/DAIABANK.png") center center no-repeat;
}
.bankIcon-default.bankIcon-DONGABANK {
  background: url("/vn/img/bank/DONGABANK.png") center center no-repeat;
}
.bankIcon-default.bankIcon-EXIMBANK {
  background: url("/vn/img/bank/EXIMBANK.png") center center no-repeat;
}
.bankIcon-default.bankIcon-EXIMBANKVIETNAM {
  background: url("/vn/img/bank/EXIMBANKVIETNAM.png") center center no-repeat;
}
.bankIcon-default.bankIcon-GLOBALPETROCOMMERCIALJOINTSTOCKBANK {
  background: url("/vn/img/bank/GLOBALPETROCOMMERCIALJOINTSTOCKBANK.png") center center no-repeat;
}
.bankIcon-default.bankIcon-HDBANK {
  background: url("/vn/img/bank/HDBANK.png") center center no-repeat;
}
.bankIcon-default.bankIcon-KIENLONGBANK {
  background: url("/vn/img/bank/KIENLONGBANK.png") center center no-repeat;
}
.bankIcon-default.bankIcon-LIENVIETPOSTBANK {
  background: url("/vn/img/bank/LIENVIETPOSTBANK.png") center center no-repeat;
}
.bankIcon-default.bankIcon-MARITIMEBANK {
  background: url("/vn/img/bank/MARITIMEBANK.png") center center no-repeat;
}
.bankIcon-default.bankIcon-MB {
  background: url("/vn/img/bank/MB.png") center center no-repeat;
}
.bankIcon-default.bankIcon-NAMABANK {
  background: url("/vn/img/bank/NAMABANK.png") center center no-repeat;
}
.bankIcon-default.bankIcon-NATIONALCITIZENBANKNCB {
  background: url("/vn/img/bank/NATIONALCITIZENBANKNCB.png") center center no-repeat;
}
.bankIcon-default.bankIcon-OCB {
  background: url("/vn/img/bank/OCB.png") center center no-repeat;
}
.bankIcon-default.bankIcon-OCEANBANK {
  background: url("/vn/img/bank/OCEANBANK.png") center center no-repeat;
}
.bankIcon-default.bankIcon-PGBANK {
  background: url("/vn/img/bank/PGBANK.png") center center no-repeat;
}
.bankIcon-default.bankIcon-PVCOMMERCIALBANK {
  background: url("/vn/img/bank/PVCOMMERCIALBANK.png") center center no-repeat;
}
.bankIcon-default.bankIcon-SACOMBANK {
  background: url("/vn/img/bank/SACOMBANK.png") center center no-repeat;
}
.bankIcon-default.bankIcon-SAIGONCOMMERCIALBANK {
  background: url("/vn/img/bank/SAIGONCOMMERCIALBANK.png") center center no-repeat;
}
.bankIcon-default.bankIcon-SAIGONCOMMERCIALBANKSCB {
  background: url("/vn/img/bank/SAIGONCOMMERCIALBANKSCB.png") center center no-repeat;
}
.bankIcon-default.bankIcon-SAIGONHANOICOMMERCIALJOINTSTOCKBANKSHB {
  background: url("/vn/img/bank/SAIGONHANOICOMMERCIALJOINTSTOCKBANKSHB.png") center center no-repeat;
}
.bankIcon-default.bankIcon-SEABANK {
  background: url("/vn/img/bank/SEABANK.png") center center no-repeat;
}
.bankIcon-default.bankIcon-TECHCOMBANK {
  background: url("/vn/img/bank/TECHCOMBANK.png") center center no-repeat;
}
.bankIcon-default.bankIcon-TIENPHONGBANK {
  background: url("/vn/img/bank/TIENPHONGBANK.png") center center no-repeat;
}
.bankIcon-default.bankIcon-TIMOBANK {
  background: url("/vn/img/bank/TIMOBANK.png") center center no-repeat;
}
.bankIcon-default.bankIcon-VIB {
  background: url("/vn/img/bank/VIB.png") center center no-repeat;
}
.bankIcon-default.bankIcon-VIETCAPITALBANK {
  background: url("/vn/img/bank/VIETCAPITALBANK.png") center center no-repeat;
}
.bankIcon-default.bankIcon-VIETCOMBANK {
  background: url("/vn/img/bank/VIETCOMBANK.png") center center no-repeat;
}
.bankIcon-default.bankIcon-VIETINBANK {
  background: url("/vn/img/bank/VIETINBANK.png") center center no-repeat;
}
.bankIcon-default.bankIcon-VIETNAMASIACOMMERCIALJOINTSTOCKBANK {
  background: url("/vn/img/bank/VIETNAMASIACOMMERCIALJOINTSTOCKBANK.png") center center no-repeat;
}
.bankIcon-default.bankIcon-VIETNAMTHUONGTINCOMMERCIALJOINTSTOCKBANK {
  background: url("/vn/img/bank/VIETNAMTHUONGTINCOMMERCIALJOINTSTOCKBANK.png") center center no-repeat;
}
.bankIcon-default.bankIcon-VPBANK {
  background: url("/vn/img/bank/VPBANK.png") center center no-repeat;
}
.USDT-TRC20 {
  background: url("/vn/img/central-payment/USDT-TRC20.svg") center center no-repeat;
  height: 36px;
}
.USDT-ERC20 {
  background: url("/vn/img/central-payment/USDT-ERC20.svg") center center no-repeat;
  height: 36px;
}
.deposit-sprite.LB {
  background-image: url(/vn/img/central-payment/methods/LB.svg);
  background-repeat: no-repeat;
  width: 40px;
  height: 30px;
  background-position: center;
  background-size: contain;
}
.deposit-sprite.CCW {
  background-image: url(/vn/img/central-payment/methods/CTC.svg);
  background-repeat: no-repeat;
  width: 40px;
  height: 30px;
  background-position: center;
  background-size: contain;
}
.form-item {
  margin-bottom: 15px;
}
.form-item > span {
  font-size: 12px;
  margin-bottom: 5px;
  display: block;
}
.form-item input {
  width: 100%;
  height: 45px;
  padding: 13px;
  border-radius: 10px;
  border: 1px solid #cccccc;
}
.form-item input::-moz-placeholder {
  color: #b1b1b1;
}
.form-item input:-ms-input-placeholder {
  color: #b1b1b1;
}
.form-item input::placeholder {
  color: #b1b1b1;
}
.form-item + button {
  margin-top: 30px;
}
.error-input-reference + input {
  border-color: #ec1c23;
}
.error-text {
  display: inline-block;
  width: 100%;
  height: 40px;
  border-radius: 10px;
  padding: 13px;
  background-color: #fee0e0;
  color: #eb2121;
  font-size: 12px;
  margin-top: 5px;
}
p,
ul {
  margin-bottom: 0;
}
.primary-button {
  color: #ffffff;
  width: 100%;
  height: 40px;
  border: 0;
  border-radius: 10px;
  background-color: #00a6ff;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}
.primary-button:hover,
.primary-button:focus {
  background-color: #29bbff;
  border-color: #29bbff;
}
button[disabled].primary-button {
  opacity: 0.5;
  cursor: not-allowed;
  background-color: #ccc;
}
.ghost-button {
  color: #00a6ff;
  width: 100%;
  height: 40px;
  border: 0;
  border-radius: 10px;
  border: 1px solid #00a6ff;
  background-color: transparent;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}
.ghost-button:hover,
.ghost-button:focus {
  color: #29bbff;
  border-color: #29bbff;
}
.rc-select {
  width: 100%;
  background-color: #ffffff;
  border-radius: 10px;
  cursor: pointer;
  border: 1px solid #cccccc;
}
.rc-select .rc-select-selection {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.rc-select .rc-select-selection .rc-select-selection-selected-value {
  height: 45px;
  padding: 14px 15px 12px;
  display: flex !important;
  gap: 13px;
}
.rc-select .rc-select-selection .rc-select-selection-selected-value p {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 330px;
}
.rc-select .rc-select-selection .rc-select-arrow {
  display: block;
  margin-right: 22px;
}
.rc-select .rc-select-selection .rc-select-arrow .rc-select-arrow-icon {
  display: block;
  width: 14px;
  height: 14px;
  background-image: url("/vn/img/central-payment/icon-dropdown.svg");
  transition: transform 0.3s;
}
.rc-select-open .rc-select-arrow-icon {
  transform: rotate(180deg);
}
.rc-select-dropdown {
  width: 100%;
  z-index: 99999;
  max-height: 180px;
  overflow-y: scroll;
  background-color: #ffffff;
  border-radius: 10px;
  color: #333333;
}
.rc-select-dropdown .rc-select-dropdown-menu-item {
  overflow: hidden;
  cursor: pointer;
  border-top: 1px solid #f2f2f2;
  line-height: 32px;
  padding: 5px 0px 5px 10px;
  display: flex;
  align-items: center;
  gap: 13px;
}
.rc-select-dropdown .rc-select-dropdown-menu-item:last-child {
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}
.rc-select-dropdown .rc-select-dropdown-menu-item:hover,
.rc-select-dropdown .rc-select-dropdown-menu-item:focus {
  background-color: #f5f5f5;
}
.rc-select-dropdown .rc-select-dropdown-menu-item > :last-child {
  max-width: 350px;
  word-break: break-all;
}
.rc-select-dropdown .rc-select-dropdown-menu-item.rc-select-dropdown-menu-item-selected {
  position: relative;
}
.rc-select-dropdown .rc-select-dropdown-menu-item.rc-select-dropdown-menu-item-selected::after {
  content: "";
  position: absolute;
  background-image: url("/vn/img/central-payment/miniTick.svg");
  background-position: center center;
  background-repeat: no-repeat;
  width: 14px;
  height: 14px;
  right: 15px;
}
.rc-select-dropdown .rc-select-dropdown-menu-item.light-blue {
  justify-content: space-between;
}
.rc-select-dropdown .rc-select-dropdown-menu-item.light-blue .addIcon {
  width: 14px;
  height: 14px;
  margin-right: 15px;
  background-image: url("/vn/img/central-payment/icon-add.svg");
  background-repeat: no-repeat;
}
.rc-select-dropdown.rc-select-dropdown-hidden {
  display: none;
}
.light-blue {
  color: #1c8eff;
}
.CCW-container {
  color: #222222;
  margin-bottom: 64px;
}
.CCW-container .ctc-card-container {
  display: flex;
  gap: 10px;
}
.CCW-container .ctc-card-container .ctc-card {
  width: 49%;
  padding: 20px 0;
  text-align: center;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0px 2px 4px #ebebeb;
  font-size: 14px;
  cursor: pointer;
  font-weight: 700;
}
.CCW-container .ctc-card-container .ctc-card .name {
  margin-top: 6px;
}
.CCW-container .ctc-card-container .ctc-card.active {
  border: 2px solid #00a6ff;
}
.CCW-container span[for="exchange_other"] + div input {
  background-color: #e8e8eb;
}
.CCW-container .exchageInfo {
  font-size: 12px;
  color: #666666;
  background-color: #e0e0e0;
  border-radius: 10px;
  margin-top: 13px;
  padding: 13px;
}
.CCW-container .exchageInfo ol {
  padding-left: 25px;
}
.CCW-container .exchageInfo ol li {
  list-style: disc;
}
.CCW-container .exchageInfo ol li:last-child {
  margin-left: -25px;
  list-style: none;
}
.CCW-container .exchageInfo .showCalculation {
  cursor: pointer;
  color: #00a6ff;
  text-decoration: underline;
}
.CCW-container .addCTCAddress {
  background-color: transparent;
  color: #00a6ff;
  text-align: center;
  border: 1px solid #00a6ff;
}
.CCW-container .tutorialButton {
  text-align: right;
  margin-bottom: 16px;
  display: block;
  color: #1C8EFF;
  border: none;
  line-height: 46px;
  font-weight: bold;
  cursor: pointer;
  position: absolute;
  right: 25px;
  top: 0;
  height: 46px;
}
.CCW-container .ctcmethod {
  font-weight: 700;
}
.exchangeCalculationDiscription {
  text-align: start;
  font-size: 12px;
}
.exchangeCalculationDiscription .withdrawAmount {
  display: flex;
  justify-content: space-between;
  border-radius: 10px;
  background-color: #f5f5f5;
  padding: 13px 15px;
  margin-bottom: 10px;
}
.exchangeCalculationDiscription .withdrawAmount label {
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
}
.exchangeCalculationDiscription .withdrawAmount p {
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
  /* 128.571% */
}
.exchangeCalculationDiscription .exchangeDetail {
  padding: 16px 15px 12px;
  border-radius: 10px;
  background-color: #f5f5f5;
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 21px;
}
.exchangeCalculationDiscription .exchangeDetail .detailItem label {
  font-weight: 400;
  line-height: 16px;
  margin-bottom: 5px;
}
.exchangeCalculationDiscription .exchangeDetail .detailItem p {
  transform: scale(0.82);
  transform-origin: 0 0;
  color: #999999;
  font-weight: 400;
  line-height: 16px;
}
.exchangeCalculationDiscription .exchangeByLevel {
  display: flex;
  flex-direction: column;
  gap: 13px;
  margin-bottom: 20px;
}
.exchangeCalculationDiscription .exchangeByLevel label {
  line-height: 16px;
}
.exchangeCalculationDiscription .exchangeByLevel p {
  color: #00a6ff;
}
.exchangeCalculationDiscription .yellowNote {
  background-color: #fff5bf;
  color: #83630b;
  padding: 4px 15px;
  border-radius: 10px;
  margin-bottom: 24px;
  line-height: 16px;
}
.LB-container {
  color: #222222;
}
.LB-container .addBankcard {
  background-color: transparent;
  color: #00a6ff;
  text-align: center;
  border: 1px solid #00a6ff;
  height: 45px;
  border-radius: 10px;
  padding: 13px;
  cursor: pointer;
}
.LB-container .defaultBox {
  display: flex;
  gap: 8px;
  width: -moz-fit-content;
  width: fit-content;
}
.LB-container .defaultBox .checkIcon {
  width: 18px;
  height: 18px;
  background-image: none;
  border: 1px solid #cccccc;
  border-radius: 5px;
}
.LB-container .defaultBox .checkIcon.checked {
  border-color: #00a6ff;
  background-image: url("/vn/img/central-payment/square-check.svg");
  background-size: contain;
}
.LB-container .defaultBox p {
  font-size: 12px;
  font-weight: 300;
}
.LB-container .rc-select .rc-select-selection-selected-value {
  align-items: center;
}
.wallet-content-wrap .money .vnMoneyTip {
  margin-top: 8px;
  padding: 8px;
  border-radius: 8px;
  background-color: #e0e0e0;
  color: #666;
}
.withdrawalSuccessModal + .spaceBox {
  min-height: 600px;
}
.withdrawalSuccessModal {
  position: absolute;
  top: 0;
  width: calc(100% - 60px);
  padding-top: 30px;
  min-height: 600px;
}
.withdrawalSuccessModal .successContent {
  background-color: #fff;
  padding-top: 30px;
  margin-bottom: 21px;
  font-size: 12px;
  margin-top: 20px;
}
.withdrawalSuccessModal .successContent .headerText {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 17px;
  margin-bottom: 43px;
}
.withdrawalSuccessModal .successContent .headerText .successIcon {
  background: url("/vn/img/central-payment/successIcon.svg");
  background-size: cover;
  width: 60px;
  height: 60px;
}
.withdrawalSuccessModal .successContent .headerText p {
  color: #000;
  text-align: center;
  font-size: 18px;
  font-weight: 700;
  line-height: normal;
}
.withdrawalSuccessModal .successContent .transactionDetail .amount {
  height: 48px;
  padding: 0 15px;
  border-top: 1px solid #f3f3f3;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.withdrawalSuccessModal .successContent .transactionDetail .amount label {
  font-weight: 600;
}
.withdrawalSuccessModal .successContent .transactionDetail .amount p {
  font-weight: 600;
  font-size: 16px;
}
.withdrawalSuccessModal .successContent .transactionDetail .transactionID {
  height: 48px;
  padding: 0 15px;
  border-top: 1px solid #f3f3f3;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.withdrawalSuccessModal .successContent .transactionDetail .transactionID label {
  font-weight: 600;
}
.withdrawalSuccessModal .successContent .transactionDetail .transactionID .rightBox {
  display: flex;
  gap: 9px;
}
.withdrawalSuccessModal .successContent .transactionDetail .transactionID .rightBox .copyIcon {
  cursor: pointer;
  background: url("/vn/img/central-payment/copyIcon.svg") center center no-repeat;
  width: 14px;
  height: 14px;
}
.withdrawalSuccessModal .successContent .transactionDetail .transactionID .rightBox .copySuccessIcon {
  background-image: url("/vn/img/central-payment/checkIcon.svg");
  background-repeat: no-repeat;
  width: 20px;
  height: 20px;
  display: inline-block;
  margin-left: 3px;
}
.withdrawalSuccessModal .successContent .status {
  padding-left: 36px;
  margin-bottom: 20px;
}
.withdrawalSuccessModal .successContent .status .statusItem {
  display: flex;
  flex-direction: column;
  gap: 7px;
  height: 58px;
  color: #999999;
}
.withdrawalSuccessModal .successContent .status .statusItem label {
  font-size: 12px;
  font-weight: 400;
  line-height: normal;
  position: relative;
}
.withdrawalSuccessModal .successContent .status .statusItem label::before {
  display: block;
  content: "";
  width: 12px;
  height: 12px;
  background-size: contain;
  position: absolute;
  top: 50%;
  left: -20px;
  transform: translateY(-50%);
}
.withdrawalSuccessModal .successContent .status .statusItem label::after {
  content: "";
  display: block;
  width: 2px;
  height: 46px;
  background-color: #cccccc;
  position: absolute;
  left: -15px;
  top: 16px;
}
.withdrawalSuccessModal .successContent .status .statusItem label.completed {
  color: #00a6ff;
  font-size: 14px;
}
.withdrawalSuccessModal .successContent .status .statusItem label.completed::before {
  background-image: url("/vn/img/central-payment/checkIcon.svg");
  background-repeat: no-repeat;
}
.withdrawalSuccessModal .successContent .status .statusItem label.processing::before {
  border: 2px solid #cccccc;
  border-radius: 50%;
  width: 12px;
  height: 12px;
}
.withdrawalSuccessModal .successContent .status .statusItem:last-child {
  height: -moz-fit-content;
  height: fit-content;
}
.withdrawalSuccessModal .successContent .status .statusItem:last-child label::after {
  display: none;
  background-color: #83630b !important;
}
.withdrawalSuccessModal .remark {
  color: #999;
  text-align: left;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  margin-bottom: 29px;
}
.withdrawalSuccessModal .buttonBox {
  display: flex;
  flex-direction: column;
  gap: 21px;
}
.withdrawalSuccessModal .buttonBox button {
  text-align: center;
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
}
.usdt-exchange-hint .title {
  font-weight: bold;
}
.usdt-exchange-hint .content {
  text-align: left;
  color: #222;
}
.usdt-exchange-hint .content .desc {
  margin-bottom: 1em;
}
.usdt-exchange-hint .content .check-wrap {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.usdt-exchange-hint .content .check-wrap .check {
  width: 18px;
  height: 18px;
  border-radius: 4px;
  border: 2px solid #CCC;
  background: #FFF;
  cursor: pointer;
}
.usdt-exchange-hint .content .check-wrap .checked {
  width: 18px;
  height: 18px;
  background-image: url("/vn/img/central-payment/square-check.svg");
  background-position: 0 center;
  background-repeat: no-repeat;
  background-size: contain;
  cursor: pointer;
}
.usdt-exchange-hint .content .check-wrap h4 {
  margin: 0 10px ;
}
.loadingModal {
  width: 100vw;
  height: 100vh;
  position: fixed;
  z-index: 10000;
  display: flex;
  top: 0;
  left: 0;
  background-color: #00000011;
}
.loadingModal .loading {
  margin: auto;
  vertical-align: middle;
  width: 250px;
  background-color: #000000cc;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 25px;
}
.loadingModal .loading p {
  font-size: 16px;
  color: #fff;
  text-align: center;
}
.confirm-modal-of-public .ant-modal-content .ant-modal-body .ant-modal-confirm-body-wrapper .ant-modal-confirm-body .ant-modal-confirm-content {
  padding: 0px 40px;
  margin: 20px 0px;
}
.confirm-modal-of-public .ant-modal-content .ant-modal-body .ant-modal-confirm-body-wrapper .ant-modal-confirm-body .ant-modal-confirm-content > div {
  text-align: left !important;
}
.confirm-modal-of-public .ant-modal-content .ant-modal-body .ant-modal-confirm-body-wrapper .ant-modal-confirm-body .ant-modal-confirm-content > div > div .pendingWithdrawBox {
  background-color: #f5f5f5;
  padding: 15px;
  border-radius: 10px;
  margin: 20px 0px;
}
@media (max-width: 768px) {
  .searchContainer .textInputBox {
    display: none;
  }
}
@media (min-width: 768px) {
  div.CustomerService {
    background-color: #F6F6F6;
  }
  div.CustomerService .maintain-header-wrap {
    width: 100%;
    height: 60px;
    background-color: #00A6FF;
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 999;
  }
  div.CustomerService .maintain-header-wrap .common-distance {
    width: 1200px;
    padding: 0 40px;
    margin: 0 auto;
    position: relative;
  }
  div.CustomerService .maintain-header-wrap .common-distance .logo-wrap {
    display: flex;
    align-items: center;
    cursor: pointer;
  }
  div.CustomerService .maintain-header-wrap .common-distance .logo-wrap > div {
    display: flex;
    align-items: center;
  }
  div.CustomerService .maintain-header-wrap .common-distance .logo-wrap .line {
    color: #fff;
    font-size: 20;
    font-weight: 600;
    margin: 0 12px;
  }
  div.CustomerService .maintain-header-wrap .common-distance .logo-wrap span.pageTitle {
    color: #fff;
    font-size: 20;
    font-weight: 600;
  }
  #CustomerService {
    background-color: #F6F6F6;
    padding: 80px 0 0;
    margin: 0 auto;
  }
  #CustomerService .liveChatContainer {
    left: auto;
    left: initial;
    right: 40px;
    bottom: 40px;
  }
  #CustomerService .headerBox {
    display: none;
  }
  #CustomerService .detailNavBox {
    display: none;
  }
  #CustomerService .questionContentBox {
    margin-bottom: 0;
  }
  #CustomerService .questionContentBox .contentTitle {
    display: none;
  }
  #CustomerService .helpContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #E6F6FF;
    border: 2px solid #00A6FF;
    margin-bottom: 0px;
  }
  #CustomerService .helpContainer .helpTipText1 {
    text-align: left;
  }
  #CustomerService .helpContainer .helpTipText2 {
    text-align: left;
  }
  #CustomerService .helpContainer .helpTipBtn {
    width: auto;
    padding: 0 10px;
  }
  #CustomerService .questionTopContainer {
    padding-left: 0;
    padding-right: 0;
  }
  #CustomerService .questionTopContainer .searchBgBox .searchInput {
    display: none;
  }
  #CustomerService .questionTopContainer .hotListBox {
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  #CustomerService .questionTopContainer .hotListBox .hotListTitle {
    margin-bottom: 0;
    margin-right: 10px;
  }
  #CustomerService .questionTopContainer .hotListBox .hotListWrap {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  #CustomerService .questionTopContainer .hotListBox .hotListWrap.hotListWrapspecical {
    flex-wrap: wrap;
    justify-content: flex-start;
    width: 75%;
  }
  #CustomerService .questionTopContainer .hotListBox .hotListWrap .hotList {
    padding-top: 0;
    padding-bottom: 0;
    margin: 0 8px;
    cursor: pointer;
    text-align: center;
  }
  #CustomerService .questionTopContainer .hotListBox .hotListWrap .hotList .hotListText {
    text-align: center;
  }
  #CustomerService .question-wrap {
    display: flex;
    justify-content: space-between;
  }
  #CustomerService .question-wrap .questionBottomContainer {
    width: 200px;
    margin-right: 20px;
    margin-bottom: 0;
  }
  #CustomerService .question-wrap .questionBottomContainer .knowledgeCategoriesBox {
    padding: 15px 0;
  }
  #CustomerService .question-wrap .questionBottomContainer .knowledgeCategoriesBox .knowledgeCategoriesList {
    border-left: 6px solid transparent;
    transition: all 0.3s;
    border-bottom-width: 0;
    cursor: pointer;
  }
  #CustomerService .question-wrap .questionBottomContainer .knowledgeCategoriesBox .knowledgeCategoriesList.knowledgeCategoriesListActive {
    border-left-color: #00A6FF;
    background-color: #F0F0F2;
  }
  #CustomerService .question-wrap .questionBottomContainer .knowledgeCategoriesBox .knowledgeCategoriesList .arrowIcon {
    display: none;
  }
  #CustomerService .question-wrap .questionDetailContainer {
    min-height: 500px;
    background-color: #fff;
    border-radius: 8px;
    padding: 10px 30px;
    width: 900px;
  }
  #CustomerService .question-wrap .questionDetailContainer .knowledgeCategoriesBox {
    margin-bottom: 0px;
  }
  #CustomerService .question-wrap .questionDetailContainer .knowledgeCategoriesListBox {
    border-bottom: 1px solid #e3e3e3;
  }
  #CustomerService .question-wrap .questionDetailContainer .knowledgeCategoriesListBox .questionContentBox .contentWrap {
    margin-bottom: 0;
    padding-bottom: 15px;
  }
  #CustomerService .question-wrap .questionDetailContainer .knowledgeCategoriesList {
    border-bottom-width: 0px;
    padding: 15px 0 !important;
    margin-bottom: 0;
  }
  #CustomerService .question-wrap .questionDetailContainer .knowledgeCategoriesList .knowledgeCategoriesText {
    color: #222;
    font-weight: 500;
    font-size: 16px;
  }
  #CustomerService .question-wrap .questionDetailContainer .knowledgeCategoriesList .arrowIcon {
    margin-top: -2px;
  }
  #CustomerService .question-wrap .questionDetailContainer .helpContainer {
    display: none;
  }
  #CustomerService .tabBox {
    background-color: transparent;
    margin-bottom: 40px;
    margin-top: 20px;
    border-bottom: 2px solid #EFEFF4;
    justify-content: flex-start;
  }
  #CustomerService .tabBox .headerBoxList {
    width: auto;
    padding: 0 15px;
    border-bottom: 6px solid transparent;
    cursor: pointer;
    color: #BCBEC3;
    font-size: 18px;
  }
  #CustomerService .tabBox .headerBoxList.headerBoxListActive {
    color: #00A6FF;
    border-bottom-color: #00A6FF;
  }
  #CustomerService .tabContainers {
    position: relative;
  }
  #CustomerService .tabContainers .searchShow {
    opacity: 1;
    width: auto;
    height: auto;
    z-index: auto;
    z-index: initial;
  }
  #CustomerService .tabContainers .searchShow .serachContentWrap {
    margin-bottom: 0;
  }
  #CustomerService .tabContainers .searchShow .noMessageBox {
    padding: 200px 0;
  }
  #CustomerService .tabContainers .searchShow .noMessageBox .searchLength {
    text-align: center !important;
  }
  #CustomerService .tabContainers .searchHide {
    opacity: 0;
    width: 0;
    height: 0;
    z-index: -99999;
  }
  #CustomerService .tabContainers .questionShow {
    opacity: 1;
    width: auto;
    height: auto;
    z-index: auto;
    z-index: initial;
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
  #CustomerService .tabContainers .questionhHide {
    opacity: 0;
    width: 0;
    height: 0;
    z-index: -99999;
  }
  #CustomerService .tabContainers .searchContainer {
    width: 100%;
    padding-top: 0px;
    height: auto;
  }
  #CustomerService .tabContainers .searchContainer .serachInputWrap {
    position: absolute;
    top: -225px;
    left: 26%;
    right: 26%;
    display: flex;
    padding: 0;
    justify-content: center;
  }
  #CustomerService .tabContainers .searchContainer .serachInputWrap .textInput {
    padding-left: 20px;
  }
  #CustomerService .tabContainers .searchContainer .serachInputWrap .searchInputIcon {
    right: 10px;
    left: auto;
    left: initial;
  }
  #CustomerService .tabContainers .searchContainer .serachInputWrap .searchResultTitleListBox {
    position: absolute;
    left: 0;
    right: 0;
    border-radius: 8px;
    margin-top: 2px;
    top: 44px;
    box-shadow: -2px 0 8px rgba(0, 0, 0, 0.15);
    margin-bottom: 0;
  }
  #CustomerService .tabContainers .searchContainer .searchShow .searchLength {
    text-align: left;
    padding: 0;
  }
  #CustomerService div.paginationBox {
    justify-content: flex-start;
  }
  #CustomerService .searchContainer .searchResultBox {
    padding: 30px;
  }
  #CustomerService .noDataBox {
    padding: 200px 0;
  }
  #CustomerService .noDataBox .mobileFeedBox {
    display: none;
  }
  #CustomerService .recordContainer .recordListBoxMobile {
    display: none;
  }
  #CustomerService .recordContainer .calendarWrap {
    display: none;
  }
  #CustomerService .recordContainer .recordListBoxWeb {
    background-color: #fff;
    border-radius: 6px;
    margin-bottom: 8px;
    border: 1px solid #EAEAEA;
    margin: 10px 0;
  }
  #CustomerService .recordContainer .recordListBoxWeb .recordTextTime {
    color: #bcbec3 !important;
  }
  #CustomerService .recordContainer .recordListBoxWeb .recordTextCom {
    color: #222 !important;
  }
  #CustomerService .recordContainer .recordListBoxWeb .recordList {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 8px 15px;
    border-bottom: 1px solid #EAEAEA;
  }
  #CustomerService .recordContainer .recordListBoxWeb .recordList1 {
    display: flex;
  }
  #CustomerService .recordContainer .recordListBoxWeb .recordList1 .recordList2 {
    justify-content: space-between;
    width: 22%;
    padding: 8px 15px;
  }
  #CustomerService .recordContainer .recordListBoxWeb .recordList1 .recordList2 .recordList3 {
    display: flex;
  }
  #CustomerService .recordContainer .recordListBoxWeb .recordList1 .recordList4 {
    padding: 12px 15px;
    padding-right: 60px;
    display: flex;
    justify-content: center;
    /* align-items: center; */
    width: 64%;
    border-right: 1px solid #EAEAEA;
    border-left: 1px solid #EAEAEA;
    position: relative;
    flex-direction: column;
  }
  #CustomerService .recordContainer .recordListBoxWeb .recordList1 .recordList4 .rightArrow {
    position: absolute;
    right: 20px;
  }
  #CustomerService .recordContainer .recordListBoxWeb .recordList1 .recordList4 .tipBox .tipBoxText1 {
    margin-bottom: 0;
  }
  #CustomerService .recordContainer .recordListBoxWeb .recordList1 .recordList4 .recordTextCom {
    margin-bottom: 8px;
  }
  #CustomerService .recordContainer .recordListBoxWeb .recordList1 .csBtn {
    width: 14%;
  }
  #CustomerService .recordContainer .recordListBoxWeb .recordText2 {
    color: #BCBEC3;
    font-size: 10px;
    font-weight: 400;
  }
  #CustomerService .recordContainer .recordListBoxWeb .recordText3 {
    color: #666;
    font-size: 12px;
    font-weight: 400;
  }
  #CustomerService .recordContainer .recordListBoxWeb .eventNameBox {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
  .feedbackModalContainer .submission {
    display: none;
  }
}
.CustomerService {
  background-color: #EFEFF4;
  height: 100%;
  overflow: hidden;
}
.CustomerService .hide {
  pointer-events: none;
  visibility: hidden;
}
.CustomerService #CustomerService.Mobile {
  display: flex;
  flex-direction: column;
  height: 100vh;
  /* 设置容器占满视口高度 */
}
.CustomerService #CustomerService.Mobile .scroll {
  overflow-y: scroll;
  flex: 1 1;
  /* 自动填满剩余空间 */
  margin: 10px 10px 0;
}
.CustomerService #CustomerService.Mobile .scroll.QuestionDetail {
  margin-top: 0;
}
.CustomerService .arrowIcon {
  margin-left: 8px;
  width: 10px;
  height: 10px;
  border-width: 2px;
  border-top-color: transparent;
  border-right-color: transparent;
  border-bottom-color: #666;
  border-left-color: #666;
  transform: rotate(-135deg);
  border-style: solid;
  transition: all 0.2s;
  cursor: pointer;
}
.CustomerService .detailNavBox {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 10px;
}
.CustomerService .detailNavBox .detailNav {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: 4px;
}
.CustomerService .detailNavBox .detailNav .detailNavText1 {
  color: #666;
  font-size: 12px;
  font-weight: 400;
  text-decoration: underline;
}
.CustomerService .detailNavBox .detailNav .detailNavText2 {
  color: #222;
  font-size: 12px;
  font-weight: 400;
}
.CustomerService .detailNavBox .detailNav .rightArrow1 {
  margin-left: 5px;
  margin-right: 10px;
  border-bottom-color: #000;
  border-left-color: #000;
  border-width: 1px;
  transform: rotate(-135deg);
}
.CustomerService .questionContentBox {
  height: 100%;
  transition: all 0.35s;
  line-height: normal;
}
.CustomerService .questionContentBox .contentTitle {
  font-size: 16px;
  font-weight: 600;
  color: #222;
  margin-top: 20px;
  margin-bottom: 15px;
  transition: all 0.35s;
}
.CustomerService .questionContentBox .contentWrap {
  color: #222;
  font-size: 14px;
  font-weight: 400;
  transition: all 1s ease-in-out;
  word-break: break-word;
  white-space: normal;
  /* 默认情况下允许自动换行 */
  overflow-wrap: break-word;
  /* 处理长单词换行 */
  margin-bottom: 150px;
}
.CustomerService .questionContentBox .contentWrap img {
  width: 100%;
}
.CustomerService .paginationBox {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-bottom: 80px;
  flex-wrap: wrap;
}
.CustomerService .paginationBox .paginationBtn {
  width: 24px;
  height: 24px;
  border-radius: 4px;
  border-width: 1px;
  border-color: #E3E3E3;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 4px;
  margin-bottom: 4px;
  border-style: solid;
  cursor: pointer;
}
.CustomerService .paginationBox .paginationBtnText {
  color: #222;
  font-weight: 400;
  font-size: 12px;
}
.CustomerService .paginationBox .rightArrow2 {
  border-bottom-color: #222;
  border-left-color: #222;
  border-width: 1px;
  transform: rotate(45deg);
}
.CustomerService .paginationBox .rightArrow3 {
  border-bottom-color: #222;
  border-left-color: #222;
  border-width: 1px;
  transform: rotate(-135deg);
  margin-left: -4px;
}
.CustomerService .noDataBox {
  display: flex;
  flex-direction: column;
  /* 垂直布局 */
  justify-content: center;
  /* 水平居中 */
  align-items: center;
  padding: 100px 0px;
}
.CustomerService .noDataBox .noDataImg {
  width: 80px;
  height: 80px;
  margin-bottom: 10px;
}
.CustomerService .noDataBox .noDataText {
  color: #BCBEC3;
  font-size: 16px;
  font-weight: 600;
}
.CustomerService .noDataBox .noDataText1 {
  color: #000;
  font-size: 14px;
  font-weight: 400;
  margin-top: 20px;
  margin-bottom: 10px;
  text-align: center;
  padding: 0 50px;
}
.CustomerService .noDataBox .mobileFeedBox {
  display: flex;
  flex-direction: column;
  /* 垂直布局 */
  justify-content: center;
  /* 水平居中 */
  align-items: center;
}
.CustomerService .noDataBox .noDataBtnText {
  color: #fff;
  font-size: 16px;
  font-weight: 400;
}
.CustomerService .noDataBox .noDataBtn {
  background-color: #00A6FF;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  padding: 0 80px;
  cursor: pointer;
}
.CustomerService .headerContainer .headerBox1 {
  height: 30px;
}
.CustomerService .headerContainer .searchHeaderBox {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  transition: 0.25s all ease-in-out;
}
.CustomerService .headerContainer .searchHeaderBox .arrowIcon {
  transform: rotate(45deg);
  margin-right: 10px;
  width: 14px;
  height: 14px;
}
.CustomerService .headerContainer .headerBox {
  display: flex;
  flex-direction: row;
  background-color: #00A6FF;
  align-items: center;
  justify-content: space-between;
  height: 50px;
  padding-left: 20px;
  padding-right: 20px;
}
.CustomerService .headerContainer .headerBox .headerTitle {
  color: #FFFFFF;
  font-size: 16px;
  font-weight: 500;
}
.CustomerService .headerContainer .headerBox .leftArrow {
  width: 14px;
  height: 14px;
  border-bottom-color: #fff;
  border-left-color: #fff;
  transform: rotate(45deg);
  margin-left: 0px;
}
.CustomerService .headerContainer .headerBox .leftArrow1 {
  opacity: 0;
}
.CustomerService .tabBox {
  display: flex;
  flex-direction: row;
  background-color: #00A6FF;
  align-items: center;
  justify-content: space-between;
}
.CustomerService .tabBox .headerBoxList {
  height: 40px;
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 3px solid #00A6FF;
  font-size: 14px;
  font-weight: 400;
  color: #E6F6FF;
  transition: all 0.2s;
}
.CustomerService .tabBox .headerBoxList.headerBoxListActive {
  border-bottom-color: #fff;
  color: #fff;
}
.CustomerService .tabBox .headerText {
  font-size: 14px;
  font-weight: 400;
}
.CustomerService .questionTopContainer .searchBox {
  border-radius: 10px;
  overflow: hidden;
  background-color: #fff;
  margin-bottom: 10px;
}
.CustomerService .questionTopContainer .searchBox .searchBg {
  width: 100%;
}
.CustomerService .questionTopContainer .searchBox .searchBgBox {
  position: relative;
}
.CustomerService .questionTopContainer .searchBox .searchBgBox .searchInput {
  margin-left: 15px;
  margin-right: 15px;
  border-style: solid;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 15px;
}
.CustomerService .questionTopContainer .hotListBox {
  padding-left: 12px;
  padding-right: 12px;
  padding-top: 16px;
  padding-bottom: 16px;
}
.CustomerService .questionTopContainer .hotListBox .hotListTitle {
  color: #00A6FF;
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 5px;
}
.CustomerService .questionTopContainer .hotListBox .hotList {
  padding-top: 6px;
  padding-bottom: 6px;
}
.CustomerService .questionTopContainer .hotListBox .hotListText {
  color: #000;
  font-size: 14px;
  font-weight: 400;
  text-decoration: underline;
}
.CustomerService .knowledgeCategoriesBox {
  border-radius: 8px;
  background-color: #fff;
  margin-bottom: 160px;
}
.CustomerService .knowledgeCategoriesBox .knowledgeCategoriesList {
  padding: 15px 12px;
  border-bottom-width: 1px;
  border-bottom-color: #EAEAEA;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-bottom-style: solid;
  cursor: pointer;
}
.CustomerService .knowledgeCategoriesBox .knowledgeCategoriesList .knowledgeCategoriesText {
  color: #666666;
  font-size: 16px;
  font-weight: 400;
  width: 90%;
  flex-wrap: wrap;
}
.CustomerService .knowledgeCategoriesBox .knowledgeCategoriesList .rightArrow {
  width: 22px;
  height: 22px;
}
.CustomerService .questionDetailContainer {
  flex: 1 1;
  width: 100%;
}
.CustomerService .questionDetailContainer .knowledgeCategoriesBox {
  background-color: transparent;
}
.CustomerService .questionDetailContainer .knowledgeCategoriesBox .knowledgeCategoriesList {
  margin-bottom: 5px;
  border-radius: 8px;
  background-color: #fff;
  border-bottom-width: 0;
}
.CustomerService .searchContainer {
  display: flex;
  flex-direction: column;
  height: 100vh;
}
.CustomerService .searchContainer .serachInputWrap {
  margin-top: 10px;
  padding: 0 10px;
  margin-bottom: 10px;
}
.CustomerService .searchContainer .scroll {
  margin-top: 0 !important;
}
.CustomerService .searchContainer .serachContentWrap {
  margin-bottom: 170px;
  line-height: normal;
}
.CustomerService .searchContainer .searchLength {
  color: #222;
  font-size: 18px;
  font-weight: 600;
  padding: 0 10px;
  width: 100%;
  word-break: break-word;
  margin-top: 20px;
}
.CustomerService .searchContainer .searchResultBox {
  background-color: #FFF;
  border-radius: 8px;
  padding: 15px;
  margin-top: 15px;
  margin-bottom: 15px;
}
.CustomerService .searchContainer .searchResultList {
  border-bottom-width: 1px;
  border-bottom-color: #EFEFF4;
  margin-bottom: 15px;
  border-bottom-style: solid;
  cursor: pointer;
}
.CustomerService .searchContainer .searchResultListTitle {
  color: #222;
  font-size: 14px;
  font-weight: 600;
}
.CustomerService .searchContainer .searchResultListText {
  color: #666;
  font-size: 12px;
  font-weight: 400;
  margin-top: 10px;
  margin-bottom: 10px;
  word-wrap: break-word;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  /* 限制显示三行 */
  overflow: hidden;
  text-overflow: ellipsis;
  line-clamp: 3;
  /* 对非Webkit内核的支持 */
}
.CustomerService .searchContainer .noMessageBox {
  padding-top: 60%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.CustomerService .searchContainer .noMessageBox .searchLength {
  text-align: center;
  padding: 0 25px;
  line-height: 26px;
}
.CustomerService .searchContainer .searchResultTitleListBox {
  background-color: #fff;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 4px;
  margin-bottom: 180px;
}
.CustomerService .searchContainer .searchResultTitleList {
  height: 40px;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-bottom-width: 1px;
  border-bottom-color: #EFEFF4;
  border-bottom-style: solid;
  cursor: pointer;
}
.CustomerService .searchContainer .searchResultTitleListText {
  color: #222;
  font-size: 14px;
  font-weight: 600;
}
.CustomerService .helpContainer {
  border-radius: 8px;
  overflow: hidden;
  background-color: #fff;
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 15px;
  padding-bottom: 15px;
  margin-bottom: 10px;
}
.CustomerService .helpContainer .helpTipText1 {
  color: #00A6FF;
  font-size: 20px;
  font-weight: 600;
  text-align: center;
}
.CustomerService .helpContainer .helpTipText2 {
  color: #666;
  font-size: 14px;
  font-weight: 400;
  text-align: center;
  padding-top: 8px;
  padding-bottom: 8px;
}
.CustomerService .helpContainer .helpTipBtn {
  background-color: #00A6FF;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  width: 100%;
  height: 44px;
  cursor: pointer;
}
.CustomerService .helpContainer .helpTipBtnText {
  color: #fff;
  font-size: 16px;
  font-weight: 400;
}
.CustomerService .liveChatContainer {
  position: fixed;
  left: 16px;
  bottom: 10px;
  cursor: pointer;
  z-index: 99;
}
.CustomerService .liveChatContainer .contact {
  width: 70px;
  height: 70px;
}
.CustomerService .textInputBox {
  background-color: #fff;
  border-width: 1px;
  border-color: #E3E3E3;
  border-radius: 8px;
  overflow: hidden;
  flex-direction: row;
  align-items: center;
  height: 44px;
  border-style: solid;
  position: relative;
  display: flex;
  flex: 1 1;
}
.CustomerService .textInputBox .textInput {
  padding-left: 42px;
  height: 100%;
  width: 100%;
  color: #222222;
  border: none;
  outline: none;
  box-sizing: border-box;
  font-size: 14px;
  padding-right: 20px;
  background-color: #fff;
}
.CustomerService .textInputBox .searchInputIcon {
  width: 24px;
  height: 24px;
  position: absolute;
  left: 10px;
  cursor: pointer;
}
.CustomerService .recordContainer {
  flex: 1 1;
}
.CustomerService .recordContainer .timeSelect {
  display: flex;
  /* 等效于 flexDirection: 'row' */
  flex-direction: row;
  align-items: center;
  padding-top: 5px;
  /* 等效于 paddingVertical: 5 */
  padding-bottom: 5px;
  padding-left: 12px;
  /* 等效于 paddingHorizontal: 12 */
  padding-right: 12px;
  background-color: #fff;
  border-radius: 9999px;
}
.CustomerService .recordContainer .timeSelect span {
  color: #666;
  font-size: 12px;
  font-weight: 400;
}
.CustomerService .recordContainer .timeSelect .ToIcon {
  margin: 0 6px;
  width: 16px;
  height: 16px;
}
.CustomerService .recordContainer .timeSelect .TimeIcon {
  margin-left: 6px;
  width: 16px;
  height: 16px;
}
.CustomerService .recordContainer .calendarBox {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}
.CustomerService .recordContainer .calendar {
  width: 28px;
  height: 28px;
}
.CustomerService .recordContainer .recordListBox {
  line-height: normal;
}
.CustomerService .recordContainer .recordListBox .recordTextStatus {
  font-size: 12px;
  font-weight: 400;
  text-align: right;
}
.CustomerService .recordContainer .recordListBox .recordTextTime {
  color: #999;
  font-size: 10px;
  font-weight: 400;
}
.CustomerService .recordContainer .recordListBox .recordTextCom {
  color: #666666;
  font-size: 12px;
  font-weight: 400;
  min-width: 85px;
  word-break: break-word;
}
.CustomerService .recordContainer .recordListBox .recordTextCom.recordTextComM3 {
  min-width: 115px;
}
.CustomerService .recordContainer .recordListBox .csBtn {
  display: flex;
  align-items: center;
  justify-content: center;
}
.CustomerService .recordContainer .recordListBox .csBtn .csBtnText {
  color: #00A6FF;
  font-weight: 400;
  font-size: 12px;
  border: 1px solid #00A6FF;
  border-radius: 8px;
  padding: 0 6px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.CustomerService .recordContainer .recordListBox .rightArrow {
  width: 24px;
  height: 24px;
  transition: all 0.2s;
  cursor: pointer;
}
.CustomerService .recordContainer .recordListBox .hiddenText {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 12px;
}
.CustomerService .recordContainer .recordListBox .tipBox {
  background-color: #E6F6FF;
  border-radius: 8px;
  padding: 6px;
  margin-bottom: 10px;
}
.CustomerService .recordContainer .recordListBox .tipBox .tipBoxText1 {
  color: #00A6FF;
  font-size: 12px;
  font-weight: 400;
  margin-bottom: 6px;
}
.CustomerService .recordContainer .recordListBox .tipBox .tipBoxText2 {
  color: #222222;
  font-size: 12px;
  font-weight: 400;
  word-break: break-word;
  transition: all 0.2s;
}
.CustomerService .recordContainer .recordListBox .recordSignedImgbox {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 8px;
}
.CustomerService .recordContainer .recordListBox .recordSignedImgbox .recordImg {
  width: 100px;
  height: 100px;
  cursor: pointer;
  border-radius: 8px;
  overflow: hidden;
}
.CustomerService .recordContainer .recordListBoxMobile {
  background-color: #fff;
  border-radius: 6px;
  margin-bottom: 8px;
  padding: 12px 8px;
}
.CustomerService .recordContainer .recordListBoxMobile .recordList {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 8px;
}
.CustomerService .recordContainer .recordListBoxMobile .recordList1 {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.CustomerService .recordContainer .recordListBoxMobile .recordTexCom {
  width: 92px;
}
.CustomerService .recordContainer .recordListBoxMobile .recordText2 {
  color: #222222;
  font-size: 11px;
  font-weight: 400;
  max-width: 120px;
}
.CustomerService .recordContainer .recordListBoxMobile .recordTextStatus {
  max-width: 120px;
}
.CustomerService .recordContainer .recordListBoxMobile .recordText3 {
  color: #999999;
  font-size: 12px;
  font-weight: 400;
  margin-top: 10px;
}
.CustomerService .recordContainer .recordListBoxMobile .eventNameBox {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.CustomerService .recordContainer .recordListBoxMobile .tipBoxText2 {
  font-size: 12px;
  word-break: break-word;
}
.CustomerService .recordContainer .recordListBoxMobile .recordBottom {
  border-top: 1px solid #EAEAEA;
  padding-top: 10px;
}
.CustomerServiceDropDownWrap .drawer-custom-content-wrapper {
  display: flex;
  align-items: flex-end;
}
.CustomerServiceDropDownWrap .CustomerServiceDropDown {
  border-top-right-radius: 16px;
  background-color: #efeff4;
  border-top-left-radius: 16px;
  overflow: hidden;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 15px;
  width: 100%;
  /* Assuming you want the width to be 100%, adjust if necessary */
  padding-bottom: 10px;
}
.CustomerServiceDropDownWrap .CustomerServiceDropDown .modalHeader {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}
.CustomerServiceDropDownWrap .CustomerServiceDropDown .modalHeader > div {
  flex: 1 1;
}
.CustomerServiceDropDownWrap .CustomerServiceDropDown .modalHeader > span {
  flex: 1 1;
}
.CustomerServiceDropDownWrap .CustomerServiceDropDown .modalHeader .modalClose {
  width: 24px;
  height: 24px;
}
.CustomerServiceDropDownWrap .CustomerServiceDropDown .modalHeader .modalHeaderTitle {
  color: #222222;
  font-size: 16px;
  font-weight: 700;
  text-align: center;
}
.CustomerServiceDropDownWrap .CustomerServiceDropDown .modalHeader .modalHeaderRightText {
  color: #00A6FF;
  font-weight: 600;
  font-size: 16px;
  text-align: right;
}
.CustomerServiceDropDownWrap .CustomerServiceDropDown .modalList {
  height: 40px;
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background-color: #ffffff;
  margin-bottom: 6px;
  padding: 0 10px;
}
.CustomerServiceDropDownWrap .CustomerServiceDropDown .modalListText {
  color: #222;
  font-size: 14px;
  font-weight: 400;
}
.CustomerServiceDropDownWrap .CustomerServiceDropDown .modalListCircle {
  width: 20px;
  height: 20px;
  border-radius: 10000px;
  border-width: 2px;
  border-style: solid;
  border-color: #BCBEC3;
  display: flex;
  align-items: center;
  justify-content: center;
}
.CustomerServiceDropDownWrap .CustomerServiceDropDown .modalListInnerCircle {
  background-color: #00A6FF;
  width: 10px;
  height: 10px;
  border-radius: 1000px;
}
.CustomerServiceDropDownWrap .CustomerServiceDropDown .selectBox {
  margin-bottom: 15px;
}
.CustomerServiceDropDownWrap .CustomerServiceDropDown .selectBoxTitle {
  color: #222;
  font-size: 16px;
  font-weight: 600;
}
.CustomerServiceDropDownWrap .CustomerServiceDropDown .selectListBox {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  background-color: #fff;
  padding: 12px;
  padding-bottom: 6px;
  border-radius: 8px;
  margin-top: 8px;
}
.CustomerServiceDropDownWrap .CustomerServiceDropDown .selectBtn {
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px;
  margin-right: 5px;
  border: 1px solid #666;
  width: auto;
  margin-bottom: 6px;
}
.CustomerServiceDropDownWrap .CustomerServiceDropDown .selectBtnText {
  font-size: 12px;
  font-weight: 400;
  color: #00A6FF;
}
.CustomerServiceModal.CustomerServiceModalNo .confirm-btn-wrap {
  display: none;
}
.CustomerServiceModal.modal-dialog {
  left: 5% !important;
  right: 5% !important;
  margin: 0 !important;
  margin: initial !important;
}
.CustomerServiceModal .modaluploadImg {
  width: 80%;
}
.CustomerServiceModal .confirm-btn-wrap .button_sportBtn__mSDS3 {
  margin-bottom: 0 !important;
}
.CustomerServiceModal .uploadImgErrImg {
  width: 58px;
  height: 58px;
}
.CustomerServiceModal .uploadImgErrText {
  text-align: center;
  color: #222;
  font-size: 14px;
  font-weight: 400;
  margin-top: 25px;
  padding-left: 25px;
  padding-right: 25px;
  margin-bottom: 5px;
}
.CustomerServiceModal .modaluploadImgName {
  color: #222222;
  font-size: 14px;
  font-weight: 400;
  text-align: center;
  margin-top: 15px;
  width: 80%;
  word-wrap: break-word;
}
.CustomerServiceModal .CustomerServiceModalContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
  text-align: center;
}
.CustomerServiceModal .CustomerServiceModalContainer > div {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.CustomerServiceModal.CustomerServiceModalNoFooter .ant-modal-confirm-btns {
  display: none !important;
}
.CustomerServiceModal.CustomerServiceModalNoFooter .confirm-btn-wrap {
  display: none !important;
}
.CustomerServiceModal.CustomerServiceModalNoheader .ant-modal-confirm-title {
  display: none;
}
.CustomerServiceModal .ant-modal-confirm-btns {
  margin: 0 30px 30px !important;
}
.CustomerServiceModal .ant-modal-confirm-btns span {
  color: #fff !important;
}
.transfer-drawer.photo-drawer .transfer-drawer .drawer-custom-content {
  background-color: transparent !important;
  display: flex;
  align-items: flex-end;
}
.transfer-drawer.photo-drawer .viewPhotoContainer {
  margin: 0 10px 20px 10px;
  width: 100%;
}
.transfer-drawer.photo-drawer .viewPhotoContainer .photoListBox {
  background-color: rgba(249, 249, 249, 0.78);
  overflow: hidden;
  border-radius: 10px;
}
.transfer-drawer.photo-drawer .viewPhotoContainer .photoList {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 40px;
  padding: 0 15px;
  overflow: hidden;
  position: relative;
}
.transfer-drawer.photo-drawer .viewPhotoContainer .photoList0 {
  border-bottom-width: 1px;
  border-bottom-color: #C6C6C8;
}
.transfer-drawer.photo-drawer .viewPhotoContainer .photoListText {
  color: #007AFF;
  font-size: 20px;
  font-weight: 400;
}
.transfer-drawer.photo-drawer .viewPhotoContainer .photoIcon {
  width: 25px;
  height: 25px;
}
.transfer-drawer.photo-drawer .viewPhotoContainer .Modal_file_btn {
  width: 100%;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
  opacity: 0;
  height: 100%;
}
.transfer-drawer.photo-drawer .viewPhotoContainer .photoCancleBtnText {
  font-size: 20px;
  font-weight: 700;
  color: #007AFF;
}
.transfer-drawer.photo-drawer .viewPhotoContainer .photoCancleBtn {
  background-color: rgba(255, 255, 255, 0.92);
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 46px;
  margin-top: 15px;
  overflow: hidden;
  border-radius: 10px;
}
.CustomerServiceCalendar .modalSelectTimeWrap .modalTimeTip {
  color: #666;
  font-weight: 400;
  font-size: 12px;
  text-align: center;
  padding: 0 15px;
}
.CustomerServiceCalendar .modalSelectTimeWrap .modalSelectTimeBox {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: 12px 0;
}
.CustomerServiceCalendar .modalSelectTimeWrap .modalSelectTimeBox .modalSelectTime {
  color: #222;
  font-size: 16px;
  font-weight: 600;
  margin: 0 8px;
}
.CustomerServiceCalendar .confrimTimeBtn {
  background-color: #00A6FF;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 44px;
  margin: 15px 0 0;
}
.CustomerServiceCalendar .confrimTimeBtn .confrimTimeBtnText {
  color: #fff;
  font-size: 16px;
  font-weight: 400;
}
.CustomerServiceCalendar .react-calendar__year-view .react-calendar__year-view__months .react-calendar__tile {
  padding: 10px !important;
  display: flex;
  align-items: center;
  justify-content: center;
}
.CustomerServiceCalendar .react-calendar__year-view .react-calendar__year-view__months .react-calendar__tile abbr[aria-label] {
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative !important;
}
.CustomerServiceCalendar .react-calendar__year-view .react-calendar__year-view__months .renderDate {
  display: none;
}
.CustomerServiceCalendar .react-calendar__year-view .react-calendar__year-view__months .react-calendar__tile--hasActive {
  border-radius: 999px !important;
}
.CustomerServiceCalendar .react-calendar .react-calendar__navigation {
  margin-bottom: 0px !important;
  background-color: #fff !important;
}
.CustomerServiceCalendar .react-calendar .react-calendar__navigation .react-calendar__navigation__label {
  pointer-events: none;
}
.CustomerServiceCalendar .react-calendar .react-calendar__navigation button[disabled] {
  opacity: 0;
}
.CustomerServiceCalendar .rmc-picker-popup-header {
  margin-bottom: 10px;
  padding: 15px 10px 0;
}
.CustomerServiceCalendar .rmc-picker-popup-header-left {
  padding-left: 0px;
  padding-right: 0px;
}
.CustomerServiceCalendar .rmc-picker-popup-header-right {
  padding-left: 0px;
  padding-right: 0px;
}
.CustomerServiceCalendar .react-daterange-picker__wrapper {
  visibility: hidden;
  pointer-events: none;
}
.CustomerServiceCalendar .react-calendar__month-view__days__day--neighboringMonth {
  color: #FFF !important;
}
.CustomerServiceCalendar .react-calendar__month-view__days__day--neighboringMonth.react-calendar__tile--active {
  color: #e6f6ff !important;
}
.CustomerServiceCalendar .react-daterange-picker__calendar {
  position: relative;
}
.CustomerServiceCalendar .react-daterange-picker__calendar {
  margin-top: -50px;
}
.CustomerServiceCalendar .drawer-custom-content-wrapper .drawer-custom-content .sport-calendar-wrap {
  padding: 0 !important;
  margin: 0 -10px !important;
}
.CustomerServiceCalendar .drawer-custom-content-wrapper .drawer-custom-content .sport-calendar-wrap .react-daterange-picker {
  display: flex;
  flex-direction: column;
}
.CustomerServiceCalendar .drawer-custom-content-wrapper .drawer-custom-content .sport-calendar-wrap .react-daterange-picker .react-daterange-picker__calendar {
  position: static !important;
  height: -moz-fit-content !important;
  height: fit-content !important;
}
.CustomerServiceCalendar .drawer-custom-content-wrapper .drawer-custom-content .sport-calendar-wrap .react-daterange-picker .react-daterange-picker__calendar .react-calendar__month-view__weekdays {
  display: flex;
  /* flex-direction: column; */
  justify-content: center;
  align-items: center;
}
.CustomerServiceCalendar .drawer-custom-content-wrapper .drawer-custom-content .sport-calendar-wrap .react-daterange-picker .react-daterange-picker__calendar .react-calendar__month-view__weekdays .react-calendar__month-view__weekdays__weekday {
  flex: 0 0 48px !important;
}
.CustomerServiceCalendar .drawer-custom-content-wrapper .drawer-custom-content .sport-calendar-wrap .react-daterange-picker .react-daterange-picker__calendar .react-calendar__month-view__weekdays .react-calendar__month-view__weekdays__weekday abbr {
  text-decoration: none !important;
}
.CustomerServiceCalendar .drawer-custom-content-wrapper .drawer-custom-content .sport-calendar-wrap .react-daterange-picker .react-daterange-picker__calendar .react-calendar__month-view__days {
  display: flex;
  /* flex-direction: column; */
  justify-content: center;
  align-items: center;
  padding-bottom: 6px;
}
.CustomerServiceCalendar .drawer-custom-content-wrapper .drawer-custom-content .sport-calendar-wrap .react-daterange-picker .react-daterange-picker__calendar .react-calendar__month-view__days abbr[aria-label] {
  display: none;
}
.CustomerServiceCalendar .drawer-custom-content-wrapper .drawer-custom-content .sport-calendar-wrap .react-daterange-picker .react-daterange-picker__calendar .react-calendar__month-view__days .react-calendar__tile {
  color: #222;
  flex: 0 0 48px !important;
  font-size: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 48px;
  line-height: 48px !important;
}
.CustomerServiceCalendar .drawer-custom-content-wrapper .drawer-custom-content .sport-calendar-wrap .react-daterange-picker .react-daterange-picker__calendar .react-calendar__month-view__days .react-calendar__tile:disabled {
  background-color: white;
  color: #BCBEC3;
}
.CustomerServiceCalendar .drawer-custom-content-wrapper .drawer-custom-content .sport-calendar-wrap .react-daterange-picker .react-daterange-picker__calendar .react-calendar__month-view__days .react-calendar__tile > abbr {
  line-height: 48px !important;
}
.CustomerServiceCalendar .drawer-custom-content-wrapper .drawer-custom-content .sport-calendar-wrap .react-daterange-picker .react-daterange-picker__calendar .react-calendar__month-view__days .react-calendar__tile--active {
  color: #666;
}
.selectBtnWrap {
  display: flex;
  justify-content: flex-end;
}
.selectBtnWrap .customerServiceSelect {
  margin-left: 12px;
}
.selectBtnWrap .customerServiceSelect.customerServiceSelectM2 .ant-select-selection {
  min-width: 160px;
}
.selectBtnWrap .customerServiceSelect.customerServiceSelectM2Long .ant-select-selection {
  min-width: 280px;
}
.selectBtnWrap .customerServiceSelect.customerServiceSelectM3 .ant-select-selection {
  min-width: 180px;
}
.selectBtnWrap .customerServiceSelect.customerServiceSelectM3Long .ant-select-selection {
  min-width: 240px;
}
.selectBtnWrap .customerServiceSelect.ant-calendar-picker {
  position: fixed;
  opacity: 0;
  z-index: -9999;
  top: 30%;
  left: 40%;
  transform: translate(-50%, -45%);
}
.selectBtnWrap .customerServiceSelect .ant-calendar-picker-input.ant-input {
  padding-right: 24px !important;
}
.selectBtnWrap .customerServiceSelect .ant-select-selection {
  min-width: 150px;
}
.selectBtnWrap .customerServiceSelect .ant-select-selection {
  padding-left: 0;
  padding-right: 0;
  border: 1px solid #E3E3E3;
  border-radius: 8px;
  height: 100%;
  display: flex;
  align-items: center;
}
.selectBtnWrap .customerServiceSelect .ant-select-selection .ant-select-selection-selected-value {
  margin-right: 10px;
}
.selectBtnWrap .customerServiceSelect .ant-select-selection .general {
  width: 25px;
  height: 30px;
  margin: 0 6px 0 4px;
}
.selectBtnWrap .customerServiceSelect .ant-select-selection .calendar1 {
  width: 20px;
  height: 20px;
  transform: translateX(10px);
}
.selectBtnWrap .customerServiceSelect .ant-select-selection .ant-select-selection__rendered {
  display: flex;
  align-items: center;
}
.customerServiceSelectDropdownChild-mask {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.2);
  z-index: 999;
}
.customerServiceSelectDropdown {
  background-color: #ffffff;
}
.customerServiceSelectDropdown.ant-calendar-picker-container .ant-calendar-header .ant-calendar-ym-select {
  pointer-events: none;
  /* 禁用点击 */
  cursor: default;
  /* 更改鼠标样式 */
}
.customerServiceSelectDropdown.ant-calendar-picker-container .ant-calendar-body .ant-calendar-cell.ant-calendar-selected-start-date.ant-calendar-selected-day .ant-calendar-date,
.customerServiceSelectDropdown.ant-calendar-picker-container .ant-calendar-body .ant-calendar-cell.ant-calendar-selected-end-date.ant-calendar-selected-day .ant-calendar-date,
.customerServiceSelectDropdown.ant-calendar-picker-container .ant-calendar-body .ant-calendar-cell.ant-calendar-selected-start-date.ant-calendar-last-day-of-month.ant-calendar-selected-day .ant-calendar-date,
.customerServiceSelectDropdown.ant-calendar-picker-container .ant-calendar-body .ant-calendar-cell.ant-calendar-today.ant-calendar-selected-end-date.ant-calendar-selected-day .ant-calendar-date {
  background-color: #00A6FF !important;
  color: #fff !important;
  border-radius: 999px !important;
}
.customerServiceSelectDropdown.ant-calendar-picker-container .ant-calendar-body .ant-calendar-cell.ant-calendar-next-month-btn-day.ant-calendar-disabled-cell {
  display: none;
}
.customerServiceSelectDropdown.ant-calendar-picker-container .ant-calendar-body .ant-calendar-cell.ant-calendar-today.ant-calendar-next-month-btn-day {
  display: none;
}
.customerServiceSelectDropdown.ant-calendar-picker-container .ant-calendar-body .ant-calendar-cell.ant-calendar-today.ant-calendar-next-month-btn-day.ant-calendar-disabled-cell-first-of-row.ant-calendar-disabled-cell {
  display: none;
}
.customerServiceSelectDropdown.ant-calendar-picker-container .ant-calendar-body .ant-calendar-cell.ant-calendar-next-month-btn-day.ant-calendar-disabled-cell-last-of-row.ant-calendar-disabled-cell {
  display: none;
}
.customerServiceSelectDropdown.ant-calendar-picker-container .ant-calendar-body .ant-calendar-cell.ant-calendar-today .ant-calendar-date {
  color: #00A6FF !important;
  font-weight: normal !important;
  border-color: #00A6FF;
  color: #000 !important;
  border-radius: 9999px !important;
}
.customerServiceSelectDropdown.ant-calendar-picker-container .ant-calendar-body .ant-calendar-cell.ant-calendar-today.ant-calendar-disabled-cell .ant-calendar-date {
  background-color: #d8d8d8 !important;
  border: none;
  color: #999 !important;
}
.customerServiceSelectDropdown.ant-calendar-picker-container .ant-calendar-body .ant-calendar-disabled-cell.ant-calendar-today .ant-calendar-date::before {
  position: absolute;
  top: 0px;
  left: 8px;
  width: 24px;
  height: 24px;
  border: 1px solid #fff !important;
  border-radius: 1000px;
  content: ' ';
}
.customerServiceSelectDropdown.ant-calendar-picker-container .ant-calendar-body .ant-calendar-disabled-cell .ant-calendar-date {
  background-color: transparent;
  color: #999999;
  border-radius: unset;
}
.customerServiceSelectDropdown.ant-calendar-picker-container .ant-calendar-body .ant-calendar-last-day-of-month + .ant-calendar-cell .ant-calendar-date,
.customerServiceSelectDropdown.ant-calendar-picker-container .ant-calendar-body .ant-calendar-disabled-cell-first-of-row .ant-calendar-date {
  border-radius: 20px 0 0 20px;
}
.customerServiceSelectDropdown.ant-calendar-picker-container .ant-calendar-body .ant-calendar-disabled-cell-last-of-row .ant-calendar-date,
.customerServiceSelectDropdown.ant-calendar-picker-container .ant-calendar-body .ant-calendar-last-day-of-month .ant-calendar-date {
  border-radius: 0 20px 20px 0;
}
.customerServiceSelectDropdown.ant-calendar-picker-container .ant-calendar-body .ant-calendar-last-day-of-month + .ant-calendar-cell.ant-calendar-disabled-cell-last-of-row .ant-calendar-date,
.customerServiceSelectDropdown.ant-calendar-picker-container .ant-calendar-body .ant-calendar-last-day-of-month.ant-calendar-disabled-cell-first-of-row .ant-calendar-date {
  border-radius: 20px 20px 20px 20px;
}
.customerServiceSelectDropdown.ant-calendar-picker-container .ant-calendar-body .ant-calendar-last-month-cell .ant-calendar-date,
.customerServiceSelectDropdown.ant-calendar-picker-container .ant-calendar-body .ant-calendar-next-month-btn-day .ant-calendar-date {
  color: transparent;
  background-color: #fff;
}
.customerServiceSelectDropdown .ant-calendar-range-part .ant-calendar-header .ant-calendar-prev-year-btn,
.customerServiceSelectDropdown .ant-calendar-range-part .ant-calendar-header .ant-calendar-next-year-btn {
  display: none !important;
}
.customerServiceSelectDropdown .ant-calendar-range-part .ant-calendar-header .ant-calendar-prev-month-btn,
.customerServiceSelectDropdown .ant-calendar-range-part .ant-calendar-header .ant-calendar-next-month-btn {
  width: 24px;
  height: 24px;
  background: url(/vn/img/CustomerService/rightArrow.png) no-repeat;
  background-size: contain;
  top: 8px;
}
.customerServiceSelectDropdown .ant-calendar-range-part .ant-calendar-header .ant-calendar-prev-month-btn::before,
.customerServiceSelectDropdown .ant-calendar-range-part .ant-calendar-header .ant-calendar-next-month-btn::before {
  display: none;
}
.customerServiceSelectDropdown .ant-calendar-range-part .ant-calendar-header .ant-calendar-prev-month-btn {
  left: 15px !important;
  transform: rotate(180deg);
}
.customerServiceSelectDropdown .ant-calendar-range-part .ant-calendar-header .ant-calendar-next-month-btn {
  right: 15px !important;
}
.customerServiceSelectDropdown .ant-calendar-my-select a,
.customerServiceSelectDropdown .ant-calendar-ym-select a {
  color: #222 !important;
}
.customerServiceSelectDropdown .ant-calendar-selected-start-date,
.customerServiceSelectDropdown .ant-calendar-selected-end-date {
  position: relative;
  z-index: 99;
}
.customerServiceSelectDropdown .ant-calendar-selected-start-date::before,
.customerServiceSelectDropdown .ant-calendar-selected-end-date::before {
  background-color: #e6faff;
  content: "";
  width: 50%;
  display: block;
  position: absolute;
  top: 4px;
  bottom: 4px;
  z-index: -1;
}
.customerServiceSelectDropdown .ant-calendar-selected-start-date::before {
  left: 50%;
}
.customerServiceSelectDropdown .ant-calendar-selected-end-date::before {
  right: 50%;
}
.customerServiceSelectDropdown .ant-calendar-range {
  width: 600px;
  box-shadow: 0 0 1px 1px rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.08), 0 2px 8px 2px rgba(0, 0, 0, 0.05);
}
.customerServiceSelectDropdown .selectOption {
  color: #222222;
  font-weight: 400;
  font-size: 14px;
  text-align: center;
  padding-top: 0;
  padding-bottom: 0;
}
.customerServiceSelectDropdown .ant-calendar-footer.ant-calendar-range-bottom {
  border-top-width: 0px;
}
.customerServiceSelectDropdown .ant-calendar-footer-extra {
  width: 100% !important;
}
.customerServiceSelectDropdown.ant-calendar-picker-container {
  border-radius: 16px;
  overflow: hidden;
}
.customerServiceSelectDropdown.ant-calendar-picker-container .ant-calendar-date-panel {
  position: relative;
  margin-top: 70px;
}
.customerServiceSelectDropdown.ant-calendar-picker-container .ant-calendar-date-panel .ant-calendar-range-middle {
  position: absolute;
  left: 0;
  right: 0;
  transform: translateX(0) !important;
  padding-right: 0px !important;
  color: #222;
}
.customerServiceSelectDropdown.ant-calendar-picker-container .ant-calendar-date-panel .ant-calendar-range-left .ant-calendar-input-wrap {
  border-bottom-width: 0px !important;
}
.customerServiceSelectDropdown.ant-calendar-picker-container .ant-calendar-date-panel .ant-calendar-range-left .ant-calendar-input-wrap .ant-calendar-date-input-wrap .ant-calendar-input {
  text-align: right !important;
  padding-right: 25px;
}
.customerServiceSelectDropdown.ant-calendar-picker-container .ant-calendar-date-panel .ant-calendar-range-right .ant-calendar-input-wrap {
  border-bottom-width: 0px !important;
}
.customerServiceSelectDropdown.ant-calendar-picker-container .ant-calendar-date-panel .ant-calendar-range-right .ant-calendar-input-wrap .ant-calendar-date-input-wrap {
  margin-left: 0px !important;
}
.customerServiceSelectDropdown.ant-calendar-picker-container .ant-calendar-date-panel .ant-calendar-range-right .ant-calendar-input-wrap .ant-calendar-date-input-wrap .ant-calendar-input {
  text-align: left !important;
  padding-left: 25px;
}
.customerServiceSelectDropdown.ant-calendar-picker-container .RangePickerFooter {
  display: flex;
  justify-content: flex-end;
  padding-bottom: 15px;
}
.customerServiceSelectDropdown.ant-calendar-picker-container .RangePickerFooter .rangePickerHeader {
  position: absolute;
  top: 0px;
  left: 0;
  right: 0;
  width: 100%;
}
.customerServiceSelectDropdown.ant-calendar-picker-container .RangePickerFooter .rangePickerHeader .pickerHeaderBox {
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 20px 0;
}
.customerServiceSelectDropdown.ant-calendar-picker-container .RangePickerFooter .rangePickerHeader .pickerHeaderBox .pickerHeaderTitle1 {
  color: #222;
  font-weight: 600;
  font-size: 18px;
}
.customerServiceSelectDropdown.ant-calendar-picker-container .RangePickerFooter .rangePickerHeader .pickerHeaderBox .CustomerServiceClose {
  width: 24px;
  height: 24px;
  cursor: pointer;
}
.customerServiceSelectDropdown.ant-calendar-picker-container .RangePickerFooter .rangePickerHeader .pickerHeaderTitle2 {
  color: #666;
  font-weight: 400;
  font-size: 12px;
  text-align: center;
  line-height: 32px;
}
.customerServiceSelectDropdown.ant-calendar-picker-container .RangePickerFooter .modalHeaderRightText {
  color: #00A6FF;
  font-weight: 600;
  font-size: 16px;
  cursor: pointer;
}
.customerServiceSelectDropdown.ant-calendar-picker-container .RangePickerFooter .confrimTimeBtn {
  background-color: #00A6FF;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 38px;
  margin-left: 15px;
  padding: 0 12px;
  cursor: pointer;
}
.customerServiceSelectDropdown.ant-calendar-picker-container .RangePickerFooter .confrimTimeBtnText {
  color: #fff;
  font-size: 16px;
  font-weight: 400;
}
#CustomerServiceSpin.ant-spin {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  padding-top: 0px;
  z-index: 99999999;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.2);
}
#CustomerServiceSpin.ant-spin.hide {
  display: none;
  z-index: -999999999;
}
#CustomerServiceSpin.ant-spin.show {
  display: flex !important;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  z-index: 99999999;
}
.feedbackModalContainer .submission {
  width: 100%;
  margin-bottom: 15px;
  border-radius: 8px;
}
.feedbackModalContainer .fileBoxCom {
  border-width: 1px;
  border-style: solid;
  border-color: #E3E3E3;
  border-radius: 8px;
  padding: 0 15px;
  background-color: #fff;
  box-sizing: border-box;
}
.feedbackModalContainer .fileBox {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 44px;
}
.feedbackModalContainer .fileInputBox {
  width: 100%;
  height: 200px;
  padding-top: 10px;
  padding-bottom: 10px;
  margin-bottom: 5px;
}
.feedbackModalContainer .fileTitleBox {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.feedbackModalContainer .fileTitle {
  color: #222;
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 10px;
}
.feedbackModalContainer .fileText {
  color: #BCBEC3;
  font-size: 14px;
  font-weight: 400;
}
.feedbackModalContainer .downArrow {
  margin-top: -4px;
  border-bottom-color: #BCBEC3;
  border-left-color: #BCBEC3;
  transform: rotate(-45deg);
}
.feedbackModalContainer .fileTitleIcon {
  color: #83630B;
  font-size: 10px;
  font-weight: 500;
  background-color: #FFF5BF;
  padding: 4px;
  border-radius: 8px;
  margin-left: 5px;
  overflow: hidden;
  margin-bottom: 10px;
}
.feedbackModalContainer .deleteImgBox {
  position: absolute;
  right: 8px;
  top: 8px;
  z-index: 99;
}
.feedbackModalContainer .deleteImg {
  width: 32px;
  height: 32px;
}
.feedbackModalContainer .uploadFileBytesImg {
  width: 100%;
  height: 140px;
  overflow: hidden;
  margin-bottom: 10px;
  border-radius: 8px;
  position: relative;
}
.feedbackModalContainer .uploadBtn {
  display: flex;
  flex-direction: row;
  height: 44px;
  align-items: center;
  justify-content: center;
  border-width: 1px;
  border-color: #00A6FF;
  border-radius: 8px;
  margin-bottom: 10px;
  border-style: solid;
  cursor: pointer;
  position: relative;
}
.feedbackModalContainer .uploadImg {
  width: 24px;
  height: 24px;
  margin-right: 6px;
}
.feedbackModalContainer .uploadText {
  color: #00A6FF;
  font-size: 16px;
  font-weight: 400;
}
.feedbackModalContainer .uploadTipList {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 2px 0;
}
.feedbackModalContainer .uploadTipCirlce {
  background-color: #222;
  width: 5px;
  height: 5px;
  border-radius: 1000px;
  overflow: hidden;
  margin-right: 5px;
}
.feedbackModalContainer .uploadTipText {
  color: #222222;
  font-size: 12px;
  font-weight: 400;
  margin-bottom: 6px;
}
.feedbackModalContainer .submitBtn {
  background-color: #E3E3E3;
  height: 44px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
  margin-bottom: 100px;
  cursor: pointer;
}
.feedbackModalContainer .submitBtnText {
  color: #999;
  font-size: 16px;
  font-weight: 400;
}
.feedbackModalContainer .viewBox {
  margin-bottom: 15px;
}
.feedbackModalContainer .inputLimitBox {
  text-align: right;
}
.feedbackModalContainer .inputLimitText {
  color: #999;
  font-size: 12px;
  font-weight: 400;
}
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-modal-confirm-btns .ant-btn:first-child {
  border-color: #00a6ff;
}
.ant-btn-success {
  background: #0ccc3c 0% 0% no-repeat padding-box;
  border-radius: 8px;
  color: #ffffff;
  border: 0;
  width: 100%;
}
.ant-btn-background-ghost {
  color: #00a5fd;
  background: transparent !important;
  border-color: #00a5fd;
}
.ant-modal-close-x {
  color: white;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  color: #000;
  font-weight: bold;
}
.ant-input:focus,
input:focus {
  border-color: #00a5fd;
  border-right-width: 1px !important;
  outline: 0;
  box-shadow: 0 0 0 1px rgba(89, 89, 89, 0.2);
}
.ant-input-affix-wrapper:hover .ant-input:not(.ant-input-disabled):focus {
  border-color: #00a5fd;
}
.has-error .ant-input-affix-wrapper:hover .ant-input:not(.ant-input-disabled) {
  border-color: #ff4d4f;
}
input.ant-select-search__field {
  border: 0;
  box-shadow: none;
}
.ant-btn {
  border-radius: 8px;
}
.ant-modal-confirm-body .ant-modal-confirm-title {
  color: #222;
}
.ant-btn-danger[disabled] {
  color: #fff !important;
  background-color: #cccccc !important;
  border-color: #bfbfbf !important;
}
.ant-message-notice-content {
  padding: 0;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 100px;
  overflow: hidden;
}
.ant-message-notice-content .ant-message-success {
  padding: 10px;
  display: flex;
  align-items: center;
  background: #daffe3;
  color: #0ccc3c;
  max-width: 644px;
}
.ant-message-notice-content .ant-message-error {
  padding: 20px;
  display: flex;
  align-items: center;
  background: #fee5e5;
  color: #eb2121;
  max-width: 644px;
}
.ant-select-disabled .ant-select-selection {
  background: #e8e8e8;
  color: #999999;
  border: 1px solid #cccccc;
}
.ant-select-disabled .ant-select-selection .ant-select-arrow {
  display: none;
}
.ant-steps-item-process .ant-steps-item-icon {
  background: #fff;
}
.ant-steps-item-process .ant-steps-item-icon .anticon {
  color: #00a6ff;
}
.ant-steps-item-process .ant-steps-item-icon .ant-steps-icon {
  color: #00a6ff;
}
.ant-steps-item-finish .ant-steps-item-icon {
  background: #00a6ff;
}
.ant-steps-item-finish .ant-steps-item-icon .anticon {
  color: white;
}
.ant-popover {
  z-index: 998 !important;
}
.ant-select-dropdown-menu-item-selected {
  background-color: #a3ebff00;
}
.login-and-registration .ant-tabs .ant-tabs-bottom-content.ant-tabs-content-animated,
.login-and-registration .ant-tabs .ant-tabs-top-content.ant-tabs-content-animated {
  transition: unset;
}
.ant-input[disabled] {
  color: #222222;
}
.telegram-qrcode-modal .ant-modal-confirm-body-wrapper .ant-modal-confirm-btns {
  width: 100%;
  height: 40px;
  cursor: pointer;
}
.telegram-qrcode-modal .ant-modal-confirm-body-wrapper .ant-modal-confirm-btns button {
  width: 100%;
  height: 100%;
}
.telegram-qrcode-modal .ant-modal-confirm-body-wrapper .ant-modal-confirm-btns button:hover {
  background-color: #29bbff;
}

.error-wrapper {
  width: 100%;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 8px 20px #0000000d;
  border-radius: 20px;
  opacity: 1;
  margin-top: 60px;
  display: flex;
}
.error-wrapper .error-img {
  width: 540px;
  height: 480px;
  -o-object-fit: cover;
     object-fit: cover;
}
.error-wrapper .error-content {
  padding: 0px 80px !important;
}
.error-wrapper .error-item-wrapper {
  display: flex;
  align-items: center;
}
.error-wrapper .error-right {
  width: 660px;
  height: 480px;
  padding: 50px 140px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 0px 20px 20px 0px;
  display: flex;
  justify-content: center;
}
.error-wrapper .error-right .error-item {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.error-wrapper .error-right .error-item .error-text {
  font-weight: normal;
  font-style: normal;
  font-size: 18px;
  line-height: 26px;
  letter-spacing: 0px;
  color: #222222;
  opacity: 1;
  font-weight: bold;
  background: #ffffff 0% 0% no-repeat padding-box;
  text-align: center;
}
.error-wrapper .error-right .error-item .error-icon {
  width: 58px;
  height: 58px;
  margin: 30px 0;
}
.error-wrapper .error-right .error-item .error-icon img {
  width: 100%;
}
.error-wrapper .error-right .error-item .error-block {
  margin: 30px 0;
}
.error-wrapper .error-right .error-item .error-mes {
  text-align: center;
  font-weight: normal;
  font-style: normal;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0px;
  color: #666666;
  opacity: 1;
}
.error-wrapper .error-right .error-item .error-btn {
  margin: 30px 0 20px;
  padding: 8px;
  width: 80%;
  border: 0;
  background: #00a6ff 0% 0% no-repeat padding-box;
  border-radius: 8px;
  opacity: 1;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}
.error-wrapper .error-right .error-item .error-btn img {
  width: 16px;
  height: 16px;
}
.error-wrapper .error-right .error-item .error-btn span {
  text-align: center;
  font-weight: normal;
  font-style: normal;
  font-size: 14px;
  line-height: 19px;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
  padding-left: 10px;
}
.error-wrapper .error-right .error-item .error-chat-btn,
.error-wrapper .error-right .error-item .error-home-btn {
  margin: 20px 0;
  padding: 8px;
  width: 80%;
  border: 0;
  background: #00a6ff 0% 0% no-repeat padding-box;
  border: 1px solid #00a6ff;
  border-radius: 8px;
  opacity: 1;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}
.error-wrapper .error-right .error-item .error-chat-btn img,
.error-wrapper .error-right .error-item .error-home-btn img {
  width: 16px;
  height: 16px;
}
.error-wrapper .error-right .error-item .error-chat-btn span,
.error-wrapper .error-right .error-item .error-home-btn span {
  text-align: center;
  font: normal normal normal 14px/19px Microsoft YaHei;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
  padding-left: 10px;
}
.error-wrapper .error-right .error-item .error-chat-btn {
  margin: 0 0 20px;
  background: #fff 0% 0% no-repeat padding-box;
}
.error-wrapper .error-right .error-item .error-chat-btn span {
  color: #00a6ff;
}
.error-wrapper .error-right .error-item .error-information {
  display: flex;
  flex-direction: column;
  align-self: center;
  gap: 10px;
  text-align: center;
}
.error-wrapper .error-right .error-item .error-information .error-info-text span {
  font-weight: normal;
  font-style: normal;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0px;
  color: #999999;
  opacity: 1;
}
.error-wrapper .error-right .error-item .error-information .error-info-text a {
  text-align: center;
  font-weight: normal;
  font-style: normal;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0px;
  color: #00a6ff;
  opacity: 1;
  padding-left: 18px;
}
.error-wrapper .error-right .error-item .maintain-time {
  width: 80%;
  margin-top: 20px ;
}
.error-wrapper .error-right .error-item .maintain-time label {
  color: #999;
}
.error-wrapper .error-right .error-item .maintain-time .ant-row-flex {
  width: 100%;
  margin-top: 10px;
}
.error-wrapper .error-right .error-item .maintain-time .ant-row-flex .ant-col {
  width: 60px;
}
.error-wrapper .error-right .error-item .maintain-time .ant-row-flex .ant-col .time {
  height: 70px;
  box-shadow: 0px 0px 10px #999;
  border-radius: 10px;
  margin-bottom: 10px;
  color: #00a6ff;
  line-height: 70px;
  text-align: center;
  font-size: 30px;
  font-weight: bold;
}
.error-wrapper .error-right .error-item .maintain-time .ant-row-flex .ant-col center {
  color: #999;
}
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-modal-confirm-btns .ant-btn:first-child {
  border-color: #00a6ff;
}
.ant-btn-success {
  background: #0ccc3c 0% 0% no-repeat padding-box;
  border-radius: 8px;
  color: #ffffff;
  border: 0;
  width: 100%;
}
.ant-btn-background-ghost {
  color: #00a5fd;
  background: transparent !important;
  border-color: #00a5fd;
}
.ant-modal-close-x {
  color: white;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  color: #000;
  font-weight: bold;
}
.ant-input:focus,
input:focus {
  border-color: #00a5fd;
  border-right-width: 1px !important;
  outline: 0;
  box-shadow: 0 0 0 1px rgba(89, 89, 89, 0.2);
}
.ant-input-affix-wrapper:hover .ant-input:not(.ant-input-disabled):focus {
  border-color: #00a5fd;
}
.has-error .ant-input-affix-wrapper:hover .ant-input:not(.ant-input-disabled) {
  border-color: #ff4d4f;
}
input.ant-select-search__field {
  border: 0;
  box-shadow: none;
}
.ant-btn {
  border-radius: 8px;
}
.ant-modal-confirm-body .ant-modal-confirm-title {
  color: #222;
}
.ant-btn-danger[disabled] {
  color: #fff !important;
  background-color: #cccccc !important;
  border-color: #bfbfbf !important;
}
.ant-message-notice-content {
  padding: 0;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 100px;
  overflow: hidden;
}
.ant-message-notice-content .ant-message-success {
  padding: 10px;
  display: flex;
  align-items: center;
  background: #daffe3;
  color: #0ccc3c;
  max-width: 644px;
}
.ant-message-notice-content .ant-message-error {
  padding: 20px;
  display: flex;
  align-items: center;
  background: #fee5e5;
  color: #eb2121;
  max-width: 644px;
}
.ant-select-disabled .ant-select-selection {
  background: #e8e8e8;
  color: #999999;
  border: 1px solid #cccccc;
}
.ant-select-disabled .ant-select-selection .ant-select-arrow {
  display: none;
}
.ant-steps-item-process .ant-steps-item-icon {
  background: #fff;
}
.ant-steps-item-process .ant-steps-item-icon .anticon {
  color: #00a6ff;
}
.ant-steps-item-process .ant-steps-item-icon .ant-steps-icon {
  color: #00a6ff;
}
.ant-steps-item-finish .ant-steps-item-icon {
  background: #00a6ff;
}
.ant-steps-item-finish .ant-steps-item-icon .anticon {
  color: white;
}
.ant-popover {
  z-index: 998 !important;
}
.ant-select-dropdown-menu-item-selected {
  background-color: #a3ebff00;
}
.login-and-registration .ant-tabs .ant-tabs-bottom-content.ant-tabs-content-animated,
.login-and-registration .ant-tabs .ant-tabs-top-content.ant-tabs-content-animated {
  transition: unset;
}
.ant-input[disabled] {
  color: #222222;
}
.telegram-qrcode-modal .ant-modal-confirm-body-wrapper .ant-modal-confirm-btns {
  width: 100%;
  height: 40px;
  cursor: pointer;
}
.telegram-qrcode-modal .ant-modal-confirm-body-wrapper .ant-modal-confirm-btns button {
  width: 100%;
  height: 100%;
}
.telegram-qrcode-modal .ant-modal-confirm-body-wrapper .ant-modal-confirm-btns button:hover {
  background-color: #29bbff;
}

