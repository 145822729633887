//seo footer 的less
.footer-seo-wrap{
    background-color: @theme;
    color: @white;
    overflow: hidden;
    .common-distance{
        padding: 0;
    }
    h1,h2,h3{
        color: @white;
        padding-top: 0.5em;
    }
    h1{
        text-align: center;
    }
    .seo-container{
        padding: 50px 0;
        position: relative;
        .button-more{
            width: 100%;
            position: absolute;
            left: 0;
            top: 145px;
            background: linear-gradient(0deg,rgba(0,166,255,.878) 37.3%,rgba(0,166,255,0) 100%);
            height: 145px;
            .show-more-arrow{
                position: absolute;
                width: 94px;
                left: calc(50% - 47px);
                top: 50px;
                display: inline-block;
                border-radius: 18px;
                background-color: rgba(0, 0, 0, 0.2);
                text-align: center;
                line-height: 36px;
                cursor: pointer;
                transition-duration: 2s;
                &:hover{
                    transform: scale(1.1);
                    transition-duration: 1.5s
                }
            }
        }
        .button-up{
            position: absolute;
            left: calc(50% - 18px);
            bottom: 15px;
            cursor: pointer;
            font-size: 18px;
            .anticon-up{
                width: 36px;
                height: 36px;
                background-color: rgba(0, 0, 0, 0.2);
                border-radius: 18px;
                text-align: center;
                line-height: 36px;
                transition-duration: 2s;
                &:hover{
                    transform: scale(1.2);
                    transition-duration: 1.5s;
                }
            }
        }
        ol {
            padding-left: 25px;
            li{
                list-style: disc
            };
        }
    }
}